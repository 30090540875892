import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    textTransform: 'none',
  },
  titleButton: {
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingBottom: '1rem',
    fontWeight: 'bold',
    color: '#111827',
  },
  titleInput: {
    textAlign: 'left',
    paddingBottom: '1rem',
    fontWeight: 'bold',
    color: '#111827',
  },
  selected: {
    backgroundColor: '#3B82F6',
    color: '#FFFFFF',
    textTransform: 'none',
  },
  boldText: {
    fontWeight: 'bold',
    color: '#111827',
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  noTextTranform: {
    textTransform: 'none',
  },
});

export default useStyles;
