import store from '../store';
import { resetPaciente } from '../actions/basicosPaciente/actions';
import { resetConsulta } from '../actions/consulta/actions';

export function limpiarRedux() {
  store.dispatch(resetPaciente());
  store.dispatch(resetConsulta());
}

export function limpiarReduxPaciente() {
  store.dispatch(resetPaciente());
}
