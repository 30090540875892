import { Dispatch, SetStateAction } from 'react';

export interface ISintomaAYS {
  nombreSintoma: string;
  comentarioSintoma: string;
  tieneSintoma: boolean | null;
}

export interface IAparatosYSistemas {
  general: ISintomaAYS[];
  cabezaOidosBoca: ISintomaAYS[];
  cardiovascular: ISintomaAYS[];
  respiratorio: ISintomaAYS[];
  gastrointestinal: ISintomaAYS[];
  genitourinarioRenal: ISintomaAYS[];
  hematicoLinfatico: ISintomaAYS[];
  endocrino: ISintomaAYS[];
  neurologico: ISintomaAYS[];
  musculoEsqueletico: ISintomaAYS[];
  pielAnexos: ISintomaAYS[];
  reproductivoHombre: ISintomaAYS[];
  reproductivoMujer: ISintomaAYS[];
  esferaPsiquica: ISintomaAYS[];
}

export const aparatosYSistemasInitial: IAparatosYSistemas = {
  general: [],
  cabezaOidosBoca: [],
  cardiovascular: [],
  respiratorio: [],
  gastrointestinal: [],
  genitourinarioRenal: [],
  hematicoLinfatico: [],
  endocrino: [],
  neurologico: [],
  musculoEsqueletico: [],
  pielAnexos: [],
  reproductivoHombre: [],
  reproductivoMujer: [],
  esferaPsiquica: [],
};

export interface IBotonAYS {
  seccion: number;
  titulo:
  | 'general'
  | 'cabeza_oidos_boca_garganta'
  | 'cardiovascular'
  | 'respiratorio'
  | 'gastrointestinal'
  | 'genitourinario_renal'
  | 'hematico_linfatico'
  | 'endocrino'
  | 'neurologico'
  | 'musculo_esqueletico'
  | 'piel_y_anexos'
  | 'reproductivo_mujer'
  | 'reproductivo_hombre'
  | 'esfera_psiquica';
  icono: any;
  sexo?: string;
}

export interface IAparatosYSistemasProps {
  aparatosYSistemas: IAparatosYSistemas;
  setAparatosYSistemas: Dispatch<SetStateAction<IAparatosYSistemas>>;
}
