export function getUser() {
  const userStr = sessionStorage.getItem('user');
  if (userStr) {
    return userStr;
  }
  return null;
}

export function setUser(user: string) {
  sessionStorage.setItem('user', user);
}

export function getToken() {
  return sessionStorage.getItem('token') || '';
}

export function setToken(token: string) {
  sessionStorage.setItem('token', token);
}

export function getIdConsulta() {
  const ss = sessionStorage.getItem('idConsulta');
  if (ss) {
    return parseInt(ss, 10);
  }
  return 0;
}

export function setIdConsulta(idConsulta: number) {
  sessionStorage.setItem('idConsulta', idConsulta.toString());
}

export function getConsultorioSS() {
  return parseInt(sessionStorage.getItem('consultorio') || '0', 10);
}

export function setConsultorioSS(idConsultorio: number) {
  sessionStorage.setItem('consultorio', idConsultorio.toString());
}

export function getOrganizacionSS() {
  return parseInt(sessionStorage.getItem('organizacion') || '0', 10);
}

export function setOrganizacionSS(id: number) {
  sessionStorage.setItem('organizacion', id.toString());
}

export function getEmailMe() {
  return sessionStorage.getItem('emailMe') || '';
}

export function setEmailMe(email: string) {
  sessionStorage.setItem('emailMe', email);
}

export function setPaciente(id: number) {
  sessionStorage.setItem('idPaciente', id.toString());
}

export function getPaciente() {
  return parseInt(sessionStorage.getItem('idPaciente') || '0', 10);
}

export function setUserSession(user: string, token: string, rol: string) {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', user);
  sessionStorage.setItem('rol', rol);
}

export function getRol() {
  return sessionStorage.getItem('rol') || '';
}

export function setRol(rol: string) {
  sessionStorage.setItem('rol', rol);
}

export function removeIdConsulta() {
  sessionStorage.removeItem('idConsulta');
}

export function removePaciente() {
  sessionStorage.removeItem('nombre');
  sessionStorage.removeItem('sexo');
  sessionStorage.removeItem('dia');
  sessionStorage.removeItem('mes');
  sessionStorage.removeItem('anio');
  sessionStorage.removeItem('idPaciente');
}

export function removeUserSession() {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('rol');
  sessionStorage.removeItem('emailMe');
  sessionStorage.removeItem('idConsulta');
  sessionStorage.removeItem('nombre');
  sessionStorage.removeItem('sexo');
  sessionStorage.removeItem('dia');
  sessionStorage.removeItem('mes');
  sessionStorage.removeItem('anio');
  sessionStorage.removeItem('idPaciente');
  sessionStorage.removeItem('consultorio');
  sessionStorage.removeItem('organizacion');
}
