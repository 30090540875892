import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  helperText: {
    textAlign: 'right',
  },
  noTextTranform: {
    textTransform: 'none',
  },
});

export default useStyles;
