import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogContent, Button, DialogTitle, Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { IModalMotivoConsultaProps } from './types';
import RenderMotivoConsulta from '../RenderMotivoConsulta';
import MMCNotasAdicionales from './NotasAdicionales';

const Transition = React.forwardRef((
  // eslint-disable-next-line react/require-default-props
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

function ModalMotivoConsulta({
  open,
  componentes,
  categoria,
  nombreFormato,
  rangoEdad,
  sexo,
  tipoConsulta,
  opciones,
  callBackClose,
}: IModalMotivoConsultaProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="alert-modal-componente"
      aria-describedby="alert-dialog-componente"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <div className="flex justify-between items-center">
          <h5 className="m-0 font-medium text-gray-600">
            Nombre del formato:&nbsp;
            {nombreFormato}
          </h5>
          <Button onClick={callBackClose} endIcon={<CloseIcon />}>
            {t('cerrar')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <h3 className="font-bold text-sm m-0 text-gray-600 mb-4">
          {`${t('filtro_del_formato')}: ${t('categoria')}: `}
          <span className="font-normal">{categoria}</span>
          {` - ${t('rango_de_edad')}: `}
          <span className="font-normal">{rangoEdad}</span>
          {` - ${t('sexo_para_el_que_aplica')}: `}
          <span className="font-normal">{sexo}</span>
          {` - ${t('tipo_de_consulta')}: `}
          <span className="font-normal">{tipoConsulta}</span>
        </h3>
        {componentes.length ? (
          <RenderMotivoConsulta componentes={componentes} />
        ) : (
          <h3 className="text-gray-600 font-medium">{t('sin_variables_anexadas')}</h3>
        )}
        {opciones.notasAdicionales.incluido && (
          <MMCNotasAdicionales
            incluido={opciones.notasAdicionales.incluido}
            comentarios={opciones.notasAdicionales.comentarios}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ModalMotivoConsulta;
