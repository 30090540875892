import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IListaDatosTabProps } from './types';

function ListaDatosTab({
  titulo,
  tipoQuimico,
  txtDb,
  txtTiempo,
  radioSeleccion,
  comentarios,
  fechaCreacion,
  inx,
  setDatoActualizar,
  valDatoArray,
  openModalActualizar,
  setInxArraySeleccionado,
  setDeleteAlert,
}: IListaDatosTabProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full shadow-md border border-solid border-gray-200 rounded mb-4 flex justify-between items-center">
      <div className="w-full p-4">
        <h3 className="w-full m-0 font-normal text-gray-900">{titulo}</h3>
        {txtDb && (
          <p className="m-0 pt-2 font-normal text-gray-500">
            <b>{`${t('antecedentes_laborales_modal_ruido_nivel')}: `}</b>
            {`${txtDb} dB`}
          </p>
        )}
        {tipoQuimico && (
          <p className="m-0 pt-2 font-normal text-gray-500">
            <b>{`${t('antecedentes_laborales_modal_quimico_tipo')}: `}</b>
            {tipoQuimico}
          </p>
        )}
        {txtTiempo && radioSeleccion && (
          <p className="m-0 pt-2 font-normal text-gray-500">
            <b>{`${t('antecedentes_laborales_modal_tiempo_exposicion')}: `}</b>
            {`${txtTiempo} `}
            {radioSeleccion === 'H' ? t('hora_s_') : t('minuto_s_')}
          </p>
        )}
        {fechaCreacion && (
          <p className="m-0 pt-2 font-normal text-gray-500">
            <b>{`${t('antecedentes_laborales_fecha_creacion')}: `}</b>
            {fechaCreacion}
          </p>
        )}
        {comentarios && (
          <p className="m-0 pt-2 font-normal text-gray-500">
            <b>{`${t('comentarios')}: `}</b>
            {comentarios}
          </p>
        )}
      </div>
      <div className="min-w-max self-center">
        <IconButton
          aria-label="edit"
          onClick={() => {
            if (valDatoArray) {
              setDatoActualizar({ ...valDatoArray });
              openModalActualizar();
              setInxArraySeleccionado(inx);
            }
          }}
          color="primary"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => {
            setInxArraySeleccionado(inx);
            setDeleteAlert(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ListaDatosTab;
