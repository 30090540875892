import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertMultipleDialogProps from './types';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function AlertMultipleDialog(props: IAlertMultipleDialogProps) {
  const {
    open,
    titulo,
    descripcion,
    icono,
    nombreCancelar,
    nombreAceptar,
    callBackAceptar,
    callBackClose,
  } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="alert-dialog-titulo"
      aria-describedby="alert-dialo-descripcion"
    >
      <div className="flex justify-between items-center bg-blue-800 text-white pl-4">
        <span className="text-lg">{titulo}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-justify">
        <div className="text-center my-4">
          {icono}
        </div>
        <DialogContentText className="m-0">
          {descripcion.map((d, indx) => (
            <p className={indx === 0 ? 'mb-0 text-center font-medium' : 'mb-0'} key={d}>
              {d}
            </p>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center w-full mb-3">
          <div className="mr-4">
            <Button onClick={callBackClose} color="primary" variant="outlined">
              {nombreCancelar}
            </Button>
          </div>
          <Button
            onClick={(e) => {
              callBackAceptar();
              callBackClose(e);
            }}
            color="primary"
            variant="contained"
          >
            {nombreAceptar}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default AlertMultipleDialog;
