import { Dispatch, SetStateAction } from 'react';
import { ICatalogosEstandar } from '../../../../../common/types';

interface IServiciosBasicos {
  check1: boolean;
  check2: boolean;
  check3: boolean;
  check4: boolean;
}

export interface ITipoMascota {
  idAnimal: ICatalogosEstandar;
  especifica: string;
  cantidad: string;
}

export interface IVivienda {
  loaded: boolean;
  serviciosBasicos: IServiciosBasicos;
  numeroHabitaciones: number | null;
  numeroHabitantes: number | null;
  indiceHac: string;
  animales: string;
  tipo: ITipoMascota[];
  comentarios: string;
}

export const viviendaInitial: IVivienda = {
  loaded: false,
  serviciosBasicos: {
    check1: false,
    check2: false,
    check3: false,
    check4: false,
  },
  numeroHabitaciones: null,
  numeroHabitantes: null,
  indiceHac: '',
  animales: '',
  tipo: [],
  comentarios: '',
};

export interface IViviendaProps {
  setActiveSubStep: Dispatch<
  SetStateAction<
  | 'vivienda'
  | 'higiene'
  | 'alimentacion'
  | 'actividad-fisica'
  | 'inmunizaciones'
  | 'toxicomania'
  | 'gineco-obstetricos'
  | 'historia-sexual'
  >
  >;
  vivienda: IVivienda;
  setVivienda: Dispatch<SetStateAction<IVivienda>>;
  cambiosUsuario: string[];
  viviendaUsuario: IVivienda;
  setViviendaUsuario: Dispatch<SetStateAction<IVivienda>>;
}
