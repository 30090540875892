import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  noTextTranform: {
    textTransform: 'none',
  },
  header: {
    backgroundColor: 'rgb(59, 130, 246)',
    color: '#FFFFFF',
    textAlign: 'left',
  },
  row: {
    backgroundColor: 'rgb(147, 197, 253)',
    color: '#111827',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
}));

export default useStyles;
