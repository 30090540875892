export interface ICatalogosEstandar {
  id: number;
  label: string;
}

export interface ICatStrEstandar {
  id: string;
  label: string;
}

export interface IPhone {
  codigo: string;
  numero: string;
}

export interface ICountryPhoneInput {
  countryCode: string;
  dialCode: string;
  format: string;
  name: string;
}

export interface ICatalogoFarmaVia {
  id: number;
  label: string;
  impresion: string;
}

export interface ITextFieldEstandar {
  helperText: string;
  error: boolean;
}

export interface IFechasFields {
  dia: {
    error: boolean;
    helperText: string;
  };
  mes: {
    error: boolean;
    helperText: string;
  };
  anio: {
    error: boolean;
    helperText: string;
  };
}

export interface IFechaField {
  error: boolean;
  helperText: string;
}

export interface IFechaObj {
  dia: string;
  mes: string;
  anio: string;
}

export interface ICatalogosCie10 {
  id: string;
  label: string;
  grupoEnfermedades: number;
}

export interface IEdad {
  edad: number;
  claveEdad: number;
  periodo: '' | 'anios' | 'meses' | 'dias';
}

export const edadInitial: IEdad = {
  edad: 0,
  claveEdad: 0,
  periodo: '',
};

export interface ICatLaboratorio {
  id: number;
  idCat: number;
  categoria: string;
  label: string;
}

export interface ICatalogoVariable {
  id: number;
  nombre: string;
  formato: number;
  tipo: boolean;
}

// Archivos basico solo nombre y path
export interface IArchivosLista {
  idArchivo: number;
  idMedico: number;
  nombreArchivo: string;
  pathArchivo: string;
}

// Archivos con extension y peso
export interface IArchivosListaEP {
  idArchivo: number;
  idMedico: number;
  nombreArchivo: string;
  pathArchivo: string;
  extensionArchivo: string;
  pesoArchivo: number;
  fechaCarga: string;
}
