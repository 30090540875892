import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { HtmlTooltip } from '../../../utils/ComponentesJSX';
import { IInfoMotivoConsulta } from './types';

function InfoMotivoConsulta({
  nombreDelMC,
  categoria,
  rangoEdad,
  sexoParaElQueAplica,
  tipoDeMC,
  catCategoriaMC,
  catRangoEdadMC,
  paso,
  setNombreDelMC,
  setCategoria,
  setRangoEdad,
  setSexoParaElQueAplica,
  setTipoDeMC,
}: IInfoMotivoConsulta) {
  const { t } = useTranslation();
  /** States del functional component */
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  /** Catalogos */
  /** Logica */
  /** Hooks */
  /** Efecto al cargar la pagina */
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers de la snackbar */
  /** Handlers del componente */
  const handleChangeCategoria = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    setCategoria(val);
  };

  const handleChangeRangoEdad = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    setRangoEdad(val);
  };

  const handleChangeNombreDelMC = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setNombreDelMC(val);
  };

  const handleChangeSexoParaElQueAplica = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setSexoParaElQueAplica(parseInt(val, 10) || 0);
  };

  const handleChangeTipoDeMC = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setTipoDeMC(parseInt(val, 10) || 0);
  };
  /** handles de disabled components */
  /** Handlers para enviar la informacion */
  /** handler del evento submit */

  return (
    <div className="p-4 shadow-lg bg-white rounded border border-solid border-gray-200">
      <h3 className="font-medium text-blue-600 mt-0">{t('filtro_del_formato')}</h3>
      <div className="grid grid-cols-12 gap-y-4 mt-2">
        <div className="col-span-3">
          <h3 className="mb-2 text-gray-600 m-0 font-medium flex items-center">
            {`${t('selecciona_una_categoria')}*`}
          </h3>
          <Select
            value={categoria}
            onChange={handleChangeCategoria}
            disabled={paso !== 0}
            fullWidth
            variant="outlined"
          >
            {catCategoriaMC.map((singleCategoria) => (
              <MenuItem value={singleCategoria.id} key={singleCategoria.id}>
                {singleCategoria.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="col-span-1" />
        <div className="col-span-6">
          <h3 className="mb-2 text-gray-600 m-0 font-medium flex items-center">
            {`${t('nombre_del_mc')}*`}
          </h3>
          <TextField
            value={nombreDelMC}
            onChange={handleChangeNombreDelMC}
            fullWidth
            variant="outlined"
            disabled={paso !== 0}
            inputProps={{ maxLength: 50, autoComplete: 'off' }}
          />
        </div>
        <div className="col-span-3">
          <h3 className="mb-2 text-gray-600 m-0 font-medium flex items-center">
            {`${t('rango_de_edad')}*`}
          </h3>
          <Select
            value={rangoEdad}
            onChange={handleChangeRangoEdad}
            disabled={paso !== 0}
            fullWidth
            variant="outlined"
          >
            {catRangoEdadMC.map((singleRangoEdad) => (
              <MenuItem value={singleRangoEdad.id} key={singleRangoEdad.id}>
                {singleRangoEdad.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="col-span-1" />
        <div className="col-span-4">
          <h3 className="mb-1 text-gray-600 m-0 font-medium flex items-center">
            {`${t('sexo_para_el_que_aplica')}*`}
          </h3>
          <RadioGroup
            aria-label={t('sexo_para_el_que_aplica')}
            name="sexo-para-el-que-aplica"
            value={sexoParaElQueAplica}
            onChange={handleChangeSexoParaElQueAplica}
            row
          >
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label={t('ambos')}
              disabled={paso !== 0}
            />
            <FormControlLabel
              value={2}
              control={<Radio color="primary" />}
              label={t('mujer')}
              disabled={paso !== 0}
            />
            <FormControlLabel
              value={3}
              control={<Radio color="primary" />}
              label={t('hombre')}
              disabled={paso !== 0}
            />
          </RadioGroup>
        </div>
        <div className="col-span-3">
          <div className=" flex items-center">
            <h3 className="text-gray-600 m-0 font-medium">{`${t('tipo_de_consulta')}*`}</h3>
            <HtmlTooltip
              title={(
                <>
                  <p>
                    <b>{t('motivo_primario')}</b>
                    : &nbsp;
                    {t('motivo_primario_instruccion')}
                  </p>
                  <p>
                    <b>{t('motivo_subsecuente')}</b>
                    : &nbsp;
                    {t('motivo_subsecuente_instruccion')}
                  </p>
                </>
              )}
            >
              <IconButton size="small">
                <InfoIcon color="primary" fontSize="small" />
              </IconButton>
            </HtmlTooltip>
          </div>
          <RadioGroup
            aria-label={`${t('tipo_de_consulta')}*`}
            name="tipo-de-motivo-de-consulta"
            value={tipoDeMC}
            onChange={handleChangeTipoDeMC}
            row
          >
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label={t('primario')}
              disabled={paso !== 0}
            />
            <FormControlLabel
              value={2}
              control={<Radio color="primary" />}
              label={t('subsecuente')}
              disabled={paso !== 0}
            />
          </RadioGroup>
        </div>
      </div>
      <p className="m-0">
        *&nbsp;
        {t('campos_obligatorios')}
      </p>
    </div>
  );
}

export default InfoMotivoConsulta;
