import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFamiliarGrupoEnfermedadesNegadasProps } from './types';

function FamiliarGrupoEnfermedadesNegadas({
  grupoEnfermedadesNegadas,
}: IFamiliarGrupoEnfermedadesNegadasProps) {
  const { t } = useTranslation();
  return (
    <>
      {grupoEnfermedadesNegadas.infecciosasYParasitariasSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('infecciosas_y_parasitarias_(A00-B99)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.neoplasiasSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">{`${t('niega')}: ${t('neoplasias_(C00-D48)')}`}</h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.hematologicasOQueAfectanElMecanismoDeLaInmunidadSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t(
              'hematologicas_o_que_afectan_el_mecanismo_de_la_inmunidad_(D50-D89)',
            )}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.endocrinasNutricionalesYMetabolicasSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('endocrinas_nutricionales_y_metabolicas_(E00-E90)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.trastornosMentalesYDelComportamientoSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('trastornos_mentales_y_del_comportamiento_(F00-F99)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.sistemaNerviosoSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">{`${t('niega')}: ${t('sistema_nervioso_(G00-G99)')}`}</h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.delOjoYSusAnexosSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('del_ojo_y_sus_anexos_(H00-H59)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.delOidoYApofisisMastoideSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('del_oido_y_apofisis_mastoide_(H60-H95)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.sistemaCirculatorioSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('sistema_circulatorio_(I00-I99)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.sistemaRespiratorioSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('sistema_respiratorio_(J00-J99)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.sistemaDigestivoSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('sistema_digestivo_(K00-K93)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.pielYDelTejidoSubcutaneoSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('piel_y_del_tejido_subcutaneo_(L00-L99)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.sistemaOsteomuscularYDelTejidoConjuntivoSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('sistema_osteomuscular_y_del_tejido_conjuntivo_(M00-M99)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.sistemaGenitourinarioSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('sistema_genitourinario_(N00-N99)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.embarazoPartoPuerperioSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('embarazo_parto_puerperio_(O00-O99)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.delPeriodoPerinatalSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('del_periodo_perinatal_(P00-P96)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.malformaconesCongenitasYAnomaliasCongenitasSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t('malformacones_congenitas_y_anomalias_congenitas_(Q00-Q99)')}`}
          </h3>
        </div>
      )}
      {grupoEnfermedadesNegadas.traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 px-4 py-2 text-gray-500">
          <h3 className="font-normal m-0">
            {`${t('niega')}: ${t(
              'traumatismo_y_envenenamiento_y_otras_consecuencias_de_causas_externas_(S00-T98)',
            )}`}
          </h3>
        </div>
      )}
    </>
  );
}

export default FamiliarGrupoEnfermedadesNegadas;
