import {
  withStyles, Theme, Tooltip, Button,
} from '@material-ui/core';
import { green, orange } from '@material-ui/core/colors';

export const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const GreenButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[700],
    '&:hover': {
      backgroundColor: green[900],
    },
    '&:disabled': {
      backgroundColor: green[300],
    },
  },
}))(Button);

export const OrangeButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[700]),
    backgroundColor: orange[700],
    '&:hover': {
      backgroundColor: orange[900],
    },
    '&:disabled': {
      backgroundColor: orange[300],
    },
  },
}))(Button);
