const arrAreas = [
  {
    label: 'antecedentes-ef-ag',
    valor: {
      a: 'antecedentes-ef-ag-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-pa',
    valor: {
      a: 'antecedentes-ef-pa-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-craneo',
    valor: {
      a: 'antecedentes-ef-craneo-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-cara',
    valor: {
      a: 'antecedentes-ef-cara-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-ojos',
    valor: {
      a: 'antecedentes-ef-ojos-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-oidos',
    valor: {
      a: 'antecedentes-ef-oidos-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-nariz',
    valor: {
      a: 'antecedentes-ef-nariz-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-bg',
    valor: {
      a: 'antecedentes-ef-bg-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-cuello',
    valor: {
      a: 'antecedentes-ef-cuello-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-torax',
    valor: {
      a: 'antecedentes-ef-torax-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-rp',
    valor: {
      a: 'antecedentes-ef-rp-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-gm',
    valor: {
      a: '',
      h: 'antecedentes-ef-gm-o-h',
      m: 'antecedentes-ef-gm-o-m',
    },
  },
  {
    label: 'antecedentes-ef-axila',
    valor: {
      a: 'antecedentes-ef-axila-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-abdomen',
    valor: {
      a: 'antecedentes-ef-abdomen-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-ric',
    valor: {
      a: 'antecedentes-ef-ric-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-gen',
    valor: {
      a: '',
      h: 'antecedentes-ef-gen-o-h',
      m: 'antecedentes-ef-gen-o-m',
    },
  },
  {
    label: 'antecedentes-ef-recto',
    valor: {
      a: 'antecedentes-ef-recto-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-extremidades',
    valor: {
      a: 'antecedentes-ef-extremidades-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-col',
    valor: {
      a: 'antecedentes-ef-col-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-neurologico',
    valor: {
      a: 'antecedentes-ef-neurologico-o',
      h: '',
      m: '',
    },
  },
  {
    label: 'antecedentes-ef-otro',
    valor: {
      a: '',
      h: '',
      m: '',
    },
  },
];

export const arrSeleccion = {
  'antecedentes-ef-ag': 'antecedentes-ef-ag-o',
  'antecedentes-ef-pa': 'antecedentes-ef-pa-o',
  'antecedentes-ef-craneo': 'antecedentes-ef-craneo-o',
  'antecedentes-ef-cara': 'antecedentes-ef-cara-o',
  'antecedentes-ef-ojos': 'antecedentes-ef-ojos-o',
  'antecedentes-ef-oidos': 'antecedentes-ef-oidos-o',
  'antecedentes-ef-nariz': 'antecedentes-ef-nariz-o',
  'antecedentes-ef-bg': 'antecedentes-ef-bg-o',
  'antecedentes-ef-cuello': 'antecedentes-ef-cuello-o',
  'antecedentes-ef-torax': 'antecedentes-ef-torax-o',
  'antecedentes-ef-rp': 'antecedentes-ef-rp-o',
  'antecedentes-ef-gm-h': 'antecedentes-ef-gm-o-h',
  'antecedentes-ef-gm-m': 'antecedentes-ef-gm-o-m',
  'antecedentes-ef-axila': 'antecedentes-ef-axila-o',
  'antecedentes-ef-abdomen': 'antecedentes-ef-abdomen-o',
  'antecedentes-ef-ric': 'antecedentes-ef-ric-o',
  'antecedentes-ef-gen-h': 'antecedentes-ef-gen-o-h',
  'antecedentes-ef-gen-m': 'antecedentes-ef-gen-o-m',
  'antecedentes-ef-recto': 'antecedentes-ef-recto-o',
  'antecedentes-ef-extremidades': 'antecedentes-ef-extremidades-o',
  'antecedentes-ef-col': 'antecedentes-ef-col-o',
  'antecedentes-ef-neurologico': 'antecedentes-ef-neurologico-o',
  'antecedentes-ef-otro': '',
};

export default arrAreas;
