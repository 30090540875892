// necesita la longitud de todos los arrays como primer parametro
// como segundo todos los arrays a los que se les hara la comparacion
// regresará un array de indices, con los indices de los elementos
// que están vacios
export function ObtenerIndiceVacios(len: number, ...arrays: string[][]) {
  const indicesVacios: number[] = [];
  for (let i = 0; i < len; i += 1) {
    if (arrays.every((array) => array[i] === '')) {
      indicesVacios.unshift(i);
    }
  }
  return indicesVacios;
}

// necesita como primer argumento el array de indices, con los indices
// vacios ordenados de mayor a menor, para que el splice funcione correctamente
// y se pueda eliminar de atras hacia adelante para que los indices
// no cambien y asi sea funcional
export function EliminarDesdeIndices(arrayIndices: number[], ...arrs: any[][]) {
  const arrays = [...arrs];
  for (let i = 0; i < arrays.length; i += 1) {
    for (let j = 0; j < arrayIndices.length; j += 1) {
      arrays[i].splice(arrayIndices[j], 1);
    }
  }
  return arrays;
}
// a diferencia de la primera, este solo elimina un array a la vez, para tener
// controlado el nombre de cada array
export function EliminarUnArrayDesdeIndices(arrayIndices: number[], arr: any[]) {
  const array = [...arr];
  for (let i = 0; i < arrayIndices.length; i += 1) {
    array.splice(arrayIndices[i], 1);
  }
  return array.length ? array : undefined;
}
// funcion que filtra y verifica por una propiedad de un array de objetos
// que no este duplicada y que tenga informacion si no se eliminan
// los repetidos y el que cuenta con informacion
export function uniqueObjArray(arr: any[], property: string) {
  return arr.length > 0
    ? arr.filter(
      (x, index) => x[property].trim().length !== 0
          && arr.findIndex((val) => val[property] === x[property]) === index,
    )
    : [];
}

export function uniqueStringArray(arr: string[]) {
  return arr.length > 0
    ? arr.filter((x, index) => x.trim().length !== 0 && arr.findIndex((val) => val === x) === index)
    : [];
}
