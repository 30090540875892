import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Editor, RawDraftContentState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import IComentarioProps from './types';

const ComentariosComponente = (props: IComentarioProps) => {
  const {
    comentarios, setComentarios, maxLength, placeholder, lang, disabled,
  } = props;
  const [max, setMax] = useState<number>(0);
  const [pasa, setPasa] = useState<boolean>(false);
  const onContentStateChange = (contentState: RawDraftContentState) => {
    let valIngresado = 0;
    contentState.blocks.forEach((x) => {
      valIngresado += x.text.length;
    });
    if (valIngresado <= maxLength) {
      setComentarios(contentState);
      setMax(valIngresado);
      setPasa(false);
    } else {
      setMax(valIngresado - maxLength);
      setPasa(true);
    }
  };
  useEffect(() => {
    if (max === 0 && comentarios.blocks.length > 0) {
      let valIngresado = 0;
      comentarios.blocks.forEach((x) => {
        valIngresado += x.text.length;
      });
      setMax(valIngresado);
      setPasa(false);
    }
  }, [comentarios]);
  return (
    <div className="mb-4 border-solid border border-gray-400 rounded p-3" key="comentarios-editor">
      <Editor
        initialContentState={comentarios}
        readOnly={disabled}
        placeholder={placeholder}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onContentStateChange={onContentStateChange}
        toolbar={{
          options: [
            'inline',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'history',
          ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
            bold: { className: 'bordered-option-classname' },
            italic: { className: 'bordered-option-classname' },
            underline: { className: 'bordered-option-classname' },
            strikethrough: { className: 'bordered-option-classname' },
          },
          fontSize: {
            className: 'bordered-option-classname',
            options: [12, 14, 16, 18],
          },
          fontFamily: {
            className: 'bordered-option-classname',
          },
        }}
        localization={{
          locale: `${lang}`,
        }}
        hashtag={{
          separator: ' ',
          trigger: '#',
        }}
      />
      {pasa ? (
        <div className="flex justify-end text-red-500 font-medium">
          <Trans i18nKey="component-comentarios-excedido">
            index0
            {' '}
            {{ max }}
            {' '}
            index1
            {' '}
            {{ maxLength }}
          </Trans>
        </div>
      ) : (
        <div className="flex justify-end text-gray-600 font-medium">
          <Trans i18nKey="component-comentarios-conteo">
            index0
            {' '}
            {{ max }}
            {' '}
            index1
            {' '}
            {{ maxLength }}
          </Trans>
        </div>
      )}
    </div>
  );
};

export default ComentariosComponente;
