import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Button, Accordion, AccordionDetails, AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from '../../../../../../../common/styles';
import ListaDatosTab from '../ListaDatosTabs';
import { ITabExposicionMecanicoProps } from './types';
import { ITiempoSeleccionComentariosFecha } from '../../../types';

function TabExposicionMecanico({
  setOpenModalTiempoExposicion,
  setNombreModal,
  setIdTabSeleccionado,
  objExposicionRiesgo,
  setDatoActualizar,
  setInxArraySeleccionado,
  setDeleteAlert,
}: ITabExposicionMecanicoProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [accAtrapamiento, setAccAtrapamiento] = useState<boolean | undefined>(undefined);
  const [accAtropellamientos, setAccAtropellamientos] = useState<boolean | undefined>(undefined);
  const [accCaidaObjetos, setAccCaidaObjetos] = useState<boolean | undefined>(undefined);
  const [accCaidaMismo, setAccCaidaMismo] = useState<boolean | undefined>(undefined);
  const [accCaidaDiferente, setAccCaidaDiferente] = useState<boolean | undefined>(undefined);
  const [accContactoSuperficie, setAccContactoSuperficie] = useState<boolean | undefined>(
    undefined,
  );
  const [accChoques, setAccChoques] = useState<boolean | undefined>(undefined);
  const [accContactoElectrico, setAccContactoElectrico] = useState<boolean | undefined>(undefined);
  const [accCortes, setAccCortes] = useState<boolean | undefined>(undefined);
  const [accProyeccionFragmentos, setAccProyeccionFragmentos] = useState<boolean | undefined>(
    undefined,
  );
  const [accProyeccionFluidos, setAccProyeccionFluidos] = useState<boolean | undefined>(undefined);
  const [accPinchazos, setAccPinchazos] = useState<boolean | undefined>(undefined);
  const [accOtro, setAccOtro] = useState<boolean | undefined>(undefined);
  return (
    <div className="w-full">
      {/* es la parte que corresponde a temperaturas altas */}
      <Accordion expanded={accAtrapamiento} onChange={() => setAccAtrapamiento(!accAtrapamiento)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_atrapamiento')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_atrapamiento'));
                  setIdTabSeleccionado(11);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtrapamiento.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtrapamiento.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_atrapamiento')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(11);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_atrapamiento'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(11);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a temperaturas bajas */}
      <Accordion
        expanded={accAtropellamientos}
        onChange={() => setAccAtropellamientos(!accAtropellamientos)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_atropellamientos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_atropellamientos'));
                  setIdTabSeleccionado(12);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtropellamientosVehiculos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtropellamientosVehiculos.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_atropellamientos')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(12);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_atropellamientos'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(12);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion ionizante */}
      <Accordion expanded={accCaidaObjetos} onChange={() => setAccCaidaObjetos(!accCaidaObjetos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_caida_objetos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_caida_objetos'));
                  setIdTabSeleccionado(13);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidaObjetos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidaObjetos.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_caida_objetos')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(13);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_caida_objetos'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(13);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion no ionizante */}
      <Accordion expanded={accCaidaMismo} onChange={() => setAccCaidaMismo(!accCaidaMismo)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_caidas_mismo')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_caidas_mismo'));
                  setIdTabSeleccionado(14);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasMismoNivel.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasMismoNivel.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_caidas_mismo')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(14);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_caidas_mismo'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(14);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ventilacion */}
      <Accordion
        expanded={accCaidaDiferente}
        onChange={() => setAccCaidaDiferente(!accCaidaDiferente)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_caidas_diferente')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_caidas_diferente'));
                  setIdTabSeleccionado(15);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasDiferenteNivel.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasDiferenteNivel.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_caidas_diferente')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(15);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_caidas_diferente'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(15);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a iluminacion */}
      <Accordion
        expanded={accContactoSuperficie}
        onChange={() => setAccContactoSuperficie(!accContactoSuperficie)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_contacto_superficies')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_contacto_superficies'));
                  setIdTabSeleccionado(16);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayContactoSuperficiesTrabajo.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayContactoSuperficiesTrabajo.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_contacto_superficies')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(16);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_contacto_superficies'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(16);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ruido */}
      <Accordion expanded={accChoques} onChange={() => setAccChoques(!accChoques)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_choques')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_choques'));
                  setIdTabSeleccionado(17);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayChoques.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayChoques.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_choques')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(17);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_choques'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(17);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a vibracion */}
      <Accordion
        expanded={accContactoElectrico}
        onChange={() => setAccContactoElectrico(!accContactoElectrico)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_contacto_electrico')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_contacto_electrico'));
                  setIdTabSeleccionado(18);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayContactoElectrico.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayContactoElectrico.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_contacto_electrico')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(18);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_contacto_electrico'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(18);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a fluido electrico */}
      <Accordion expanded={accCortes} onChange={() => setAccCortes(!accCortes)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_cortes')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_cortes'));
                  setIdTabSeleccionado(19);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCortes.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCortes.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_cortes')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(19);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_cortes'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(19);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a fluido electrico */}
      <Accordion
        expanded={accProyeccionFragmentos}
        onChange={() => setAccProyeccionFragmentos(!accProyeccionFragmentos)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_proyeccion_fragmentos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_proyeccion_fragmentos'));
                  setIdTabSeleccionado(20);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayProyeccionFragmentos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayProyeccionFragmentos.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_proyeccion_fragmentos')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(20);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_proyeccion_fragmentos'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(20);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a fluido electrico */}
      <Accordion
        expanded={accProyeccionFluidos}
        onChange={() => setAccProyeccionFluidos(!accProyeccionFluidos)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_proyeccion_fluidos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_proyeccion_fluidos'));
                  setIdTabSeleccionado(21);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayProyeccionFluidos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayProyeccionFluidos.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_proyeccion_fluidos')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(21);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_proyeccion_fluidos'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(21);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a fluido electrico */}
      <Accordion expanded={accPinchazos} onChange={() => setAccPinchazos(!accPinchazos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_pinchazos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_pinchazos'));
                  setIdTabSeleccionado(22);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayPinchazos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayPinchazos.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_pinchazos')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(22);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_pinchazos'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(22);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a otro */}
      <Accordion expanded={accOtro} onChange={() => setAccOtro(!accOtro)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_mecanico_otros')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_mecanico_otros'));
                  setIdTabSeleccionado(23);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayOtro.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayOtro.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_mecanico_otros')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(23);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_mecanico_otros'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(23);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TabExposicionMecanico;
