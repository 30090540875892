import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  noTextTranform: {
    textTransform: 'none',
  },
  bottomSticky: {
    top: 'auto',
    bottom: 0,
  },
  orderButton: {
    textAlign: 'right',
  },
  divButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonRigth: {
    paddingRight: '10px',
  },
}));

export default useStyles;
