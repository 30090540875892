import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IAlimentosProps } from './types';

function AlimentacionComponente({
  hayCambios,
  index,
  eliminar,
  handleChange,
  alimento,
  especifica,
}: IAlimentosProps) {
  const { t } = useTranslation();
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 flex">
      <div className="grid gap-4 grid-cols-3 w-full">
        <TextField
          label={t('antecedentes-pnp-alimentacion-restringidos')}
          value={alimento}
          onChange={(event) => handleChange(event, index, 'alimento')}
          variant="outlined"
          inputProps={{ maxLength: 50, autoComplete: 'off' }}
          disabled={hayCambios}
          fullWidth
        />
        <div className="col-span-2">
          <TextField
            label={t('antecedentes-pnp-alimentacion-especifica-ejemplo')}
            value={especifica}
            onChange={(event) => handleChange(event, index, 'especifica')}
            variant="outlined"
            inputProps={{ maxLength: 100, autoComplete: 'off' }}
            disabled={hayCambios}
            fullWidth
          />
        </div>
      </div>
      <IconButton aria-label="delete" onClick={() => eliminar(index)} disabled={hayCambios}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
}

export default AlimentacionComponente;
