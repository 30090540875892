import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../Navbar/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    zIndex: 20,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    border: 'none',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  iconWidth: {
    minWidth: theme.spacing(6),
  },
  listItemHome: {
    background: '#BFDBFE',
    color: '#1E40AF',
    borderRadius: '0 10px 10px 0',
    marginBottom: '10px',
    width: 'calc(100% - 10px)',
    height: '80px',
    textWrap: 'wrap',
  },
  listItemHomeSelected: {
    background: '#1E40AF',
    color: '#FFFFFF',
    borderRadius: '0 10px 10px 0',
    marginBottom: '10px',
    width: 'calc(100% - 10px)',
    height: '80px',
    textWrap: 'wrap',
  },
  listItemECE: {
    background: '#BFDBFE',
    color: '#1E40AF',
    borderRadius: '0 10px 10px 0',
    marginBottom: '10px',
    width: 'calc(100% - 10px)',
  },
  listItemECESelected: {
    background: '#1E40AF',
    color: '#FFFFFF',
    borderRadius: '0 10px 10px 0',
    marginBottom: '10px',
    width: 'calc(100% - 10px)',
  },
  button: {
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.35);',
    },
  },
}));

export default useStyles;
