import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ListaDiagnosticos from './ListaDiagnosticos';
import { IDiagnosticosPacienteProps } from './types';
import { IDiagnosticoGeneral } from '../../../DiagnosticosGenerales/types';

function DiagnosticosPaciente({
  diagnosticosGenerales,
  setDiagnosticosGenerales,
}: IDiagnosticosPacienteProps) {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="font-normal mt-0 text-gray-500">
        <span className="font-bold">{`${t('instrucciones')}: `}</span>
        <Trans i18nKey="podras_validar_enfermedades">
          index0
          <span className="font-bold">index1</span>
          index2
          <span className="font-bold">index3</span>
          index4
        </Trans>
      </h3>
      <div className="mt-4 bg-white rounded p-4 shadow">
        <h3 className="text-blue-500 font-medium m-0">{t('lista_de_enfermedades_referidas')}</h3>
        <div className="mt-4">
          {diagnosticosGenerales.contenidoTabla
            .filter(
              (diagnostico: IDiagnosticoGeneral) => !diagnostico.validado && diagnostico.posee === 'si',
            )
            .map((diagnostico: IDiagnosticoGeneral) => (
              <ListaDiagnosticos
                diagnostico={diagnostico}
                diagnosticosGenerales={diagnosticosGenerales}
                setDiagnosticosGenerales={setDiagnosticosGenerales}
              />
            ))}
        </div>
      </div>
      <div className="mt-4 bg-white rounded p-4 shadow">
        <h3 className="text-blue-500 font-medium m-0">{t('lista_de_enfermedades_negadas')}</h3>
        <div className="mt-4">
          {diagnosticosGenerales.contenidoTabla
            .filter(
              (diagnostico: IDiagnosticoGeneral) => !diagnostico.validado && diagnostico.posee === 'no',
            )
            .map((diagnostico: IDiagnosticoGeneral) => (
              <ListaDiagnosticos
                diagnostico={diagnostico}
                diagnosticosGenerales={diagnosticosGenerales}
                setDiagnosticosGenerales={setDiagnosticosGenerales}
              />
            ))}
        </div>
      </div>
    </>
  );
}

export default DiagnosticosPaciente;
