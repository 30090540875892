import { Dispatch, SetStateAction } from 'react';
import { ICatalogosEstandar } from '../../../../../common/types';
import { IEnfermedad } from '../../../Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';

export interface ITerapiaFarmacologicaProps {
  enfermedadesActivas: IEnfermedad[];
  tratamientosActualesConsulta: IMedicamento[];
  setTratamientos: Dispatch<SetStateAction<IMedicamento[]>>;
  indiceReceta: number;
  setIndiceReceta: Dispatch<SetStateAction<number>>;
  recetas: IListaRecetas[];
  setRecetas: Dispatch<SetStateAction<IListaRecetas[]>>;
}

export interface IListaRecetas {
  idBD: number;
  actualizado: boolean;
}

export interface IMedicamento {
  idConsulta: number;
  idReceta: number;
  idRecetaConsulta: number;
  enfermedad: ICatalogosEstandar;
  cantidad: number;
  duracion: number;
  fechaFinTratamiento: string;
  fechaReceta: string;
  frecuencia: {
    id: number;
    label: string;
  };
  otraFrecuencia: string;
  instruccionesAdicionales: string;
  medTextoLibre: string;
  nombreDistintivo: {
    id: number;
    generico: number;
    label: string;
  };
  nombreGenerico: {
    id: number;
    label: string;
  };
  periodo: {
    id: number;
    label: string;
  };
  presentacion: string;
  tipo: 'nombre_generico' | 'denominacion_distintiva' | 'texto_libre';
  unidad: {
    id: number;
    label: string;
  };
  via: {
    id: number;
    label: string;
    impresion: string;
  };
  id: number;
  idPaciente: number;
  actual: boolean;
  alertas: IAlertas;
}

export const medicamentoInitial: IMedicamento = {
  idConsulta: 0,
  idReceta: -1,
  idRecetaConsulta: 0,
  enfermedad: { id: 0, label: '' },
  cantidad: 0,
  duracion: 0,
  fechaFinTratamiento: '',
  fechaReceta: '',
  frecuencia: { id: 0, label: '' },
  otraFrecuencia: '',
  instruccionesAdicionales: '',
  medTextoLibre: '',
  nombreDistintivo: { id: 0, generico: 0, label: '' },
  nombreGenerico: { id: 0, label: '' },
  periodo: { id: 0, label: '' },
  presentacion: '',
  tipo: 'nombre_generico',
  unidad: { id: 0, label: '' },
  via: { id: 0, label: '', impresion: '' },
  id: -1,
  idPaciente: -1,
  actual: true,
  alertas: { alergias: [] },
};

export interface IAlertaEstandar {
  nombreAlerta: string; // el nombre de la alerta ej. alergia al amlodipino
  descripcion: string; // la descripcion de la alerta ej. hipersensibilidad al amlodipino
  grado: number; // el grado, dado en numero, moderado, leve, grave etc. a discutir
}
export interface IAlertas {
  alergias: IAlertaEstandar[];
}

export interface IAReceta {
  idReceta: number;
  idConsulta: number;
  idPacienteMedicoConsultorio: number;
}

export interface ILDiagnostico {
  id: number;
  label: string;
}
