import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  barraHerramientas: {
    border: '1px solid #dbdbdb',
    borderRadius: '5px',
    backgroundColor: 'white',
    zIndex: 99,
    position: 'fixed',
    width: '100%',
    left: '0',
    paddingLeft: '144px',
  },
  lineaIzq: {
    borderLeft: '1px solid gray',
    height: '25px',
  },
  marginLeft: {
    marginLeft: '1rem',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 0,
    padding: 0,
    backgroundColor: '#3b82f6',
  },
  input: {
    display: 'none',
    margin: 0,
    padding: 0,
  },
  root: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& > *': {
      margin: 0,
    },
  },
  iconButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  flechaColor: {
    color: '#111827',
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  iconRed: {
    color: '#DC2626',
    width: 15,
  },
  iconOrange: {
    color: '#D97706',
    width: 15,
  },
  iconYellow: {
    color: '#FCD34D',
    width: 15,
  },
  colorIconBlue: {
    color: '#3B82F6',
  },
}));

export default useStyles;
