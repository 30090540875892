import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import { RootState } from '../../../../../store';
import BotonesGuardar from '../../../../../components/BotonesGuardar';
import ResumenCambios from '../../../../../components/ResumenCambios';
import Anticonceptivo from './Anticonceptivo';
import DespistajeDeCancer from './DespistajeDeCancer';
import Embarazos from './Embarazos';
import Generalidades from './Generalidades';
import MenstruacionHabitual from './MenstruacionHabitual';
import SintomasDelClimaterio from './SintomasDelClimaterio';
import {
  antecedentesNoPatologicosGuardarGinecoObstetricos,
  antecedentesNoPatologicosUsuarioPacienteGuardarGinecoObstetricos,
  antecedentesNoPatologicosUsuarioGuardarGinecoObstetricos,
} from '../../../../../utils/sendInfo';
import { constDia, constMes, constAnio } from '../../../../../constants/fechas';
import { IGinecoObstetrico, IGinecoObstetricoProps, ginecoObstetricoInitial } from './types';
import { setRequest } from '../../../../../actions/request/types';

function GinecoObstetricos(props: IGinecoObstetricoProps) {
  const {
    setActiveStep,
    enGestacion,
    setEnGestacion,
    ginecoObstetrico,
    setGinecoObstetrico,
    cambiosUsuario,
    enGestacionUsuario,
    setEnGestacionUsuario,
    ginecoObstetricoUsuario,
    setGinecoObstetricoUsuario,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const [enGestacionTemp, setEnGestacionTemp] = useState<'' | 'si' | 'no'>(enGestacion);
  const [ginecoObstetricoTemp, setGinecoObstetricoTemp] = useState<IGinecoObstetrico>(ginecoObstetricoInitial);

  const hayCambios: boolean = idUsuario > 0 && cambiosUsuario.filter((c: string) => c.length > 0).length > 0;
  const overlayEl = useRef<HTMLDivElement>(null);

  const handleChangeRadio = (
    event: React.ChangeEvent<HTMLInputElement>,
    radio: 'citologia' | 'mastografia' | 'ultrasonido',
  ) => {
    const datosGinecoObstetricos = { ...ginecoObstetricoTemp };
    const value = event.target.value as 'si' | 'no';
    switch (radio) {
      case 'citologia':
        datosGinecoObstetricos.citologiaRadio = value;
        break;
      case 'mastografia':
        datosGinecoObstetricos.mastografia.radio = value;
        break;
      case 'ultrasonido':
        datosGinecoObstetricos.ultrasonido.radio = value;
        break;
      default:
        break;
    }
    setGinecoObstetricoTemp(datosGinecoObstetricos);
  };
  const handleChangeFecha = (
    event: React.ChangeEvent<{ value: unknown }>,
    radio: 'mastografia' | 'ultrasonido',
    tipo: 'dia' | 'mes' | 'anio',
  ) => {
    const datosGinecoObstetricos = { ...ginecoObstetricoTemp };
    const value = (event.target.value as string).toString();
    if (radio === 'mastografia') {
      if (tipo === 'dia') {
        datosGinecoObstetricos.mastografia.fecha.dia = value;
      } else if (tipo === 'mes') {
        datosGinecoObstetricos.mastografia.fecha.mes = value;
      } else {
        datosGinecoObstetricos.mastografia.fecha.anio = value;
      }
    } else if (radio === 'ultrasonido') {
      if (tipo === 'dia') {
        datosGinecoObstetricos.ultrasonido.fecha.dia = value;
      } else if (tipo === 'mes') {
        datosGinecoObstetricos.ultrasonido.fecha.mes = value;
      } else {
        datosGinecoObstetricos.ultrasonido.fecha.anio = value;
      }
    }
    setGinecoObstetricoTemp(datosGinecoObstetricos);
  };

  const evalDespistajeCervicoUterino = () => {
    if (
      ginecoObstetricoTemp.despistajeCancer.cervicoUterino.estudio.length === 1
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.arrayTabla[0] === 'I'
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.estudio[0] === ''
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.especifica[0] === ''
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.resultado[0].check1 === null
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.resultado[0].check2 === null
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.resultado[0].check3 === null
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.resultado[0].selector2 === null
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.resultado[0].selector3 === null
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.resultado[0].resultadoRadio === null
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.resultado[0].resultadoTexto === ''
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.dia[0] === ''
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.mes[0] === ''
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.anio[0] === ''
      && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.comentarios[0] === ''
    ) {
      return false;
    }
    if (
      ginecoObstetricoTemp.despistajeCancer.cervicoUterino.estudio.find(
        (estudioVal, index) => estudioVal === ''
          || (estudioVal === 'otro'
            && ginecoObstetricoTemp.despistajeCancer.cervicoUterino.especifica[index] === ''),
      ) !== undefined
      || ginecoObstetricoTemp.despistajeCancer.cervicoUterino.resultado.find(
        (resultadoVal) => resultadoVal.resultadoTexto === '',
      ) !== undefined
      || ginecoObstetricoTemp.despistajeCancer.cervicoUterino.dia.find((diaVal) => diaVal === '')
        !== undefined
      || ginecoObstetricoTemp.despistajeCancer.cervicoUterino.mes.find((mesVal) => mesVal === '')
        !== undefined
      || ginecoObstetricoTemp.despistajeCancer.cervicoUterino.anio.find(
        (anioVal) => anioVal === '',
      ) !== undefined
    ) {
      return true;
    }
    return false;
  };
  const evalDespistajeMama = () => {
    if (
      ginecoObstetricoTemp.despistajeCancer.mama.estudio.length === 1
      && ginecoObstetricoTemp.despistajeCancer.mama.arrayTabla[0] === 'I'
      && ginecoObstetricoTemp.despistajeCancer.mama.estudio[0] === ''
      && ginecoObstetricoTemp.despistajeCancer.mama.especifica[0] === ''
      && ginecoObstetricoTemp.despistajeCancer.mama.resultado[0] === ''
      && ginecoObstetricoTemp.despistajeCancer.mama.dia[0] === ''
      && ginecoObstetricoTemp.despistajeCancer.mama.mes[0] === ''
      && ginecoObstetricoTemp.despistajeCancer.mama.anio[0] === ''
    ) {
      return false;
    }
    if (
      ginecoObstetricoTemp.despistajeCancer.mama.estudio.find(
        (estudioVal, index) => estudioVal === ''
          || (estudioVal === 'otro'
            && ginecoObstetricoTemp.despistajeCancer.mama.especifica[index] === ''),
      ) !== undefined
      || ginecoObstetricoTemp.despistajeCancer.mama.resultado.find(
        (resultadoVal) => resultadoVal === '',
      ) !== undefined
      || ginecoObstetricoTemp.despistajeCancer.mama.dia.find((diaVal) => diaVal === '')
        !== undefined
      || ginecoObstetricoTemp.despistajeCancer.mama.mes.find((mesVal) => mesVal === '')
        !== undefined
      || ginecoObstetricoTemp.despistajeCancer.mama.anio.find((anioVal) => anioVal === '')
        !== undefined
    ) {
      return true;
    }
    return false;
  };
  const reviewCatchData = () => !esPaciente && (evalDespistajeCervicoUterino() || evalDespistajeMama());

  const getDatosGinecoObstetricos = (datos: IGinecoObstetrico) => ({
    enGestacion: enGestacionTemp || '',
    generalidades: datos.generalidades,
    menstruacionHabitual: datos.menstruacionHabitual,
    anticonceptivo: datos.anticonceptivo,
    embarazos: datos.embarazos,
  });
  const getDatosExpediente = (datos: IGinecoObstetrico) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsulta,
    idConsultorio,
    ginecoObstetricos: {
      ...getDatosGinecoObstetricos(datos),
      despistajeCancer: datos.despistajeCancer,
      sintomasClimaterio: ginecoObstetricoTemp.sintomasClimaterio,
    },
  });
  const getDatosUsuario = (datos: IGinecoObstetrico) => ({
    idMedico,
    idPaciente,
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    idConsultorio,
    ginecoObstetricos: {
      ...getDatosGinecoObstetricos(datos),
      citologiaRadio: datos.citologiaRadio || '',
      mastografia: datos.mastografia || { radio: '', fecha: { dia: '', mes: '', anio: '' } },
      ultrasonido: datos.ultrasonido || { radio: '', fecha: { dia: '', mes: '', anio: '' } },
    },
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const sendFunctions = [
      antecedentesNoPatologicosGuardarGinecoObstetricos(getDatosExpediente(ginecoObstetricoTemp)),
    ];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(
        antecedentesNoPatologicosUsuarioPacienteGuardarGinecoObstetricos(
          getDatosUsuario(ginecoObstetricoTemp),
        ),
      );
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => {
            setEnGestacion(enGestacionTemp);
            setGinecoObstetrico({ ...ginecoObstetricoTemp, loaded: true });
          },
          () => {
            setEnGestacionUsuario({ loaded: true, enGestacion });
            setGinecoObstetricoUsuario({ ...ginecoObstetricoTemp, loaded: true });
          },
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarGinecoObstetricos(
          getDatosUsuario(ginecoObstetricoTemp),
        ),
        successFunction: () => {
          setGinecoObstetrico({ ...ginecoObstetricoTemp, loaded: true });
          setEnGestacion(enGestacionTemp);
          setActiveStep('exploracion-fisica');
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarGinecoObstetricos(
          getDatosExpediente(ginecoObstetricoUsuario),
        ),
        successFunction: () => {
          setEnGestacion(enGestacionUsuario.enGestacion);
          setGinecoObstetrico(ginecoObstetricoUsuario);
          setGinecoObstetricoTemp(ginecoObstetricoUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarGinecoObstetricos(
          getDatosUsuario(ginecoObstetricoTemp),
        ),
        successFunction: () => {
          setEnGestacionUsuario({ loaded: true, enGestacion });
          setGinecoObstetricoUsuario(ginecoObstetrico);
        },
      }),
    );
  };

  useEffect(() => {
    if (ginecoObstetrico.loaded && !ginecoObstetricoTemp.loaded) {
      if (esPaciente) {
        setGinecoObstetricoTemp({
          ...ginecoObstetrico,
          citologiaRadio: ginecoObstetrico.citologiaRadio || '',
          mastografia: ginecoObstetrico.mastografia || {
            radio: '',
            fecha: { dia: '', mes: '', anio: '' },
          },
          ultrasonido: ginecoObstetrico.ultrasonido || {
            radio: '',
            fecha: { dia: '', mes: '', anio: '' },
          },
        });
      } else {
        setGinecoObstetricoTemp(ginecoObstetrico);
      }
      setEnGestacionTemp(enGestacion);
    }
  }, [ginecoObstetrico]);
  useEffect(() => {
    overlayEl.current?.focus();
  }, []);
  return (
    <div className="py-4" ref={overlayEl}>
      {hayCambios && (
        <>
          <ResumenCambios cambios={cambiosUsuario} />
          <div className="mb-4" />
        </>
      )}
      <Generalidades
        hayCambios={hayCambios}
        enGestacion={enGestacionTemp}
        setEnGestacion={setEnGestacionTemp}
        ginecoObstetrico={ginecoObstetricoTemp}
        setGinecoObstetrico={setGinecoObstetricoTemp}
      />
      <MenstruacionHabitual
        hayCambios={hayCambios}
        ginecoObstetrico={ginecoObstetricoTemp}
        setGinecoObstetrico={setGinecoObstetricoTemp}
      />
      <Anticonceptivo
        hayCambios={hayCambios}
        ginecoObstetrico={ginecoObstetricoTemp}
        setGinecoObstetrico={setGinecoObstetricoTemp}
      />
      <Embarazos
        hayCambios={hayCambios}
        ginecoObstetrico={ginecoObstetricoTemp}
        setGinecoObstetrico={setGinecoObstetricoTemp}
      />
      {esPaciente ? (
        <>
          <div className="border-solid border-0 border-b border-gray-600">
            <h2 className="font-normal text-blue-800 mt-6 mb-0">{t('citologia_pregunta')}</h2>
            <div className="pb-4">
              <RadioGroup
                row
                name="citologia cervical"
                value={ginecoObstetricoTemp.citologiaRadio}
                onChange={(e) => handleChangeRadio(e, 'citologia')}
              >
                <FormControlLabel
                  value="si"
                  control={<Radio color="primary" />}
                  label={t('si')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label={t('no')}
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
          </div>
          <div className="grid grid-cols-4 border-solid border-0 border-b border-gray-600">
            <div className="col-span-2">
              <h2 className="font-normal text-blue-800 mt-6 mb-0">{t('mastografia_pregunta')}</h2>
            </div>
            <div />
            {ginecoObstetricoTemp.mastografia.radio === 'si' ? (
              <h2 className="font-normal text-blue-800 mt-6 mb-0">{t('pregunta_ultima_vez')}</h2>
            ) : (
              <div />
            )}
            <div className="pb-4">
              <RadioGroup
                row
                name="mastografia"
                value={ginecoObstetricoTemp.mastografia.radio}
                onChange={(e) => handleChangeRadio(e, 'mastografia')}
              >
                <FormControlLabel
                  value="si"
                  control={<Radio color="primary" />}
                  label={t('si')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label={t('no')}
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
            <div />
            <div />
            {ginecoObstetricoTemp.mastografia.radio === 'si' && (
              <div className="w-full grid grid-cols-3 gap-2">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-dia">{t('dia')}</InputLabel>
                  <Select
                    labelId="label-dia"
                    id="select-dia"
                    value={ginecoObstetricoTemp.mastografia.fecha.dia}
                    onChange={(e) => handleChangeFecha(e, 'mastografia', 'dia')}
                    label={t('dia')}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    {constDia.map((diaSingle) => (
                      <MenuItem value={diaSingle.toString()} key={diaSingle}>
                        {diaSingle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-mes">{t('mes')}</InputLabel>
                  <Select
                    labelId="label-mes"
                    id="select-mes"
                    value={ginecoObstetricoTemp.mastografia.fecha.mes}
                    onChange={(e) => handleChangeFecha(e, 'mastografia', 'mes')}
                    label={t('mes')}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    {constMes.map((mesSingle, indx) => (
                      <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                        {mesSingle.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-año">{t('anio')}</InputLabel>
                  <Select
                    labelId="label-año"
                    id="select-año"
                    value={ginecoObstetricoTemp.mastografia.fecha.anio}
                    onChange={(e) => handleChangeFecha(e, 'mastografia', 'anio')}
                    label={t('anio')}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    {constAnio().map((anioSingle) => (
                      <MenuItem key={anioSingle} value={anioSingle}>
                        {anioSingle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
          <div className="grid grid-cols-4 border-solid border-0 border-b border-gray-600">
            <div className="col-span-2">
              <h2 className="font-normal text-blue-800 mt-6 mb-0">
                {t('ultrasonido_mama_pregunta')}
              </h2>
            </div>
            <div />
            {ginecoObstetricoTemp.ultrasonido.radio === 'si' ? (
              <h2 className="font-normal text-blue-800 mt-6 mb-0">{t('pregunta_ultima_vez')}</h2>
            ) : (
              <div />
            )}
            <div className="pb-4">
              <RadioGroup
                row
                name="ultrasonido de mama"
                value={ginecoObstetricoTemp.ultrasonido.radio}
                onChange={(e) => handleChangeRadio(e, 'ultrasonido')}
              >
                <FormControlLabel
                  value="si"
                  control={<Radio color="primary" />}
                  label={t('si')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label={t('no')}
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
            <div />
            <div />
            {ginecoObstetricoTemp.ultrasonido.radio === 'si' && (
              <div className="w-full grid grid-cols-3 gap-2">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-dia">{t('dia')}</InputLabel>
                  <Select
                    labelId="label-dia"
                    id="select-dia"
                    value={ginecoObstetricoTemp.ultrasonido.fecha.dia}
                    onChange={(e) => handleChangeFecha(e, 'ultrasonido', 'dia')}
                    label={t('dia')}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    {constDia.map((diaSingle) => (
                      <MenuItem value={diaSingle.toString()} key={diaSingle}>
                        {diaSingle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-mes">{t('mes')}</InputLabel>
                  <Select
                    labelId="label-mes"
                    id="select-mes"
                    value={ginecoObstetricoTemp.ultrasonido.fecha.mes}
                    onChange={(e) => handleChangeFecha(e, 'ultrasonido', 'mes')}
                    label={t('mes')}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    {constMes.map((mesSingle, indx) => (
                      <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                        {mesSingle.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-año">{t('anio')}</InputLabel>
                  <Select
                    labelId="label-año"
                    id="select-año"
                    value={ginecoObstetricoTemp.ultrasonido.fecha.anio}
                    onChange={(e) => handleChangeFecha(e, 'ultrasonido', 'anio')}
                    label={t('anio')}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    {constAnio().map((anioSingle) => (
                      <MenuItem key={anioSingle} value={anioSingle}>
                        {anioSingle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <DespistajeDeCancer
            ginecoObstetrico={ginecoObstetricoTemp}
            setGinecoObstetrico={setGinecoObstetricoTemp}
          />
          <SintomasDelClimaterio
            ginecoObstetrico={ginecoObstetricoTemp}
            setGinecoObstetrico={setGinecoObstetricoTemp}
          />
        </>
      )}
      <div className="text-right">
        <BotonesGuardar
          hayCambios={hayCambios}
          aceptarCallback={guardarDatosDeUsuarioAExpediente}
          cancelarCallback={guardarDatosDeExpedienteAUsuario}
          guardarCallback={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
          guardarDisable={reviewCatchData()}
          continuar={esPaciente}
        />
      </div>
    </div>
  );
}

export default GinecoObstetricos;
