import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  txtCenter: {
    textAlign: 'center',
  },
  noTextTransform: {
    textTransform: 'none',
  },
  button: {
    justifyContent: 'left',
    textTransform: 'none',
  },
  boldText: {
    fontWeight: 'bold',
    color: 'black',
  },
  header: {
    backgroundColor: '#3B82F6',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  row: {
    backgroundColor: '#BFDBFE',
    textAlign: 'center',
    color: '#6B7280',
  },
  row2: {
    backgroundColor: 'inherit',
    textAlign: 'center',
    color: '#6B7280',
  },
  rowIcons: {
    backgroundColor: '#F3F4F6',
    textAlign: 'center',
    color: '#6B7280',
    width: 100,
  },
});

export default useStyles;
