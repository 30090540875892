import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import CallMade from '@material-ui/icons/CallMade';
import { ITablaFMCPropiosProps, ITablaFMCPropiosRowProps } from './types';
import { HtmlTooltip } from '../../../utils/ComponentesJSX';
import DeleteDialog from '../../../components/DeleteDialog';

function TablaFMCPropiosRows({
  enOrganizacion,
  nombreFormato,
  rangoEdad,
  sexo,
  tipoConsulta,
  index,
  idFormato,
  compartido,
  handleEditar,
  handleBorrar,
  handleCompartir,
  handleVerFormato,
}: ITablaFMCPropiosRowProps) {
  const { t } = useTranslation();
  const [compartirFormato, setCompartirFormato] = useState<boolean>(false);
  return (
    <>
      <DeleteDialog
        open={compartirFormato}
        titulo={compartido ? t('descompartir_formato') : t('compartir_formato')}
        descripcion={
          compartido ? t('confirmar_descompartir_formato') : t('confirmar_compartir_formato')
        }
        callBackAceptar={() => handleCompartir(idFormato)}
        callBackClose={() => setCompartirFormato(false)}
      />
      <div
        className={`grid grid-cols-12${
          index % 2 === 0 ? ' bg-blue-200' : ''
        } text-center items-center`}
      >
        <div className="col-span-2 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center">
          {nombreFormato}
        </div>
        <div className="col-span-3 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center text-left">
          {rangoEdad}
        </div>
        <div className="col-span-1 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center">
          {sexo}
        </div>
        <div className="col-span-1 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center overflow-ellipsis">
          <p className="m-0 overflow-ellipsis overflow-hidden">{tipoConsulta}</p>
        </div>
        <div className="col-span-2 p-2 border-l border-solid border-0 border-gray-400 h-full flex flex-wrap items-center justify-center">
          <div>
            <Button
              color="inherit"
              onClick={() => handleEditar(idFormato)}
              startIcon={<CreateIcon color="primary" />}
            >
              {t('editar')}
            </Button>
            <Button
              color="inherit"
              onClick={() => handleBorrar(idFormato)}
              startIcon={<DeleteIcon color="primary" />}
            >
              {t('borrar')}
            </Button>
          </div>
        </div>
        {enOrganizacion && (
          <div className="col-span-1 p-2 border-l border-solid border-0 border-gray-400 h-full flex flex-wrap items-center justify-center">
            <HtmlTooltip title={compartido ? t('formato_compartido') : t('compartir_formato')}>
              <Button
                color="primary"
                onClick={() => setCompartirFormato(true)}
                size="large"
                startIcon={<CallMade className={compartido ? 'text-blue-900' : 'text-gray-400 '} />}
              />
            </HtmlTooltip>
          </div>
        )}
        <div
          className={`${
            enOrganizacion ? 'col-span-2' : 'col-span-3'
          } p-2 border-l border-solid border-0 border-gray-400 h-full flex justify-center items-center`}
        >
          <Button color="primary" onClick={() => handleVerFormato(idFormato)}>
            {t('ver_formato')}
          </Button>
          {/* <Button color="inherit" style={{ color: '#EF4444' }}>
            {t('usar_formato_como_plantilla')}
          </Button> */}
        </div>
      </div>
    </>
  );
}

function TablaFMCPropios({
  enOrganizacion,
  motivosConsultaPropios,
  handleEditar,
  handleBorrar,
  handleCompartir,
  handleVerFormato,
}: ITablaFMCPropiosProps) {
  const { t } = useTranslation();
  return (
    <div className="border border-solid border-gray-400 rounded-md">
      <div className="grid grid-cols-12 bg-blue-600 text-white text-center py-2 items-center">
        <span className="col-span-2">{t('nombre_del_formato')}</span>
        <span className="col-span-3">{t('rango_de_edad')}</span>
        <span className="col-span-1">{t('sexo')}</span>
        <span className="col-span-1">{t('tipo_de_consulta')}</span>
        <span className="col-span-2">{t('opciones')}</span>
        {enOrganizacion && <span className="col-span-1">{t('compartir_formato')}</span>}
      </div>
      {motivosConsultaPropios.map((objResultado, index) => (
        <TablaFMCPropiosRows
          enOrganizacion={enOrganizacion}
          nombreFormato={objResultado.nombreFormato}
          rangoEdad={objResultado.rangoEdad}
          sexo={objResultado.sexo}
          tipoConsulta={objResultado.tipoConsulta}
          idFormato={objResultado.idFormato}
          compartido={objResultado.compartido}
          index={index}
          handleEditar={handleEditar}
          handleBorrar={handleBorrar}
          handleCompartir={handleCompartir}
          handleVerFormato={handleVerFormato}
          key={`${objResultado.nombreFormato}-${objResultado.rangoEdad}-${objResultado.sexo}-${objResultado.tipoConsulta}-${objResultado.idFormato}`}
        />
      ))}
    </div>
  );
}

export default TablaFMCPropios;
