import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { ReactComponent as ViviendaIcon } from '../../../../assets/icons/Vivienda.svg';
import { ReactComponent as HigieneIcon } from '../../../../assets/icons/Higiene.svg';
import { ReactComponent as AlimentacionIcon } from '../../../../assets/icons/Alimentacion.svg';
import { ReactComponent as ActividadFisicaIcon } from '../../../../assets/icons/ActividadFisica.svg';
import { ReactComponent as InmunizacionesIcon } from '../../../../assets/icons/Inmunizaciones.svg';
import { ReactComponent as ToxicomaniaIcon } from '../../../../assets/icons/Toxicomania.svg';
import { ReactComponent as GinecobstetricoIcon } from '../../../../assets/icons/Ginecobstetrico.svg';
import { ReactComponent as HistoriaSexualIcon } from '../../../../assets/icons/HistoriaSexual.svg';
import useStyles from './styles';
import { RootState } from '../../../../store';
import Vivienda from './Vivienda/index';
import Higiene from './Higiene/index';
import Alimentacion from './Alimentacion/index';
import ActividadFisica from './ActividadFisica/index';
import Inmunizaciones from './Inmunizaciones/index';
import Toxicomania from './Toxicomania';
import GinecoObstetricos from './GinecoObstetricos';
import HistoriaSexual from './HistoriaSexual';
import { IPersonalesNoPatologicosProps } from './types';

function PersonalesNoPatologicos(props: IPersonalesNoPatologicosProps) {
  const {
    setActiveStep,
    enGestacion,
    setEnGestacion,
    vivienda,
    setVivienda,
    higiene,
    setHigiene,
    alimentacion,
    setAlimentacion,
    actividadFisica,
    setActividadFisica,
    inmunizaciones,
    setInmunizaciones,
    toxicomania,
    setToxicomania,
    ginecoObstetrico,
    setGinecoObstetrico,
    historiaSexual,
    setHistoriaSexual,
    cambiosVivienda,
    cambiosHigiene,
    cambiosAlimentacion,
    cambiosActividadFisica,
    cambiosToxicomania,
    cambiosGinecoObstetrico,
    viviendaUsuario,
    setViviendaUsuario,
    higieneUsuario,
    setHigieneUsuario,
    alimentacionUsuario,
    setAlimentacionUsuario,
    actividadFisicaUsuario,
    setActividadFisicaUsuario,
    toxicomaniaUsuario,
    setToxicomaniaUsuario,
    enGestacionUsuario,
    setEnGestacionUsuario,
    ginecoObstetricoUsuario,
    setGinecoObstetricoUsuario,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { secciones, esPaciente } = useSelector((state: RootState) => state.Me);
  const { sexo, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const [activeSubStep, setActiveSubStep] = useState<
  | 'vivienda'
  | 'higiene'
  | 'alimentacion'
  | 'actividad-fisica'
  | 'inmunizaciones'
  | 'toxicomania'
  | 'gineco-obstetricos'
  | 'historia-sexual'
  >('vivienda');

  return (
    <div>
      <div className="p-4 bg-white container mx-auto shadow">
        <div className="relative pb-4">
          <div className="text-left">
            <h1 className="text-blue-800 font-normal m-2">{t('antecedentes-pnp-selecciona')}</h1>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-3 border-solid border-0 border-b border-gray-600 pb-6">
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 23) && (
            <Button
              className={activeSubStep === 'vivienda' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              startIcon={(
                <>
                  {!esPaciente
                  && idUsuario > 0
                  && cambiosVivienda.filter((c: string) => c.length > 0).length > 0 ? (
                    <Warning color="secondary" fontSize="small" style={{ marginTop: '2px' }} />
                    ) : null}
                  <ViviendaIcon />
                </>
              )}
              fullWidth
              onClick={() => setActiveSubStep('vivienda')}
              size="large"
            >
              {t('antecedentes-pnp-vivienda')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 24) && (
            <Button
              className={activeSubStep === 'higiene' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              size="large"
              startIcon={(
                <>
                  {!esPaciente
                  && idUsuario > 0
                  && cambiosHigiene.filter((c: string) => c.length > 0).length > 0 ? (
                    <Warning color="secondary" fontSize="small" style={{ marginTop: '2px' }} />
                    ) : null}
                  <HigieneIcon />
                </>
              )}
              fullWidth
              onClick={() => setActiveSubStep('higiene')}
            >
              {t('antecedentes-pnp-higiene')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 25) && (
            <Button
              className={activeSubStep === 'alimentacion' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              size="large"
              startIcon={(
                <>
                  {!esPaciente
                  && idUsuario > 0
                  && cambiosAlimentacion.filter((c: string) => c.length > 0).length > 0 ? (
                    <Warning color="secondary" fontSize="small" style={{ marginTop: '2px' }} />
                    ) : null}
                  <AlimentacionIcon />
                </>
              )}
              fullWidth
              onClick={() => setActiveSubStep('alimentacion')}
            >
              {t('antecedentes-pnp-alimentacion')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 26) && (
            <Button
              className={activeSubStep === 'actividad-fisica' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              size="large"
              startIcon={(
                <>
                  {!esPaciente
                  && idUsuario > 0
                  && cambiosActividadFisica.filter((c: string) => c.length > 0).length > 0 ? (
                    <Warning color="secondary" fontSize="small" style={{ marginTop: '2px' }} />
                    ) : null}
                  <ActividadFisicaIcon />
                </>
              )}
              fullWidth
              onClick={() => setActiveSubStep('actividad-fisica')}
            >
              {t('antecedentes-pnp-actividad')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 27) && (
            <Button
              className={activeSubStep === 'inmunizaciones' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<InmunizacionesIcon />}
              fullWidth
              onClick={() => setActiveSubStep('inmunizaciones')}
            >
              {t('antecedentes-pnp-inmunizaciones')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 28) && (
            <Button
              className={activeSubStep === 'toxicomania' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              size="large"
              startIcon={(
                <>
                  {!esPaciente
                  && idUsuario > 0
                  && cambiosToxicomania.filter((c: string) => c.length > 0).length > 0 ? (
                    <Warning color="secondary" fontSize="small" style={{ marginTop: '2px' }} />
                    ) : null}
                  <ToxicomaniaIcon />
                </>
              )}
              fullWidth
              onClick={() => setActiveSubStep('toxicomania')}
            >
              {t('antecedentes-pnp-toxicomania')}
            </Button>
          )}
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 29)
            && sexo === 'M' && (
              <Button
                className={
                  activeSubStep === 'gineco-obstetricos' ? classes.selected : classes.button
                }
                variant="contained"
                color="primary"
                size="large"
                startIcon={(
                  <>
                    {!esPaciente
                    && idUsuario > 0
                    && ((enGestacionUsuario.loaded
                      && enGestacion !== enGestacionUsuario.enGestacion)
                      || cambiosGinecoObstetrico.filter((c: string) => c.length > 0).length > 0) ? (
                        <Warning color="secondary" fontSize="small" style={{ marginTop: '2px' }} />
                      ) : null}
                    <GinecobstetricoIcon />
                  </>
                )}
                fullWidth
                onClick={() => setActiveSubStep('gineco-obstetricos')}
              >
                {t('antecedentes-pnp-gineco')}
              </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 30) && (
            <Button
              className={activeSubStep === 'historia-sexual' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              startIcon={<HistoriaSexualIcon />}
              fullWidth
              onClick={() => setActiveSubStep('historia-sexual')}
            >
              {t('antecedentes-pnp-historias')}
            </Button>
          )}
        </div>
        <div>
          {activeSubStep === 'vivienda'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 23) && (
              <Vivienda
                setActiveSubStep={setActiveSubStep}
                vivienda={vivienda}
                setVivienda={setVivienda}
                cambiosUsuario={cambiosVivienda}
                viviendaUsuario={viviendaUsuario}
                setViviendaUsuario={setViviendaUsuario}
              />
          )}
          {activeSubStep === 'higiene'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 24) && (
              <Higiene
                setActiveSubStep={setActiveSubStep}
                higiene={higiene}
                setHigiene={setHigiene}
                cambiosUsuario={cambiosHigiene}
                higieneUsuario={higieneUsuario}
                setHigieneUsuario={setHigieneUsuario}
              />
          )}
          {activeSubStep === 'alimentacion'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 25) && (
              <Alimentacion
                setActiveSubStep={setActiveSubStep}
                alimentacion={alimentacion}
                setAlimentacion={setAlimentacion}
                cambiosUsuario={cambiosAlimentacion}
                alimentacionUsuario={alimentacionUsuario}
                setAlimentacionUsuario={setAlimentacionUsuario}
              />
          )}
          {activeSubStep === 'actividad-fisica'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 26) && (
              <ActividadFisica
                setActiveSubStep={setActiveSubStep}
                actividadFisica={actividadFisica}
                setActividadFisica={setActividadFisica}
                cambiosUsuario={cambiosActividadFisica}
                actividadFisicaUsuario={actividadFisicaUsuario}
                setActividadFisicaUsuario={setActividadFisicaUsuario}
              />
          )}
          {activeSubStep === 'inmunizaciones'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 27) && (
              <Inmunizaciones
                isPlan={false}
                enGestacion={enGestacion}
                setEnGestacion={setEnGestacion}
                inmunizaciones={inmunizaciones}
                setInmunizaciones={setInmunizaciones}
              />
          )}
          {activeSubStep === 'toxicomania'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 28) && (
              <Toxicomania
                setActiveStep={setActiveStep}
                setActiveSubStep={setActiveSubStep}
                enGestacion={enGestacion}
                setEnGestacion={setEnGestacion}
                toxicomania={toxicomania}
                setToxicomania={setToxicomania}
                cambiosUsuario={cambiosToxicomania}
                toxicomaniaUsuario={toxicomaniaUsuario}
                setToxicomaniaUsuario={setToxicomaniaUsuario}
              />
          )}
          {activeSubStep === 'gineco-obstetricos'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 29)
            && sexo === 'M' && (
              <GinecoObstetricos
                setActiveStep={setActiveStep}
                enGestacion={enGestacion}
                setEnGestacion={setEnGestacion}
                ginecoObstetrico={ginecoObstetrico}
                setGinecoObstetrico={setGinecoObstetrico}
                cambiosUsuario={cambiosGinecoObstetrico}
                enGestacionUsuario={enGestacionUsuario}
                setEnGestacionUsuario={setEnGestacionUsuario}
                ginecoObstetricoUsuario={ginecoObstetricoUsuario}
                setGinecoObstetricoUsuario={setGinecoObstetricoUsuario}
              />
          )}
          {activeSubStep === 'historia-sexual'
            && secciones.find((valor) => valor.idSeccion === 30) && (
              <HistoriaSexual
                historiaSexual={historiaSexual}
                setHistoriaSexual={setHistoriaSexual}
              />
          )}
        </div>
      </div>
    </div>
  );
}

export default PersonalesNoPatologicos;
