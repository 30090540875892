import React from 'react';
import { useTranslation } from 'react-i18next';
import NotasAdicionalesMCPA from './NotasAdicionalesMCPA';
import RenderMCPA from './RenderMCPA';
import { IEditorMCPAProps } from './types';
import PadecimientoActual from './PadecimientoActual';

function EditorMCPA({ mcpa, setMCPA, setMCPACompleto }: IEditorMCPAProps) {
  const { t } = useTranslation();
  const {
    categoria, rangoEdad, sexo, tipoConsulta, componentes, opciones,
  } = mcpa;
  return (
    <div>
      {mcpa.id > 0 && (
        <div className="text-center">
          <h1 className="text-blue-800 font-normal m-2">{t('mc/pa-label')}</h1>
        </div>
      )}
      <h2 className="text-blue-800 font-normal mt-0">
        {`${t('motivo_consulta')}:`}
        &nbsp;
        {mcpa.formato.nombre}
      </h2>
      <h3 className="font-bold text-sm m-0 text-gray-600 mb-4">
        {`${t('filtro_del_formato')}: ${t('categoria')}: `}
        <span className="font-normal">{categoria.label}</span>
        {` - ${t('rango_de_edad')}: `}
        <span className="font-normal">{rangoEdad.label}</span>
        {` - ${t('sexo_para_el_que_aplica')}: `}
        <span className="font-normal">{sexo.label}</span>
        {` - ${t('tipo_de_consulta')}: `}
        <span className="font-normal">{tipoConsulta === 1 ? 'Primaria' : 'Subsecuente'}</span>
      </h3>
      {componentes.length ? (
        <RenderMCPA componentes={componentes} setMCPA={setMCPA} />
      ) : (
        <h3 className="text-gray-600 font-medium">{t('sin_variables_anexadas')}</h3>
      )}
      {opciones.notasAdicionales.incluido && (
        <NotasAdicionalesMCPA
          incluido={opciones.notasAdicionales.incluido}
          comentarios={opciones.notasAdicionales.comentarios}
          setMCPA={setMCPA}
        />
      )}
      {!!mcpa.componentes.length && <PadecimientoActual mcpa={mcpa} setMCPA={setMCPACompleto} />}
    </div>
  );
}

export default EditorMCPA;
