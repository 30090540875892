export const SET_SNACK_MENSAJE = 'SET_SNACK_MENSAJE';
export const SET_SNACK_SEVERITY = 'SET_SNACK_SEVERITY';
export const SET_SNACK_BOOLEAN = 'SET_SNACK_BOOLEAN';
export const SET_SNACK_OPEN = 'SET_SNACK_OPEN';
export const SET_SNACK_CLOSE = 'SET_SNACK_CLOSE';
export const SET_SNACK_COMPLETE = 'SET_SNACK_COMPLETE';

export interface ISnackMensaje {
  mensaje: string;
  severity: 'info' | 'error' | 'success' | 'warning';
  open: boolean;
}

interface SetMensaje {
  type: typeof SET_SNACK_MENSAJE;
  payload: string;
}

interface SetSeverity {
  type: typeof SET_SNACK_SEVERITY;
  payload: 'info' | 'error' | 'success' | 'warning';
}

interface SetBoolean {
  type: typeof SET_SNACK_BOOLEAN;
  payload: boolean;
}

interface SetOpen {
  type: typeof SET_SNACK_OPEN;
}

interface SetClose {
  type: typeof SET_SNACK_CLOSE;
}

interface SetComplete {
  type: typeof SET_SNACK_COMPLETE;
  payload: ISnackMensaje;
}

export type SnackActionTypes =
  | SetMensaje
  | SetSeverity
  | SetBoolean
  | SetOpen
  | SetClose
  | SetComplete;
