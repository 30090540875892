import React from 'react';
import { useTranslation } from 'react-i18next';
import { IResumenPagoProps } from './types';

function ResumenPago({ tipoSuscripcion, tiempoSuscripcion }: IResumenPagoProps) {
  const { t } = useTranslation();
  const costoSuscripcion = () => {
    switch (tipoSuscripcion) {
      case 'premium':
        return tiempoSuscripcion === 'mensual'
          ? t('costo_mensual_premium')
          : t('costo_anual_premium');
      case 'estandar':
        return tiempoSuscripcion === 'mensual'
          ? t('costo_mensual_estandar')
          : t('costo_anual_estandar');
      case 'basica':
        return tiempoSuscripcion === 'mensual'
          ? t('costo_mensual_basica')
          : t('costo_anual_basica');
      default:
        return '';
    }
  };
  return (
    <div className="shadow-lg bg-white rounded-lg border border-solid border-gray-200 mb-2 flex-grow flex flex-col">
      <div className="p-4">
        <h2 className="text-green-500 mt-0 font-medium">
          {tiempoSuscripcion === 'mensual' ? t('subscripcion_mensual') : t('subscripcion_anual')}
        </h2>
        <p className="text-gray-600 font-medium m-0">
          {tiempoSuscripcion === 'mensual'
            ? t('se_hara_cargo_mensual_recurrente')
            : t('se_hara_cargo_anual_recurrente')}
        </p>
      </div>
      <div className="flex-grow" />
      <div className="bg-blue-200 p-4 rounded-b-lg">
        <div className="flex justify-between">
          <h2 className="m-0 font-medium text-gray-800">{`${t('total')}:`}</h2>
          <div className="text-right">
            <h2 className="m-0 font-bold text-yellow-600">{costoSuscripcion()}</h2>
            <span>{t('impuestos_incluidos')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResumenPago;
