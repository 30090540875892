export const SET_CARGA_SAVE = 'SET_CARGA_SAVE';
export const SET_MODIFICACION_SAVE = 'SET_MODIFICACION_SAVE';
export const RESET_CHECK_SAVE = 'RESET_CHECK_SAVE';

interface SetCargaSaveAction {
  type: typeof SET_CARGA_SAVE;
  payload: boolean;
}

interface SetModificacionSaveAction {
  type: typeof SET_MODIFICACION_SAVE;
  payload: boolean;
}

interface ResetCheckSaveAction {
  type: typeof RESET_CHECK_SAVE;
}

export type CheckSaveActionTypes =
  | SetCargaSaveAction
  | SetModificacionSaveAction
  | ResetCheckSaveAction;
