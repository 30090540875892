import {
  IRXConsultorios,
  ConsultoriosActionTypes,
  SET_CONSULTORIOS,
  SET_CONSULTORIO,
  RESET_CONSULTORIO,
  RESET_CONSULTORIOS,
} from '../actions/consultorios/types';

const initialState = {
  consultorios: [],
  consultorio: {
    tienePacientes: 0,
    idConsultorio: -1,
    perteneceHospital: false,
    label: '',
    clinicaHospital: { id: '', label: '' },
    tipoEstablecimiento: { id: 0, label: '' },
    otroEstablecimiento: '',
    nombreEstablecimiento: '',
    nombre: '',
    correoElectronico: '',
    sitioWeb: '',
    agee: null,
    agem: null,
    agel: null,
    agea: null,
    calle: '',
    num1: '',
    num2: '',
    cp: '',
    telefono1: { telefono: '', extension: '' },
    telefono2: { telefono: '', extension: '' },
    telefono3: { telefono: '', extension: '' },
    logotipo: '',
    porDefecto: false,
    borrado: false,
  },
} as IRXConsultorios;

function Consultorios(state = initialState, action: ConsultoriosActionTypes) {
  switch (action.type) {
    case SET_CONSULTORIOS:
      return {
        ...state,
        consultorios: action.payload,
      };
    case SET_CONSULTORIO:
      return {
        ...state,
        consultorio: action.payload,
      };
    case RESET_CONSULTORIO:
      return {
        ...state,
        consultorio: initialState.consultorio,
      };
    case RESET_CONSULTORIOS:
      return {
        ...state,
        consultorios: initialState.consultorios,
      };
    default:
      return state;
  }
}

export default Consultorios;
