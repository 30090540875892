import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateIcon from '@material-ui/icons/Create';
import { ISeleccionVariablesMC } from './types';
import VariablesCreadas from './VariablesCreadas';
import TodasLasVariables from './TodasLasVariables';
import { GreenButton } from '../../../utils/ComponentesJSX';

function SeleccionVariablesMC({
  arrComponentes,
  setArrComponentes,
  objChecked,
  setObjChecked,
  crearVariable,
}: ISeleccionVariablesMC) {
  const { t } = useTranslation();
  return (
    <div className="p-4 shadow-lg bg-white rounded border border-solid border-gray-200">
      <div className="grid grid-cols-2 gap-2 mb-6">
        <VariablesCreadas arrComponentes={arrComponentes} setArrComponentes={setArrComponentes} />
        <TodasLasVariables
          arrComponentes={arrComponentes}
          setArrComponentes={setArrComponentes}
          setObjChecked={setObjChecked}
          objChecked={objChecked}
        />
      </div>
      <div className="text-right">
        <p className="text-gray-600">{t('_te_falta_una_variable_y_no_esta_disponible_arriba_')}</p>
        <GreenButton
          variant="contained"
          color="inherit"
          startIcon={<CreateIcon />}
          onClick={crearVariable}
        >
          {t('crear_nueva_variable')}
        </GreenButton>
      </div>
    </div>
  );
}

export default SeleccionVariablesMC;
