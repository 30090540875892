import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IMenuPaciente } from './types';

/** Componente que regresa el menú del paciente */
function MenuPaciente({
  anchorEl, handleClose, open, handleLogout,
}: IMenuPaciente) {
  const { t } = useTranslation();
  return (
    <Menu
      id="menu-paciente"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      <MenuItem onClick={handleLogout}>{t('cerrar_sesion')}</MenuItem>
    </Menu>
  );
}

export default MenuPaciente;
