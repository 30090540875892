import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    justifyContent: 'left',
  },
  buttonOrange: {
    justifyContent: 'left',
    color: '#FFFFFF',
  },
  selected: {
    justifyContent: 'left',
    backgroundColor: '#1E467E',
    color: '#FFFFFF',
  },
  selectedOrange: {
    justifyContent: 'left',
    // backgroundColor: '#D97706',
    color: '#FFFFFF',
  },
});

export default useStyles;
