import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DragSourceMonitor, DropTargetMonitor, useDrag, useDrop,
} from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import WarningIcon from '@material-ui/icons/Warning';
import { IMedicamentoRecetaProps } from './types';
import { IItemType, ItemTypes } from '../ListaMedicamentos/types';

function MedicamentoReceta({
  medicamento,
  tipoMedicamento,
  index,
  onMoveMedicamento,
  handleQuitarMedicamento,
  handleEditarMedicamento,
  handleVerDetalleMedicamento,
}: IMedicamentoRecetaProps) {
  const { t } = useTranslation();
  const {
    id,
    tipo,
    nombreDistintivo,
    nombreGenerico,
    medTextoLibre,
    via,
    cantidad,
    unidad,
    frecuencia,
    otraFrecuencia,
    duracion,
    periodo,
    alertas,
  } = medicamento;

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.MEDICAMENTO_RECETA,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: IItemType, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // regresar si se esta hovereando el mismo medicamento
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determinar el rectangulo en pantalla
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Obtener la mitad vertical
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determinar la posición del mouse
      const clientOffset = monitor.getClientOffset();
      // Obtener los pixeles de la parte de arriba
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Solamente hacer el movimiento cuando el mouse cruzo la mitad del rectangulo
      // cuando se arrastra hacia abajo solo mover si el cursor esta debajo del 50%
      // cuando se arrastra hacia arriba solo mejor si el cursor esta arriba del 50%

      // Regresar si se arrastra para abajo
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Regresar si se arrastra para arriba
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // realizar el movimiento, mandando el indice del medicamento que se mueve
      // con el medicamente que se tiene el hover
      onMoveMedicamento(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: tipoMedicamento, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging() as boolean,
    }),
  });

  const titulo = () => {
    let jsx;
    switch (tipo) {
      case 'nombre_generico':
        jsx = <h3 className="font-normal m-0 text-gray-900">{nombreGenerico.label}</h3>;
        break;
      case 'denominacion_distintiva':
        jsx = (
          <>
            <h3 className="font-normal m-0 text-gray-900">{nombreDistintivo.label}</h3>
            <h4 className="font-normal m-0 text-gray-500">{nombreGenerico.label}</h4>
          </>
        );
        break;
      case 'texto_libre':
        jsx = <h3 className="font-normal m-0 text-gray-900">{medTextoLibre}</h3>;
        break;
      default:
        jsx = <h3 className="font-normal m-0 text-gray-900">Sin información</h3>;
        break;
    }
    return jsx;
  };

  const descripcion = () => (
    <p className="text-gray-500">
      <span className="text-gray-900">{via.impresion}</span>
      {` ${cantidad} ${unidad.label} ${frecuencia.label || otraFrecuencia} ${t(
        'farma_por',
      )} ${duracion} ${periodo.label}`}
    </p>
  );

  const quitarMedicamento = () => {
    handleQuitarMedicamento(id);
  };

  drag(drop(ref));
  return (
    <div
      className={`shadow-lg bg-white p-4 pb-6 rounded border border-solid border-gray-200 relative my-4 w-full${
        isDragging ? ' opacity-20' : ''
      }`}
      ref={ref}
      data-handler-id={handlerId}
    >
      <div className="absolute top-0 right-0">
        <IconButton color="primary" onClick={quitarMedicamento}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <div className="pr-9">{titulo()}</div>
      {descripcion()}
      <div className="absolute bottom-0 right-0">
        {!!alertas.alergias.length && (
          <Button
            disabled
            style={{ alignItems: 'end' }}
            startIcon={<WarningIcon fontSize="small" color="inherit" className="text-yellow-500" />}
          >
            <span className="text-red-500">
              (
              {alertas.alergias.length}
              )
            </span>
          </Button>
        )}
        <Button color="primary" onClick={() => handleVerDetalleMedicamento(medicamento)}>
          {t('ver_detalle')}
        </Button>
        <IconButton color="primary" onClick={() => handleEditarMedicamento(medicamento)}>
          <CreateIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}

export default MedicamentoReceta;
