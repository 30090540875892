import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  selected: {
    backgroundColor: '#1E40AF',
    color: '#FFFFFF',
  },
  buttonColor: {
    backgroundColor: '#3B82F6',
    '&:hover': {
      backgroundColor: '#1E467E',
      color: '#FFFFFF',
    },
  },
});

export default useStyles;
