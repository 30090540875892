import { Dispatch, SetStateAction } from 'react';
import { ICatStrEstandar, ICatalogosEstandar, IFechaObj } from '../../../../common/types';
import { IInmunizacion } from '../../Antecedentes/PersonalesNoPatologicos/Inmunizaciones/types';

interface IObjNumero {
  numero: string;
  codigo: string;
}

export interface IDatosPersonales {
  loaded: boolean;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  sexo: string;
  fechaNac: IFechaObj;
  nacionalidad: ICatStrEstandar;
  grupoEtnico: ICatalogosEstandar;
  estadoCivil: ICatalogosEstandar;
  escolaridad: ICatalogosEstandar;
  religion: ICatalogosEstandar;
  otraReligion: string;
  docId: string;
  noPoseeDocId: boolean;
  tipoIdentificacion: ICatalogosEstandar;
  numeroDocumento: string;
  ocupacion: string;
  tipoOcupacion: ICatalogosEstandar;
  tiempoOcupacion: ICatalogosEstandar;
  celular: IObjNumero;
  noTieneCelular: boolean;
  fijo: IObjNumero;
  email: string;
  noTieneEmail: boolean;
  comoEntero: string;
  otroComoEntero: string;
}

export interface IDatosPersonalesProps {
  setActiveSubStep: Dispatch<
  SetStateAction<
  | 'datos-personales'
  | 'datos-ubicacion'
  | 'discapacidades'
  | 'info-emergencia'
  | 'seguros-planes'
  >
  >;
  datosPersonales: IDatosPersonales;
  setDatosPersonales: Dispatch<SetStateAction<IDatosPersonales>>;
  cambiosUsuario: string[];
  datosPersonalesUsuario: IDatosPersonales;
  setDatosPersonalesUsuario: Dispatch<SetStateAction<IDatosPersonales>>;
  setEnGestacion: Dispatch<SetStateAction<'' | 'si' | 'no'>>;
  setInmunizaciones: Dispatch<SetStateAction<IInmunizacion[]>>;
  tipoPaciente: null | 'directo' | 'candidato' | 'contratista' | 'otro';
}

export const datosPersonalesInitial: IDatosPersonales = {
  loaded: false,
  nombre: '',
  primerApellido: '',
  segundoApellido: '',
  sexo: '',
  fechaNac: { dia: '', mes: '', anio: '' },
  nacionalidad: { id: '', label: '' },
  grupoEtnico: { id: 0, label: '' },
  estadoCivil: { id: 0, label: '' },
  escolaridad: { id: 0, label: '' },
  religion: { id: 0, label: '' },
  otraReligion: '',
  docId: '',
  noPoseeDocId: false,
  tipoIdentificacion: { id: 0, label: '' },
  numeroDocumento: '',
  ocupacion: '',
  tipoOcupacion: { id: 0, label: '' },
  tiempoOcupacion: { id: 0, label: '' },
  celular: { numero: '', codigo: '' },
  noTieneCelular: false,
  fijo: { numero: '', codigo: '' },
  email: '',
  noTieneEmail: false,
  comoEntero: '',
  otroComoEntero: '',
};
