/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IDatosSuscripcionProps, IOBJConekta } from './types';
import { getInfoSubscription } from '../../../utils/getCatalogos';
import { setSnackComplete } from '../../../actions/snackbar/types';
import loading from '../../../actions/loading/actions';
import { epochTimeToDiaMesAnioStr } from '../../../common/functions';

function estatusConekta(estatus: string) {
  switch (estatus) {
    case 'in_trial':
      return 'En proceso...';
    case 'active':
      return 'Activa';
    case 'past_due':
      return 'Vencida';
    case 'canceled':
      return 'Cancelada';
    default:
      return 'Verificando';
  }
}

function VerDatosSuscripcion({ customer }: IDatosSuscripcionProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [objConekta, setObjConekta] = useState<IOBJConekta>({
    corporate: false,
    created_at: 0,
    custom_reference: '',
    email: '',
    id: '',
    livemode: false,
    name: '',
    object: '',
    phone: '',
  });

  useEffect(() => {
    if (customer.length) {
      dispatch(loading(true));
      getInfoSubscription(customer)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setObjConekta(result.data);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-general')} ${result.msg} ${result.detail}`,
              }),
            );
          }
          dispatch(loading(false));
        })
        .catch((err) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: err,
            }),
          );
          dispatch(loading(false));
        });
    }
  }, [customer]);

  return (
    <>
      <div className="pb-4 mt-4">
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            history.goBack();
          }}
        >
          {t('regresar')}
        </Button>
      </div>
      <div className="py-4 shadow-lg bg-white rounded-lg border border-solid border-gray-200">
        <div className="container mx-auto w-10/12">
          <div className="flex justify-between items-center py-2 border-0 border-solid border-gray-400 border-b">
            <h3 className="text-gray-800 font-normal m-0">
              {t('registrado_en_medipraxi_desde')}
              : &nbsp;
              <span className="font-medium">
                {epochTimeToDiaMesAnioStr(objConekta.created_at || Date.now())}
              </span>
            </h3>
          </div>
          <div className="flex justify-between items-center py-2 border-0 border-solid border-gray-400 border-b">
            <h3 className="text-gray-800 font-normal m-0">
              {t('nombre')}
              : &nbsp;
              <span className="font-medium">{t(objConekta.name)}</span>
            </h3>
          </div>
          <div className="flex justify-between items-center py-2 border-0 border-solid border-gray-400 border-b">
            <h3 className="text-gray-800 font-normal m-0">
              {t('email')}
              : &nbsp;
              <span className="font-medium">{t(objConekta.email)}</span>
            </h3>
          </div>
          {objConekta.subscription && (
            <>
              <div className="flex justify-between items-center py-2 border-0 border-solid border-gray-400 border-b">
                <h3 className="text-gray-800 font-normal m-0">
                  {t('tipo_de_subscripcion')}
                  : &nbsp;
                  <span className="font-medium">{t(objConekta.subscription.plan_id || '')}</span>
                </h3>
              </div>
              <div className="flex justify-between items-center py-2 border-0 border-solid border-gray-400 border-b">
                <h3 className="text-gray-800 font-normal m-0">
                  {t('suscrito_a_medipraxi_desde')}
                  : &nbsp;
                  <span className="font-medium">
                    {epochTimeToDiaMesAnioStr(objConekta.subscription.created_at)}
                  </span>
                </h3>
              </div>
              <div className="flex justify-between items-center py-2 border-0 border-solid border-gray-400 border-b">
                <h3 className="text-gray-800 font-normal m-0">
                  {t('estatus_de_suscripcion')}
                  : &nbsp;
                  <span className="font-medium">
                    {estatusConekta(objConekta.subscription.status)}
                  </span>
                </h3>
              </div>
              <div className="flex justify-between items-center py-2 border-0 border-solid border-gray-400 border-b">
                <h3 className="text-gray-800 font-normal m-0">
                  {t('inicio_del_ciclo_de_facturacion')}
                  : &nbsp;
                  <span className="font-medium">
                    {epochTimeToDiaMesAnioStr(objConekta.subscription.billing_cycle_start)}
                  </span>
                </h3>
              </div>
              <div className="flex justify-between items-center py-2 border-0 border-solid border-gray-400 border-b">
                <h3 className="text-gray-800 font-normal m-0">
                  {t('fin_del_ciclo_de_facturacion')}
                  : &nbsp;
                  <span className="font-medium">
                    {epochTimeToDiaMesAnioStr(objConekta.subscription.billing_cycle_end)}
                  </span>
                </h3>
              </div>
              {objConekta.subscription.canceled_at && (
                <div className="flex justify-between items-center py-2 border-0 border-solid border-gray-400 border-b">
                  <h3 className="text-gray-800 font-normal m-0">
                    {t('cancelada_el')}
                    : &nbsp;
                    <span className="font-medium">
                      {epochTimeToDiaMesAnioStr(objConekta.subscription.canceled_at)}
                    </span>
                  </h3>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default VerDatosSuscripcion;
