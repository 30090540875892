import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Button, Accordion, AccordionDetails, AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from '../../../../../../../common/styles';
import ListaDatosTab from '../ListaDatosTabs';
import { ITabExposicionPsicosocialProps } from './types';
import { IObjComentariosFecha } from '../../../types';

function TabExposicionPsicosocial({
  setOpenModalRiesgoPsicosocial,
  setNombreModal,
  setIdTabSeleccionado,
  objExposicionRiesgo,
  setDatoActualizar,
  setInxArraySeleccionado,
  setDeleteAlert,
}: ITabExposicionPsicosocialProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [accCargas, setAccCargas] = useState<boolean | undefined>(undefined);
  const [accFalta, setAccFalta] = useState<boolean | undefined>(undefined);
  const [accJornadas, setAccJornadas] = useState<boolean | undefined>(undefined);
  const [accRotacion, setAccRotacion] = useState<boolean | undefined>(undefined);
  const [accPeriodos, setAccPeriodos] = useState<boolean | undefined>(undefined);
  const [accInterferencia, setAccInterferencia] = useState<boolean | undefined>(undefined);
  const [accLiderazgo, setAccLiderazgo] = useState<boolean | undefined>(undefined);
  const [accHostigamiento, setAccHostigamiento] = useState<boolean | undefined>(undefined);
  const [accAcoso, setAccAcoso] = useState<boolean | undefined>(undefined);
  const [accMalos, setAccMalos] = useState<boolean | undefined>(undefined);
  const [accOtro, setAccOtro] = useState<boolean | undefined>(undefined);
  return (
    <div className="w-full">
      {/* es la parte que corresponde a temperaturas altas */}
      <Accordion expanded={accCargas} onChange={() => setAccCargas(!accCargas)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_cargas')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_cargas'));
                  setIdTabSeleccionado(49);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayCargasTrabajoExcesivas.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayCargasTrabajoExcesivas.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_cargas')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(49);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_cargas'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(49);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a temperaturas bajas */}
      <Accordion expanded={accFalta} onChange={() => setAccFalta(!accFalta)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_falta')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_falta'));
                  setIdTabSeleccionado(50);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayFaltaControlTrabajo.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayFaltaControlTrabajo.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_falta')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(50);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_falta'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(50);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion ionizante */}
      <Accordion expanded={accJornadas} onChange={() => setAccJornadas(!accJornadas)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_jornadas')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_jornadas'));
                  setIdTabSeleccionado(51);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayJornadasTrabajoSuperiores.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayJornadasTrabajoSuperiores.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_jornadas')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(51);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_jornadas'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(51);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion no ionizante */}
      <Accordion expanded={accRotacion} onChange={() => setAccRotacion(!accRotacion)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_rotacion')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_rotacion'));
                  setIdTabSeleccionado(52);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayRotacionTurnos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayRotacionTurnos.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_rotacion')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(52);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_rotacion'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(52);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ventilacion */}
      <Accordion expanded={accPeriodos} onChange={() => setAccPeriodos(!accPeriodos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_periodos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_periodos'));
                  setIdTabSeleccionado(53);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayPeriodosCortosRecuperacion.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayPeriodosCortosRecuperacion.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_periodos')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(53);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_periodos'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(53);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a iluminacion */}
      <Accordion
        expanded={accInterferencia}
        onChange={() => setAccInterferencia(!accInterferencia)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_interferencia')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_interferencia'));
                  setIdTabSeleccionado(54);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayInterferenciaRelacion.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayInterferenciaRelacion.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_interferencia')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(54);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_interferencia'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(54);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ruido */}
      <Accordion expanded={accLiderazgo} onChange={() => setAccLiderazgo(!accLiderazgo)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_liderazgo')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_liderazgo'));
                  setIdTabSeleccionado(55);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayLiderazgoNegativo.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayLiderazgoNegativo.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_liderazgo')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(55);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_liderazgo'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(55);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a vibracion */}
      <Accordion
        expanded={accHostigamiento}
        onChange={() => setAccHostigamiento(!accHostigamiento)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_hostigamiento')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_hostigamiento'));
                  setIdTabSeleccionado(56);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayHostigamiento.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayHostigamiento.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_hostigamiento')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(56);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_hostigamiento'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(56);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a fluido electrico */}
      <Accordion expanded={accAcoso} onChange={() => setAccAcoso(!accAcoso)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_acoso')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_acoso'));
                  setIdTabSeleccionado(57);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayAcoso.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayAcoso.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_acoso')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(57);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_acoso'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(57);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a fluido electrico */}
      <Accordion expanded={accMalos} onChange={() => setAccMalos(!accMalos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_malos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_malos'));
                  setIdTabSeleccionado(58);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayMalosTratos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayMalosTratos.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_malos')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(58);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_malos'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(58);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a otro */}
      <Accordion expanded={accOtro} onChange={() => setAccOtro(!accOtro)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_psicosocial_otros')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoPsicosocial(true);
                  setNombreModal(t('antecedentes_laborales_psicosocial_otros'));
                  setIdTabSeleccionado(59);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayOtro.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayOtro.map(
            (valPruebaLista: IObjComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_psicosocial_otros')}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(59);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_psicosocial_otros'));
                }}
                openModalActualizar={() => setOpenModalRiesgoPsicosocial(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(59);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TabExposicionPsicosocial;
