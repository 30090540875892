import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    minWidth: 'auto',
    backgroundColor: '#008EDE',
    color: 'white',
    borderRadius: '5px 5px 0 0',
    borderLeft: '1px solid white',
    borderRight: '1px solid white',
    textTransform: 'none',
  },
  selected: {
    backgroundColor: '#1E467E',
  },
  background: {
    padding: '10px 0 0 10px',
    marginBottom: '1px',
  },
  buttonSelected: {
    backgroundColor: '#1E40AF',
  },
});

export default useStyles;
