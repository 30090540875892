import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

/**
 * Modulo de configuracion para la traduccion de idiomas
 */
i18n
  /**
   * Usarr Backend para cargar por defecto los locales definidos dentro de la carpeta
   * public/locales/
   */
  .use(Backend)
  /**
   * User el laguage detector para determinar el lenguage basado en las cookies
   * el navegador y el sistema operativo
   */
  .use(LanguageDetector)
  /**
   * pasa i18n hacia to react-i18next
   */
  .use(initReactI18next)
  .init({
    /** Lenguage por defecto en caso de un error es el español */
    fallbackLng: 'es',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  });

export default i18n;
