import React from 'react';
import {
  createStyles, Theme, withStyles, WithStyles,
} from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
  Grid,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertDialogProps from './types';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#111827',
  },
});

const Transition = React.forwardRef((
  // eslint-disable-next-line react/require-default-props
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string | undefined) => void;
}

const MuiDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Grid container alignItems="center" justify="center">
        <Typography variant="h6" color="primary">
          {children}
        </Typography>
      </Grid>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

function SeccionDialog(props: IAlertDialogProps) {
  const {
    open, titulo, descripcion, callBackClose,
  } = props;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="alert-dialog-titulo"
      aria-describedby="alert-dialo-descripcion"
    >
      <MuiDialogTitle id="customized-dialog-title" onClose={callBackClose}>
        {titulo}
      </MuiDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-descripcion">
          <Typography variant="body1">{descripcion}</Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default SeccionDialog;
