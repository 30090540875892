import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IAlergiasProps } from './types';

function ListaAlergias({
  alergiaObj,
  index,
  setEditAlert,
  setDeleteAlert,
  idBD,
  setIdActualizar,
  setIndexToDelete,
  editAlergia,
  gradoForm,
  alergiaForm,
}: IAlergiasProps) {
  const { t } = useTranslation();
  const { alergia, grado, idBD: indiceBD } = alergiaObj;
  /* funcion que nos cambia el color del borde de la alergia dependiendo del grado */
  const borderAlergia = () => {
    let border = 'rounded p-4 w-full';
    switch (grado) {
      case 'grado_1':
      case 'grado_2':
        border = 'border-0 border-solid border-yellow-300 rounded border-l-8 p-4 pr-5 w-full';
        break;
      case 'grado_3':
        border = 'border-0 border-solid border-yellow-600 rounded border-l-8 p-4 pr-5 w-full';
        break;
      case 'grado_4':
      case 'grado_5':
        border = 'border-0 border-solid border-red-600 rounded border-l-8 p-4 pr-5 w-full';
        break;
      default:
        break;
    }
    return border;
  };
  /* funcion que nos cambia el color del texto grado dependiendo del grado de este */
  const colorAlergia = () => {
    let color = '';
    switch (grado) {
      case 'grado_1':
      case 'grado_2':
        color = 'font-normal m-0 text-yellow-300';
        break;
      case 'grado_3':
        color = 'font-normal m-0 text-yellow-600';
        break;
      case 'grado_4':
      case 'grado_5':
        color = 'font-normal m-0 text-red-600';
        break;
      default:
        color = 'font-normal m-0';
        break;
    }
    return color;
  };
  /* funcion que hace el botón editar, aqui verificamos que en caso de existir datos en el formulario
  se debe guardar el indice a actualizar y prende el modal de actualizar, en caso que no haya datos
  en el formulario se manda a llamar a la funcion para editar (llenar el formulario con los datos a editar)
  directamente */
  const handleEditAlergia = () => {
    if (gradoForm || alergiaForm.id) {
      setIdActualizar(index);
      setEditAlert(true);
    } else {
      setIdActualizar(index);
      editAlergia(index);
    }
  };
  /* funcion que hace el botón de eliminar, manda a guardar el indice a borrar y prende el modal a eliminar */
  const handleDeleteAlergia = () => {
    setIndexToDelete(index);
    setDeleteAlert(true);
  };
  /* para deshabilitar el botón para eliminar si este dato se esta editando en ese momento se utiliza el
  indiceBD que se hace con la funcion actualizar, en dado caso que sean el mismo,
  esté botón debe estar deshabilitado */
  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <div className={borderAlergia()}>
        <div className="flex justify-between">
          <h3 className="m-0 overflow-ellipsis overflow-hidden text-left">{alergia.label}</h3>
          <h4 className={colorAlergia()}>{t(grado)}</h4>
        </div>
      </div>
      <div className="min-w-max self-center">
        <IconButton aria-label="edit" color="primary" onClick={handleEditAlergia}>
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={handleDeleteAlergia}
          disabled={idBD === indiceBD}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ListaAlergias;
