import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import dayjs from 'dayjs';
import { IListaIncapacidadesProps } from './types';

dayjs.locale('es');

const Incapacidad = (props: IListaIncapacidadesProps) => {
  const {
    incapacidad,
    handleEditIncapacidad,
    setDeleteAlert,
    setEditAlert,
    setIdActualizar,
    setIdEliminar,
  } = props;
  const { t } = useTranslation();

  const handleEdit = () => {
    if (!incapacidad.folio) {
      handleEditIncapacidad(incapacidad.id);
    } else {
      setIdActualizar(incapacidad.id);
      setEditAlert(true);
    }
  };
  const handleDelete = () => {
    setIdEliminar(incapacidad.id);
    setDeleteAlert(true);
  };

  const obtenerFecha = (dia: string, mes: string, anio: string) => {
    const formatString = dia.length === 0 ? 'MMM-YYYY' : 'DD-MMM-YYYY';
    return dayjs(`${anio}-${mes}-${dia.length === 0 ? '1' : dia}`).format(formatString);
  };

  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
        <h3 className="font-normal text-gray-500 m-0">
          {`${incapacidad.tipo.toUpperCase()} - ${incapacidad.folio}`}
        </h3>
        <h4 className="font-normal text-gray-500 m-0">
          {`${t('fecha')}: ${obtenerFecha(
            incapacidad.fecha.dia,
            incapacidad.fecha.mes,
            incapacidad.fecha.anio,
          )}`}
        </h4>
        <h4 className="font-normal text-gray-500 m-0">
          {`${t('incapacidades_tipo_incidencia')}: ${t(incapacidad.tipoIncidencia.label)}`}
        </h4>
        <h4 className="font-normal text-gray-500 m-0">
          {`${t('incapacidades_dias')}: ${incapacidad.diasIncapacidad}`}
        </h4>
        <h4 className="font-normal text-gray-500 m-0">
          {`${t('fecha_inicio')}: ${obtenerFecha(
            incapacidad.fechaInicio.dia,
            incapacidad.fechaInicio.mes,
            incapacidad.fechaInicio.anio,
          )}`}
        </h4>
        <h4 className="font-normal text-gray-500 m-0">
          {`${t('incapacidades_ramo_seguro')}: ${incapacidad.ramoSeguro.label}`}
        </h4>
        <h4 className="font-normal text-gray-500 m-0">
          {`${t('incapacidades_tipo_riesgo')}: ${incapacidad.tipoRiesgo.label}`}
        </h4>
        <h4 className="font-normal text-gray-500 m-0">
          {`${t('incapacidades_secuela')}: ${incapacidad.secuela.label}`}
        </h4>
        <h4 className="font-normal text-gray-500 m-0">
          {`${t('incapacidades_control_incapacidad')}: ${incapacidad.controlIncapacidad.label}`}
        </h4>
        <h4 className="font-normal text-gray-500 m-0">
          {`${t('incapacidades_accidente')}: ${incapacidad.accidenteLaboral.label}`}
        </h4>
      </div>
      <div className="min-w-max self-center">
        <IconButton aria-label="edit" color="primary" onClick={() => handleEdit()}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" color="primary" onClick={() => handleDelete()}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Incapacidad;
