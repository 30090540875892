import dayjs from 'dayjs';

export const constDia = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];

export const constMes = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const constAnio = () => {
  let anioActual = parseInt(dayjs().format('YYYY'), 10);
  const anios = [];
  let i = 101;
  while (i) {
    anios.push(anioActual);
    anioActual -= 1;
    i -= 1;
  }
  return anios;
};

export const aniosFuturos = (aFuturos: number) => {
  let anioActual = parseInt(dayjs().format('YYYY'), 10);
  if (aFuturos > 0) {
    const anios = [];
    let i = aFuturos + 1;
    while (i) {
      anios.push(anioActual);
      anioActual += 1;
      i -= 1;
    }
    return anios;
  }
  return [anioActual];
};

export const aniosPasados = (aPasados: number) => {
  let anioActual = parseInt(dayjs().format('YYYY'), 10);
  if (aPasados > 0) {
    const anios = [];
    let i = aPasados + 1;
    while (i) {
      anios.push(anioActual);
      anioActual -= 1;
      i -= 1;
    }
    return anios;
  }
  return [anioActual];
};

export const constAnioGestacion = () => {
  let anioActual = parseInt(dayjs().format('YYYY'), 10);
  const anios = [];
  let i = 2;
  while (i) {
    anios.push(anioActual);
    anioActual -= 1;
    i -= 1;
  }
  return anios;
};

export const constAnioPoliza = () => {
  let anioActual = parseInt(dayjs().format('YYYY'), 10);
  const anios = [];
  let i = 5;
  while (i) {
    anios.push(anioActual);
    anioActual += 1;
    i -= 1;
  }
  return anios;
};

export const constAnioBuilder = () => {
  let anioActual = parseInt(dayjs().format('YYYY'), 10);
  anioActual += 6;
  const anios = [];
  let i = 12;
  while (i) {
    anios.push(anioActual);
    anioActual -= 1;
    i -= 1;
  }
  return anios;
};
