import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@material-ui/core';
import CardAnual from './CardAnual';
import CardMensual from './CardMensual';
import { ITipoSuscripcionProps } from './types';

function TipoSuscripcion({
  tipoSuscripcion,
  tiempoSuscripcion,
  setTiempoSuscripcion,
}: ITipoSuscripcionProps) {
  const { t } = useTranslation();

  const handleChangeTiempoSuscripcion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setTiempoSuscripcion(val);
  };

  const handleClickButtonSuscripcion = (tipo: 'A' | 'M') => {
    if (tipo === 'A') {
      setTiempoSuscripcion('anual');
    } else {
      setTiempoSuscripcion('mensual');
    }
  };

  return (
    <div className="p-4 shadow-lg bg-white rounded-lg border border-solid border-gray-200 mb-2">
      <h2 className="mt-0 font-medium text-gray-600">{`${t('tipo_de_suscripcion')}:`}</h2>
      <RadioGroup
        aria-label="subscripcion-mensual"
        name="mensual"
        value={tiempoSuscripcion}
        onChange={handleChangeTiempoSuscripcion}
      >
        <div className="grid grid-cols-2 gap-4">
          <CardMensual
            tiempoSuscripcion={tiempoSuscripcion}
            tipoSuscripcion={tipoSuscripcion}
            handleClickButtonSuscripcion={handleClickButtonSuscripcion}
          />
          <CardAnual
            tiempoSuscripcion={tiempoSuscripcion}
            tipoSuscripcion={tipoSuscripcion}
            handleClickButtonSuscripcion={handleClickButtonSuscripcion}
          />
        </div>
      </RadioGroup>
    </div>
  );
}

export default TipoSuscripcion;
