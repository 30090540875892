import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@material-ui/core';
import { IBuilderRespuestaBreveProps } from './types';
import { capitalize } from '../../../../common/functions';
import { IMCPACompleto } from '../../../TabsPaciente/MCPA/types';

/** Componente de respuesta breve */
function RespuestaBreve({ componenteRespuesta, setMCPA, id }: IBuilderRespuestaBreveProps) {
  const { t } = useTranslation();

  const otraUnidadOLabel = () => {
    if (
      componenteRespuesta.constructor.respuestaBreve!.unidad.label !== 'OTRA'
      && componenteRespuesta.constructor.respuestaBreve!.unidad.label !== 'SIN UNIDAD'
    ) {
      return componenteRespuesta.constructor.respuestaBreve!.unidad.label;
    }
    if (componenteRespuesta.constructor.respuestaBreve!.unidad.label === 'SIN UNIDAD') {
      return '';
    }
    return componenteRespuesta.constructor.respuestaBreve!.otraUnidad;
  };

  const handleChangeRespuesta = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setMCPA((preMCPA: IMCPACompleto) => ({
      ...preMCPA,
      componentes: preMCPA.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: { ...preComp.constructorComponente.constructor, valor: val },
              valores: {
                ...preComp.constructorComponente.valores,
                valor: val,
                respuestaBreve: otraUnidadOLabel(),
              },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  const handleChangeComentarios = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setMCPA((preMCPA: IMCPACompleto) => ({
      ...preMCPA,
      componentes: preMCPA.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: { ...preComp.constructorComponente.constructor, comentarios: val },
              valores: { ...preComp.constructorComponente.valores, textoLibre: val },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 grid grid-cols-2 gap-4">
      <div className="grid grid-cols-2 gap-x-2 mb-3.5">
        <h3 className="mt-0 mb-2 self-end font-medium text-gray-600 overflow-ellipsis overflow-hidden">
          {capitalize(componenteRespuesta.datosComponente.titulo)}
        </h3>
        <div className="text-gray-500 row-span-2">
          {componenteRespuesta.datosComponente.descripcion
            ? `* ${componenteRespuesta.datosComponente.descripcion}`
            : ''}
        </div>
        <TextField
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{otraUnidadOLabel()}</InputAdornment>,
            inputProps: { autoComplete: 'off', maxLength: 10 },
          }}
          value={componenteRespuesta.constructor.valor}
          onChange={handleChangeRespuesta}
        />
      </div>
      <TextField
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        label={t('comentarios')}
        inputProps={{ autoComplete: 'off', maxLength: 300 }}
        onChange={handleChangeComentarios}
        value={componenteRespuesta.constructor.comentarios}
      />
    </div>
  );
}

export default React.memo(RespuestaBreve);
