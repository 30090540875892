import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Slider } from '@material-ui/core';
import { IViewEscalaLineal, IObjValues } from './types';
import { capitalize } from '../../../../common/functions';

function ViewEscalaLineal({ componenteEscalaLineal }: IViewEscalaLineal) {
  const { t } = useTranslation();
  const [marks, setMarks] = useState<IObjValues[]>([]);

  useEffect(() => {
    if (
      componenteEscalaLineal.constructor.escalaLineal!.valor.final
        - componenteEscalaLineal.constructor.escalaLineal!.valor.inicial
      <= 30
    ) {
      const arrayMarks = [];
      for (
        let step = componenteEscalaLineal.constructor.escalaLineal!.valor.inicial;
        step <= componenteEscalaLineal.constructor.escalaLineal!.valor.final;
        step += 1
      ) {
        arrayMarks.push({ value: step, label: step.toString() });
      }
      setMarks(arrayMarks);
    }
  }, [componenteEscalaLineal.constructor.escalaLineal!.valor]);

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="flex flex-wrap mb-12">
        <h3 className="w-full mt-0 mb-0 mr-6 text-gray-600 font-medium">
          {capitalize(componenteEscalaLineal.datosComponente.titulo)}
        </h3>
        <div className="text-gray-500 w-full">
          {componenteEscalaLineal.datosComponente.descripcion
            ? `* ${componenteEscalaLineal.datosComponente.descripcion}`
            : ''}
        </div>
      </div>
      <div className="px-28">
        <Slider
          value={
            parseInt(componenteEscalaLineal.constructor.valor, 10)
            || componenteEscalaLineal.constructor.escalaLineal!.valor.inicial
          }
          aria-labelledby="escala-lineal"
          valueLabelDisplay="on"
          step={1}
          min={componenteEscalaLineal.constructor.escalaLineal!.valor.inicial}
          max={componenteEscalaLineal.constructor.escalaLineal!.valor.final}
          marks={
            componenteEscalaLineal.constructor.escalaLineal!.valor.final
              - componenteEscalaLineal.constructor.escalaLineal!.valor.inicial
            <= 30
              ? marks
              : true
          }
          disabled
        />
      </div>
      <div className="px-24 flex justify-between mb-4">
        <div className="font-bold text-left">
          {componenteEscalaLineal.constructor.escalaLineal!.escala.inicial}
        </div>
        <div className="font-bold text-right">
          {componenteEscalaLineal.constructor.escalaLineal!.escala.final}
        </div>
      </div>
      <TextField
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        disabled
        label={t('comentarios')}
        inputProps={{ autoComplete: 'off', maxLength: 500 }}
        value={componenteEscalaLineal.constructor.comentarios}
      />
    </div>
  );
}

export default ViewEscalaLineal;
