import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { capitalize } from '../../../../common/functions';
import { IBuilderEscalaLinealProps, IObjValues } from './types';
import { IMCPACompleto } from '../../../TabsPaciente/MCPA/types';

function BuilderEscalaLineal({ componenteEscalaLineal, setMCPA, id }: IBuilderEscalaLinealProps) {
  const { t } = useTranslation();
  const [marks, setMarks] = useState<IObjValues[]>([]);

  const handleChangeSlider = (event: any, newValue: number | number[]) => {
    const val = newValue as number;
    if (Number(componenteEscalaLineal.constructor.valor) !== val) {
      setMCPA((preMCPA: IMCPACompleto) => ({
        ...preMCPA,
        componentes: preMCPA.componentes.map((preComp) => {
          if (preComp.id === id) {
            return {
              ...preComp,
              constructorComponente: {
                ...preComp.constructorComponente,
                constructor: { ...preComp.constructorComponente.constructor, valor: String(val) },
                valores: { ...preComp.constructorComponente.valores, valor: String(val) },
              },
            };
          }
          return preComp;
        }),
      }));
    }
  };

  const handleChangeComentarios = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setMCPA((preMCPA: IMCPACompleto) => ({
      ...preMCPA,
      componentes: preMCPA.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: { ...preComp.constructorComponente.constructor, comentarios: val },
              valores: { ...preComp.constructorComponente.valores, textoLibre: val },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  useEffect(() => {
    if (
      componenteEscalaLineal.constructor.escalaLineal!.valor.final
        - componenteEscalaLineal.constructor.escalaLineal!.valor.inicial
      <= 30
    ) {
      const arrayMarks = [];
      for (
        let step = componenteEscalaLineal.constructor.escalaLineal!.valor.inicial;
        step <= componenteEscalaLineal.constructor.escalaLineal!.valor.final;
        step += 1
      ) {
        arrayMarks.push({ value: step, label: step.toString() });
      }
      setMarks(arrayMarks);
    }
  }, []);

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="flex flex-wrap mb-12">
        <h3 className="w-full mt-0 mb-0 mr-6 text-gray-600 font-medium">
          {capitalize(componenteEscalaLineal.datosComponente.titulo)}
        </h3>
        <div className="text-gray-500 w-full">
          {componenteEscalaLineal.datosComponente.descripcion
            ? `* ${componenteEscalaLineal.datosComponente.descripcion}`
            : ''}
        </div>
      </div>
      <div className="px-28">
        <Slider
          value={
            parseInt(componenteEscalaLineal.constructor.valor, 10)
            || componenteEscalaLineal.constructor.escalaLineal!.valor.inicial
          }
          aria-labelledby="escala-lineal"
          valueLabelDisplay="on"
          step={1}
          min={componenteEscalaLineal.constructor.escalaLineal!.valor.inicial}
          max={componenteEscalaLineal.constructor.escalaLineal!.valor.final}
          marks={
            componenteEscalaLineal.constructor.escalaLineal!.valor.final
              - componenteEscalaLineal.constructor.escalaLineal!.valor.inicial
            <= 30
              ? marks
              : true
          }
          onChange={handleChangeSlider}
        />
      </div>
      <div className="px-24 flex justify-between mb-4">
        <div className="font-bold text-left">
          {componenteEscalaLineal.constructor.escalaLineal!.escala.inicial}
        </div>
        <div className="font-bold text-right">
          {componenteEscalaLineal.constructor.escalaLineal!.escala.final}
        </div>
      </div>
      <TextField
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        label={t('comentarios')}
        inputProps={{ autoComplete: 'off', maxLength: 500 }}
        value={componenteEscalaLineal.constructor.comentarios}
        onChange={handleChangeComentarios}
      />
    </div>
  );
}

export default React.memo(BuilderEscalaLineal);
