import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { getToken, getPronosticosEnfermedad } from '../../../../../../utils/commonUrl';
import useStyles from './styles';
import { IListaPronosticoProps, IListaElementoProps } from './types';
import { RootState } from '../../../../../../store';
import DeleteDialog from '../../../../../../components/DeleteDialog';
import ViewComentarios from '../../../../../../components/Comentarios/view';
import ModalPronostico from './ModalPronostico/index';
import { setSnackComplete } from '../../../../../../actions/snackbar/types';

require('dayjs/locale/es');

function ListaPronosticos(props: IListaPronosticoProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    diagnostico,
    radioProcedimiento,
    groupCheck,
    secuelaTratamiento,
    secuelaEspontanea,
    causa,
    cronica,
    otro,
    terapeuticas,
    exacerbaciones,
    remisiones,
    recaidas,
    fecha,
    comentarios,
    fechaCreacion,
    newData,
    setNewData,
    arrayData,
    deleteDiagnostico,
    setInxUpdate,
    inx,
  } = props;
  // cierre del modal
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [arrayModal, setArrayModal] = useState<IListaElementoProps[]>([]);
  const [petInfo, setPetInfo] = useState<boolean>(false);
  const [openPronosticos, setOpenPronosticos] = useState<boolean>(false);

  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
    setInxUpdate(-1);
  };

  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };

  const editDiagnostico = () => {
    const datoToLoad = { ...arrayData[inx], fechaCreacion: dayjs().toString() };
    setEditAlert(false);
    setNewData(datoToLoad);
    setInxUpdate(inx);
  };

  const handleEditDiagnostico = () => {
    if (newData.diagnostico.idCat !== 0) {
      setEditAlert(true);
    } else {
      editDiagnostico();
      setInxUpdate(inx);
    }
  };

  const handleDeleteDiagnostico = () => {
    setInxUpdate(inx);
    setDeleteAlert(true);
  };

  const handleFindData = (idCat: string) => {
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('d-p-plan-pron-find'),
      }),
    );
    const token = getToken();
    fetch(getPronosticosEnfermedad(idMedico, idPaciente, idConsultorio, idCat), {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200 && result.data && result.data.length > 0) {
          setArrayModal(result.data);
        } else if (result.code === 200 && result.data && result.data.length === 0) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'warning',
              mensaje: t('d-p-plan-pron-find-warning'),
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('d-p-plan-pron-find-warning'),
            }),
          );
        }
        setPetInfo(true);
      })
      .catch(() => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: t('d-p-plan-pron-find-error'),
          }),
        );
        setArrayModal([]);
        setPetInfo(true);
      });
  };

  const selectColor = () => {
    let colorStyle = 'font-normal mt-4 mr-4 absolute top-0 right-0 text-gray-500';
    if (
      radioProcedimiento === 'd-p-plan-pron-reservado'
      || radioProcedimiento === 'd-p-plan-pron-malo'
    ) {
      colorStyle = 'font-normal mt-4 mr-4 absolute top-0 right-0 text-red-500';
    } else if (radioProcedimiento === 'd-p-plan-pron-regular') {
      colorStyle = 'font-normal mt-4 mr-4 absolute top-0 right-0 text-yellow-500';
    }
    return colorStyle;
  };

  const selectMarginColor = () => {
    let colorStyle = 'border-0 border-solid border-gray-500 rounded border-l-8 p-4 w-full';
    if (
      radioProcedimiento === 'd-p-plan-pron-reservado'
      || radioProcedimiento === 'd-p-plan-pron-malo'
    ) {
      colorStyle = 'border-0 border-solid border-red-500 rounded border-l-8 p-4 w-full';
    } else if (radioProcedimiento === 'd-p-plan-pron-regular') {
      colorStyle = 'border-0 border-solid border-yellow-500 rounded border-l-8 p-4 w-full';
    }
    return colorStyle;
  };

  const formatDate = () => {
    const strDate = `
      ${fecha.anio}-
      ${fecha.mes.length === 2 ? fecha.mes : `0${fecha.mes}`}
      ${fecha.dia.length === 2 ? fecha.dia : `0${fecha.dia}`}`;
    return strDate;
  };

  useEffect(() => {
    if (petInfo && arrayModal.length > 0) {
      setOpenPronosticos(true);
    }
  }, [arrayModal, petInfo]);

  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pnp-historias-eliminar')}
        callBackAceptar={() => {
          deleteDiagnostico();
          setDeleteAlert(false);
        }}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_este_diagnostico_se_borrara_el_que_estas_creando')}
        callBackAceptar={editDiagnostico}
        callBackClose={handleEditAlertClose}
      />
      <ModalPronostico
        open={openPronosticos}
        callBackClose={() => {
          setOpenPronosticos(false);
          setPetInfo(false);
        }}
        arrayData={arrayModal}
      />
      <h4 className={selectColor()}>{`${t('pronostico')}: ${t(radioProcedimiento)}`}</h4>
      <div className="mb-2 mr-4 absolute bottom-0 right-0">
        <Button
          color="primary"
          size="small"
          className={classes.noTextTranform}
          onClick={() => handleFindData(diagnostico.idCat.toString())}
        >
          {t('d-p-plan-pron-ver')}
        </Button>
      </div>
      <div className={selectMarginColor()}>
        <h2 className="m-0 overflow-ellipsis overflow-hidden font-normal">{diagnostico.label}</h2>
        <div className="flex flex-col">
          <h4 className="font-normal text-gray-500 m-0 mt-2">
            {`${t('d-p-plan-pron-fecha')}: ${dayjs(fechaCreacion).format('DD-MMM-YYYY')}`}
          </h4>
          {fecha.dia.length > 0 && fecha.mes.length > 0 && fecha.anio.length > 0 ? (
            <h4 className="font-normal text-gray-900 m-0 mt-2">
              {`${t('d-p-plan-pron-terminacion')}: ${dayjs(formatDate()).format('DD-MMM-YYYY')}.`}
            </h4>
          ) : null}
          <h4 className="font-normal text-gray-900 m-0 mt-2">{`${t('d-p-plan-pron-curso')}:`}</h4>
          {groupCheck.check1 ? (
            <>
              <div className="flex flex-row items-center mt-2">
                <CheckIcon />
                <h4 className="font-normal text-gray-900 m-0 ml-2">
                  {`${t('d-p-plan-pron-curara')}.`}
                </h4>
              </div>
              {secuelaTratamiento.radio === 'N' ? (
                <h4 className="font-normal text-gray-900 m-0 mt-2">
                  {`${t('d-p-plan-pron-secuela2')}: ${t('no')}`}
                </h4>
              ) : (
                <h4 className="font-normal text-gray-900 m-0 mt-2">
                  {`${t('d-p-plan-pron-secuela2')}: ${secuelaTratamiento.area.label}. ${
                    secuelaTratamiento.tipo
                  }`}
                </h4>
              )}
            </>
          ) : null}
          {groupCheck.check2 ? (
            <>
              <div className="flex flex-row items-center mt-2">
                <CheckIcon />
                <h4 className="font-normal text-gray-900 m-0 ml-2">
                  {`${t('d-p-plan-pron-espontaneamente')}.`}
                </h4>
              </div>
              {secuelaEspontanea.radio === 'N' ? (
                <h4 className="font-normal text-gray-900 m-0 mt-2">
                  {`${t('d-p-plan-pron-secuela2')}: ${t('no')}`}
                </h4>
              ) : (
                <h4 className="font-normal text-gray-900 m-0 mt-2">
                  {`${t('d-p-plan-pron-secuela2')}: ${secuelaEspontanea.area.label}. ${
                    secuelaEspontanea.tipo
                  }`}
                </h4>
              )}
            </>
          ) : null}
          {groupCheck.check6 ? (
            <>
              <div className="max-h-24 mt-2 w-full">
                <h4 className="font-normal text-gray-900 m-0 mt-2">
                  {t('d-p-plan-pron-terapeuticas')}
                </h4>
                {terapeuticas.blocks.length > 0 ? (
                  <div>
                    <ViewComentarios comentarios={terapeuticas} />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {groupCheck.check7 ? (
            <>
              <div className="max-h-24 mt-2 w-full">
                <h4 className="font-normal text-gray-900 m-0 mt-2">
                  {t('d-p-plan-pron-exacerbaciones')}
                </h4>
                {exacerbaciones.blocks.length > 0 ? (
                  <div>
                    <ViewComentarios comentarios={exacerbaciones} />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {groupCheck.check8 ? (
            <>
              <div className="max-h-24 mt-2 w-full">
                <h4 className="font-normal text-gray-900 m-0 mt-2">
                  {t('d-p-plan-pron-remisiones')}
                </h4>
                {remisiones.blocks.length > 0 ? (
                  <div>
                    <ViewComentarios comentarios={remisiones} />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {groupCheck.check9 ? (
            <>
              <div className="max-h-24 mt-2 w-full">
                <h4 className="font-normal text-gray-900 m-0 mt-2">
                  {t('d-p-plan-pron-recaidas')}
                </h4>
                {recaidas.blocks.length > 0 ? (
                  <div>
                    <ViewComentarios comentarios={recaidas} />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {groupCheck.check3 ? (
            <>
              <div className="max-h-24 mt-2 w-full">
                <h4 className="font-normal text-gray-900 m-0 mt-2">{t('d-p-plan-pron-causa')}</h4>
                {causa.blocks.length > 0 ? (
                  <div>
                    <ViewComentarios comentarios={causa} />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {groupCheck.check4 ? (
            <>
              <div className="max-h-24 mt-2 w-full">
                <h4 className="font-normal text-gray-900 m-0 mt-2">{t('d-p-plan-pron-cronica')}</h4>
                {cronica.blocks.length > 0 ? (
                  <div>
                    <ViewComentarios comentarios={cronica} />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {groupCheck.check5 ? (
            <>
              <div className="max-h-24 mt-2 w-full">
                <h4 className="font-normal text-gray-900 m-0 mt-2">{t('d-p-plan-pron-otros')}</h4>
                {otro.blocks.length > 0 ? (
                  <div>
                    <ViewComentarios comentarios={otro} />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
        <div className="max-h-24 mt-2 w-full">
          {comentarios.blocks.length > 0 ? (
            <div>
              <h4 className="font-normal text-gray-900 m-0 mt-3">
                {t('d-p-plan-pron-comentarios')}
              </h4>
              <div>
                <ViewComentarios comentarios={comentarios} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="min-w-max self-center">
        <IconButton aria-label="edit" color="primary" onClick={handleEditDiagnostico}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" color="primary" onClick={handleDeleteDiagnostico}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ListaPronosticos;
