import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Button, Accordion, AccordionDetails, AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from '../../../../../../../common/styles';
import ListaDatosTab from '../ListaDatosTabs';
import { ITabExposicionErgonomicoProps } from './types';
import { ITiempoSeleccionComentariosFecha } from '../../../types';

function TabExposicionErgonomico({
  setOpenModalTiempoExposicion,
  setNombreModal,
  setIdTabSeleccionado,
  objExposicionRiesgo,
  setDatoActualizar,
  setInxArraySeleccionado,
  setDeleteAlert,
}: ITabExposicionErgonomicoProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [accRepeticion, setAccRepeticion] = useState<boolean | undefined>(undefined);
  const [accPostura, setAccPostura] = useState<boolean | undefined>(undefined);
  const [accFuerza, setAccFuerza] = useState<boolean | undefined>(undefined);
  const [accPosiciones, setAccPosiciones] = useState<boolean | undefined>(undefined);
  const [accPresion, setAccPresion] = useState<boolean | undefined>(undefined);
  const [accTemperaturas, setAccTemperaturas] = useState<boolean | undefined>(undefined);
  const [accVibraciones, setAccVibraciones] = useState<boolean | undefined>(undefined);
  const [accEstres, setAccEstres] = useState<boolean | undefined>(undefined);
  const [accOtro, setAccOtro] = useState<boolean | undefined>(undefined);
  return (
    <div className="w-full">
      {/* es la parte que corresponde a temperaturas altas */}
      <Accordion expanded={accRepeticion} onChange={() => setAccRepeticion(!accRepeticion)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_ergonomico_repeticion')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_ergonomico_repeticion'));
                  setIdTabSeleccionado(40);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayRepeticion.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayRepeticion.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_ergonomico_repeticion')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(40);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_ergonomico_repeticion'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(40);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a temperaturas bajas */}
      <Accordion expanded={accPostura} onChange={() => setAccPostura(!accPostura)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_ergonomico_postura')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_ergonomico_postura'));
                  setIdTabSeleccionado(41);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPosturaIncomoda.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPosturaIncomoda.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_ergonomico_postura')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(41);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_ergonomico_postura'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(41);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion ionizante */}
      <Accordion expanded={accFuerza} onChange={() => setAccFuerza(!accFuerza)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_ergonomico_fuerza')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_ergonomico_fuerza'));
                  setIdTabSeleccionado(42);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayFuerzaExcesiva.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayFuerzaExcesiva.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_ergonomico_fuerza')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(42);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_ergonomico_fuerza'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(42);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion no ionizante */}
      <Accordion expanded={accPosiciones} onChange={() => setAccPosiciones(!accPosiciones)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_ergonomico_posiciones')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_ergonomico_posiciones'));
                  setIdTabSeleccionado(43);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPosicionesEstacionarias.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPosicionesEstacionarias.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_ergonomico_posiciones')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(43);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_ergonomico_posiciones'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(43);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ventilacion */}
      <Accordion expanded={accPresion} onChange={() => setAccPresion(!accPresion)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_ergonomico_presion')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_ergonomico_presion'));
                  setIdTabSeleccionado(44);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPresionDirecta.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPresionDirecta.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_ergonomico_presion')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(44);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_ergonomico_presion'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(44);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a iluminacion */}
      <Accordion expanded={accTemperaturas} onChange={() => setAccTemperaturas(!accTemperaturas)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_ergonomico_temperaturas')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_ergonomico_temperaturas'));
                  setIdTabSeleccionado(45);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayTemperaturasExtremas.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayTemperaturasExtremas.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_ergonomico_temperaturas')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(45);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_ergonomico_temperaturas'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(45);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ruido */}
      <Accordion expanded={accVibraciones} onChange={() => setAccVibraciones(!accVibraciones)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_ergonomico_vibraciones')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_ergonomico_vibraciones'));
                  setIdTabSeleccionado(46);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayVibraciones.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayVibraciones.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_ergonomico_vibraciones')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(46);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_ergonomico_vibraciones'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(46);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a vibracion */}
      <Accordion expanded={accEstres} onChange={() => setAccEstres(!accEstres)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_ergonomico_estres')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_ergonomico_estres'));
                  setIdTabSeleccionado(47);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayExtresContacto.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayExtresContacto.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_ergonomico_estres')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(47);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_ergonomico_estres'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(47);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a otro */}
      <Accordion expanded={accOtro} onChange={() => setAccOtro(!accOtro)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_ergonomico_otro')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_ergonomico_otro'));
                  setIdTabSeleccionado(48);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayOtro.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayOtro.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_ergonomico_otro')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(48);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_ergonomico_otro'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(48);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TabExposicionErgonomico;
