import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import dayjs from 'dayjs';
import CheckIcon from '@material-ui/icons/Check';
import MaUTable from '@material-ui/core/Table';
import { useTable } from 'react-table';
import { getToken, getRecomendacionReferencia } from '../../../../../../../utils/commonUrl';
import { IPrevencionCardiovascularProps } from './types';
import useStyles from '../styles';
import ModalRecomendacion from '../../ModalRecomendacion';

require('dayjs/locale/es');

function PrevencionCardiovascularDiv({
  conColesterol = true,
  presion,
  colesterol,
  imc,
  valor,
  sexo,
  toxi,
  esDiabetico,
  colesterolResultado,
  fechaColesterol,
  imcResultado,
  fechaImc,
  tableGloborisk,
  edad,
}: IPrevencionCardiovascularProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [ref, setRef] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);

  const evalColor = (idColor: number) => {
    let color = 'inherit';
    if (idColor === 1) {
      color = '#38B050';
    } else if (idColor === 2) {
      color = '#92D050';
    } else if (idColor === 3) {
      color = '#F9FF00';
    } else if (idColor === 4) {
      color = '#F5C106';
    } else if (idColor === 5) {
      color = '#EF0405';
    } else if (idColor === 6) {
      color = '#C00300';
    } else if (idColor === 7) {
      color = '#800100';
    }
    return color;
  };

  const evalColorTexto = (idColor: string) => {
    let idC = 0;
    if (idColor === '<1') {
      idC = 1;
    } else {
      idC = parseInt(idColor, 10);
    }
    let color = 'text-gray-500 font-normal m-0 mt-4 text-center';
    if (idC >= 5) {
      color = 'text-red-500 font-normal m-0 mt-4 text-center';
    } else if (idC >= 3 && idC < 5) {
      color = 'text-yellow-500 font-normal m-0 mt-4 text-center';
    }
    return color;
  };

  const evalTexto = (idTxt: string) => {
    let idT = 0;
    if (idTxt === '<1') {
      idT = 1;
    } else {
      idT = parseInt(idTxt, 10);
    }
    let txt = t('p_e_cardio_er_riesgo_bajo');
    if (idT >= 10) {
      txt = t('p_e_cardio_er_riesgo_fatal');
    } else if (idT >= 5 && idT < 10) {
      txt = t('p_e_cardio_er_riesgo_alto');
    } else if (idT >= 3 && idT < 5) {
      txt = t('p_e_cardio_er_riesgo_intermedio');
    }
    return txt;
  };

  const columnas = conColesterol
    ? [
      {
        Header: '',
        accessor: 'valor',
      },
      {
        Header: '116',
        accessor: 'colesterol1',
      },
      {
        Header: '154',
        accessor: 'colesterol2',
      },
      {
        Header: '193',
        accessor: 'colesterol3',
      },
      {
        Header: '232',
        accessor: 'colesterol4',
      },
      {
        Header: '270',
        accessor: 'colesterol5',
      },
    ]
    : [
      {
        Header: '',
        accessor: 'valor',
      },
      {
        Header: '20',
        accessor: 'imc1',
      },
      {
        Header: '25',
        accessor: 'imc2',
      },
      {
        Header: '30',
        accessor: 'imc3',
      },
      {
        Header: '35',
        accessor: 'imc4',
      },
    ];

  const Table = (val: any) => {
    // Use the state and functions returned from useTable to build your UI
    const { columns, data } = val;
    const {
      getTableProps, headerGroups, rows, prepareRow,
    } = useTable({
      columns,
      data,
    });
    // Render the UI for your table
    return (
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()} className={classes.header}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <TableCell
                    {...cell.getCellProps()}
                    style={{
                      backgroundColor: `${
                        cell && cell.value && cell.value.color
                          ? `${evalColor(cell.value.color)}`
                          : 'inherit'
                      }`,
                      color: `${
                        cell && cell.value && cell.value.color && cell.value.color < 5
                          ? '#000000'
                          : '#FFFFFF'
                      }`,
                      textAlign: 'center',
                      borderColor: `${
                        cell
                        && cell.value
                        && cell.value.presion
                        && cell.value.presion === presion
                        && ((conColesterol
                          && cell.value.colesterol
                          && cell.value.colesterol === colesterol)
                          || (!conColesterol && cell.value.imc && cell.value.imc === imc))
                          ? 'blue'
                          : 'inherit'
                      }`,
                      borderStyle: `${
                        cell
                        && cell.value
                        && cell.value.presion
                        && cell.value.presion === presion
                        && ((conColesterol
                          && cell.value.colesterol
                          && cell.value.colesterol === colesterol)
                          || (!conColesterol && cell.value.imc && cell.value.imc === imc))
                          ? 'solid'
                          : 'inherit'
                      }`,
                      borderWidth: `${
                        cell
                        && cell.value
                        && cell.value.presion
                        && cell.value.presion === presion
                        && ((conColesterol
                          && cell.value.colesterol
                          && cell.value.colesterol === colesterol)
                          || (!conColesterol && cell.value.imc && cell.value.imc === imc))
                          ? '2px'
                          : 'inherit'
                      }`,
                    }}
                  >
                    {cell && cell.value && cell.value.valor ? cell.value.valor : null}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
    );
  };
  return (
    <div className="flex w-full">
      <ModalRecomendacion
        open={openModal}
        callBackClose={() => {
          setOpenModal(false);
          setRef({});
        }}
        referencia={ref}
      />
      <div className="border border-solid border-gray-500 rounded-lg w-full p-4">
        <h3 className="text-blue-500 font-normal m-0 mb-4">{t('p_e_cardio_evaluacion')}</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-gray-900 font-normal m-0 mb-4 text-center">
              {t('p_e_cardio_er_escala')}
            </h3>
            <div className="border border-solid border-gray-500 rounded-lg w-full p-4">
              <h4 className="text-gray-900 font-normal m-0 mb-2 text-center">
                {`${sexo === 'M' ? t('mujer') : t('hombre')}-${
                  toxi === 'activo' ? t('fumador') : t('no_fumador')
                }${
                  esDiabetico !== undefined
                    ? `-${esDiabetico ? t('diabetico') : t('no_diabetico')}`
                    : ''
                }`}
              </h4>
              <h4 className="text-gray-900 font-normal m-0 text-center">
                {t(conColesterol ? 'p_e_cardio_er_colesterol_label' : 'p_e_cardio_er_imc_label')}
              </h4>
              <h4 className="text-gray-900 font-normal m-0 text-center">
                {conColesterol
                  ? `(${t('resultado')}: ${colesterolResultado} mg/dL / ${dayjs(
                    fechaColesterol,
                  ).format('DD-MMM-YYYY')})`
                  : `(${t('resultado')}: ${imcResultado} kg/m² / ${dayjs(fechaImc).format(
                    'DD-MMM-YYYY',
                  )})`}
              </h4>
              <div className="flex">
                <div className="relative" style={{ height: '10px', width: '10px' }}>
                  <h4
                    className="absolute text-gray-900 font-normal m-0 whitespace-nowrap transform -rotate-90"
                    style={{ right: '-100px', top: '150px' }}
                  >
                    {t('p_e_cardio_er_presion_label')}
                  </h4>
                </div>
                <Table columns={columnas} data={tableGloborisk} />
                <h4 className="text-gray-900 font-normal m-0 pl-4 text-center flex place-self-center">
                  {`${edad} ${t('anios')}`}
                </h4>
              </div>
              <h4 className={evalColorTexto(valor)}>{evalTexto(valor)}</h4>
            </div>
          </div>
          {valor !== '<1' && parseInt(valor, 10) >= 10 ? (
            <>
              <div className="border-0 border-solid border-l-2 border-gray-300 pl-4 pt-4 flex flex-col">
                <h4 className="text-gray-900 font-normal m-0 pb-4">
                  {`${t('p_e_cardio_er_recomendacion')}:*`}
                </h4>
                <h4 className="text-gray-500 font-normal m-0 pb-2">
                  {t('p_e_cardio_er_sociedad_cardiologia')}
                </h4>
                <div className="flex flex-row items-center pb-2">
                  <CheckIcon />
                  <h4 className="font-normal text-gray-500 m-0 ml-2">
                    {`${t('p_e_cardio_er_objetivo1')}.`}
                  </h4>
                </div>
                <div className="flex flex-row items-center pb-2">
                  <CheckIcon />
                  <h4 className="font-normal text-gray-500 m-0 ml-2">
                    {`${t('p_e_cardio_er_indicar1')}.`}
                  </h4>
                </div>
                <div className="flex justify-end m-0 pb-2">
                  <Button
                    color="primary"
                    size="small"
                    className={classes.noTextTransform}
                    onClick={() => {
                      const token = getToken();
                      fetch(getRecomendacionReferencia('1'), {
                        method: 'GET',
                        headers: { Authorization: `Bearer ${token}` },
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          if (result.code === 200 && result.datos && result.datos.length > 0) {
                            setRef(result.datos[0]);
                            setOpenModal(true);
                          }
                        })
                        .catch(() => {
                          setRef({});
                        });
                    }}
                  >
                    {t('ver_recomendacion')}
                  </Button>
                </div>
                <h4 className="text-gray-500 font-normal m-0 pb-2">
                  {`${t('p_e_cardio_er_acc')}:`}
                </h4>
                <div className="flex flex-row items-center pb-2">
                  <CheckIcon />
                  <h4 className="font-normal text-gray-500 m-0 ml-2">
                    {`${t('p_e_cardio_er_acc1')}.`}
                  </h4>
                </div>
                <div className="flex justify-end m-0 pb-2">
                  <Button
                    color="primary"
                    size="small"
                    className={classes.noTextTransform}
                    onClick={() => {
                      const token = getToken();
                      fetch(getRecomendacionReferencia('4'), {
                        method: 'GET',
                        headers: { Authorization: `Bearer ${token}` },
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          if (result.code === 200 && result.datos && result.datos.length > 0) {
                            setRef(result.datos[0]);
                            setOpenModal(true);
                          }
                        })
                        .catch(() => {
                          setRef({});
                        });
                    }}
                  >
                    {t('ver_recomendacion')}
                  </Button>
                </div>
                <div className="h-full flex">
                  <h4 className="text-gray-300 font-normal m-0 self-end pb-4">
                    {`*${t('p_e_cardio_er_recomendaciones')}`}
                  </h4>
                </div>
              </div>
            </>
          ) : null}
          {valor !== '<1' && parseInt(valor, 10) >= 5 && parseInt(valor, 10) < 10 ? (
            <>
              <div className="border-0 border-solid border-l-2 border-gray-300 pl-4 pt-4 flex flex-col">
                <h4 className="text-gray-900 font-normal m-0 pb-4">
                  {`${t('p_e_cardio_er_recomendacion')}:*`}
                </h4>
                <h4 className="text-gray-500 font-normal m-0 pb-2">
                  {t('p_e_cardio_er_sociedad_cardiologia')}
                </h4>
                <div className="flex flex-row items-center pb-2">
                  <CheckIcon />
                  <h4 className="font-normal text-gray-500 m-0 ml-2">
                    {`${t('p_e_cardio_er_objetivo2')}.`}
                  </h4>
                </div>
                <div className="flex flex-row items-center pb-2">
                  <CheckIcon />
                  <h4 className="font-normal text-gray-500 m-0 ml-2">
                    {`${t('p_e_cardio_er_indicar1')}.`}
                  </h4>
                </div>
                <div className="flex justify-end m-0 pb-2">
                  <Button
                    color="primary"
                    size="small"
                    className={classes.noTextTransform}
                    onClick={() => {
                      const token = getToken();
                      fetch(getRecomendacionReferencia('2'), {
                        method: 'GET',
                        headers: { Authorization: `Bearer ${token}` },
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          if (result.code === 200 && result.datos && result.datos.length > 0) {
                            setRef(result.datos[0]);
                            setOpenModal(true);
                          }
                        })
                        .catch(() => {
                          setRef({});
                        });
                    }}
                  >
                    {t('ver_recomendacion')}
                  </Button>
                </div>
                <h4 className="text-gray-500 font-normal m-0 pb-2">
                  {`${t('p_e_cardio_er_acc')}:`}
                </h4>
                <div className="flex flex-row items-center pb-2">
                  <CheckIcon />
                  <h4 className="font-normal text-gray-500 m-0 ml-2">
                    {`${t('p_e_cardio_er_acc1')}.`}
                  </h4>
                </div>
                <div className="flex justify-end m-0 pb-2">
                  <Button
                    color="primary"
                    size="small"
                    className={classes.noTextTransform}
                    onClick={() => {
                      const token = getToken();
                      fetch(getRecomendacionReferencia('4'), {
                        method: 'GET',
                        headers: { Authorization: `Bearer ${token}` },
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          if (result.code === 200 && result.datos && result.datos.length > 0) {
                            setRef(result.datos[0]);
                            setOpenModal(true);
                          }
                        })
                        .catch(() => {
                          setRef({});
                        });
                    }}
                  >
                    {t('ver_recomendacion')}
                  </Button>
                </div>
                <div className="h-full flex">
                  <h4 className="text-gray-300 font-normal m-0 self-end pb-4">
                    {`*${t('p_e_cardio_er_recomendaciones')}`}
                  </h4>
                </div>
              </div>
            </>
          ) : null}
          {valor !== '<1' && parseInt(valor, 10) >= 3 && parseInt(valor, 10) < 5 ? (
            <>
              <div className="border-0 border-solid border-l-2 border-gray-300 pl-4 pt-4 flex flex-col">
                <h4 className="text-gray-900 font-normal m-0 pb-4">
                  {`${t('p_e_cardio_er_recomendacion')}:*`}
                </h4>
                <h4 className="text-gray-500 font-normal m-0 pb-2">
                  {t('p_e_cardio_er_sociedad_cardiologia')}
                </h4>
                <div className="flex flex-row items-center pb-2">
                  <CheckIcon />
                  <h4 className="font-normal text-gray-500 m-0 ml-2">
                    {`${t('p_e_cardio_er_objetivo3')}.`}
                  </h4>
                </div>
                <div className="flex flex-row items-center pb-2">
                  <CheckIcon />
                  <h4 className="font-normal text-gray-500 m-0 ml-2">
                    {`${t('p_e_cardio_er_indicar3')}.`}
                  </h4>
                </div>
                <div className="flex justify-end m-0 pb-2">
                  <Button
                    color="primary"
                    size="small"
                    className={classes.noTextTransform}
                    onClick={() => {
                      const token = getToken();
                      fetch(getRecomendacionReferencia('3'), {
                        method: 'GET',
                        headers: { Authorization: `Bearer ${token}` },
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          if (result.code === 200 && result.datos && result.datos.length > 0) {
                            setRef(result.datos[0]);
                            setOpenModal(true);
                          }
                        })
                        .catch(() => {
                          setRef({});
                        });
                    }}
                  >
                    {t('ver_recomendacion')}
                  </Button>
                </div>
                <div className="h-full flex">
                  <h4 className="text-gray-300 font-normal m-0 self-end pb-4">
                    {`*${t('p_e_cardio_er_recomendaciones')}`}
                  </h4>
                </div>
              </div>
            </>
          ) : null}
          {valor === '<1' || parseInt(valor, 10) < 3 ? (
            <>
              <div className="border-0 border-solid border-l-2 border-gray-300 pl-4 pt-4 flex flex-col">
                <h4 className="text-gray-900 font-normal m-0 pb-4">
                  {`${t('p_e_cardio_er_recomendacion')}:*`}
                </h4>
                <h4 className="text-gray-500 font-normal m-0 pb-2">
                  {t('p_e_cardio_er_riesgo_muy_bajo')}
                </h4>
                <div className="h-full flex">
                  <h4 className="text-gray-300 font-normal m-0 self-end pb-4">
                    {`*${t('p_e_cardio_er_recomendaciones')}`}
                  </h4>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default PrevencionCardiovascularDiv;
