import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RawDraftContentState } from 'draft-js';
import {
  FormControlLabel, Checkbox, Button, Collapse,
} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../store';
import FormularioTransfusiones from './FormularioTransfusiones';
import ListaTransfusiones from './ListaTransfusiones';
import { setSnackComplete } from '../../../../../actions/snackbar/types';
import DeleteDialog from '../../../../../components/DeleteDialog';
import { setLoading } from '../../../../../actions/loading/actions';
import { ICatalogosEstandar, IFechaObj } from '../../../../../common/types';
import {
  transfusionesEliminarTransfusion,
  transfusionesEliminarUsuarioPacienteTransfusion,
} from '../../../../../utils/deleteInfo';
import {
  transfusionesGuardarSinAntecedentes,
  transfusionesUsuarioPacienteGuardarSinAntecedentes,
} from '../../../../../utils/sendInfo';
import { crearArchivo } from '../../../../../utils/sendFiles';
import { deleteArchivo } from '../../../../../utils/deleteFiles';
import { ITransfusionesProps } from './types';
import { setRequest } from '../../../../../actions/request/types';
import { setRequestFile } from '../../../../../actions/requestFile/types';

function Transfusiones(props: ITransfusionesProps) {
  const {
    sinAntecedentes, setSinAntecedentes, transfusiones, setTransfusiones, catDiagnostico,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico, idUsuario, esPaciente } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  /* alertas */
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [cambioAlert, setCambioAlert] = useState<boolean>(false);
  /* archivo */
  const [selObjArchivo, setSelObjArchivo] = useState<{ archivo: File; idLista: number } | null>(
    null,
  );
  /* variables formulario */
  const [diagnosticoRelacionadoALaTransfusion, setDiagnosticoRelacionadoALaTransfusion] = useState<ICatalogosEstandar>({ id: 0, label: '' });
  const [motivoTransfusion, setMotivoTransfusion] = useState<string>('');
  const [fecha, setFecha] = useState<IFechaObj>({ dia: '', mes: '', anio: '' });
  const [componente, setComponente] = useState<string>('');
  const [otroComponente, setOtroComponente] = useState<string>('');
  const [comentarios, setComentarios] = useState<RawDraftContentState>({
    entityMap: {},
    blocks: [],
  });
  /* variables para los modales */
  const [indexToDelete, setIndexToDelete] = useState<number>(-1);
  const [indexToUpdate, setIndexToUpdate] = useState<number>(-1);
  const [idBD, setIDBD] = useState<number>(-1);

  const [liTraExpand, setLiTraExpand] = useState(true);
  const [numListaT, setNumListaT] = useState(transfusiones.length);

  /* FUNCIONES DE LAS ALERTAS */
  const [objArchivoBorrar, setObjArchivoBorrar] = useState<{
    idLista: number;
    idArchivo: number;
    nombreArchivo: string;
  } | null>(null);
  const [openArchivoBorrar, setOpenArchivoBorrar] = useState<boolean>(false);
  /* funcion para cerrar la alerta de borrado */
  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  /* funcion para cerrar la alerta de edición */
  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };
  /* funcion para cerrar la alerta de borrado de archivos */
  const handleDeleteArchivoBorrar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setObjArchivoBorrar(null);
    setOpenArchivoBorrar(false);
  };
  /* funcion para cerrar la alerta de activar sin antecedentes de alergias */
  const handleCambioAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCambioAlert(false);
  };

  const editTransfusion = (indice = indexToUpdate) => {
    const {
      idBD: indiceBD,
      diagnosticoRelacionadoALaTransfusion: diagnosticoRelacionadoALaTransfusionAEdit,
      // otroDiagnosticoRelacionadoALaTransfusion: otroDiagnosticoRelacionadoALaTransfusionAEdit,
      motivoTransfusion: motivoTransfusionAEdit,
      fecha: fechaAEdit,
      componente: componenteAEdit,
      otroComponente: otroComponenteAEdit,
      comentarios: comentariosAEdit,
    } = transfusiones[indice];
    /* setemos las variables del formulario y cerramos la alerta */
    setDiagnosticoRelacionadoALaTransfusion(diagnosticoRelacionadoALaTransfusionAEdit);
    // setOtroDiagosticoRelacionadoALaTransfusion(otroDiagnosticoRelacionadoALaTransfusionAEdit);
    setMotivoTransfusion(motivoTransfusionAEdit);
    setFecha(fechaAEdit);
    setComponente(componenteAEdit);
    setOtroComponente(otroComponenteAEdit);
    setComentarios(comentariosAEdit);
    setIDBD(indiceBD);
    setEditAlert(false);
  };

  const deleteTransfusion = () => {
    const funcTransfusionesEliminarTransfusion = esPaciente
      ? transfusionesEliminarUsuarioPacienteTransfusion({
        idUsuario,
        idBD: transfusiones[indexToDelete].idBD,
      })
      : transfusionesEliminarTransfusion({
        idPaciente,
        idBD: transfusiones[indexToDelete].idBD,
        consulta: idConsulta,
        idMedico,
        idConsultorio,
      });
    dispatch(
      setRequest({
        type: 'delete',
        requestFunction: funcTransfusionesEliminarTransfusion,
        successFunction: () => {
          setTransfusiones((listaPrev) => listaPrev.filter((_transf, indx) => indx !== indexToDelete));
          setIndexToDelete(-1);
        },
      }),
    );
    setDeleteAlert(false);
  };

  const handleCambioAntecedentesTransfusion = () => {
    dispatch(setLoading(true));
    const funcTransfusionesGuardarSinAntecedentes = esPaciente
      ? transfusionesUsuarioPacienteGuardarSinAntecedentes({ idUsuario })
      : transfusionesGuardarSinAntecedentes({
        idPaciente,
        idMedico,
        idConsultorio,
      });
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: funcTransfusionesGuardarSinAntecedentes,
        successFunction: (result: any) => {
          setSinAntecedentes(result.datos);
        },
      }),
    );
    setCambioAlert(false);
  };

  const verArchivo = (
    _idTransfusion: number,
    idArchivo: number,
    nombreArchivo: string,
    pathArchivo: string,
  ) => {
    dispatch(
      setRequestFile({
        type: 'get',
        idMedico: null,
        idPaciente,
        idArchivo,
        nombreArchivo,
        pathArchivo,
        tipoArchivo: 'transfusiones',
      }),
    );
  };

  const deleteArchivoBorrar = () => {
    if (objArchivoBorrar) {
      const { idLista, idArchivo } = objArchivoBorrar;
      const arch = transfusiones
        .find((lt) => lt.idBD === idLista)
        ?.archivos.find((ar) => ar.idArchivo === idArchivo);
      if (arch) {
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: deleteArchivo({
              idLista,
              idArchivo,
              idMedico,
              idPaciente,
              idConsultorio,
              pathArchivo: arch.pathArchivo,
              registro: 'transfusiones',
            }),
            successFunction: () => {
              const listaArchivos = transfusiones.find((lt) => lt.idBD === idLista)?.archivos;
              // se verificar que sea un array y no undefined
              if (Array.isArray(listaArchivos)) {
                // le añadimos el objeto que acabamos de subir a la BD
                const laf = listaArchivos.filter((ar) => ar.idArchivo !== idArchivo);
                // actualizamos la lista de transfusiones para evitar hacer un refresh y evitar
                // peticiones extra a la BD
                setTransfusiones((preTra) => preTra.map((tra) => {
                  if (tra.idBD === idLista) {
                    return { ...tra, archivos: laf };
                  }
                  return tra;
                }));
              }
            },
          }),
        );
      }
    } else {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: 'Ocurrio un error intentar nuevamente',
        }),
      );
    }
  };

  const eliminarArchivo = (idLista: number, idArchivo: number, nombreArchivo: string) => {
    setObjArchivoBorrar({ idLista, idArchivo, nombreArchivo });
  };

  /* EFECTOS PARA LA PAGINA */

  useEffect(() => {
    setNumListaT(transfusiones.length);
  }, [transfusiones]);

  // useEffect(() => {
  //   if (idPaciente > 0 && idMedico > 0 && idConsultorio > 0) {
  //     dispatch(setLoading(true));
  //     const fetchDiagnosticos = getEnfermedadesListaEnfermedadesReferidas(
  //       idPaciente,
  //       idMedico,
  //       idConsultorio,
  //     );
  //     const fetchListaTransfusiones = getListaTransfusiones(
  //       idMedico,
  //       idPaciente,
  //       idConsultorio,
  //     );
  //     Promise.all([fetchDiagnosticos, fetchListaTransfusiones])
  //       .then((responses) => {
  //         Promise.all([responses[0].json(), responses[1].json()])
  //           .then((results) => {
  //             if (results[0].code === 200) {
  //               setCatDiagnostico(results[0].data);
  //             }
  //             if (results[1].code === 200) {
  //               setSinAntecedentes(results[1].data.sinAntecedentes);
  //               if (!results[1].data.sinAntecedentes && results[1].data.transfusiones) {
  //                 setTransfusiones(results[1].data.transfusiones);
  //               } else {
  //                 setTransfusiones([]);
  //               }
  //             }
  //             dispatch(setLoading(false));
  //           })
  //           .catch((err) => {
  //             dispatch(
  //               setSnackComplete({
  //                 open: true,
  //                 severity: 'error',
  //                 mensaje: `${t('message-get-error')} ${err.toString()}`,
  //               }),
  //             );
  //             dispatch(setLoading(false));
  //           });
  //       })
  //       .catch((err) => {
  //         dispatch(setLoading(false));
  //         if (err.toString() === 'SyntaxError: Unexpected token E in JSON at position 0') {
  //           dispatch(
  //             setSnackComplete({
  //               open: true,
  //               severity: 'error',
  //               mensaje: 'Error en la sesión. Cierra la sesión e inicia nuevamente',
  //             }),
  //           );
  //           return;
  //         }
  //         dispatch(
  //           setSnackComplete({
  //             open: true,
  //             severity: 'error',
  //             mensaje: `${t('message-get-error')} ${err.toString()}`,
  //           }),
  //         );
  //       });
  //   }
  // }, [idPaciente, idMedico, idConsultorio]);

  useEffect(() => {
    if (selObjArchivo && selObjArchivo.archivo.size > 10485760) {
      setSelObjArchivo(null);
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('el_archivo_selecionado_pesa_mas_de_10_megas'),
        }),
      );
      return;
    }
    if (selObjArchivo) {
      dispatch(setLoading(true));
      // despues en un futuro debemos crear un XMLHttpRequest en vez de un fetch para obtener el progreso de la carga
      // ese progreso se dejará maqueteado para usarlo en un futuro, asi que los comentarios de dejan a proposito
      // obtenemos un objeto form data que nos servira para enviar nuestra peticion como si se hubiera generado en
      // un formulario
      const data = new FormData();
      // añadimos la informacion importante y OJO el archivo debe ir siempre al último o el back no parseara correctamente
      // los parametros que se le adjunta
      data.append('idLista', String(selObjArchivo.idLista));
      data.append('idMedico', String(idMedico));
      data.append('idPaciente', String(idPaciente));
      data.append('idConsulta', String(idConsulta));
      data.append('nombre', String(selObjArchivo.archivo?.name));
      data.append('tipo', selObjArchivo.archivo?.type);
      data.append('peso', String(selObjArchivo.archivo?.size));
      data.append('registro', 'transfusiones');
      data.append('file', selObjArchivo?.archivo);
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: crearArchivo(data),
          successFunction: (result: any) => {
            // si el resultado es ok procedemos a obtener la lista de archivos
            const listaArchivos = transfusiones.find(
              (lt) => lt.idBD === selObjArchivo.idLista,
            )?.archivos;
            // se verifica que sea un array y no undefined
            if (Array.isArray(listaArchivos)) {
              // le añadimos el objeto que acabamos de subir a la BD
              listaArchivos.push({
                idArchivo: result.datos.idArchivo,
                idMedico,
                nombreArchivo: selObjArchivo.archivo.name,
                pathArchivo: result.datos.url,
              });
              // actualizamos la lista de transfusiones para evitar hacer un refresh y evitar
              // peticiones extra a la BD
              setTransfusiones((preTra) => preTra.map((tra) => {
                if (tra.idBD === selObjArchivo.idLista) {
                  return { ...tra, archivos: listaArchivos };
                }
                return tra;
              }));
            }
          },
        }),
      );
    }
  }, [selObjArchivo]);

  useEffect(() => {
    if (objArchivoBorrar) {
      setOpenArchivoBorrar(true);
    }
  }, [objArchivoBorrar]);

  return (
    <>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pnp-historias-eliminar')}
        callBackAceptar={deleteTransfusion}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={openArchivoBorrar}
        titulo={t('_atencion_')}
        descripcion={`${t('_estas_seguro_de_eliminar')}: ${objArchivoBorrar?.nombreArchivo}?`}
        callBackAceptar={deleteArchivoBorrar}
        callBackClose={handleDeleteArchivoBorrar}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_este_diagnostico_se_borrara_el_que_estas_creando')}
        callBackAceptar={editTransfusion}
        callBackClose={handleEditAlertClose}
      />
      <DeleteDialog
        open={cambioAlert}
        titulo={t('_atencion_')}
        descripcion={t('_estas_seguro__si_existe_registros_anteriores_se_eliminaran')}
        callBackAceptar={handleCambioAntecedentesTransfusion}
        callBackClose={handleCambioAlertClose}
      />
      <div>
        <h2 className="text-blue-800 font-normal m-0">{t('transfusiones')}</h2>
        <FormControlLabel
          control={(
            <Checkbox
              checked={sinAntecedentes}
              onChange={() => {
                if (sinAntecedentes) {
                  setSinAntecedentes(false);
                } else {
                  setCambioAlert(true);
                }
              }}
              name="sinAntecedentesTransfusionales"
              color="primary"
            />
          )}
          label={t('sin_antecedentes_transfusionales')}
          disabled={!!transfusiones.length}
        />
        {!sinAntecedentes && (
          <FormularioTransfusiones
            idPaciente={idPaciente}
            catDiagnostico={catDiagnostico}
            idBD={idBD}
            setIDBD={setIDBD}
            diagnosticoRelacionadoALaTransfusion={diagnosticoRelacionadoALaTransfusion}
            setDiagnosticoRelacionadoALaTransfusion={setDiagnosticoRelacionadoALaTransfusion}
            // otroDiagnosticoRelacionadoALaTransfusion={otroDiagnosticoRelacionadoALaTransfusion}
            // setOtroDiagosticoRelacionadoALaTransfusion={setOtroDiagosticoRelacionadoALaTransfusion}
            motivoTransfusion={motivoTransfusion}
            setMotivoTransfusion={setMotivoTransfusion}
            fecha={fecha}
            setFecha={setFecha}
            componente={componente}
            setComponente={setComponente}
            otroComponente={otroComponente}
            setOtroComponente={setOtroComponente}
            comentarios={comentarios}
            setComentarios={setComentarios}
            setTransfusiones={setTransfusiones}
          />
        )}
        {numListaT > 0 && (
          <div className="mt-4">
            <h3 className="text-blue-500 font-medium">
              {`${t('lista_de')} ${t('transfusiones')}: ${numListaT}`}
            </h3>
            <Collapse in={liTraExpand}>
              {transfusiones.map((transfusion, indx) => (
                <ListaTransfusiones
                  key={transfusion.idBD}
                  transfusion={transfusion}
                  index={indx}
                  setEditAlert={setEditAlert}
                  setDeleteAlert={setDeleteAlert}
                  idBD={idBD}
                  setIndexToUpdate={setIndexToUpdate}
                  setIndexToDelete={setIndexToDelete}
                  editTransfusion={editTransfusion}
                  diagnosticoRelacionadoVerificar={diagnosticoRelacionadoALaTransfusion}
                  motivoTransfusionVerificar={motivoTransfusion}
                  fechaVerificar={fecha}
                  componenteVerificar={componente}
                  comentariosVerificar={comentarios}
                  setSelArchivo={setSelObjArchivo}
                  verArchivo={verArchivo}
                  eliminarArchivo={eliminarArchivo}
                />
              ))}
            </Collapse>
            <div className="text-center">
              <Button
                onClick={() => setLiTraExpand((prevVal: boolean) => !prevVal)}
                color="primary"
              >
                {liTraExpand ? <span>{t('ver_menos')}</span> : <span>{t('ver_mas')}</span>}
                {liTraExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Transfusiones;
