import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  InputLabel,
  Radio,
  Checkbox,
  IconButton,
  Button,
  Switch,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { IConstructorTablaProps } from './types';
import { capitalize } from '../../../../common/functions';
import { uniqueObjArray } from '../../../../common/funcionesArrays';
import useStyles from './styles';
import ViewPrueba from './view';
import { validacionSoloNumeros } from '../../../../constants/validaciones';

function BuilderTabla({
  variable,
  setVariable,
  setDisableContinuar,
  paso,
}: IConstructorTablaProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  /** States del functional component */
  const [numColumnas, setNumColumnas] = useState<number>(0);
  const [numFilas, setNumFilas] = useState<number>(0);
  const [columnaComentario, setColumnaComentario] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>('');
  /** Handlers del componente */
  const handleChangeDescripcion = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    const datComp = { ...variable.datosComponente };
    datComp.descripcion = val;
    setVariable({ ...variable, datosComponente: datComp });
  };

  const handleChangeColFilas = (e: React.ChangeEvent<{ value: unknown }>, sel: string) => {
    const val = parseInt(e.target.value as string, 10) || 0;
    if (sel === 'filas' && val >= 0 && val < 22 && validacionSoloNumeros(val.toString())) {
      setNumFilas(val);
    } else if (
      sel === 'columnas'
      && val >= 0
      && val < 12
      && validacionSoloNumeros(val.toString())
    ) {
      setNumColumnas(val);
    }
  };

  const handleBlurColFilas = (e: React.ChangeEvent<{ value: unknown }>, sel: string) => {
    const val = parseInt(e.target.value as string, 10) || 0;
    if (sel === 'filas' && val >= 3 && val < 22) {
      const sendVal = [];
      for (let i = 1; i < val; i += 1) {
        sendVal.push({ id: i, titulo: '' });
      }
      const constTabla = { ...variable.constructor };
      constTabla.tabla!.renglones = sendVal;
      setVariable({ ...variable, constructor: constTabla });
    } else if (sel === 'columnas' && val >= 3 && val < 12) {
      const sendVal = [];
      sendVal.push({
        accessor: 'titulo',
        Header: '',
      });
      for (let i = 1; i < val; i += 1) {
        sendVal.push({
          accessor: 'I'.repeat(i),
          Header: '',
        });
      }
      const constTabla = { ...variable.constructor };
      constTabla.tabla!.columnas = sendVal;
      setVariable({ ...variable, constructor: constTabla });
    }
  };

  const handleChangeColumna = (e: React.ChangeEvent<{ value: unknown }>, indice: number) => {
    const val = e.target.value as string;
    const constRenglones = [...variable.constructor.tabla!.columnas];
    constRenglones[indice].Header = val;
    const constTabla = { ...variable.constructor };
    constTabla.tabla!.columnas = constRenglones;
    setVariable({ ...variable, constructor: constTabla });
  };

  const handleChangeFila = (
    e: React.ChangeEvent<{ value: unknown }>,
    indice: number,
    nombre: string,
  ) => {
    const val = e.target.value as string;
    const constRenglones = [...variable.constructor.tabla!.renglones];
    if (nombre === 'comentarios') {
      constRenglones[indice].comentarios = val;
    } else {
      constRenglones[indice].titulo = val;
    }
    const constConstructor = { ...variable.constructor };
    constConstructor.tabla!.renglones = constRenglones;
    setVariable({ ...variable, constructor: constConstructor });
  };

  const handleDeleteColumna = (indice: number) => {
    if (
      ((!columnaComentario && variable.constructor.tabla!.columnas.length > 3)
        || (columnaComentario && variable.constructor.tabla!.columnas.length > 4))
      && variable.constructor.tabla!.columnas[indice].accessor !== 'comentarios'
    ) {
      const newCols = [...variable.constructor.tabla!.columnas];
      newCols.splice(indice, 1);
      for (let i = 1; i < (columnaComentario ? newCols.length - 1 : newCols.length); i += 1) {
        newCols[i].accessor = 'I'.repeat(i);
      }
      const constConstructor = { ...variable.constructor };
      constConstructor.tabla!.columnas = newCols;
      setVariable({ ...variable, constructor: constConstructor });
    }
  };

  const handleDeleteFila = (indice: number) => {
    if (variable.constructor.tabla!.renglones.length > 2) {
      const newRows = [...variable.constructor.tabla!.renglones];
      newRows.splice(indice, 1);
      for (let i = 0; i < newRows.length; i += 1) {
        newRows[i].id = i + 1;
      }
      const constConstructor = { ...variable.constructor };
      constConstructor.tabla!.renglones = newRows;
      setVariable({ ...variable, constructor: constConstructor });
    }
  };

  const handleAdd = (selector: string) => {
    if (selector === 'fila') {
      if (variable.constructor.tabla!.renglones.length < 21) {
        const newRows = [...variable.constructor.tabla!.renglones];
        newRows.push({ id: variable.constructor.tabla!.renglones.length, titulo: '' });
        const constConstructor = { ...variable.constructor };
        constConstructor.tabla!.renglones = newRows;
        setVariable({ ...variable, constructor: constConstructor });
        return;
      }
    } else if (selector === 'columna') {
      if (columnaComentario) {
        if (variable.constructor.tabla!.columnas.length < 12) {
          const arr = [...variable.constructor.tabla!.columnas];
          arr.pop();
          arr.push({
            accessor: 'I'.repeat(arr.length),
            Header: '',
          });
          arr.push({
            accessor: 'comentarios',
            Header: 'Comentarios',
          });
          const constConstructor = { ...variable.constructor };
          constConstructor.tabla!.columnas = arr;
          setVariable({ ...variable, constructor: constConstructor });
          return;
        }
      } else if (variable.constructor.tabla!.columnas.length < 11) {
        const preCols = [...variable.constructor.tabla!.columnas];
        preCols.push({ accessor: 'I'.repeat(preCols.length), Header: '' });
        const constConstructor = { ...variable.constructor };
        constConstructor.tabla!.columnas = preCols;
        setVariable({ ...variable, constructor: constConstructor });
        return;
      }
    }
    setOpenError(true);
    setSnackMessage(t('t-max-11-columnas'));
  };

  const handleComentario = () => {
    if (!columnaComentario) {
      const addCol = [...variable.constructor.tabla!.columnas];
      addCol.push({
        accessor: 'comentarios',
        Header: 'Comentarios',
      });
      const constConstructor = { ...variable.constructor };
      constConstructor.tabla!.columnas = addCol;
      setVariable({ ...variable, constructor: constConstructor });
    } else {
      const removeCol = [...variable.constructor.tabla!.columnas];
      removeCol.pop();
      const removeCommentRow = variable.constructor.tabla!.renglones.map((row) => {
        const nRow = row;
        delete nRow.comentarios;
        return nRow;
      });
      const constConstructor = { ...variable.constructor };
      constConstructor.tabla!.columnas = removeCol;
      constConstructor.tabla!.renglones = removeCommentRow;
      setVariable({ ...variable, constructor: constConstructor });
    }
    setColumnaComentario(!columnaComentario);
  };

  const handleSnackClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  useEffect(() => {
    if (numFilas < 3 || numFilas > 21 || numColumnas < 3 || numColumnas > 11) {
      setDisableContinuar(true);
    } else {
      setDisableContinuar(false);
    }
  }, [variable.datosComponente, variable.constructor, numColumnas, numFilas]);

  useEffect(() => {
    if (paso === 0) {
      const sendValRows = [];
      for (let i = 1; i < numFilas; i += 1) {
        sendValRows.push({ id: i + 1, titulo: '' });
      }
      const sendValCols = [];
      sendValCols.push({
        accessor: 'titulo',
        Header: '',
      });
      for (let i = 1; i < numColumnas; i += 1) {
        sendValCols.push({
          accessor: 'I'.repeat(i),
          Header: '',
        });
      }
      const constConstructor = { ...variable.constructor };
      constConstructor.tabla!.renglones = sendValRows;
      constConstructor.tabla!.columnas = sendValCols;
      setVariable({ ...variable, constructor: constConstructor });
      setColumnaComentario(false);
    }
    if (paso === 2) {
      const filterRows = uniqueObjArray(variable.constructor.tabla!.renglones, 'titulo');
      const filterColumns = uniqueObjArray(variable.constructor.tabla!.columnas.slice(1), 'Header');
      filterColumns.unshift(variable.constructor.tabla!.columnas[0]);
      if (
        filterRows.length < 2
        || (columnaComentario && filterColumns.length < 4)
        || (!columnaComentario && filterColumns.length < 3)
      ) {
        setOpenError(true);
        setSnackMessage(t('t-error-minimo-sin-datos'));
      } else {
        const constConstructor = { ...variable.constructor };
        constConstructor.tabla!.renglones = filterRows;
        constConstructor.tabla!.columnas = filterColumns;
        setVariable({ ...variable, constructor: constConstructor });
      }
    }
  }, [paso]);

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 grid grid-cols-1 gap-4">
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert severity="error" onClose={handleSnackClose}>
          {snackMessage}
        </MuiAlert>
      </Snackbar>
      {paso === 0 && (
        <>
          <div className="grid grid-cols-4 gap-4 mb-2">
            <div className="col-span-3 inset-x-0 top-0">
              <div className="font-bold">{t('instrucciones')}</div>
              <div className="mt-2">{t('t-casillas-o')}</div>
            </div>
          </div>
          <div className="border-solid border-0 border-b border-gray-600" />
        </>
      )}
      {paso > 0 && (
        <div className="flex mb-3.5 items-end justify-center">
          <div className="font-bold w-max mr-4">{capitalize(variable.datosComponente.titulo)}</div>
          <div className="w-1/3">
            <h3 className="mt-0 mb-2 text-gray-600 font-medium">
              {t('incluye_una_descripcion_opcional')}
            </h3>
            <TextField
              fullWidth
              value={variable.datosComponente.descripcion}
              onChange={handleChangeDescripcion}
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            />
          </div>
        </div>
      )}
      {paso === 0 && (
        <>
          <div className="grid grid-cols-8 gap-4 mb-2">
            <div className="col-span-2" />
            <div className="col-span-2 flex items-center justify-start">
              <ViewColumnIcon color="primary" className={classes.rotateButton} />
              <TextField
                label={t('t-filas-o')}
                value={numFilas.toString()}
                variant="outlined"
                fullWidth
                onChange={(e) => handleChangeColFilas(e, 'filas')}
                onBlur={(e) => handleBlurColFilas(e, 'filas')}
                type="number"
                InputProps={{ inputProps: { step: '1' } }}
                onKeyPress={(e) => {
                  if (e.key === '.') {
                    e.preventDefault();
                  }
                }}
                error={(numFilas < 3 && numFilas !== 0) || numFilas > 21}
                helperText={numFilas < 3 && numFilas !== 0 ? t('t-error-filas-o') : ''}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
              />
            </div>
            <div className="col-span-2 flex items-center justify-start">
              <ViewColumnIcon color="primary" />
              <TextField
                label={t('t-columnas-o')}
                value={numColumnas.toString()}
                variant="outlined"
                fullWidth
                onChange={(e) => handleChangeColFilas(e, 'columnas')}
                onBlur={(e) => handleBlurColFilas(e, 'columnas')}
                type="number"
                InputProps={{ inputProps: { step: '1' } }}
                onKeyPress={(e) => {
                  if (e.key === '.') {
                    e.preventDefault();
                  }
                }}
                error={(numColumnas < 3 && numColumnas !== 0) || numColumnas > 11}
                helperText={numColumnas < 3 && numColumnas !== 0 ? t('t-error-columnas-o') : ''}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
              />
            </div>
          </div>
        </>
      )}
      {paso === 1 && (
        <>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-2 font-bold w-max mr-4">{`${t('t-selector-o')}:`}</div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-2 flex items-center justify-start">
              <Checkbox
                color="primary"
                checked={variable.constructor.tabla!.unaVarOpc}
                onChange={() => {
                  const newConst = { ...variable.constructor };
                  newConst.tabla!.unaVarOpc = true;
                  setVariable({ ...variable, constructor: newConst });
                }}
              />
              <InputLabel
                id="ordenar-checkbox"
                className={
                  variable.constructor.tabla!.unaVarOpc
                    ? classes.textColorBlue
                    : classes.textColorBlack
                }
              >
                {t('t-varias-o')}
              </InputLabel>
              <Radio
                color="primary"
                checked={!variable.constructor.tabla!.unaVarOpc}
                onChange={() => {
                  const newConst = { ...variable.constructor };
                  newConst.tabla!.unaVarOpc = false;
                  setVariable({ ...variable, constructor: newConst });
                }}
              />
              <InputLabel
                id="ordenar-radio"
                className={
                  !variable.constructor.tabla!.unaVarOpc
                    ? classes.textColorBlue
                    : classes.textColorBlack
                }
              >
                {t('t-una-o')}
              </InputLabel>
            </div>
            <div className="col-span-2 flex items-center justify-end">
              <InputLabel id="ordenar">{t('t-txtcomentarios-o')}</InputLabel>
              <Switch color="primary" checked={columnaComentario} onChange={handleComentario} />
              <Button
                color="primary"
                onClick={() => handleAdd('columna')}
                className={classes.noTextTranform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('t-add-columna-o')}
              </Button>
            </div>
          </div>
          <div className="grid auto-cols-max overflow-x-auto">
            <div className="flex flex-row">
              {variable.constructor.tabla!.columnas.map((valCol, idxCol) => {
                if (idxCol <= 2) {
                  if (idxCol === 0) {
                    return (
                      <>
                        <div
                          className="items-center flex justify-center w-14 px-1 my-2 invisible"
                          key="columna-trash-fija-1"
                        >
                          <IconButton disabled>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                        <div
                          className="items-center flex justify-center w-52 px-1 my-2"
                          key="columna-trash-fija-2"
                        />
                      </>
                    );
                  }
                  return (
                    <div
                      className="items-center flex justify-center w-52 px-1 my-2"
                      key="columna-trash-fija-2"
                    />
                  );
                }
                return (
                  <div
                    className="items-center flex justify-center w-52 px-1 my-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`columna-trash-${idxCol}`}
                  >
                    {valCol.accessor !== 'comentarios' && (
                      <IconButton aria-label="delete" onClick={() => handleDeleteColumna(idxCol)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row">
              <div
                className="items-center flex justify-center w-14 px-1 my-2"
                key="columna-input-empty"
              />
              {variable.constructor.tabla!.columnas.map((valCol, idxCol) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="px-1 my-2 w-52" key={`columna-inputs-${idxCol}`}>
                  <TextField
                    id={`textfield-${idxCol}`}
                    label={idxCol === 0 ? null : `${t('t-columna-o')} ${idxCol}`}
                    value={valCol.Header}
                    variant="outlined"
                    onChange={(e) => handleChangeColumna(e, idxCol)}
                    inputProps={{ maxLength: 22, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                    disabled={valCol.accessor === 'comentarios' || valCol.accessor === 'titulo'}
                    fullWidth
                    multiline
                    rows={1}
                  />
                </div>
              ))}
            </div>
            {variable.constructor.tabla!.renglones.map((valRow, idxRow) => (
              <>
                <div className="flex flex-row">
                  <div
                    className="items-center flex justify-end w-14 px-1 my-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`trash-column-${idxRow}`}
                  >
                    {idxRow > 1 && (
                      <IconButton aria-label="delete" onClick={() => handleDeleteFila(idxRow)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                  {variable.constructor.tabla!.columnas.map((valCol, idxCol) => (
                    <div
                      className="items-center flex justify-center w-52 px-1 my-2"
                      // eslint-disable-next-line react/no-array-index-key
                      key={`fila-${idxRow}-${idxCol}`}
                    >
                      {idxCol === 0 || valCol.accessor === 'comentarios' ? (
                        <TextField
                          id={`textfield-${idxRow}-${idxCol}`}
                          label={
                            valCol.accessor === 'comentarios'
                              ? `${t('t-comentarios-o')}`
                              : `${t('t-fila-o')} ${idxRow + 1}`
                          }
                          value={
                            valCol.accessor === 'comentarios' ? valRow.comentarios : valRow.titulo
                          }
                          variant="outlined"
                          onChange={(e) => handleChangeFila(e, idxRow, valCol.accessor)}
                          inputProps={{
                            maxLength: 50,
                            autoComplete: 'off',
                            'aria-autocomplete': 'none',
                          }}
                          disabled={valCol.accessor === 'comentarios'}
                          fullWidth
                          multiline
                          rows={1}
                        />
                      ) : (
                        <>
                          {variable.constructor.tabla!.unaVarOpc ? (
                            <Checkbox disabled />
                          ) : (
                            <Radio disabled />
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </>
            ))}
            <div className="flex flex-row">
              <div
                className="items-center flex justify-center w-14 px-1 my-2"
                key="columna-input-empty2"
              />
              <div className="px-1 my-2 w-52" key="agrega-fila">
                <Button
                  color="primary"
                  onClick={() => handleAdd('fila')}
                  className={classes.noTextTranform}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {t('t-add-fila-o')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
      {paso === 2 && variable && <ViewPrueba componenteTabla={variable} />}
    </div>
  );
}

export default BuilderTabla;
