import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Switch } from '@material-ui/core';
import { INotasAdicionales } from './types';

function NotasAdicionales({
  comentariosExtra,
  incluirSeccionComentarios,
  setComentariosExtra,
  setIncluirSeccionComentarios,
}: INotasAdicionales) {
  const { t } = useTranslation();

  const handleChangeIncluirSeccionComentarios = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncluirSeccionComentarios(event.target.checked);
  };

  const handleChangeComentariosExtra = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setComentariosExtra(val.toUpperCase());
  };

  return (
    <div className="p-4 shadow-lg bg-white rounded border border-solid border-gray-200">
      <div className="flex justify-between items-center">
        <h3 className="m-0 text-blue-800 font-medium">{t('notas_adicionales')}</h3>
        <div className="flex items-center">
          <p className="mr-2 my-0">{t('desea_incluir_seccion?')}</p>
          <div className="grid grid-cols-3 items-center justify-items-center">
            {t('no')}
            <Switch
              name="switch-incluir-notas-adicionales"
              color="primary"
              size="small"
              checked={incluirSeccionComentarios}
              onChange={handleChangeIncluirSeccionComentarios}
            />
            {t('si')}
          </div>
        </div>
      </div>
      {incluirSeccionComentarios && (
        <div className="mt-2">
          <TextField
            value={comentariosExtra}
            onChange={handleChangeComentariosExtra}
            fullWidth
            rows={3}
            variant="outlined"
            multiline
            disabled
          />
        </div>
      )}
    </div>
  );
}

export default NotasAdicionales;
