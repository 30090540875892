import {
  ISnackMensaje,
  SET_SNACK_BOOLEAN,
  SET_SNACK_CLOSE,
  SET_SNACK_COMPLETE,
  SET_SNACK_MENSAJE,
  SET_SNACK_OPEN,
  SET_SNACK_SEVERITY,
  SnackActionTypes,
} from '../actions/snackbar/actions';

const initialState: ISnackMensaje = {
  mensaje: '',
  severity: 'info',
  open: false,
};

const SnackReducer = (state = initialState, action: SnackActionTypes) => {
  switch (action.type) {
    case SET_SNACK_MENSAJE:
      return {
        ...state,
        mensaje: action.payload,
      };
    case SET_SNACK_SEVERITY:
      return {
        ...state,
        severity: action.payload,
      };
    case SET_SNACK_BOOLEAN:
      return {
        ...state,
        open: action.payload,
      };
    case SET_SNACK_OPEN:
      return {
        ...state,
        open: true,
      };
    case SET_SNACK_CLOSE:
      return {
        ...state,
        open: false,
      };
    case SET_SNACK_COMPLETE:
      return action.payload;
    default:
      return state;
  }
};

export default SnackReducer;
