import React from 'react';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { IViewComentariosProps } from './types';

function ViewComentarios({ comentarios }: IViewComentariosProps) {
  return (
    <Editor
      toolbarHidden
      editorState={EditorState.createWithContent(convertFromRaw(comentarios))}
      readOnly
    />
  );
}

export default ViewComentarios;
