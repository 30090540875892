import {
  ConsultaActionTypes,
  SET_CONSULTA,
  SET_ID_PACIENTE_CONSULTA,
  RESET_CONSULTA,
} from './types';

export function setConsulta(idConsulta: number, idPaciente: number): ConsultaActionTypes {
  return {
    type: SET_CONSULTA,
    payload: { idConsulta, idPaciente },
  };
}

export function setIdPacienteConsulta(idPaciente: number): ConsultaActionTypes {
  return {
    type: SET_ID_PACIENTE_CONSULTA,
    payload: idPaciente,
  };
}

export function resetConsulta(): ConsultaActionTypes {
  return {
    type: RESET_CONSULTA,
  };
}
