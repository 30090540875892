import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Radio,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ITabExposicionProps, IObjDatoActualizar } from './types';
import TabExposicionFisico from './TabExposicionFisico';
import TabExposicionMecanico from './TabExposicionMecanico';
import TabExposicionQuimico from './TabExposicionQuimico';
import TabExposicionBiologico from './TabExposicionBiologico';
import TabExposicionErgonomico from './TabExposicionErgonomico';
import TabExposicionPsicosocial from './TabExposicionPsicosocial';
import DeleteDialog from '../../../../../../components/DeleteDialog';
import ModalTiempoExposicion from '../Modales/ModalTiempoExposicion';
import ModalRuido from '../Modales/ModalRuido';
import ModalRiesgoQuimico from '../Modales/ModalRiesgoQuimico';
import ModalRiesgoPsicosocial from '../Modales/ModalRiesgoPsicosocial';
import useStyles from './styles';

function TabExposicion({
  objExposicionRiesgo,
  setObjExposicionRiesgo,
  datosQuimicos,
  evalAnyRiesgo,
  updateExposicionesNegadas,
  saveTiempoSeleccionComentarios,
  actualizaTiempoSeleccionComentarios,
  borraTiempoSeleccionComentarios,
}: ITabExposicionProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openModalTiempoExposicion, setOpenModalTiempoExposicion] = useState<boolean>(false);
  const [openModalRuido, setOpenModalRuido] = useState<boolean>(false);
  const [openModalRiesgoQuimico, setOpenModalRiesgoQuimico] = useState<boolean>(false);
  const [openModalRiesgoPsicosocial, setOpenModalRiesgoPsicosocial] = useState<boolean>(false);
  const [idTabSeleccionado, setIdTabSeleccionado] = useState<number>(0);
  const [inxArraySeleccionado, setInxArraySeleccionado] = useState<number>(-1);
  const [nombreModal, setNombreModal] = useState<string>('');
  const [allNo, setAllNo] = useState<boolean>(objExposicionRiesgo.allNo);
  const [negadas, setNegadas] = useState<string[]>(objExposicionRiesgo.negadas);
  const [accFisico, setAccFisico] = useState<boolean>(false);
  const [accMecanico, setAccMecanico] = useState<boolean>(false);
  const [accQuimico, setAccQuimico] = useState<boolean>(false);
  const [accBiologico, setAccBiologico] = useState<boolean>(false);
  const [accErgonomico, setAccErgonomico] = useState<boolean>(false);
  const [accPsicosocial, setAccPsicosocial] = useState<boolean>(false);
  const [datoActualizar, setDatoActualizar] = useState<IObjDatoActualizar>({
    id: -1,
    txtTiempo: '',
    radioSeleccion: undefined,
    comentarios: '',
  });
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const formatRiesgo = (riesgo: number) => (riesgo === 0 ? 'm-0 text-gray-400 font-normal' : 'm-0 text-gray-600 font-normal');
  const newArrayNegadas = (value: boolean, riesgo: string): string[] => {
    let listaNegadas = negadas ? [...negadas] : [];
    if (value) {
      listaNegadas = listaNegadas.filter((r) => r !== riesgo);
      setAllNo(false);
    } else if (!listaNegadas.includes(riesgo)) {
      listaNegadas = [...listaNegadas, riesgo];
      if (listaNegadas.length === 6) {
        setAllNo(true);
      }
    }
    setNegadas(listaNegadas);
    return listaNegadas;
  };
  const setSeleccionRiesgoFisico = (seleccionado: number) => {
    setObjExposicionRiesgo({
      ...objExposicionRiesgo,
      grupoSelectores: {
        ...objExposicionRiesgo.grupoSelectores,
        fisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico,
          seleccionado,
        },
      },
    });
  };
  const setSeleccionRiesgoMecanico = (seleccionado: number) => {
    setObjExposicionRiesgo({
      ...objExposicionRiesgo,
      grupoSelectores: {
        ...objExposicionRiesgo.grupoSelectores,
        mecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico,
          seleccionado,
        },
      },
    });
  };
  const setSeleccionRiesgoQuimico = (seleccionado: number) => {
    setObjExposicionRiesgo({
      ...objExposicionRiesgo,
      grupoSelectores: {
        ...objExposicionRiesgo.grupoSelectores,
        quimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico,
          seleccionado,
        },
      },
    });
  };
  const setSeleccionRiesgoBiologico = (seleccionado: number) => {
    setObjExposicionRiesgo({
      ...objExposicionRiesgo,
      grupoSelectores: {
        ...objExposicionRiesgo.grupoSelectores,
        biologico: {
          ...objExposicionRiesgo.grupoSelectores.biologico,
          seleccionado,
        },
      },
    });
  };
  const setSeleccionRiesgoErgonomico = (seleccionado: number) => {
    setObjExposicionRiesgo({
      ...objExposicionRiesgo,
      grupoSelectores: {
        ...objExposicionRiesgo.grupoSelectores,
        ergonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico,
          seleccionado,
        },
      },
    });
  };
  const setSeleccionRiesgoPsicosocial = (seleccionado: number) => {
    setObjExposicionRiesgo({
      ...objExposicionRiesgo,
      grupoSelectores: {
        ...objExposicionRiesgo.grupoSelectores,
        psicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial,
          seleccionado,
        },
      },
    });
  };
  const handleRiesgoFisico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'si';
    setAccFisico(value);
    setSeleccionRiesgoFisico(value ? 1 : 0);
    updateExposicionesNegadas(allNo, newArrayNegadas(value, 'fisico'), value ? 'fisico' : '');
  };
  const handleRiesgoMecanico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'si';
    setAccMecanico(value);
    setSeleccionRiesgoMecanico(value ? 1 : 0);
    updateExposicionesNegadas(allNo, newArrayNegadas(value, 'mecanico'), value ? 'mecanico' : '');
  };
  const handleRiesgoQuimico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'si';
    setAccQuimico(value);
    setSeleccionRiesgoQuimico(value ? 1 : 0);
    updateExposicionesNegadas(allNo, newArrayNegadas(value, 'quimico'), value ? 'quimico' : '');
  };
  const handleRiesgoBiologico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'si';
    setAccBiologico(value);
    setSeleccionRiesgoBiologico(value ? 1 : 0);
    updateExposicionesNegadas(allNo, newArrayNegadas(value, 'biologico'), value ? 'biologico' : '');
  };
  const handleRiesgoErgonomico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'si';
    setAccErgonomico(value);
    setSeleccionRiesgoErgonomico(value ? 1 : 0);
    updateExposicionesNegadas(
      allNo,
      newArrayNegadas(value, 'ergonomico'),
      value ? 'ergonomico' : '',
    );
  };
  const handleRiesgoPsicosocial = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'si';
    setAccPsicosocial(value);
    setSeleccionRiesgoPsicosocial(value ? 1 : 0);
    updateExposicionesNegadas(
      allNo,
      newArrayNegadas(value, 'psicosocial'),
      value ? 'psicosocial' : '',
    );
  };
  const updateExposicionNegarTodas = (checkAll: boolean) => {
    setAllNo(checkAll);
    const listaRiesgos = [
      'fisico',
      'mecanico',
      'quimico',
      'biologico',
      'ergonomico',
      'psicosocial',
    ];
    const listaNegadas = checkAll ? listaRiesgos.filter((r) => !evalAnyRiesgo(r)) : [];
    const value = checkAll ? 0 : -1;
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        ...objExposicionRiesgo.grupoSelectores.fisico,
        seleccionado: evalAnyRiesgo('fisico') ? 1 : value,
      },
      mecanico: {
        ...objExposicionRiesgo.grupoSelectores.mecanico,
        seleccionado: evalAnyRiesgo('mecanico') ? 1 : value,
      },
      quimico: {
        ...objExposicionRiesgo.grupoSelectores.quimico,
        seleccionado: evalAnyRiesgo('quimico') ? 1 : value,
      },
      biologico: {
        ...objExposicionRiesgo.grupoSelectores.biologico,
        seleccionado: evalAnyRiesgo('biologico') ? 1 : value,
      },
      ergonomico: {
        ...objExposicionRiesgo.grupoSelectores.ergonomico,
        seleccionado: evalAnyRiesgo('ergonomico') ? 1 : value,
      },
      psicosocial: {
        ...objExposicionRiesgo.grupoSelectores.psicosocial,
        seleccionado: evalAnyRiesgo('psicosocial') ? 1 : value,
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
    updateExposicionesNegadas(checkAll, listaNegadas);
  };
  return (
    <div className="border border-solid border-gray-300 rounded p-4">
      <ModalTiempoExposicion
        open={openModalTiempoExposicion}
        callBackClose={() => {
          setOpenModalTiempoExposicion(false);
          setDatoActualizar({
            id: -1,
            txtTiempo: '',
            radioSeleccion: undefined,
            comentarios: '',
          });
          setInxArraySeleccionado(-1);
        }}
        callBackSave={(valorObj: any) => {
          saveTiempoSeleccionComentarios(valorObj, idTabSeleccionado);
        }}
        callBackUpdate={(valorObj: any) => {
          actualizaTiempoSeleccionComentarios(valorObj, idTabSeleccionado, inxArraySeleccionado);
        }}
        nombre={nombreModal}
        datoActualizar={datoActualizar}
      />
      <ModalRuido
        open={openModalRuido}
        callBackClose={() => {
          setOpenModalRuido(false);
          setDatoActualizar({
            id: -1,
            txtTiempo: '',
            radioSeleccion: undefined,
            comentarios: '',
          });
          setInxArraySeleccionado(-1);
        }}
        callBackSave={(valorObj: any) => {
          saveTiempoSeleccionComentarios(valorObj, idTabSeleccionado);
        }}
        callBackUpdate={(valorObj: any) => {
          actualizaTiempoSeleccionComentarios(valorObj, idTabSeleccionado, inxArraySeleccionado);
        }}
        nombre={nombreModal}
        datoActualizar={datoActualizar}
      />
      <ModalRiesgoQuimico
        open={openModalRiesgoQuimico}
        callBackClose={() => {
          setOpenModalRiesgoQuimico(false);
          setDatoActualizar({
            id: -1,
            txtTiempo: '',
            radioSeleccion: undefined,
            comentarios: '',
          });
          setInxArraySeleccionado(-1);
        }}
        callBackSave={(valorObj: any) => {
          saveTiempoSeleccionComentarios(valorObj, idTabSeleccionado);
        }}
        callBackUpdate={(valorObj: any) => {
          actualizaTiempoSeleccionComentarios(valorObj, idTabSeleccionado, inxArraySeleccionado);
        }}
        nombre={nombreModal}
        datosQuimicos={datosQuimicos}
        datoActualizar={datoActualizar}
      />
      <ModalRiesgoPsicosocial
        open={openModalRiesgoPsicosocial}
        callBackClose={() => {
          setOpenModalRiesgoPsicosocial(false);
          setDatoActualizar({
            id: -1,
            txtTiempo: '',
            radioSeleccion: undefined,
            comentarios: '',
          });
          setInxArraySeleccionado(-1);
        }}
        callBackSave={(valorObj: any) => {
          saveTiempoSeleccionComentarios(valorObj, idTabSeleccionado);
        }}
        callBackUpdate={(valorObj: any) => {
          actualizaTiempoSeleccionComentarios(valorObj, idTabSeleccionado, inxArraySeleccionado);
        }}
        nombre={nombreModal}
        datoActualizar={datoActualizar}
      />
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pnp-historias-eliminar')}
        callBackAceptar={() => borraTiempoSeleccionComentarios(idTabSeleccionado, inxArraySeleccionado)}
        callBackClose={() => setDeleteAlert(false)}
      />
      <div className="flex justify-start items-center m-0">
        <h3 className="m-0 text-gray-600 font-medium pr-4">
          <Trans i18nKey="antecedentes_laborales_exposicion">
            <strong title="index1">index0</strong>
            index1
          </Trans>
        </h3>
      </div>
      <div className="pl-7">
        <FormControlLabel
          control={(
            <Checkbox
              checked={allNo}
              onChange={(e) => updateExposicionNegarTodas(e.target.checked)}
              name="no"
              color="primary"
            />
          )}
          label={t('antecedentes_laborales_exposicion_selecciona')}
        />
      </div>
      <div className="flex flex-row w-full">
        <h3 className="m-0 text-gray-600 font-medium pl-7">{t('no')}</h3>
        <h3 className="m-0 text-gray-600 font-medium pl-6">{t('si')}</h3>
      </div>
      {/* es la parte que corresponde a fisico */}
      <Accordion expanded={accFisico}>
        <AccordionSummary
          onClick={() => {
            if (objExposicionRiesgo.grupoSelectores.fisico.seleccionado !== 0) {
              setSeleccionRiesgoFisico(accFisico ? -1 : 1);
              setAccFisico(!accFisico);
            }
          }}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            color:
              objExposicionRiesgo.grupoSelectores.fisico.seleccionado !== 0 ? 'primary' : 'default',
          }}
          className={classes.summary}
        >
          <div className="w-full flex justify-start items-center">
            <Radio
              color="primary"
              value="no"
              name="radio-fisico-no"
              checked={objExposicionRiesgo.grupoSelectores.fisico.seleccionado === 0}
              onChange={handleRiesgoFisico}
              disabled={evalAnyRiesgo('fisico')}
            />
            <Radio
              color="primary"
              value="si"
              name="radio-fisico-si"
              checked={
                objExposicionRiesgo.grupoSelectores.fisico.seleccionado === 1
                || evalAnyRiesgo('fisico')
              }
              onChange={handleRiesgoFisico}
              disabled={evalAnyRiesgo('fisico')}
            />
            <h3 className={formatRiesgo(objExposicionRiesgo.grupoSelectores.fisico.seleccionado)}>
              {t('antecedentes_laborales_fisico')}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TabExposicionFisico
            setOpenModalTiempoExposicion={setOpenModalTiempoExposicion}
            setOpenModalRuido={setOpenModalRuido}
            setNombreModal={setNombreModal}
            setIdTabSeleccionado={setIdTabSeleccionado}
            objExposicionRiesgo={objExposicionRiesgo}
            setDatoActualizar={setDatoActualizar}
            setInxArraySeleccionado={setInxArraySeleccionado}
            setDeleteAlert={setDeleteAlert}
          />
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a mecanico */}
      <Accordion expanded={accMecanico}>
        <AccordionSummary
          onClick={() => {
            if (objExposicionRiesgo.grupoSelectores.mecanico.seleccionado !== 0) {
              setSeleccionRiesgoMecanico(accMecanico ? -1 : 1);
              setAccMecanico(!accMecanico);
            }
          }}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            color:
              objExposicionRiesgo.grupoSelectores.mecanico.seleccionado !== 0
                ? 'primary'
                : 'default',
          }}
        >
          <div className="w-full flex justify-start items-center">
            <Radio
              color="primary"
              value="no"
              name="radio-mecanico-no"
              checked={objExposicionRiesgo.grupoSelectores.mecanico.seleccionado === 0}
              onChange={handleRiesgoMecanico}
              disabled={evalAnyRiesgo('mecanico')}
            />
            <Radio
              color="primary"
              value="si"
              name="radio-mecanico-si"
              checked={
                objExposicionRiesgo.grupoSelectores.mecanico.seleccionado === 1
                || evalAnyRiesgo('mecanico')
              }
              onChange={handleRiesgoMecanico}
              disabled={evalAnyRiesgo('mecanico')}
            />
            <h3 className={formatRiesgo(objExposicionRiesgo.grupoSelectores.mecanico.seleccionado)}>
              {t('antecedentes_laborales_mecanico')}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TabExposicionMecanico
            setOpenModalTiempoExposicion={setOpenModalTiempoExposicion}
            setNombreModal={setNombreModal}
            setIdTabSeleccionado={setIdTabSeleccionado}
            objExposicionRiesgo={objExposicionRiesgo}
            setDatoActualizar={setDatoActualizar}
            setInxArraySeleccionado={setInxArraySeleccionado}
            setDeleteAlert={setDeleteAlert}
          />
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a quimico */}
      <Accordion expanded={accQuimico}>
        <AccordionSummary
          onClick={() => {
            if (objExposicionRiesgo.grupoSelectores.quimico.seleccionado !== 0) {
              setSeleccionRiesgoQuimico(accQuimico ? -1 : 1);
              setAccQuimico(!accQuimico);
            }
          }}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            color:
              objExposicionRiesgo.grupoSelectores.quimico.seleccionado !== 0
                ? 'primary'
                : 'default',
          }}
          className={classes.summary}
        >
          <div className="w-full flex justify-start items-center">
            <Radio
              color="primary"
              value="no"
              name="radio-quimico-no"
              checked={objExposicionRiesgo.grupoSelectores.quimico.seleccionado === 0}
              onChange={handleRiesgoQuimico}
              disabled={evalAnyRiesgo('quimico')}
            />
            <Radio
              color="primary"
              value="si"
              name="radio-quimico-si"
              checked={
                objExposicionRiesgo.grupoSelectores.quimico.seleccionado === 1
                || evalAnyRiesgo('quimico')
              }
              onChange={handleRiesgoQuimico}
              disabled={evalAnyRiesgo('quimico')}
            />
            <h3 className={formatRiesgo(objExposicionRiesgo.grupoSelectores.quimico.seleccionado)}>
              {t('antecedentes_laborales_quimico')}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TabExposicionQuimico
            setOpenModalRiesgoQuimico={setOpenModalRiesgoQuimico}
            setNombreModal={setNombreModal}
            setIdTabSeleccionado={setIdTabSeleccionado}
            objExposicionRiesgo={objExposicionRiesgo}
            setDatoActualizar={setDatoActualizar}
            setInxArraySeleccionado={setInxArraySeleccionado}
            setDeleteAlert={setDeleteAlert}
          />
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a biologico */}
      <Accordion expanded={accBiologico}>
        <AccordionSummary
          onClick={() => {
            if (objExposicionRiesgo.grupoSelectores.biologico.seleccionado !== 0) {
              setSeleccionRiesgoBiologico(accBiologico ? -1 : 1);
              setAccBiologico(!accBiologico);
            }
          }}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            color:
              objExposicionRiesgo.grupoSelectores.biologico.seleccionado !== 0
                ? 'primary'
                : 'default',
          }}
        >
          <div className="w-full flex justify-start items-center">
            <Radio
              color="primary"
              value="no"
              name="radio-biologico-no"
              checked={objExposicionRiesgo.grupoSelectores.biologico.seleccionado === 0}
              onChange={handleRiesgoBiologico}
              disabled={evalAnyRiesgo('biologico')}
            />
            <Radio
              color="primary"
              value="si"
              name="radio-biologico-si"
              checked={
                objExposicionRiesgo.grupoSelectores.biologico.seleccionado === 1
                || evalAnyRiesgo('biologico')
              }
              onChange={handleRiesgoBiologico}
              disabled={evalAnyRiesgo('biologico')}
            />
            <h3
              className={formatRiesgo(objExposicionRiesgo.grupoSelectores.biologico.seleccionado)}
            >
              {t('antecedentes_laborales_biologico')}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TabExposicionBiologico
            setOpenModalTiempoExposicion={setOpenModalTiempoExposicion}
            setNombreModal={setNombreModal}
            setIdTabSeleccionado={setIdTabSeleccionado}
            objExposicionRiesgo={objExposicionRiesgo}
            setDatoActualizar={setDatoActualizar}
            setInxArraySeleccionado={setInxArraySeleccionado}
            setDeleteAlert={setDeleteAlert}
          />
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ergonomico */}
      <Accordion expanded={accErgonomico}>
        <AccordionSummary
          onClick={() => {
            if (objExposicionRiesgo.grupoSelectores.ergonomico.seleccionado !== 0) {
              setSeleccionRiesgoErgonomico(accErgonomico ? -1 : 1);
              setAccErgonomico(!accErgonomico);
            }
          }}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            color:
              objExposicionRiesgo.grupoSelectores.ergonomico.seleccionado !== 0
                ? 'primary'
                : 'default',
          }}
          className={classes.summary}
        >
          <div className="w-full flex justify-start items-center">
            <Radio
              color="primary"
              value="no"
              name="radio-ergonomico-no"
              checked={objExposicionRiesgo.grupoSelectores.ergonomico.seleccionado === 0}
              onChange={handleRiesgoErgonomico}
              disabled={evalAnyRiesgo('ergonomico')}
            />
            <Radio
              color="primary"
              value="si"
              name="radio-ergonomico-si"
              checked={
                objExposicionRiesgo.grupoSelectores.ergonomico.seleccionado === 1
                || evalAnyRiesgo('ergonomico')
              }
              onChange={handleRiesgoErgonomico}
              disabled={evalAnyRiesgo('ergonomico')}
            />
            <h3
              className={formatRiesgo(objExposicionRiesgo.grupoSelectores.ergonomico.seleccionado)}
            >
              {t('antecedentes_laborales_ergonomico')}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TabExposicionErgonomico
            setOpenModalTiempoExposicion={setOpenModalTiempoExposicion}
            setNombreModal={setNombreModal}
            setIdTabSeleccionado={setIdTabSeleccionado}
            objExposicionRiesgo={objExposicionRiesgo}
            setDatoActualizar={setDatoActualizar}
            setInxArraySeleccionado={setInxArraySeleccionado}
            setDeleteAlert={setDeleteAlert}
          />
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a psicosocial */}
      <Accordion expanded={accPsicosocial}>
        <AccordionSummary
          onClick={() => {
            if (objExposicionRiesgo.grupoSelectores.psicosocial.seleccionado !== 0) {
              setSeleccionRiesgoPsicosocial(accPsicosocial ? -1 : 1);
              setAccPsicosocial(!accPsicosocial);
            }
          }}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            color:
              objExposicionRiesgo.grupoSelectores.psicosocial.seleccionado !== 0
                ? 'primary'
                : 'default',
          }}
        >
          <div className="w-full flex justify-start items-center">
            <Radio
              color="primary"
              value="no"
              name="radio-psicosocial-no"
              checked={objExposicionRiesgo.grupoSelectores.psicosocial.seleccionado === 0}
              onChange={handleRiesgoPsicosocial}
              disabled={evalAnyRiesgo('psicosocial')}
            />
            <Radio
              color="primary"
              value="si"
              name="radio-psicosocial-si"
              checked={
                objExposicionRiesgo.grupoSelectores.psicosocial.seleccionado === 1
                || evalAnyRiesgo('psicosocial')
              }
              onChange={handleRiesgoPsicosocial}
              disabled={evalAnyRiesgo('psicosocial')}
            />
            <h3
              className={formatRiesgo(objExposicionRiesgo.grupoSelectores.psicosocial.seleccionado)}
            >
              {t('antecedentes_laborales_psicosocial')}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TabExposicionPsicosocial
            setOpenModalRiesgoPsicosocial={setOpenModalRiesgoPsicosocial}
            setNombreModal={setNombreModal}
            setIdTabSeleccionado={setIdTabSeleccionado}
            objExposicionRiesgo={objExposicionRiesgo}
            setDatoActualizar={setDatoActualizar}
            setInxArraySeleccionado={setInxArraySeleccionado}
            setDeleteAlert={setDeleteAlert}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TabExposicion;
