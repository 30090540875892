import {
  ACREDITADO,
  AuthActionTypes,
  DESACREDITADO,
  IAuth,
  RESET_AUTH,
  SET_AUTH,
  SET_ID,
  SINSUSCRIPCION,
  SUSCRITO,
} from '../../actions/auth/types';

const initialState = {
  id: '',
  acreditado: null,
  suscrito: null,
} as IAuth;

function Auth(state = initialState, action: AuthActionTypes) {
  switch (action.type) {
    case SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case ACREDITADO:
      return {
        ...state,
        acreditado: true,
      };
    case DESACREDITADO:
      return {
        ...state,
        acreditado: false,
      };
    case SUSCRITO:
      return {
        ...state,
        suscrito: true,
      };
    case SINSUSCRIPCION:
      return {
        ...state,
        suscrito: false,
      };
    case RESET_AUTH:
      return initialState;
    case SET_AUTH:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

export default Auth;
