import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Navbar from '../../containers/Navbar';
import NavbarPaciente from '../../containers/NavbarPaciente';
import AdmonNavbar from '../../containers/AdmonNavbar';
import { RootState } from '../../store';
import { ILoggedInSubscribedRouteProps } from './types';

function LoggedInSubscribedRoute({ children, ...otrasProps }: ILoggedInSubscribedRouteProps) {
  const { suscrito, acreditado } = useSelector((state: RootState) => state.Auth);
  const { rol, esPaciente, secciones } = useSelector((state: RootState) => state.Me);
  if (acreditado === false) {
    return (
      <Route
        {...otrasProps}
        render={({ location }) => (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )}
      />
    );
  }
  return (
    <Route
      {...otrasProps}
      render={({ location }) => (rol.grupo !== 'paciente' && suscrito === false ? (
        <Redirect to={{ pathname: '/subscripcion', state: { from: location } }} />
      ) : (
        <div className="flex">
          {secciones.length > 0
              && secciones.find((valor) => valor.idSeccion >= 1 && valor.idSeccion <= 149) && (
                <>{esPaciente ? <NavbarPaciente /> : <Navbar />}</>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion >= 150) && (
          <AdmonNavbar />
          )}
          <main style={{ width: '100%' }}>{children}</main>
        </div>
      ))}
    />
  );
}

export default LoggedInSubscribedRoute;
