import React from 'react';
import { CircularProgress } from '@material-ui/core';

/** Componente que regresar un cargando */
function Loading() {
  return (
    <div className="w-100 h-screen flex justify-center items-center">
      <CircularProgress />
    </div>
  );
}

export default Loading;
