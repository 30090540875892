import { ICatalogosEstandar } from '../../../../../common/types';
import { IEnfermedad } from '../../../Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';

export interface ICatalogosCombinados {
  id: number;
  label: string;
  categoria: ICatalogosEstandar;
  source: 'medipraxi' | 'propio';
  inUse: boolean;
}

export interface ITipoExamen {
  categoria: ICatalogosEstandar;
  tipo: ICatalogosCombinados;
}

export interface IDictamenMedLab {
  id: number;
  tipoExamen: ICatalogosCombinados;
  puesto: ICatalogosCombinados;
  dictamen: ICatalogosEstandar;
  sinRestricciones: boolean;
  certifica: string;
  arrayRestriccionTotal: string[];
  arrayRestriccionParcial: string[];
  comentariosRestricciones: string;
  arrayCausaNoAptitudEnf: string[];
  arrayCausaNoAptitudOtra: string[];
  recomendaciones: string;
  idConsulta: number;
}

export const dictamenMedLabInitial: IDictamenMedLab = {
  id: -1,
  tipoExamen: {
    id: 0,
    label: '',
    categoria: { id: 0, label: '' },
    source: 'medipraxi',
    inUse: false,
  },
  puesto: {
    id: 0,
    label: '',
    categoria: { id: 0, label: '' },
    source: 'medipraxi',
    inUse: false,
  },
  dictamen: {
    id: 0,
    label: '',
  },
  sinRestricciones: true,
  certifica: '',
  arrayRestriccionTotal: [],
  arrayRestriccionParcial: [],
  comentariosRestricciones: '',
  arrayCausaNoAptitudEnf: [],
  arrayCausaNoAptitudOtra: [],
  recomendaciones: '',
  idConsulta: 0,
};

export interface IDictamenMedicoLaboralProps {
  enfermedadesActivas: IEnfermedad[];
}
