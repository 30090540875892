import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import WarningIcon from '@material-ui/icons/Warning';
import { RootState } from '../../../../../store';
import {
  calculateEdad,
  calculateMeses,
  calculateDias,
  fechaISOADiaMesAnioStr,
} from '../../../../../common/functions';
import { IPadecimientoActualProps } from './types';
import { ICompUnico } from '../../../../PersonalizacionFC/SeleccionVariablesMC/TodasLasVariables/types';
import { setLoading } from '../../../../../actions/loading/actions';
import { setSnackComplete } from '../../../../../actions/snackbar/types';
import { mcpaGuardarMCPA } from '../../../../../utils/sendInfo';
import { mcpaEditarMCPA } from '../../../../../utils/editInfo';
import AlertMultipleDialog from '../../../../../components/AlertMultipleDialog';

const tablaToStringArr = (
  valores: {
    tituloFila: string;
    tituloColumna: string;
    comentarios?: string;
  }[],
) => {
  const mapaTabla = new Map();
  valores.forEach((vt) => {
    if (mapaTabla.get(vt.tituloFila)) {
      const obj = mapaTabla.get(vt.tituloFila);
      if (vt.tituloColumna === 'comentarios') {
        obj.comentarios = vt.comentarios;
      } else {
        obj.valores = mapaTabla.get(vt.tituloFila).valores.concat(vt.tituloColumna);
      }
      mapaTabla.set(vt.tituloFila, obj);
    } else {
      const obj = {
        valores: vt.tituloColumna === 'comentarios' ? [] : [vt.tituloColumna],
        comentarios: vt.tituloColumna === 'comentarios' ? vt.comentarios : '',
      };
      mapaTabla.set(vt.tituloFila, obj);
    }
  });
  let str = '';
  const arr = [] as string[];
  mapaTabla.forEach((val, key) => {
    str = `- ${key}: ${val.valores.join(', ')}. ${
      val.comentarios ? val.comentarios.concat('.') : ''
    }`;
    arr.push(str);
  });
  return arr;
};

const stringData = (singleComp: ICompUnico) => {
  switch (singleComp.formato) {
    case 1:
      return `${singleComp.constructorComponente.datosComponente.titulo}: ${
        singleComp.constructorComponente.valores.valor
      } ${singleComp.constructorComponente.valores.respuestaBreve!}. ${
        singleComp.constructorComponente.valores.textoLibre
          ? singleComp.constructorComponente.valores.textoLibre.concat('.')
          : ''
      }`;
    case 2:
      return `${singleComp.constructorComponente.datosComponente.titulo}: ${
        singleComp.constructorComponente.valores.textoLibre
          ? singleComp.constructorComponente.valores.textoLibre.concat('.')
          : ''
      }`;
    case 3:
      return `${
        singleComp.constructorComponente.datosComponente.titulo
      }: ${singleComp.constructorComponente.valores
        .casillasOpciones!.arrOpciones.map(
        (op) => `- ${op.nombreOpcion}${op.arraySubOpciones.length ? ':' : ''} ${
          op.arraySubOpciones.length ? '(' : ''
        }${op.arraySubOpciones.join(', ')}${op.arraySubOpciones.length ? ') ' : ''}`,
      )
        .join(', ')
        .concat('.')} ${
        singleComp.constructorComponente.valores.textoLibre
          ? ` ${singleComp.constructorComponente.valores.textoLibre}.`
          : ''
      }`;
    case 4:
      return `${
        singleComp.constructorComponente.datosComponente.titulo
      }: ${singleComp.constructorComponente.valores
        .casillasOpciones!.arrOpciones.map(
        (op) => `- ${op.nombreOpcion}${op.arraySubOpciones.length ? ':' : ''} ${
          op.arraySubOpciones.length ? '(' : ''
        }${op.arraySubOpciones.join(', ')}${op.arraySubOpciones.length ? ') ' : ''}`,
      )
        .join(', ')
        .concat('.')} ${
        singleComp.constructorComponente.valores.textoLibre
          ? ` ${singleComp.constructorComponente.valores.textoLibre}.`
          : ''
      }`;
    case 5:
      return `${
        singleComp.constructorComponente.datosComponente.titulo
      }: ${singleComp.constructorComponente.valores.listaDesplegable!.join(', ').concat('.')} ${
        singleComp.constructorComponente.valores.textoLibre
          ? ` ${singleComp.constructorComponente.valores.textoLibre}.`
          : ''
      }`;
    case 6:
      return `${
        singleComp.constructorComponente.datosComponente.titulo
      }: ${`${singleComp.constructorComponente.valores.valor} / ${singleComp.constructorComponente.valores.escalaLineal?.valor.final}`} ${
        singleComp.constructorComponente.valores.textoLibre
          ? ` ${singleComp.constructorComponente.valores.textoLibre}`
          : ''
      }`;
    case 7: {
      const str = tablaToStringArr(singleComp.constructorComponente.valores.tabla!);
      return `${singleComp.constructorComponente.datosComponente.titulo}: ${str.join('\n')} ${
        singleComp.constructorComponente.valores.textoLibre
          ? ` ${singleComp.constructorComponente.valores.textoLibre}`
          : ''
      }`;
    }
    case 8:
      return `${
        singleComp.constructorComponente.datosComponente.titulo
      }: ${`${fechaISOADiaMesAnioStr(singleComp.constructorComponente.valores.valor)}. `} ${
        singleComp.constructorComponente.valores.textoLibre
          ? ` ${singleComp.constructorComponente.valores.textoLibre}.`
          : ''
      }`;
    case 9:
      return `${singleComp.constructorComponente.datosComponente.titulo}: ${`${
        singleComp.constructorComponente.valores.valor
      } ${singleComp.constructorComponente.valores.hora!} `} ${
        singleComp.constructorComponente.valores.textoLibre
          ? ` ${singleComp.constructorComponente.valores.textoLibre}.`
          : ''
      }`;
    default:
      return '';
  }
};

const formatData = (singleComp: ICompUnico) => {
  switch (singleComp.formato) {
    case 1:
      return singleComp.constructorComponente.valores.valor
        || singleComp.constructorComponente.valores.textoLibre ? (
          <li className="py-1 text-gray-600">
            <span className="font-medium">{`${singleComp.constructorComponente.datosComponente.titulo}: `}</span>
            {singleComp.constructorComponente.valores.valor
              ? `${singleComp.constructorComponente.valores.valor} ${singleComp.constructorComponente
                .valores.respuestaBreve!}. `
              : ''}
            {singleComp.constructorComponente.valores.textoLibre
              ? singleComp.constructorComponente.valores.textoLibre.concat('.')
              : ''}
          </li>
        ) : null;
    case 2:
      return singleComp.constructorComponente.valores.textoLibre ? (
        <li className="py-1 text-gray-600">
          <span className="font-medium">{`${singleComp.constructorComponente.datosComponente.titulo}: `}</span>
          {singleComp.constructorComponente.valores.textoLibre.concat('.')}
        </li>
      ) : null;
    case 3:
      return singleComp.constructorComponente.valores.casillasOpciones!.arrOpciones.length > 0
        || singleComp.constructorComponente.valores.textoLibre ? (
          <li className="py-1 text-gray-600">
            <span className="font-medium">{`${singleComp.constructorComponente.datosComponente.titulo}: `}</span>
            <ul className="list-none">
              {singleComp.constructorComponente.valores.casillasOpciones!.arrOpciones.length > 0
                ? singleComp.constructorComponente.valores
                  .casillasOpciones!.arrOpciones.map(
                  (op) => `- ${op.nombreOpcion}${
                    op.arraySubOpciones.length ? ': ' : ''
                  }${op.arraySubOpciones.join(', ')}.`,
                )
                  .map((s) => <li>{s}</li>)
                : ''}
            </ul>
            {!!singleComp.constructorComponente.valores.textoLibre
            && ` ${singleComp.constructorComponente.valores.textoLibre}.`}
          </li>
        ) : null;
    case 4:
      return singleComp.constructorComponente.valores.casillasOpciones!.arrOpciones.length > 0
        || singleComp.constructorComponente.valores.textoLibre ? (
          <li className="py-1 text-gray-600">
            <span className="font-medium">{`${singleComp.constructorComponente.datosComponente.titulo}: `}</span>
            <ul className="list-none">
              {singleComp.constructorComponente.valores.casillasOpciones!.arrOpciones.length > 0
                ? singleComp.constructorComponente.valores
                  .casillasOpciones!.arrOpciones.map(
                  (op) => `- ${op.nombreOpcion}${
                    op.arraySubOpciones.length ? ': ' : ''
                  }${op.arraySubOpciones.join(', ')}.`,
                )
                  .map((s) => <li>{s}</li>)
                : ''}
            </ul>
            {!!singleComp.constructorComponente.valores.textoLibre
            && ` ${singleComp.constructorComponente.valores.textoLibre}.`}
          </li>
        ) : null;
    case 5:
      return singleComp.constructorComponente.valores.listaDesplegable!.length > 0
        || singleComp.constructorComponente.valores.textoLibre ? (
          <li className="py-1 text-gray-600">
            <span className="font-medium">{`${singleComp.constructorComponente.datosComponente.titulo}: `}</span>
            {singleComp.constructorComponente.valores.listaDesplegable!.length > 0
              ? singleComp.constructorComponente.valores.listaDesplegable!.join(', ').concat('.')
              : ''}
            {!!singleComp.constructorComponente.valores.textoLibre
            && ` ${singleComp.constructorComponente.valores.textoLibre}.`}
          </li>
        ) : null;
    case 6:
      return (
        <li className="py-1 text-gray-600">
          <span className="font-medium">{`${singleComp.constructorComponente.datosComponente.titulo}: `}</span>
          {`${
            singleComp.constructorComponente.valores.valor
              ? singleComp.constructorComponente.valores.valor
              : singleComp.constructorComponente.valores.escalaLineal!.valor.inicial
          } / ${singleComp.constructorComponente.valores.escalaLineal?.valor.final}.`}
          {!!singleComp.constructorComponente.valores.textoLibre
            && ` ${singleComp.constructorComponente.valores.textoLibre}.`}
        </li>
      );
    case 7: {
      const str = tablaToStringArr(singleComp.constructorComponente.valores.tabla!);
      return singleComp.constructorComponente.valores.tabla!.length ? (
        <li className="py-1 text-gray-600">
          <span className="font-medium">{`${singleComp.constructorComponente.datosComponente.titulo}: `}</span>
          <ul className="list-none">
            {str.map((s) => (
              <li>{s}</li>
            ))}
          </ul>
        </li>
      ) : null;
    }
    case 8:
      return singleComp.constructorComponente.valores.valor
        || singleComp.constructorComponente.valores.textoLibre ? (
          <li className="py-1 text-gray-600">
            <span className="font-medium">{`${singleComp.constructorComponente.datosComponente.titulo}: `}</span>
            {singleComp.constructorComponente.valores.valor
              ? `${fechaISOADiaMesAnioStr(singleComp.constructorComponente.valores.valor)}. `
              : ''}
            {!!singleComp.constructorComponente.valores.textoLibre
            && ` ${singleComp.constructorComponente.valores.textoLibre}.`}
          </li>
        ) : null;
    case 9:
      return singleComp.constructorComponente.valores.valor
        || singleComp.constructorComponente.valores.textoLibre ? (
          <li className="py-1 text-gray-600">
            <span className="font-medium">{`${singleComp.constructorComponente.datosComponente.titulo}: `}</span>
            {singleComp.constructorComponente.valores.valor
              ? `${singleComp.constructorComponente.valores.valor} ${singleComp.constructorComponente
                .valores.hora!}`
              : ''}
            {!!singleComp.constructorComponente.valores.textoLibre
            && ` ${singleComp.constructorComponente.valores.textoLibre}.`}
          </li>
        ) : null;
    default:
      return null;
  }
};

const conoceEdad = (anio: string, mes: string, dia: string, t: any) => {
  let edadCalculada = parseInt(calculateEdad(anio, mes, dia), 10);
  let returnText = '';
  if (edadCalculada >= 2) {
    returnText = ` ${edadCalculada.toString()} ${t('anios')}`;
  } else {
    edadCalculada = calculateMeses(anio, mes, dia);
    if (edadCalculada >= 1) {
      returnText = ` ${edadCalculada.toString()} ${t('meses')}`;
    } else {
      edadCalculada = calculateDias(anio, mes, dia);
      returnText = ` ${edadCalculada.toString()} ${t('dias')}`;
    }
  }
  return returnText;
};

function PadecimientoActual({ mcpa, setMCPA }: IPadecimientoActualProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    sexo, anio, mes, dia,
  } = useSelector((store: RootState) => store.BasicosPaciente);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const handleSaveMCPA = () => {
    dispatch(setLoading(true));
    // aqui mandamos un poco listo para la tabla MCPA_INFO
    const objEnviar = {
      idMedico,
      idMCPAInfo: mcpa.id,
      idConsulta,
      constructor: mcpa.componentes,
      resumen: mcpa.componentes.map((singleComp) => stringData(singleComp)).join('\n'),
      idMotivosConsulta: mcpa.formato.id,
      opciones: mcpa.opciones,
      tipo: mcpa.formato.tipo === 'medipraxi',
    };
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    mcpaGuardarMCPA(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setMCPA({
            ...mcpa,
            id: result.datos,
          });
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${result.msg}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-get-error')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleEditMCPA = () => {
    dispatch(setLoading(true));
    // aqui mandamos un poco listo para la tabla MCPA_INFO
    const objEnviar = {
      idMedico,
      idMCPAInfo: mcpa.id,
      constructor: mcpa.componentes,
      resumen: mcpa.componentes.map((singleComp) => stringData(singleComp)).join('\n'),
      idMotivosConsulta: mcpa.formato.id,
      opciones: mcpa.opciones,
      tipo: mcpa.formato.tipo === 'medipraxi',
    };
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    mcpaEditarMCPA(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setMCPA({
            ...mcpa,
            id: result.datos,
          });
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${result.msg}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-get-error')} ${err.toString()}`,
          }),
        );
      });
    dispatch(setLoading(false));
  };
  return (
    <div className="p-4 shadow-lg bg-white rounded border border-solid border-gray-200 mt-2">
      <AlertMultipleDialog
        open={alertOpen}
        titulo="¡Atención!"
        descripcion={[
          t('_seguro_que_los_datos_son_correctos_'),
          t('no_podras_cambiar_el_formato_de_consulta_despues_de_guardar'),
        ]}
        icono={<WarningIcon className="text-yellow-500" color="inherit" fontSize="large" />}
        nombreCancelar={t('cancelar')}
        nombreAceptar={t('aceptar')}
        callBackAceptar={handleSaveMCPA}
        callBackClose={handleAlertClose}
      />
      <h2 className="text-center text-blue-800 mt-0 font-medium">{t('padecimiento_actual')}</h2>
      <p className="font-medium">{`${t('motivo_consulta')}: ${mcpa.formato.nombre}`}</p>
      <ul className="list-outside list-none pl-0">
        <li className="py-1 text-gray-600">
          <span className="font-medium">{`${t('sexo')}: `}</span>
          {sexo === 'H' && `${t('hombre')}`}
          {sexo === 'M' && `${t('mujer')}`}
        </li>
        <li className="py-1 text-gray-600">
          <span className="font-medium">{`${t('edad')}:`}</span>
          {anio && mes && dia && conoceEdad(anio, mes, dia, t)}
        </li>
        {mcpa.componentes.map((singleComp) => formatData(singleComp))}
        {mcpa.opciones.notasAdicionales.incluido
          && mcpa.opciones.notasAdicionales.comentarios.length > 0 && (
            <li className="py-1 text-gray-600">
              <span className="font-medium">{`${t('notas_adicionales')}: `}</span>
              {`${mcpa.opciones.notasAdicionales.comentarios}.`}
            </li>
        )}
      </ul>
      <div className="flex justify-end mb-4">
        {mcpa.id > 0 ? (
          <Button
            variant="contained"
            color="primary"
            type="button"
            size="large"
            startIcon={<SyncIcon />}
            onClick={handleEditMCPA}
          >
            {t('editar')}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="button"
            size="large"
            startIcon={<SaveIcon />}
            onClick={() => setAlertOpen(true)}
          >
            {t('guardar')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default PadecimientoActual;
