import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { IAneurismaAortaAbdominalProps } from './types';
import { getToken, getRecomendacionReferencia } from '../../../../../../../utils/common';
import { RootState } from '../../../../../../../store';
import ModalRecomendacion from '../../ModalRecomendacion';

function DeteccionAneurismaAortaAbdominal({ txtToxicomania }: IAneurismaAortaAbdominalProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { secciones } = useSelector((state: RootState) => state.Me);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [ref, setRef] = useState<any>({});

  return (
    <div className="flex-1">
      <ModalRecomendacion
        open={openModal}
        callBackClose={() => {
          setOpenModal(false);
          setRef({});
        }}
        referencia={ref}
      />
      {txtToxicomania !== '' && txtToxicomania !== 'ERROR' && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
          <div className="border-0 border-solid border-red-600 rounded border-l-8 p-4 pr-5 w-full">
            <h3 className="m-0 text-left text-blue-500 font-normal">{t('det_tem_aaa')}</h3>
            <div className="flex pt-2">
              <h4 className="font-normal m-0 text-gray-900 pr-1">
                {`${t('det_tem_can_analisis')}: `}
              </h4>
              <h4 className="font-normal m-0 text-red-500">{t('det_tem_can_alto')}</h4>
            </div>
            <div className="flex pt-2">
              <h4 className="font-normal m-0 text-gray-500 pr-4">
                {`${t('edad')}: ${t('det_tem_aaa_65_75')}`}
              </h4>
              <h4 className="font-normal m-0 text-gray-500 pr-4">
                {`${t('sexo')}: ${t('hombre')}`}
              </h4>
              <h4 className="font-normal m-0 text-gray-500 pr-4">
                {`${t('det_tem_can_estatus')}: ${
                  txtToxicomania === 'activo'
                    ? t('det_tem_can_estatus_activo')
                    : t('det_tem_can_estatus_ex')
                }`}
              </h4>
            </div>
            <h4 className="font-normal m-0 text-gray-900 pt-2">{`${t('det_tem_ustp')}:`}</h4>
            <h4 className="font-normal m-0 text-gray-500 py-2">{t('det_tem_ultrasonido')}</h4>
          </div>
          <div className="min-w-max self-end">
            <Button
              color="primary"
              onClick={() => {
                const token = getToken();
                fetch(getRecomendacionReferencia('6'), {
                  method: 'GET',
                  headers: { Authorization: `Bearer ${token}` },
                })
                  .then((response) => response.json())
                  .then((result) => {
                    if (result.code === 200 && result.datos && result.datos.length > 0) {
                      setRef(result.datos[0]);
                      setOpenModal(true);
                    }
                  })
                  .catch(() => {
                    setRef({});
                  });
              }}
            >
              {t('ver_recomendacion')}
            </Button>
          </div>
        </div>
      )}
      {txtToxicomania === 'ERROR' && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
          <div className="border-0 border-solid border-red-600 rounded border-l-8 p-4 pr-5 w-full">
            <h3 className="m-0 text-left text-blue-500 font-normal">{t('det_tem_aaa')}</h3>
            <ul>
              <li className="text-red-500">
                <span className="text-gray-500">{t('det_tem_can_txt1')}</span>
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 28) && (
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push('/ece/antecedentes/personales-no-patologicos/toxicomania');
                    }}
                  >
                    {`${t('det_tem_can_txt2')}.`}
                  </Button>
                )}
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeteccionAneurismaAortaAbdominal;
