import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  TextField,
  Checkbox,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AlertDialog from '../../../../components/AlertDialogs/AlertDialog';
import ResumenCambios from '../../../../components/ResumenCambios';
import BotonesGuardar from '../../../../components/BotonesGuardar';
import useStyles from './styles';
import { ICatalogosEstandar, IFechasFields } from '../../../../common/types';
import { getCatalogoAseguradoras } from '../../../../utils/getCatalogos';
import { ISegurosPlanesProps, ISegurosPlanes, segurosPlanesInitial } from './types';
/** importancion de las fechas para la fecha de nacimiento */
import { constDia, constMes, constAnioPoliza } from '../../../../constants/fechas';
/** importacion de funciones */
import { capitalize, validarFecha, validarFechaEsfutura } from '../../../../common/functions';
import {
  updateSegurosPlanes,
  updateSegurosPlanesPaciente,
  updateSegurosPlanesUsuario,
} from '../../../../utils/sendInfo';
import { RootState } from '../../../../store';
import { setRequest } from '../../../../actions/request/types';

/** Formulario del seguro del paciente */
function SegurosPlanes(props: ISegurosPlanesProps) {
  const {
    setActiveStep,
    segurosPlanes,
    setSegurosPlanes,
    cambiosUsuario,
    segurosPlanesUsuario,
    setSegurosPlanesUsuario,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
    pais,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  const [segurosPlanesTemp, setSegurosPlanesTemp] = useState<ISegurosPlanes>(segurosPlanesInitial);
  /** states de comprobacion de fields */
  const [fechaField, setFechaField] = useState<IFechasFields>({
    dia: {
      error: false,
      helperText: '',
    },
    mes: {
      error: false,
      helperText: '',
    },
    anio: {
      error: false,
      helperText: '',
    },
  });
  /** states de componentes que se deshabilitan */
  /** states para mostrar o no diferentes campos */
  const [refCualAseguradora, setRefCualAseguradora] = useState<boolean>(true);
  /** states de la snackbar y el alert */
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  /** catalogos */
  const [catAseguradoras, setCatAseguradoras] = useState<ICatalogosEstandar[]>([]);
  /** logica */
  const hayCambios: boolean = idUsuario > 0 && cambiosUsuario.filter((c: string) => c.length > 0).length > 0;
  const verificarErrorValidacionGeneral = !segurosPlanesTemp.aseguradora
    && !segurosPlanesTemp.numPoliza
    && !segurosPlanesTemp.vigencia.dia
    && !segurosPlanesTemp.vigencia.mes
    && !segurosPlanesTemp.vigencia.anio
    && !segurosPlanesTemp.sumaAsegurada
    && !segurosPlanesTemp.noTieneAseguradora;
  /** hooks como useEffect */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCatalogoAseguradoras(pais).then((result: ICatalogosEstandar[]) => {
      setCatAseguradoras(result);
    });
  }, []);

  useEffect(() => {
    if (segurosPlanes.loaded && !segurosPlanesTemp.loaded) {
      setSegurosPlanesTemp(segurosPlanes);
    }
  }, [segurosPlanes]);

  useEffect(() => {
    if (
      segurosPlanesTemp.vigencia.dia !== ''
      && segurosPlanesTemp.vigencia.mes !== ''
      && segurosPlanesTemp.vigencia.anio !== ''
    ) {
      if (
        !validarFecha(
          segurosPlanesTemp.vigencia.anio,
          segurosPlanesTemp.vigencia.mes,
          segurosPlanesTemp.vigencia.dia,
        )
      ) {
        setFechaField({
          dia: {
            error: true,
            helperText: ' ',
          },
          mes: {
            error: true,
            helperText: t('fecha_invalida'),
          },
          anio: {
            error: true,
            helperText: ' ',
          },
        });
      } else if (
        !validarFechaEsfutura(
          segurosPlanesTemp.vigencia.anio,
          segurosPlanesTemp.vigencia.mes,
          segurosPlanesTemp.vigencia.dia,
        )
      ) {
        setFechaField({
          dia: {
            error: true,
            helperText: ' ',
          },
          mes: {
            error: true,
            helperText: t('vigencia_expirada'),
          },
          anio: {
            error: true,
            helperText: ' ',
          },
        });
      } else {
        setFechaField({
          dia: {
            error: false,
            helperText: '',
          },
          mes: {
            error: false,
            helperText: '',
          },
          anio: {
            error: false,
            helperText: '',
          },
        });
      }
    }
  }, [segurosPlanesTemp.vigencia]);
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers de la snackbar y alert */
  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  /** Handlers del componente */
  const handleChangeAseguradora = (event: any, newValue: ICatalogosEstandar | null) => {
    const ref = newValue === null || newValue.label !== 'OTRA';
    setSegurosPlanesTemp({
      ...segurosPlanesTemp,
      aseguradora: newValue !== null ? newValue : { id: 0, label: '' },
      cualAseguradora: ref ? '' : segurosPlanesTemp.cualAseguradora,
    });
    setRefCualAseguradora(ref);
  };

  const handleChangeCualAseguradora = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSegurosPlanesTemp({
      ...segurosPlanesTemp,
      cualAseguradora: event.target.value as string,
    });
  };

  const handleChangeNumPoliza = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSegurosPlanesTemp({
      ...segurosPlanesTemp,
      numPoliza: event.target.value as string,
    });
  };

  const handleChangeVigencia = (event: React.ChangeEvent<{ value: unknown }>, tipo: string) => {
    const val = (event.target.value as string).toString();
    if (tipo === 'dia') {
      setSegurosPlanesTemp({
        ...segurosPlanesTemp,
        vigencia: {
          ...segurosPlanesTemp.vigencia,
          dia: val,
        },
      });
    } else if (tipo === 'mes') {
      setSegurosPlanesTemp({
        ...segurosPlanesTemp,
        vigencia: {
          ...segurosPlanesTemp.vigencia,
          mes: val,
        },
      });
    } else {
      setSegurosPlanesTemp({
        ...segurosPlanesTemp,
        vigencia: {
          ...segurosPlanesTemp.vigencia,
          anio: val,
        },
      });
    }
  };

  const handleChangeSumaAsegurada = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSegurosPlanesTemp({
      ...segurosPlanesTemp,
      sumaAsegurada: (event.target.value as string).replaceAll(/\D/g, ''),
    });
  };

  const handleChangeComentarios = (event: React.ChangeEvent<{ value: string }>) => {
    setSegurosPlanesTemp({
      ...segurosPlanesTemp,
      comentarios: event.target.value,
    });
  };
  const handleChangeNoTieneAseguradora = (event: React.ChangeEvent<HTMLInputElement>) => {
    const noTieneAseguradora = event.target.checked as boolean;
    if (noTieneAseguradora) {
      setSegurosPlanesTemp({
        ...segurosPlanesTemp,
        noTieneAseguradora,
        aseguradora: { id: 0, label: '' },
        numPoliza: '',
        vigencia: { dia: '', mes: '', anio: '' },
        sumaAsegurada: '',
        comentarios: '',
        cualAseguradora: '',
      });
    } else {
      setSegurosPlanesTemp({
        ...segurosPlanesTemp,
        noTieneAseguradora,
      });
    }
  };

  const getData = (datos: ISegurosPlanes, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsultorio,
    noTieneAseguradora: datos.noTieneAseguradora,
    aseguradora: datos.aseguradora ? datos.aseguradora.id : '',
    cualAseguradora: datos.cualAseguradora,
    numPoliza: datos.numPoliza,
    vigDia: datos.vigencia.dia.toString(),
    vigMes: datos.vigencia.mes.toString(),
    vigAnio: datos.vigencia.anio.toString(),
    sumaAsegurada: datos.sumaAsegurada,
    comentarios: datos.comentarios,
  });

  /** Handlers para enviar la informacion */
  const guardarDatosExpediente = () => {
    const datosSegurosPlanes = getData(segurosPlanesTemp, idUsuario);
    const sendFunctions = [updateSegurosPlanes(datosSegurosPlanes)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateSegurosPlanesPaciente(datosSegurosPlanes));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => setSegurosPlanes({ ...segurosPlanesTemp, loaded: true }),
          () => setSegurosPlanesUsuario({ ...segurosPlanesTemp, loaded: true }),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateSegurosPlanesUsuario(getData(segurosPlanesTemp, idMiUsuario)),
        successFunction: () => {
          setSegurosPlanes({ ...segurosPlanesTemp, loaded: true });
          setActiveStep('antecedentes');
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateSegurosPlanes(getData(segurosPlanesUsuario)),
        successFunction: () => {
          setSegurosPlanes(segurosPlanesUsuario);
          setSegurosPlanesTemp(segurosPlanesUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateSegurosPlanesPaciente(getData(segurosPlanes, idUsuario)),
        successFunction: () => setSegurosPlanesUsuario(segurosPlanes),
      }),
    );
  };

  return (
    <div id="seguros-planes-paciente" aria-labelledby="seguros-planes-paciente">
      <AlertDialog
        open={alertOpen}
        titulo="¡Atención!"
        descripcion=""
        nombreCancelar="Regresar"
        nombreAceptar="Guardar"
        callBackAceptar={guardarDatosExpediente}
        callBackClose={handleAlertClose}
      />
      <div className="p-4 bg-white container mx-auto shadow rounded-b-md border-solid border border-gray-100">
        {hayCambios && <ResumenCambios cambios={cambiosUsuario} />}
        <form>
          <div className="grid grid-cols-4 gap-4 mt-4 mb-6">
            <div>
              <h3 className="text-gray-600 font-medium m-0 mb-2">{t('aseguradora')}</h3>
              <Autocomplete
                disabled={segurosPlanesTemp.noTieneAseguradora || hayCambios}
                options={catAseguradoras}
                getOptionLabel={(option) => option.label}
                fullWidth
                value={segurosPlanesTemp.aseguradora}
                noOptionsText={t('sin_resultados')}
                onChange={(e, nV) => handleChangeAseguradora(e, nV)}
                // inputValue={segurosPlanesTemp.aseguradora.label}
                // onInputChange={(e, newInputValue) => setSegurosPlanesTemp({ ...segurosPlanesTemp, aseguradora: newInputValue })}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
            {!refCualAseguradora && (
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t('especifica')}</h3>
                <TextField
                  disabled={hayCambios}
                  variant="outlined"
                  fullWidth
                  value={segurosPlanesTemp.cualAseguradora}
                  onChange={handleChangeCualAseguradora}
                  inputProps={{ maxLength: 60, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                />
              </div>
            )}
            <div className="justify-center self-end" style={{ height: '56px' }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    disabled={hayCambios}
                    checked={segurosPlanesTemp.noTieneAseguradora}
                    onChange={handleChangeNoTieneAseguradora}
                    name="checked-desconoce-aseguradora"
                    color="primary"
                  />
                )}
                label={t('no_tiene')}
              />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 mb-6">
            <div>
              <h3 className="text-gray-600 font-medium m-0 mb-2">{t('num_poliza')}</h3>
              <TextField
                variant="outlined"
                fullWidth
                value={segurosPlanesTemp.numPoliza}
                disabled={segurosPlanesTemp.noTieneAseguradora || hayCambios}
                onChange={handleChangeNumPoliza}
                inputProps={{ maxLength: 20, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              />
            </div>
            <div className="col-span-2">
              <h3 className="text-gray-600 font-medium m-0 mb-2">{t('vigencia')}</h3>
              <div className=" grid grid-cols-12 gap-2">
                <div className="col-span-3">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    disabled={segurosPlanesTemp.noTieneAseguradora}
                  >
                    <InputLabel id="label-dia">{t('dia')}</InputLabel>
                    <Select
                      disabled={hayCambios}
                      labelId="label-dia"
                      id="select-dia"
                      label={t('dia')}
                      value={segurosPlanesTemp.vigencia.dia}
                      onChange={(e) => handleChangeVigencia(e, 'dia')}
                      displayEmpty
                      error={fechaField.dia.error}
                    >
                      <MenuItem value="" />
                      {constDia.map((diaSingle) => (
                        <MenuItem value={diaSingle.toString()} key={diaSingle}>
                          {diaSingle}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{fechaField.dia.helperText}</FormHelperText>
                  </FormControl>
                </div>
                <div className="col-span-5">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    disabled={segurosPlanesTemp.noTieneAseguradora}
                  >
                    <InputLabel id="label-mes">{t('mes')}</InputLabel>
                    <Select
                      disabled={hayCambios}
                      labelId="label-mes"
                      id="select-mes"
                      label={t('mes')}
                      value={segurosPlanesTemp.vigencia.mes}
                      onChange={(e) => handleChangeVigencia(e, 'mes')}
                      error={fechaField.mes.error}
                      displayEmpty
                    >
                      <MenuItem value="" />
                      {constMes.map((mesSingle, indx) => (
                        <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                          {capitalize(mesSingle)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{fechaField.mes.helperText}</FormHelperText>
                  </FormControl>
                </div>
                <div className="col-span-4">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    disabled={segurosPlanesTemp.noTieneAseguradora}
                  >
                    <InputLabel id="label-año">{t('anio')}</InputLabel>
                    <Select
                      disabled={hayCambios}
                      labelId="label-año"
                      id="select-año"
                      label={t('anio')}
                      value={segurosPlanesTemp.vigencia.anio}
                      onChange={(e) => handleChangeVigencia(e, 'anio')}
                      error={fechaField.anio.error}
                      displayEmpty
                    >
                      <MenuItem value="" />
                      {constAnioPoliza().map((anioSingle) => (
                        <MenuItem key={anioSingle} value={anioSingle.toString()}>
                          {anioSingle}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{fechaField.anio.helperText}</FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-gray-600 font-medium m-0 mb-2">{t('suma_asegurada')}</h3>
              <FormControl
                fullWidth
                variant="outlined"
                disabled={segurosPlanesTemp.noTieneAseguradora}
              >
                <OutlinedInput
                  disabled={hayCambios}
                  value={
                    segurosPlanesTemp.sumaAsegurada
                      ? parseInt(segurosPlanesTemp.sumaAsegurada, 10).toLocaleString('es-mx')
                      : ''
                  }
                  onChange={handleChangeSumaAsegurada}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  inputProps={{ maxLength: 13, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                />
              </FormControl>
            </div>
          </div>
          <div className="mb-6">
            <h3 className="text-gray-600 font-medium m-0 mb-2">{t('comentarios')}</h3>
            <TextField
              variant="outlined"
              fullWidth
              value={segurosPlanesTemp.comentarios}
              onChange={(e) => {
                handleChangeComentarios(e);
                const { target } = e;
                const caret = target.selectionStart;
                const element = target;
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret;
                  element.selectionEnd = caret;
                });
              }}
              multiline
              rows={2}
              helperText={`${
                segurosPlanesTemp.comentarios ? segurosPlanesTemp.comentarios.length : 0
              }/300`}
              inputProps={{ maxLength: 300, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              FormHelperTextProps={{ className: classes.helperText }}
              inputRef={React.useRef(null)}
              disabled={segurosPlanesTemp.noTieneAseguradora || hayCambios}
            />
          </div>
          <div className="flex justify-end">
            <BotonesGuardar
              hayCambios={hayCambios}
              aceptarCallback={guardarDatosDeUsuarioAExpediente}
              cancelarCallback={guardarDatosDeExpedienteAUsuario}
              guardarCallback={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
              guardarDisable={verificarErrorValidacionGeneral}
              continuar={esPaciente}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default SegurosPlanes;
