import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { Autocomplete } from '@material-ui/lab';
import { setLoading } from '../../../../actions/loading/actions';
import { RootState } from '../../../../store';
import { setSnackComplete } from '../../../../actions/snackbar/types';
import { constDia, constMes, constAnio } from '../../../../constants/fechas';
import { ICatalogosEstandar, IFechaObj } from '../../../../common/types';
import { IDatosIncapacidad } from '../types';
import { setRequest } from '../../../../actions/request/types';
import { sendIncapacidades } from '../../../../utils/sendInfo';
import {
  getCatalogoIncapacidadesControlIncapacidad,
  getCatalogoIncapacidadesRamoSeguro,
  getCatalogoIncapacidadesSecuela,
  getCatalogoIncapacidadesTipoIncidencia,
  getCatalogoIncapacidadesTipoRiesgo,
} from '../../../../utils/getCatalogos';
import { IFormularioIncapacidadesProps } from './types';

dayjs.locale('es');

const FormularioIncapacidades = (props: IFormularioIncapacidadesProps) => {
  const {
    listaIncapacidades,
    setListaIncapacidades,
    accidentesLaborales,
    idActualizar,
    setIdActualizar,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const [catTipoIncidencia, setCatTipoIncidencia] = useState<ICatalogosEstandar[]>([]);
  const [catRamoSeguro, setCatRamoSeguro] = useState<ICatalogosEstandar[]>([]);
  const [catTipoRiesgo, setCatTipoRiesgo] = useState<ICatalogosEstandar[]>([]);
  const [catSecuela, setCatSecuela] = useState<ICatalogosEstandar[]>([]);
  const [catControlIncapacidad, setCatControlIncapacidad] = useState<ICatalogosEstandar[]>([]);
  const [id, setId] = useState<number>(-1);
  const [tipo, setTipo] = useState<'interna' | 'imss'>('interna');
  const [fecha, setFecha] = useState<IFechaObj>({ dia: '', mes: '', anio: '' });
  const [tipoIncidencia, setTipoIncidencia] = useState<ICatalogosEstandar>({ id: 0, label: '' });
  const [folio, setFolio] = useState<string>('######');
  const [diasIncapacidad, setDiasIncapacidad] = useState<string>('');
  const [fechaInicio, setFechaInicio] = useState<IFechaObj>({ dia: '', mes: '', anio: '' });
  const [ramoSeguro, setRamoSeguro] = useState<ICatalogosEstandar>({ id: 0, label: '' });
  const [tipoRiesgo, setTipoRiesgo] = useState<ICatalogosEstandar>({ id: 0, label: '' });
  const [secuela, setSecuela] = useState<ICatalogosEstandar>({ id: 0, label: '' });
  const [controlIncapacidad, setControlIncapacidad] = useState<ICatalogosEstandar>({
    id: 0,
    label: '',
  });
  const [comentarios, setComentarios] = useState<string>('');
  const [accidenteLaboral, setAccidenteLaboral] = useState<number>(0);

  const handleChangeFecha = (event: React.ChangeEvent<{ value: unknown }>, tp: string) => {
    const dataUpd = { ...fecha };
    const valAdd = (event.target.value as string).toString();
    if (tp === 'dia') {
      dataUpd.dia = valAdd;
    } else if (tp === 'mes') {
      dataUpd.mes = valAdd;
    } else if (tp === 'anio') {
      dataUpd.anio = valAdd;
    }
    setFecha(dataUpd);
  };

  const handleChangeFechaInicio = (event: React.ChangeEvent<{ value: unknown }>, tp: string) => {
    const dataUpd = { ...fechaInicio };
    const valAdd = (event.target.value as string).toString();
    if (tp === 'dia') {
      dataUpd.dia = valAdd;
    } else if (tp === 'mes') {
      dataUpd.mes = valAdd;
    } else if (tp === 'anio') {
      dataUpd.anio = valAdd;
    }
    setFechaInicio(dataUpd);
  };

  const guardarIncapacidad = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendIncapacidades({
          idMedico,
          idPaciente,
          idConsultorio,
          idIncapacidad: id,
          tipo,
          fechaDia: fecha.dia,
          fechaMes: fecha.mes,
          fechaAnio: fecha.anio,
          tipoIncidencia: tipoIncidencia.id,
          folio,
          diasIncapacidad,
          fechaInicioDia: fecha.dia,
          fechaInicioMes: fecha.mes,
          fechaInicioAnio: fecha.anio,
          ramoSeguro: ramoSeguro.id,
          tipoRiesgo: tipoRiesgo.id,
          secuela: secuela.id,
          controlIncapacidad: controlIncapacidad.id,
          accidenteLaboral,
          comentarios,
        }),
        successFunction: (result: any) => {
          setIdActualizar(-1);
          const newIncapacidad: IDatosIncapacidad = {
            id: result.data.id,
            tipo,
            fecha,
            tipoIncidencia,
            folio: result.data.folio,
            diasIncapacidad: parseInt(diasIncapacidad, 10),
            fechaInicio,
            ramoSeguro,
            tipoRiesgo,
            secuela,
            controlIncapacidad,
            accidenteLaboral: accidentesLaborales.find(
              (accidente: ICatalogosEstandar) => accidente.id === accidenteLaboral,
            ) || { id: 0, label: '' },
            comentarios,
          };
          if (id > 0) {
            setListaIncapacidades((prev: IDatosIncapacidad[]) => prev.map((incapacidad: IDatosIncapacidad) => (incapacidad.id === id ? newIncapacidad : incapacidad)));
          } else {
            setListaIncapacidades([...listaIncapacidades, newIncapacidad]);
          }
          setTipo('interna');
          setFecha({ dia: '', mes: '', anio: '' });
          setTipoIncidencia({ id: 0, label: '' });
          setFolio('######');
          setDiasIncapacidad('');
          setFechaInicio({ dia: '', mes: '', anio: '' });
          setRamoSeguro({ id: 0, label: '' });
          setTipoRiesgo({ id: 0, label: '' });
          setSecuela({ id: 0, label: '' });
          setControlIncapacidad({ id: 0, label: '' });
          setAccidenteLaboral(0);
          setComentarios('');
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const fetchTipoIncidencia = getCatalogoIncapacidadesTipoIncidencia();
    const fetchRamoSeguro = getCatalogoIncapacidadesRamoSeguro();
    const fetchTipoRiesgo = getCatalogoIncapacidadesTipoRiesgo();
    const fetchSecuela = getCatalogoIncapacidadesSecuela();
    const fetchControlIncapacidad = getCatalogoIncapacidadesControlIncapacidad();
    Promise.all([
      fetchTipoIncidencia,
      fetchRamoSeguro,
      fetchTipoRiesgo,
      fetchSecuela,
      fetchControlIncapacidad,
    ])
      .then((responses) => {
        Promise.all(responses).then((results) => {
          setCatTipoIncidencia(results[0] || []);
          setCatRamoSeguro(results[1] || []);
          setCatTipoRiesgo(results[2] || []);
          setCatSecuela(results[3] || []);
          setCatControlIncapacidad(results[4] || []);
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message_get_success'),
            }),
          );
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-get-error')} ${err.toString()}`,
          }),
        );
      });
  }, []);

  useEffect(() => {
    if (idActualizar > 0) {
      const incapacidad = listaIncapacidades.find(
        (inc: IDatosIncapacidad) => inc.id === idActualizar,
      );
      if (incapacidad) {
        setId(incapacidad.id);
        setTipo(incapacidad.tipo);
        setFecha(incapacidad.fecha);
        setTipoIncidencia(incapacidad.tipoIncidencia);
        setFolio(incapacidad.folio);
        setDiasIncapacidad(incapacidad.diasIncapacidad.toString());
        setFechaInicio(incapacidad.fechaInicio);
        setRamoSeguro(incapacidad.ramoSeguro);
        setTipoRiesgo(incapacidad.tipoRiesgo);
        setSecuela(incapacidad.secuela);
        setControlIncapacidad(incapacidad.controlIncapacidad);
        setComentarios(incapacidad.comentarios);
      }
    }
  }, [idActualizar]);

  return (
    <div>
      <div className="my-2 grid justify-end">
        <FormControl fullWidth>
          <RadioGroup
            row
            aria-label="tipo"
            name="tipo"
            value={tipo}
            onChange={(e) => {
              const tp = e.target.value as 'interna' | 'imss';
              setTipo(tp);
              if (id <= 0) {
                setFolio(tp === 'imss' ? '' : '######');
              }
            }}
          >
            <FormControlLabel
              disabled={id > 0}
              value="interna"
              control={<Radio color="primary" />}
              label="Interna"
            />
            <FormControlLabel
              disabled={id > 0}
              value="imss"
              control={<Radio color="primary" />}
              label="IMSS"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="grid grid-cols-3 gap-x-4 gap-y-2 mb-6">
        <h3 className="text-gray-600 font-medium m-0">{t('fecha')}</h3>
        <h3 className="text-gray-600 font-medium m-0">{t('incapacidades_tipo_incidencia')}</h3>
        <h3 className="text-gray-600 font-medium m-0">
          {`Folio ${
            tipo === 'imss' ? 'IMSS' : 'interno'
          }`}
        </h3>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-3">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-dia">{t('dia')}</InputLabel>
              <Select
                labelId="label-dia"
                id="select-dia"
                value={fecha.dia}
                onChange={(e) => handleChangeFecha(e, 'dia')}
                label={t('dia')}
                // error={fechaField.dia.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
                displayEmpty
              >
                <MenuItem value={0} disabled>
                  <span className="text-gray-400 font-light">{t('selecciona')}</span>
                </MenuItem>
                {constDia.map((diaSingle) => (
                  <MenuItem value={diaSingle} key={diaSingle}>
                    {diaSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-span-5">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-mes">{t('mes')}</InputLabel>
              <Select
                labelId="label-mes"
                id="select-mes"
                value={fecha.mes}
                onChange={(e) => handleChangeFecha(e, 'mes')}
                label={t('mes')}
                // error={fechaField.mes.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                <MenuItem value={0} disabled>
                  <span className="text-gray-400 font-light">{t('selecciona')}</span>
                </MenuItem>
                {constMes.map((mesSingle, indx) => (
                  <MenuItem value={indx + 1} key={mesSingle}>
                    {mesSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-span-4">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-año">{t('anio')}</InputLabel>
              <Select
                labelId="label-año"
                id="select-año"
                value={fecha.anio}
                onChange={(e) => handleChangeFecha(e, 'anio')}
                label={t('anio')}
                // error={fechaField.anio.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                <MenuItem value={0} disabled>
                  <span className="text-gray-400 font-light">{t('selecciona')}</span>
                </MenuItem>
                {constAnio().map((anioSingle) => (
                  <MenuItem key={anioSingle} value={anioSingle}>
                    {anioSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <Autocomplete
          options={catTipoIncidencia}
          getOptionLabel={(option) => option.label}
          fullWidth
          noOptionsText={t('sin_resultados')}
          value={tipoIncidencia}
          onChange={(e, nV) => setTipoIncidencia(nV || { id: 0, label: '' })}
          renderInput={(params) => (
            <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
          )}
        />
        {tipo === 'imss' ? (
          <TextField
            variant="outlined"
            onChange={(e) => setFolio(e.target.value as string)}
            value={folio}
            fullWidth
            inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
          />
        ) : (
          <span className="text-gray-400 font-medium m-0 pt-4">{folio}</span>
        )}
      </div>
      <div className="grid grid-cols-3 gap-x-4 gap-y-2 mb-6">
        <h3 className="text-gray-600 font-medium m-0">{t('incapacidades_dias_autorizados')}</h3>
        <h3 className="text-gray-600 font-medium m-0">{t('fecha_inicio')}</h3>
        <h3 className="text-gray-600 font-medium m-0">{t('incapacidades_ramo_seguro')}</h3>
        <div>
          <TextField
            variant="outlined"
            onChange={(e) => setDiasIncapacidad(e.target.value as string)}
            value={diasIncapacidad}
            inputProps={{ maxLength: 3, autoComplete: 'off', 'aria-autocomplete': 'none' }}
          />
        </div>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-3">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-dia">{t('dia')}</InputLabel>
              <Select
                labelId="label-dia"
                id="select-dia"
                value={fechaInicio.dia}
                onChange={(e) => handleChangeFechaInicio(e, 'dia')}
                label={t('dia')}
                // error={fechaField.dia.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
                displayEmpty
              >
                <MenuItem value={0} disabled>
                  <span className="text-gray-400 font-light">{t('selecciona')}</span>
                </MenuItem>
                {constDia.map((diaSingle) => (
                  <MenuItem value={diaSingle} key={diaSingle}>
                    {diaSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-span-5">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-mes">{t('mes')}</InputLabel>
              <Select
                labelId="label-mes"
                id="select-mes"
                value={fechaInicio.mes}
                onChange={(e) => handleChangeFechaInicio(e, 'mes')}
                label={t('mes')}
                // error={fechaField.mes.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                <MenuItem value={0} disabled>
                  <span className="text-gray-400 font-light">{t('selecciona')}</span>
                </MenuItem>
                {constMes.map((mesSingle, indx) => (
                  <MenuItem value={indx + 1} key={mesSingle}>
                    {mesSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-span-4">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-año">{t('anio')}</InputLabel>
              <Select
                labelId="label-año"
                id="select-año"
                value={fechaInicio.anio}
                onChange={(e) => handleChangeFechaInicio(e, 'anio')}
                label={t('anio')}
                // error={fechaField.anio.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                <MenuItem value={0} disabled>
                  <span className="text-gray-400 font-light">{t('selecciona')}</span>
                </MenuItem>
                {constAnio().map((anioSingle) => (
                  <MenuItem key={anioSingle} value={anioSingle}>
                    {anioSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <Autocomplete
          options={catRamoSeguro}
          getOptionLabel={(option) => option.label}
          fullWidth
          noOptionsText={t('sin_resultados')}
          value={ramoSeguro}
          onChange={(e, nV) => setRamoSeguro(nV || { id: 0, label: '' })}
          renderInput={(params) => (
            <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
          )}
        />
      </div>
      <div className="grid grid-cols-3 gap-x-4 gap-y-2 mb-6">
        <h3 className="text-gray-600 font-medium m-0">{t('incapacidades_tipo_riesgo')}</h3>
        <h3 className="text-gray-600 font-medium m-0">{t('incapacidades_secuela')}</h3>
        <h3 className="text-gray-600 font-medium m-0">{t('incapacidades_control_incapacidad')}</h3>
        <Autocomplete
          disabled={tipoIncidencia.id !== 2}
          options={catTipoRiesgo}
          getOptionLabel={(option) => option.label}
          fullWidth
          noOptionsText={t('sin_resultados')}
          value={tipoRiesgo}
          onChange={(e, nV) => setTipoRiesgo(nV || { id: 0, label: '' })}
          renderInput={(params) => (
            <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
          )}
        />
        <Autocomplete
          disabled={tipoIncidencia.id !== 2}
          options={catSecuela}
          getOptionLabel={(option) => option.label}
          fullWidth
          noOptionsText={t('sin_resultados')}
          value={secuela}
          onChange={(e, nV) => setSecuela(nV || { id: 0, label: '' })}
          renderInput={(params) => (
            <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
          )}
        />
        <Autocomplete
          options={catControlIncapacidad.filter((item: ICatalogosEstandar) => (tipoIncidencia.id === 3
            ? [8, 9, 10].includes(item.id)
            : [1, 2, 3, 4, 5, 6, 7].includes(item.id)))}
          getOptionLabel={(option) => option.label}
          fullWidth
          noOptionsText={t('sin_resultados')}
          value={controlIncapacidad}
          onChange={(e, nV) => setControlIncapacidad(nV || { id: 0, label: '' })}
          renderInput={(params) => (
            <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
          )}
        />
      </div>
      <h3 className="text-gray-600 font-medium m-0 mb-2">{t('incapacidades_descripcion')}</h3>
      <TextField
        variant="outlined"
        fullWidth
        value={comentarios}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          setComentarios(event.target.value as string);
        }}
        inputProps={{ maxLength: 500, autoComplete: 'off' }}
        multiline
        rows="4"
      />
      <h3 className="text-gray-600 font-medium m-0 mt-4 mb-2">{t('incapacidades_accidente')}</h3>
      <FormControl variant="outlined" fullWidth>
        <Select
          disabled={tipoIncidencia.id !== 2}
          displayEmpty
          labelId="tipo"
          id="tipo"
          value={accidenteLaboral}
          onChange={(event) => {
            const val = event.target.value as string;
            if (val) {
              setAccidenteLaboral(parseInt(val, 10));
            }
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 375,
              },
            },
          }}
        >
          <MenuItem value={0} disabled>
            <span className="text-gray-300">{t('selecciona')}</span>
          </MenuItem>
          {accidentesLaborales.map((accidente, inx) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem value={accidente.id} key={inx}>
              {accidente.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="text-right pt-4">
        <Button
          // disabled={!campaniaNombre || !correoAsunto}
          variant="contained"
          color="primary"
          onClick={guardarIncapacidad}
        >
          {t('guardar')}
        </Button>
      </div>
    </div>
  );
};

export default FormularioIncapacidades;
