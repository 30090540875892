import React from 'react';
import dayjs from 'dayjs';
import { useTable, usePagination } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Button, TableBody, TableCell, TableHead, TableRow, Avatar,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import MaUTable from '@material-ui/core/Table';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import ImportExportIcon from '@material-ui/icons/ImportExport';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import TableChartIcon from '@material-ui/icons/TableChart';
import PhoneIcon from '@material-ui/icons/Phone';
import Pagination from '../../../components/PaginationButtons';
import { setPacienteID } from '../../../actions/basicosPaciente/actions';
import { ITablaPacientesProps } from './types';
import { RootState } from '../../../store';
import useStyles from '../styles';

dayjs.locale('es');

function TablaPacientes({
  columns,
  data,
  totalDatos,
  pageIndex,
  pageCount,
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  ordenarPor,
  setOrdenarPor,
  setPasoModal,
  setModalConsultaAbierta,
  iniciarConsultaPacienteExistente,
  agendarPaciente,
  idMedico,
  idPaciente,
  idConsulta,
}: ITablaPacientesProps) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { secciones, pais } = useSelector((state: RootState) => state.Me);
  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
  );
  // Render the UI for your table
  return (
    <>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                if (
                  column.id === 'paciente'
                  || column.id === 'consulta'
                  || column.id === 'proxima'
                ) {
                  return (
                    <TableCell
                      className={classes.header}
                      onClick={() => setOrdenarPor(ordenarPor === column.id ? `-${column.id}` : column.id)}
                    >
                      <div className="flex flex-row content-center items-center">
                        {column.id === 'paciente'
                          ? `${column.render('Header')} (${totalDatos})`
                          : column.render('Header')}
                        {ordenarPor === `-${column.id}` ? (
                          <ArrowDownward fontSize="small" color="inherit" />
                        ) : (
                          <ArrowUpward
                            fontSize="small"
                            color={ordenarPor === column.id ? 'inherit' : 'action'}
                          />
                        )}
                      </div>
                    </TableCell>
                  );
                }
                return (
                  <TableCell {...column.getHeaderProps()} className={classes.header}>
                    {column.render('Header')}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column && cell.column.id === 'paciente') {
                    return (
                      <TableCell {...cell.getCellProps()} className="inherit">
                        <div className="flex flex-row">
                          <div className="flex flex-col justify-center items-center content-center">
                            <Avatar
                              variant="rounded"
                              style={{ backgroundColor: '#93C5FD' }}
                              src={cell.value.foto ? '' : cell.value.foto}
                              className={classes.large}
                            >
                              {cell.value.foto ? null : cell.value.abreviado}
                            </Avatar>
                            {/* <Button color="primary" size="small" className={classes.noTextTranform}>
                              {t('pacientes-ver-perfil')}
                            </Button> */}
                          </div>
                          <div className="pl-4">
                            <h3 className="text-blue-800 font-normal m-0">{cell.value.nombre}</h3>
                            <h4 className="text-gray-500 font-normal m-0">
                              {`${t(`doc_id_${pais}`)}: ${cell.value.docId}`}
                            </h4>
                            <h4 className="text-gray-500 font-normal m-0">
                              {`${t('edad')}: ${cell.value.edad} | ${cell.value.sexo}`}
                            </h4>
                            <div className="self-left">
                              <div>
                                <Button
                                  color="primary"
                                  size="small"
                                  className={classes.noTextTranform}
                                  startIcon={<TableChartIcon />}
                                  onClick={() => {
                                    dispatch(setPacienteID(cell.value.id));
                                    history.push('/tablero-paciente');
                                  }}
                                >
                                  {t('pacientes-ver-tablero')}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    );
                  }
                  if (cell.column && cell.column.id === 'datos') {
                    return (
                      <TableCell {...cell.getCellProps()} className="inherit">
                        <div className="flex flex-row content-center items-center pb-2">
                          <PhoneIphoneIcon color="primary" />
                          <h4 className="text-gray-500 font-normal m-0 pl-2">
                            {cell.value.celular}
                          </h4>
                        </div>
                        <div className="flex flex-row content-center items-center pb-2">
                          <PhoneIcon color="primary" />
                          <h4 className="text-gray-500 font-normal m-0 pl-2">
                            {cell.value.telefono}
                          </h4>
                        </div>
                        <div className="flex flex-row content-center items-center">
                          <EmailIcon color="primary" />
                          <h4 className="text-gray-500 font-normal m-0 pl-2">{cell.value.email}</h4>
                        </div>
                      </TableCell>
                    );
                  }
                  if (cell.column && cell.column.id === 'proxima') {
                    return (
                      <TableCell {...cell.getCellProps()} className="inherit">
                        <h4 className="text-gray-500 font-normal m-0">
                          {cell.value ? dayjs(cell.value).format('DD-MMM-YYYY') : null}
                        </h4>
                      </TableCell>
                    );
                  }
                  if (cell.column && cell.column.id === 'consulta') {
                    return (
                      <TableCell {...cell.getCellProps()} className="inherit">
                        <h4 className="text-gray-500 font-normal m-0">
                          {cell.value ? dayjs(cell.value).format('DD-MMM-YYYY') : null}
                        </h4>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell {...cell.getCellProps()} className="inherit">
                      <div className="min-w-max self-left">
                        {secciones.length > 0
                          && secciones.find((valor) => valor.idSeccion === 64) && (
                            <div>
                              <Button
                                color="primary"
                                size="small"
                                onClick={() => {
                                  if (idPaciente && idPaciente > 0 && idConsulta && idMedico) {
                                    setPasoModal(parseInt(cell.value.id, 10));
                                    setModalConsultaAbierta(true);
                                  } else {
                                    iniciarConsultaPacienteExistente(parseInt(cell.value.id, 10));
                                  }
                                }}
                                startIcon={<AddCircleOutlineIcon />}
                              >
                                {t('pacientes-iniciar-existe')}
                              </Button>
                            </div>
                        )}
                        {secciones.length > 0
                          && secciones.find(
                            (valor) => valor.idSeccion >= 2 && valor.idSeccion <= 6,
                          ) && (
                            <div>
                              <Button
                                color="primary"
                                size="small"
                                onClick={() => agendarPaciente(cell.value.id)}
                                startIcon={<AddCircleOutlineIcon />}
                              >
                                {t('pacientes-agendar-existe')}
                              </Button>
                            </div>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
      <Pagination
        pageIndex={pageIndex}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
      />
    </>
  );
}

export default TablaPacientes;
