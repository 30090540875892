/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Switch, Route, useRouteMatch, Redirect,
} from 'react-router-dom';
import { RootState } from '../../store';
import InformacionGeneral from './InformacionGeneral';
import InformacionDePago from './InformacionDePago';
import Checkout from './Checkout';
import CancelarSubscripcion from './CancelarSubscripcion';
import VerDatosSuscripcion from './VerDatosSuscripcion';
import VerMetodosPago from './VerMetodosPago';

function MiCuenta() {
  const {
    nombre, primerApellido, segundoApellido, email, idMedico, customer,
  } = useSelector(
    (state: RootState) => state.Me,
  );
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const [estatusSubscripcion, setStatusSubscripcion] = useState<string>('');
  return (
    <>
      <Switch>
        <Route path={path} exact>
          <div className="p-4 shadow-lg bg-white rounded-lg border border-solid border-gray-200">
            <h1 className="text-blue-800 font-normal m-0">{t('mi_cuenta')}</h1>
            <InformacionGeneral
              nombreCompleto={`${nombre} ${primerApellido} ${segundoApellido}`.trim()}
              email={email}
              idMedico={idMedico}
            />
            <InformacionDePago
              customer={customer}
              estatusSubscripcion={estatusSubscripcion}
              setStatusSubscripcion={setStatusSubscripcion}
            />
          </div>
        </Route>
        <Route path={`${path}/checkout`}>
          <Checkout customer={customer} />
        </Route>
        <Route path={`${path}/cancelar-suscripcion`}>
          {estatusSubscripcion === 'active' ? (
            <CancelarSubscripcion customer={customer} />
          ) : (
            <Redirect to={path} />
          )}
        </Route>
        <Route path={`${path}/ver-suscripcion`}>
          <VerDatosSuscripcion customer={customer} />
        </Route>
        <Route path={`${path}/ver-metodos-de-pago`}>
          <VerMetodosPago customer={customer} />
        </Route>
        <Route path={path}>
          <Redirect to={path} />
        </Route>
      </Switch>
    </>
  );
}

export default MiCuenta;
