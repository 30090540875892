import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { IMMCNotasAdicionalesProps } from './types';

function MMCNotasAdicionales({ incluido, comentarios }: IMMCNotasAdicionalesProps) {
  const { t } = useTranslation();
  return (
    <div className="p-4 shadow-lg bg-white rounded border border-solid border-gray-200">
      <div className="flex justify-between items-center">
        <h3 className="m-0 text-blue-800 font-medium">{t('notas_adicionales')}</h3>
      </div>
      {incluido && (
        <div className="mt-2">
          <TextField value={comentarios} fullWidth rows={3} variant="outlined" multiline disabled />
        </div>
      )}
    </div>
  );
}

export default MMCNotasAdicionales;
