const moneda = {
  mx: { codigo: 'MXN', simbolo: '$' },
  us: { codigo: 'USD', simbolo: '$' },
  uy: { codigo: 'UYU', simbolo: '$U' },
};

function getMoneda(pais: string) {
  return moneda[pais as keyof typeof moneda];
}

export default getMoneda;
