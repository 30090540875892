import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    justifyContent: 'left',
  },
  selected: {
    justifyContent: 'left',
    backgroundColor: '#1E467E',
    color: '#FFFFFF',
  },
  boldText: {
    fontWeight: 'normal',
    color: 'black',
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  noTextTranform: {
    textTransform: 'none',
  },
});

export default useStyles;
