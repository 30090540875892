import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import Psicologicos from './Psicologicos';
import Somaticos from './Somaticos';
import TractoGenital from './TractoGenital';
import Vasomotores from './Vasomotores';
import { ISintomasDelClimaterioProps } from './types';

function SintomasDelClimaterio(props: ISintomasDelClimaterioProps) {
  const { ginecoObstetrico, setGinecoObstetrico } = props;
  const { t } = useTranslation();

  const handleChangeSintomasDelClimaterio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === 'si') {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        generalidades: {
          ...ginecoObstetrico.generalidades,
          sintomasDelClimaterio: val,
        },
        sintomasClimaterio: {
          vasomotores: {
            radiosValues: ['', '', ''],
            comentariosValues: ['', '', ''],
            items: ['bochorno', 'sudoracion_nocturna', 'palpitaciones'],
          },
          tractoGenital: {
            radiosValues: ['', ''],
            comentariosValues: ['', ''],
            items: ['resequedad_vaginal', 'dispareunia'],
          },
          somaticos: {
            radiosValues: ['', '', '', '', '', ''],
            comentariosValues: ['', '', '', '', '', ''],
            items: ['insomnio', 'piel_reseca', 'cefalea', 'mialgias', 'artralgias', 'mareos'],
          },
          psicologicos: {
            radiosValues: ['', '', '', '', '', ''],
            comentariosValues: ['', '', '', '', '', ''],
            items: [
              'depresion',
              'disminucion_de_la_libido',
              'fatiga',
              'alteraciones_en_la_memoria',
              'ansiedad',
              'cambios_en_el_estado_de_animo',
            ],
          },
        },
      });
    } else {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        generalidades: {
          ...ginecoObstetrico.generalidades,
          sintomasDelClimaterio: val,
        },
      });
    }
  };

  return (
    <div className="border-solid border-0 border-b border-gray-600">
      <h2 className="font-normal text-blue-800 mt-6 mb-0">{t('sintomas_del_climaterio')}</h2>
      <RadioGroup
        row
        name="sintomas del climaterio"
        value={ginecoObstetrico.generalidades.sintomasDelClimaterio}
        onChange={handleChangeSintomasDelClimaterio}
      >
        <FormControlLabel
          value="si"
          control={<Radio color="primary" />}
          label={t('si')}
          labelPlacement="end"
        />
        <FormControlLabel
          value="no"
          control={<Radio color="primary" />}
          label={t('no')}
          labelPlacement="end"
        />
      </RadioGroup>
      {ginecoObstetrico.generalidades.sintomasDelClimaterio === 'si' && (
        <>
          <Vasomotores
            ginecoObstetrico={ginecoObstetrico}
            setGinecoObstetrico={setGinecoObstetrico}
          />
          <TractoGenital
            ginecoObstetrico={ginecoObstetrico}
            setGinecoObstetrico={setGinecoObstetrico}
          />
          <Somaticos
            ginecoObstetrico={ginecoObstetrico}
            setGinecoObstetrico={setGinecoObstetrico}
          />
          <Psicologicos
            ginecoObstetrico={ginecoObstetrico}
            setGinecoObstetrico={setGinecoObstetrico}
          />
        </>
      )}
    </div>
  );
}

export default SintomasDelClimaterio;
