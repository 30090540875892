import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Collapse } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { capitalize } from '../../../../../../common/functions';
import { IFamiliarProps } from './types';
import FamiliarGrupoEnfermedadesNegadas from './FamiliarGrupoEnfermedadesNegadas';
import FamiliarEnfermedadNegada from './FamiliarEnfermedadNegada';
import FamiliarEnfermedad from './FamiliarEnfermedad';
import { IFamiliarEnfermedades, IFamiliaresEnfermedadesNegadas } from '../../types';

function Familiar({
  familiar,
  setGrupoEnfNegadas,
  setDeleteAlert,
  setEditAlert,
  setIndiceEnfermedad,
  setIndiceFamiliar,
  setTipoEnfermedad,
  verificarExitenDatosEnElFormulario,
  handleEditEnfermedad,
  index,
  handleAddDiagnostico,
  setAddAlert,
}: IFamiliarProps) {
  const { t } = useTranslation();
  const [expand, setExpand] = useState<boolean>(true);

  const agregarOtroDiagnostico = () => {
    if (verificarExitenDatosEnElFormulario()) {
      setIndiceFamiliar(index);
      setAddAlert(true);
    } else {
      handleAddDiagnostico(index);
    }
  };

  const handleEditarEnfermedad = (tipoEnfermedad: string, indiceEnfermedad: number) => {
    if (verificarExitenDatosEnElFormulario()) {
      setIndiceFamiliar(index);
      setIndiceEnfermedad(indiceEnfermedad);
      setTipoEnfermedad(tipoEnfermedad);
      setEditAlert(true);
    } else {
      // si no existe datos llamar a la funcion editar de una vez
      handleEditEnfermedad(index, tipoEnfermedad, indiceEnfermedad);
    }
  };

  const handleBorrarEnfermedad = (tipoEnfermedad: string, indiceEnfermedad: number) => {
    setIndiceFamiliar(index);
    setIndiceEnfermedad(indiceEnfermedad);
    setTipoEnfermedad(tipoEnfermedad);
    setDeleteAlert(true);
  };

  return (
    <div className="shadow-lg border border-solid border-gray-200 rounded px-2 pt-2 mb-4">
      <div className="flex justify-between items-center">
        <h3 className="font-normal m-0 text-blue-800">
          {`${familiar.parentesco.label} (${familiar.vive ? t('vive') : t('fallecido')})${
            familiar.nombreApellidos ? ` - ${capitalize(familiar.nombreApellidos)}` : ''
          }`}
        </h3>
        <div className="flex items-center">
          <div className="mr-8">
            <Button
              color="primary"
              size="small"
              onClick={agregarOtroDiagnostico}
              startIcon={<AddCircleOutlineIcon />}
            >
              {t('agregar_otro_diagnostico')}
            </Button>
          </div>
          <Button onClick={() => setExpand((prevVal: boolean) => !prevVal)} color="primary">
            {expand ? <span>{t('ver_menos')}</span> : <span>{t('ver_mas')}</span>}
            {expand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Button>
        </div>
      </div>
      <Collapse in={expand}>
        <div>
          {familiar.listaEnfermedades.map((enf: IFamiliarEnfermedades, indx: number) => (
            <FamiliarEnfermedad
              enfermedad={enf}
              key={enf.idEnfermedad}
              index={indx}
              handleEditarEnfermedad={handleEditarEnfermedad}
              handleBorrarEnfermedad={handleBorrarEnfermedad}
              setGrupoEnfNegadas={setGrupoEnfNegadas}
            />
          ))}
          {familiar.enfermedadesNegadas.map(
            (enfN: IFamiliaresEnfermedadesNegadas, indx: number) => (
              <FamiliarEnfermedadNegada
                index={indx}
                enfermedadNegada={enfN}
                key={enfN.idEnfermedadNegada}
                handleEditarEnfermedad={handleEditarEnfermedad}
                handleBorrarEnfermedad={handleBorrarEnfermedad}
              />
            ),
          )}
          <FamiliarGrupoEnfermedadesNegadas
            grupoEnfermedadesNegadas={familiar.grupoEnfermedadesNegadas}
          />
        </div>
      </Collapse>
    </div>
  );
}

export default Familiar;
