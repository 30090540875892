import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noTextTranform: {
    textTransform: 'none',
  },
  nopaddingIzquierdo: {
    paddingLeft: 0,
  },
});

export default useStyles;
