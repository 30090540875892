import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select, TextField, FormControl, InputLabel,
} from '@material-ui/core';
import { IConstructorFechaProps } from './types';
import { capitalize } from '../../../../common/functions';

function BuilderFecha({ variable, setVariable }: IConstructorFechaProps) {
  const { t } = useTranslation();
  /** States del functional component */
  /** Handlers del componente */
  const handleChangeDescripcion = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setVariable((preCons) => ({
      ...preCons,
      datosComponente: {
        ...preCons.datosComponente,
        descripcion: val,
      },
      constructor: {
        ...preCons.constructor,
        descripcion: val,
      },
    }));
  };

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 grid grid-cols-2 gap-4 items-center">
      <div>
        <div className="flex flex-wrap mb-3.5 items-end">
          <h3 className="w-max mr-4 mb-2 mt-0 text-gray-600 font-medium">
            {capitalize(variable.datosComponente.titulo)}
          </h3>
          <div className="w-1/2 flex-wrap">
            <h3 className="mt-0 mb-2">{t('incluye_una_descripcion_opcional')}</h3>
            <TextField
              fullWidth
              value={variable.datosComponente.descripcion}
              onChange={handleChangeDescripcion}
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              multiline
              rows={1}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mb-3">
          <FormControl variant="outlined" fullWidth disabled>
            <InputLabel>{t('dia')}</InputLabel>
            <Select label={t('dia')} />
          </FormControl>
          <FormControl variant="outlined" fullWidth className="col-span-2" disabled>
            <InputLabel>{t('mes')}</InputLabel>
            <Select label={t('mes')} />
          </FormControl>
          <FormControl variant="outlined" fullWidth disabled>
            <InputLabel>{t('anio')}</InputLabel>
            <Select label={t('anio')} />
          </FormControl>
        </div>
      </div>
      <TextField
        variant="outlined"
        rows={6}
        multiline
        fullWidth
        disabled
        label={t('comentarios')}
      />
    </div>
  );
}

export default BuilderFecha;
