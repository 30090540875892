import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Avatar from '@material-ui/core/Avatar';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import useStyles from './styles';
import { IPrevisualizarProps } from './types';
import ViewTxt from '../../../../components/Comentarios/view';
import { RootState } from '../../../../store';

dayjs.locale('es');

const Previsualizar = (props: IPrevisualizarProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { rol } = useSelector((state: RootState) => state.Me);
  const {
    objDatId,
    objCarrera,
    arrayEspecialidad,
    arrayMaestria,
    arrayDoctorado,
    objExperiencia,
    perfilMostrar,
    setPerfilMostrar,
  } = props;
  const txtEspecialidad = () => {
    let txt = '';
    arrayEspecialidad.forEach((value) => {
      if (txt.length !== 0) {
        txt += ', ';
      }
      txt += value.titulo ? value.titulo.toString() : value.selector?.label.toString();
    });
    return txt;
  };
  const txtCedulaEspecialidad = () => {
    let txt = `${t('medico_perfil_cedulaee')}: `;
    arrayEspecialidad.forEach((value, inx) => {
      if (inx !== 0) {
        txt += '  |  ';
      }
      txt += value.cedula.toString();
    });
    return txt;
  };
  const txtLanguaje = () => {
    let txt = '';
    objExperiencia.idiomas.forEach((value, inx) => {
      if (inx !== 0) {
        txt += '  |  ';
      }
      txt += value.toString();
    });
    return txt;
  };
  const txtCarrera = () => {
    let txt = '';
    if (objCarrera.titulor === 'LM') {
      txt = 'Licenciado en Medicina.';
    } else if (objCarrera.titulor === 'MCP') {
      txt = 'Médico Cirujano y Partero.';
    } else if (objCarrera.titulor === 'MC') {
      txt = 'Médico Cirujano.';
    } else if (objCarrera.titulor === 'MG') {
      txt = 'Médico General.';
    } else if (objCarrera.titulor === 'O') {
      txt = `${objCarrera.titulo.toString()}.`;
    }
    txt += ` ${
      objCarrera.selector?.id === 57 ? objCarrera.institucion : objCarrera.selector?.label
    },`;
    txt += ` ${
      objCarrera.anio && parseInt(objCarrera.anio, 10) > 0 ? objCarrera.anio.toString() : ''
    }`;
    return txt;
  };
  const txtTitulo = () => {
    let txt = '';
    if (rol.tipo === 'medico') {
      txt = objDatId.sexo === 'M' ? t('dra_') : t('dr_');
    } else if (rol.tipo === 'enfermero') {
      txt = t('enf_');
    }
    return txt;
  };
  return (
    <div className="p-4 bg-white">
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <div className="absolute top right-10">
          <Button
            color="primary"
            onClick={() => setPerfilMostrar(!perfilMostrar)}
            startIcon={<KeyboardReturnIcon />}
          >
            {t('medico_perfil_pmedico')}
          </Button>
        </div>
        <div className="flex flex-row">
          <div className="flex flex-col justify-center">
            <div className="p-0 pr-4">
              <div className={classes.root}>
                <label htmlFor="contained-button-file" className="m-0 font-normal">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    classes={{ label: classes.iconButton }}
                  >
                    <Avatar
                      alt="Usuario"
                      variant="rounded"
                      className={classes.largeAvatar}
                      color="primary"
                    >
                      {`${objDatId.nombre[0]} ${objDatId.apellidoP[0]} ${objDatId.apellidoS[0]}`}
                    </Avatar>
                  </IconButton>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                  />
                </label>
              </div>
            </div>
          </div>
          <div>
            <h1 className="text-blue-800 font-normal m-0">
              {`${txtTitulo()} ${objDatId.nombre} ${objDatId.apellidoP} ${objDatId.apellidoS}`}
            </h1>
            {arrayEspecialidad.length > 0 ? (
              <h2 className="text-gray-900 font-normal m-0">{txtEspecialidad()}</h2>
            ) : null}
            <h4 className="text-gray-500 font-normal m-0 mt-2">
              {`${t('medico_perfil_cedulapp')}: ${objCarrera.cedula}`}
            </h4>
            {arrayEspecialidad.length > 0 ? (
              <h4 className="text-gray-500 font-normal m-0 mt-2">{txtCedulaEspecialidad()}</h4>
            ) : null}
            <div className="flex pt-2 items-center">
              {objDatId.celular.numero.length !== 0 ? (
                <>
                  <PhoneIphoneIcon color="primary" />
                  <h4 className="text-gray-500 font-normal m-0">
                    {`${objDatId.celular.codigo} ${objDatId.celular.numero}`}
                  </h4>
                </>
              ) : null}
              {objDatId.celular.numero.length !== 0 && objDatId.telefono.numero.length !== 0 ? (
                <div className="px-4" />
              ) : null}
              {objDatId.telefono.numero.length !== 0 ? (
                <>
                  <PhoneIcon color="primary" />
                  <h4 className="text-gray-500 font-normal m-0">
                    {`${objDatId.telefono.codigo} ${objDatId.telefono.numero}`}
                  </h4>
                </>
              ) : null}
            </div>
            {objExperiencia.idiomas.length !== 0 ? (
              <div className="flex pt-2 items-center">
                <LanguageIcon color="primary" />
                <h4 className="text-gray-500 font-normal m-0">{txtLanguaje()}</h4>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="py-2" />
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <h1 className="text-blue-800 font-normal m-0">{t('medico_perfil_experiencia')}</h1>
        <PersonIcon color="primary" />
        <ViewTxt
          comentarios={objExperiencia.sobre}
          key={Math.floor(Math.random() * (100 - 1)) + 1}
        />
        <div className="flex pt-2 items-center">
          <InsertDriveFileIcon color="primary" />
          <h2 className="text-gray-900 font-normal m-0">{`${t('medico_perfil_habilidades')}:`}</h2>
        </div>
        {objExperiencia.area.map((value, inx) => (
          // eslint-disable-next-line react/no-array-index-key
          <h3 className="text-gray-500 font-normal m-0 pt-2" key={inx}>
            {`* ${value}`}
          </h3>
        ))}
        <div className="flex pt-4 items-center">
          <PersonIcon color="primary" />
          <h2 className="text-gray-900 font-normal m-0">{`${t('medico_perfil_formacion')}:`}</h2>
        </div>
        <h3 className="text-gray-500 font-normal m-0 pt-2">{`* ${txtCarrera()}`}</h3>
        {arrayMaestria.map((value, inx) => (
          // eslint-disable-next-line react/no-array-index-key
          <h3 className="text-gray-500 font-normal m-0 pt-2" key={inx}>
            {`* ${value.titulo}. ${value.institucion}, ${
              value.anio && parseInt(value.anio, 10) > 0 ? value.anio.toString() : ''
            }`}
          </h3>
        ))}
        {arrayDoctorado.map((value, inx) => (
          // eslint-disable-next-line react/no-array-index-key
          <h3 className="text-gray-500 font-normal m-0 pt-2" key={inx}>
            {`* ${value.titulo}. ${value.institucion}, ${
              value.anio && parseInt(value.anio, 10) > 0 ? value.anio.toString() : ''
            }`}
          </h3>
        ))}
      </div>
    </div>
  );
};

export default Previsualizar;
