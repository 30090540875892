import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
/** Importacion de estilos */
import es from 'react-phone-input-2/lang/es.json';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  validacionNombres,
  validacionEmail,
  validacionApellidos,
} from '../../../../constants/validaciones';
import AlertDialog from '../../../../components/AlertDialogs/AlertDialog';
import ResumenCambios from '../../../../components/ResumenCambios';
import BotonesGuardar from '../../../../components/BotonesGuardar';
import useStyles from './styles';
import { IInfoEmergenciaProps, IInfoEmergencia, infoEmergenciaInitial } from './types';
import {
  ICatalogosEstandar,
  ITextFieldEstandar,
  ICountryPhoneInput,
} from '../../../../common/types';
import { getCatalogoParentesco } from '../../../../utils/getCatalogos';
import {
  updateInfoEmergencia,
  updateInfoEmergenciaPaciente,
  updateInfoEmergenciaUsuario,
} from '../../../../utils/sendInfo';
import { RootState } from '../../../../store';
import { setRequest } from '../../../../actions/request/types';

/** Formulario de la Info de emergencia del paciente */
function InfoEmergencia(props: IInfoEmergenciaProps) {
  const {
    setActiveSubStep,
    infoEmergencia,
    setInfoEmergencia,
    cambiosUsuario,
    infoEmergenciaUsuario,
    setInfoEmergenciaUsuario,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
    pais,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  const [infoEmergenciaTemp, setInfoEmergenciaTemp] = useState<IInfoEmergencia>(infoEmergenciaInitial);

  /** states de comprobacion de fields */
  const [nombresField, setNombresField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [apellidosField, setApellidosField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [emailField, setEmailField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  /** states de componentes que se deshabilitan */
  const [refGrupoSanguineo, setRefGrupoSanguineo] = useState<boolean>(false);
  const [refEspecifique, setRefEspecifique] = useState<boolean>(true);
  /** states para mostrar o no diferentes campos */
  /** states del alert */
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  /** catalogos */
  const [catParentesco, setCatParentesco] = useState<Array<ICatalogosEstandar>>([]);
  /** logica */
  const verificarErrorValidacionGeneral = nombresField.error || apellidosField.error || emailField.error;
  const hayCambios: boolean = idUsuario > 0 && cambiosUsuario.filter((c: string) => c.length > 0).length > 0;

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  /** Handlers del componente */
  const handleChangeTipoSanguineo = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInfoEmergenciaTemp({
      ...infoEmergenciaTemp,
      tipoSanguineo: event.target.value as string,
    });
  };

  const handleChangeFactorSanguineo = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInfoEmergenciaTemp({
      ...infoEmergenciaTemp,
      factorSanguineo: event.target.value as string,
    });
  };

  const handleChangeNombre = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setInfoEmergenciaTemp({
      ...infoEmergenciaTemp,
      nombreContacto: val,
    });
    if (!val.length || validacionNombres(val)) {
      setNombresField({ helperText: '', error: false });
    } else {
      setNombresField({ helperText: t('nombres_incorrectos'), error: true });
    }
  };

  const handleChangeApellidos = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setInfoEmergenciaTemp({
      ...infoEmergenciaTemp,
      apellidosContacto: val,
    });
    if (!val.length || validacionApellidos(val)) {
      setApellidosField({ helperText: '', error: false });
    } else {
      setApellidosField({ helperText: t('apellidos_incorrectos'), error: true });
    }
  };

  const handleChangeParentesco = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setInfoEmergenciaTemp({
      ...infoEmergenciaTemp,
      parentescoContacto: val,
      especifiqueParentescoContacto:
        val === '11' ? infoEmergenciaTemp.especifiqueParentescoContacto : '',
    });
    setRefEspecifique(val !== '11');
  };

  const handleChangeEspeifiqueParentesco = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInfoEmergenciaTemp({
      ...infoEmergenciaTemp,
      especifiqueParentescoContacto: event.target.value as string,
    });
  };

  const handleChangeEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInfoEmergenciaTemp({
      ...infoEmergenciaTemp,
      emailContacto: event.target.value as string,
    });
    setEmailField({ helperText: '', error: false });
  };

  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setEmailField({ helperText: '', error: false });
    } else {
      setEmailField({ helperText: t('email_incorrecto'), error: true });
    }
  };

  const handleChangeTipoTelefono = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInfoEmergenciaTemp({
      ...infoEmergenciaTemp,
      tipoTelefonoContacto: event.target.value as string,
    });
  };

  const handleChangeDesconoceGSanguineo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const desconoce = event.target.checked as boolean;
    setInfoEmergenciaTemp({
      ...infoEmergenciaTemp,
      desconoceGSanguineo: desconoce,
      tipoSanguineo: desconoce ? '' : infoEmergenciaTemp.tipoSanguineo,
      factorSanguineo: desconoce ? '' : infoEmergenciaTemp.factorSanguineo,
    });
    setRefGrupoSanguineo(desconoce);
  };

  const getData = (datos: IInfoEmergencia, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsultorio,
    desconoceGSanguineo: datos.desconoceGSanguineo,
    tipoSanguineo: datos.tipoSanguineo,
    factorSanguineo: datos.factorSanguineo,
    nombreContacto: datos.nombreContacto,
    apellidosContacto: datos.apellidosContacto,
    parentescoContacto: datos.parentescoContacto,
    especifiqueParentescoContacto: datos.especifiqueParentescoContacto,
    emailContacto: datos.emailContacto,
    ladaTel: datos.ladaTel,
    telefonoContacto: datos.telefonoContacto,
    tipoTelefonoContacto: datos.tipoTelefonoContacto,
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosInfoEmergencia = getData(infoEmergenciaTemp, idUsuario);
    const sendFunctions = [updateInfoEmergencia(datosInfoEmergencia)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateInfoEmergenciaPaciente(datosInfoEmergencia));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => setInfoEmergencia({ ...infoEmergenciaTemp, loaded: true }),
          () => setInfoEmergenciaUsuario({ ...infoEmergenciaTemp, loaded: true }),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateInfoEmergenciaUsuario(getData(infoEmergenciaTemp, idMiUsuario)),
        successFunction: () => {
          setInfoEmergencia({ ...infoEmergenciaTemp, loaded: true });
          setActiveSubStep('seguros-planes');
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateInfoEmergencia(getData(infoEmergenciaUsuario)),
        successFunction: () => {
          setInfoEmergencia(infoEmergenciaUsuario);
          setInfoEmergenciaTemp(infoEmergenciaUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateInfoEmergenciaPaciente(getData(infoEmergencia, idUsuario)),
        successFunction: () => setInfoEmergenciaUsuario(infoEmergencia),
      }),
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCatalogoParentesco().then((result: ICatalogosEstandar[]) => {
      setCatParentesco(result);
    });
  }, []);

  useEffect(() => {
    if (infoEmergencia.loaded && !infoEmergenciaTemp.loaded) {
      setInfoEmergenciaTemp(infoEmergencia);
      setRefGrupoSanguineo(infoEmergencia.desconoceGSanguineo);
    }
  }, [infoEmergencia]);

  return (
    <div id="info-emergencia-paciente" aria-labelledby="info-emergencia-paciente">
      <AlertDialog
        open={alertOpen}
        titulo="¡Atención!"
        descripcion=""
        nombreCancelar="Regresar"
        nombreAceptar="Guardar"
        callBackAceptar={guardarDatosExpediente}
        callBackClose={handleAlertClose}
      />
      <div className="p-4 bg-white container mx-auto shadow rounded-b-md border-solid border border-gray-100">
        {hayCambios && <ResumenCambios cambios={cambiosUsuario} />}
        <form>
          <h2 className="text-gray-600 font-medium my-4">{t('grupo_sanguineo')}</h2>
          <div className="flex flex-row">
            <div className="mr-6">
              <h3 className="text-gray-600 font-medium m-0 mb-2">{t('tipo')}</h3>
              <FormControl disabled={refGrupoSanguineo} fullWidth>
                <RadioGroup
                  row
                  aria-label="tipo"
                  name="tipo"
                  defaultValue="top"
                  value={infoEmergenciaTemp.tipoSanguineo}
                  onChange={handleChangeTipoSanguineo}
                >
                  <FormControlLabel
                    disabled={hayCambios || infoEmergenciaTemp.desconoceGSanguineo}
                    value="A"
                    control={<Radio color="primary" />}
                    label="A"
                    className={classes.marginRadio}
                  />
                  <FormControlLabel
                    disabled={hayCambios || infoEmergenciaTemp.desconoceGSanguineo}
                    value="B"
                    control={<Radio color="primary" />}
                    label="B"
                    className={classes.marginRadio}
                  />
                  <FormControlLabel
                    disabled={hayCambios || infoEmergenciaTemp.desconoceGSanguineo}
                    value="AB"
                    control={<Radio color="primary" />}
                    label="AB"
                    className={classes.marginRadio}
                  />
                  <FormControlLabel
                    disabled={hayCambios || infoEmergenciaTemp.desconoceGSanguineo}
                    value="O"
                    control={<Radio color="primary" />}
                    label="O"
                    className={classes.marginRadio}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="flex flex-row items-end">
              <div className="mr-4">
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t('factor_rh')}</h3>
                <FormControl disabled={refGrupoSanguineo} fullWidth>
                  <RadioGroup
                    row
                    aria-label="factor"
                    name="factor"
                    defaultValue="top"
                    value={infoEmergenciaTemp.factorSanguineo}
                    onChange={handleChangeFactorSanguineo}
                  >
                    <FormControlLabel
                      disabled={hayCambios || infoEmergenciaTemp.desconoceGSanguineo}
                      value="POSITIVO"
                      control={<Radio color="primary" />}
                      label={<AddIcon />}
                      className=""
                    />
                    <FormControlLabel
                      disabled={hayCambios || infoEmergenciaTemp.desconoceGSanguineo}
                      value="NEGATIVO"
                      control={<Radio color="primary" />}
                      label={<RemoveIcon />}
                      className=""
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <FormControlLabel
                control={(
                  <Checkbox
                    disabled={hayCambios}
                    checked={infoEmergenciaTemp.desconoceGSanguineo}
                    onChange={handleChangeDesconoceGSanguineo}
                    name="checked-desconoce-g-sanguineo"
                    color="primary"
                  />
                )}
                label={t('desconoce_grupo_sanguineo')}
              />
            </div>
          </div>
          <h2 className="text-gray-600 font-medium my-4">{t('contacto_emergencia')}</h2>
          <div className="grid-cols-2 grid gap-8 mb-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t('nombre(s)')}</h3>
                <TextField
                  disabled={hayCambios}
                  variant="outlined"
                  fullWidth
                  value={infoEmergenciaTemp.nombreContacto}
                  onChange={handleChangeNombre}
                  error={nombresField.error}
                  helperText={nombresField.helperText}
                  inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                />
              </div>
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t('apellidos(s)')}</h3>
                <TextField
                  disabled={hayCambios}
                  variant="outlined"
                  fullWidth
                  value={infoEmergenciaTemp.apellidosContacto}
                  onChange={handleChangeApellidos}
                  error={apellidosField.error}
                  helperText={apellidosField.helperText}
                  inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t('parentesco')}</h3>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    disabled={hayCambios}
                    value={infoEmergenciaTemp.parentescoContacto}
                    onChange={handleChangeParentesco}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      <span className="text-gray-400">{t('selecciona')}</span>
                    </MenuItem>
                    {catParentesco.length > 0
                      && catParentesco.map((singleParentesco: ICatalogosEstandar) => (
                        <MenuItem value={singleParentesco.id.toString()} key={singleParentesco.id}>
                          {singleParentesco.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              {!refEspecifique && (
                <div>
                  <h3 className="text-gray-600 font-medium m-0 mb-2">{t('especifica')}</h3>
                  <TextField
                    disabled={hayCambios}
                    variant="outlined"
                    fullWidth
                    value={infoEmergenciaTemp.especifiqueParentescoContacto}
                    onChange={handleChangeEspeifiqueParentesco}
                    inputProps={{
                      maxLength: 50,
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/3 mr-8">
              <h3 className="text-gray-600 font-medium m-0 mb-2">{t('email')}</h3>
              <TextField
                disabled={hayCambios}
                variant="outlined"
                type="email"
                fullWidth
                value={infoEmergenciaTemp.emailContacto}
                onChange={handleChangeEmail}
                error={emailField.error}
                helperText={emailField.helperText}
                inputProps={{
                  maxLength: 100,
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                }}
                onBlur={handleBlurEmail}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
              />
            </div>
            <div className="grid grid-cols-3 gap-4 items-center">
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t('telefono')}</h3>
                <PhoneInput
                  disabled={hayCambios}
                  preferredCountries={[pais, 'us']}
                  country={pais}
                  localization={es}
                  enableSearch
                  searchPlaceholder={t('buscar')}
                  searchNotFound={t('sin_resultados')}
                  inputStyle={{ width: '100%' }}
                  value={`${infoEmergenciaTemp.ladaTel}${infoEmergenciaTemp.telefonoContacto}`}
                  onChange={(value, country: ICountryPhoneInput) => {
                    const { dialCode } = country;
                    const nuevoCel = value.slice(dialCode.length);
                    setInfoEmergenciaTemp({
                      ...infoEmergenciaTemp,
                      telefonoContacto: nuevoCel,
                      ladaTel: country.dialCode,
                    });
                  }}
                />
              </div>
              <FormControl
                fullWidth
                className="col-span-2 justify-center self-end"
                style={{ height: '56px' }}
              >
                <RadioGroup
                  row
                  aria-label="tipo-telefono"
                  name="tipo-telefono"
                  defaultValue="top"
                  value={infoEmergenciaTemp.tipoTelefonoContacto}
                  onChange={handleChangeTipoTelefono}
                >
                  <FormControlLabel
                    disabled={hayCambios}
                    value="1"
                    control={<Radio color="primary" />}
                    label={t('celular')}
                  />
                  <FormControlLabel
                    disabled={hayCambios}
                    value="2"
                    control={<Radio color="primary" />}
                    label={t('fijo')}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="flex justify-end">
            <BotonesGuardar
              hayCambios={hayCambios}
              aceptarCallback={guardarDatosDeUsuarioAExpediente}
              cancelarCallback={guardarDatosDeExpedienteAUsuario}
              guardarCallback={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
              guardarDisable={verificarErrorValidacionGeneral}
              continuar={esPaciente}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default InfoEmergencia;
