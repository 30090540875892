import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  ninetyPx: {
    width: '90px',
    height: '90px',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  iconButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
}));

export default useStyles;
