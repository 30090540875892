import { createTheme } from '@material-ui/core';
import { esES } from '@material-ui/core/locale';

const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
    palette: {
      primary: {
        light: '#BFDBFE',
        main: '#3B82F6',
        dark: '#1E40AF',
      },
      secondary: {
        light: '#FBBF24',
        main: '#F59E0B',
        dark: '#D97706',
      },
      error: {
        light: '#F87171',
        main: '#EF4444',
        dark: '#DC2626',
      },
      warning: {
        light: '#FEF3C7',
        main: '#FDE68A',
        dark: '#FCD34D',
      },
      info: {
        light: '#A5B4FC',
        main: '#6366F1',
        dark: '#312E81',
      },
      success: {
        light: '#6EE7B7',
        main: '#10B981',
        dark: '#047857',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
        },
        containedSizeLarge: {
          padding: '8px 20px',
        },
      },
      MuiFormControlLabel: {
        root: {
          color: '#4B5563',
        },
      },
    },
  },
  esES,
);

export default theme;
