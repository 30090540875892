import { IDatosAdministrador, IDatosConsultorio, IRolesData } from './types';

export const datosAdministradorInitial: IDatosAdministrador = {
  nombre: '',
  apellidoP: '',
  apellidoS: '',
  docId: '',
  sexo: '',
  fechaNac: { dia: '', mes: '', anio: '' },
  celular: { numero: '', codigo: '+52' },
  telefono: { numero: '', codigo: '+52' },
  email: '',
  emailLog: '',
  nacionalidad: { id: '', label: '' },
  id: -1,
  password: '',
};

export const datosConsultorioInitial: IDatosConsultorio = {
  idConsultorio: -1,
  nombre: '',
  label: '',
  clinicaHospital: { id: '', label: '' },
  estaEnHospital: 'si',
  agee: null,
  agem: null,
  agel: null,
  agea: null,
  calle: '',
  num1: '',
  num2: '',
  cp: '',
  telefono1: '',
  extension1: '',
  telefono2: '',
  extension2: '',
  telefono3: '',
  extension3: '',
  correoElectronico: '',
  sitioWeb: '',
  tienePacientes: 0,
  showDelete2: false,
  showDelete3: false,
  showTel2: false,
  showTel3: false,
  tipoEstablecimiento: { id: 0, label: '' },
  otroEstablecimiento: '',
  nombreEstablecimiento: '',
  borrado: false,
  actualizado: false,
};

export const rolesDataInitial: IRolesData = {
  id: -1,
  checkNuevoRol: false,
  nombre: '',
  tipoRol: {
    id: -1,
    tipo: '',
    grupo: '',
    secciones: [],
  },
  asignado: false,
  checkGenLectura: false,
  checkGenEscritura: false,
  arrayRolesSeleccionados: [],
};
