import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AlertDialog from '../../../components/AlertDialogs/AlertDialog';
import RenderComponent from '../../../components/RenderComponent';
import VariableSeleccionada from '../SeleccionVariablesMC/VariableSeleccionada';
import { IVisualizacionMCProps } from './types';

function VisualizacionMC({
  nombreDelMC,
  arrComponentes,
  setArrComponentes,
}: IVisualizacionMCProps) {
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [idBDAE, setIDBDAE] = useState<number>(-1);
  const quitarVariable = () => {
    setArrComponentes((preAC) => preAC.filter((comp) => comp.id !== idBDAE));
  };

  const eliminarVariable = (idBDV: number) => {
    setIDBDAE(idBDV);
    setAlertOpen(true);
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  return (
    <div className="p-4 shadow-lg bg-white rounded border border-solid border-gray-200">
      <AlertDialog
        open={alertOpen}
        titulo="¡Atención!"
        descripcion={t('mcpa_warning_eliminar_variable')}
        nombreCancelar={t('cancelar')}
        nombreAceptar={t('aceptar')}
        callBackAceptar={quitarVariable}
        callBackClose={handleAlertClose}
      />
      <div>
        <h3 className="m-0 font-medium text-blue-800 mb-2">{t('variables_seleccionadas')}</h3>
        {arrComponentes.map((comp) => (
          <VariableSeleccionada
            idBD={comp.id}
            nombre={comp.titulo}
            eliminarVariable={eliminarVariable}
          />
        ))}
      </div>
      <div className="border-solid border-0 border-b border-black mb-4 mt-2" />
      <h3 className="font-medium text-blue-500">
        {`${t('asi_se_vera_el_formato_mc')} “${nombreDelMC}”:`}
      </h3>
      <p className="text-gray-800">
        {t('puedes_arrastrar_y_soltar_los_modulos_para_ubicarlos_donde_desees')}
      </p>
      <div>
        <RenderComponent arrComps={arrComponentes} setArrComps={setArrComponentes} />
      </div>
    </div>
  );
}

export default VisualizacionMC;
