import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AppBar, Toolbar, IconButton, Avatar, CssBaseline, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PersonIcon from '@material-ui/icons/Person';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ApartmentIcon from '@material-ui/icons/Apartment';
import Business from '@material-ui/icons/Business';
import MenuDoctor from './MenuDoctor';
import MenuHospital from './MenuHospital';
import MenuOrganizacion from './MenuOrganizacion';
import useStyles from './styles';
import SideBar from '../SideBar/index';
import { RootState } from '../../store';
import { sendLoguot } from '../../utils/sendInfo';
import loading from '../../actions/loading/actions';
import {
  getToken,
  getUser,
  removeUserSession,
  setConsultorioSS,
  getOrganizacionSS,
  setOrganizacionSS,
  removeIdConsulta,
  removePaciente,
} from '../../utils/commonStore';
import { resetMe } from '../../actions/me/actions';
import { ReactComponent as LogoIcon } from '../../assets/logo/Logotipo_Color.svg';
import { setSnackComplete } from '../../actions/snackbar/types';
import {
  getConsultorio,
  getConsultorios,
  resetConsultorio,
  resetConsultorios,
} from '../../actions/consultorios/actions';
import {
  getOrganizacion,
  getOrganizaciones,
  resetOrganizacion,
  resetOrganizaciones,
} from '../../actions/organizaciones/actions';
import { limpiarRedux } from '../../utils/reduxCommon';
import { setAuth } from '../../actions/auth/actions';
import ModalConsultaAbierta from '../../components/AlertDialogs/AlertDialog';
import {
  editConsultasMedicasGuardarConsulta,
  editConsultasMedicasFinalizarConsulta,
} from '../../utils/editInfo';
import FinalizarConsulta from '../../components/ModalDialogs/FinalizarConsulta';
import { resetConsulta } from '../../actions/consulta/actions';

function Navbar() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  /** state del componente */
  const [doctorEl, setDoctorEl] = useState<null | HTMLButtonElement>(null);
  const [hospitalEl, setHospitalEl] = useState<null | HTMLElement>(null);
  const [organizacionEl, setOrganizacionEl] = useState<null | HTMLElement>(null);
  // const [avatarURL] = useState(
  //   'https://media-exp3.licdn.com/dms/image/C5603AQG_u5SDFqU_Gw/profile-displayphoto-shrink_200_200/0/1612906027923?e=1631750400&v=beta&t=z2J3I4Qu-HMMvnHvSdPwSe3H9WfBujmOaL4WXHPDZnw',
  // );
  const [doctorName, setDoctorName] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalConsultaAbierta, setModalConsultaAbierta] = useState<boolean>(false);
  const openDoctorMenu = Boolean(doctorEl);
  const openHospitalMenu = Boolean(hospitalEl);
  const openOrganizacionMenu = Boolean(organizacionEl);
  const {
    nombre, primerApellido, segundoApellido, sexo, idMedico, rol, enOrganizacion,
  } = useSelector((state: RootState) => state.Me);
  const { idConsulta, idPaciente } = useSelector((state: RootState) => state.Consulta);
  const { consultorio, consultorios } = useSelector((state: RootState) => state.Consultorios);
  const { idConsultorio } = consultorio;
  const { organizacion, organizaciones } = useSelector((state: RootState) => state.Organizaciones);
  const { idOrganizacion } = organizacion;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [idHospital, setIDHospital] = useState<number>(0);
  const [prevLocation, setPrevLocation] = useState<string>('');
  const [consultaFinalizada, setConsultaFinalizada] = useState<boolean>(false);
  useEffect(() => {
    setDoctorName(
      `${nombre ? nombre[0] : ''}${primerApellido ? primerApellido[0] : ''}${
        segundoApellido ? segundoApellido[0] : ''
      }`,
    );
  }, [nombre, primerApellido, segundoApellido]);

  // OJO AQUI ES EL CAMBIO DE ORGANIZACION
  useEffect(() => {
    if (organizaciones.length) {
      const idOrg = getOrganizacionSS();
      if (idOrg) {
        dispatch(getOrganizacion(idMedico, idOrg));
      } else {
        setOrganizacionSS(organizaciones[0].id);
        dispatch(getOrganizacion(idMedico, organizaciones[0].id));
      }
    }
  }, [organizaciones]);
  useEffect(() => {
    if (!enOrganizacion || idOrganizacion > 0) {
      dispatch(getConsultorios(idMedico, idOrganizacion));
    }
  }, [organizacion]);
  // OJO AQUI ES EL CAMBIO DE CONSULTORIO
  useEffect(() => {
    if (consultorios.length) {
      setConsultorioSS(consultorios[0].id);
      dispatch(getConsultorio(idMedico, consultorios[0].id));
    }
  }, [consultorios]);
  /** Handlers del componente */
  useEffect(() => {
    if (idMedico > 0) {
      dispatch(getOrganizaciones(idMedico));
    }
  }, [idMedico]);

  useEffect(() => {
    if (location.pathname === '/ece') {
      setConsultaFinalizada(false);
    }
    /** Guardar la consulta si sale sin finalizar */
    if (!consultaFinalizada && prevLocation === '/ece' && idConsulta && idPaciente > 0) {
      dispatch(loading(true));
      const objActualizar = {
        idConsulta,
        idPaciente,
        idMedico,
        idConsultorio,
      };
      editConsultasMedicasGuardarConsulta(objActualizar)
        .then((response) => response.json())
        .then((result) => {
          if (result.code !== 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${result.msg}`,
              }),
            );
          }
          dispatch(loading(false));
        })
        .catch((err) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
          dispatch(loading(false));
        });
    }
    setPrevLocation(location.pathname);
  }, [location.pathname]);

  /** Abrir el menú doctor */
  const handleMenuDoctor = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDoctorEl(e.currentTarget);
  };

  /** Abrir el menú hospital */
  const handleMenuHospital = (e: React.MouseEvent<HTMLButtonElement>) => {
    setHospitalEl(e.currentTarget);
  };

  /** Abrir el menú organizacion */
  const handleMenuOrganizacion = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOrganizacionEl(e.currentTarget);
  };

  /** Cerrar el menú del doctor */
  const handleCloseDoctor = () => {
    setDoctorEl(null);
  };

  /** Cerrar el menu hospital */
  const handleCloseHospital = () => {
    setHospitalEl(null);
  };

  /** Cerrar el menu organizacion */
  const handleCloseOrganizacion = () => {
    setOrganizacionEl(null);
  };

  /** Abrir el Sidebar */
  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const cerrarModal = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenDialog(false);
  };

  /** Cerrar el Sidebar */
  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  const cerrarConsultaModalConsultaAbiertaModal = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setModalConsultaAbierta(false);
  };

  const cerrarSesion = () => {
    dispatch(loading(true));
    const obj = { token: getToken(), usuario: getUser() };
    sendLoguot(obj)
      .then((result) => result.json())
      .then((data) => {
        if (data.code === 200) {
          removeUserSession();
          dispatch(resetMe());
          dispatch(resetConsultorios());
          dispatch(resetConsultorio());
          dispatch(resetOrganizaciones());
          dispatch(resetOrganizacion());
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
              keyBack: '',
            }),
          );
          limpiarRedux();
          history.push('/login');
        }
        dispatch(loading(false));
      })
      .catch((err) => {
        if (err.toString() === 'SyntaxError: Unexpected token E in JSON at position 0') {
          removeUserSession();
          dispatch(resetMe());
          dispatch(resetConsultorios());
          dispatch(resetConsultorio());
          dispatch(resetOrganizaciones());
          dispatch(resetOrganizacion());
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
              keyBack: '',
            }),
          );
          limpiarRedux();
          history.push('/login');
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        }
        dispatch(loading(false));
      });
  };

  const finalizarConsulta = () => {
    setConsultaFinalizada(true);
    if (idConsulta || idPaciente > 0) {
      dispatch(loading(true));
      const objActualizar = {
        idConsulta,
        idPaciente,
        idMedico,
        idConsultorio,
      };
      editConsultasMedicasFinalizarConsulta(objActualizar)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            removeIdConsulta();
            dispatch(resetConsulta());
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('consulta_finalizada_correctamente'),
              }),
            );
            if (['/ece', '/tablero-paciente'].includes(location.pathname)) {
              history.push('/pacientes');
            }
            limpiarRedux();
          } else if (result.code === 300) {
            // el token y el idMedico no son el mismo
            /* Cerrar sesión */
            removeUserSession();
            dispatch(resetMe());
            limpiarRedux();
            dispatch(
              setAuth({
                id: '',
                suscrito: false,
                acreditado: false,
                keyBack: '',
              }),
            );
            history.push('/login');
          } else if (result.code === 301) {
            // el medico no tiene subscripcion activa
            /* Resetear y redirigir al checkout */
            removePaciente();
            limpiarRedux();
            dispatch(
              setAuth({
                id: '',
                suscrito: false,
                acreditado: true,
                keyBack: '',
              }),
            );
            history.push('/subscripcion');
          } else if (result.code === 302) {
            // el paciente y el medico no tienen relacion
            /* Resetear redux paciente y datos paciente y redirigir a paciente */
            removeIdConsulta();
            removePaciente();
            limpiarRedux();
            history.push('/pacientes');
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${result.msg}`,
              }),
            );
          }
          dispatch(loading(false));
        })
        .catch((err) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
          dispatch(loading(false));
        });
    }
  };

  const finalizarYCerrarSesion = () => {
    const objActualizar = {
      idConsulta,
      idPaciente,
      idMedico,
      idConsultorio,
    };
    dispatch(loading(true));
    editConsultasMedicasFinalizarConsulta(objActualizar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setModalConsultaAbierta(false);
          removeIdConsulta();
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('consulta_finalizada_correctamente'),
            }),
          );
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
              keyBack: '',
            }),
          );
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: true,
              keyBack: '',
            }),
          );
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${result.msg}`,
            }),
          );
        }
        cerrarSesion();
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  /** Manejar el eventoLogout */
  const handleLogout = () => {
    if (idPaciente > 0 && idMedico > 0 && idConsulta > 0) {
      setModalConsultaAbierta(true);
    } else {
      cerrarSesion();
    }
  };

  /** Manejar el evento cambiar hospita */
  const handleChangeHospital = (idHospitalCambio: number) => {
    if (idHospitalCambio !== idConsultorio) {
      if (!idConsulta || !(idPaciente > 0)) {
        setConsultorioSS(idHospitalCambio);
        dispatch(getConsultorio(idMedico, idHospitalCambio));
        removePaciente();
        limpiarRedux();
        if (location.pathname === '/tablero-paciente') {
          history.push('/pacientes');
        }
        handleCloseHospital();
      } else {
        setIDHospital(idHospitalCambio);
        setOpenDialog(true);
        handleCloseHospital();
      }
    } else {
      handleCloseHospital();
    }
  };

  /** Manejar el evento cambiar hospita */
  const handleChangeOrganizacion = (idOrganizacionCambio: number) => {
    if (idOrganizacionCambio !== idOrganizacion) {
      if (!idConsulta || !(idPaciente > 0)) {
        setOrganizacionSS(idOrganizacionCambio);
        dispatch(getOrganizacion(idMedico, idOrganizacionCambio));
        removePaciente();
        limpiarRedux();
        if (location.pathname === '/tablero-paciente') {
          history.push('/pacientes');
        }
        handleCloseOrganizacion();
      } else {
        setOpenDialog(true);
        handleCloseOrganizacion();
      }
    } else {
      handleCloseOrganizacion();
    }
  };

  const getTitulo = () => {
    let titulo = '';
    if (rol.tipo === 'medico') {
      titulo = sexo === 'M' ? t('dra_') : t('dr_');
    } else if (rol.tipo === 'enfermero') {
      titulo = t('enf_');
    }
    return titulo;
  };

  /** Regresa el navbar y el sidebar */
  return (
    <>
      <ModalConsultaAbierta
        open={modalConsultaAbierta}
        titulo={t('modal_con_abierta')}
        descripcion={t('consulta_abiert_seguro_cerrar_sesion_')}
        nombreCancelar={t('cancelar')}
        nombreAceptar={t('aceptar')}
        callBackAceptar={finalizarYCerrarSesion}
        callBackClose={cerrarConsultaModalConsultaAbiertaModal}
      />
      <FinalizarConsulta
        open={openDialog}
        titulo={t('_atencion_')}
        descripcion={t('_estas_seguro_de_finalizar_el_encuentro_')}
        idHospital={idHospital}
        setIDHospital={setIDHospital}
        callBackAceptar={finalizarConsulta}
        callBackClose={cerrarModal}
      />
      <CssBaseline />
      <div className="text-white bg-white z-30">
        <AppBar
          position="fixed"
          color="inherit"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: sidebarOpen,
          })}
        >
          <Toolbar>
            <div className="text-gray-900">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="expandir menu"
                onClick={handleSidebarOpen}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div className="text-gray-900 w-100 flex-grow">
              <LogoIcon height="55" />
            </div>
            {enOrganizacion && (
              <>
                <Button
                  color="default"
                  startIcon={<Business />}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handleMenuOrganizacion}
                  disableElevation
                >
                  {organizacion.nombreOrganizacion}
                </Button>
                <MenuOrganizacion
                  anchorEl={organizacionEl}
                  handleClose={handleCloseOrganizacion}
                  open={openOrganizacionMenu}
                  handleCambiarOrganizacion={handleChangeOrganizacion}
                />
              </>
            )}
            <Button
              color="default"
              startIcon={<ApartmentIcon />}
              endIcon={<ArrowDropDownIcon />}
              onClick={handleMenuHospital}
              disableElevation
            >
              {consultorio.nombre}
            </Button>
            <MenuHospital
              anchorEl={hospitalEl}
              handleClose={handleCloseHospital}
              open={openHospitalMenu}
              handleCambiarHospital={handleChangeHospital}
            />
            {/* <Button color="default" startIcon={<HelpOutlineIcon />} disableElevation>
              Ayuda
            </Button> */}
            <Button
              color="default"
              startIcon={<PersonIcon />}
              endIcon={(
                <Avatar variant="rounded" style={{ backgroundColor: '#1D4ED8' }} alt={doctorName}>
                  {doctorName}
                </Avatar>
              )}
              onClick={handleMenuDoctor}
              disableElevation
            >
              {`${getTitulo()} ${nombre} ${primerApellido} ${segundoApellido}`}
            </Button>
            <MenuDoctor
              anchorEl={doctorEl}
              handleClose={handleCloseDoctor}
              open={openDoctorMenu}
              handleLogout={handleLogout}
            />
          </Toolbar>
        </AppBar>
      </div>
      <SideBar
        sidebarOpen={sidebarOpen}
        setOpenDialog={setOpenDialog}
        handleSidebarClose={handleSidebarClose}
      />
    </>
  );
}

export default Navbar;
