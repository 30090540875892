import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { IDeteccionTempranaCancerProps } from './types';
import { getToken, getRecomendacionReferencia } from '../../../../../../utils/common';
import { setLoading } from '../../../../../../actions/loading/actions';
import { getDetectionLungCancer } from '../../../../../../utils/getCatalogos';
import { RootState } from '../../../../../../store';
import ModalRecomendacion from '../ModalRecomendacion';
import DeteccionCancerCervical from './DeteccionCancerCervical';

function DeteccionTempranaCancer({
  selIdPaciente,
  selEdadPaciente,
}: IDeteccionTempranaCancerProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    idPaciente,
    sexo,
    edad: { edad },
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const { secciones } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [ref, setRef] = useState<any>({});
  const [txtMostrar, setTxtMostrar] = useState<number>(0);
  const [mujerEdad, setMujerEdad] = useState<number>(0);
  const [edadGeneral] = useState<number>(selEdadPaciente || edad);

  useEffect(() => {
    dispatch(setLoading(true));
    getDetectionLungCancer(idMedico, selIdPaciente || idPaciente, idConsultorio)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setTxtMostrar(result.datos);
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }, []);

  useEffect(() => {
    if (edadGeneral >= 21 && edadGeneral <= 29) {
      setMujerEdad(1);
    } else if (edadGeneral >= 30 && edadGeneral <= 65) {
      setMujerEdad(2);
    } else if (edadGeneral >= 66) {
      setMujerEdad(3);
    }
  }, [edadGeneral]);

  return (
    <div className="flex-1">
      <ModalRecomendacion
        open={openModal}
        callBackClose={() => {
          setOpenModal(false);
          setRef({});
        }}
        referencia={ref}
      />
      {edadGeneral >= 50 && (txtMostrar === 1 || txtMostrar === 2) && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
          <div className="border-0 border-solid border-red-600 rounded border-l-8 p-4 pr-5 w-full">
            <h3 className="m-0 text-left text-blue-500 font-normal">{t('det_tem_can')}</h3>
            <div className="flex pt-2">
              <h4 className="font-normal m-0 text-gray-900 pr-1">
                {`${t('det_tem_can_analisis')}: `}
              </h4>
              <h4 className="font-normal m-0 text-red-500">{t('det_tem_can_alto')}</h4>
            </div>
            <div className="flex pt-2">
              <h4 className="font-normal m-0 text-gray-500 pr-4">
                {`${t('det_tem_can_analisis')}: ${t('det_tem_can_edad_entre')}`}
              </h4>
              <h4 className="font-normal m-0 text-gray-500 pr-4">
                {`${t('det_tem_can_estatus')}: ${
                  txtMostrar === 1 ? t('det_tem_can_estatus_activo') : t('det_tem_can_estatus_ex')
                }`}
              </h4>
              <h4 className="font-normal m-0 text-gray-500">
                {`${t('det_tem_can_ipa')}: ${t('det_tem_can_ipa_valor')}`}
              </h4>
            </div>
            <h4 className="font-normal m-0 text-gray-900 pt-2">{`${t('det_tem_can_usptf')}:`}</h4>
            <h4 className="font-normal m-0 text-gray-500 py-2">{t('det_tem_can_tomografia')}</h4>
          </div>
          <div className="min-w-max self-end">
            <Button
              color="primary"
              onClick={() => {
                const token = getToken();
                fetch(getRecomendacionReferencia('5'), {
                  method: 'GET',
                  headers: { Authorization: `Bearer ${token}` },
                })
                  .then((response) => response.json())
                  .then((result) => {
                    if (result.code === 200 && result.datos && result.datos.length > 0) {
                      setRef(result.datos[0]);
                      setOpenModal(true);
                    }
                  })
                  .catch(() => {
                    setRef({});
                  });
              }}
            >
              {t('ver_recomendacion')}
            </Button>
          </div>
        </div>
      )}
      {edadGeneral >= 50 && txtMostrar === 3 && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
          <div className="border-0 border-solid border-red-600 rounded border-l-8 p-4 pr-5 w-full">
            <h3 className="m-0 text-left text-blue-500 font-normal">{t('det_tem_can')}</h3>
            <ul>
              <li className="text-red-500">
                <span className="text-gray-500">{t('det_tem_can_txt1')}</span>
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 28) && (
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push('/ece/antecedentes/personales-no-patologicos/toxicomania');
                    }}
                  >
                    {`${t('det_tem_can_txt2')}.`}
                  </Button>
                )}
                <span className="text-gray-500">{t('det_tem_can_txt3')}</span>
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className="py-2" />
      {sexo === 'M' && !selIdPaciente && <DeteccionCancerCervical edad={mujerEdad} />}
    </div>
  );
}

export default DeteccionTempranaCancer;
