import {
  CheckSaveActionTypes,
  RESET_CHECK_SAVE,
  SET_CARGA_SAVE,
  SET_MODIFICACION_SAVE,
} from '../actions/checkSave/types';

const initialState = {
  carga: false,
  modificacion: false,
};

function CheckSave(state = initialState, action: CheckSaveActionTypes) {
  switch (action.type) {
    case SET_MODIFICACION_SAVE:
      return {
        ...state,
        modificacion: action.payload,
      };
    case SET_CARGA_SAVE:
      return {
        ...state,
        carga: action.payload,
      };
    case RESET_CHECK_SAVE:
      return initialState;
    default:
      return state;
  }
}

export default CheckSave;
