import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField, Button, FormGroup, FormControlLabel, Checkbox,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ICatalogosCie10 } from '../../../../../../../common/types';
import { getCatCie10QueryCategoria } from '../../../../../../../utils/getCatalogos';
import { RootState } from '../../../../../../../store';
import {
  enfermedadesGuardarGrupoEnfermedadesNegadas,
  enfermedadesGuardarNegada,
} from '../../../../../../../utils/sendInfo';
import { setSnackComplete } from '../../../../../../../actions/snackbar/types';
import { INegarEnfermedadesProps } from './types';
import { setRequest } from '../../../../../../../actions/request/types';

function NegarEnfermedades({
  enfNegadas,
  setEnfNegadas,
  grupoEnfNegadas,
  setGrupoEnfNegadas,
}: INegarEnfermedadesProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    idPaciente,
    sexo: sexoPaciente,
    edad: { edad, periodo },
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const [diagnosticoCie10, setDiagnosticoCie10] = useState<ICatalogosCie10>({
    id: '0',
    label: '',
    grupoEnfermedades: 0,
  });

  const [catCIE10, setCatCIE10] = useState<ICatalogosCie10[]>([]);
  const [openCIE10, setOpenCIE10] = useState<boolean>(false);
  const [loadingCIE10, setLoadingCIE10] = useState<boolean>(false);
  const [inputCIE10, setInputCIE10] = useState<string>('');

  const handleChangeCIE = (event: any, newValue: ICatalogosCie10 | null, reason: string) => {
    setDiagnosticoCie10(
      reason !== 'clear' && newValue ? newValue : { id: '', label: '', grupoEnfermedades: 0 },
    );
    setOpenCIE10(reason === 'clear');
  };

  const handleChangeNegarEnfermedades = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nuevaLista = {
      ...grupoEnfNegadas,
      [`${event.target.name}Selected`]: event.target.checked,
    };
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enfermedadesGuardarGrupoEnfermedadesNegadas({
          idMedico,
          idPaciente,
          idConsultorio,
          consulta: idConsulta,
          listaGrupoEnfermedadesNegadas: nuevaLista,
        }),
        successFunction: () => setGrupoEnfNegadas(nuevaLista),
      }),
    );
  };

  const handleClickAgregarDiagnostico = () => {
    /*
     *  QUIZA FALTA ALGUN ALGORITMO PARA DETECTAR QUE SI EXISTE UNA ENFERMEDAD
     *  QUE SEA SUBGRUPO DE LA ENFERMEDAD PREVIAMENTE REFERIDA SALGA
     *  UN MODAL QUE NOS DIGA QUE EXISTE UNA PREVIA
     *  QUIZA HACERLO MAS ADELANTE
     */
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enfermedadesGuardarNegada({
          idMedico,
          idPaciente,
          idConsultorio,
          consulta: idConsulta,
          enfermedadNegada: {
            idBD: 0,
            diagnosticoCie10,
          },
        }),
        successFunction: (result: any) => {
          const LEN = {
            idBD: result.data.idBD,
            diagnosticoCie10,
          };
          setEnfNegadas([...enfNegadas, LEN]);
          setDiagnosticoCie10({ id: '0', label: '', grupoEnfermedades: 0 });
        },
        errorFunction: (result: any) => {
          if (result.code === 201) {
            setDiagnosticoCie10({ id: '0', label: '', grupoEnfermedades: 0 });
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'info',
                mensaje: t('negar_enfermedad_existe'),
              }),
            );
          }
        },
        showMsgError: false,
      }),
    );
  };

  useEffect(() => {
    if (!openCIE10) {
      setCatCIE10([]);
    }
  }, [openCIE10]);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    if (inputCIE10.length > 2) {
      let sexo = '';
      if (sexoPaciente === 'H') {
        sexo = 'HOMBRE';
      } else if (sexoPaciente === 'M') {
        sexo = 'MUJER';
      }
      setLoadingCIE10(true);
      getCatCie10QueryCategoria(idPaciente, inputCIE10, sexo, periodo, edad, signal)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatCIE10(result.datos);
          } else {
            setCatCIE10([]);
          }
          setLoadingCIE10(false);
        })
        .catch(() => {
          setCatCIE10([]);
          setLoadingCIE10(false);
        });
    } else {
      setCatCIE10([]);
      setLoadingCIE10(false);
    }
    return () => abortController.abort();
  }, [inputCIE10]);

  return (
    <div>
      <h3 className="font-normal mt-0 text-gray-500">
        <span className="font-bold">{t('instrucciones')}</span>
        :&nbsp;
        {t('podras_negar')}
        &nbsp;
        <span className="font-bold">{t('enfermedades_especificas')}</span>
        &nbsp;
        {t('y/o')}
        &nbsp;
        <span className="font-bold">{t('grupos_de_enfermedades')}</span>
      </h3>
      <div className="border border-solid rounded border-gray-500 p-4 pb-2 mb-4">
        <h3 className="mt-0 text-gray-500">{t('negar_enfermedad_especifica')}</h3>
        <Autocomplete
          open={openCIE10}
          onOpen={() => {
            setOpenCIE10(true);
          }}
          onClose={() => {
            setOpenCIE10(false);
          }}
          getOptionLabel={(option) => option.label}
          options={catCIE10}
          loading={loadingCIE10}
          loadingText="Buscando..."
          noOptionsText={
            inputCIE10.length <= 2 ? t('introduce-tres-caracteres') : t('sin_resultados')
          }
          value={diagnosticoCie10}
          onChange={(e, nV, r) => handleChangeCIE(e, nV, r)}
          onInputChange={(e, newInputValue) => {
            if (newInputValue.length < 3) {
              setCatCIE10([]);
            }
            setInputCIE10(newInputValue);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('selecciona')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingCIE10 ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <div className="text-right pt-2">
          <Button
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleClickAgregarDiagnostico}
          >
            {t('guardar_diagnostico')}
          </Button>
        </div>
      </div>
      <div className="border border-solid rounded border-gray-500 p-4 mb-4">
        <h3 className="m-0 text-gray-500">{t('negar_grupo_enfermedades')}</h3>
        <div>
          <FormGroup>
            <div className="grid grid-cols-2">
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.infecciosasYParasitariasSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="infecciosasYParasitarias"
                    disabled={grupoEnfNegadas.infecciosasYParasitariasDisabled}
                  />
                )}
                label={t('infecciosas_y_parasitarias_(A00-B99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.neoplasiasSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="neoplasias"
                    disabled={grupoEnfNegadas.neoplasiasDisabled}
                  />
                )}
                label={t('neoplasias_(C00-D48)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={
                      grupoEnfNegadas.hematologicasOQueAfectanElMecanismoDeLaInmunidadSelected
                    }
                    onChange={handleChangeNegarEnfermedades}
                    name="hematologicasOQueAfectanElMecanismoDeLaInmunidad"
                    disabled={
                      grupoEnfNegadas.hematologicasOQueAfectanElMecanismoDeLaInmunidadDisabled
                    }
                  />
                )}
                label={t('hematologicas_o_que_afectan_el_mecanismo_de_la_inmunidad_(D50-D89)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.endocrinasNutricionalesYMetabolicasSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="endocrinasNutricionalesYMetabolicas"
                    disabled={grupoEnfNegadas.endocrinasNutricionalesYMetabolicasDisabled}
                  />
                )}
                label={t('endocrinas_nutricionales_y_metabolicas_(E00-E90)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.trastornosMentalesYDelComportamientoSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="trastornosMentalesYDelComportamiento"
                    disabled={grupoEnfNegadas.trastornosMentalesYDelComportamientoDisabled}
                  />
                )}
                label={t('trastornos_mentales_y_del_comportamiento_(F00-F99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.sistemaNerviosoSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaNervioso"
                    disabled={grupoEnfNegadas.sistemaNerviosoDisabled}
                  />
                )}
                label={t('sistema_nervioso_(G00-G99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.delOjoYSusAnexosSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="delOjoYSusAnexos"
                    disabled={grupoEnfNegadas.delOjoYSusAnexosDisabled}
                  />
                )}
                label={t('del_ojo_y_sus_anexos_(H00-H59)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.delOidoYApofisisMastoideSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="delOidoYApofisisMastoide"
                    disabled={grupoEnfNegadas.delOidoYApofisisMastoideDisabled}
                  />
                )}
                label={t('del_oido_y_apofisis_mastoide_(H60-H95)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.sistemaCirculatorioSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaCirculatorio"
                    disabled={grupoEnfNegadas.sistemaCirculatorioDisabled}
                  />
                )}
                label={t('sistema_circulatorio_(I00-I99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.sistemaRespiratorioSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaRespiratorio"
                    disabled={grupoEnfNegadas.sistemaRespiratorioDisabled}
                  />
                )}
                label={t('sistema_respiratorio_(J00-J99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.sistemaDigestivoSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaDigestivo"
                    disabled={grupoEnfNegadas.sistemaDigestivoDisabled}
                  />
                )}
                label={t('sistema_digestivo_(K00-K93)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.pielYDelTejidoSubcutaneoSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="pielYDelTejidoSubcutaneo"
                    disabled={grupoEnfNegadas.pielYDelTejidoSubcutaneoDisabled}
                  />
                )}
                label={t('piel_y_del_tejido_subcutaneo_(L00-L99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.sistemaOsteomuscularYDelTejidoConjuntivoSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaOsteomuscularYDelTejidoConjuntivo"
                    disabled={grupoEnfNegadas.sistemaOsteomuscularYDelTejidoConjuntivoDisabled}
                  />
                )}
                label={t('sistema_osteomuscular_y_del_tejido_conjuntivo_(M00-M99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.sistemaGenitourinarioSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaGenitourinario"
                    disabled={grupoEnfNegadas.sistemaGenitourinarioDisabled}
                  />
                )}
                label={t('sistema_genitourinario_(N00-N99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.embarazoPartoPuerperioSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="embarazoPartoPuerperio"
                    disabled={grupoEnfNegadas.embarazoPartoPuerperioDisabled}
                  />
                )}
                label={t('embarazo_parto_puerperio_(O00-O99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.delPeriodoPerinatalSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="delPeriodoPerinatal"
                    disabled={grupoEnfNegadas.delPeriodoPerinatalDisabled}
                  />
                )}
                label={t('del_periodo_perinatal_(P00-P96)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={grupoEnfNegadas.malformaconesCongenitasYAnomaliasCongenitasSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="malformaconesCongenitasYAnomaliasCongenitas"
                    disabled={grupoEnfNegadas.malformaconesCongenitasYAnomaliasCongenitasDisabled}
                  />
                )}
                label={t('malformacones_congenitas_y_anomalias_congenitas_(Q00-Q99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={
                      grupoEnfNegadas.traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasSelected
                    }
                    onChange={handleChangeNegarEnfermedades}
                    name="traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternas"
                    disabled={
                      grupoEnfNegadas.traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasDisabled
                    }
                  />
                )}
                label={t(
                  'traumatismo_y_envenenamiento_y_otras_consecuencias_de_causas_externas_(S00-T98)',
                )}
              />
            </div>
          </FormGroup>
        </div>
      </div>
      <span className="text-gray-500">
        {t('_grupos_de_enfermedades_considerando_la_clasificacion_del_CIE-10')}
      </span>
    </div>
  );
}

export default NegarEnfermedades;
