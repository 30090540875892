export const SET_CONSULTA = 'SET_CONSULTA';
export const SET_ID_PACIENTE_CONSULTA = 'SET_ID_PACIENTE_CONSULTA';
export const RESET_CONSULTA = 'RESET_CONSULTA';

interface IConsulta {
  idConsulta: number;
  idPaciente: number;
}

interface SetConsultaAction {
  type: typeof SET_CONSULTA;
  payload: IConsulta;
}

interface SetIdPacienteConsultaAction {
  type: typeof SET_ID_PACIENTE_CONSULTA;
  payload: number;
}

interface ResetConsultaAction {
  type: typeof RESET_CONSULTA;
}

export type ConsultaActionTypes =
  | SetConsultaAction
  | SetIdPacienteConsultaAction
  | ResetConsultaAction;
