import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import { RootState } from '../../../../../../store';
import { setLoading } from '../../../../../../actions/loading/actions';
import { setSnackComplete } from '../../../../../../actions/snackbar/types';
import { esMismoDiaFechaISO, getFechaActualISO, hasKey } from '../../../../../../common/functions';
import ComentariosComponente from '../../../../../../components/Comentarios';
import { numeroEnfermedadGrupo } from '../../../../../../common/enfermedades';
import { enfermedadesGuardarGrupoEnfermedadesNegadas } from '../../../../../../utils/sendInfo';
import { enfermedadesEditReferida } from '../../../../../../utils/editInfo';
import { enfermedadesEliminarNegada } from '../../../../../../utils/deleteInfo';
import AlertaEnfermedadGuardado from '../../../../EnfermedadesDialogs/AlertaEnfermedadGuardado';
import { IFormPreexistenteProps } from './types';
import { setRequest } from '../../../../../../actions/request/types';
import { enfermedadesFormInitial } from '../../../../Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';

function FormPreexistente({
  idDiagnostico,
  setIdDiagnostico,
  setSeleccionDiagnostico,
  diagnostico,
  setDiagnostico,
  enfReferidas,
  setEnfReferidas,
  enfNegadas,
  setEnfNegadas,
  grupoEnfNegadas,
  setGrupoEnfNegadas,
}: IFormPreexistenteProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const [keyComentarios, setKeyComentarios] = useState<number>(1);
  const [openAlertEnfermedad, setOpenAlertEnfermedad] = useState(false);
  const [indiceRepetido, setIndiceRepetido] = useState(-1);

  const cambiarKeyComentarios = () => {
    setKeyComentarios(Math.floor(Math.random() * 1000) + 1);
  };

  const handleChangeTipoDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiagnostico({
      ...diagnostico,
      tipoDiagnostico: event.target.value as 'cronico' | 'agudo' | '',
    });
  };

  const handleChangeCertezaDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiagnostico({
      ...diagnostico,
      certezaDelDiagnostico: event.target.value as 'presuntivo' | 'confirmado' | '',
    });
  };

  const handleChangeEstatusDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiagnostico({
      ...diagnostico,
      estatusDelDiagnostico: event.target.value as 'activo' | 'resuelto' | '',
    });
  };

  const setComentarios = (data: RawDraftContentState) => {
    setDiagnostico({
      ...diagnostico,
      comentario: data,
    });
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertEnfermedad(false);
  };

  const limpiarCampos = () => {
    setSeleccionDiagnostico('cie10');
    setIdDiagnostico(-1);
    setDiagnostico(enfermedadesFormInitial);
  };

  const handleCancelar = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    limpiarCampos();
  };

  const handleClickActualizarDiagnostico = () => {
    if (diagnostico.idBD > 0) {
      const index = enfReferidas.findIndex((e) => e.idBD === diagnostico.idBD);
      const enfComentarios = enfReferidas[index].comentarios;
      let comentarios = [];
      if (
        diagnostico.comentario.blocks.length
        && !diagnostico.comentario.blocks.every((row) => row.text === '' || /^\s*$/.test(row.text))
      ) {
        // si existen comentarios previos que coincidan con la misma fecha, se reemplazara
        if (
          enfComentarios.length > 0
          // eslint-disable-next-line max-len
          && esMismoDiaFechaISO(enfComentarios[0].fecha, getFechaActualISO())
        ) {
          comentarios = [
            { fecha: getFechaActualISO(), comentario: diagnostico.comentario },
            ...enfComentarios.slice(1),
          ];
        } else {
          comentarios = [
            { fecha: getFechaActualISO(), comentario: diagnostico.comentario },
            ...enfComentarios,
          ];
        }
      } else {
        // si los comentarios no tienen informacion los dejamos como esta
        comentarios = [...enfComentarios];
      }
      const objDiagnostico = {
        ...enfReferidas[index],
        seleccionDiagnostico: diagnostico.seleccionDiagnostico,
        diagnosticoCie10: diagnostico.diagnosticoCie10,
        diagnosticoMedico: diagnostico.diagnosticoMedico,
        tipoDiagnostico: diagnostico.tipoDiagnostico,
        certezaDelDiagnostico: diagnostico.certezaDelDiagnostico,
        estatusDelDiagnostico: diagnostico.estatusDelDiagnostico,
        comentarios,
        idConsulta,
      };
      const objEnviar = {
        idMedico,
        idPaciente,
        idConsultorio,
        enfermedadReferida: objDiagnostico,
        consulta: idConsulta,
      };
      const LGEN: any = { ...grupoEnfNegadas };
      // si el diagnostico es cie 10 entonces tenemos que hacer varias verificaciones
      if (diagnostico.seleccionDiagnostico === 'cie10') {
        // tenemos que verificar que esa misma enfermedad no esté activa
        // solamente si esta es referida
        // si esta activa tenemos que mostrar el modal con las opciones de que hacer con ella
        if (objDiagnostico.estatusDelDiagnostico === 'activo') {
          const found = enfReferidas.findIndex(
            (enfermedad) => enfermedad.diagnosticoCie10.id === objDiagnostico.diagnosticoCie10.id
              && enfermedad.estatusDelDiagnostico === 'activo'
              && enfermedad.idBD !== objDiagnostico.idBD,
          );
          if (found >= 0) {
            setIndiceRepetido(found);
            setOpenAlertEnfermedad(true);
            return;
          }
        }
        // tenemos que obtener el catalog_key padre de la enfermedad introducida
        // para buscarla en la lista de enfermedades negadas, y si resulta que existe
        // en la lista de enfermedades negadas, procederemos a eliminarla
        const idPadre = diagnostico.diagnosticoCie10.id.substr(0, 3);
        const findIndxEN = enfNegadas.findIndex((len) => len.diagnosticoCie10.id === idPadre);
        if (findIndxEN >= 0) {
          const idABorrar = enfNegadas[findIndxEN].idBD;
          dispatch(
            setRequest({
              type: 'delete',
              requestFunction: enfermedadesEliminarNegada({
                idMedico,
                idPaciente,
                idConsultorio,
                idBD: idABorrar,
                consulta: idConsulta,
              }),
              successFunction: () => {
                const LEN = enfNegadas.filter((enfN) => enfN.diagnosticoCie10.id !== idPadre);
                setEnfNegadas(LEN);
              },
            }),
          );
        }
        // AQUI DEBERIAMOS VOLVER A RECREAR LA LISTA CON ALGUN ALGORITMO
        /* FALTA ALGORITMO */
        const grupoEnf = numeroEnfermedadGrupo[diagnostico.diagnosticoCie10.grupoEnfermedades];
        const keySelected = `${grupoEnf}Selected`;
        const keyDisabled = `${grupoEnf}Disabled`;
        // cuando lo encontremos tenemos que deseleccionalo si llegara a existir
        // y tenemos que deshabilitarlo
        if (hasKey(grupoEnfNegadas, keySelected) && hasKey(grupoEnfNegadas, keyDisabled)) {
          LGEN[keySelected] = false;
          LGEN[keyDisabled] = true;
        }
      }
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: enfermedadesEditReferida(objEnviar),
          successFunction: () => {
            dispatch(setLoading(true));
            enfermedadesGuardarGrupoEnfermedadesNegadas({
              idMedico,
              idPaciente,
              idConsultorio,
              consulta: idConsulta,
              listaGrupoEnfermedadesNegadas: LGEN,
            })
              .then((response) => response.json())
              .then((result) => {
                if (result.code === 200) {
                  // al resultado positivo procedemos a actualizar el grupo de enfermedades negadas
                  setGrupoEnfNegadas(LGEN);
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'success',
                      mensaje: t('message-success'),
                    }),
                  );
                } else {
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'error',
                      mensaje: `${t('message-error-warning')}: ${result.msg}`,
                    }),
                  );
                }
                dispatch(setLoading(false));
              })
              .catch((err) => {
                dispatch(
                  setSnackComplete({
                    open: true,
                    severity: 'error',
                    mensaje: `${t('message-error-warning')}: ${err}`,
                  }),
                );
                dispatch(setLoading(false));
              });
            const nuevoArray = [...enfReferidas];
            nuevoArray[index] = { ...objDiagnostico };
            setEnfReferidas(nuevoArray);
            cambiarKeyComentarios();
            limpiarCampos();
          },
        }),
      );
    }
  };

  const verificarDisableActualizarDiagnostico = () => diagnostico.idBD === -1
    || diagnostico.tipoDiagnostico === ''
    || diagnostico.certezaDelDiagnostico === ''
    || diagnostico.estatusDelDiagnostico === '';

  const handleChangeDiagnosticoPreexistente = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const idBD = parseInt(val, 10) || 0;
    if (idBD > 0) {
      cambiarKeyComentarios();
      const enfermedad = enfReferidas.find((e) => e.idBD === idBD);
      if (enfermedad) {
        setSeleccionDiagnostico(enfermedad.seleccionDiagnostico);
        setIdDiagnostico(enfermedad.idBD);
        setDiagnostico({
          idBD: enfermedad.idBD,
          seleccionDiagnostico: enfermedad.seleccionDiagnostico,
          diagnosticoCie10: enfermedad.diagnosticoCie10,
          diagnosticoMedico: enfermedad.diagnosticoMedico,
          tipoDiagnostico: enfermedad.tipoDiagnostico,
          certezaDelDiagnostico: enfermedad.certezaDelDiagnostico,
          estatusDelDiagnostico: enfermedad.estatusDelDiagnostico,
          edadAlDiagnostico: enfermedad.edadAlDiagnostico,
          fechaRegistroDiagnostico: enfermedad.fechaRegistroDiagnostico,
          comentario: { entityMap: {}, blocks: [] },
          idConsulta: enfermedad.idConsulta,
        });
      }
    }
  };

  return (
    <div>
      <AlertaEnfermedadGuardado
        open={openAlertEnfermedad}
        callBackClose={handleAlertClose}
        listaEnfermedades={enfReferidas}
        setListaEnfermedades={setEnfReferidas}
        indiceRepetido={indiceRepetido}
        objForm={diagnostico}
        estaEnDiagnostico
      />
      <div className="my-4">
        <FormControl variant="outlined" fullWidth>
          <Select
            displayEmpty
            value={diagnostico.idBD}
            onChange={handleChangeDiagnosticoPreexistente}
          >
            <MenuItem value={0} disabled>
              <span className="text-gray-400">{t('selecciona')}</span>
            </MenuItem>
            {enfReferidas
              .filter((e) => e.estatusDelDiagnostico === 'activo')
              .map((e) => (
                <MenuItem
                  key={
                    e.seleccionDiagnostico === 'cie10' ? e.diagnosticoCie10.id : e.diagnosticoMedico
                  }
                  value={e.idBD}
                >
                  {e.seleccionDiagnostico === 'cie10'
                    ? e.diagnosticoCie10.label
                    : e.diagnosticoMedico}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex my-4">
        <div className="mr-4">
          <h3 className="m-0 mr-4 text-gray-600 font-medium mb-2">
            {`${t('tipo_de_diagnostico')}*`}
          </h3>
          <RadioGroup
            row
            name="tipo diagnostico"
            value={diagnostico.tipoDiagnostico}
            onChange={handleChangeTipoDiagnostico}
          >
            <FormControlLabel
              value="cronico"
              control={<Radio color="primary" />}
              label={t('cronico')}
              labelPlacement="end"
            />
            <FormControlLabel
              value="agudo"
              control={<Radio color="primary" />}
              label={t('agudo')}
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
        <div className="mr-4">
          <h3 className="m-0 mr-4 text-gray-600 font-medium mb-2">
            {`${t('certeza_del_diagnostico')}*`}
          </h3>
          <RadioGroup
            row
            name="diagnostico"
            value={diagnostico.certezaDelDiagnostico}
            onChange={handleChangeCertezaDiagnostico}
          >
            <FormControlLabel
              value="presuntivo"
              control={<Radio color="primary" />}
              label={t('presuntivo')}
              labelPlacement="end"
            />
            <FormControlLabel
              value="confirmado"
              control={<Radio color="primary" />}
              label={t('confirmado')}
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
        <div>
          <h3 className="m-0 mr-4 text-gray-600 font-medium mb-2">
            {`${t('estatus_del_diagnostico')}*`}
          </h3>
          <RadioGroup
            row
            name="diagnostico"
            value={diagnostico.estatusDelDiagnostico}
            onChange={handleChangeEstatusDiagnostico}
          >
            <FormControlLabel
              value="activo"
              control={<Radio color="primary" />}
              label={t('activo')}
              labelPlacement="end"
            />
            <FormControlLabel
              value="resuelto"
              control={<Radio color="primary" />}
              label={t('resuelto')}
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
      </div>
      <ComentariosComponente
        comentarios={diagnostico.comentario}
        setComentarios={setComentarios}
        rows={4}
        maxLength={400}
        placeholder={t('comentarios')}
        lang="es"
        disabled={false}
        key={keyComentarios}
      />
      <div className="flex justify-between items-center my-4">
        <span>
          *
          {t('campos_obligatorios')}
        </span>
        <div className="flex flex-row">
          <Button
            color="primary"
            variant="contained"
            disabled={verificarDisableActualizarDiagnostico()}
            onClick={handleClickActualizarDiagnostico}
            startIcon={<SyncIcon />}
          >
            {t('actualizar')}
          </Button>
          <div className="ml-2">
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={idDiagnostico === -1}
              onClick={(e) => {
                handleCancelar(e);
              }}
            >
              {t('cancelar')}
            </Button>
          </div>
        </div>
      </div>
      <div className="text-right">
        {`${t('para_registrar_los_cambios_debes_dar_clic_en_el_boton')} `}
        <span className="font-bold">{`“${t('actualizar')}”`}</span>
      </div>
    </div>
  );
}

export default FormPreexistente;
