import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Consultorios from './Consultorios';
import FormularioConsultorios from './FormularioConsultorios';
import { IMisConsultoriosProps } from './types';
import DeleteDialog from '../../../../components/DeleteDialog';
import { IDatosConsultorio } from '../../types';

function MiOrganizacionConsultorios(props: IMisConsultoriosProps) {
  const { t } = useTranslation();
  const {
    idActualizar,
    setIdActualizar,
    objConsultorio,
    setObjConsultorio,
    consultorios,
    setConsultorios,
    catAGEE,
    catTipoEstablecimiento,
    setDisabledSave,
    setDisabledNext,
  } = props;
  const [indexToDelete, setIndexToDelete] = useState<number>(-1);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [consultoriosFilter, setConsultoriosFilter] = useState<IDatosConsultorio[]>([]);

  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };
  const handleEditConsultorio = (indice = indexToDelete) => {
    const idCons = consultorios.findIndex(
      (consultorio) => JSON.stringify(consultorio) === JSON.stringify(consultoriosFilter[indice]),
    );
    setIdActualizar(idCons);
    window.scrollTo({ top: 380, left: 0, behavior: 'smooth' });
  };
  const handleDeleteConsultorio = () => {
    const nuevaLista = [...consultorios];
    const idCons = consultorios.findIndex(
      (consultorio) => JSON.stringify(consultorio) === JSON.stringify(consultoriosFilter[indexToDelete]),
    );
    nuevaLista[idCons].borrado = true;
    setConsultorios(nuevaLista);
  };

  useEffect(() => {
    setConsultoriosFilter(consultorios.filter((cons) => !cons.borrado));
    if (consultorios.length > 0 && consultorios.filter((cons) => !cons.borrado).length === 0) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
    if (
      consultorios.length > 0
      && consultorios.filter((cons) => cons.idConsultorio === -1 || cons.actualizado || cons.borrado)
        .length !== 0
    ) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [consultorios]);

  return (
    <div className="p-2">
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('_seguro_que_deseas_eliminar_el_registro_')}
        callBackAceptar={handleDeleteConsultorio}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_el_registro_se_borrara_el_que_estas_creando')}
        callBackAceptar={handleEditConsultorio}
        callBackClose={handleEditAlertClose}
      />
      <FormularioConsultorios
        setConsultorios={setConsultorios}
        idActualizar={idActualizar}
        setIdActualizar={setIdActualizar}
        consultorios={consultorios}
        catAGEE={catAGEE}
        catTipoEstablecimiento={catTipoEstablecimiento}
        objConsultorio={objConsultorio}
        setObjConsultorio={setObjConsultorio}
      />
      {consultoriosFilter.length > 0 && (
        <>
          <h2 className="text-blue-800 font-normal">{t('lista_de_consultorios')}</h2>
          {consultoriosFilter.map((cons, indx) => (
            <Consultorios
              consultorio={cons}
              objConsultorio={objConsultorio}
              index={indx}
              key={cons.idConsultorio}
              editConsultorio={handleEditConsultorio}
              setDeleteAlert={setDeleteAlert}
              setEditAlert={setEditAlert}
              setIndexToDelete={setIndexToDelete}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default MiOrganizacionConsultorios;
