/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { IBotonPlan, IPlanProps } from './types';
import useStyles from './styles';
import { RootState } from '../../../../store';
import { OrangeButton } from '../../../../utils/ComponentesJSX';
import { ReactComponent as LaboratorioIcon } from '../../../../assets/icons/Laboratorio.svg';
import { ReactComponent as ProcedimientosQuirurgicosIcon } from '../../../../assets/icons/ProcedimientosQuirurgicos.svg';
import { ReactComponent as ProcedimientosTerapeuticosIcon } from '../../../../assets/icons/ProcedimientosTerapeuticos.svg';
import { ReactComponent as ProcedimientosDiagnosticosIcon } from '../../../../assets/icons/ProcedimientosDiagnosticos.svg';
import { ReactComponent as TerapiaFarmacologicaIcon } from '../../../../assets/icons/TerapiaFarmacologica.svg';
import { ReactComponent as InmunizacionIcon } from '../../../../assets/icons/Inmunizacion.svg';
import { ReactComponent as GeneralIcon } from '../../../../assets/icons/general.svg';
import { ReactComponent as MedicinaPreventivaIcon } from '../../../../assets/icons/MedicinaPreventiva.svg';
import { ReactComponent as PronosticoIcon } from '../../../../assets/icons/Pronostico.svg';
// import { ReactComponent as ProximaCitaIcon } from '../../../../assets/icons/ProximaCita.svg';
// import { ReactComponent as InterconsultaIcon } from '../../../../assets/icons/Interconsulta.svg';
import Procedimientos from './Procedimientos/index';
import Laboratorio from './Laboratorio/index';
import TerapiaFarmacologica from './TerapiaFarmacologica';
import MedicinaPreventiva from './MedicinaPreventiva';
import Inmunizacion from '../../Antecedentes/PersonalesNoPatologicos/Inmunizaciones/index';
import Pronostico from './Pronostico/index';
import DictamenMedicoLaboral from './DictamenMedicoLaboral';

function Plan(props: IPlanProps) {
  const {
    setActiveStep,
    laboratorioSolicitud,
    setLaboratorioSolicitud,
    listaEnfermedades,
    solProcDiagnosticos,
    setSolProcDiagnosticos,
    solProcTerapeuticos,
    setSolProcTerapeuticos,
    solProcQuirurgicos,
    setSolProcQuirurgicos,
    procDiagnosticos,
    setProcDiagnosticos,
    procTerapeuticos,
    setProcTerapeuticos,
    sinProcQuirurgicos,
    setSinProcQuirurgicos,
    procQuirurgicos,
    setProcQuirurgicos,
    catDiagnostico,
    tratamientosActualesConsulta,
    setTratamientos,
    indiceReceta,
    setIndiceReceta,
    recetas,
    setRecetas,
    enfermedadesActivas,
    exploracionFisica,
    enGestacion,
    setEnGestacion,
    inmunizaciones,
    setInmunizaciones,
    pronosticos,
    setPronosticos,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { secciones, enOrganizacion, esPaciente } = useSelector((state: RootState) => state.Me);
  const [activeSubStep, setActiveSubStep] = useState<
  | 'laboratorio'
  | 'procedimientos_diagnosticos'
  | 'procedimientos_terapeuticos'
  | 'procedimientos_quirurgicos'
  | 'terapia_farmacologica'
  | 'inmunizacion'
  | 'dictamen_med_lab'
  | 'medicina_preventiva'
  | 'pronostico'
  >(esPaciente ? 'medicina_preventiva' : 'laboratorio');

  const botones: IBotonPlan[] = [
    { secciones: [50, 51], titulo: 'laboratorio', icono: <LaboratorioIcon /> },
    {
      secciones: [52, 53],
      titulo: 'procedimientos_diagnosticos',
      icono: <ProcedimientosDiagnosticosIcon />,
    },
    {
      secciones: [54, 55],
      titulo: 'procedimientos_terapeuticos',
      icono: <ProcedimientosTerapeuticosIcon />,
    },
    {
      secciones: [56],
      titulo: 'procedimientos_quirurgicos',
      icono: <ProcedimientosQuirurgicosIcon />,
    },
    { secciones: [48], titulo: 'terapia_farmacologica', icono: <TerapiaFarmacologicaIcon /> },
    { secciones: [57], titulo: 'inmunizacion', icono: <InmunizacionIcon /> },
    { secciones: [76], titulo: 'dictamen_med_lab', icono: <GeneralIcon /> },
    { secciones: [49], titulo: 'medicina_preventiva', icono: <MedicinaPreventivaIcon /> },
    { secciones: [58], titulo: 'pronostico', icono: <PronosticoIcon /> },
    // { secciones: [], titulo: 'proximas_citas', icono: <ProximaCitaIcon /> },
    // { secciones: [], titulo: 'interconsulta', icono: <InterconsultaIcon /> },
  ];

  return (
    <div className="w-full">
      {!esPaciente && (
        <>
          <h1 className="text-blue-800 font-normal m-0 mb-4">{t('d-p-plan')}</h1>
          <div className="bg-white grid grid-cols-3 xl:grid-cols-4 gap-2 border-solid border-0 border-b border-gray-600 pb-6">
            {botones.map(
              (boton: IBotonPlan) => (enOrganizacion || boton.titulo !== 'dictamen_med_lab')
                && secciones.length > 0
                && secciones.some((valor) => boton.secciones.includes(valor.idSeccion)) && (
                  <Button
                    className={activeSubStep === boton.titulo ? classes.selected : classes.button}
                    size="large"
                    variant="contained"
                    color="primary"
                    startIcon={boton.icono}
                    fullWidth
                    onClick={() => setActiveSubStep(boton.titulo)}
                  >
                    {t(boton.titulo)}
                  </Button>
              ),
            )}
          </div>
        </>
      )}
      {activeSubStep === 'laboratorio'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion === 50 || valor.idSeccion === 51) && (
          <Laboratorio
            laboratorioSolicitud={laboratorioSolicitud}
            setLaboratorioSolicitud={setLaboratorioSolicitud}
          />
      )}
      {activeSubStep === 'procedimientos_diagnosticos'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion === 52 || valor.idSeccion === 53) && (
          <Procedimientos
            tipo="diagnostico"
            solProcedimientos={solProcDiagnosticos}
            setSolProcedimientos={setSolProcDiagnosticos}
            sinProcedimientos={sinProcQuirurgicos}
            setSinProcedimientos={setSinProcQuirurgicos}
            procedimientos={procDiagnosticos}
            setProcedimientos={setProcDiagnosticos}
            catDiagnostico={catDiagnostico}
            lenfermedades={listaEnfermedades}
            exploracionFisica={exploracionFisica}
            enGestacion={enGestacion}
            setEnGestacion={setEnGestacion}
          />
      )}
      {activeSubStep === 'procedimientos_terapeuticos'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion === 54 || valor.idSeccion === 55) && (
          <Procedimientos
            tipo="terapeutico"
            solProcedimientos={solProcTerapeuticos}
            setSolProcedimientos={setSolProcTerapeuticos}
            sinProcedimientos={sinProcQuirurgicos}
            setSinProcedimientos={setSinProcQuirurgicos}
            procedimientos={procTerapeuticos}
            setProcedimientos={setProcTerapeuticos}
            catDiagnostico={catDiagnostico}
            lenfermedades={listaEnfermedades}
            exploracionFisica={exploracionFisica}
            enGestacion={enGestacion}
            setEnGestacion={setEnGestacion}
          />
      )}
      {activeSubStep === 'procedimientos_quirurgicos'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion === 56) && (
          <Procedimientos
            tipo="quirurgico"
            solProcedimientos={solProcQuirurgicos}
            setSolProcedimientos={setSolProcQuirurgicos}
            sinProcedimientos={sinProcQuirurgicos}
            setSinProcedimientos={setSinProcQuirurgicos}
            procedimientos={procQuirurgicos}
            setProcedimientos={setProcQuirurgicos}
            catDiagnostico={catDiagnostico}
            lenfermedades={listaEnfermedades}
            exploracionFisica={exploracionFisica}
            enGestacion={enGestacion}
            setEnGestacion={setEnGestacion}
          />
      )}
      {activeSubStep === 'terapia_farmacologica'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion === 48) && (
          <TerapiaFarmacologica
            enfermedadesActivas={enfermedadesActivas}
            tratamientosActualesConsulta={tratamientosActualesConsulta}
            setTratamientos={setTratamientos}
            indiceReceta={indiceReceta}
            setIndiceReceta={setIndiceReceta}
            recetas={recetas}
            setRecetas={setRecetas}
          />
      )}
      {activeSubStep === 'inmunizacion'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion === 57) && (
          <Inmunizacion
            isPlan
            enGestacion={enGestacion}
            setEnGestacion={setEnGestacion}
            inmunizaciones={inmunizaciones}
            setInmunizaciones={setInmunizaciones}
          />
      )}
      {activeSubStep === 'dictamen_med_lab' && enOrganizacion && (
        <DictamenMedicoLaboral
          enfermedadesActivas={listaEnfermedades.filter(
            (enf) => enf.estatusDelDiagnostico === 'activo',
          )}
        />
      )}
      {activeSubStep === 'medicina_preventiva'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion === 49) && (
          <MedicinaPreventiva setActiveStep={setActiveStep} exploracionFisica={exploracionFisica} />
      )}
      {activeSubStep === 'pronostico'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion === 58) && (
          <Pronostico
            enfermedades={listaEnfermedades}
            arrayData={pronosticos}
            setArrayData={setPronosticos}
          />
      )}
    </div>
  );
}

export default Plan;
