import {
  AlergiasURLAlergia,
  antecedentesFamiliarEnfermedad,
  saveProcedimientos,
  saveAgendaDelete,
  antecedentesFamiliarEnfermedadNegada,
  FarmacologicoGuardarMedicamento,
  FarmacologicoGuardarReceta,
  HospitalizacionesURLHospitalizacion,
  TransfusionesURLTransfusion,
  TransfusionesURLUsuarioPacienteTransfusion,
  saveAgendaConfigurar,
  builderURLFCEliminar,
  enfermedadesEnfermedadReferidaURL,
  enfermedadesEnfermedadNegadaURL,
  solicitudProcedimiento,
  pronosticoURL,
  tarjetaConektaURL,
  guardarSAMiOrganizacionAdministradorURL,
  guardarMiOrganizacionAdministradorURL,
  guardarMiOrganizacionRolesURL,
  guardarDictamenMedLab,
  guardarTipoExamenURL,
  guardarOcupacionURL,
  guardarRestriccionURL,
  guardarCausaNoAptitudURL,
} from './commonUrl';
import { getToken } from './commonStore';

function fetchDeleteInfo(url: string, obj: any) {
  return fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function deleteAntecedentesFamiliarEnfermedad(obj: any) {
  return fetchDeleteInfo(antecedentesFamiliarEnfermedad(), obj);
}

export function deleteAntecedentesFamiliarEnfermedadNegada(obj: any) {
  return fetchDeleteInfo(antecedentesFamiliarEnfermedadNegada(), obj);
}

export function deleteTerapiaFarmacologicaReceta(obj: any) {
  return fetchDeleteInfo(FarmacologicoGuardarReceta(), obj);
}

export function deleteTerapiaFarmacologicaMedicamento(obj: any) {
  return fetchDeleteInfo(FarmacologicoGuardarMedicamento(), obj);
}

export function procedimientoEliminarProcedimiento(obj: any) {
  return fetchDeleteInfo(saveProcedimientos(), obj);
}

export function alergiasEliminarAlergia(obj: any) {
  return fetchDeleteInfo(AlergiasURLAlergia(), obj);
}

export function transfusionesEliminarTransfusion(obj: any) {
  return fetchDeleteInfo(TransfusionesURLTransfusion(), obj);
}

export function transfusionesEliminarUsuarioPacienteTransfusion(obj: any) {
  return fetchDeleteInfo(TransfusionesURLUsuarioPacienteTransfusion(), obj);
}

export function hospitalizacionesEliminarHospitalizacion(obj: any) {
  return fetchDeleteInfo(HospitalizacionesURLHospitalizacion(), obj);
}

export function agendaEliminarCita(obj: any) {
  return fetchDeleteInfo(saveAgendaDelete(), obj);
}

export function deleteSAMiOrganizacionAdministrador(obj: any) {
  return fetchDeleteInfo(guardarSAMiOrganizacionAdministradorURL(), obj);
}

export function deleteMiOrganizacionAdministrador(obj: any) {
  return fetchDeleteInfo(guardarMiOrganizacionAdministradorURL(), obj);
}

export function deleteMiOrganizacionRoles(obj: any) {
  return fetchDeleteInfo(guardarMiOrganizacionRolesURL(), obj);
}

export function agendaEliminarConfiguracion(obj: any) {
  return fetchDeleteInfo(saveAgendaConfigurar(), obj);
}

export function deleteFormatoConsultaEliminar(obj: any) {
  return fetchDeleteInfo(builderURLFCEliminar(), obj);
}

export function enfermedadesEliminarReferida(obj: any) {
  return fetchDeleteInfo(enfermedadesEnfermedadReferidaURL(), obj);
}

export function enfermedadesEliminarNegada(obj: any) {
  return fetchDeleteInfo(enfermedadesEnfermedadNegadaURL(), obj);
}

export function solicitudProcedimientoEliminarSolicitud(obj: any) {
  return fetchDeleteInfo(solicitudProcedimiento(), obj);
}

export function sendPronosticoEliminar(obj: any) {
  return fetchDeleteInfo(pronosticoURL(), obj);
}

export function deleteDictamenesDictamenMedLab(obj: any) {
  return fetchDeleteInfo(guardarDictamenMedLab(), obj);
}

export function deleteDictamenesTipoExamen(obj: any) {
  return fetchDeleteInfo(guardarTipoExamenURL(), obj);
}

export function deleteDictamenesOcupacion(obj: any) {
  return fetchDeleteInfo(guardarOcupacionURL(), obj);
}

export function deleteDictamenesRestriccion(obj: any) {
  return fetchDeleteInfo(guardarRestriccionURL(), obj);
}

export function deleteDictamenesCausaNoAptitud(obj: any) {
  return fetchDeleteInfo(guardarCausaNoAptitudURL(), obj);
}

export function sendTarjetaConektaEliminar(obj: any) {
  return fetchDeleteInfo(tarjetaConektaURL(), obj);
}
