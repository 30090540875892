export const CHANGE_ROL = 'CHANGE_ROL';
export const CHANGE_TOKEN = 'CHANGE_TOKEN';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_ID_MEDICO = 'CHANGE_ID_MEDICO';
export const CHANGE_ID_USUARIO = 'CHANGE_ID_USUARIO';
export const CHANGE_NOMBRE = 'CHANGE_NOMBRE';
export const CHANGE_PRIMER_APELLIDO = 'CHANGE_PRIMER_APELLIDO';
export const CHANGE_SEGUNDO_APELLIDO = 'CHANGE_SEGUNDO_APELLIDO';
export const CHANGE_CARRERA = 'CHANGE_CARRERA';
export const CHANGE_ESPECIALIDAD = 'CHANGE_ESPECIALIDAD';
export const CHANGE_SEXO = 'CHANGE_SEXO';
export const GET_ME_MEDICO = 'GET_ME_MEDICO';
export const CHANGE_ACTIVO = 'CHANGE_ACTIVO';
export const CHANGE_TIEMPO = 'CHANGE_TIEMPO';
export const CHANGE_ARRAY_SECCIONES = 'CHANGE_ARRAY_SECCIONES';
export const SET_ME = 'SET_ME';
export const RESET_ME = 'RESET_ME';

export interface IRol {
  id: number;
  rol: string;
  tipo: string;
  grupo: string;
}

interface Seccion {
  idSeccion: number;
  rol: string;
}

export interface IEducacion {
  titulo: '';
  cedula: '';
  escuela: '';
}

export interface MeState {
  email: string;
  idMedico: number;
  idUsuario: number;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  token: string;
  carrera: IEducacion;
  especialidades: IEducacion[];
  rol: IRol;
  sexo: string;
  activo: boolean;
  tiempoRestante: string;
  customer: string;
  secciones: Seccion[];
  pais: string;
  esPaciente: boolean;
  validar: boolean;
  enOrganizacion: boolean;
  orgsAdministradas: number;
}

interface ChangeRolAction {
  type: typeof CHANGE_ROL;
  payload: IRol;
}

interface ChangeTokenAction {
  type: typeof CHANGE_TOKEN;
  payload: string;
}

interface ChangeEmailAction {
  type: typeof CHANGE_EMAIL;
  payload: string;
}

interface ChangeIDMedicoAction {
  type: typeof CHANGE_ID_MEDICO;
  payload: number;
}

interface ChangeIDUsuarioAction {
  type: typeof CHANGE_ID_USUARIO;
  payload: number;
}

interface ChangeNombreAction {
  type: typeof CHANGE_NOMBRE;
  payload: string;
}

interface ChangePrimerApellidoAction {
  type: typeof CHANGE_PRIMER_APELLIDO;
  payload: string;
}

interface ChangeSegundoApellidoAction {
  type: typeof CHANGE_SEGUNDO_APELLIDO;
  payload: string;
}

interface ChangeCarreraAction {
  type: typeof CHANGE_CARRERA;
  payload: IEducacion;
}

interface ChangeEspecialidadAction {
  type: typeof CHANGE_ESPECIALIDAD;
  payload: IEducacion[];
}

interface ChangeSexoAction {
  type: typeof CHANGE_SEXO;
  payload: string;
}

interface ChangeActivoAction {
  type: typeof CHANGE_ACTIVO;
  payload: boolean;
}

interface ChangeTiempoAction {
  type: typeof CHANGE_TIEMPO;
  payload: string;
}

interface SetMeAction {
  type: typeof SET_ME;
  payload: MeState;
}

interface ResetMeAction {
  type: typeof RESET_ME;
}

interface ChangeArraySecciones {
  type: typeof CHANGE_ARRAY_SECCIONES;
  payload: Seccion[];
}

export type MeActionTypes =
  | ChangeRolAction
  | ChangeTokenAction
  | ChangeEmailAction
  | ChangeIDMedicoAction
  | ChangeIDUsuarioAction
  | ChangeNombreAction
  | ChangePrimerApellidoAction
  | ChangeSegundoApellidoAction
  | ChangeCarreraAction
  | ChangeEspecialidadAction
  | ChangeSexoAction
  | SetMeAction
  | ChangeActivoAction
  | ChangeTiempoAction
  | ChangeArraySecciones
  | ResetMeAction;
