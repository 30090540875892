import { getToken } from './commonStore';
import { UploadURL } from './commonUrl';

function sendFile(obj: any, url: string) {
  return fetch(url, {
    method: 'POST',
    headers: { Authorization: `Bearer ${getToken()}` },
    body: obj,
  });
}

export function crearArchivo(obj: any) {
  return sendFile(obj, UploadURL());
}

export function subirArchivo(obj: any) {
  return sendFile(obj, UploadURL());
}
