import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import TableChartIcon from '@material-ui/icons/TableChart';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EceIcon from '@material-ui/icons/LocalHospital';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './styles';
import { RootState } from '../../store';
import { setLoading } from '../../actions/loading/actions';
import { setSnackComplete } from '../../actions/snackbar/types';
import { sendTokenUsuario, sendLoguot, validarTokenUsuario } from '../../utils/sendInfo';
import ValidarUsuarioPIN from '../../components/ModalDialogs/ValidarUsuarioPIN';
import { resetMe } from '../../actions/me/actions';
import { getToken, getUser, removeUserSession } from '../../utils/commonStore';
import { limpiarRedux } from '../../utils/reduxCommon';
import { setAuth } from '../../actions/auth/actions';

/** Prop recibidas del componente Navbar */
interface ISideBarProps {
  sidebarOpen: boolean;
  handleSidebarClose: () => void;
}

/** Regresa una sidebar (un componente drawer de Material UI) */
function SideBar(props: ISideBarProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { sidebarOpen, handleSidebarClose } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { idUsuario, validar } = useSelector((state: RootState) => state.Me);

  const [modalPinValidacion, setModalPinValidacion] = useState<boolean>(false);
  const [pinStr, setPinStr] = useState<string>('');

  const cerrarSesion = () => {
    dispatch(setLoading(true));
    const obj = { token: getToken(), usuario: getUser() };
    sendLoguot(obj)
      .then((result) => result.json())
      .then((data) => {
        if (data.code === 200) {
          removeUserSession();
          dispatch(resetMe());
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
              keyBack: '',
            }),
          );
          limpiarRedux();
          history.push('/login');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.toString() === 'SyntaxError: Unexpected token E in JSON at position 0') {
          removeUserSession();
          dispatch(resetMe());
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
              keyBack: '',
            }),
          );
          limpiarRedux();
          history.push('/login');
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        }
        dispatch(setLoading(false));
      });
  };

  const sendValidacionPIN = () => {
    dispatch(setLoading(true));
    validarTokenUsuario({
      idUsuario,
      pin: pinStr,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: `${t('usuario_validado')}`,
            }),
          );
          setModalPinValidacion(false);
        } else if (result.code === 201) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('pin_error')} ${result.intentos}/3`,
            }),
          );
        } else if (result.code === 202) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('intentos_exedido_validacion_usuario')}`,
            }),
          );
          setModalPinValidacion(false);
        } else if (result.code === 203) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('pin_vencido')}`,
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${result.msg}`,
            }),
          );
        }
        setPinStr('');
        dispatch(setLoading(false));
      })
      .catch((err) => {
        setPinStr('');
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
        dispatch(setLoading(false));
      });
  };
  const sendCode = () => {
    sendTokenUsuario({ idUsuario })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: `${t('pin_enviado')}`,
            }),
          );
        } else if (result.code === 204) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: `${t('no_se_puede_enviar_el_correo')}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('pin_no_enviado')}`,
          }),
        );
      });
  };

  useEffect(() => {
    if (validar) {
      setModalPinValidacion(true);
    }
  }, [validar]);

  return (
    <>
      <ValidarUsuarioPIN // Modal que se abre para solicitar el pin para confirmar el usuario de tipo paciente
        open={modalPinValidacion}
        pass={pinStr}
        setPass={setPinStr}
        callBackAceptar={sendValidacionPIN}
        callBackClose={() => cerrarSesion()}
        callBackSendCode={sendCode}
      />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: sidebarOpen,
          [classes.drawerClose]: !sidebarOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleSidebarClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <List>
          <ListItem
            button
            classes={{ button: classes.button }}
            className={
              pathname === '/tablero-paciente' ? classes.listItemHomeSelected : classes.listItemHome
            }
            onClick={() => history.push('/tablero-paciente')}
          >
            <ListItemIcon className={classes.iconWidth}>
              <TableChartIcon
                className={pathname === '/tablero-paciente' ? 'text-white' : 'text-blue-800'}
              />
            </ListItemIcon>
            <ListItemText primary={t('tablero_paciente')} />
          </ListItem>
          <ListItem
            button
            classes={{ button: classes.button }}
            className={pathname === '/ece' ? classes.listItemECESelected : classes.listItemECE}
            onClick={() => history.push('/ece')}
          >
            <ListItemIcon className={classes.iconWidth}>
              <EceIcon className={pathname === '/ece' ? 'text-white' : 'text-blue-800'} />
            </ListItemIcon>
            <ListItemText primary="ECE" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default SideBar;
