/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Chip,
  Button,
  FormHelperText,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import Switch from '@material-ui/core/Switch';
import { IConstructorLDProps } from './types';
import { capitalize } from '../../../../common/functions';
import useStyles from './styles';

function BuilderListaDesplegable({
  variable,
  setVariable,
  errores,
  setErrores,
  setDisableContinuar,
  paso,
}: IConstructorLDProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  /** States del functional component */
  const [valMultiple, setValMultiple] = useState<string[]>([]);
  const [valSelect, setValSelect] = useState<string>('');
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  /** Catalogos */
  /** Logica */
  /** Hooks */
  /** Efecto al cargar la pagina */
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers de la snackbar */
  /** Handlers del componente */
  const handleChangeDescripcion = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setVariable((preCons) => ({
      ...preCons,
      datosComponente: {
        ...preCons.datosComponente,
        descripcion: val,
      },
      constructor: {
        ...preCons.constructor,
        descripcion: val,
      },
    }));
  };

  const handleChangeValores = (e: React.ChangeEvent<{ value: unknown }>, index: number) => {
    const val = e.target.value as string;
    setVariable((preCons) => {
      const arrV = preCons.constructor.listaDesplegable!.arrValores.map((sVal, indx) => {
        if (index === indx) {
          return val;
        }
        return sVal;
      });
      return {
        ...preCons,
        constructor: {
          ...preCons.constructor,
          listaDesplegable: {
            ...preCons.constructor.listaDesplegable!,
            arrValores: arrV,
          },
        },
      };
    });
    setErrores((preE) => ({ ...preE, errorAlMenosDosOpciones: false }));
    setDisableContinuar(false);
  };

  const addOption = () => {
    setVariable((preCons) => {
      let arrV = preCons.constructor.listaDesplegable!.arrValores;
      if (arrV.length < 30) {
        arrV = preCons.constructor.listaDesplegable!.arrValores.concat('');
      }
      return {
        ...preCons,
        constructor: {
          ...preCons.constructor,
          listaDesplegable: {
            ...preCons.constructor.listaDesplegable!,
            arrValores: arrV,
          },
        },
      };
    });
  };

  const ordenar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const check = event.target.checked as boolean;
    setVariable((preCons) => ({
      ...preCons,
      constructor: {
        ...preCons.constructor,
        listaDesplegable: {
          ...preCons.constructor.listaDesplegable!,
          ordenar: check,
        },
      },
    }));
  };

  const onChangeOpciones = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValMultiple([]);
    setValSelect('');
    const check = event.target.checked as boolean;
    setVariable((preCons) => ({
      ...preCons,
      constructor: {
        ...preCons.constructor,
        listaDesplegable: {
          ...preCons.constructor.listaDesplegable!,
          seleccionMultiple: check,
        },
      },
    }));
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValMultiple(event.target.value as string[]);
  };
  const deleteChip = (id: number) => {
    const chips = [...valMultiple];
    if (id !== -1) {
      chips.splice(id, 1);
      setValMultiple(chips);
    }
  };

  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setValSelect(val);
  };
  /** handles de disabled components */
  /** Handlers para enviar la informacion */
  /** handler del evento submit */
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 grid grid-cols-1 gap-4">
      {paso < 2 && (
        <div className="grid grid-cols-4">
          <div className="col-span-3 inset-x-0 top-0">
            <div className="font-bold">{t('instrucciones')}</div>
            {paso === 0 ? (
              <div className="mt-2">{t('l-d-listadesplegable')}</div>
            ) : (
              <div className="mt-2">{t('l-d-multiseleccion')}</div>
            )}
          </div>
          {paso === 0 && (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo">{t('selecciona')}</InputLabel>
              <Select labelId="demo" id="demo" label={t('selecciona')} value="">
                <MenuItem>Opción 1</MenuItem>
                <MenuItem>Opción 2</MenuItem>
              </Select>
              <FormHelperText>
                <span className="text-gray-800">{`* ${t('l-d-demo')}`}</span>
              </FormHelperText>
            </FormControl>
          )}
          <div className="col-span-4 border-solid border-0 border-b border-gray-600" />
        </div>
      )}
      <div className={paso > 0 ? 'flex mb-3.5 items-end justify-center' : 'flex mb-3.5 items-end'}>
        <h3 className="w-max mr-4 mb-2 mt-0 text-gray-600 font-medium">
          {capitalize(variable.datosComponente.titulo)}
        </h3>
        <div className="w-1/3 flex-wrap">
          <h3 className="mt-0 mb-2 text-gray-600 font-medium">
            {t('incluye_una_descripcion_opcional')}
          </h3>
          <TextField
            fullWidth
            value={variable.datosComponente.descripcion}
            onChange={handleChangeDescripcion}
            inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            multiline
            rows={1}
          />
        </div>
      </div>
      {paso === 0 && (
        <>
          {variable.constructor.listaDesplegable!.arrValores.map((x, index) => (variable.constructor.listaDesplegable!.arrValores.length - 1 === index ? (
            <TextField
              label={`${t('l-d-escribir-o')} ${index + 1}`}
              variant="outlined"
              value={x}
              rows={1}
              onChange={(e) => handleChangeValores(e, index)}
              inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              fullWidth
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              error={errores.errorAlMenosDosOpciones}
              helperText={errores.errorAlMenosDosOpciones ? t('escribe_una_segunda_opcion') : ''}
            />
          ) : (
            <TextField
              label={`${t('l-d-escribir-o')} ${index + 1}`}
              variant="outlined"
              value={x}
              rows={1}
              onChange={(e) => handleChangeValores(e, index)}
              inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              fullWidth
            />
          )))}
        </>
      )}
      {paso >= 1 && (
        <>
          <div className="grid grid-cols-8 gap-4 mb-2">
            <div className="col-span-2" />
            <div className="col-span-4 inset-x-0 top-0">
              <h3 className="mt-2 mb-2 text-gray-600 font-medium">
                {capitalize(variable.datosComponente.titulo)}
              </h3>
              {!variable.constructor.listaDesplegable!.seleccionMultiple ? (
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="select-lista"
                    displayEmpty
                    value={valSelect}
                    onChange={handleChangeSelect}
                  >
                    <MenuItem value="" disabled>
                      <span className="text-gray-400">{t('selecciona')}</span>
                    </MenuItem>
                    {variable.constructor.listaDesplegable!.ordenar
                      ? [...variable.constructor.listaDesplegable!.arrValores]
                        .sort((a, b) => {
                          let valReturn = 0;
                          if (a.toLowerCase() > b.toLowerCase()) {
                            valReturn = 1;
                          } else if (a.toLowerCase() < b.toLowerCase()) {
                            valReturn = -1;
                          }
                          return valReturn;
                        })
                        .map((valor, index) => (
                          <MenuItem value={valor} key={String(index)}>
                            {valor}
                          </MenuItem>
                        ))
                      : variable.constructor.listaDesplegable!.arrValores.map((valor, index) => (
                        <MenuItem value={valor} key={String(index)}>
                          {valor}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl variant="outlined" fullWidth className={classes.height56}>
                  {!valMultiple.length && (
                    <InputLabel id="demo-mutiple-chip-label">{t('selecciona')}</InputLabel>
                  )}
                  <Select
                    displayEmpty
                    id="select-multiple-lista"
                    multiple
                    value={valMultiple}
                    onChange={handleChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {(selected as string[]).map((value, index) => (
                          <Chip
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            key={value}
                            label={value}
                            className={classes.chip}
                            onDelete={() => deleteChip(index)}
                            variant="outlined"
                            color="primary"
                          />
                        ))}
                      </div>
                    )}
                  >
                    {variable.constructor.listaDesplegable!.ordenar
                      ? [...variable.constructor.listaDesplegable!.arrValores]
                        .sort((a, b) => {
                          let valReturn = 0;
                          if (a.toLowerCase() > b.toLowerCase()) {
                            valReturn = 1;
                          } else if (a.toLowerCase() < b.toLowerCase()) {
                            valReturn = -1;
                          }
                          return valReturn;
                        })
                        .map((valor, index) => (
                          <MenuItem value={valor} key={String(index)}>
                            {valor}
                          </MenuItem>
                        ))
                      : variable.constructor.listaDesplegable!.arrValores.map((valor, index) => (
                        <MenuItem value={valor} key={String(index)}>
                          {valor}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="col-span-2" />
          </div>
          {paso !== 2 && (
            <>
              <div className="grid grid-cols-8 gap-4 mb-2">
                <div className="col-span-2" />
                <div className="col-span-2 flex items-center justify-start">
                  <SortByAlphaIcon color="primary" />
                  <InputLabel id="ordenar" className={classes.textColor}>
                    {t('l-d-ordenar-o')}
                  </InputLabel>
                </div>
                <div className="flex items-center justify-start">
                  <Switch
                    color="primary"
                    checked={variable.constructor.listaDesplegable!.ordenar}
                    onChange={ordenar}
                  />
                </div>
                <div className="col-span-3" />
              </div>
              <div className="grid grid-cols-8 gap-4 mb-2">
                <div className="col-span-2" />
                <div className="col-span-2 flex items-center justify-start">
                  <InputLabel id="seleccionar" className={classes.textColor}>
                    {t('l-d-permitir-o')}
                  </InputLabel>
                </div>
                <div className="flex items-center justify-start">
                  <Switch
                    color="primary"
                    checked={variable.constructor.listaDesplegable!.seleccionMultiple}
                    onChange={onChangeOpciones}
                  />
                </div>
                <div className="col-span-3" />
              </div>
            </>
          )}
        </>
      )}
      {paso === 0 && (
        <div className="flex items-center justify-end">
          <Button
            color="primary"
            onClick={addOption}
            className={classes.noTextTranform}
            startIcon={<AddCircleOutlineIcon />}
            disabled={!(variable.constructor.listaDesplegable!.arrValores.length < 30)}
          >
            {t('l-d-agregar-o')}
          </Button>
        </div>
      )}
      {paso === 2 && (
        <TextField
          label={t('comentarios')}
          variant="outlined"
          rows={6}
          multiline
          fullWidth
          disabled
        />
      )}
    </div>
  );
}

export default BuilderListaDesplegable;
