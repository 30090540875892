import React from 'react';
import { useTranslation } from 'react-i18next';
import ConstructorTabla from '../ConstructorTabla';
import { ISomaticosProps } from './types';

function Somaticos(props: ISomaticosProps) {
  const { ginecoObstetrico, setGinecoObstetrico } = props;
  const { t } = useTranslation();

  const setRadiosValues = (newRadioVals: string[]) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      sintomasClimaterio: {
        ...ginecoObstetrico.sintomasClimaterio,
        somaticos: {
          ...ginecoObstetrico.sintomasClimaterio.somaticos,
          radiosValues: newRadioVals,
        },
      },
    });
  };

  const setComentariosValues = (newComVals: string[]) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      sintomasClimaterio: {
        ...ginecoObstetrico.sintomasClimaterio,
        somaticos: {
          ...ginecoObstetrico.sintomasClimaterio.somaticos,
          comentariosValues: newComVals,
        },
      },
    });
  };

  return (
    <div>
      <h2 className="mt-4 mb-4 font-normal text-blue-300">{t('somaticos')}</h2>
      <ConstructorTabla
        items={ginecoObstetrico.sintomasClimaterio.somaticos.items}
        radiosValues={ginecoObstetrico.sintomasClimaterio.somaticos.radiosValues}
        setRadiosValues={setRadiosValues}
        comentariosValues={ginecoObstetrico.sintomasClimaterio.somaticos.comentariosValues}
        setComentariosValues={setComentariosValues}
      />
    </div>
  );
}

export default Somaticos;
