import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import StarIcon from '@material-ui/icons/Star';
import Variable from '../Variable';
import { setLoading } from '../../../../actions/loading/actions';
import { getVariableBuilderMedipraxi } from '../../../../utils/getCatalogos';
import { RootState } from '../../../../store';
import { IVariablesCreadasProps } from './types';
import { setSnackComplete } from '../../../../actions/snackbar/types';
import { ICompUnico } from '../TodasLasVariables/types';
import ModalComponet from '../../../../components/ModalComponent';

function VariablesCreadas({ setArrComponentes }: IVariablesCreadasProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [singleComp, setSingleComp] = useState<ICompUnico>({
    titulo: '',
    id: 0,
    formato: 0,
    tipo: false,
    constructorComponente: {
      componente: '',
      datosComponente: {
        titulo: '',
        descripcion: '',
      },
      constructor: {
        titulo: '',
        descripcion: '',
        comentarios: '',
        valor: '',
      },
      valores: {
        textoLibre: '',
        valor: '',
      },
    },
  });

  const verVariable = (idBD: number) => {
    dispatch(setLoading(false));
    getVariableBuilderMedipraxi(idMedico, idBD)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setSingleComp(result.data);
        } else {
          setLoading(false);
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: t('message-get-error'),
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
  };

  const agregarVariable = (idBD: number) => {
    dispatch(setLoading(true));
    getVariableBuilderMedipraxi(idMedico, idBD)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setArrComponentes((preArr) => {
            const existe = preArr.some((el) => el.id === result.data.id);
            if (!existe) {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'success',
                  mensaje: t('message-add-success'),
                }),
              );
              return preArr.concat(result.data);
            }
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'info',
                mensaje: t('message-add-same-variable-formato'),
              }),
            );
            return preArr;
          });
        }
        if (result.code === 300) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-get-error')} ${t(result.msg)}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (singleComp.titulo) {
      setModalOpen(true);
    }
  }, [singleComp]);
  return (
    <div>
      <ModalComponet
        open={modalOpen}
        constructorComponente={singleComp.constructorComponente}
        id={singleComp.id}
        titulo={singleComp.titulo}
        formato={singleComp.formato}
        callBackClose={handleAlertClose}
      />
      <div className="flex justify-center items-center">
        <span className="text-yellow-500">
          <StarIcon />
        </span>
        <h3 className="font-medium text-blue-600">{t('variables_mas_usadas')}</h3>
      </div>
      <div className="bg-yellow-200 p-4 rounded-md">
        <Variable
          idBD={1}
          texto="Inicio del síntoma"
          tipo
          verVariable={verVariable}
          agregarVariable={agregarVariable}
        />
        <Variable
          idBD={2}
          texto="Modo de inicio del dolor"
          tipo
          verVariable={verVariable}
          agregarVariable={agregarVariable}
        />
        <Variable
          idBD={3}
          texto="Causa aparente de la cefalea"
          tipo
          verVariable={verVariable}
          agregarVariable={agregarVariable}
        />
        <Variable
          idBD={4}
          texto="Localización de la cefalea"
          tipo
          verVariable={verVariable}
          agregarVariable={agregarVariable}
        />
        <Variable
          idBD={5}
          texto="Irradiación de la cefalea"
          tipo
          verVariable={verVariable}
          agregarVariable={agregarVariable}
        />
        <Variable
          idBD={6}
          texto="Carácter del dolor"
          tipo
          verVariable={verVariable}
          agregarVariable={agregarVariable}
        />
        <Variable
          idBD={7}
          texto="Intensidad del dolor"
          tipo
          verVariable={verVariable}
          agregarVariable={agregarVariable}
        />
        <Variable
          idBD={8}
          texto="Duración (horas)"
          tipo
          verVariable={verVariable}
          agregarVariable={agregarVariable}
        />
        <Variable
          idBD={9}
          texto="Predominio horario del dolor"
          tipo
          verVariable={verVariable}
          agregarVariable={agregarVariable}
        />
        <Variable
          idBD={10}
          texto="Frecuencia del dolor"
          tipo
          verVariable={verVariable}
          agregarVariable={agregarVariable}
        />
      </div>
    </div>
  );
}

export default VariablesCreadas;
