import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Collapse } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListaDiagnosticos from '../../../../../DiagnosticoPlan/Diagnostico/ListaDiagnosticos';
import ListaEnfermedadesNegadas from '../NegarEnfermedades/ListaEnfermedadesNegadas';
import ListaGrupoEnfermedadesNegadas from '../NegarEnfermedades/ListaGrupoEnfermedadesNegadas';
import { IListaEnfermedadesProps } from './types';
import DeleteDialog from '../../../../../../../components/DeleteDialog';
import { RootState } from '../../../../../../../store';
import { enfermedadesEliminarNegada } from '../../../../../../../utils/deleteInfo';
import { setRequest } from '../../../../../../../actions/request/types';

function ListaEnfermedades({
  idDiagnostico,
  setIdDiagnostico,
  eActivas,
  eResueltas,
  enfReferidas,
  setEnfReferidas,
  enfNegadas,
  setEnfNegadas,
  grupoEnfNegadas,
  setGrupoEnfNegadas,
  refNegEnfermedad,
}: IListaEnfermedadesProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const [referidasActivasExpand, setReferidasActivasExpand] = useState(true);
  const [referidasResueltasExpand, setReferidasResueltasExpand] = useState(true);
  const [negadasExpand, setNegadasExpand] = useState(true);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [idToDelete, setIDToDelete] = useState<number>(0);

  const handleDeleteDiagnostico = (idDB: number) => {
    setIDToDelete(idDB);
    setDeleteAlert(true);
  };

  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  const deleteDiagnostico = () => {
    dispatch(
      setRequest({
        type: 'delete',
        requestFunction: enfermedadesEliminarNegada({
          idMedico,
          idPaciente,
          idConsultorio,
          idBD: idToDelete,
          consulta: idConsulta,
        }),
        successFunction: () => {
          setEnfNegadas(enfNegadas.filter((enf) => enf.idBD !== idToDelete));
          // enfListaEnfReferidas();
        },
      }),
    );
  };

  return (
    <div>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pnp-historias-eliminar')}
        callBackAceptar={deleteDiagnostico}
        callBackClose={handleDeleteAlertClose}
      />
      {refNegEnfermedad === 'referir_enfermedad'
        && (eActivas.length > 0 || eResueltas.length > 0) && (
          <div className="mt-4 bg-white rounded p-4 shadow">
            {eActivas.length > 0 && (
              <h3 className="text-blue-500 font-medium m-0">
                {t('lista_de_enfermedades_referidas')}
              </h3>
            )}
            <div className="mt-4">
              {eActivas.length > 0 && (
                <>
                  <h3 className="text-blue-500 font-light m-0 mb-4">
                    {`${t('diagnosticos_activos')}: ${eActivas.length}`}
                  </h3>
                  <Collapse in={referidasActivasExpand}>
                    {eActivas.map((diagnostico) => (
                      <ListaDiagnosticos
                        diagnostico={diagnostico}
                        idDiagnostico={idDiagnostico}
                        setIdDiagnostico={setIdDiagnostico}
                        enfReferidas={enfReferidas}
                        setEnfReferidas={setEnfReferidas}
                      />
                    ))}
                  </Collapse>
                  <div className="text-center">
                    <Button
                      onClick={() => setReferidasActivasExpand((prevVal: boolean) => !prevVal)}
                      color="primary"
                    >
                      {referidasActivasExpand ? (
                        <span>{t('ver_menos')}</span>
                      ) : (
                        <span>{t('ver_mas')}</span>
                      )}
                      {referidasActivasExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Button>
                  </div>
                </>
              )}
              {eResueltas.length > 0 && (
                <>
                  <h3 className="text-blue-500 font-light m-0 mb-4">
                    {`${t('diagnosticos_resueltos')}: ${eResueltas.length}`}
                  </h3>
                  <Collapse in={referidasResueltasExpand}>
                    {eResueltas.map((diagnostico) => (
                      <ListaDiagnosticos
                        diagnostico={diagnostico}
                        idDiagnostico={idDiagnostico}
                        setIdDiagnostico={setIdDiagnostico}
                        enfReferidas={enfReferidas}
                        setEnfReferidas={setEnfReferidas}
                      />
                    ))}
                  </Collapse>
                  <div className="text-center">
                    <Button
                      onClick={() => setReferidasResueltasExpand((prevVal: boolean) => !prevVal)}
                      color="primary"
                    >
                      {referidasResueltasExpand ? (
                        <span>{t('ver_menos')}</span>
                      ) : (
                        <span>{t('ver_mas')}</span>
                      )}
                      {referidasResueltasExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
      )}
      {refNegEnfermedad === 'negar_enfermedad' && (
        <div className="mt-4 bg-white rounded p-4 shadow">
          <h3 className="text-blue-500 font-medium m-0">{t('lista_de_enfermedades_negadas')}</h3>
          <div className="mt-4">
            <Collapse in={negadasExpand}>
              <>
                {enfNegadas.map((enfermedad) => (
                  <ListaEnfermedadesNegadas
                    enfermedad={enfermedad}
                    key={enfermedad.diagnosticoCie10?.label}
                    handleBorrarEnfermedadNegada={handleDeleteDiagnostico}
                  />
                ))}
                <ListaGrupoEnfermedadesNegadas
                  grupoEnfermedades={grupoEnfNegadas}
                  setGrupoEnfermedades={setGrupoEnfNegadas}
                />
              </>
            </Collapse>
            <div className="text-center">
              <Button
                onClick={() => setNegadasExpand((prevVal: boolean) => !prevVal)}
                color="primary"
              >
                {negadasExpand ? <span>{t('ver_menos')}</span> : <span>{t('ver_mas')}</span>}
                {negadasExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListaEnfermedades;
