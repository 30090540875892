export const SET_LOADING = 'SET_LOADING';

export interface ILoadingState {
  loading: boolean;
}

interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

export type LoadingActionTypes = SetLoadingAction;
