import { Dispatch, SetStateAction } from 'react';

export interface IHigiene {
  loaded: boolean;
  banio: string;
  banioComentario: string;
  bucal: string;
  ropa: string;
  ropaComentario: string;
  comentarios: string;
}

export const higieneInitial: IHigiene = {
  loaded: false,
  banio: '',
  banioComentario: '',
  bucal: '',
  ropa: '',
  ropaComentario: '',
  comentarios: '',
};

export interface IHigieneProps {
  setActiveSubStep: Dispatch<
  SetStateAction<
  | 'vivienda'
  | 'higiene'
  | 'alimentacion'
  | 'actividad-fisica'
  | 'inmunizaciones'
  | 'toxicomania'
  | 'gineco-obstetricos'
  | 'historia-sexual'
  >
  >;
  higiene: IHigiene;
  setHigiene: Dispatch<SetStateAction<IHigiene>>;
  cambiosUsuario: string[];
  higieneUsuario: IHigiene;
  setHigieneUsuario: Dispatch<SetStateAction<IHigiene>>;
}
