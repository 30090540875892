import { ICatalogosEstandar } from '../../common/types';
import { IDatosOrganizacion } from '../../containers/AdmonInformacion/types';

export const SET_ORGANIZACIONES = 'SET_ORGANIZACIONES';
export const SET_ORGANIZACION = 'SET_ORGANIZACION';
export const SET_ORGANIZACION_PERMITIR_TOKENS_PACIENTES = 'SET_ORGANIZACION_PERMITIR_TOKENS_PACIENTES';
export const RESET_ORGANIZACIONES = 'RESET_ORGANIZACIONES';
export const RESET_ORGANIZACION = 'RESET_ORGANIZACION';
export const GET_ORGANIZACIONES = 'GET_ORGANIZACIONES';
export const GET_ORGANIZACION = 'GET_ORGANIZACION';

export interface IOrganizaciones {
  organizaciones: ICatalogosEstandar[];
  organizacion: IDatosOrganizacion;
}

interface SetOrganizacionAction {
  type: typeof SET_ORGANIZACION;
  payload: IDatosOrganizacion;
}

interface SetOrganizacionPermitirTokensPacientesAction {
  type: typeof SET_ORGANIZACION_PERMITIR_TOKENS_PACIENTES;
  payload: boolean;
}

interface SetOrganizacionesAction {
  type: typeof SET_ORGANIZACIONES;
  payload: ICatalogosEstandar[];
}

interface ResetOrganizacionesAction {
  type: typeof RESET_ORGANIZACIONES;
}

interface ResetOrganizacionAction {
  type: typeof RESET_ORGANIZACION;
}

export type OrganizacionesActionTypes =
  | SetOrganizacionAction
  | SetOrganizacionPermitirTokensPacientesAction
  | SetOrganizacionesAction
  | ResetOrganizacionAction
  | ResetOrganizacionesAction;
