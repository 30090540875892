import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IListaGrupoEnfermedadesNegadasProps } from './types';
import { RootState } from '../../../../../../../../store';
import { enfermedadesGuardarGrupoEnfermedadesNegadas } from '../../../../../../../../utils/sendInfo';
import { setRequest } from '../../../../../../../../actions/request/types';

function ListaGrupoEnfermedadesNegadas({
  grupoEnfermedades,
  setGrupoEnfermedades,
}: IListaGrupoEnfermedadesNegadasProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);

  const handleDeleteNegarEnfermedades = (nombre: string) => {
    const nuevaLista = {
      ...grupoEnfermedades,
      [`${nombre}Selected`]: false,
    };
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enfermedadesGuardarGrupoEnfermedadesNegadas({
          idMedico,
          idPaciente,
          idConsultorio,
          consulta: idConsulta,
          listaGrupoEnfermedadesNegadas: nuevaLista,
        }),
        successFunction: () => {
          setGrupoEnfermedades(nuevaLista);
        },
      }),
    );
  };

  return (
    <>
      {grupoEnfermedades.infecciosasYParasitariasSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('infecciosas_y_parasitarias_(A00-B99)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('infecciosasYParasitarias')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.neoplasiasSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('neoplasias_(C00-D48)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('neoplasias')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.hematologicasOQueAfectanElMecanismoDeLaInmunidadSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">
            {t('hematologicas_o_que_afectan_el_mecanismo_de_la_inmunidad_(D50-D89)')}
          </h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('hematologicasOQueAfectanElMecanismoDeLaInmunidad')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.endocrinasNutricionalesYMetabolicasSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">
            {t('endocrinas_nutricionales_y_metabolicas_(E00-E90)')}
          </h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('endocrinasNutricionalesYMetabolicas')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.trastornosMentalesYDelComportamientoSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">
            {t('trastornos_mentales_y_del_comportamiento_(F00-F99)')}
          </h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('trastornosMentalesYDelComportamiento')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.sistemaNerviosoSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('sistema_nervioso_(G00-G99)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('sistemaNervioso')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.delOjoYSusAnexosSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('del_ojo_y_sus_anexos_(H00-H59)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('delOjoYSusAnexos')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.delOidoYApofisisMastoideSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('del_oido_y_apofisis_mastoide_(H60-H95)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('delOidoYApofisisMastoide')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.sistemaCirculatorioSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('sistema_circulatorio_(I00-I99)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('sistemaCirculatorio')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.sistemaRespiratorioSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('sistema_respiratorio_(J00-J99)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('sistemaRespiratorio')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.sistemaDigestivoSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('sistema_digestivo_(K00-K93)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('sistemaDigestivo')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.pielYDelTejidoSubcutaneoSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('piel_y_del_tejido_subcutaneo_(L00-L99)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('pielYDelTejidoSubcutaneo')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.sistemaOsteomuscularYDelTejidoConjuntivoSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">
            {t('sistema_osteomuscular_y_del_tejido_conjuntivo_(M00-M99)')}
          </h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('sistemaOsteomuscularYDelTejidoConjuntivo')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.sistemaGenitourinarioSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('sistema_genitourinario_(N00-N99)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('sistemaGenitourinario')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.embarazoPartoPuerperioSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('embarazo_parto_puerperio_(O00-O99)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('embarazoPartoPuerperio')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.delPeriodoPerinatalSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">{t('del_periodo_perinatal_(P00-P96)')}</h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('delPeriodoPerinatal')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.malformaconesCongenitasYAnomaliasCongenitasSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">
            {t('malformacones_congenitas_y_anomalias_congenitas_(Q00-Q99)')}
          </h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades('malformaconesCongenitasYAnomaliasCongenitas')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
      {grupoEnfermedades.traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasSelected && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 text-gray-500 flex justify-between items-center">
          <h3 className="font-normal m-0 pl-4">
            {t('traumatismo_y_envenenamiento_y_otras_consecuencias_de_causas_externas_(S00-T98)')}
          </h3>
          <div className="min-w-max self-center">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDeleteNegarEnfermedades(
                'traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternas',
              )}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
}

export default ListaGrupoEnfermedadesNegadas;
