import {
  ISnackMensaje,
  SET_SNACK_BOOLEAN,
  SET_SNACK_CLOSE,
  SET_SNACK_COMPLETE,
  SET_SNACK_MENSAJE,
  SET_SNACK_OPEN,
  SET_SNACK_SEVERITY,
  SnackActionTypes,
} from './actions';

export function SetSnackMensaje(mensaje: string): SnackActionTypes {
  return {
    type: SET_SNACK_MENSAJE,
    payload: mensaje,
  };
}

export function SetSnackSeverity(
  severity: 'info' | 'error' | 'success' | 'warning',
): SnackActionTypes {
  return {
    type: SET_SNACK_SEVERITY,
    payload: severity,
  };
}

export function SetSnackBooleano(booleano: boolean): SnackActionTypes {
  return {
    type: SET_SNACK_BOOLEAN,
    payload: booleano,
  };
}

export function SetSnackOpen(): SnackActionTypes {
  return {
    type: SET_SNACK_OPEN,
  };
}

export function SetSnackClose(): SnackActionTypes {
  return {
    type: SET_SNACK_CLOSE,
  };
}

export function setSnackComplete(snack: ISnackMensaje): SnackActionTypes {
  return {
    type: SET_SNACK_COMPLETE,
    payload: snack,
  };
}
