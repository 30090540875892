import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { IExamenMedicoPeriodicoProps } from './types';

dayjs.locale('es');

function ExamenMedicoPeriodico({ fechaEstudio }: IExamenMedicoPeriodicoProps) {
  const { t } = useTranslation();

  return (
    <div className="flex-1">
      <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
        <div className="border-0 border-solid border-red-600 rounded border-l-8 p-4 pr-5 w-full">
          <h3 className="m-0 text-left text-blue-500 font-normal">{t('emp')}</h3>
          <div className="flex pt-2">
            <h4 className="font-normal m-0 text-gray-500 pr-4">
              {`${t('emp_fecha_ultimo_examen')}: ${dayjs(`${fechaEstudio}`, 'YYYY M D').format(
                'DD-MMM-YYYY',
              )}`}
            </h4>
          </div>
          <h4 className="font-normal m-0 text-gray-900 pt-2">{`${t('emp_descripcion')}`}</h4>
        </div>
      </div>
    </div>
  );
}

export default ExamenMedicoPeriodico;
