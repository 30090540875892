import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, withStyles } from '@material-ui/core';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { IConstructorTabla } from './types';

const BlackRadio = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const BlackTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

function ConstructorTabla(props: IConstructorTabla) {
  const {
    setRadiosValues, radiosValues, setComentariosValues, comentariosValues, items,
  } = props;
  const { t } = useTranslation();
  const lastIndex = items.length - 1;

  const handleChangeRadioSeleccion = (event: React.ChangeEvent<HTMLInputElement>, indx: number) => {
    const arr = [...radiosValues];
    arr[indx] = event.target.value as string;
    setRadiosValues(arr);
  };

  const handleChangeComentarios = (event: React.ChangeEvent<{ value: unknown }>, indx: number) => {
    const val = event.target.value as string;
    const arr = [...comentariosValues];
    arr[indx] = val;
    setComentariosValues(arr);
  };

  return (
    <div>
      <div className="grid grid-cols-12 gap-4 bg-blue-600 text-white text-center py-2 rounded-t">
        <span className="col-span-1">{t('si')}</span>
        <span className="col-span-1">{t('no')}</span>
        <span className="col-span-2">{t('item')}</span>
        <span className="col-span-8">{t('comentarios')}</span>
      </div>
      {items.map((item, index) => (
        <div
          className={`grid grid-cols-12 gap-4${
            index % 2 === 0 ? ' bg-blue-300' : ''
          } text-center items-center py-4 border border-solid border-gray-300 border-t-0${
            index === lastIndex ? ' rounded-b' : ' border-b-0'
          }`}
          key={item}
        >
          <div className="col-span-1">
            <BlackRadio
              value="SI"
              name={`radio-button-${item}-${index}`}
              checked={radiosValues[index] === 'SI'}
              onChange={(e) => handleChangeRadioSeleccion(e, index)}
            />
          </div>
          <div className="col-span-1">
            <BlackRadio
              value="NO"
              name={`radio-button-${item}-${index}`}
              checked={radiosValues[index] === 'NO'}
              onChange={(e) => handleChangeRadioSeleccion(e, index)}
            />
          </div>
          <span className="col-span-2 text-left">{t(item)}</span>
          <div className="col-span-8 pr-4">
            <BlackTextField
              multiline
              rows="3"
              variant="outlined"
              fullWidth
              InputProps={{
                className: 'bg-white',
                inputProps: {
                  maxLength: 300,
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                },
              }}
              value={comentariosValues[index]}
              onChange={(e) => handleChangeComentarios(e, index)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ConstructorTabla;
