import React from 'react';
import { useTranslation } from 'react-i18next';
import IResumenCambiosProps from './types';

function ResumenCambios(props: IResumenCambiosProps) {
  const { cambios } = props;
  const { t } = useTranslation();

  return (
    <div className="pb-2">
      <div>
        <span className="font-bold">{`${t('resumen_cambios')}: `}</span>
        <span className="text-red-500">{t('aviso_cambios')}</span>
      </div>
      {cambios.map((c: string) => (
        <div className="text-gray-600">{c}</div>
      ))}
    </div>
  );
}

export default ResumenCambios;
