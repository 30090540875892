import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import WarningIcon from '@material-ui/icons/Warning';
import CallMade from '@material-ui/icons/CallMade';
import { HtmlTooltip } from '../../utils/ComponentesJSX';
import InfoMotivoConsulta from './InfoMotivoConsulta';
import PaperInstrucciones from './PaperInstrucciones';
import SeleccionVariablesMC from './SeleccionVariablesMC';
// import PatologiasRelacionadas from './PatologiasRelacionadas';
import NotasAdicionales from './NotasAdicionales';
import PadecimientoActualMC from './PadecimientoActualMC';
import { ICatalogosEstandar } from '../../common/types';
import { getCatalogoRangoEdadMC, getCatalogoCategoriasMC } from '../../utils/getCatalogos';
import { RootState } from '../../store';
import { ICompUnico } from './SeleccionVariablesMC/TodasLasVariables/types';
import Builder from '../Builder';
import { setSnackComplete } from '../../actions/snackbar/types';
import { builderFCGuardar, compartirFormatoConsulta } from '../../utils/sendInfo';
import VisualizacionMC from './VisualizacionMC';
import { setLoading } from '../../actions/loading/actions';
import { sendFormatoConsultaActualizar } from '../../utils/editInfo';
import AlertMultipleDialog from '../../components/AlertMultipleDialog';
import { removePaciente, removeUserSession } from '../../utils/commonStore';
import { resetMe } from '../../actions/me/actions';
import { limpiarRedux } from '../../utils/reduxCommon';
import { setAuth } from '../../actions/auth/actions';
import DeleteDialog from '../../components/DeleteDialog';

function PersonalizacionFC() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { idMedico, enOrganizacion } = useSelector((state: RootState) => state.Me);
  /** States del functional component */
  // const [publico, setPublico] = useState<boolean>(true); // a proposito
  const [idFC, setIdFC] = useState<number>(0);
  const [compartirFormato, setCompartirFormato] = useState<boolean>(false);
  const [disableCompartirFormato, setDisableCompartirFormato] = useState<boolean>(true);
  const [compartido, setCompartido] = useState<boolean>(false);
  const [rangoEdadMC, setRangoEdadMC] = useState<number>(0);
  const [categoriaMC, setCategoriaMC] = useState<number>(0);
  const [nombreDelMC, setNombreDelMC] = useState<string>('');
  const [sexoParaElQueAplica, setSexoParaElQueAplica] = useState<number>(0);
  const [tipoDeMC, setTipoDeMC] = useState<number>(0);
  const [paso, setPaso] = useState<number>(0);
  const [comentariosExtra, setComentariosExtra] = useState<string>('');
  const [incluirSeccionComentarios, setIncluirSeccionComentarios] = useState<boolean>(true);
  // const [incluirSeccionPatologiasRelacionadas] = useState<boolean>(false); // a proposito
  const [arrComponentes, setArrComponentes] = useState<ICompUnico[]>([]);
  const [builderShow, setBuilderShow] = useState<boolean>(false);
  /** state de los checkbox cuando se seleccionan */
  const [objChecked, setObjChecked] = useState<any>({});
  /** states para expandir el texto del child */
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  /** Catalogos */
  const [catCategoriaMC, setCatCategoriaMC] = useState<Array<ICatalogosEstandar>>([]);
  const [catRangoEdadMC, setCatRangoEdadMC] = useState<Array<ICatalogosEstandar>>([]);
  /** Logica */
  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const verificarErrorValidacionGeneral = () => {
    if (nombreDelMC && categoriaMC && rangoEdadMC && sexoParaElQueAplica && tipoDeMC) {
      return false;
    }
    return true;
  };

  const verificarErrorValidacionGeneralFormato = () => {
    if (
      nombreDelMC
      && categoriaMC
      && rangoEdadMC
      && sexoParaElQueAplica
      && tipoDeMC
      && arrComponentes.length
    ) {
      return false;
    }
    return true;
  };
  /** Hooks */
  useEffect(() => {
    getCatalogoCategoriasMC().then((result: ICatalogosEstandar[]) => {
      setCatCategoriaMC(result);
    });
    getCatalogoRangoEdadMC().then((result: ICatalogosEstandar[]) => {
      setCatRangoEdadMC(result);
    });
  }, []);
  /** Efecto al cargar la pagina */
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers del componente */
  /** handles de disabled components */
  /** handler del evento submit */
  const actualizarFormatoConsulta = () => {
    dispatch(setLoading(true));
    const datosUpdateFormato = {
      idMedico,
      idFormato: idFC,
      motivoNombre: nombreDelMC,
      motivoCategoria: categoriaMC,
      motivoRango: rangoEdadMC,
      motivoSexo: sexoParaElQueAplica,
      motivoConsulta: tipoDeMC,
      publico: true,
      constructor: arrComponentes,
      comentarios: incluirSeccionComentarios,
    };
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    sendFormatoConsultaActualizar(datosUpdateFormato)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          setDisableCompartirFormato(false);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
              keyBack: '',
            }),
          );
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: true,
              keyBack: '',
            }),
          );
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else if (result.code === 305) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t(result.msg),
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: t('message-get-error'),
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  const enviarFormatoConsulta = () => {
    const formatoConsulta = {
      idMedico,
      motivoNombre: nombreDelMC,
      motivoCategoria: categoriaMC,
      motivoRango: rangoEdadMC,
      motivoSexo: sexoParaElQueAplica,
      motivoConsulta: tipoDeMC,
      publico: true,
      constructor: '',
    };
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    builderFCGuardar(formatoConsulta)
      .then((response) => response.json())
      .then((result) => {
        // SI NO TIENE ID REGRESAR Y NO CONTINUAR
        // SI REGRESA ID CONTINUAR
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          setPaso((prevPaso) => prevPaso + 1);
          setIdFC(result.datos.idFormato);
          document.documentElement.scrollTop = 0;
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
              keyBack: '',
            }),
          );
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: true,
              keyBack: '',
            }),
          );
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else if (result.code === 305) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${t(result.msg)}`,
            }),
          );
        }
      })
      .catch(() => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: t('message-error-warning'),
          }),
        );
      });
  };

  const handleCompartirFormato = () => {
    dispatch(setLoading(true));
    const compartir = !compartido;
    const objEnviar = {
      idMedico,
      idFormato: idFC,
      compartir,
    };
    compartirFormatoConsulta(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(setLoading(false));
          setCompartido(compartir);
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: compartir
                ? t('formato_compartido_satisfactoriamente')
                : t('formato_descompartido_satisfactoriamente'),
            }),
          );
        } else if (result.code === 400) {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: t('organizacion_sin_mas_usuarios'),
            }),
          );
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
              keyBack: '',
            }),
          );
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: true,
              keyBack: '',
            }),
          );
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  /** Handlers para enviar la informacion */
  const crearNuevaVariable = () => {
    setBuilderShow(true);
  };

  if (builderShow) {
    return (
      <Builder
        index={1}
        value={1}
        regresar={setBuilderShow}
        nombreDelMC={nombreDelMC}
        setArrComponentes={setArrComponentes}
      />
    );
  }
  // falta enviar el catalogo de sexo y el catalogo de motivo de consulta
  return (
    <>
      <DeleteDialog
        open={compartirFormato}
        titulo={compartido ? t('descompartir_formato') : t('compartir_formato')}
        descripcion={
          compartido ? t('confirmar_descompartir_formato') : t('confirmar_compartir_formato')
        }
        callBackAceptar={handleCompartirFormato}
        callBackClose={() => setCompartirFormato(false)}
      />
      <div className="p-4">
        <AlertMultipleDialog
          open={alertOpen}
          titulo="¡Atención!"
          descripcion={[
            t('_seguro_que_los_datos_son_correctos_'),
            t(
              'solo_podras_editar_estos_datos_en_la_seccion_anterior_(formatos_de_motivo_de_consulta)',
            ),
          ]}
          icono={<WarningIcon className="text-yellow-500" color="inherit" fontSize="large" />}
          nombreCancelar={t('cancelar')}
          nombreAceptar={t('aceptar')}
          callBackAceptar={enviarFormatoConsulta}
          callBackClose={handleAlertClose}
        />
        <PaperInstrucciones />
        <div className="mb-6" />
        <InfoMotivoConsulta
          nombreDelMC={nombreDelMC}
          rangoEdad={rangoEdadMC}
          categoria={categoriaMC}
          catCategoriaMC={catCategoriaMC}
          catRangoEdadMC={catRangoEdadMC}
          setNombreDelMC={setNombreDelMC}
          setRangoEdad={setRangoEdadMC}
          setCategoria={setCategoriaMC}
          sexoParaElQueAplica={sexoParaElQueAplica}
          setSexoParaElQueAplica={setSexoParaElQueAplica}
          tipoDeMC={tipoDeMC}
          setTipoDeMC={setTipoDeMC}
          paso={paso}
        />
        {paso === 0 && (
          <>
            <div className="mb-4" />
            <div className="text-right">
              <Button
                variant="contained"
                color="primary"
                type="button"
                disabled={verificarErrorValidacionGeneral()}
                size="large"
                onClick={() => setAlertOpen(true)}
                startIcon={<SaveIcon />}
              >
                {t('guardar')}
              </Button>
            </div>
          </>
        )}
        {paso > 0 && (
          <>
            <div className="mb-4" />
            <SeleccionVariablesMC
              nombreDelMC={nombreDelMC}
              arrComponentes={arrComponentes}
              setArrComponentes={setArrComponentes}
              objChecked={objChecked}
              setObjChecked={setObjChecked}
              crearVariable={crearNuevaVariable}
            />
            <div className="mb-4" />
            <VisualizacionMC
              nombreDelMC={nombreDelMC}
              arrComponentes={arrComponentes}
              setArrComponentes={setArrComponentes}
            />
            <div className="mb-4" />
            <NotasAdicionales
              comentariosExtra={comentariosExtra}
              setComentariosExtra={setComentariosExtra}
              incluirSeccionComentarios={incluirSeccionComentarios}
              setIncluirSeccionComentarios={setIncluirSeccionComentarios}
            />
            {/* <PatologiasRelacionadas
              incluirSeccionPatologiasRelacionadas={incluirSeccionPatologiasRelacionadas}
              setIncluirSeccionPatologiasRelacionadas={setIncluirSeccionPAtologiasRelacionadas}
            /> */}
            <div className="mb-4" />
            <PadecimientoActualMC
              nombreDelMC={nombreDelMC}
              arrComponentes={arrComponentes}
              setArrComponentes={setArrComponentes}
            />
            <div className="mb-4" />
            <div className="flex justify-end items-end">
              <div>
                {enOrganizacion && (
                  <HtmlTooltip
                    title={compartido ? t('formato_compartido') : t('compartir_formato')}
                  >
                    <Button
                      color="default"
                      disabled={disableCompartirFormato || verificarErrorValidacionGeneralFormato()}
                      onClick={() => setCompartirFormato(true)}
                      startIcon={
                        <CallMade className={compartido ? 'text-blue-900' : 'text-gray-400 '} />
                      }
                    >
                      {t('compartir_formato')}
                    </Button>
                  </HtmlTooltip>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={verificarErrorValidacionGeneralFormato()}
                  size="large"
                  onClick={actualizarFormatoConsulta}
                  startIcon={<CreateIcon />}
                >
                  {t('crear_formato')}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default PersonalizacionFC;
