import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { RootState } from '../../../../../../store';
import EnfermedadesFormulario from './Formulario';
import NegarEnfermedades from './NegarEnfermedades';
import ListaEnfermedades from './ListaEnfermedades';
import { IEnfermedadesForm, IEnfermedadesProps, enfermedadesFormInitial } from './types';
import DiagnosticosPaciente from './DiagnosticosPaciente';
import { IDiagnosticoGeneral } from '../../DiagnosticosGenerales/types';

function Enfermedades({
  eActivas,
  eResueltas,
  // enfListaEnfReferidas,
  enfReferidas,
  setEnfReferidas,
  enfNegadas,
  setEnfNegadas,
  grupoEnfNegadas,
  setGrupoEnfNegadas,
  diagnosticosGenerales,
  setDiagnosticosGenerales,
}: IEnfermedadesProps) {
  const { t } = useTranslation();
  const { idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const [refNegEnfermedad, setRefNegEnfermedad] = useState<
  'referir_enfermedad' | 'negar_enfermedad' | 'diagnosticos_usuario'
  >(
    idUsuario > 0
      && diagnosticosGenerales.contenidoTabla?.some(
        (diagnostico: IDiagnosticoGeneral) => !diagnostico.validado,
      )
      ? 'diagnosticos_usuario'
      : 'referir_enfermedad',
  );
  const [idDiagnostico, setIdDiagnostico] = useState<number>(-1);
  const [diagnosticoSeleccionado, setDiagnosticoSeleccionado] = useState<IEnfermedadesForm>(enfermedadesFormInitial);

  const handleChangeRefNegEnfermedad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as
      | 'referir_enfermedad'
      | 'negar_enfermedad'
      | 'diagnosticos_usuario';
    setRefNegEnfermedad(val);
    // if (val === 'negar_enfermedad') {
    //   dispatch(resetDiagnosticoFormularioDiagnostico());
    // }
  };

  useEffect(() => {
    if (idDiagnostico !== -1) {
      const diagnosticoSel = enfReferidas.find((e) => e.idBD === idDiagnostico);
      if (diagnosticoSel) {
        setDiagnosticoSeleccionado({
          ...diagnosticoSel,
          comentario: diagnosticoSel.comentarios.length
            ? diagnosticoSel.comentarios[0].comentario
            : { entityMap: {}, blocks: [] },
        });
      }
    }
  }, [idDiagnostico]);

  return (
    <div className="shadow p-4">
      <RadioGroup
        row
        name="diagnostico"
        value={refNegEnfermedad}
        onChange={handleChangeRefNegEnfermedad}
      >
        <FormControlLabel
          value="referir_enfermedad"
          control={<Radio color="primary" />}
          label={t('referir_enfermedad')}
          labelPlacement="end"
        />
        <FormControlLabel
          value="negar_enfermedad"
          control={<Radio color="primary" />}
          label={t('negar_enfermedad')}
          labelPlacement="end"
        />
        {idUsuario > 0
          && diagnosticosGenerales.contenidoTabla?.some(
            (diagnostico: IDiagnosticoGeneral) => !diagnostico.validado,
          ) && (
            <FormControlLabel
              value="diagnosticos_usuario"
              control={<Radio color="primary" />}
              label={t('referidas_negadas_usuario_paciente')}
              labelPlacement="end"
            />
        )}
      </RadioGroup>
      {refNegEnfermedad === 'referir_enfermedad' && (
        <EnfermedadesFormulario
          enfermedad={diagnosticoSeleccionado}
          setEnfermedad={setDiagnosticoSeleccionado}
          enfReferidas={enfReferidas}
          setEnfReferidas={setEnfReferidas}
          enfNegadas={enfNegadas}
          setEnfNegadas={setEnfNegadas}
          grupoEnfNegadas={grupoEnfNegadas}
          setGrupoEnfNegadas={setGrupoEnfNegadas}
        />
      )}
      {refNegEnfermedad === 'negar_enfermedad' && (
        <NegarEnfermedades
          enfNegadas={enfNegadas}
          setEnfNegadas={setEnfNegadas}
          grupoEnfNegadas={grupoEnfNegadas}
          setGrupoEnfNegadas={setGrupoEnfNegadas}
        />
      )}
      {refNegEnfermedad === 'diagnosticos_usuario' && (
        <DiagnosticosPaciente
          diagnosticosGenerales={diagnosticosGenerales}
          setDiagnosticosGenerales={setDiagnosticosGenerales}
        />
      )}
      <ListaEnfermedades
        idDiagnostico={idDiagnostico}
        setIdDiagnostico={setIdDiagnostico}
        eActivas={eActivas}
        eResueltas={eResueltas}
        enfReferidas={enfReferidas}
        setEnfReferidas={setEnfReferidas}
        enfNegadas={enfNegadas}
        setEnfNegadas={setEnfNegadas}
        grupoEnfNegadas={grupoEnfNegadas}
        setGrupoEnfNegadas={setGrupoEnfNegadas}
        refNegEnfermedad={refNegEnfermedad}
        // enfListaEnfReferidas={enfListaEnfReferidas}
      />
    </div>
  );
}

export default Enfermedades;
