import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
} from '@material-ui/core';
import { ICatalogosEstandar } from '../../../../common/types';
import { getAntecedentesParentesco } from '../../../../utils/getCatalogos';
import FormAntecedentesFamiliaresNegar from './FormAFNegar';
import FormAntecedentesFamiliaresReferir from './FormAFReferir';
import {
  sendFamiliaresAgregarEnfermedadFamiliar,
  sendFamiliaresGuardarEnfermedadFamiliar,
} from '../../../../utils/sendInfo';
import { RootState } from '../../../../store';
import ListaAntecedentesFamiliares from './ListaAntecedentesFamiliares';
import { numeroEnfermedadGrupo } from '../../../../common/enfermedades';
import { hasKey } from '../../../../common/functions';
import { setSnackComplete } from '../../../../actions/snackbar/types';
import { editAntecedentesFamiliaresEnfermedades } from '../../../../utils/editInfo';
import DeleteDialog from '../../../../components/DeleteDialog';
import loading from '../../../../actions/loading/actions';
import {
  IFamComprimido,
  IFamiliar,
  IFamiliarEnfermedades,
  IFamiliaresEnfermedadesNegadas,
  IFamiliaresGrupoEnfermedadesNegadas,
  IFamiliaresProps,
  familiarEnfermedadesInitial,
  familiarEnfermedadesNegadasInitial,
  familiaresGrupoEnfermedadesNegadasInitial,
} from './types';
import { setRequest } from '../../../../actions/request/types';
import { IGrupoEnfermedadesNegadas } from '../PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';

function Familiares(props: IFamiliaresProps) {
  const { familiares, setFamiliares } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { secciones } = useSelector((state: RootState) => state.Me);
  const [catParentesco, setCatParentesco] = useState<Array<ICatalogosEstandar>>([]);
  const [esFamiliarUnico, setEsFamiliarUnico] = useState<boolean>(false);
  const [cambioGrupoEnfermedades, setCambioGrupoEnfermedades] = useState<boolean>(false);
  const [arrFam, setArrFam] = useState<IFamComprimido[]>([]);
  const [referirNegar, setReferirNegar] = useState<string>('');
  const [modalFallecido, setModalFallecido] = useState<boolean>(false);
  const [disableParentesco, setDisableParentesco] = useState<boolean>(false);
  const [disableNomYAp, setDisableNomYAp] = useState<boolean>(false);
  const [disableVive, setDisableVive] = useState<boolean>(false);
  const [disableReferirNegar, setDisableReferirNegar] = useState<boolean>(false);
  const [idFamiliarActualizar, setIDFamiliarActualizar] = useState<number>(0);
  // variable que checa si esta trayendo datos y se va a agregar un diagnostico al familiar
  const [agregarDiagnostico, setAgregarDiagnostico] = useState<boolean>(false);
  const [parentesco, setParentesco] = useState<ICatalogosEstandar>({ id: 0, label: '' });
  const [vive, setVive] = useState<string>('');
  const [nombreApellidos, setNombreApellidos] = useState<string>('');
  const [sexoFamiliar, setSexoFamiliar] = useState<string>('');
  const [listaEnfermedad, setListaEnfermedad] = useState<IFamiliarEnfermedades>(
    familiarEnfermedadesInitial,
  );
  const [enfNegada, setEnfNegada] = useState<IFamiliaresEnfermedadesNegadas>(
    familiarEnfermedadesNegadasInitial,
  );
  const [grupoEnfNegadas, setGrupoEnfNegadas] = useState<IFamiliaresGrupoEnfermedadesNegadas>(
    familiaresGrupoEnfermedadesNegadasInitial,
  );
  const disableFamiliar = () => {
    setDisableNomYAp(true);
    setDisableParentesco(true);
    setDisableVive(true);
  };
  const disableParentescoFamiliar = () => {
    setDisableParentesco(true);
  };
  const enableFamiliar = () => {
    setDisableVive(false);
    setDisableParentesco(false);
    setDisableNomYAp(false);
  };
  const enableRF = () => {
    setDisableReferirNegar(false);
  };
  const disableRF = () => {
    setDisableReferirNegar(true);
  };
  const handleModalFallecidoAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setModalFallecido(false);
  };
  const resetListasFamiliar = () => {
    setListaEnfermedad(familiarEnfermedadesInitial);
    setEnfNegada(familiarEnfermedadesNegadasInitial);
    setGrupoEnfNegadas(familiaresGrupoEnfermedadesNegadasInitial);
  };
  const resetFamiliarParcial = () => {
    setVive('');
    setNombreApellidos('');
    setReferirNegar('');
    resetListasFamiliar();
  };
  const resetFamiliar = () => {
    resetFamiliarParcial();
    setParentesco({ id: 0, label: '' });
    enableRF();
    enableFamiliar();
    setEsFamiliarUnico(false);
  };
  const handleChangeNombreApellidos = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setNombreApellidos(val);
  };
  const handleChangeParentesco = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    const indice = val - 1;
    setParentesco(catParentesco[indice]);
    resetFamiliarParcial();
  };
  const handleChangeViviente = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    setVive(val);
    setListaEnfermedad((leP) => ({ ...leP, esCausaDeMuerte: false }));
  };
  const getObjEnviar = (
    listaEnf: IFamiliarEnfermedades = listaEnfermedad,
    enfNeg: IFamiliaresEnfermedadesNegadas = enfNegada,
    grupoEnfNeg: IGrupoEnfermedadesNegadas = grupoEnfNegadas,
  ) => ({
    familiar: {
      idFamiliar: idFamiliarActualizar,
      parentesco: parentesco.id,
      vive,
      nombreApellidos,
      idPaciente,
    },
    listaEnfermedad: listaEnf,
    enfermedadNegada: enfNeg,
    grupoEnfermedadesNegadas: grupoEnfNeg,
    referirNegar,
    idPaciente,
    idMedico,
    idConsultorio,
    consulta: idConsulta,
  });
  // Agregar un diagnostico por primera vez
  const handleClickAgregarAntecedenteFamiliar = () => {
    // verificar si existe previamente el familiar
    // si es familiar unico y tiene el mismo parentesco regresar
    // si no es familiar unico pero es el mismo parentesco y el mismo nombre, regresar
    if (esFamiliarUnico) {
      const existe = arrFam.findIndex((fam) => fam.parentesco.id === parentesco.id);
      if (existe >= 0) {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: t('ya_existe_un_familiar_con_el_mismo_parentesco'),
          }),
        );
        return;
      }
    } else {
      const existe = arrFam
        .filter((fam) => fam.parentesco.id === parentesco.id)
        .findIndex((fam) => fam.nombreApellidos === nombreApellidos);
      if (existe >= 0) {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: t('ya_existe_un_familiar_con_el_mismo_nombre_y_parentesco'),
          }),
        );
        return;
      }
    }
    if (listaEnfermedad.edadAlDiagnostico.edad && !listaEnfermedad.edadAlDiagnostico.periodo) {
      return;
    }
    const listaGrupoNueva = { ...grupoEnfNegadas };
    if (referirNegar === 'referir') {
      if (listaEnfermedad.diagnostico === 'cie10') {
        // ahora buscamos el nombre del grupo, ya que si esta referida la enfermedad
        // eso significa que no podemos negarla del grupo, ya que existe
        // asi que buscamos su grupo y su selected y disabled
        const grupoEnf = numeroEnfermedadGrupo[listaEnfermedad.diagnosticoCie10.grupoEnfermedades];
        const keySelected = `${grupoEnf}Selected`;
        const keyDisabled = `${grupoEnf}Disabled`;
        // cuando lo encontremos tenemos que deseleccionalo si llegara a existir
        // y tenemos que deshabilitarlo
        if (hasKey(grupoEnfNegadas, keySelected) && hasKey(grupoEnfNegadas, keyDisabled)) {
          (listaGrupoNueva[keySelected] as boolean) = false;
          (listaGrupoNueva[keyDisabled] as boolean) = true;
          setGrupoEnfNegadas(listaGrupoNueva);
        }
      }
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendFamiliaresGuardarEnfermedadFamiliar(
          getObjEnviar(listaEnfermedad, enfNegada, listaGrupoNueva),
        ),
        successFunction: (result: any) => {
          const newFamiliares: IFamiliar[] = [
            ...familiares,
            {
              idPaciente,
              idFamiliar: result.datos,
              parentesco,
              vive: vive === 'T',
              nombreApellidos,
              listaEnfermedades:
                listaEnfermedad.diagnosticoCie10.id || listaEnfermedad.diagnosticoMedico
                  ? [listaEnfermedad]
                  : [],
              enfermedadesNegadas: enfNegada.diagnosticoCie10.id ? [enfNegada] : [],
              grupoEnfermedadesNegadas: listaGrupoNueva,
            },
          ];
          setFamiliares(newFamiliares);
          setIDFamiliarActualizar(0);
          resetFamiliar();
        },
      }),
    );
  };
  const handleModalFallecido = () => {
    if (listaEnfermedad.edadAlDiagnostico.edad && !listaEnfermedad.edadAlDiagnostico.periodo) {
      return;
    }
    const listaGrupoNueva = { ...grupoEnfNegadas };
    if (referirNegar === 'referir' && listaEnfermedad.diagnostico === 'cie10') {
      // ahora buscamos el nombre del grupo, ya que si esta referida la enfermedad
      // eso significa que no podemos negarla del grupo, ya que existe
      // asi que buscamos su grupo y su selected y disabled
      const grupoEnf = numeroEnfermedadGrupo[listaEnfermedad.diagnosticoCie10.grupoEnfermedades];
      const keySelected = `${grupoEnf}Selected`;
      const keyDisabled = `${grupoEnf}Disabled`;
      // cuando lo encontremos tenemos que deseleccionalo si llegara a existir
      // y tenemos que deshabilitarlo
      if (hasKey(grupoEnfNegadas, keySelected) && hasKey(grupoEnfNegadas, keyDisabled)) {
        (listaGrupoNueva[keySelected] as boolean) = false;
        (listaGrupoNueva[keyDisabled] as boolean) = true;
        setGrupoEnfNegadas(listaGrupoNueva);
      }
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: editAntecedentesFamiliaresEnfermedades(getObjEnviar()),
        successFunction: () => {
          const newFamiliares = [...familiares];
          const index = familiares.findIndex((f) => f.idFamiliar === idFamiliarActualizar);
          newFamiliares[index] = {
            ...newFamiliares[index],
            parentesco,
            vive: vive === 'T',
            nombreApellidos,
            // eslint-disable-next-line no-confusing-arrow
            listaEnfermedades: newFamiliares[index].listaEnfermedades.map((enf) => enf.idEnfermedad === listaEnfermedad.idEnfermedad ? listaEnfermedad : enf),
            // eslint-disable-next-line no-confusing-arrow
            enfermedadesNegadas: newFamiliares[index].enfermedadesNegadas.map((enf) => enf.idEnfermedadNegada === enfNegada.idEnfermedadNegada ? enfNegada : enf),
            grupoEnfermedadesNegadas: grupoEnfNegadas,
          };
          setFamiliares(newFamiliares);
          setIDFamiliarActualizar(0);
          resetFamiliarParcial();
          setParentesco({ id: 0, label: '' });
        },
      }),
    );
  };
  // Actualizar un diagnostico previo
  const handleClickActualizarAntecedenteFamiliar = () => {
    if (listaEnfermedad.edadAlDiagnostico.edad && !listaEnfermedad.edadAlDiagnostico.periodo) {
      return;
    }
    // verificar que no pase de muerto a vivo y en caso afirmativo sacar el modal
    const fi = arrFam.findIndex((fam) => fam.idFamiliar === idFamiliarActualizar);
    if (fi >= 0 && !familiares[fi].vive && vive === 'T') {
      setModalFallecido(true);
      dispatch(loading(false));
      return;
    }
    // sacar el modal y mandar las dos opciones, para quitar todas las enfermedades de ese paciente
    // si en dado caso tienen es causa de muerte
    const listaGrupoNueva = { ...grupoEnfNegadas };
    if (referirNegar === 'referir' && listaEnfermedad.diagnostico === 'cie10') {
      // ahora buscamos el nombre del grupo, ya que si esta referida la enfermedad
      // eso significa que no podemos negarla del grupo, ya que existe
      // asi que buscamos su grupo y su selected y disabled
      const grupoEnf = numeroEnfermedadGrupo[listaEnfermedad.diagnosticoCie10.grupoEnfermedades];
      const keySelected = `${grupoEnf}Selected`;
      const keyDisabled = `${grupoEnf}Disabled`;
      // cuando lo encontremos tenemos que deseleccionalo si llegara a existir
      // y tenemos que deshabilitarlo
      if (hasKey(grupoEnfNegadas, keySelected) && hasKey(grupoEnfNegadas, keyDisabled)) {
        (listaGrupoNueva[keySelected] as boolean) = false;
        (listaGrupoNueva[keyDisabled] as boolean) = true;
        setGrupoEnfNegadas(listaGrupoNueva);
      }
    }
    const nuevaListaEnfermedad = {
      ...listaEnfermedad,
      diagnosticoCie10: listaEnfermedad.diagnosticoCie10.id
        ? listaEnfermedad.diagnosticoCie10
        : { id: '', label: '', grupoEnfermedades: 0 },
    };
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: editAntecedentesFamiliaresEnfermedades(getObjEnviar(nuevaListaEnfermedad)),
        successFunction: () => {
          const newFamiliares = [...familiares];
          const index = familiares.findIndex((f) => f.idFamiliar === idFamiliarActualizar);
          newFamiliares[index] = {
            ...newFamiliares[index],
            parentesco,
            vive: vive === 'T',
            nombreApellidos,
            listaEnfermedades:
              nuevaListaEnfermedad.diagnosticoCie10.id || nuevaListaEnfermedad.diagnosticoMedico
                ? [
                  ...newFamiliares[index].listaEnfermedades.filter(
                    (e) => e.idEnfermedad !== nuevaListaEnfermedad.idEnfermedad,
                  ),
                  nuevaListaEnfermedad,
                ]
                : newFamiliares[index].listaEnfermedades,
            enfermedadesNegadas: enfNegada.diagnosticoCie10.id
              ? [
                ...newFamiliares[index].enfermedadesNegadas.filter(
                  (e) => e.idEnfermedadNegada !== enfNegada.idEnfermedadNegada,
                ),
                enfNegada,
              ]
              : newFamiliares[index].enfermedadesNegadas,
            grupoEnfermedadesNegadas: listaGrupoNueva,
          };
          setFamiliares(newFamiliares);
          setIDFamiliarActualizar(0);
          setDisableParentesco(false);
          resetFamiliar();
        },
      }),
    );
  };
  // Agregar un diagnostico a un familiarExistente
  const handleClickAgregarAFamiliar = () => {
    if (listaEnfermedad.edadAlDiagnostico.edad && !listaEnfermedad.edadAlDiagnostico.periodo) {
      return;
    }
    const listaGrupoNueva = { ...grupoEnfNegadas };
    if (referirNegar === 'referir') {
      if (listaEnfermedad.diagnostico === 'cie10') {
        // ahora buscamos el nombre del grupo, ya que si esta referida la enfermedad
        // eso significa que no podemos negarla del grupo, ya que existe
        // asi que buscamos su grupo y su selected y disabled
        const grupoEnf = numeroEnfermedadGrupo[listaEnfermedad.diagnosticoCie10.grupoEnfermedades];
        const keySelected = `${grupoEnf}Selected`;
        const keyDisabled = `${grupoEnf}Disabled`;
        // cuando lo encontremos tenemos que deseleccionalo si llegara a existir
        // y tenemos que deshabilitarlo
        if (hasKey(grupoEnfNegadas, keySelected) && hasKey(grupoEnfNegadas, keyDisabled)) {
          (listaGrupoNueva[keySelected] as boolean) = false;
          (listaGrupoNueva[keyDisabled] as boolean) = true;
          setGrupoEnfNegadas(listaGrupoNueva);
        }
      }
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendFamiliaresAgregarEnfermedadFamiliar(
          getObjEnviar(listaEnfermedad, enfNegada, listaGrupoNueva),
        ),
        successFunction: () => {
          const newFamiliares = [...familiares];
          const index = familiares.findIndex((f) => f.idFamiliar === idFamiliarActualizar);
          newFamiliares[index] = {
            ...newFamiliares[index],
            parentesco,
            vive: vive === 'T',
            nombreApellidos,
            listaEnfermedades:
              referirNegar === 'referir'
                ? [...newFamiliares[index].listaEnfermedades, listaEnfermedad]
                : newFamiliares[index].listaEnfermedades,
            enfermedadesNegadas:
              referirNegar === 'negar'
                ? [...newFamiliares[index].enfermedadesNegadas, enfNegada]
                : newFamiliares[index].enfermedadesNegadas,
            grupoEnfermedadesNegadas: listaGrupoNueva,
          };
          setFamiliares(newFamiliares);
          resetFamiliar();
        },
      }),
    );
  };
  const handleChangeReferirNegar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    setReferirNegar(val);
    if (!idFamiliarActualizar) {
      resetListasFamiliar();
    }
  };
  const verificarDisableAntecedenteFamiliar = () => {
    const val1 = esFamiliarUnico
      ? !parentesco.id || !vive || !referirNegar
      : !parentesco.id || !nombreApellidos || !vive || !referirNegar;
    if (referirNegar === 'referir') {
      return (
        val1
        || (listaEnfermedad.diagnostico === 'cie10'
          ? !listaEnfermedad.diagnosticoCie10.id
          : !listaEnfermedad.diagnosticoMedico)
      );
    }
    if (referirNegar === 'negar') {
      return val1 || (cambioGrupoEnfermedades ? false : !enfNegada.diagnosticoCie10.id);
    }
    return val1 || true;
  };
  const verificarExistenDatosEnElFormulario = () => !!parentesco.id || !!vive || !!nombreApellidos;
  useEffect(() => {
    if (idPaciente > 0 && idMedico > 0 && idConsultorio > 0) {
      const abortController = new AbortController();
      const { signal } = abortController;
      getAntecedentesParentesco(signal).then((result: ICatalogosEstandar[]) => {
        setCatParentesco(result);
      });
      return () => abortController.abort();
    }
    return undefined;
  }, [idPaciente, idConsultorio, idMedico]);
  useEffect(() => {
    setEsFamiliarUnico([1, 2, 11, 12, 13, 14].includes(parentesco.id));
    setSexoFamiliar([1, 3, 5, 7, 8, 11, 12, 15, 17, 18, 21].includes(parentesco.id) ? 'H' : 'M');
  }, [parentesco]);
  useEffect(() => {
    const fam = familiares.map((lf) => ({
      idFamiliar: lf.idFamiliar,
      parentesco: lf.parentesco,
      vive: lf.vive,
      nombreApellidos: lf.nombreApellidos,
      idPaciente: lf.idPaciente,
    }));
    setArrFam(fam);
  }, [familiares]);

  return (
    <div>
      <DeleteDialog
        open={modalFallecido}
        titulo={t('_atencion_')}
        descripcion={t('modal_fallecido_texto_atencion')}
        callBackAceptar={handleModalFallecido}
        callBackClose={handleModalFallecidoAlertClose}
      />
      <div className="container mx-auto shadow-lg border border-solid border-gray-200 rounded p-4">
        <h1 className="text-blue-800 font-normal m-2" id="antecedentes-familiar">
          {t('antecedentes_familiares')}
        </h1>
        <div className="grid grid-cols-3 mb-4 gap-x-8 gap-y-4 items-end">
          <div>
            <h3 className="text-gray-600 font-normal m-0 mb-2">{`${t('parentesco')}*`}</h3>
            <FormControl variant="outlined" fullWidth>
              <Select
                displayEmpty
                onChange={handleChangeParentesco}
                value={parentesco.id}
                disabled={disableParentesco}
              >
                <MenuItem value={0} disabled>
                  <span className="text-gray-400">{t('selecciona')}</span>
                </MenuItem>
                {catParentesco.map((cParentesco) => (
                  <MenuItem value={cParentesco.id} key={cParentesco.id}>
                    {cParentesco.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {!esFamiliarUnico && (
            <div>
              <h3 className="text-gray-600 font-normal m-0 mb-2">
                {`${t('nombre_y_apellidos')}*`}
              </h3>
              <TextField
                variant="outlined"
                fullWidth
                value={nombreApellidos}
                onChange={handleChangeNombreApellidos}
                disabled={disableNomYAp}
              />
            </div>
          )}
          <div>
            <h3 className="text-gray-600 font-normal m-0 mb-4">{`${t('_su_familiar_vive_')}*`}</h3>
            <RadioGroup
              aria-label="¿Su familiar vive?"
              name="¿Su familiar vive?"
              row
              value={vive}
              onChange={handleChangeViviente}
            >
              <FormControlLabel
                value="T"
                control={<Radio color="primary" />}
                label={t('si')}
                disabled={disableVive}
              />
              <FormControlLabel
                value="F"
                control={<Radio color="primary" />}
                label={t('no')}
                disabled={disableVive}
              />
            </RadioGroup>
          </div>
          {parentesco.id > 0 && vive && (
            <div className="col-span-2">
              <h3 className="text-gray-600 font-normal m-0">{`${t('_que_deseas_')}*`}</h3>
              <RadioGroup
                aria-label="¿Qué deseas?"
                name="¿Qué deseas?"
                row
                value={referirNegar}
                onChange={handleChangeReferirNegar}
              >
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 31) && (
                  <FormControlLabel
                    value="referir"
                    control={<Radio color="primary" />}
                    label={t('referir_una_enfermedad')}
                    disabled={disableReferirNegar}
                  />
                )}
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 32) && (
                  <FormControlLabel
                    value="negar"
                    control={<Radio color="primary" />}
                    label={t('negar_una_enfermedad')}
                    disabled={disableReferirNegar}
                  />
                )}
              </RadioGroup>
            </div>
          )}
        </div>
        <hr />
        <div className="grid grid-cols-4 mb-4 gap-4" />
        {referirNegar === 'referir' && (
          <FormAntecedentesFamiliaresReferir
            listaEnfermedad={listaEnfermedad}
            setListaEnfermedad={setListaEnfermedad}
            vive={vive === 'T'}
            sexoFamiliar={sexoFamiliar}
            setGrupoEnfNegadas={setGrupoEnfNegadas}
          />
        )}
        {referirNegar === 'negar' && (
          <FormAntecedentesFamiliaresNegar
            enfNegada={enfNegada}
            setEnfNegada={setEnfNegada}
            grupoEnfNegadas={grupoEnfNegadas}
            setGrupoEnfNegadas={setGrupoEnfNegadas}
            sexoFamiliar={sexoFamiliar}
            setCambioGrupoEnfermedades={setCambioGrupoEnfermedades}
          />
        )}
        <div className="text-right mt-4">
          {/* eslint-disable no-nested-ternary */}
          <Button
            variant="contained"
            color="primary"
            type="button"
            size="large"
            disabled={verificarDisableAntecedenteFamiliar()}
            onClick={
              idFamiliarActualizar === 0
                ? handleClickAgregarAntecedenteFamiliar
                : agregarDiagnostico
                  ? handleClickAgregarAFamiliar
                  : handleClickActualizarAntecedenteFamiliar
            }
          >
            {t(idFamiliarActualizar !== 0 && !agregarDiagnostico ? 'actualizar' : 'guardar')}
          </Button>
        </div>
        <p className="text-right">
          {t('para_registrar_el_antecedente_debes_dar_click_en_el_boton')}
          &nbsp;
          <span className="font-bold">{`"${t('guardar')}"`}</span>
        </p>
      </div>
      <ListaAntecedentesFamiliares
        disableFamiliar={disableFamiliar}
        enableFamiliar={enableFamiliar}
        listaFamiliares={familiares}
        setListaFamiliares={setFamiliares}
        setParentesco={setParentesco}
        setVive={setVive}
        setNombreApellidos={setNombreApellidos}
        setIdFamiliarActualizar={setIDFamiliarActualizar}
        setReferirNegar={setReferirNegar}
        setListaEnfermedad={setListaEnfermedad}
        setEnfNegada={setEnfNegada}
        setGrupoEnfNegadas={setGrupoEnfNegadas}
        verificarExitenDatosEnElFormulario={verificarExistenDatosEnElFormulario}
        resetListasFamiliar={resetListasFamiliar}
        enableRF={enableRF}
        disableRF={disableRF}
        setAgregarDiagnostico={setAgregarDiagnostico}
        disableParentesco={disableParentescoFamiliar}
      />
    </div>
  );
}

export default Familiares;
