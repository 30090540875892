import React from 'react';
import { useTranslation } from 'react-i18next';
import ConstructorTabla from '../ConstructorTabla';
import { IVasomotoresProps } from './types';

function Vasomotores(props: IVasomotoresProps) {
  const { ginecoObstetrico, setGinecoObstetrico } = props;
  const { t } = useTranslation();

  const setRadiosValues = (newRadioVals: string[]) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      sintomasClimaterio: {
        ...ginecoObstetrico.sintomasClimaterio,
        vasomotores: {
          ...ginecoObstetrico.sintomasClimaterio.vasomotores,
          radiosValues: newRadioVals,
        },
      },
    });
  };

  const setComentariosValues = (newComVals: string[]) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      sintomasClimaterio: {
        ...ginecoObstetrico.sintomasClimaterio,
        vasomotores: {
          ...ginecoObstetrico.sintomasClimaterio.vasomotores,
          comentariosValues: newComVals,
        },
      },
    });
  };

  return (
    <div>
      <h2 className="mt-4 mb-4 font-normal text-blue-300">{t('vasomotores')}</h2>
      <ConstructorTabla
        items={ginecoObstetrico.sintomasClimaterio.vasomotores.items}
        radiosValues={ginecoObstetrico.sintomasClimaterio.vasomotores.radiosValues}
        setRadiosValues={setRadiosValues}
        comentariosValues={ginecoObstetrico.sintomasClimaterio.vasomotores.comentariosValues}
        setComentariosValues={setComentariosValues}
      />
    </div>
  );
}

export default Vasomotores;
