import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    justifyContent: 'left',
    textTransform: 'none',
  },
  boldText: {
    fontWeight: 'bold',
    color: 'black',
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  noTextTranform: {
    textTransform: 'none',
  },
});

export default useStyles;
