import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paperPadding: {
    padding: '1rem',
  },
  fullWidth: {
    width: '100%',
  },
  marginUpDown: {
    marginTop: '0.9375rem',
    marginBottom: '0.9375rem',
  },
  marginDown: {
    marginBottom: '0.9375rem',
  },
  marginUp: {
    marginTop: '0.9375rem',
  },
  margin10pdown: {
    marginBottom: '10px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  textBlack: {
    color: 'black',
  },
  noPaddingB: {
    paddingBottom: '0 !important',
  },
  helperText: {
    textAlign: 'right',
  },
  upperText: {
    textTransform: 'uppercase',
  },
  diezPxDown: {
    marginBottom: '10px',
  },
});

export default useStyles;
