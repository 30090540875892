import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import TabEmpresa from './TabEmpresa';
import TabLaboral from './TabLaboral';
import TabExposicion from './TabExposicion';
import ListaAntecedentesLaborales from './ListaAntecedentesLaborales';
import DeleteDialog from '../../../../../components/DeleteDialog';
import { RootState } from '../../../../../store';
import { IFormAntecedentesLaboralesProps } from './types';
import { ICatalogosEstandar } from '../../../../../common/types';
import {
  IDbTiempoSeleccionComentariosFecha,
  IGroupSelectorsRiesgo,
  IObjArrayAccidentesLaborales,
  IObjComentariosFecha,
  IObjLaboralAccidente,
  IObjTabEmpresa,
  ITiempoSeleccionComentariosFecha,
  ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
  arrayAccidentesLaboralesInitial,
  groupSelectorRiesgoInitial,
  laboralAccidenteInitial,
  tabEmpresaInitial,
} from '../types';
import {
  getCatalogoTipoOcupacion,
  getCatalogoAccidentesCausa,
  getCatalogoAccidentesLesionNaturaleza,
  getCatalogoIncapacidadesTipoRiesgo,
  getCatalogoQuimicos,
  getCatalogoAccidentesLugar,
  getCatalogoAccidentesTurno,
  getCatalogoAccidentesLesionSegmento,
  getCatalogoAccidentesLesionUbicacion,
  getCatalogoAccidentesLesionGravedad,
  getCatalogoAccidentesPorcentajeIncapacidad,
} from '../../../../../utils/getCatalogos';
import {
  sendAntecedentesLaboralesEmpresa,
  sendExposicionesNegadas,
  sendAntecedentesLaboralesExposicionLista,
  sendEliminarAntecedentesLaboralesExposicionLista,
  sendAccidenteLaboralRadio,
  sendAntecedentesAccidenteObjLaboral,
  sendAntecedentesEliminarAccidenteLaboral,
  sendAntecedentesEliminarEmpresaURL,
} from '../../../../../utils/sendInfo';
import { setSnackComplete } from '../../../../../actions/snackbar/types';
import { setRequest } from '../../../../../actions/request/types';
import { setLoading } from '../../../../../actions/loading/actions';

function FormAntecedentesLaborales({
  nombreEmpresa,
  setDisabledCheck,
  idLaboral,
  antecedentesLaborales,
  setAntecedentesLaborales,
}: IFormAntecedentesLaboralesProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { consultorios } = useSelector((state: RootState) => state.Consultorios);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [inxArraySeleccionado, setInxArraySeleccionado] = useState<number>(-1);
  const [objTabEmpresa, setObjTabEmpresa] = useState<IObjTabEmpresa>(tabEmpresaInitial);
  const [objLaboralAccidente, setObjLaboralAccidente] = useState<IObjLaboralAccidente>(laboralAccidenteInitial);
  const [objArrayAccidentesLaborales, setObjArrayAccidentesLaborales] = useState<IObjArrayAccidentesLaborales>(arrayAccidentesLaboralesInitial);
  const [datosTipoOcupacion, setDatosTipoOcupacion] = useState<ICatalogosEstandar[]>([]);
  const [datosLugarAccidente, setDatosLugarAccidente] = useState<ICatalogosEstandar[]>([]);
  const [datosTurnoAccidente, setDatosTurnoAccidente] = useState<ICatalogosEstandar[]>([]);
  const [datosCausaAccidente, setDatosCausaAccidente] = useState<ICatalogosEstandar[]>([]);
  const [datosNaturalezaLesion, setDatosNaturalezaLesion] = useState<ICatalogosEstandar[]>([]);
  const [datosSegmentoLesionado, setDatosSegmentoLesionado] = useState<ICatalogosEstandar[]>([]);
  const [datosUbicacionLesion, setDatosUbicacionLesion] = useState<ICatalogosEstandar[]>([]);
  const [datosGravedad, setDatosGravedad] = useState<ICatalogosEstandar[]>([]);
  const [datosPorcentajeIncapacidad, setDatosPorcentajeIncapacidad] = useState<
  ICatalogosEstandar[]
  >([]);
  const [datosTipoRiesgo, setDatosTipoRiesgo] = useState<ICatalogosEstandar[]>([]);
  const [datosQuimicos, setDatosQuimicos] = useState<ICatalogosEstandar[]>([]);
  const [objExposicionRiesgo, setObjExposicionRiesgo] = useState<IGroupSelectorsRiesgo>(
    groupSelectorRiesgoInitial,
  );
  // peticion para limpiar todos los elementos de la pantalla
  const peticionLimpiarPantalla = () => {
    setObjExposicionRiesgo(groupSelectorRiesgoInitial);
    setObjTabEmpresa(tabEmpresaInitial);
    setObjLaboralAccidente(laboralAccidenteInitial);
    setObjArrayAccidentesLaborales(arrayAccidentesLaboralesInitial);
  };
  const evalAnyRiesgo = (riesgo: string): boolean => {
    if (riesgo === 'fisico') {
      return (
        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayFluidoElectrico.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayIluminacion.length !== 0
        || objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayOtro.length !== 0
        || objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionIonizante.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionNoIonizante
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRuido.length !== 0
        || objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasAltas.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasBajas.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVentilacion.length !== 0
        || objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVibracion.length !== 0
      );
    }
    if (riesgo === 'mecanico') {
      return (
        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtrapamiento.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtropellamientosVehiculos
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidaObjetos.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasDiferenteNivel
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasMismoNivel
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayChoques.length !== 0
        || objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayContactoElectrico
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
          .arrayContactoSuperficiesTrabajo.length !== 0
        || objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCortes.length !== 0
        || objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayOtro.length !== 0
        || objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayPinchazos.length !== 0
      );
    }
    if (riesgo === 'quimico') {
      return (
        objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayAerosoles.length !== 0
        || objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayGaseosos.length !== 0
        || objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayHumos.length !== 0
        || objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayLiquidos.length !== 0
        || objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayNeblinas.length !== 0
        || objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayOtro.length !== 0
        || objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayPolvos.length !== 0
        || objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arraySolidos.length !== 0
        || objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayVapores.length !== 0
      );
    }
    if (riesgo === 'biologico') {
      return (
        objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayAnimales.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayBacterias.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayHongos.length !== 0
        || objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayOtro.length !== 0
        || objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayParasitos.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVectores.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVirus.length !== 0
      );
    }
    if (riesgo === 'ergonomico') {
      return (
        objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayExtresContacto
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayFuerzaExcesiva
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayOtro.length !== 0
        || objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
          .arrayPosicionesEstacionarias.length !== 0
        || objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPosturaIncomoda
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPresionDirecta
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayRepeticion.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayTemperaturasExtremas
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayVibraciones
          .length !== 0
      );
    }
    if (riesgo === 'psicosocial') {
      return (
        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayAcoso.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
          .arrayCargasTrabajoExcesivas.length !== 0
        || objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayFaltaControlTrabajo
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayHostigamiento
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
          .arrayInterferenciaRelacion.length !== 0
        || objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
          .arrayJornadasTrabajoSuperiores.length !== 0
        || objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayLiderazgoNegativo
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayMalosTratos
          .length !== 0
        || objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayOtro.length
          !== 0
        || objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
          .arrayPeriodosCortosRecuperacion.length !== 0
        || objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayRotacionTurnos
          .length !== 0
      );
    }
    return false;
  };
  const showSuccessMessage = (trad: string) => {
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'success',
        mensaje: t(trad),
      }),
    );
  };
  // guardar los datos de la empresa
  const guardarEmpresa = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendAntecedentesLaboralesEmpresa({
          ...objTabEmpresa,
          idPaciente,
          idMedico,
          idConsultorio,
          idLaboral,
        }),
        successFunction: (result: any) => {
          setObjTabEmpresa({
            ...objTabEmpresa,
            id: parseInt(result.data.id, 10),
            guardadoOk: true,
          });
          setAntecedentesLaborales([
            ...antecedentesLaborales,
            {
              objTabEmpresa: {
                ...objTabEmpresa,
                id: parseInt(result.data.id, 10),
                guardadoOk: true,
              },
              objExposicionRiesgo: groupSelectorRiesgoInitial,
              objLaboral: {
                idLaboralAccidente: -1,
                radioLaboral: undefined,
                arrayAccidentesLaborales: [],
              },
            },
          ]);
        },
      }),
    );
  };
  // actualizar los datos de la empresa
  const actualizarEmpresa = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendAntecedentesLaboralesEmpresa({
          ...objTabEmpresa,
          idPaciente,
          idMedico,
          idConsultorio,
          idLaboral,
        }),
        successFunction: () => {
          peticionLimpiarPantalla();
          const newAntLaborales = [...antecedentesLaborales];
          const index = antecedentesLaborales.findIndex(
            (a) => a.objTabEmpresa.id === objTabEmpresa.id,
          );
          newAntLaborales[index] = {
            ...newAntLaborales[index],
            objTabEmpresa: {
              ...objTabEmpresa,
              guardadoOk: true,
            },
          };
          setAntecedentesLaborales(newAntLaborales);
        },
      }),
    );
  };
  // edita la información de la lista seleccionada
  const actualizaListaValores = (inxArrayAntecedenteLaboral: number) => {
    setObjTabEmpresa(antecedentesLaborales[inxArrayAntecedenteLaboral].objTabEmpresa);
    setObjLaboralAccidente(
      Object.prototype.hasOwnProperty.call(
        antecedentesLaborales[inxArrayAntecedenteLaboral].objLaboral,
        'radioLaboral',
      )
        ? antecedentesLaborales[inxArrayAntecedenteLaboral].objLaboral
        : {
          idLaboralAccidente: -1,
          radioLaboral: undefined,
          arrayAccidentesLaborales: [],
        },
    );
    setObjExposicionRiesgo(
      Object.prototype.hasOwnProperty.call(
        antecedentesLaborales[inxArrayAntecedenteLaboral].objExposicionRiesgo,
        'negadas',
      )
        ? antecedentesLaborales[inxArrayAntecedenteLaboral].objExposicionRiesgo
        : groupSelectorRiesgoInitial,
    );
  };
  const openDeleteDialog = (inxArrayAntecedenteLaboral: number) => {
    setInxArraySeleccionado(inxArrayAntecedenteLaboral);
    setDeleteAlert(true);
  };
  // elimina el valor de la lista seleccionada
  const eliminaListaValores = (inxArrayAntecedenteLaboral: number) => {
    const newAntecedentesLaborales = [...antecedentesLaborales];
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendAntecedentesEliminarEmpresaURL({
          idPaciente,
          idMedico,
          idConsultorio,
          idAntecedenteEmpresa:
            newAntecedentesLaborales[inxArrayAntecedenteLaboral].objTabEmpresa.id,
        }),
        successFunction: () => {
          newAntecedentesLaborales.splice(inxArrayAntecedenteLaboral, 1);
          setAntecedentesLaborales(newAntecedentesLaborales);
          setObjArrayAccidentesLaborales({
            id: -1,
            fechaDia: '',
            fechaMes: '',
            fechaAnio: '',
            lugarAccidente: { id: 0, label: '' },
            turnoAccidente: { id: 0, label: '' },
            radioCausaAccidente: undefined,
            tipoCausaAccidente: { id: 0, label: '' },
            txtCausaAccidente: '',
            radioNaturalezaLesion: undefined,
            tipoNaturalezaLesion: { id: 0, label: '' },
            txtNaturalezaLesion: '',
            segmentoLesionado: { id: 0, label: '' },
            ubicacionLesion: { id: 0, label: '' },
            gravedad: { id: 0, label: '' },
            porcentajeIncapacidad: { id: 0, label: '' },
            radioEncuestaNOM035: undefined,
            radioAmeritaSeguimiento: undefined,
            tipoRiesgo: { id: 0, label: '' },
            radioDiscapacidad: undefined,
            diasIncapacidad: undefined,
            comentarios: '',
          });
        },
      }),
    );
  };
  const changeArrayTemperaturasAltas = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        seleccionado: 1,
        opcionesFisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico,
          arrayTemperaturasAltas: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayTemperaturasBajas = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        seleccionado: 1,
        opcionesFisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico,
          arrayTemperaturasBajas: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayRadiacionIonizante = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        seleccionado: 1,
        opcionesFisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico,
          arrayRadiacionIonizante: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayRadiacionNoIonizante = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        seleccionado: 1,
        opcionesFisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico,
          arrayRadiacionNoIonizante: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayVentilacion = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        seleccionado: 1,
        opcionesFisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico,
          arrayVentilacion: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayIluminacion = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        seleccionado: 1,
        opcionesFisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico,
          arrayIluminacion: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayRuido = (newArray: IDbTiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        seleccionado: 1,
        opcionesFisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico,
          arrayRuido: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayVibracion = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        seleccionado: 1,
        opcionesFisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico,
          arrayVibracion: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayFluidoElectrico = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        seleccionado: 1,
        opcionesFisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico,
          arrayFluidoElectrico: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayOtroFisico = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      fisico: {
        seleccionado: 1,
        opcionesFisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico,
          arrayOtro: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayAtrapamiento = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayAtrapamiento: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayAtropellamientosVehiculos = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayAtropellamientosVehiculos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayCaidaObjetos = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayCaidaObjetos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayCaidasMismoNivel = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayCaidasMismoNivel: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayCaidasDiferenteNivel = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayCaidasDiferenteNivel: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayContactoSuperficiesTrabajo = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayContactoSuperficiesTrabajo: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayChoques = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayChoques: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayContactoElectrico = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayContactoElectrico: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayCortes = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayCortes: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayProyeccionFragmentos = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayProyeccionFragmentos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayProyeccionFluidos = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayProyeccionFluidos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayPinchazos = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayPinchazos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayOtroMecanico = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      mecanico: {
        seleccionado: 1,
        opcionesMecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico,
          arrayOtro: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayAerosoles = (
    newArray: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[],
  ) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      quimico: {
        seleccionado: 1,
        opcionesQuimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico,
          arrayAerosoles: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayHumos = (
    newArray: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[],
  ) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      quimico: {
        seleccionado: 1,
        opcionesQuimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico,
          arrayHumos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayGaseosos = (
    newArray: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[],
  ) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      quimico: {
        seleccionado: 1,
        opcionesQuimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico,
          arrayGaseosos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayLiquidos = (
    newArray: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[],
  ) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      quimico: {
        seleccionado: 1,
        opcionesQuimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico,
          arrayLiquidos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArraySolidos = (
    newArray: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[],
  ) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      quimico: {
        seleccionado: 1,
        opcionesQuimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico,
          arraySolidos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayNeblinas = (
    newArray: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[],
  ) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      quimico: {
        seleccionado: 1,
        opcionesQuimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico,
          arrayNeblinas: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayPolvos = (
    newArray: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[],
  ) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      quimico: {
        seleccionado: 1,
        opcionesQuimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico,
          arrayPolvos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayVapores = (
    newArray: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[],
  ) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      quimico: {
        seleccionado: 1,
        opcionesQuimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico,
          arrayVapores: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayOtroQuimico = (
    newArray: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[],
  ) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      quimico: {
        seleccionado: 1,
        opcionesQuimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico,
          arrayOtro: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayAnimales = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      biologico: {
        seleccionado: 1,
        opcionesBiologico: {
          ...objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico,
          arrayAnimales: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayBacterias = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      biologico: {
        seleccionado: 1,
        opcionesBiologico: {
          ...objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico,
          arrayBacterias: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayHongos = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      biologico: {
        seleccionado: 1,
        opcionesBiologico: {
          ...objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico,
          arrayHongos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayParasitos = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      biologico: {
        seleccionado: 1,
        opcionesBiologico: {
          ...objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico,
          arrayParasitos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayVectores = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      biologico: {
        seleccionado: 1,
        opcionesBiologico: {
          ...objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico,
          arrayVectores: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayVirus = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      biologico: {
        seleccionado: 1,
        opcionesBiologico: {
          ...objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico,
          arrayVirus: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayOtroBiologico = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      biologico: {
        seleccionado: 1,
        opcionesBiologico: {
          ...objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico,
          arrayOtro: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayRepeticion = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      ergonomico: {
        seleccionado: 1,
        opcionesErgonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico,
          arrayRepeticion: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayPosturaIncomoda = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      ergonomico: {
        seleccionado: 1,
        opcionesErgonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico,
          arrayPosturaIncomoda: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayFuerzaExcesiva = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      ergonomico: {
        seleccionado: 1,
        opcionesErgonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico,
          arrayFuerzaExcesiva: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayPosicionesEstacionarias = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      ergonomico: {
        seleccionado: 1,
        opcionesErgonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico,
          arrayPosicionesEstacionarias: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayPresionDirecta = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      ergonomico: {
        seleccionado: 1,
        opcionesErgonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico,
          arrayPresionDirecta: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayTemperaturasExtremas = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      ergonomico: {
        seleccionado: 1,
        opcionesErgonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico,
          arrayTemperaturasExtremas: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayVibraciones = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      ergonomico: {
        seleccionado: 1,
        opcionesErgonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico,
          arrayVibraciones: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayExtresContacto = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      ergonomico: {
        seleccionado: 1,
        opcionesErgonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico,
          arrayExtresContacto: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayOtroErgonomico = (newArray: ITiempoSeleccionComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      ergonomico: {
        seleccionado: 1,
        opcionesErgonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico,
          arrayOtro: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayCargasTrabajoExcesivas = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayCargasTrabajoExcesivas: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayFaltaControlTrabajo = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayFaltaControlTrabajo: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayJornadasTrabajoSuperiores = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayJornadasTrabajoSuperiores: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayRotacionTurnos = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayRotacionTurnos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayPeriodosCortosRecuperacion = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayPeriodosCortosRecuperacion: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayInterferenciaRelacion = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayInterferenciaRelacion: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayLiderazgoNegativo = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayLiderazgoNegativo: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayHostigamiento = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayHostigamiento: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayAcoso = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayAcoso: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayMalosTratos = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayMalosTratos: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const changeArrayOtroPsicosocial = (newArray: IObjComentariosFecha[]) => {
    const grupoSelectores = {
      ...objExposicionRiesgo.grupoSelectores,
      psicosocial: {
        seleccionado: 1,
        opcionesPsicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial,
          arrayOtro: newArray,
        },
      },
    };
    setObjExposicionRiesgo({ ...objExposicionRiesgo, grupoSelectores });
  };
  const getDataToSend = (valorTiempoSeleccionComentarios: any, grupoSelector: number) => ({
    idPaciente,
    idMedico,
    idConsultorio,
    idEmpresa: objTabEmpresa.id || undefined,
    idExposicion: objExposicionRiesgo.id || undefined,
    idExposicionLista:
      valorTiempoSeleccionComentarios && valorTiempoSeleccionComentarios.id
        ? valorTiempoSeleccionComentarios.id
        : undefined,
    grupoSelector,
    txtDb:
      valorTiempoSeleccionComentarios && valorTiempoSeleccionComentarios.txtDb
        ? valorTiempoSeleccionComentarios.txtDb
        : undefined,
    tipoQuimico:
      valorTiempoSeleccionComentarios && valorTiempoSeleccionComentarios.tipoQuimico
        ? valorTiempoSeleccionComentarios.tipoQuimico
        : undefined,
    txtQuimicoOtro:
      valorTiempoSeleccionComentarios && valorTiempoSeleccionComentarios.txtQuimicoOtro
        ? valorTiempoSeleccionComentarios.txtQuimicoOtro
        : undefined,
    txtTiempo:
      valorTiempoSeleccionComentarios && valorTiempoSeleccionComentarios.txtTiempo
        ? valorTiempoSeleccionComentarios.txtTiempo
        : undefined,
    radioSeleccion:
      valorTiempoSeleccionComentarios && valorTiempoSeleccionComentarios.radioSeleccion
        ? valorTiempoSeleccionComentarios.radioSeleccion
        : undefined,
    comentarios:
      valorTiempoSeleccionComentarios && valorTiempoSeleccionComentarios.comentarios
        ? valorTiempoSeleccionComentarios.comentarios
        : undefined,
  });
  const changeArray = (
    grupoSelector: number,
    func: (
      array:
      | ITiempoSeleccionComentariosFecha[]
      | IDbTiempoSeleccionComentariosFecha[]
      | IObjComentariosFecha[],
    ) => any,
  ) => {
    switch (grupoSelector) {
      case 1:
        changeArrayTemperaturasAltas(
          func(objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasAltas),
        );
        break;
      case 2:
        changeArrayTemperaturasBajas(
          func(objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasBajas),
        );
        break;
      case 3:
        changeArrayRadiacionIonizante(
          func(objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionIonizante),
        );
        break;
      case 4:
        changeArrayRadiacionNoIonizante(
          func(objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionNoIonizante),
        );
        break;
      case 5:
        changeArrayVentilacion(
          func(objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVentilacion),
        );
        break;
      case 6:
        changeArrayIluminacion(
          func(objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayIluminacion),
        );
        break;
      case 7:
        changeArrayRuido(
          func(objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRuido),
        );
        break;
      case 8:
        changeArrayVibracion(
          func(objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVibracion),
        );
        break;
      case 9:
        changeArrayFluidoElectrico(
          func(objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayFluidoElectrico),
        );
        break;
      case 10:
        changeArrayOtroFisico(
          func(objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayOtro),
        );
        break;
      case 11:
        changeArrayAtrapamiento(
          func(objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtrapamiento),
        );
        break;
      case 12:
        changeArrayAtropellamientosVehiculos(
          func(
            objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
              .arrayAtropellamientosVehiculos,
          ),
        );
        break;
      case 13:
        changeArrayCaidaObjetos(
          func(objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidaObjetos),
        );
        break;
      case 14:
        changeArrayCaidasMismoNivel(
          func(objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasMismoNivel),
        );
        break;
      case 15:
        changeArrayCaidasDiferenteNivel(
          func(
            objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasDiferenteNivel,
          ),
        );
        break;
      case 16:
        changeArrayContactoSuperficiesTrabajo(
          func(
            objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
              .arrayContactoSuperficiesTrabajo,
          ),
        );
        break;
      case 17:
        changeArrayChoques(
          func(objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayChoques),
        );
        break;
      case 18:
        changeArrayContactoElectrico(
          func(
            objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayContactoElectrico,
          ),
        );
        break;
      case 19:
        changeArrayCortes(
          func(objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCortes),
        );
        break;
      case 20:
        changeArrayProyeccionFragmentos(
          func(
            objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayProyeccionFragmentos,
          ),
        );
        break;
      case 21:
        changeArrayProyeccionFluidos(
          func(
            objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayProyeccionFluidos,
          ),
        );
        break;
      case 22:
        changeArrayPinchazos(
          func(objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayPinchazos),
        );
        break;
      case 23:
        changeArrayOtroMecanico(
          func(objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayOtro),
        );
        break;
      case 24:
        changeArrayAerosoles(
          func(objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayAerosoles),
        );
        break;
      case 25:
        changeArrayHumos(
          func(objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayHumos),
        );
        break;
      case 26:
        changeArrayGaseosos(
          func(objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayGaseosos),
        );
        break;
      case 27:
        changeArrayLiquidos(
          func(objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayLiquidos),
        );
        break;
      case 28:
        changeArraySolidos(
          func(objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arraySolidos),
        );
        break;
      case 29:
        changeArrayNeblinas(
          func(objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayNeblinas),
        );
        break;
      case 30:
        changeArrayPolvos(
          func(objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayPolvos),
        );
        break;
      case 31:
        changeArrayVapores(
          func(objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayVapores),
        );
        break;
      case 32:
        changeArrayOtroQuimico(
          func(objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayOtro),
        );
        break;
      case 33:
        changeArrayAnimales(
          func(objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayAnimales),
        );
        break;
      case 34:
        changeArrayBacterias(
          func(objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayBacterias),
        );
        break;
      case 35:
        changeArrayHongos(
          func(objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayHongos),
        );
        break;
      case 36:
        changeArrayParasitos(
          func(objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayParasitos),
        );
        break;
      case 37:
        changeArrayVectores(
          func(objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVectores),
        );
        break;
      case 38:
        changeArrayVirus(
          func(objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVirus),
        );
        break;
      case 39:
        changeArrayOtroBiologico(
          func(objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayOtro),
        );
        break;
      case 40:
        changeArrayRepeticion(
          func(objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayRepeticion),
        );
        break;
      case 41:
        changeArrayPosturaIncomoda(
          func(
            objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPosturaIncomoda,
          ),
        );
        break;
      case 42:
        changeArrayFuerzaExcesiva(
          func(
            objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayFuerzaExcesiva,
          ),
        );
        break;
      case 43:
        changeArrayPosicionesEstacionarias(
          func(
            objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
              .arrayPosicionesEstacionarias,
          ),
        );
        break;
      case 44:
        changeArrayPresionDirecta(
          func(
            objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPresionDirecta,
          ),
        );
        break;
      case 45:
        changeArrayTemperaturasExtremas(
          func(
            objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
              .arrayTemperaturasExtremas,
          ),
        );
        break;
      case 46:
        changeArrayVibraciones(
          func(objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayVibraciones),
        );
        break;
      case 47:
        changeArrayExtresContacto(
          func(
            objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayExtresContacto,
          ),
        );
        break;
      case 48:
        changeArrayOtroErgonomico(
          func(objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayOtro),
        );
        break;
      case 49:
        changeArrayCargasTrabajoExcesivas(
          func(
            objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
              .arrayCargasTrabajoExcesivas,
          ),
        );
        break;
      case 50:
        changeArrayFaltaControlTrabajo(
          func(
            objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
              .arrayFaltaControlTrabajo,
          ),
        );
        break;
      case 51:
        changeArrayJornadasTrabajoSuperiores(
          func(
            objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
              .arrayJornadasTrabajoSuperiores,
          ),
        );
        break;
      case 52:
        changeArrayRotacionTurnos(
          func(
            objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayRotacionTurnos,
          ),
        );
        break;
      case 53:
        changeArrayPeriodosCortosRecuperacion(
          func(
            objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
              .arrayPeriodosCortosRecuperacion,
          ),
        );
        break;
      case 54:
        changeArrayInterferenciaRelacion(
          func(
            objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
              .arrayInterferenciaRelacion,
          ),
        );
        break;
      case 55:
        changeArrayLiderazgoNegativo(
          func(
            objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
              .arrayLiderazgoNegativo,
          ),
        );
        break;
      case 56:
        changeArrayHostigamiento(
          func(
            objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayHostigamiento,
          ),
        );
        break;
      case 57:
        changeArrayAcoso(
          func(objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayAcoso),
        );
        break;
      case 58:
        changeArrayMalosTratos(
          func(
            objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayMalosTratos,
          ),
        );
        break;
      case 59:
        changeArrayOtroPsicosocial(
          func(objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayOtro),
        );
        break;
      default:
        break;
    }
  };
  // guarda la información de los taps
  const saveTiempoSeleccionComentarios = (
    valorTiempoSeleccionComentarios: any,
    grupoSelector: number,
  ) => {
    // aqui va a ir el id que retorno la bd
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendAntecedentesLaboralesExposicionLista(
          getDataToSend(valorTiempoSeleccionComentarios, grupoSelector),
        ),
        successFunction: (result: any) => {
          const { idExposicion, idExposicionLista: idBd, fechaCreacion } = result.data;
          setObjExposicionRiesgo({ ...objExposicionRiesgo, id: idExposicion });
          const newAntLaborales = [...antecedentesLaborales];
          const index = antecedentesLaborales.findIndex(
            (a) => a.objTabEmpresa.id === objTabEmpresa.id,
          );
          newAntLaborales[index] = {
            ...newAntLaborales[index],
            objExposicionRiesgo,
          };
          setAntecedentesLaborales(newAntLaborales);
          const agregar = (
            array:
            | ITiempoSeleccionComentariosFecha[]
            | IDbTiempoSeleccionComentariosFecha[]
            | IObjComentariosFecha[],
          ) => {
            const newArray = array;
            newArray.unshift({ ...valorTiempoSeleccionComentarios, fechaCreacion, id: idBd });
            return newArray;
          };
          changeArray(grupoSelector, agregar);
          // peticionActualizacionLista();
        },
      }),
    );
  };
  // actualiza la información que se necesita
  const actualizaTiempoSeleccionComentarios = (
    valorTiempoSeleccionComentarios: any,
    grupoSelector: number,
    index: number,
  ) => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendAntecedentesLaboralesExposicionLista(
          getDataToSend(valorTiempoSeleccionComentarios, grupoSelector),
        ),
        successFunction: (result: any) => {
          const { fechaCreacion } = result.data;
          const actualizar = (
            array:
            | ITiempoSeleccionComentariosFecha[]
            | IDbTiempoSeleccionComentariosFecha[]
            | IObjComentariosFecha[],
          ) => {
            const newArray = array;
            newArray[index] = { ...valorTiempoSeleccionComentarios, fechaCreacion };
            return newArray;
          };
          changeArray(grupoSelector, actualizar);
        },
      }),
    );
  };
  const getDeleteFunc = (idExposicionLista: number) => sendEliminarAntecedentesLaboralesExposicionLista({
    idPaciente,
    idMedico,
    idConsultorio,
    idExposicionLista,
  });
  // elimina la información de los taps
  const borraTiempoSeleccionComentarios = (grupoSelector: number, index: number) => {
    const newAntLaborales = [...antecedentesLaborales];
    const indx = antecedentesLaborales.findIndex((a) => a.objTabEmpresa.id === objTabEmpresa.id);
    let newArrayTSC: ITiempoSeleccionComentariosFecha[];
    let newArrayDBTSC: IDbTiempoSeleccionComentariosFecha[];
    let newArrayQTSC: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[];
    let newArrayOC: IObjComentariosFecha[];
    switch (grupoSelector) {
      case 1:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasAltas;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayTemperaturasAltas(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasAltas = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 2:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasBajas;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayTemperaturasBajas(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasBajas = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 3:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionIonizante;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayRadiacionIonizante(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionIonizante = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 4:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionNoIonizante;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayRadiacionNoIonizante(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionNoIonizante = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 5:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVentilacion;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayVentilacion(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVentilacion = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 6:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayIluminacion;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayIluminacion(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayIluminacion = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 7:
        newArrayDBTSC = objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRuido;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayDBTSC[index].id),
            successFunction: () => {
              newArrayDBTSC.splice(index, 1);
              changeArrayRuido(newArrayDBTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRuido = newArrayDBTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 8:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVibracion;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayVibracion(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVibracion = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 9:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayFluidoElectrico;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayFluidoElectrico(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayFluidoElectrico = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 10:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayOtro;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayOtroFisico(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayOtro = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 11:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtrapamiento;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayAtrapamiento(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtrapamiento = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 12:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
          .arrayAtropellamientosVehiculos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayAtropellamientosVehiculos(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtropellamientosVehiculos = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 13:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidaObjetos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayCaidaObjetos(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidaObjetos = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 14:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasMismoNivel;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayCaidasMismoNivel(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasMismoNivel = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 15:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasDiferenteNivel;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayCaidasDiferenteNivel(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidasDiferenteNivel = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 16:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
          .arrayContactoSuperficiesTrabajo;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayContactoSuperficiesTrabajo(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayContactoSuperficiesTrabajo = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 17:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayChoques;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayChoques(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayChoques = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 18:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayContactoElectrico;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayContactoElectrico(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayContactoElectrico = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 19:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCortes;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayCortes(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCortes = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 20:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayProyeccionFragmentos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayProyeccionFragmentos(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayProyeccionFragmentos = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 21:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayProyeccionFluidos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayProyeccionFluidos(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayProyeccionFluidos = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 22:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayPinchazos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayPinchazos(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayPinchazos = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 23:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayOtro;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayOtroMecanico(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayOtro = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 24:
        newArrayQTSC = objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayAerosoles;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayQTSC[index].id),
            successFunction: () => {
              newArrayQTSC.splice(index, 1);
              changeArrayAerosoles(newArrayQTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayAerosoles = newArrayQTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 25:
        newArrayQTSC = objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayHumos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayQTSC[index].id),
            successFunction: () => {
              newArrayQTSC.splice(index, 1);
              changeArrayHumos(newArrayQTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayHumos = newArrayQTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 26:
        newArrayQTSC = objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayGaseosos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayQTSC[index].id),
            successFunction: () => {
              newArrayQTSC.splice(index, 1);
              changeArrayGaseosos(newArrayQTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayGaseosos = newArrayQTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 27:
        newArrayQTSC = objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayLiquidos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayQTSC[index].id),
            successFunction: () => {
              newArrayQTSC.splice(index, 1);
              changeArrayLiquidos(newArrayQTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayLiquidos = newArrayQTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 28:
        newArrayQTSC = objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arraySolidos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayQTSC[index].id),
            successFunction: () => {
              newArrayQTSC.splice(index, 1);
              changeArraySolidos(newArrayQTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arraySolidos = newArrayQTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 29:
        newArrayQTSC = objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayNeblinas;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayQTSC[index].id),
            successFunction: () => {
              newArrayQTSC.splice(index, 1);
              changeArrayNeblinas(newArrayQTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayNeblinas = newArrayQTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 30:
        newArrayQTSC = objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayPolvos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayQTSC[index].id),
            successFunction: () => {
              newArrayQTSC.splice(index, 1);
              changeArrayPolvos(newArrayQTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayPolvos = newArrayQTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 31:
        newArrayQTSC = objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayVapores;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayQTSC[index].id),
            successFunction: () => {
              newArrayQTSC.splice(index, 1);
              changeArrayVapores(newArrayQTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayVapores = newArrayQTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 32:
        newArrayQTSC = objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayOtro;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayQTSC[index].id),
            successFunction: () => {
              newArrayQTSC.splice(index, 1);
              changeArrayOtroQuimico(newArrayQTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayOtro = newArrayQTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 33:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayAnimales;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayAnimales(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayAnimales = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 34:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayBacterias;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayBacterias(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayBacterias = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 35:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayHongos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayHongos(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayHongos = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 36:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayParasitos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayParasitos(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayParasitos = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 37:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVectores;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayVectores(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVectores = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 38:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVirus;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayVirus(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVirus = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 39:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayOtro;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayOtroBiologico(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayOtro = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 40:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayRepeticion;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayRepeticion(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayRepeticion = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 41:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPosturaIncomoda;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayPosturaIncomoda(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPosturaIncomoda = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 42:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayFuerzaExcesiva;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayFuerzaExcesiva(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayFuerzaExcesiva = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 43:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
          .arrayPosicionesEstacionarias;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayPosicionesEstacionarias(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPosicionesEstacionarias = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 44:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPresionDirecta;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayPresionDirecta(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayPresionDirecta = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 45:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
          .arrayTemperaturasExtremas;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayTemperaturasExtremas(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayTemperaturasExtremas = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 46:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayVibraciones;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayVibraciones(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayVibraciones = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 47:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayExtresContacto;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayExtresContacto(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayExtresContacto = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 48:
        newArrayTSC = objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayOtro;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayTSC[index].id),
            successFunction: () => {
              newArrayTSC.splice(index, 1);
              changeArrayOtroErgonomico(newArrayTSC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayOtro = newArrayTSC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 49:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
          .arrayCargasTrabajoExcesivas;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayCargasTrabajoExcesivas(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayCargasTrabajoExcesivas = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 50:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
          .arrayFaltaControlTrabajo;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayFaltaControlTrabajo(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayFaltaControlTrabajo = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 51:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
          .arrayJornadasTrabajoSuperiores;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayJornadasTrabajoSuperiores(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayJornadasTrabajoSuperiores = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 52:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayRotacionTurnos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayRotacionTurnos(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayRotacionTurnos = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 53:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
          .arrayPeriodosCortosRecuperacion;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayPeriodosCortosRecuperacion(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayPeriodosCortosRecuperacion = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 54:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
          .arrayInterferenciaRelacion;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayInterferenciaRelacion(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayInterferenciaRelacion = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 55:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
          .arrayLiderazgoNegativo;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayLiderazgoNegativo(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayLiderazgoNegativo = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 56:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayHostigamiento;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayHostigamiento(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayHostigamiento = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 57:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayAcoso;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayAcoso(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayAcoso = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 58:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayMalosTratos;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayMalosTratos(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayMalosTratos = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      case 59:
        newArrayOC = objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayOtro;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: getDeleteFunc(newArrayOC[index].id),
            successFunction: () => {
              newArrayOC.splice(index, 1);
              changeArrayOtroPsicosocial(newArrayOC);
              newAntLaborales[
                indx
              ].objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayOtro = newArrayOC;
              setAntecedentesLaborales(newAntLaborales);
            },
          }),
        );
        break;
      default:
        break;
    }
  };
  const actualizarAccidentesLaborales = (
    newArrayAccidentesLaborales: IObjArrayAccidentesLaborales[],
  ) => {
    setObjLaboralAccidente({
      ...objLaboralAccidente,
      arrayAccidentesLaborales: newArrayAccidentesLaborales,
    });
    const newAntLaborales = [...antecedentesLaborales];
    const index = antecedentesLaborales.findIndex((a) => a.objTabEmpresa.id === objTabEmpresa.id);
    newAntLaborales[index] = {
      ...newAntLaborales[index],
      objLaboral: {
        ...objLaboralAccidente,
        arrayAccidentesLaborales: newArrayAccidentesLaborales,
      },
    };
    setAntecedentesLaborales(newAntLaborales);
    setObjArrayAccidentesLaborales(arrayAccidentesLaboralesInitial);
    // peticionActualizacionLista();
  };
  const actualizarArrayAccidentesLaborales = (
    tipo: 'agregar' | 'actualizar' | 'borrar',
    inx: number,
  ) => {
    const newArrayAccidentesLaborales = [...objLaboralAccidente.arrayAccidentesLaborales];
    if (tipo === 'borrar') {
      dispatch(
        setRequest({
          type: 'delete',
          requestFunction: sendAntecedentesEliminarAccidenteLaboral({
            idPaciente,
            idMedico,
            idConsultorio,
            idAccidenteLista: newArrayAccidentesLaborales[inx].id,
          }),
          successFunction: () => {
            newArrayAccidentesLaborales.splice(inx, 1);
            actualizarAccidentesLaborales(newArrayAccidentesLaborales);
          },
        }),
      );
    } else {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: sendAntecedentesAccidenteObjLaboral({
            idPaciente,
            idMedico,
            idConsultorio,
            idEmpresa: objTabEmpresa.id,
            idLaboralAccidente: objLaboralAccidente.idLaboralAccidente,
            ...objArrayAccidentesLaborales,
          }),
          successFunction: (result: any) => {
            if (tipo === 'agregar') {
              newArrayAccidentesLaborales.push({
                ...objArrayAccidentesLaborales,
                id: result.data.id,
              });
            } else if (tipo === 'actualizar') {
              newArrayAccidentesLaborales[inx] = {
                ...objArrayAccidentesLaborales,
                id: result.data.id,
              };
            }
            actualizarAccidentesLaborales(newArrayAccidentesLaborales);
          },
        }),
      );
    }
  };

  /* eslint-disable no-nested-ternary */
  // funcion que se encarga de cambiar los check de los selectores
  const allRadioEstatus = (allNo: boolean, negadas: string[], seleccionado: string = '') => {
    const value = allNo ? 0 : -1;
    setObjExposicionRiesgo({
      ...objExposicionRiesgo,
      allNo,
      grupoSelectores: {
        ...objExposicionRiesgo.grupoSelectores,
        fisico: {
          ...objExposicionRiesgo.grupoSelectores.fisico,
          seleccionado:
            evalAnyRiesgo('fisico') || seleccionado === 'fisico'
              ? 1
              : negadas.includes('fisico')
                ? 0
                : value,
        },
        mecanico: {
          ...objExposicionRiesgo.grupoSelectores.mecanico,
          seleccionado:
            evalAnyRiesgo('mecanico') || seleccionado === 'mecanico'
              ? 1
              : negadas.includes('mecanico')
                ? 0
                : value,
        },
        quimico: {
          ...objExposicionRiesgo.grupoSelectores.quimico,
          seleccionado:
            evalAnyRiesgo('quimico') || seleccionado === 'quimico'
              ? 1
              : negadas.includes('quimico')
                ? 0
                : value,
        },
        biologico: {
          ...objExposicionRiesgo.grupoSelectores.biologico,
          seleccionado:
            evalAnyRiesgo('biologico') || seleccionado === 'biologico'
              ? 1
              : negadas.includes('biologico')
                ? 0
                : value,
        },
        ergonomico: {
          ...objExposicionRiesgo.grupoSelectores.ergonomico,
          seleccionado:
            evalAnyRiesgo('ergonomico') || seleccionado === 'ergonomico'
              ? 1
              : negadas.includes('ergonomico')
                ? 0
                : value,
        },
        psicosocial: {
          ...objExposicionRiesgo.grupoSelectores.psicosocial,
          seleccionado:
            evalAnyRiesgo('psicosocial') || seleccionado === 'psicosocial'
              ? 1
              : negadas.includes('psicosocial')
                ? 0
                : value,
        },
      },
    });
  };
  // funcion para actualizar los riesgos negados en la bd
  const updateExposicionesNegadas = (
    checkAll: boolean,
    negadas: string[],
    seleccionado: string = '',
  ) => {
    const allNo = negadas.length === 6 || checkAll;
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendExposicionesNegadas({
          idEmpresa: objTabEmpresa.id,
          idExposicion: objExposicionRiesgo.id,
          negadas,
          idPaciente,
          idMedico,
          idConsultorio,
        }),
        successFunction: (result: any) => {
          setObjExposicionRiesgo({
            ...objExposicionRiesgo,
            id: result.data.id,
            allNo,
            negadas,
          });
          showSuccessMessage('message_get_success');
          const newAntLaborales = [...antecedentesLaborales];
          const index = antecedentesLaborales.findIndex(
            (a) => a.objTabEmpresa.id === objTabEmpresa.id,
          );
          newAntLaborales[index] = {
            ...newAntLaborales[index],
            objExposicionRiesgo: {
              ...objExposicionRiesgo,
              id: result.data.id,
              allNo,
              negadas,
            },
          };
          // peticionActualizacionLista();
          allRadioEstatus(allNo, negadas, seleccionado);
        },
      }),
    );
  };
  // funcion para actualizar el radio de accidente laboral
  const updateAccidenteLaboralRadio = (valRadio: string) => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendAccidenteLaboralRadio({
          idPaciente,
          idMedico,
          idConsultorio,
          idEmpresa: objTabEmpresa.id,
          idLaboralAccidente: objLaboralAccidente.idLaboralAccidente,
          radioLaboral: valRadio,
        }),
        successFunction: (result: any) => {
          setObjLaboralAccidente({
            ...objLaboralAccidente,
            idLaboralAccidente: result.data.id,
            radioLaboral: result.data.radioLaboral,
          });
          const newAntLaborales = [...antecedentesLaborales];
          const index = antecedentesLaborales.findIndex(
            (a) => a.objTabEmpresa.id === objTabEmpresa.id,
          );
          newAntLaborales[index] = {
            ...newAntLaborales[index],
            objLaboral: {
              ...objLaboralAccidente,
              idLaboralAccidente: result.data.id,
              radioLaboral: result.data.radioLaboral,
            },
          };
          // peticionActualizacionLista();
        },
      }),
    );
  };
  useEffect(() => {
    dispatch(setLoading(true));
    const fetchTipoOcupacion = getCatalogoTipoOcupacion();
    const fetchLugarAccidente = getCatalogoAccidentesLugar();
    const fetchTurnoAccidente = getCatalogoAccidentesTurno();
    const fetchCausaAccidente = getCatalogoAccidentesCausa();
    const fetchNaturalezaLesion = getCatalogoAccidentesLesionNaturaleza();
    const fetchSegmentoLesionado = getCatalogoAccidentesLesionSegmento();
    const fetchUbicacionLesion = getCatalogoAccidentesLesionUbicacion();
    const fetchGravedad = getCatalogoAccidentesLesionGravedad();
    const fetchPorcentajeIncapacidad = getCatalogoAccidentesPorcentajeIncapacidad();
    const fetchTipoRiesgo = getCatalogoIncapacidadesTipoRiesgo();
    const fetchQuimicos = getCatalogoQuimicos();
    // const fetchListaAntecedentes = getListaAntecedentesLaborales(idPaciente, idMedico);
    Promise.all([
      fetchTipoOcupacion,
      fetchLugarAccidente,
      fetchTurnoAccidente,
      fetchCausaAccidente,
      fetchNaturalezaLesion,
      fetchSegmentoLesionado,
      fetchUbicacionLesion,
      fetchGravedad,
      fetchPorcentajeIncapacidad,
      fetchTipoRiesgo,
      fetchQuimicos,
      // fetchListaAntecedentes,
    ])
      .then((responses) => {
        Promise.all(responses).then((results) => {
          setDatosTipoOcupacion(results[0] || []);
          setDatosLugarAccidente(results[1] || []);
          setDatosTurnoAccidente(results[2] || []);
          setDatosCausaAccidente(results[3] || []);
          setDatosNaturalezaLesion(results[4] || []);
          setDatosSegmentoLesionado(results[5] || []);
          setDatosUbicacionLesion(results[6] || []);
          setDatosGravedad(results[7] || []);
          setDatosPorcentajeIncapacidad(results[8] || []);
          setDatosTipoRiesgo(results[9] || []);
          setDatosQuimicos(results[10] || []);
          // setAntecedentesLaborales(results[5] || []);
          dispatch(setLoading(false));
          showSuccessMessage('message_get_success');
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-get-error')} ${err.toString()}`,
          }),
        );
      });
  }, []);
  useEffect(() => {
    if (antecedentesLaborales.length > 0) {
      setDisabledCheck(true);
    }
  }, [antecedentesLaborales]);
  return (
    <div>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pnp-historias-eliminar')}
        callBackAceptar={() => eliminaListaValores(inxArraySeleccionado)}
        callBackClose={() => setDeleteAlert(false)}
      />
      <div className="pb-4">
        <TabEmpresa
          nombreEmpresa={nombreEmpresa}
          datosTipoOcupacion={datosTipoOcupacion}
          datosSelector={consultorios}
          objTabEmpresa={objTabEmpresa}
          setObjTabEmpresa={setObjTabEmpresa}
          guardarEmpresa={guardarEmpresa}
          actualizarEmpresa={actualizarEmpresa}
          peticionLimpiarPantalla={peticionLimpiarPantalla}
        />
      </div>
      {objTabEmpresa && objTabEmpresa.guardadoOk && (
        <>
          <div className="pb-4">
            <TabExposicion
              objExposicionRiesgo={objExposicionRiesgo}
              setObjExposicionRiesgo={setObjExposicionRiesgo}
              datosLugarAccidente={datosLugarAccidente}
              datosTurnoAccidente={datosTurnoAccidente}
              datosCausaAccidente={datosCausaAccidente}
              datosNaturalezaLesion={datosNaturalezaLesion}
              datosSegmentoLesionado={datosSegmentoLesionado}
              datosUbicacionLesion={datosUbicacionLesion}
              datosGravedad={datosGravedad}
              datosPorcentajeIncapacidad={datosPorcentajeIncapacidad}
              datosQuimicos={datosQuimicos}
              evalAnyRiesgo={evalAnyRiesgo}
              updateExposicionesNegadas={updateExposicionesNegadas}
              saveTiempoSeleccionComentarios={saveTiempoSeleccionComentarios}
              actualizaTiempoSeleccionComentarios={actualizaTiempoSeleccionComentarios}
              borraTiempoSeleccionComentarios={borraTiempoSeleccionComentarios}
            />
          </div>
          <TabLaboral
            datosLugarAccidente={datosLugarAccidente}
            datosTurnoAccidente={datosTurnoAccidente}
            datosCausaAccidente={datosCausaAccidente}
            datosNaturalezaLesion={datosNaturalezaLesion}
            datosSegmentoLesionado={datosSegmentoLesionado}
            datosUbicacionLesion={datosUbicacionLesion}
            datosGravedad={datosGravedad}
            datosPorcentajeIncapacidad={datosPorcentajeIncapacidad}
            datosTipoRiesgo={datosTipoRiesgo}
            objLaboral={objLaboralAccidente}
            objArrayAccidentesLaborales={objArrayAccidentesLaborales}
            setObjArrayAccidentesLaborales={setObjArrayAccidentesLaborales}
            actualizarArrayAccidentesLaborales={actualizarArrayAccidentesLaborales}
            updateAccidenteLaboralRadio={updateAccidenteLaboralRadio}
          />
        </>
      )}
      {antecedentesLaborales.length > 0 && (
        <div className="pt-4">
          <div className="border border-solid border-gray-300 rounded p-4">
            <h2 className="text-blue-500 font-medium m-0 pb-4">
              {t('antecedentes_laborales_lista')}
            </h2>
            {antecedentesLaborales.map((valorArray, inx) => (
              <ListaAntecedentesLaborales
                objTabEmpresa={valorArray.objTabEmpresa}
                objExposicionRiesgo={valorArray.objExposicionRiesgo}
                objLaboral={valorArray.objLaboral}
                idEmpresa={objTabEmpresa.id}
                inx={inx}
                actualizaListaValores={actualizaListaValores}
                eliminaListaValores={openDeleteDialog}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FormAntecedentesLaborales;
