import { combineReducers } from 'redux';
import LoginReducer from './LoginReducer';
import MeReducer from './MeReducer';
import BasicosPacienteReducer from './DatosBasicosPaciente';
import LoadingReducer from './LoadingReducer';
import CheckSave from './checkSaves';
import SnackReducer from './SnackBar';
import Consultorios from './consultorios';
import Organizaciones from './organizaciones';
import Consulta from './consulta';
import Auth from './Auth/auth';
import RequestReducer from './Request';
import RequestFileReducer from './RequestFile';

export const rootReducer = combineReducers({
  Me: MeReducer,
  Login: LoginReducer,
  BasicosPaciente: BasicosPacienteReducer,
  Loading: LoadingReducer,
  CheckSave,
  SnackBar: SnackReducer,
  Request: RequestReducer,
  RequestFile: RequestFileReducer,
  Consultorios,
  Organizaciones,
  Consulta,
  Auth,
});

export type RootState = ReturnType<typeof rootReducer>;
