/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IVerDetalleMedicamentoProps } from './types';

function VerDetalleMedicamento({
  open,
  handleDetalleClose,
  medicamentoAVerDetalle,
}: IVerDetalleMedicamentoProps) {
  const { t } = useTranslation();
  const {
    tipo,
    nombreDistintivo,
    nombreGenerico,
    medTextoLibre,
    via,
    cantidad,
    unidad,
    frecuencia,
    otraFrecuencia,
    duracion,
    periodo,
    enfermedad,
    instruccionesAdicionales,
    fechaReceta,
    fechaFinTratamiento,
    presentacion,
    alertas,
  } = medicamentoAVerDetalle;

  const titulo = () => {
    let jsx;
    switch (tipo) {
      case 'nombre_generico':
        jsx = <h3 className="font-normal m-0 text-blue-800">{nombreGenerico.label}</h3>;
        break;
      case 'denominacion_distintiva':
        jsx = (
          <>
            <h3 className="font-normal m-0 text-blue-800">{nombreDistintivo.label}</h3>
            <h4 className="font-normal m-0 text-gray-500">{nombreGenerico.label}</h4>
          </>
        );
        break;
      case 'texto_libre':
        jsx = <h3 className="font-normal m-0 text-blue-800">{medTextoLibre}</h3>;
        break;
      default:
        jsx = <h3 className="font-normal m-0 text-blue-800">Sin información</h3>;
        break;
    }
    return jsx;
  };

  const descripcion = () => (
    <p className="text-gray-500">
      <span className="text-gray-900">{via.impresion}</span>
      {` ${cantidad} ${unidad.label} ${frecuencia.label || otraFrecuencia} ${t(
        'farma_por',
      )} ${duracion} ${periodo.label}`}
    </p>
  );

  const diagnosticoAsociado = () => (
    <p className="text-gray-500">
      <span className="text-gray-900">{`${t('diagnostico_s_asociado_s')}:`}</span>
      {` ${enfermedad.label}`}
    </p>
  );

  const colorAlergia = (grado: number) => {
    let color = '';
    switch (grado) {
      case 1:
      case 2:
        color = 'font-normal m-0 text-yellow-300';
        break;
      case 3:
        color = 'font-normal m-0 text-yellow-600';
        break;
      case 4:
      case 5:
        color = 'font-normal m-0 text-red-600';
        break;
      default:
        color = 'font-normal m-0';
        break;
    }
    return color;
  };
  return (
    <Dialog open={open} onClose={handleDetalleClose} scroll="paper" fullWidth maxWidth="md">
      <div className="flex justify-between items-start text-blue-800 pl-4">
        <div className="mt-4">
          <div className="text-lg">{titulo()}</div>
          {descripcion()}
          {!!enfermedad.id && diagnosticoAsociado()}
          {instruccionesAdicionales && (
            <p className="text-gray-500">
              <span className="text-gray-900">{`${t('instrucciones_adicionales')}:`}</span>
              &nbsp;
              {instruccionesAdicionales}
            </p>
          )}
          <p className="text-gray-500">
            <span className="text-gray-900">{`${t('fecha_inicio_tratamiento')}:`}</span>
            &nbsp;
            {dayjs(fechaReceta).format('YYYY-MMM-DD')}
          </p>
          <p className="text-gray-500">
            <span className="text-gray-900">{`${t('fecha_estimada_fin_tratamiento')}:`}</span>
            &nbsp;
            {dayjs(fechaFinTratamiento).format('YYYY-MMM-DD')}
          </p>
          {presentacion && (
            <p className="text-gray-500">
              <span className="text-gray-900">{`${t('presentacion')}:`}</span>
              &nbsp;
              {presentacion}
            </p>
          )}
        </div>
        <IconButton aria-label="cerrar" color="inherit" onClick={handleDetalleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers>
        {!!alertas.alergias.length && (
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <h3 className="m-0 font-normal text-gray-800 mb-2">
              {t('analisis_de_la_prescripcion')}
            </h3>
            {alertas.alergias.map((ale) => (
              <div>
                <span className="text-red-500">{`${t('alergia')}:`}</span>
                &nbsp;
                <span>{ale.nombreAlerta}</span>
                &nbsp;
                <span className={colorAlergia(ale.grado)}>{`${t('grado')} ${ale.grado}`}</span>
              </div>
            ))}
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default VerDetalleMedicamento;
