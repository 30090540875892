import { ICatalogosEstandar } from '../../common/types';
import { IConsultorio } from '../../containers/MedicoInformacion/MisConsultorios/types';

export const SET_CONSULTORIOS = 'SET_CONSULTORIOS';
export const SET_CONSULTORIO = 'SET_CONSULTORIO';
export const RESET_CONSULTORIOS = 'RESET_CONSULTORIOS';
export const RESET_CONSULTORIO = 'RESET_CONSULTORIO';
export const GET_CONSULTORIOS = 'GET_CONSULTORIOS';
export const GET_CONSULTORIO = 'GET_CONSULTORIO';

export interface IRXConsultorios {
  consultorios: ICatalogosEstandar[];
  consultorio: IConsultorio;
}

interface SetConsultorioAction {
  type: typeof SET_CONSULTORIO;
  payload: IConsultorio;
}

interface SetConsultoriosAction {
  type: typeof SET_CONSULTORIOS;
  payload: ICatalogosEstandar[];
}

interface ResetConsultoriosAction {
  type: typeof RESET_CONSULTORIOS;
}

interface ResetConsultorioAction {
  type: typeof RESET_CONSULTORIO;
}

export type ConsultoriosActionTypes =
  | SetConsultorioAction
  | SetConsultoriosAction
  | ResetConsultorioAction
  | ResetConsultoriosAction;
