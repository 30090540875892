import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { ICancelarSubscripcionProps } from './types';
import { regexPassword } from '../../../common/functions';
import { RootState } from '../../../store';
import loading from '../../../actions/loading/actions';
import { setSnackComplete } from '../../../actions/snackbar/types';
import { str2AB, str2ab, arrayBufferToBase64 } from '../../../common/base64Convert';
import { cancelarSubscripcionConekta } from '../../../utils/sendInfo';
import { getPublicKey } from '../../../utils/getCatalogos';
import useStyles from '../../../common/styles';

function CancelarSubscripcion({ customer }: ICancelarSubscripcionProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id: email } = useSelector((state: RootState) => state.Auth);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const [verPassword, setVerPassword] = useState<boolean>(false);
  const [errorContrasenia, setErrorContrasenia] = useState({ error: false, msg: '' });
  const [contrasenia, setContrasenia] = useState<string>('');
  const [seleccionMotivo, setSeleccionMotivo] = useState<string>('');
  const [textoMotivo, setTextoMotivo] = useState<string>('');
  const [keyBack, setKeyBack] = useState<string>('');
  const handleChangeMotivoCancelar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeleccionMotivo((event.target as HTMLInputElement).value);
    setTextoMotivo('');
  };
  const handleChangeContrasenia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setContrasenia(val);
    setErrorContrasenia({ error: false, msg: '' });
  };

  const handleClickVerPassword = () => {
    setVerPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangeTextoMotivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextoMotivo(event.target.value);
  };

  const handleCancelarSuscripcion = async () => {
    dispatch(loading(true));
    // Headers de llaves PEM
    const pemHeader = '-----BEGIN PUBLIC KEY-----';
    const pemFooter = '-----END PUBLIC KEY-----';
    // Importada la key desde el back
    const pemContents = keyBack.substring(pemHeader.length, keyBack.length - pemFooter.length);
    const binaryDerString = window.atob(pemContents);
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
    // contraseña encriptada
    const contraseniaC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(contrasenia),
    );
    cancelarSubscripcionConekta({
      idMedico,
      customer,
      password: arrayBufferToBase64(contraseniaC),
      email,
      seleccionMotivo,
      textoMotivo,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: `${t(
                'su_subscripcion_ha_sido_cancelada_correctamente',
              )}. Numero de operacion: ${result.data.charge_id}`,
            }),
          );
          history.goBack();
        } else if (result.code === 408) {
          setErrorContrasenia({ error: true, msg: result.msg });
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-general')} ${result.msg}`,
            }),
          );
          dispatch(loading(false));
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-general')} Error de Conekta ${result.detail}`,
            }),
          );
          dispatch(loading(false));
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-general')} Error de Conekta ${err.toString()}`,
          }),
        );
        dispatch(loading(false));
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    getPublicKey(signal).then((result: string) => setKeyBack(result));
    return () => abortController.abort();
  }, []);

  return (
    <>
      <div className="pb-4 mt-4">
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            history.goBack();
          }}
        >
          {t('regresar')}
        </Button>
      </div>
      <div className="py-4 shadow-lg bg-white rounded-lg border border-solid border-gray-200">
        <div className="container mx-auto w-10/12">
          <div>
            <h1 className="text-blue-800 font-normal mt-0">
              {t('tu_cuenta')}
              &nbsp; | &nbsp;
              {t('cancelacion_de_suscripcion')}
            </h1>
            <p className="font-medium text-gray-600">{t('cancelar_suscripcion_parrafo_1')}</p>
            <p className="font-medium text-gray-600">{t('cancelar_suscripcion_parrafo_2')}</p>
            <p className="font-medium text-red-500 mb-1">{t('cancelar_suscripcion_parrafo_3')}</p>
            <div className="flex justify-between items-center">
              <p className="font-medium text-red-500 m-0">{t('cancelar_suscripcion_parrafo_4')}</p>
              <Button startIcon={<MailIcon />} color="primary" href="mailto:hola@medipraxi.com">
                hola@medipraxi.com
              </Button>
              <Button startIcon={<PhoneIcon />} color="primary" href="tel:+525589360678">
                (55)8936 0678 - (55)8936 0654
              </Button>
            </div>
          </div>
          <hr />
          <div>
            <h3 className="font-medium text-gray-600">
              {t('_cual_es_el_motivo_de_la_cancelacion_del_servicio_')}
            </h3>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="motivo-cancelar"
                name="motivo-cancelar"
                value={seleccionMotivo}
                onChange={handleChangeMotivoCancelar}
              >
                <FormControlLabel
                  value="T"
                  control={<Radio color="primary" />}
                  label={
                    <div className="my-4">{t('dejare_de_pagar_por_un_tiempo_y_regresare')}</div>
                  }
                />
                <FormControlLabel
                  value="E"
                  control={<Radio color="primary" />}
                  label={<div className="my-4">{t('no_entendi_como_usar_el_sistema')}</div>}
                />
                <FormControlLabel
                  value="P"
                  control={<Radio color="primary" />}
                  label={
                    <div className="my-4">{t('se_me_hizo_excesivo_el_precio_del_sistema')}</div>
                  }
                />
                <FormControlLabel
                  value="N"
                  control={<Radio color="primary" />}
                  label={(
                    <div className="my-4">
                      {t('no_quiero_informar_el_motivo_de_mi_cancelacion')}
                    </div>
                  )}
                />
                <FormControlLabel
                  value="C"
                  control={<Radio color="primary" />}
                  label={
                    seleccionMotivo === 'C' ? (
                      <div className="flex items-center">
                        {t('necesito_hacer_un_cambio_de_sistema')}
                        <TextField
                          style={{ marginLeft: '2rem' }}
                          variant="outlined"
                          placeholder={t('_cual_')}
                          value={textoMotivo}
                          onChange={handleChangeTextoMotivo}
                          InputProps={{
                            inputProps: {
                              autoComplete: 'off',
                              'aria-autocomplete': 'none',
                              maxLength: 80,
                            },
                          }}
                        />
                      </div>
                    ) : (
                      <div className="my-4">{t('necesito_hacer_un_cambio_de_sistema')}</div>
                    )
                  }
                />
                <FormControlLabel
                  value="O"
                  control={<Radio color="primary" />}
                  label={
                    seleccionMotivo === 'O' ? (
                      <div className="flex items-center">
                        {t('otra')}
                        <TextField
                          style={{ marginLeft: '2rem' }}
                          variant="outlined"
                          placeholder={t('_motivo_')}
                          value={textoMotivo}
                          onChange={handleChangeTextoMotivo}
                          InputProps={{
                            inputProps: {
                              autoComplete: 'off',
                              'aria-autocomplete': 'none',
                              maxLength: 80,
                            },
                          }}
                        />
                      </div>
                    ) : (
                      <div className="my-4">{t('otra')}</div>
                    )
                  }
                />
              </RadioGroup>
            </FormControl>
            <h3 className="text-gray-600 font-medium">
              {t('para_cancelar_tu_servicio_coloca_tu_contrasenia')}
            </h3>
            <h3 className="text-gray-400 font-normal">{email}</h3>
            <div className="flex justify-between items-center">
              <TextField
                variant="outlined"
                type={verPassword ? 'text' : 'password'}
                value={contrasenia}
                placeholder={t('escribir_contrasenia')}
                error={errorContrasenia.error}
                helperText={errorContrasenia.msg}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
                onChange={handleChangeContrasenia}
                InputProps={{
                  endAdornment: (
                    <div className="text-blue-500">
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickVerPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="inherit"
                        >
                          {verPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                  inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: '50' },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={
                  contrasenia.length < 8 || !regexPassword(contrasenia) || seleccionMotivo === ''
                }
                onClick={handleCancelarSuscripcion}
              >
                {t('cancelar_suscripcion')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CancelarSubscripcion;
