import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  noTextTranform: {
    textTransform: 'none',
  },
  header: {
    backgroundColor: 'rgb(59, 130, 246)',
    color: '#FFFFFF',
  },
  row: {
    backgroundColor: 'rgb(147, 197, 253)',
    color: '#111827',
  },
  root: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& > *': {
      margin: 0,
    },
  },
  iconButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 0,
    padding: 0,
  },
  input: {
    display: 'none',
    margin: 0,
    padding: 0,
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
}));

export default useStyles;
