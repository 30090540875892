import { Dispatch, SetStateAction } from 'react';
import { ICatalogosEstandar } from '../../../../../common/types';

export interface ITipoActividad {
  idTipo: ICatalogosEstandar;
  especifica: string;
  frecuencia: string;
  duracion: string;
}

export interface IActividadFisica {
  loaded: boolean;
  realiza: string;
  tipo: ITipoActividad[];
  comentarios: string;
}

export const actividadFisicaInitial: IActividadFisica = {
  loaded: false,
  realiza: '',
  tipo: [
    {
      idTipo: { id: 0, label: '' },
      especifica: '',
      frecuencia: '',
      duracion: '',
    },
  ],
  comentarios: '',
};

export interface IActividadFisicaProps {
  setActiveSubStep: Dispatch<
  SetStateAction<
  | 'vivienda'
  | 'higiene'
  | 'alimentacion'
  | 'actividad-fisica'
  | 'inmunizaciones'
  | 'toxicomania'
  | 'gineco-obstetricos'
  | 'historia-sexual'
  >
  >;
  actividadFisica: IActividadFisica;
  setActividadFisica: Dispatch<SetStateAction<IActividadFisica>>;
  cambiosUsuario: string[];
  actividadFisicaUsuario: IActividadFisica;
  setActividadFisicaUsuario: Dispatch<SetStateAction<IActividadFisica>>;
}
