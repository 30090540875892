import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    justifyContent: 'left',
    textTransform: 'none',
  },
  selected: {
    justifyContent: 'left',
    backgroundColor: '#1E467E',
    color: '#FFFFFF',
    textTransform: 'none',
  },
});

export default useStyles;
