import { RawDraftContentState } from 'react-draft-wysiwyg';
import { Dispatch, SetStateAction } from 'react';
import { ICatLaboratorio } from '../../../../../common/types';

export interface ILaboratorioSolicitado {
  idBd: number;
  selector: string;
  catLaboratorios: ICatLaboratorio;
  txtLibre: string;
  actualizado: boolean;
}

export interface ILaboratorioSolicitud {
  idSolicitud: number;
  laboratoriosArray: ILaboratorioSolicitado[];
  comentarios: RawDraftContentState;
}

export const laboratorioSolicitudInitial: ILaboratorioSolicitud = {
  idSolicitud: -1,
  laboratoriosArray: [
    {
      idBd: -1,
      selector: 'catalogo',
      catLaboratorios: {
        id: 0,
        idCat: 0,
        categoria: '',
        label: '',
      },
      txtLibre: '',
      actualizado: false,
    },
  ],
  comentarios: { entityMap: {}, blocks: [] },
};

export interface ILaboratorioProps {
  laboratorioSolicitud: ILaboratorioSolicitud[];
  setLaboratorioSolicitud: Dispatch<SetStateAction<ILaboratorioSolicitud[]>>;
}

export interface IArrayPdf {
  laboratoriosArray: ILaboratorioSolicitado[];
  comentarios: RawDraftContentState;
  labelPdf: string;
}
