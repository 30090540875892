export const SET_REQUEST_COMPLETE = 'SET_REQUEST_COMPLETE';

export interface IRequest {
  type: null | 'get' | 'send' | 'delete';
  multiple?: boolean;
  loading?: boolean;
  requestFunction?: Promise<Response>;
  requestFunctions?: Promise<Response>[];
  successFunction?: (result?: any) => void;
  successFunctions?: ((result?: any) => void)[];
  errorFunction?: (result?: any) => void;
  errorFunctions?: ((result?: any) => void)[];
  catchFunction?: () => void;
  alwaysFunction?: () => void;
  successMessage?: string;
  errorMessage?: string;
  toJson?: boolean | number[];
  showMsgs?: boolean;
  showMsgLoading?: boolean;
  showMsgSuccess?: boolean;
  showMsgError?: boolean;
}

interface SetComplete {
  type: typeof SET_REQUEST_COMPLETE;
  payload: IRequest;
}

export type RequestActionTypes = SetComplete;
