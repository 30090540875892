import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertDialogProps from './types';
import { ITextFieldEstandar } from '../../../common/types';
import { validacionEmail } from '../../../constants/validaciones';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function ValidarUsuarioCorreo(props: IAlertDialogProps) {
  const {
    open, email, setEmail, emailError, setEmailError, callBackAceptar, callBackClose,
  } = props;
  const { t } = useTranslation();

  const [emailField, setEmailField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });

  const handleChangeEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setEmail(val ? val.trim().toLowerCase() : '');
    setEmailField({ helperText: '', error: false });
    setEmailError(false);
  };

  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setEmailField({ helperText: '', error: false });
    } else {
      setEmailField({ helperText: t('email_incorrecto'), error: true });
    }
  };

  const handleAceptar = () => {
    if (!email.length || validacionEmail(email)) {
      callBackAceptar();
    } else {
      setEmailField({ helperText: t('email_incorrecto'), error: true });
    }
  };

  useEffect(() => {
    if (emailError) {
      setEmailField({ helperText: t('usuario_correo_no_valido'), error: true });
    }
  }, [emailError]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="validar-usuario-correo-titulo"
      aria-describedby="validar-usuario-correo-descripcion"
    >
      <div className="flex justify-between items-center bg-blue-500 text-white pl-4">
        <span className="text-lg">{t('validar_usuario')}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <DialogContentText>
          <p className="text-justify text-gray-500 mt-4">{t('validar_usuario_correo_mensaje_1')}</p>
          <p className="text-justify text-gray-500 mt-4">{t('validar_usuario_correo_mensaje_2')}</p>
          <div className="p-4 place-items-center">
            <div />
            <TextField
              variant="outlined"
              type="email"
              value={email}
              onChange={handleChangeEmail}
              onBlur={handleBlurEmail}
              fullWidth
              error={emailField.error}
              helperText={emailField.helperText}
              // FormHelperTextProps={{ className: classes.absoluteBottom }}
              inputProps={{
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: '100',
              }}
            />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex justify-center mb-2">
          <div className="mr-4">
            <Button onClick={callBackClose} color="primary" variant="outlined">
              {t('cancelar')}
            </Button>
          </div>
          <div>
            <Button
              onClick={handleAceptar}
              color="primary"
              variant="contained"
              disabled={emailField.error}
            >
              {t('aceptar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ValidarUsuarioCorreo;
