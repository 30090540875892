import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  rotateButton: {
    transform: 'rotate(90deg)',
  },
  textColorBlue: {
    color: '#2196f3',
  },
  textColorBlack: {
    color: '#6B7280',
  },
  noTextTranform: {
    textTransform: 'none',
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  header: {
    backgroundColor: 'rgb(59, 130, 246)',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  row: {
    backgroundColor: 'rgb(147, 197, 253)',
  },
  buttons: {
    textAlign: 'center',
    width: '100%',
  },
  backgroundWhite: {
    backgroundColor: 'white',
  },
});

export default useStyles;
