import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  Checkbox,
} from '@material-ui/core';
import dayjs from 'dayjs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { constDia, constMes, constAnio } from '../../../../../constants/fechas';
import useStyles from './styles';
import { getCatCie9 } from '../../../../../utils/getCatalogos';
import {
  ICatalogosEstandar,
  ICatStrEstandar,
  IFechaField,
  IFechaObj,
} from '../../../../../common/types';
import {
  validarFecha,
  validarFechaEsAnterior,
  valFechaToSave,
} from '../../../../../common/functions';
import { IProcedimientoProps } from './types';
import { RootState } from '../../../../../store';
import ProcedimientosComponente from './Procedimientos/index';
import DeleteDialog from '../../../../../components/DeleteDialog';
import { sendProcedimientos } from '../../../../../utils/sendInfo';
import { procedimientoEliminarProcedimiento } from '../../../../../utils/deleteInfo';
import { editProcedimientos } from '../../../../../utils/editInfo';
import ComentariosComponente from '../../../../../components/Comentarios';
import { setSnackComplete } from '../../../../../actions/snackbar/types';
import { crearArchivo } from '../../../../../utils/sendFiles';
import { deleteArchivo } from '../../../../../utils/deleteFiles';
import { setRequest } from '../../../../../actions/request/types';
import { setRequestFile } from '../../../../../actions/requestFile/types';

function Procedimiento({
  modulo,
  activoFecha,
  tipo,
  sinProcedimientos,
  setSinProcedimientos,
  procedimientos,
  setProcedimientos,
  catDiagnostico,
}: IProcedimientoProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { idPaciente, sexo } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const dispatch = useDispatch();
  const [labelGen, setLabelGen] = useState<string>('');
  const [labelLista, setLabelLista] = useState<string>('');
  const [fecha, setFecha] = useState<IFechaObj>({ dia: '', mes: '', anio: '' });
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const [radioSel, setRadioSel] = useState<string>('cie');
  const [diagnostico, setDiagnostico] = useState<ICatalogosEstandar>({ id: 0, label: '' });
  const [diagEspecifica, setDiagEspecifica] = useState<string>('');
  const [motivo, setMotivo] = useState<ICatStrEstandar>({ id: '', label: '' });
  const [motivoEspecifica, setMotivoEspecifica] = useState<string>('');
  const [comentarios, setComentarios] = useState<RawDraftContentState>({
    blocks: [],
    entityMap: {},
  });
  const [clearComentario, setClearComentario] = useState<number>(
    Math.floor(Math.random() * 1000) + 1,
  );
  const [indSelect, setIndSelect] = useState<number | null>(null);
  /** Catalogos */
  const [catCie9, setCatCie9] = useState<Array<ICatStrEstandar>>([]);
  // cierre del modal
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [cambioAlert, setCambioAlert] = useState<boolean>(false);
  const [sinProc, setSinProc] = useState<boolean>(sinProcedimientos);
  const [idSelect, setIdSelect] = useState<number>(0);
  const [openArchivoBorrar, setOpenArchivoBorrar] = useState<boolean>(false);
  /* archivo */
  const [selObjArchivo, setSelObjArchivo] = useState<{ archivo: File; idLista: number } | null>(
    null,
  );
  /* FUNCIONES DE LAS ALERTAS */
  const [objArchivoBorrar, setObjArchivoBorrar] = useState<{
    idLista: number;
    idArchivo: number;
    nombreArchivo: string;
  } | null>(null);
  /** Handlers */
  const handleChangeDiagnostico = (event: any, newValue: ICatalogosEstandar | null) => {
    if (newValue) {
      setDiagnostico(newValue);
      setDiagEspecifica('');
    } else {
      setDiagnostico({ id: 0, label: '' });
      setDiagEspecifica('');
    }
  };
  const handleChangeMotivo = (event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setMotivo(newValue);
    } else {
      setMotivo({ id: '', label: '' });
    }
  };
  const handleChangeDate = (event: React.ChangeEvent<{ value: unknown }>, sel: string) => {
    const val = event.target.value as string;
    if (sel === 'dia') {
      setFecha((prevVal) => ({ ...prevVal, dia: val }));
    } else if (sel === 'mes') {
      setFecha((prevVal) => ({ ...prevVal, mes: val }));
    } else if (sel === 'anio') {
      setFecha((prevVal) => ({ ...prevVal, anio: val }));
    }
  };
  /* funcion para cerrar la alerta de borrado */
  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  /* funcion para cerrar la alerta de edición */
  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };
  /* funcion para cerrar la alerta de activar sin antecedentes de alergias */
  const handleCambioAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCambioAlert(false);
  };
  // sin antecedentes procedimientos
  const handleCambioAntecedentesProcedimientos = () => {
    let tipoProcedimiento = 1;
    if (tipo === 'diagnostico') {
      tipoProcedimiento = 2;
    } else if (tipo === 'terapeutico') {
      tipoProcedimiento = 3;
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: editProcedimientos({
          idPaciente,
          tipo: tipoProcedimiento,
          idMedico,
          idConsultorio,
        }),
        successFunction: () => {
          setSinProc(true);
          setSinProcedimientos(true);
        },
      }),
    );
    setCambioAlert(false);
  };
  /* funcion para cerrar la alerta de borrado de archivos */
  const handleDeleteArchivoBorrar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setObjArchivoBorrar(null);
    setOpenArchivoBorrar(false);
  };
  const deleteFirstStepProcedimiento = (inx: number) => {
    setIdSelect(inx);
    setDeleteAlert(true);
  };
  const deleteSecondStepProcedimiento = () => {
    dispatch(
      setRequest({
        type: 'delete',
        requestFunction: procedimientoEliminarProcedimiento({
          idPaciente,
          idBD: procedimientos[idSelect].idProcedimiento,
          idMedico,
          idConsultorio,
        }),
        successFunction: () => {
          const newArray = [...procedimientos];
          newArray.splice(idSelect, 1);
          setIdSelect(0);
          setProcedimientos(newArray);
        },
      }),
    );
    setDeleteAlert(false);
  };
  const editSecondStepProcedimiento = (idx: number) => {
    if (procedimientos[idx].txtMotivo && procedimientos[idx].txtMotivo.length > 0) {
      setRadioSel('txt');
    } else {
      setRadioSel('cie');
    }
    setDiagnostico(procedimientos[idx].enfermedad);
    setMotivo(procedimientos[idx].cie9);
    setDiagEspecifica(procedimientos[idx].txtDiagnostico);
    setMotivoEspecifica(procedimientos[idx].txtMotivo);
    setFecha({
      dia: procedimientos[idx].dia || '',
      mes: procedimientos[idx].mes || '',
      anio: procedimientos[idx].anio,
    });
    setComentarios(procedimientos[idx].comentarios);
    setClearComentario(Math.floor(Math.random() * 1000) + 1);
  };
  const setData = (data: RawDraftContentState) => {
    setComentarios(data);
  };
  const editFirstStepProcedimiento = (idx: number) => {
    setIndSelect(idx);
    if (
      diagnostico.id !== 0
      || diagEspecifica
      || motivoEspecifica
      || motivo.id !== ''
      || fecha.dia
      || fecha.mes
      || fecha.dia
      || (comentarios && comentarios.blocks && comentarios.blocks.length > 0)
    ) {
      setEditAlert(true);
    } else {
      editSecondStepProcedimiento(idx);
    }
  };
  const updateMotivo = (idx: number) => {
    const { idProcedimiento } = procedimientos[idx];
    const valDate: IFechaObj = valFechaToSave(fecha);
    if (motivo) {
      const newTipo = {
        idPaciente,
        idConsulta,
        idProcedimiento,
        enfermedad: diagnostico,
        txtDiagnostico: diagEspecifica || '',
        txtMotivo: motivoEspecifica || '',
        cie9: motivo.id ? motivo : { id: '', label: '' },
        dia: valDate.dia,
        mes: valDate.mes,
        anio: valDate.anio,
        comentarios,
        documentos:
          procedimientos[idx] && procedimientos[idx].documentos
            ? procedimientos[idx].documentos
            : [],
      };
      if (valDate.anio === '') {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'warning',
            mensaje: t('antecedentes-pp-proc-snack'),
          }),
        );
      } else {
        dispatch(
          setRequest({
            type: 'send',
            requestFunction: sendProcedimientos({
              ...newTipo,
              idMedico,
              idConsultorio,
            }),
            successFunction: () => {
              setDiagnostico({ id: 0, label: '' });
              setDiagEspecifica('');
              setMotivoEspecifica('');
              setMotivo({ id: '', label: '' });
              setFecha({ dia: '', mes: '', anio: '' });
              setComentarios({ blocks: [], entityMap: {} });
              setClearComentario(Math.floor(Math.random() * 1000) + 1);
              setIndSelect(null);
              const upDate = [...procedimientos];
              upDate[idx] = { ...newTipo, archivos: procedimientos[idx].archivos };
              setProcedimientos(upDate);
            },
          }),
        );
      }
    }
  };
  const addMotivo = () => {
    const valDate: IFechaObj = valFechaToSave(fecha);
    if (motivo) {
      const newTipo = {
        idPaciente,
        idConsulta,
        idProcedimiento: -1,
        tipo,
        enfermedad: diagnostico,
        txtDiagnostico: diagEspecifica,
        txtMotivo: motivoEspecifica,
        cie9: motivo,
        dia: valDate.dia,
        mes: valDate.mes,
        anio: valDate.anio,
        comentarios,
        documentos: [],
        archivos: [],
      };
      if (valDate.anio === '') {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'warning',
            mensaje: t('antecedentes-pp-proc-snack'),
          }),
        );
      } else {
        dispatch(
          setRequest({
            type: 'send',
            requestFunction: sendProcedimientos({
              ...newTipo,
              idMedico,
              idConsultorio,
            }),
            successFunction: (result: any) => {
              setDiagnostico({ id: 0, label: '' });
              setDiagEspecifica('');
              setMotivoEspecifica('');
              setMotivo({ id: '', label: '' });
              setFecha({ dia: '', mes: '', anio: '' });
              setComentarios({ blocks: [], entityMap: {} });
              setClearComentario(Math.floor(Math.random() * 1000) + 1);
              const upDate = [...procedimientos];
              newTipo.idProcedimiento = result.datos.id;
              upDate.push(newTipo);
              // eslint-disable-next-line no-prototype-builtins
              if (upDate.length > 0 && upDate[0].hasOwnProperty('anio')) {
                upDate.sort((a: any, b: any) => {
                  let fechaB = { dia: '', mes: '', anio: '' };
                  let fechaA = { dia: '', mes: '', anio: '' };
                  if (b.dia && b.mes && b.anio) {
                    fechaB = { dia: b.dia, mes: b.mes, anio: b.anio };
                  } else if (b.mes && b.anio) {
                    fechaB = { dia: '1', mes: b.mes, anio: b.anio };
                  } else if (b.anio) {
                    fechaB = { dia: '1', mes: '1', anio: b.anio };
                  } else {
                    fechaB = { dia: '1', mes: '1', anio: '1500' };
                  }
                  if (a.dia && a.mes && a.anio) {
                    fechaA = { dia: a.dia, mes: a.mes, anio: a.anio };
                  } else if (a.mes && a.anio) {
                    fechaA = { dia: '1', mes: a.mes, anio: a.anio };
                  } else if (a.anio) {
                    fechaA = { dia: '1', mes: '1', anio: a.anio };
                  } else {
                    fechaA = { dia: '1', mes: '1', anio: '1500' };
                  }
                  const txtB: string = dayjs(`${fechaB.anio}-${fechaB.mes}-${fechaB.dia}`).format();
                  const txtA: string = dayjs(`${fechaA.anio}-${fechaA.mes}-${fechaA.dia}`).format();
                  return new Date(txtB).getTime() - new Date(txtA).getTime();
                });
              }
              setProcedimientos(upDate);
            },
          }),
        );
      }
    }
  };
  const verArchivo = (
    _idProcedimiento: number,
    idArchivo: number,
    nombreArchivo: string,
    pathArchivo: string,
  ) => {
    let registro = '';
    if (tipo === 'quirurgico') {
      registro = 'procedimientosQuirurgicos';
    } else if (tipo === 'terapeutico') {
      registro = 'procedimientosTerapeuticos';
    } else if (tipo === 'diagnostico') {
      registro = 'procedimientosDiagnosticos';
    }
    dispatch(
      setRequestFile({
        type: 'get',
        idMedico: null,
        idPaciente,
        idArchivo,
        nombreArchivo,
        pathArchivo,
        tipoArchivo: registro,
      }),
    );
  };
  const deleteArchivoBorrar = () => {
    if (objArchivoBorrar) {
      const { idLista, idArchivo } = objArchivoBorrar;
      const arch = procedimientos
        .find((lt) => lt.idProcedimiento === idLista)
        ?.archivos.find((ar) => ar.idArchivo === idArchivo);
      if (arch) {
        let registro = '';
        if (tipo === 'quirurgico') {
          registro = 'procedimientosQuirurgicos';
        } else if (tipo === 'terapeutico') {
          registro = 'procedimientosTerapeuticos';
        } else if (tipo === 'diagnostico') {
          registro = 'procedimientosDiagnosticos';
        }
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: deleteArchivo({
              idLista,
              idArchivo,
              idMedico,
              idPaciente,
              idConsultorio,
              pathArchivo: arch.pathArchivo,
              registro,
            }),
            successFunction: () => {
              const listaArchivos = procedimientos.find(
                (lt) => lt.idProcedimiento === idLista,
              )?.archivos;
              // se verifica que sea un array y no undefined
              if (Array.isArray(listaArchivos)) {
                // le añadimos el objeto que acabamos de subir a la BD
                const laf = listaArchivos.filter((ar) => ar.idArchivo !== idArchivo);
                // actualizamos la lista de transfusiones para evitar hacer un refresh y evitar
                // peticiones extra a la BD
                setProcedimientos((preTra) => preTra.map((tra) => {
                  if (tra.idProcedimiento === idLista) {
                    return { ...tra, archivos: laf };
                  }
                  return tra;
                }));
              }
            },
          }),
        );
      }
    } else {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: 'Ocurrio un error intentar nuevamente',
        }),
      );
    }
  };
  const eliminarArchivo = (idLista: number, idArchivo: number, nombreArchivo: string) => {
    setObjArchivoBorrar({ idLista, idArchivo, nombreArchivo });
    setOpenArchivoBorrar(true);
  };
  const getTipoProcedimiento = () => {
    switch (tipo) {
      case 'quirurgico':
        return 1;
      case 'diagnostico':
        return 2;
      case 'terapeutico':
        return 3;
      default:
        return 0;
    }
  };
  useEffect(() => {
    if (selObjArchivo && selObjArchivo.archivo.size > 10485760) {
      setSelObjArchivo(null);
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('el_archivo_selecionado_pesa_mas_de_10_megas'),
        }),
      );
      return;
    }
    if (selObjArchivo) {
      // despues en un futuro debemos crear un XMLHttpRequest en vez de un fetch para obtener el progreso de la carga
      // ese progreso se dejará maqueteado para usarlo en un futuro, asi que los comentarios de dejan a proposito
      // obtenemos un objeto form data que nos servira para enviar nuestra peticion como si se hubiera generado en
      // un formulario
      const data = new FormData();
      // añadimos la informacion importante y OJO el archivo debe ir siempre al último o el back no parseara correctamente
      // los parametros que se le adjunta
      data.append('idLista', String(selObjArchivo.idLista));
      data.append('idMedico', String(idMedico));
      data.append('idPaciente', String(idPaciente));
      data.append('idConsulta', String(idConsulta));
      data.append('nombre', String(selObjArchivo.archivo?.name));
      data.append('tipo', selObjArchivo.archivo?.type);
      data.append('peso', String(selObjArchivo.archivo?.size));
      if (tipo === 'quirurgico') {
        data.append('registro', 'procedimientosQuirurgicos');
      } else if (tipo === 'terapeutico') {
        data.append('registro', 'procedimientosTerapeuticos');
      } else if (tipo === 'diagnostico') {
        data.append('registro', 'procedimientosDiagnosticos');
      }
      data.append('file', selObjArchivo?.archivo);
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: crearArchivo(data),
          successFunction: (result: any) => {
            // si el resultado es ok procedemos a obtener la lista de archivos
            const listaArchivos = procedimientos.find(
              (lt) => lt.idProcedimiento === selObjArchivo.idLista,
            )?.archivos;
            // se verifica que sea un array y no undefined
            if (Array.isArray(listaArchivos)) {
              // le añadimos el objeto que acabamos de subir a la BD
              listaArchivos.push({
                idArchivo: result.datos.idArchivo,
                idMedico,
                nombreArchivo: selObjArchivo.archivo.name,
                pathArchivo: result.datos.url,
              });
              // actualizamos la lista de transfusiones para evitar hacer un refresh y evitar
              // peticiones extra a la BD
              setProcedimientos((preTra) => preTra.map((tra) => {
                if (tra.idProcedimiento === selObjArchivo.idLista) {
                  return { ...tra, archivos: listaArchivos };
                }
                return tra;
              }));
            }
            // reseteamos el objeto
            setSelObjArchivo(null);
          },
          errorFunction: () => {
            // reseteamos el objeto
            setSelObjArchivo(null);
          },
        }),
      );
    }
  }, [selObjArchivo]);
  useEffect(() => {
    if (tipo === 'quirurgico') {
      setLabelGen(t('antecedentes-pp-pquirurgico'));
      setLabelLista(t('antecedentes-pp-pquirurgico-l'));
    } else if (tipo === 'terapeutico') {
      setLabelGen(t('antecedentes-pp-pterapeutico'));
      setLabelLista(t('antecedentes-pp-pterapeutico-l'));
    } else if (tipo === 'diagnostico') {
      setLabelGen(t('antecedentes-pp-pdiagnosticos'));
      setLabelLista(t('antecedentes-pp-pdiagnosticos-l'));
    }
    getCatCie9(getTipoProcedimiento().toString(), sexo).then((result: ICatStrEstandar[]) => {
      setCatCie9(result);
    });
  }, []);
  useEffect(() => {
    if (
      fecha.mes
      && fecha.anio
      && (!validarFecha(fecha.anio, fecha.mes, fecha.dia || '1')
        || !validarFechaEsAnterior(fecha.anio, fecha.mes, fecha.dia || '1'))
    ) {
      setFechaField({
        error: true,
        helperText: t('fecha_invalida'),
      });
    } else {
      setFechaField({
        error: false,
        helperText: '',
      });
    }
  }, [fecha]);
  useEffect(() => {
    if (activoFecha && fecha.dia.length === 0) {
      setFecha({
        dia: dayjs().format('D'),
        mes: dayjs().format('M'),
        anio: dayjs().format('YYYY'),
      });
    }
  }, [activoFecha]);
  const disabledButton = () => fechaField.error
    || !fecha.anio
    || (radioSel === 'cie' && motivo && motivo.id === '')
    || (radioSel === 'txt' && motivoEspecifica.length === 0);
  return (
    <div className="relative">
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pp-hos-eliminar')}
        callBackAceptar={deleteSecondStepProcedimiento}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={openArchivoBorrar}
        titulo={t('_atencion_')}
        descripcion={`${t('_estas_seguro_de_eliminar')}: ${objArchivoBorrar?.nombreArchivo}?`}
        callBackAceptar={deleteArchivoBorrar}
        callBackClose={handleDeleteArchivoBorrar}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pp-hos-edicion')}
        callBackAceptar={() => editSecondStepProcedimiento(indSelect || 0)}
        callBackClose={handleEditAlertClose}
      />
      <DeleteDialog
        open={cambioAlert}
        titulo={t('_atencion_')}
        descripcion={t('_estas_seguro__si_existe_registros_anteriores_se_eliminaran')}
        callBackAceptar={handleCambioAntecedentesProcedimientos}
        callBackClose={handleCambioAlertClose}
      />
      {!activoFecha && <h2 className="text-blue-800 font-normal m-0">{labelGen}</h2>}
      {tipo === 'quirurgico' && !activoFecha && (
        <FormControlLabel
          control={(
            <Checkbox
              checked={sinProc}
              onChange={() => {
                if (sinProc) {
                  setSinProc(false);
                } else {
                  setCambioAlert(true);
                }
              }}
              name="sinProc"
              color="primary"
            />
          )}
          label={t('antecedentes-pp-proc-sin-ant')}
          disabled={!!procedimientos.length}
        />
      )}
      {!sinProc && (
        <>
          <div className="my-4 border-solid border border-gray-400 rounded p-3">
            <div className="mb-2">
              <div className="grid grid-cols-2">
                <h3 className="mt-0 mb-3 text-gray-600 font-medium">
                  {`${t('antecedentes-pp-proc-diag')}:`}
                </h3>
                <div className="flex justify-end">
                  <h4 className="mt-0 mb-2 font-normal text-red-600">
                    <Trans i18nKey="antecedentes-pp-proc-atxt">
                      index0
                      <strong title="index1">index1</strong>
                      <strong title="index2">index2</strong>
                    </Trans>
                  </h4>
                </div>
              </div>
              <Autocomplete
                options={catDiagnostico}
                getOptionLabel={(option) => option.label}
                noOptionsText={t('sin_resultados')}
                value={diagnostico}
                onChange={(e, nV) => handleChangeDiagnostico(e, nV)}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
              {/* ¿QUE HACE EL DIAGNOSTICO NUMERO 28?????? POR QUE ESTA ACA????? */}
              {diagnostico && diagnostico.id === 28 ? (
                <div className="mt-4">
                  <TextField
                    variant="outlined"
                    value={diagEspecifica}
                    placeholder={t('especifica')}
                    fullWidth
                    InputProps={{
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: '150',
                      },
                    }}
                    onChange={(event) => setDiagEspecifica(event.target.value)}
                  />
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <div className="flex justify-left items-center mb-2">
                <h3 className="m-0 mr-8 text-gray-600 font-medium">
                  {`${t('antecedentes-pp-proc-tipo')}*`}
                </h3>
                <FormControl>
                  <RadioGroup
                    aria-label="realiza"
                    name="realiza"
                    value={radioSel}
                    onChange={(event) => {
                      setRadioSel((event.target as HTMLInputElement).value);
                      setMotivo({ id: '', label: '' });
                      setMotivoEspecifica('');
                    }}
                    row
                  >
                    <FormControlLabel
                      value="cie"
                      control={<Radio color="primary" />}
                      label={t('cie-9')}
                    />
                    <FormControlLabel
                      value="txt"
                      control={<Radio color="primary" />}
                      label={t('texto-libre')}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {radioSel && radioSel === 'cie' ? (
                <Autocomplete
                  id="combo-box-duracion"
                  options={catCie9}
                  getOptionLabel={(option) => option.label}
                  noOptionsText={t('sin_resultados')}
                  value={motivo}
                  onChange={(e, nV) => handleChangeMotivo(e, nV)}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              ) : (
                <TextField
                  variant="outlined"
                  value={motivoEspecifica}
                  placeholder={t('especifica')}
                  fullWidth
                  InputProps={{
                    inputProps: {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      maxLength: '150',
                    },
                  }}
                  onChange={(event) => setMotivoEspecifica(event.target.value)}
                />
              )}
            </div>
            {!activoFecha && (
              <div className="grid grid-cols-12 mb-4 gab-4">
                <div className="col-span-4">
                  <h3 className="mt-0 mb-2 text-gray-600 font-medium">{`${t('fecha')}*`}</h3>
                  <div className="grid grid-cols-4">
                    <div className="mr-4">
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          displayEmpty
                          labelId="label-dia"
                          id="select-dia"
                          value={fecha.dia}
                          onChange={(e) => handleChangeDate(e, 'dia')}
                          error={fechaField.error}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 375,
                              },
                            },
                          }}
                          renderValue={
                            fecha.dia !== ''
                              ? undefined
                              : () => <div className={classes.placeholder}>{t('dia')}</div>
                          }
                        >
                          {constDia.map((diaSingle) => (
                            <MenuItem value={diaSingle.toString()} key={diaSingle}>
                              {diaSingle}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mr-4 col-span-2">
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          displayEmpty
                          labelId="label-mes"
                          id="select-mes"
                          value={fecha.mes}
                          onChange={(e) => handleChangeDate(e, 'mes')}
                          error={fechaField.error}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 375,
                              },
                            },
                          }}
                          renderValue={
                            fecha.mes !== ''
                              ? undefined
                              : () => <div className={classes.placeholder}>{t('mes')}</div>
                          }
                        >
                          {constMes.map((mesSingle, indx) => (
                            <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                              {mesSingle.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText className={classes.absoluteBottom}>
                          {fechaField.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className="mr-4">
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          displayEmpty
                          labelId="label-año"
                          id="select-año"
                          value={fecha.anio}
                          onChange={(e) => handleChangeDate(e, 'anio')}
                          error={fechaField.error}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 375,
                              },
                            },
                          }}
                          renderValue={
                            fecha.anio !== ''
                              ? undefined
                              : () => <div className={classes.placeholder}>{t('anio')}</div>
                          }
                        >
                          {constAnio().map((anioSingle) => (
                            <MenuItem key={anioSingle} value={anioSingle.toString()}>
                              {anioSingle}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <ComentariosComponente
              comentarios={comentarios}
              setComentarios={setData}
              rows={4}
              maxLength={500}
              placeholder={t('comentarios')}
              lang="es"
              disabled={false}
              key={clearComentario}
            />
            <div className="flex justify-end mt-2 mb-3">
              {indSelect === null ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addMotivo}
                  className={classes.noTextTranform}
                  startIcon={<SaveIcon />}
                  disabled={disabledButton()}
                >
                  {t('guardar')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="large"
                  startIcon={<SyncIcon />}
                  onClick={() => updateMotivo(indSelect)}
                  disabled={disabledButton()}
                >
                  {t('actualizar')}
                </Button>
              )}
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">
                *
                {t('campos_obligatorios')}
              </span>
              <h4 className="mt-0 mb-2 font-normal">
                <Trans i18nKey="antecedentes-pp-proc-gtxt">
                  index0
                  <strong title="index1">index1</strong>
                  index2
                </Trans>
              </h4>
            </div>
          </div>
          {procedimientos.length > 0 && (
            <>
              <hr />
              <div className="w-full mt-4">
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="lista-datos"
                    id="lista-datos"
                  >
                    <h2 className="text-blue-500 font-normal m-0">{labelLista}</h2>
                  </AccordionSummary>
                  {procedimientos.map((val, inx) => (modulo === 'antecedentes' || val.idConsulta === idConsulta ? (
                  // eslint-disable-next-line react/no-array-index-key
                    <AccordionDetails key={`accordion-${inx}`}>
                      <ProcedimientosComponente
                        {...val}
                        deleteProcedimiento={() => deleteFirstStepProcedimiento(inx)}
                        editProcedimiento={() => editFirstStepProcedimiento(inx)}
                        indSelect={indSelect}
                        inx={inx}
                        eliminarArchivo={eliminarArchivo}
                        setSelArchivo={setSelObjArchivo}
                        verArchivo={verArchivo}
                      />
                    </AccordionDetails>
                  ) : null))}
                </Accordion>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Procedimiento;
