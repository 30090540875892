import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import dayjs from 'dayjs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardCapslock, Visibility, VisibilityOff } from '@material-ui/icons';
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import { GreenButton } from '../../../../utils/ComponentesJSX';
import {
  getCatalogoNacionalidad,
  getSAMiOrganizacionValidacionDocId,
} from '../../../../utils/getCatalogos';
import { validarFecha, validarFechaEsAnterior } from '../../../../common/functions';
import { constDia, constMes, constAnio } from '../../../../constants/fechas';
import {
  docIdLongitudMax,
  validacionDocId,
  validacionDocIdOnChange,
  validacionEmail,
} from '../../../../constants/validaciones';
import {
  IFechaField,
  ITextFieldEstandar,
  ICountryPhoneInput,
  ICatStrEstandar,
} from '../../../../common/types';
import DialogPassword from '../../../../components/ModalDialogs/DialogPassword/index';
import { RootState } from '../../../../store';
import { setSnackComplete } from '../../../../actions/snackbar/types';
import { setLoading } from '../../../../actions/loading/actions';
import useStyles from './styles';
import AlertDialog from '../../../../components/AlertDialogs/AlertDialog';
import { IPerfilAdmonProps } from './types';
import { IDatosAdministrador } from '../../types';

dayjs.locale('es');

const PerfilAdministrador = (props: IPerfilAdmonProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const locState = location.state || {
    idOrg: -1,
    objType: '',
    objOrgId: '',
    objOrgLabel: '',
    objAdminId: '',
  };
  const {
    objAdministrador,
    setObjAdministrador,
    docId,
    contrasenia,
    setContrasenia,
    contraseniaRepetida,
    setContraseniaRepetida,
    otrasOrganizaciones,
    setUsuarioExistente,
    saveData,
  } = props;
  const dispatch = useDispatch();
  const { idMedico, pais } = useSelector((state: RootState) => state.Me);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const classes = useStyles();
  const organizaciones: ICatStrEstandar[] = otrasOrganizaciones.map((org) => ({
    id: org.id.toString(),
    label: org.nombreOrganizacion,
  }));
  const [anyoFur] = useState<number[]>(constAnio);
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const [disableSave, setDisabledSave] = useState<boolean>(false);
  const [rolesExistentes, setRolesExistentes] = useState<number[]>([]);
  const [organizacionesExistentes, setOrganizacionesExistentes] = useState<number[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [catNacionalidad, setCatNacionalidad] = useState<Array<ICatStrEstandar>>([]);
  const [errorOrganizacion, setErrorOrganizacion] = useState<any>({
    tipo: 0,
    text: '',
    alert: false,
  });
  const [emailError, setEmailError] = useState<any>({ helperText: '', error: false });
  const [docIdField, setDocIdField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [docIdValido, setDocIdValido] = useState<boolean>(false);
  const [validacionDocumento, setValidacionDocumento] = useState<any>({ text: '', alert: false });
  // password
  const [errorPass, setErrorPass] = useState({
    password: { error: false, helperText: '' },
    passwordRepetido: { error: false, helperText: '' },
  });
  const [verPassword, setVerPassword] = useState<boolean>(false);
  const [verPasswordRepetido, setVerPasswordRepetido] = useState<boolean>(false);
  const [isPasswordCapsLockOn, setIsPasswordCapsLockOn] = useState(false);
  const [isPasswordRepetidoCapsLockOn, setIsPasswordRepetidoCapsLockOn] = useState(false);
  const [objAdminTmp, setObjAdminTmp] = useState<IDatosAdministrador>({
    organizacion: {
      id: '',
      label: '',
    },
    nombre: '',
    apellidoP: '',
    apellidoS: '',
    docId: '',
    sexo: '',
    fechaNac: {
      dia: '',
      mes: '',
      anio: '',
    },
    celular: {
      numero: '',
      codigo: '',
    },
    telefono: {
      numero: '',
      codigo: '',
    },
    email: '',
    emailLog: '',
    nacionalidad: {
      id: '',
      label: '',
    },
    id: -1,
    password: '',
  });
  const limpiarAdministrador = () => {
    setObjAdministrador({
      ...objAdministrador,
      nombre: '',
      apellidoP: '',
      apellidoS: '',
      sexo: '',
      fechaNac: {
        dia: '',
        mes: '',
        anio: '',
      },
      celular: {
        numero: '',
        codigo: '',
      },
      telefono: {
        numero: '',
        codigo: '',
      },
      email: '',
      emailLog: '',
      nacionalidad: {
        id: '',
        label: '',
      },
      password: '',
    });
    setContrasenia('');
    setContraseniaRepetida('');
  };
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjAdministrador({ ...objAdministrador, password: val });
  };
  const handleChangePasswordRepetido = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setContraseniaRepetida(val);
  };
  const handleClickVerPasswordRepetido = () => {
    setVerPasswordRepetido((prev) => !prev);
  };
  const handleClickVerPassword = () => {
    setVerPassword((prev) => !prev);
  };
  const handleFocusPassword = () => {
    setErrorPass({
      password: { error: false, helperText: '' },
      passwordRepetido: { error: false, helperText: '' },
    });
  };
  const handleMouseDownPasswordRepetido = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const checkPasswordCapsLock = (event: any) => {
    setIsPasswordCapsLockOn(event.getModifierState('CapsLock'));
  };
  const checkPasswordRepetidoCapsLock = (event: any) => {
    setIsPasswordRepetidoCapsLockOn(event.getModifierState('CapsLock'));
  };
  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setEmailError({ helperText: '', error: false });
    } else {
      setEmailError({ helperText: t('email_incorrecto'), error: true });
    }
  };
  const handleChangeOrganizacion = (_event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setObjAdministrador({ ...objAdministrador, organizacion: newValue });
      if (
        otrasOrganizaciones.find((org) => `${org.id}` === `${newValue.id}`)?.administradores
          .length === 2
      ) {
        setErrorOrganizacion({
          tipo: 1,
          alert: true,
          text: t('organizacion_ya_tiene_dos_administradores'),
        });
      } else if (organizacionesExistentes.includes(parseInt(newValue.id, 10))) {
        setErrorOrganizacion({
          tipo: 2,
          alert: true,
          text: t('administrador_existe_en_organizacion'),
        });
      } else {
        setErrorOrganizacion({ tipo: 0, alert: false, text: '' });
      }
    } else {
      setObjAdministrador({ ...objAdministrador, organizacion: { id: '', label: '' } });
      setErrorOrganizacion({ tipo: 0, alert: false, text: '' });
    }
  };
  const handleChangeSexo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjAdministrador({ ...objAdministrador, sexo: val });
  };
  const handleChangeDate = (event: React.ChangeEvent<{ value: unknown }>, tipo: string) => {
    const dataUpd = { ...objAdministrador };
    const valAdd = event.target.value as string;
    if (tipo === 'dia') {
      dataUpd.fechaNac.dia = valAdd;
    } else if (tipo === 'mes') {
      dataUpd.fechaNac.mes = valAdd;
    } else if (tipo === 'anio') {
      dataUpd.fechaNac.anio = valAdd;
    }
    setObjAdministrador(dataUpd);
  };
  const handleChangeDocId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionDocIdOnChange(val, pais)) {
      setDocIdField({ helperText: '', error: false });
    } else {
      setDocIdField({ helperText: t(`doc_id_incorrecto_${pais}`), error: true });
    }
    setObjAdministrador({
      ...objAdministrador,
      docId: val,
    });
    setDocIdValido(false);
  };
  const handleBlurDocId = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val.length && validacionDocId(val, pais)) {
      setDocIdField({ helperText: '', error: false });
      setObjAdministrador({
        ...objAdministrador,
        docId: val,
      });
    } else {
      setDocIdField({ helperText: t(`doc_id_incorrecto_${pais}`), error: true });
    }
  };
  const handleChangeNombre = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjAdministrador({ ...objAdministrador, nombre: event.target.value as string });
  };
  const handleChangeApellidoP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjAdministrador({ ...objAdministrador, apellidoP: event.target.value as string });
  };
  const handleChangeApellidoS = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjAdministrador({ ...objAdministrador, apellidoS: event.target.value as string });
  };
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjAdministrador({
      ...objAdministrador,
      email: (event.target.value as string).toLowerCase(),
    });
  };
  const handleChangeNacionalidad = (_event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setObjAdministrador({ ...objAdministrador, nacionalidad: newValue });
    } else {
      setObjAdministrador({ ...objAdministrador, nacionalidad: { id: '', label: '' } });
    }
  };
  const handleAceptarDocId = () => {
    setDocIdValido(true);
    if (rolesExistentes.includes(2)) {
      setObjAdministrador({
        ...objAdminTmp,
        password: '********',
      });
      setContrasenia('********');
      setContraseniaRepetida('********');
      setUsuarioExistente(true);
    } else {
      setObjAdministrador({
        ...objAdminTmp,
        email: '',
      });
    }
  };
  const validarDocIdAdministrador = () => {
    getSAMiOrganizacionValidacionDocId(idMedico, idOrganizacion, objAdministrador.docId)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          if (result.data.tipo_roles.length === 0) {
            // No existe el documento de identidad
            setValidacionDocumento({
              alert: false,
              text: '',
            });
            setDocIdValido(true);
          } else {
            setObjAdminTmp({
              ...result.data,
              organizacion: objAdministrador.organizacion,
              id: -1,
            });
            setRolesExistentes(result.data.tipo_roles);
            setOrganizacionesExistentes(result.data.organizaciones);
            if (errorOrganizacion.tipo !== 1) {
              if (
                result.data.organizaciones.includes(parseInt(objAdministrador.organizacion.id, 10))
              ) {
                setErrorOrganizacion({
                  tipo: 2,
                  alert: true,
                  text: t('administrador_existe_en_organizacion_'),
                });
              } else {
                setErrorOrganizacion({ tipo: 0, alert: false, text: '' });
              }
            }
          }
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  useEffect(() => {
    if (rolesExistentes.length > 0) {
      const roles = rolesExistentes.filter(
        (rol: number, index: number) => rolesExistentes.indexOf(rol) === index,
      );
      const rolesTxt = roles.map((tipo: number) => {
        if (tipo === 1) {
          return t('administrador_roles_superadministrador');
        }
        if (tipo === 2) {
          return t('administrador_roles_administrador');
        }
        if (tipo === 3) {
          return t('administrador_roles_equipo_administrativo');
        }
        return t('administrador_roles_equipo_medico');
      });
      setValidacionDocumento({
        alert: true,
        text: (
          <div>
            <p className="text-center">
              {roles.length === 1
                ? t(`doc_id_asignado_texto_s_${pais}`)
                : t(`doc_id_asignado_texto_p_${pais}`)}
            </p>
            <p className="text-center text-red-500">{rolesTxt.join(' y ')}</p>
            <p className="text-center">
              {roles.includes(2)
                ? t('doc_id_asignado_texto_mismo')
                : t('doc_id_asignado_texto_distinto')}
            </p>
          </div>
        ),
      });
    }
  }, [rolesExistentes]);
  useEffect(() => {
    if (objAdministrador.id !== -1) {
      setDocIdValido(true);
    }
  }, [objAdministrador.id]);
  useEffect(() => {
    if (locState.objType === 'administrador') {
      if (locState.objId === '-1') {
        setDocIdValido(false);
      } else {
        setDocIdValido(true);
        setDisabledSave(false);
      }
    }
  }, [locState]);
  useEffect(() => {
    if (catNacionalidad.length === 0) {
      getCatalogoNacionalidad().then((result: ICatStrEstandar[]) => {
        setCatNacionalidad(result);
      });
    }
  }, [catNacionalidad]);
  useEffect(() => {
    const rdia = objAdministrador.fechaNac.dia;
    const rmes = objAdministrador.fechaNac.mes;
    const ranio = objAdministrador.fechaNac.anio;
    if (rdia !== '' && rmes !== '' && ranio !== '') {
      const objF = { ...fechaField };
      objF.error = false;
      objF.helperText = '';
      if (!validarFecha(ranio, rmes, rdia) || !validarFechaEsAnterior(ranio, rmes, rdia)) {
        objF.error = true;
        objF.helperText = t('fecha_invalida');
      }
      setFechaField(objF);
    }
  }, [
    objAdministrador.fechaNac.dia,
    objAdministrador.fechaNac.mes,
    objAdministrador.fechaNac.anio,
  ]);
  useEffect(() => {
    if (
      fechaField.error
      || errorOrganizacion.alert
      || objAdministrador.organizacion.id === ''
      || objAdministrador.nombre.length === 0
      || objAdministrador.apellidoP.length === 0
      || (pais === 'mx' && objAdministrador.docId.length < 18)
      || (pais === 'uy' && objAdministrador.docId.length < 6)
      || objAdministrador.sexo.length === 0
      || objAdministrador.fechaNac.dia.length === 0
      || objAdministrador.fechaNac.mes.length === 0
      || objAdministrador.nacionalidad.id.length === 0
      || objAdministrador.fechaNac.anio.length === 0
      || objAdministrador.celular.numero.length === 0
      || objAdministrador.email.length === 0
      || (objAdministrador.id === -1 && objAdministrador.password?.length === 0)
    ) {
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }
  }, [objAdministrador]);
  useEffect(() => {
    if (objAdministrador.docId !== docId) {
      setRolesExistentes([]);
      limpiarAdministrador();
      if (errorOrganizacion.tipo !== 1) {
        setErrorOrganizacion({ tipo: 0, alert: false, text: '' });
      }
    }
  }, [objAdministrador.docId]);
  useEffect(() => {
    if (objAdministrador.password?.length === 0 || objAdministrador.password?.length >= 8) {
      setContrasenia(objAdministrador.password);
      if (contraseniaRepetida.length !== 0 && objAdministrador.password !== contraseniaRepetida) {
        setErrorPass({
          password: { error: false, helperText: '' },
          passwordRepetido: { error: true, helperText: t('las_contrasenias_no_coinciden') },
        });
      } else {
        setErrorPass({
          password: { error: false, helperText: '' },
          passwordRepetido: { error: false, helperText: '' },
        });
      }
    } else if (
      objAdministrador.password?.length !== 0
      && objAdministrador.password?.length < contrasenia.length
    ) {
      setErrorPass({
        password: { error: true, helperText: t('la_contrasenia_debe_tener_al_menos_8_caracteres') },
        passwordRepetido: { error: false, helperText: '' },
      });
      setContraseniaRepetida('');
    }
  }, [objAdministrador.password]);
  useEffect(() => {
    if (contraseniaRepetida.length !== 0 && objAdministrador.password !== contraseniaRepetida) {
      setErrorPass({
        password: { error: false, helperText: '' },
        passwordRepetido: { error: true, helperText: t('las_contrasenias_no_coinciden') },
      });
    } else {
      setErrorPass({
        password: { error: false, helperText: '' },
        passwordRepetido: { error: false, helperText: '' },
      });
    }
  }, [contraseniaRepetida]);

  return (
    <div className="p-4 bg-white">
      <DialogPassword
        open={showDialog}
        callBackClose={() => {
          setShowDialog(!showDialog);
        }}
      />
      <AlertDialog
        open={validacionDocumento.alert}
        titulo={t(`doc_id_asignado_titulo_${pais}`)}
        descripcion={validacionDocumento.text}
        nombreAceptar={t('aceptar')}
        nombreCancelar={t('cancelar')}
        callBackAceptar={() => handleAceptarDocId()}
        callBackClose={() => setValidacionDocumento({ alert: false, text: '' })}
      />
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <div className="justify-center">
          <h1 className="text-blue-500 font-normal m-2 text-center">
            {`${t('administrador_administradores')} ${t('administrador_invita')}`}
          </h1>
        </div>
        <div>
          <h4 className="text-gray-500 font-normal mt-0 mb-2">
            {`${t('administrador_mi_organizacion_nombre')}* (${t(
              'administrador_mi_organizacion_comercial',
            )})`}
          </h4>
          <Autocomplete
            options={organizaciones}
            getOptionLabel={(option) => option.label}
            noOptionsText={t('sin_resultados')}
            value={objAdministrador.organizacion}
            onChange={(e, org) => handleChangeOrganizacion(e, org)}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
          />
          {errorOrganizacion.alert && (
            <span className="text-red-500 font-normal">{errorOrganizacion.text}</span>
          )}
        </div>
        <div className="mt-4">
          <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t(`doc_id_${pais}`)}*`}</h4>
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-3">
              <TextField
                variant="outlined"
                type="docId"
                value={objAdministrador.docId}
                onChange={handleChangeDocId}
                onBlur={handleBlurDocId}
                fullWidth
                inputProps={{
                  maxLength: docIdLongitudMax(pais),
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  style: { textTransform: objAdministrador.docId.length ? 'uppercase' : 'none' },
                }}
                placeholder={t(`especifica_debe_contener_n_caracteres_${pais}`)}
                error={docIdField.error}
                helperText={docIdField.helperText}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
                disabled={errorOrganizacion.tipo === 1}
              />
            </div>
            <div className="col-span-3 self-center">
              <GreenButton
                size="large"
                variant="contained"
                color="inherit"
                onClick={validarDocIdAdministrador}
                disabled={
                  (pais === 'mx' && objAdministrador.docId.length < 18)
                  || (pais === 'uy' && objAdministrador.docId.length < 6)
                  || docIdValido
                  || docIdField.error
                }
              >
                {t('validar')}
              </GreenButton>
              {docIdValido && (
                <span className="text-green-500 font-normal ml-4">
                  {t(`doc_id_validado_${pais}`)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('nombre(s)')}*`}</h4>
            <TextField
              variant="outlined"
              type="nombre"
              value={objAdministrador.nombre}
              onChange={handleChangeNombre}
              fullWidth
              inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              placeholder={t('especifica')}
              disabled={!docIdValido || rolesExistentes.length > 0}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('primer_apellido')}*`}</h4>
            <TextField
              variant="outlined"
              type="apellidoP"
              value={objAdministrador.apellidoP}
              onChange={handleChangeApellidoP}
              fullWidth
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              placeholder={t('especifica')}
              disabled={!docIdValido || rolesExistentes.length > 0}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('segundo_apellido')}</h4>
            <TextField
              variant="outlined"
              type="apellidoS"
              value={objAdministrador.apellidoS}
              onChange={handleChangeApellidoS}
              fullWidth
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              placeholder={t('especifica')}
              disabled={!docIdValido || rolesExistentes.length > 0}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-4">
          <div>
            <h4 className="m-0 mb-3 text-gray-500 font-normal">{`${t('sexo')}*`}</h4>
            <RadioGroup row name="sexo" value={objAdministrador.sexo} onChange={handleChangeSexo}>
              <FormControlLabel
                value="H"
                control={<Radio color="primary" />}
                label={t('hombre')}
                labelPlacement="end"
                disabled={!docIdValido || rolesExistentes.length > 0}
              />
              <FormControlLabel
                value="M"
                control={<Radio color="primary" />}
                label={t('mujer')}
                labelPlacement="end"
                disabled={!docIdValido || rolesExistentes.length > 0}
              />
            </RadioGroup>
          </div>
          <div className="w-full grid grid-cols-3 gap-2">
            <h4 className="text-gray-500 font-normal m-0 col-span-3">
              {`${t('fecha_nacimiento')}*`}
            </h4>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-dia">{t('dia')}</InputLabel>
              <Select
                labelId="label-dia"
                id="select-dia"
                value={objAdministrador.fechaNac.dia}
                onChange={(e) => handleChangeDate(e, 'dia')}
                label={t('dia')}
                error={fechaField.error}
                disabled={!docIdValido || rolesExistentes.length > 0}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constDia.map((diaSingle) => (
                  <MenuItem value={diaSingle.toString()} key={diaSingle}>
                    {diaSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-mes">{t('mes')}</InputLabel>
              <Select
                labelId="label-mes"
                id="select-mes"
                value={objAdministrador.fechaNac.mes}
                onChange={(e) => handleChangeDate(e, 'mes')}
                label={t('mes')}
                error={fechaField.error}
                disabled={!docIdValido || rolesExistentes.length > 0}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constMes.map((mesSingle, indx) => (
                  <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                    {mesSingle.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className={classes.absoluteBottom}>
                {fechaField.helperText}
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-año">{t('anio')}</InputLabel>
              <Select
                labelId="label-año"
                id="select-año"
                value={objAdministrador.fechaNac.anio}
                onChange={(e) => handleChangeDate(e, 'anio')}
                label={t('anio')}
                error={fechaField.error}
                disabled={!docIdValido || rolesExistentes.length > 0}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {anyoFur.map((anioSingle) => (
                  <MenuItem key={anioSingle} value={anioSingle.toString()}>
                    {anioSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <h4 className="text-gray-500 font-normal m-0 mb-2">{`${t('telefono_celular')}*`}</h4>
            <PhoneInput
              preferredCountries={[pais, 'us']}
              country={pais}
              localization={es}
              enableSearch
              searchPlaceholder={t('buscar')}
              searchNotFound={t('sin_resultados')}
              inputStyle={{
                width: '100%',
                color: !docIdValido || rolesExistentes.length > 0 ? 'darkgrey' : 'black',
              }}
              value={`${objAdministrador.celular.codigo}${objAdministrador.celular.numero}`}
              onChange={(value, country: ICountryPhoneInput) => {
                const { dialCode } = country;
                const nuevoCel = value.slice(dialCode.length);
                const dataUpd = { ...objAdministrador };
                dataUpd.celular.numero = nuevoCel;
                dataUpd.celular.codigo = country.dialCode;
                setObjAdministrador(dataUpd);
              }}
              disabled={!docIdValido || rolesExistentes.length > 0}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal m-0 mb-2">{t('telefono_fijo')}</h4>
            <PhoneInput
              preferredCountries={[pais, 'us']}
              country={pais}
              localization={es}
              enableSearch
              searchPlaceholder={t('buscar')}
              searchNotFound={t('sin_resultados')}
              inputStyle={{
                width: '100%',
                color: !docIdValido || rolesExistentes.length > 0 ? 'darkgrey' : 'black',
              }}
              value={`${objAdministrador.telefono.codigo}${objAdministrador.telefono.numero}`}
              onChange={(value, country: ICountryPhoneInput) => {
                const { dialCode } = country;
                const nuevoCel = value.slice(dialCode.length);
                const dataUpd = { ...objAdministrador };
                dataUpd.telefono.numero = nuevoCel;
                dataUpd.telefono.codigo = country.dialCode;
                setObjAdministrador(dataUpd);
              }}
              disabled={!docIdValido || rolesExistentes.length > 0}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 pt-4">
          <div className="col-span-2">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('email')}*`}</h4>
            <TextField
              variant="outlined"
              type="email"
              value={objAdministrador.email}
              placeholder={t('especifica')}
              onChange={handleChangeEmail}
              fullWidth
              error={emailError.error}
              helperText={emailError.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              onBlur={handleBlurEmail}
              disabled={objAdministrador.id !== -1 || !docIdValido || rolesExistentes.includes(2)}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('nacionalidad')}*`}</h4>
            <Autocomplete
              options={catNacionalidad}
              getOptionLabel={(option) => option.label}
              noOptionsText={t('sin_resultados')}
              value={objAdministrador.nacionalidad}
              onChange={(e, nV) => handleChangeNacionalidad(e, nV)}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
              disabled={
                (!docIdValido || rolesExistentes.length > 0)
                && objAdminTmp.nacionalidad.id.length !== 0
              }
            />
          </div>
        </div>
        {objAdministrador.id === -1 && (
          <div className="grid grid-cols-2 gap-4 pt-4">
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('contrasenia')}*`}</h4>
              <TextField
                error={errorPass.password.error}
                variant="outlined"
                type={verPassword ? 'text' : 'password'}
                fullWidth
                value={objAdministrador.password}
                disabled={!docIdValido || rolesExistentes.includes(2)}
                onChange={handleChangePassword}
                onFocus={handleFocusPassword}
                onKeyUp={checkPasswordCapsLock}
                onKeyDown={checkPasswordCapsLock}
                onCopy={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onCut={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                helperText={errorPass.password.helperText}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
                InputProps={{
                  endAdornment: (
                    <div className="text-blue-500">
                      <InputAdornment position="end">
                        {isPasswordCapsLockOn ? (
                          <Tooltip title={t('mayusculas_activadas')}>
                            <KeyboardCapslock />
                          </Tooltip>
                        ) : (
                          ''
                        )}
                        <IconButton
                          onClick={handleClickVerPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="inherit"
                        >
                          {verPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: '50',
                  },
                }}
              />
            </div>
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">
                {`${t('repetir_contrasenia')}*`}
              </h4>
              <TextField
                error={errorPass.passwordRepetido.error}
                variant="outlined"
                type={verPasswordRepetido ? 'text' : 'password'}
                onFocus={handleFocusPassword}
                onKeyDown={checkPasswordRepetidoCapsLock}
                onKeyUp={checkPasswordRepetidoCapsLock}
                fullWidth
                value={contraseniaRepetida}
                disabled={
                  !docIdValido
                  || rolesExistentes.includes(2)
                  || objAdministrador.password.length < 8
                  || errorPass.password.error
                }
                onChange={handleChangePasswordRepetido}
                onCopy={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onCut={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                helperText={errorPass.passwordRepetido.helperText}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
                InputProps={{
                  endAdornment: (
                    <div className="text-blue-500">
                      <InputAdornment position="end">
                        {isPasswordRepetidoCapsLockOn ? (
                          <Tooltip title={t('mayusculas_activadas')}>
                            <KeyboardCapslock />
                          </Tooltip>
                        ) : (
                          ''
                        )}
                        <IconButton
                          onClick={handleClickVerPasswordRepetido}
                          onMouseDown={handleMouseDownPasswordRepetido}
                          edge="end"
                          color="inherit"
                        >
                          {verPasswordRepetido ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: '50',
                  },
                }}
              />
            </div>
          </div>
        )}
        <div className="pt-4">
          <h4 className="text-gray-500 font-normal m-0">{t('campo-obligatorio')}</h4>
        </div>
        <div className="text-right pt-4">
          <GreenButton
            variant="contained"
            color="inherit"
            onClick={saveData}
            disabled={disableSave}
          >
            {objAdministrador.id > 0 ? t('actualizar') : t('agregar_administrador')}
          </GreenButton>
        </div>
      </div>
    </div>
  );
};

export default PerfilAdministrador;
