import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noTextTranform: {
    textTransform: 'none',
  },
  textColor: {
    color: '#2196f3',
  },
  height56: {
    minHeight: '56px',
  },
});

export default useStyles;
