import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Button } from '@material-ui/core';
// import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { IRowTablaDocumentosCargadosProps } from './types';
import { HtmlTooltip } from '../../../../utils/ComponentesJSX';
import { fechaISOADiaMesAnioStr } from '../../../../common/functions';

function RowTablaDocumentosCargados({
  idMedico,
  index,
  archivo,
  verArchivo,
  eliminarArchivo,
}: IRowTablaDocumentosCargadosProps) {
  const { t } = useTranslation();
  const {
    idArchivo,
    idMedico: idMedicoSubio,
    nombreArchivo,
    pathArchivo,
    // extensionArchivo,
    pesoArchivo,
    fechaCarga,
  } = archivo;
  const idLista = 0;
  return (
    <div
      className={`grid grid-cols-5 gap-4 ${
        index % 2 === 0 ? 'bg-blue-300' : ''
      } text-center items-center py-4`}
    >
      <div className="col-span-2">
        <HtmlTooltip title={nombreArchivo}>
          <Button
            fullWidth
            color="primary"
            onClick={() => verArchivo(idLista, idArchivo, nombreArchivo, pathArchivo)}
          >
            <p className="truncate m-0">{nombreArchivo}</p>
          </Button>
        </HtmlTooltip>
      </div>
      <div>{`${(pesoArchivo / 1048576).toFixed(2)} MB`}</div>
      <div>{fechaISOADiaMesAnioStr(fechaCarga)}</div>
      <div className="text-red-500">
        {idMedico !== idMedicoSubio ? (
          <HtmlTooltip title={`${t('no_se_puede_eliminar_archivos_adjuntados_por_otro_medico')}`}>
            <span>
              <IconButton disabled aria-label="borrar" color="inherit" size="small">
                <CloseIcon />
              </IconButton>
            </span>
          </HtmlTooltip>
        ) : (
          <IconButton
            aria-label="borrar"
            color="inherit"
            size="small"
            onClick={() => eliminarArchivo(idLista, idArchivo, nombreArchivo, pathArchivo)}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}

export default RowTablaDocumentosCargados;
