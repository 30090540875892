import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { RootState } from '../../../../../store';
import useStyles from './styles';
import Enfermedades from './Enfermedades';
import Tratamientos from './Tratamientos';
import { IEnfermedadesTratamientosProps } from './types';
import { IEnfermedad } from './Enfermedades/types';
import { IMedicamento } from '../../../DiagnosticoPlan/Plan/TerapiaFarmacologica/types';
import { IDiagnosticoGeneral } from '../DiagnosticosGenerales/types';

function EnfermedadesTratamientos(props: IEnfermedadesTratamientosProps) {
  const {
    enfReferidas,
    setEnfReferidas,
    catDiagnostico,
    setCatDiagnostico,
    enfNegadas,
    setEnfNegadas,
    grupoEnfNegadas,
    setGrupoEnfNegadas,
    diagnosticosGenerales,
    setDiagnosticosGenerales,
    tratamientos,
    setTratamientos,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [eActivas, setEActivas] = useState<IEnfermedad[]>([]);
  const [eResueltas, setEResueltas] = useState<IEnfermedad[]>([]);
  const [mActuales, setMActuales] = useState<IMedicamento[]>([]);
  const [mAnteriores, setMAnteriores] = useState<IMedicamento[]>([]);

  // UseEffect para llenar las listas dependendo del idPaciente
  useEffect(() => {
    const enfermedadesActivas = [] as IEnfermedad[];
    const enfermedadesResueltas = [] as IEnfermedad[];
    enfReferidas.forEach((e) => {
      if (e.estatusDelDiagnostico === 'activo') {
        enfermedadesActivas.push(e);
      } else if (e.estatusDelDiagnostico === 'resuelto') {
        enfermedadesResueltas.push(e);
      }
    });
    setEActivas(enfermedadesActivas);
    setEResueltas(enfermedadesResueltas);
    setCatDiagnostico(
      enfReferidas.map((enf: IEnfermedad) => ({
        id: enf.idBD,
        label:
          enf.seleccionDiagnostico === 'cie10' ? enf.diagnosticoCie10.label : enf.diagnosticoMedico,
      })),
    );
  }, [enfReferidas]);
  useEffect(() => {
    if (tratamientos.length) {
      const mediActual = [] as IMedicamento[];
      const mediAnterior = [] as IMedicamento[];
      tratamientos.forEach((medi) => {
        if (medi.actual) {
          mediActual.push(medi);
        } else {
          mediAnterior.push(medi);
        }
      });
      setMActuales(mediActual);
      setMAnteriores(mediAnterior);
    }
  }, [tratamientos]);

  return (
    <div>
      <h2 className="text-blue-800 font-normal mt-0">{t('antecedentes-pp-enfermedades')}</h2>
      <div className="ml-2">
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              setTabIndex(0);
            }}
            className={tabIndex === 0 ? classes.selected : classes.buttonColor}
            startIcon={
              idUsuario > 0
              && diagnosticosGenerales.contenidoTabla?.some(
                (diagnostico: IDiagnosticoGeneral) => diagnostico.posee === 'si' && !diagnostico.validado,
              ) ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('enfermedades')}
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              setTabIndex(1);
            }}
            className={tabIndex === 1 ? classes.selected : classes.buttonColor}
          >
            {t('terapia_farmacologica')}
          </Button>
        </ButtonGroup>
      </div>
      {tabIndex === 0 && (
        <Enfermedades
          eActivas={eActivas}
          eResueltas={eResueltas}
          // enfListaEnfReferidas={enfListaEnfReferidas}
          enfReferidas={enfReferidas}
          setEnfReferidas={setEnfReferidas}
          enfNegadas={enfNegadas}
          setEnfNegadas={setEnfNegadas}
          grupoEnfNegadas={grupoEnfNegadas}
          setGrupoEnfNegadas={setGrupoEnfNegadas}
          diagnosticosGenerales={diagnosticosGenerales}
          setDiagnosticosGenerales={setDiagnosticosGenerales}
        />
      )}
      {tabIndex === 1 && (
        <Tratamientos
          enfReferidas={enfReferidas}
          catDiagnostico={catDiagnostico}
          mActuales={mActuales}
          mAnteriores={mAnteriores}
          setCatMedicamentos={setTratamientos}
          catMedicamentos={tratamientos}
        />
      )}
    </div>
  );
}

export default EnfermedadesTratamientos;
