import { Dispatch, SetStateAction } from 'react';
import {
  ICatalogosCie10, ICatalogosEstandar, IEdad, edadInitial,
} from '../../../../common/types';

export interface IFamiliarEnfermedades {
  idEnfermedad: number;
  diagnostico: string;
  diagnosticoCie10: ICatalogosCie10;
  diagnosticoMedico: string;
  edadAlDiagnostico: IEdad;
  esCausaDeMuerte: boolean;
  idFamiliar: number;
}

export const familiarEnfermedadesInitial: IFamiliarEnfermedades = {
  idEnfermedad: 0,
  diagnostico: 'cie10',
  diagnosticoCie10: { id: '', label: '', grupoEnfermedades: 0 },
  diagnosticoMedico: '',
  edadAlDiagnostico: edadInitial,
  esCausaDeMuerte: false,
  idFamiliar: 0,
};

export interface IFamiliaresEnfermedadesNegadas {
  idEnfermedadNegada: number;
  diagnosticoCie10: ICatalogosCie10;
  idFamiliar: number;
}

export const familiarEnfermedadesNegadasInitial: IFamiliaresEnfermedadesNegadas = {
  idEnfermedadNegada: 0,
  diagnosticoCie10: { id: '', label: '', grupoEnfermedades: 0 },
  idFamiliar: 0,
};

export interface IFamiliaresGrupoEnfermedadesNegadas {
  idGrupoEnfermedadesNegadas: number;
  idFamiliar: number;
  infecciosasYParasitariasSelected: boolean;
  infecciosasYParasitariasDisabled: boolean;
  neoplasiasSelected: boolean;
  neoplasiasDisabled: boolean;
  hematologicasOQueAfectanElMecanismoDeLaInmunidadSelected: boolean;
  hematologicasOQueAfectanElMecanismoDeLaInmunidadDisabled: boolean;
  endocrinasNutricionalesYMetabolicasSelected: boolean;
  endocrinasNutricionalesYMetabolicasDisabled: boolean;
  trastornosMentalesYDelComportamientoSelected: boolean;
  trastornosMentalesYDelComportamientoDisabled: boolean;
  sistemaNerviosoSelected: boolean;
  sistemaNerviosoDisabled: boolean;
  delOjoYSusAnexosSelected: boolean;
  delOjoYSusAnexosDisabled: boolean;
  delOidoYApofisisMastoideSelected: boolean;
  delOidoYApofisisMastoideDisabled: boolean;
  sistemaCirculatorioSelected: boolean;
  sistemaCirculatorioDisabled: boolean;
  sistemaRespiratorioSelected: boolean;
  sistemaRespiratorioDisabled: boolean;
  sistemaDigestivoSelected: boolean;
  sistemaDigestivoDisabled: boolean;
  pielYDelTejidoSubcutaneoSelected: boolean;
  pielYDelTejidoSubcutaneoDisabled: boolean;
  sistemaOsteomuscularYDelTejidoConjuntivoSelected: boolean;
  sistemaOsteomuscularYDelTejidoConjuntivoDisabled: boolean;
  sistemaGenitourinarioSelected: boolean;
  sistemaGenitourinarioDisabled: boolean;
  embarazoPartoPuerperioSelected: boolean;
  embarazoPartoPuerperioDisabled: boolean;
  delPeriodoPerinatalSelected: boolean;
  delPeriodoPerinatalDisabled: boolean;
  malformaconesCongenitasYAnomaliasCongenitasSelected: boolean;
  malformaconesCongenitasYAnomaliasCongenitasDisabled: boolean;
  traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasSelected: boolean;
  traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasDisabled: boolean;
}

export const familiaresGrupoEnfermedadesNegadasInitial: IFamiliaresGrupoEnfermedadesNegadas = {
  idFamiliar: 0,
  idGrupoEnfermedadesNegadas: 0,
  infecciosasYParasitariasSelected: false,
  infecciosasYParasitariasDisabled: false,
  neoplasiasSelected: false,
  neoplasiasDisabled: false,
  hematologicasOQueAfectanElMecanismoDeLaInmunidadSelected: false,
  hematologicasOQueAfectanElMecanismoDeLaInmunidadDisabled: false,
  endocrinasNutricionalesYMetabolicasSelected: false,
  endocrinasNutricionalesYMetabolicasDisabled: false,
  trastornosMentalesYDelComportamientoSelected: false,
  trastornosMentalesYDelComportamientoDisabled: false,
  sistemaNerviosoSelected: false,
  sistemaNerviosoDisabled: false,
  delOjoYSusAnexosSelected: false,
  delOjoYSusAnexosDisabled: false,
  delOidoYApofisisMastoideSelected: false,
  delOidoYApofisisMastoideDisabled: false,
  sistemaCirculatorioSelected: false,
  sistemaCirculatorioDisabled: false,
  sistemaRespiratorioSelected: false,
  sistemaRespiratorioDisabled: false,
  sistemaDigestivoSelected: false,
  sistemaDigestivoDisabled: false,
  pielYDelTejidoSubcutaneoSelected: false,
  pielYDelTejidoSubcutaneoDisabled: false,
  sistemaOsteomuscularYDelTejidoConjuntivoSelected: false,
  sistemaOsteomuscularYDelTejidoConjuntivoDisabled: false,
  sistemaGenitourinarioSelected: false,
  sistemaGenitourinarioDisabled: false,
  embarazoPartoPuerperioSelected: false,
  embarazoPartoPuerperioDisabled: false,
  delPeriodoPerinatalSelected: false,
  delPeriodoPerinatalDisabled: false,
  malformaconesCongenitasYAnomaliasCongenitasSelected: false,
  malformaconesCongenitasYAnomaliasCongenitasDisabled: false,
  traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasSelected: false,
  traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasDisabled: false,
};

export interface IFamiliar {
  idFamiliar: number;
  parentesco: ICatalogosEstandar;
  vive: boolean;
  nombreApellidos: string;
  idPaciente: number;
  listaEnfermedades: IFamiliarEnfermedades[];
  enfermedadesNegadas: IFamiliaresEnfermedadesNegadas[];
  grupoEnfermedadesNegadas: IFamiliaresGrupoEnfermedadesNegadas;
}

export interface IFamComprimido {
  idFamiliar: number;
  parentesco: ICatalogosEstandar;
  vive: boolean;
  nombreApellidos: string;
  idPaciente: number;
}

export interface IFamiliaresProps {
  familiares: IFamiliar[];
  setFamiliares: Dispatch<SetStateAction<IFamiliar[]>>;
}
