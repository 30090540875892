import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Header from './Header';
import Footer from './Footer';
import { ICertificadoProps } from './types';

const Certificado = React.forwardRef(
  (
    { certificado, catRestricciones, urlLogo }: ICertificadoProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();

    return (
      <div ref={ref}>
        <Header urlLogo={urlLogo} />
        <div className="m-8">
          <h2 className="text-gray-600 mb-8 text-center">{t('certificado').toUpperCase()}</h2>
          <div className="text-gray-600 text-right font-bold">
            <p>{`${t('fecha_elaboracion')}: ${dayjs().format('DD MMMM YYYY')}`}</p>
            <p>{`${t('tipo_examen')}: ${certificado.tipoExamen.label}`}</p>
          </div>
          <div className="inline-block w-full text-justify">
            <p className="text-gray-600 m-0 mt-8">{certificado.certifica}</p>
            {(certificado.tipoExamen.id === 2
              || [1, 2, 4, 6, 7, 9].includes(certificado.dictamen.id)) && (
              <div className="m-0 mt-8">
                <p className="text-gray-600">
                  <span className="font-bold">{`${t('restricciones')}: `}</span>
                  <span>
                    {(certificado.sinRestricciones
                      || [1, 4, 7].includes(certificado.dictamen.id))
                      && t('sin_restricciones')}
                    {((certificado.tipoExamen.id === 2 && !certificado.sinRestricciones)
                      || (certificado.tipoExamen.id !== 2
                        && [2, 6, 9].includes(certificado.dictamen.id))) && (
                        <div className="ml-4">
                          <p className="m-0">
                            <span>{t('restriccion_total')}</span>
                            <span>: </span>
                            <span>
                              {catRestricciones
                                .filter((r) => certificado.arrayRestriccionTotal.includes(`${r.source}-${r.id}`))
                                .map((r) => r.label)
                                .join(', ')}
                            </span>
                          </p>
                          <p className="m-0">
                            <span>{t('restriccion_parcial')}</span>
                            <span>: </span>
                            <span>
                              {catRestricciones
                                .filter((r) => certificado.arrayRestriccionParcial.includes(`${r.source}-${r.id}`))
                                .map((r) => r.label)
                                .join(', ')}
                            </span>
                          </p>
                          <p className="m-0">
                            <span>{t('comentarios_restricciones')}</span>
                            <span>: </span>
                            <span>{certificado.comentariosRestricciones}</span>
                          </p>
                        </div>
                    )}
                  </span>
                </p>
              </div>
            )}
            <p className="text-gray-600 m-0 mt-8">
              <span className="font-bold">{`${t('recomendaciones')}: `}</span>
              <span>{certificado.recomendaciones}</span>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  },
);

export default Certificado;
