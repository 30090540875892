import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    justifyContent: 'left',
    textTransform: 'none',
  },
  selectedButton: {
    justifyContent: 'left',
    textTransform: 'none',
    backgroundColor: '#1E40AF',
  },
  backgroundWhite: {
    backgroundColor: 'white',
  },
});

export default useStyles;
