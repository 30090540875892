import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  Slide,
  IconButton,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { IModalTiempoExposicionProps } from './types';
import { validacionSoloNumeros } from '../../../../../../../constants/validaciones';
import { ITiempoSeleccionComentarios } from '../../../types';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function ModalTiempoExposicion(props: IModalTiempoExposicionProps) {
  const {
    open, callBackClose, callBackSave, callBackUpdate, nombre, datoActualizar,
  } = props;
  const { t } = useTranslation();
  const [tiempoSeleccionComentarios, setTiempoSeleccionComentarios] = useState<ITiempoSeleccionComentarios>({
    id: -1,
    txtTiempo: '',
    radioSeleccion: undefined,
    comentarios: '',
  });
  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    callBackClose(event);
    setTiempoSeleccionComentarios({
      id: -1,
      txtTiempo: '',
      radioSeleccion: undefined,
      comentarios: '',
    });
  };
  useEffect(() => {
    if (datoActualizar.id !== -1) {
      setTiempoSeleccionComentarios({
        id: datoActualizar.id,
        txtTiempo: datoActualizar.txtTiempo || '',
        radioSeleccion: datoActualizar.radioSeleccion,
        comentarios: datoActualizar.comentarios,
      });
    } else {
      setTiempoSeleccionComentarios({
        id: -1,
        txtTiempo: '',
        radioSeleccion: undefined,
        comentarios: '',
      });
    }
  }, [datoActualizar]);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
        <h2 className="font-normal">{nombre}</h2>
        <IconButton aria-label="cerrar" color="inherit">
          <CloseIcon onClick={(e) => handleClose(e)} />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2 text-left">
            {`${t('antecedentes_laborales_modal_tiempo_exposicion')}*`}
          </h3>
        </div>
        <div className="grid grid-cols-4 gap-4 mb-2">
          <TextField
            id="txtTiempo"
            value={tiempoSeleccionComentarios.txtTiempo}
            onChange={(e) => {
              const valorNumerico = e.target.value;
              if (valorNumerico === '' || validacionSoloNumeros(valorNumerico)) {
                setTiempoSeleccionComentarios({
                  ...tiempoSeleccionComentarios,
                  txtTiempo: e.target.value,
                });
              }
            }}
            inputProps={{
              maxLength: 2,
              autoComplete: 'off',
            }}
            variant="outlined"
            fullWidth
          />
          <RadioGroup
            row
            aria-label="empresa"
            name="empresa"
            value={tiempoSeleccionComentarios.radioSeleccion}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTiempoSeleccionComentarios({
                ...tiempoSeleccionComentarios,
                radioSeleccion: (event.target as HTMLInputElement).value,
              });
            }}
          >
            <FormControlLabel value="H" control={<Radio color="primary" />} label={t('hora_s_')} />
            <FormControlLabel
              value="M"
              control={<Radio color="primary" />}
              label={t('minuto_s_')}
            />
          </RadioGroup>
        </div>
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2 text-left">{t('comentarios')}</h3>
        </div>
        <TextField
          id="comentarios"
          placeholder={t('comentarios')}
          value={tiempoSeleccionComentarios.comentarios}
          onChange={(e) => {
            setTiempoSeleccionComentarios({
              ...tiempoSeleccionComentarios,
              comentarios: e.target.value,
            });
          }}
          inputProps={{
            maxLength: 400,
            autoComplete: 'off',
          }}
          multiline
          rows="4"
          variant="outlined"
          fullWidth
        />
        <div className="w-full flex justify-between py-2">
          <p className="text-gray-600">
            * &nbsp;
            {t('campos_obligatorios')}
          </p>
          <div className="flex flex-row">
            <div className="pr-2">
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={(e) => {
                  handleClose(e);
                }}
              >
                {t('cancelar')}
              </Button>
            </div>
            <div>
              {tiempoSeleccionComentarios && tiempoSeleccionComentarios.id === -1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="large"
                  onClick={(e) => {
                    callBackSave(tiempoSeleccionComentarios);
                    handleClose(e);
                  }}
                  disabled={
                    tiempoSeleccionComentarios.txtTiempo === ''
                    || tiempoSeleccionComentarios.radioSeleccion === undefined
                  }
                >
                  {t('guardar')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="large"
                  onClick={(e) => {
                    callBackUpdate(tiempoSeleccionComentarios);
                    handleClose(e);
                  }}
                  disabled={
                    tiempoSeleccionComentarios.txtTiempo === ''
                    && tiempoSeleccionComentarios.radioSeleccion === undefined
                  }
                >
                  {t('actualizar')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ModalTiempoExposicion;
