import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField, Radio, Button, Checkbox,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IConstructorOpcionMultiple } from './types';
import useStyles from './styles';
import { capitalize } from '../../../../common/functions';

function OpcionMultiple({
  variable,
  setVariable,
  errores,
  setErrores,
  setDisableContinuar,
  paso,
}: IConstructorOpcionMultiple) {
  const { t } = useTranslation();
  const classes = useStyles();
  /** States del functional component */
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  /** Catalogos */
  /** Logica */
  const agregarOpcion = () => {
    setVariable((preCons) => {
      let arrOp = preCons.constructor.casillasOpciones!.arrOpciones;
      if (arrOp.length < 12) {
        arrOp = preCons.constructor.casillasOpciones!.arrOpciones.concat({
          nombreOpcion: '',
          checked: false,
          arraySubOpciones: [],
        });
      }
      return {
        ...preCons,
        constructor: {
          ...preCons.constructor,
          casillasOpciones: {
            arrOpciones: arrOp,
          },
        },
      };
    });
  };

  const agregarSubOpcion = (indx: number) => {
    setVariable((preCons) => {
      const arrOp = preCons.constructor.casillasOpciones!.arrOpciones.map((op, ind) => {
        if (indx === ind) {
          let arrSub = op.arraySubOpciones;
          if (arrSub.length < 10) {
            arrSub = op.arraySubOpciones.concat({
              nombreOpcion: '',
              checked: false,
            });
          }
          return {
            ...op,
            arraySubOpciones: arrSub,
          };
        }
        return op;
      });
      return {
        ...preCons,
        constructor: {
          ...preCons.constructor,
          casillasOpciones: {
            arrOpciones: arrOp,
          },
        },
      };
    });
  };
  /** Hooks */
  /** Efecto al cargar la pagina */
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers de la snackbar */
  /** Handlers del componente */
  const handleChangeDescripcion = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setVariable((preCons) => ({
      ...preCons,
      datosComponente: {
        ...preCons.datosComponente,
        descripcion: val,
      },
      constructor: {
        ...preCons.constructor,
        descripcion: val,
      },
    }));
  };

  const handleChangeTextoOpcionMultiple = (
    e: React.ChangeEvent<{ value: unknown }>,
    indx: number,
  ) => {
    const val = e.target.value as string;
    setVariable((preCons) => {
      const arrOp = preCons.constructor.casillasOpciones!.arrOpciones.map((arrO, ind) => {
        if (ind === indx) {
          return {
            ...arrO,
            nombreOpcion: val,
          };
        }
        return arrO;
      });
      return {
        ...preCons,
        constructor: {
          ...preCons.constructor,
          casillasOpciones: {
            arrOpciones: arrOp,
          },
        },
      };
    });
    setErrores((preE) => ({ ...preE, errorAlMenosDosOpciones: false }));
    setDisableContinuar(false);
  };

  const handleChangeCasillaSubopcion = (
    e: React.ChangeEvent<{ value: unknown }>,
    indxi: number, // indice de la opcion
    indxj: number, // indice de la subopcion
  ) => {
    const val = e.target.value as string;
    setVariable((preCons) => {
      const arrOp = preCons.constructor.casillasOpciones!.arrOpciones.map((arrO, indi) => {
        if (indi === indxi) {
          const arrSub = arrO.arraySubOpciones.map((arrS, indj) => {
            if (indj === indxj) {
              return {
                ...arrS,
                nombreOpcion: val,
              };
            }
            return arrS;
          });
          return {
            ...arrO,
            arraySubOpciones: arrSub,
          };
        }
        return arrO;
      });
      return {
        ...preCons,
        constructor: {
          ...preCons.constructor,
          casillasOpciones: {
            arrOpciones: arrOp,
          },
        },
      };
    });
  };
  /** handles de disabled components */
  /** Handlers para enviar la informacion */
  /** handler del evento submit */
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="flex mb-4 items-end w-full">
        <h3 className="w-max mr-4 mb-2 mt-0 text-gray-600 font-medium">
          {capitalize(variable.datosComponente.titulo)}
        </h3>
        <div className="w-1/3 flex-wrap">
          <h3 className="mt-0 mb-2 text-gray-600 font-medium">
            {t('incluye_una_descripcion_opcional')}
          </h3>
          <TextField
            fullWidth
            value={variable.datosComponente.descripcion}
            onChange={handleChangeDescripcion}
            inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            multiline
            rows={1}
          />
        </div>
      </div>
      <div>
        {variable.constructor.casillasOpciones!.arrOpciones.map((singleArr, indx) => (
          <div key={`sigleOpcion-${String(indx)}`}>
            <div className="flex items-center my-2">
              <Radio
                color="default"
                name={`radio-button-${indx + 1}`}
                disabled
                className={classes.nopaddingIzquierdo}
              />
              {variable.constructor.casillasOpciones!.arrOpciones.length - 1 === indx ? (
                <TextField
                  id={`texfield-opcion-multiple-${indx + 1}`}
                  label={`${t('escriba_opcion')} ${indx + 1}`}
                  value={singleArr.nombreOpcion}
                  onChange={(e) => handleChangeTextoOpcionMultiple(e, indx)}
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 55, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                  error={errores.errorAlMenosDosOpciones}
                  helperText={
                    errores.errorAlMenosDosOpciones ? t('escribe_una_segunda_opcion') : ''
                  }
                  disabled={paso > 0}
                />
              ) : (
                <TextField
                  id={`texfield-opcion-multiple-${indx + 1}`}
                  label={`${t('escriba_opcion')} ${indx + 1}`}
                  value={singleArr.nombreOpcion}
                  onChange={(e) => handleChangeTextoOpcionMultiple(e, indx)}
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 55, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                  disabled={paso > 0}
                />
              )}
            </div>

            {paso > 0 && (
              <div className="pl-8 grid grid-cols-3 gap-x-8">
                {singleArr.arraySubOpciones.map((singleCasilla, indice) => (
                  <div className="my-3 flex align-center" key={`${String(indx)}-${String(indice)}`}>
                    <Checkbox disabled className={classes.nopaddingIzquierdo} />
                    <TextField
                      id={`textfield-${indx}-${indice + 1}`}
                      label={`${t('escriba_sub_opcion')} ${indice + 1}`}
                      value={singleCasilla.nombreOpcion}
                      variant="outlined"
                      onChange={(e) => handleChangeCasillaSubopcion(e, indx, indice)}
                      inputProps={{
                        maxLength: 13,
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                      }}
                      disabled={paso !== 1}
                      fullWidth
                    />
                  </div>
                ))}
              </div>
            )}

            {paso === 1 && (
              <div className="flex justify-start pl-8 mb-4">
                {!singleArr.arraySubOpciones.length && <Checkbox disabled />}
                <Button
                  color="primary"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => agregarSubOpcion(indx)}
                  disabled={
                    !(
                      variable.constructor.casillasOpciones!.arrOpciones[indx].arraySubOpciones
                        .length < 10
                    )
                  }
                >
                  {t('agregar_sub_opciones')}
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>

      {paso === 0 && (
        <div className="flex justify-end mb-4">
          <Button
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={agregarOpcion}
            disabled={!(variable.constructor.casillasOpciones!.arrOpciones.length < 12)}
          >
            {t('agregar_opcion')}
          </Button>
        </div>
      )}

      {paso === 2 && (
        <TextField
          variant="outlined"
          rows={6}
          multiline
          fullWidth
          disabled
          label={t('comentarios')}
          className="mt-4"
        />
      )}
    </div>
  );
}

export default OpcionMultiple;
