import { Dispatch, SetStateAction } from 'react';
import { RawDraftContentState } from 'draft-js';
import { ICatStrEstandar, ICatalogosEstandar } from '../../../../../common/types';
import { IProcedimiento } from '../../../Antecedentes/PersonalesPatologicos/Procedimiento/types';
import { IEnfermedad } from '../../../Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';
import { IExploracionFisica } from '../../../ExploracionFisica/types';

export interface IProcedimientoSolicitado {
  idBd: number;
  selector: string;
  cie9: ICatStrEstandar;
  txtLibre: string;
  actualizado: boolean;
}

export const procedimientoSolicitadoInitial: IProcedimientoSolicitado = {
  idBd: -1,
  selector: '',
  cie9: { id: '', label: '' },
  txtLibre: '',
  actualizado: false,
};

interface ICheckAll {
  check1: boolean;
  check2: boolean;
  check3: boolean;
}

export interface ISolicitudProcedimientos {
  idSolicitud: number;
  diagnosticoArray: string[];
  procedimientosArray: IProcedimientoSolicitado[];
  datosClinicos: RawDraftContentState;
  checkAll: ICheckAll;
  txtResumen: string;
}

export const solicitudProcedimientoInitial: ISolicitudProcedimientos = {
  idSolicitud: -1,
  diagnosticoArray: [],
  procedimientosArray: [procedimientoSolicitadoInitial],
  datosClinicos: { entityMap: {}, blocks: [] },
  checkAll: { check1: false, check2: false, check3: false },
  txtResumen: '',
};

export interface IProcedimientosProps {
  tipo: string;
  lenfermedades: IEnfermedad[];
  solProcedimientos: ISolicitudProcedimientos[];
  setSolProcedimientos: Dispatch<SetStateAction<ISolicitudProcedimientos[]>>;
  sinProcedimientos: boolean;
  setSinProcedimientos: Dispatch<SetStateAction<boolean>>;
  procedimientos: IProcedimiento[];
  setProcedimientos: Dispatch<SetStateAction<IProcedimiento[]>>;
  catDiagnostico: ICatalogosEstandar[];
  exploracionFisica: IExploracionFisica;
  enGestacion: '' | 'si' | 'no';
  setEnGestacion: Dispatch<SetStateAction<'' | 'si' | 'no'>>;
}
