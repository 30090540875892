export const CHANGE_ROL = 'CHANGE_ROL';
export const CHANGE_TOKEN = 'CHANGE_TOKEN';
export const CHANGE_USER = 'CHANGE_USER';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SET_LOGIN = 'SET_LOGIN';

interface ILogin {
  user: string;
  token: string;
  rol: string;
}

export interface ILoginState {
  user: string;
  token: string;
  rol: string;
  mensaje: string;
}

interface ChangeRolAction {
  type: typeof CHANGE_ROL;
  payload: string;
}

interface ChangeTokenAction {
  type: typeof CHANGE_TOKEN;
  payload: string;
}

interface ChangeUserAction {
  type: typeof CHANGE_USER;
  payload: string;
}

interface LoginError {
  type: typeof LOGIN_ERROR;
  payload: string;
}

interface SetLogin {
  type: typeof SET_LOGIN;
  payload: ILogin;
}

export type LoginActionTypes =
  | ChangeRolAction
  | ChangeTokenAction
  | ChangeUserAction
  | LoginError
  | SetLogin;
