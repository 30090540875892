import {
  AlergiasURLAlergia,
  saveProcedimientos,
  antecedentesFamiliaresEditarListaFamiliares,
  consultasMedicasGuardarPacienteConsulta,
  consultasMedicasFinalizarPacienteConsulta,
  FarmacologicoGuardarMedicamento,
  HospitalizacionesURLHospitalizacion,
  TransfusionesURLTransfusion,
  TransfusionesURLUsuarioPacienteTransfusion,
  saveAgendaConfigurar,
  builderURLFCActualizar,
  MCPAURL,
  builderURLHabilitarUsoMCMedipraxi,
  enfermedadesEnfermedadReferidaURL,
  enfermedadesEnfermedadNegadaURL,
  pronosticoURL,
} from './commonUrl';
import { getToken } from './commonStore';

function fetchPutInfo(url: string, obj: any) {
  return fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function editAntecedentesFamiliaresEnfermedades(obj: any) {
  return fetchPutInfo(antecedentesFamiliaresEditarListaFamiliares(), obj);
}

export function editProcedimientos(obj: any) {
  return fetchPutInfo(saveProcedimientos(), obj);
}

export function editAntecedentesFamEnf(obj: any) {
  return fetchPutInfo(antecedentesFamiliaresEditarListaFamiliares(), obj);
}

export function editTerapiaFarmacologicaMedicamento(obj: any) {
  return fetchPutInfo(FarmacologicoGuardarMedicamento(), obj);
}

export function editConsultasMedicasGuardarConsulta(obj: any) {
  return fetchPutInfo(consultasMedicasGuardarPacienteConsulta(), obj);
}

export function editConsultasMedicasFinalizarConsulta(obj: any) {
  return fetchPutInfo(consultasMedicasFinalizarPacienteConsulta(), obj);
}

export function alergiasActualizarAlergia(obj: any) {
  return fetchPutInfo(AlergiasURLAlergia(), obj);
}

export function transfusionesActualizarTranfusion(obj: any) {
  return fetchPutInfo(TransfusionesURLTransfusion(), obj);
}

export function transfusionesUsuarioPacienteActualizarTranfusion(obj: any) {
  return fetchPutInfo(TransfusionesURLUsuarioPacienteTransfusion(), obj);
}

export function hospitalizacionesActualizarHospitalizacion(obj: any) {
  return fetchPutInfo(HospitalizacionesURLHospitalizacion(), obj);
}

export function sendAgendaActualizar(obj: any) {
  return fetchPutInfo(saveAgendaConfigurar(), obj);
}

export function sendFormatoConsultaActualizar(obj: any) {
  return fetchPutInfo(builderURLFCActualizar(), obj);
}

export function mcpaEditarMCPA(obj: any) {
  return fetchPutInfo(MCPAURL(), obj);
}

export function editHandleToggleHabilitarUso(obj: any) {
  return fetchPutInfo(builderURLHabilitarUsoMCMedipraxi(), obj);
}

export function enfermedadesEditReferida(obj: any) {
  return fetchPutInfo(enfermedadesEnfermedadReferidaURL(), obj);
}

export function enfermedadesEditNegada(obj: any) {
  return fetchPutInfo(enfermedadesEnfermedadNegadaURL(), obj);
}

export function sendPronosticoActualizar(obj: any) {
  return fetchPutInfo(pronosticoURL(), obj);
}
