import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  typographyRoot: {
    textTransform: 'uppercase',
    paddingTop: '1.875rem',
    paddingBottom: '1.875rem',
  },
  tabRoot: {
    minWidth: 'auto',
    backgroundColor: '#DDDDDD',
    color: '#1E467E',
    borderRadius: '5px 5px 0 0',
    borderLeft: '1px solid white',
    borderRight: '1px solid white',
    textTransform: 'none',
  },
  buttonRoot: {
    minWidth: 'auto',
    backgroundColor: '#3B82F6',
    color: 'white',
    borderRadius: '5px 5px 0 0',
    borderLeft: '1px solid white',
    borderRight: '1px solid white',
    '&:hover': {
      backgroundColor: '#2563EB',
    },
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  buttonSelected: {
    minWidth: 'auto',
    backgroundColor: '#1E40AF',
    color: 'white',
    borderRadius: '5px 5px 0 0',
    borderLeft: '1px solid #DDDDDD',
    borderRight: '1px solid #DDDDDD',
    borderTop: '1px solid #DDDDDD',
    '&:hover': {
      backgroundColor: '#2563EB',
    },
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  buttonLabel: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  tabSelected: {
    backgroundColor: 'white',
  },
  backgroundRootPaper: {
    paddingLeft: '10px',
  },
});

export default useStyles;
