import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/es-mx';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import Dialog from './Dialog/index';
import Configurar from './Configurar/index';
import { RootState } from '../../store';
import { IObjetoGuardado, INewEventProps, IFechaActual } from './types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import useStyles from './styles';
import { getAgendaConfiguracionURL, getToken } from '../../utils/common';
import { setSnackComplete } from '../../actions/snackbar/types';
import { sendAgenda, traerAgenda } from '../../utils/sendInfo';
import { agendaEliminarCita } from '../../utils/deleteInfo';

const { Calendar, momentLocalizer } = require('react-big-calendar');
const withDragAndDrop = require('react-big-calendar/lib/addons/dragAndDrop');

const DragAndDropCalendar = withDragAndDrop(Calendar);
moment.locale('es-MX');
const localizer = momentLocalizer(moment);

const AgendaComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { secciones } = useSelector((state: RootState) => state.Me);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const [fechaPeticion, setFechaPeticion] = useState<IFechaActual>({
    mes: dayjs().format('MM'),
    anio: dayjs().format('YYYY'),
  });
  const [open, setOpen] = useState<boolean>(false);
  const [opConf, setOpConf] = useState<boolean>(false);
  const [datoGuardar, setDatoGuardar] = useState<IObjetoGuardado>({
    idAgenda: -1,
    idConsultorio,
    title: '',
    allDay: false,
    start: new Date(),
    end: new Date(),
    tipo: {
      id: 0,
      label: '',
    },
    idAuto: {
      id: 0,
      nombre: '',
      docId: '',
    },
    nuevoPaciente: '',
    telefono: '',
    ladaTel: '',
    email: '',
    selector: -1,
    descripcion: '',
    conteoEvento: '',
    radio: 'pre',
    tab: 'evento',
  });
  const [events, setEvents] = useState<any[]>([]);
  const [arrayCita, setArrayCita] = useState<INewEventProps[]>([]);
  const ColorCellWrapp = ({ children }: any) => React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  });
  const eventStyleGetter = (event: any) => {
    // se coloca un color de default para revisar el evento
    let backgroundColor = '#BABFCA';
    const color = arrayCita.find((element) => element.id === event.tipo.id);
    if (color) {
      backgroundColor = color.etiqueta;
    } else if (event.tipo.id === -10) {
      // color para tarea
      backgroundColor = '#FEF9C3';
    } else if (event.tipo.id === -11) {
      // color para recordatorio
      backgroundColor = '#ECFCCB';
    } else if (event.tipo.id === -12) {
      // color para fuera de consultorio
      backgroundColor = '#F3E8FF';
    }
    const style = {
      backgroundColor,
      borderRadius: '0px',
      color: 'black',
      border: '0px',
      display: 'block',
    };
    return {
      style,
    };
  };
  const handleInicioAgregar = ({ start, end, slots }: any) => {
    if (
      secciones.length > 0
      && secciones.find((valor) => valor.idSeccion >= 3 && valor.idSeccion <= 6)
    ) {
      const updateData = {
        idAgenda: -1,
        idConsultorio,
        title: '',
        allDay: false,
        start: new Date(start),
        end:
          slots && slots.length === 1
            ? new Date(dayjs(start).add(1, 'hour').toDate())
            : new Date(end),
        tipo: {
          id: 0,
          label: '',
        },
        telefono: '',
        ladaTel: '',
        email: '',
        selector: -1,
        descripcion: '',
        conteoEvento: '',
        radio: 'pre',
        idAuto: {
          id: 0,
          nombre: '',
          docId: '',
        },
        nuevoPaciente: '',
        tab: 'evento',
      };
      setDatoGuardar(updateData);
      setOpen(true);
    }
  };
  const obtenerDatos = () => {
    const token = getToken();
    traerAgenda(me.idMedico.toString(), fechaPeticion.mes, fechaPeticion.anio, token)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          const cleanData = result.datos.map((valor: any) => {
            const objNew = { ...valor };
            objNew.end = new Date(valor.end);
            objNew.start = new Date(valor.start);
            return objNew;
          });
          setEvents(cleanData);
        }
      })
      .catch(() => {
        setEvents([]);
      });
  };
  const handleFinAgregar = () => {
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    sendAgenda({
      idMedico: me.idMedico.toString(),
      datos: datoGuardar,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          obtenerDatos();
        } else if (result.code === 300) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'warning',
              mensaje: t('calendar-agenda-existe-evento'),
            }),
          );
          obtenerDatos();
        }
      })
      .catch((error) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${error.toString()}`,
          }),
        );
      });
    // lo comentado se debe de verificar para quitarse
    // traerAgenda(me.idMedico.toString(), fechaPeticion.mes, fechaPeticion.anio, getToken())
    //   .then((response) => response.json())
    //   .then((result) => {
    //     if (result.code === 200) {
    //       dispatch(
    //         setSnackComplete({
    //           open: true,
    //           severity: 'success',
    //           mensaje: t('message-success'),
    //         }),
    //       );
    //       const updateEvents = [...events];
    //       if (datoGuardar.idAgenda === -1) {
    //         const updEvent = { ...datoGuardar };
    //         updEvent.idAgenda = result.datos[0].id;
    //         updateEvents.push(updEvent);
    //       } else {
    //         const col = updateEvents.findIndex((val) => val.idAgenda === datoGuardar.idAgenda);
    //         updateEvents[col] = datoGuardar;
    //       }
    //       setDatoGuardar({
    //         idAgenda: -1,
    //         title: '',
    //         allDay: false,
    //         start: new Date(),
    //         end: new Date(),
    //         tipo: {
    //           id: 0,
    //           label: '',
    //         },
    //         telefono: '',
    //         ladaTel: '',
    //         email: '',
    //         selector: -1,
    //         descripcion: '',
    //         conteoEvento: '',
    //         radio: 'pre',
    //         idAuto: {
    //           id: 0,
    //           label: '',
    //         },
    //         nuevoPaciente: '',
    //         tab: 'evento',
    //       });
    //       setEvents(updateEvents);
    //     } else {
    //       let mensaje = '';
    //       if (/UNIQUE KEY/i.test(result.msg)) {
    //         mensaje = t('campo_repetido');
    //       } else {
    //         mensaje = result.msg;
    //       }
    //       dispatch(
    //         setSnackComplete({
    //           open: true,
    //           severity: 'error',
    //           mensaje: `${t('message-error')} ${mensaje}`,
    //         }),
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch(
    //       setSnackComplete({
    //         open: true,
    //         severity: 'error',
    //         mensaje: `${t('message-error-warning')} ${error.toString()}`,
    //       }),
    //     );
    //   });
  };
  const handleSelectEvent = (event: any) => {
    if (
      secciones.length > 0
      && secciones.find((valor) => valor.idSeccion >= 3 && valor.idSeccion <= 6)
    ) {
      setDatoGuardar(event);
      setOpen(true);
    }
  };
  const handleDeleteCita = () => {
    agendaEliminarCita({
      idMedico,
      idAgenda: datoGuardar.idAgenda,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-delete-success'),
            }),
          );
          setOpen(false);
          setDatoGuardar({
            idAgenda: -1,
            idConsultorio,
            title: '',
            allDay: false,
            start: new Date(),
            end: new Date(),
            tipo: {
              id: 0,
              label: '',
            },
            telefono: '',
            ladaTel: '',
            email: '',
            selector: -1,
            descripcion: '',
            conteoEvento: '',
            radio: 'pre',
            idAuto: {
              id: 0,
              nombre: '',
              docId: '',
            },
            nuevoPaciente: '',
            tab: 'evento',
          });
          const token = getToken();
          traerAgenda(me.idMedico.toString(), fechaPeticion.mes, fechaPeticion.anio, token)
            .then((response) => response.json())
            .then((result2) => {
              if (result2.code === 200) {
                const cleanData = result2.datos.map((valor: any) => {
                  const objNew = { ...valor };
                  objNew.end = new Date(valor.end);
                  objNew.start = new Date(valor.start);
                  return objNew;
                });
                setEvents(cleanData);
              }
            })
            .catch(() => {
              setEvents([]);
            });
          // setEvents(updateEvents);
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = t('campo_repetido');
          } else {
            mensaje = result.msg;
          }
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-delete')} ${error.toString()}`,
          }),
        );
      });
  };
  useEffect(() => {
    const token = getToken();
    fetch(getAgendaConfiguracionURL(me.idMedico.toString()), {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setArrayCita(result.datos);
        }
      })
      .catch(() => {
        setArrayCita([]);
      });
  }, []);
  useEffect(() => {
    obtenerDatos();
  }, [fechaPeticion]);
  return (
    <div className="p-4">
      <div className="flex justify-between items-center pb-4">
        <h1 className="text-blue-800 font-normal m-0 col-span-2">{t('calendar-agenda')}</h1>
        {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 2) && (
          <Button
            color="primary"
            size="small"
            onClick={() => setOpConf(true)}
            className={classes.noTextTranform}
            startIcon={<SettingsIcon />}
          >
            {t('calendar-agenda-configurar')}
          </Button>
        )}
      </div>
      <div style={{ height: 500 }}>
        <Dialog
          open={open}
          titulo={t('calendar-nueva-cita')}
          callBackClose={() => setOpen(!open)}
          arrayTipo={arrayCita.map((element) => ({
            id: element.id,
            label: element.cita,
          }))}
          setDatoGuardar={setDatoGuardar}
          handleFinAgregar={handleFinAgregar}
          datoGuardar={datoGuardar}
          handleDeleteCita={handleDeleteCita}
        />
        <Configurar
          open={opConf}
          titulo={t('calendar-agenda-configurar')}
          callBackClose={() => setOpConf(!opConf)}
          arrayCita={arrayCita}
          setArrayCita={setArrayCita}
        />
        <DragAndDropCalendar
          events={events}
          selectable
          views={['month', 'week', 'day', 'agenda']}
          step={60}
          showMultiDayTimes
          defaultDate={new Date()}
          components={{
            timeSlotWrapper: ColorCellWrapp,
          }}
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleInicioAgregar}
          eventPropGetter={eventStyleGetter}
          onNavigate={(date: any) => {
            if (
              fechaPeticion.mes !== dayjs(date).format('MM')
              || fechaPeticion.anio !== dayjs(date).format('YYYY')
            ) {
              setFechaPeticion({
                mes: dayjs(date).format('MM'),
                anio: dayjs(date).format('YYYY'),
              });
            }
          }}
          messages={{
            next: t('calendar-next'),
            previous: t('calendar-previous'),
            today: t('calendar-today'),
            month: t('calendar-month'),
            week: t('calendar-week'),
            day: t('calendar-day'),
            event: t('calendar-event'),
            date: t('calendar-date'),
            time: t('calendar-time'),
            noEventsInRange: t('calendar-no-events'),
            allDay: 'Día entero',
            showMore: function showMore(total: number) {
              return `+${total} más`;
            },
          }}
        />
      </div>
    </div>
  );
};

export default AgendaComponent;
