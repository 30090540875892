import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IMenuOrganizacion } from './types';
import { RootState } from '../../../store';

/** Componete que regresa el menú de la organizacion */
function MenuOrganizacion({
  anchorEl,
  handleClose,
  open,
  handleCambiarOrganizacion,
}: IMenuOrganizacion) {
  const {
    organizaciones,
    organizacion: { idOrganizacion },
  } = useSelector((state: RootState) => state.Organizaciones);
  return (
    <Menu
      id="menu-organizaciones"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      {organizaciones.map((org) => (
        <MenuItem
          key={org.label}
          onClick={() => handleCambiarOrganizacion(org.id)}
          selected={idOrganizacion === org.id}
        >
          {org.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

export default MenuOrganizacion;
