import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IEjerciciosProps } from './types';

function EjercicioComponente({
  hayCambios,
  index,
  catEjercicios,
  eliminarEjercicio,
  handleChangeEjercicio,
  handleChangeEjercicioEspecifica,
  handleNumbers,
  idTipo,
  frecuencia,
  especifica,
  duracion,
}: IEjerciciosProps) {
  const { t } = useTranslation();

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 flex">
      <div className="grid gap-4 grid-cols-3 xl:grid-cols-4 w-full">
        <div>
          <h3 className="text-gray-600 font-medium mb-2">{t('antecedentes-pnp-actividad-tipo')}</h3>
          <Autocomplete
            options={catEjercicios}
            getOptionLabel={(option) => option.label}
            noOptionsText={t('sin_resultados')}
            value={idTipo}
            onChange={(e, nV) => handleChangeEjercicio(e, index, nV)}
            fullWidth
            disabled={hayCambios}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        </div>
        {idTipo && idTipo.id && idTipo.id === 9999 ? (
          <div>
            <h3 className="text-gray-600 font-medium mb-2">{t('especifica')}</h3>
            <TextField
              value={especifica}
              onChange={(event) => handleChangeEjercicioEspecifica(event, index)}
              variant="outlined"
              fullWidth
              disabled={hayCambios}
            />
          </div>
        ) : null}
        <div>
          <h3 className="text-gray-600 font-medium mb-2">
            {t('antecedentes-pnp-actividad-frecuencia')}
          </h3>
          <TextField
            value={frecuencia}
            onChange={(event) => handleNumbers(event, index, 'frecuencia')}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t('antecedentes-pnp-actividad-frecuencia-v')}
                </InputAdornment>
              ),
              inputProps: { autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 2 },
            }}
            fullWidth
            disabled={hayCambios}
          />
        </div>
        <div>
          <h3 className="text-gray-600 font-medium mb-2">
            {t('antecedentes-pnp-actividad-duracion')}
          </h3>
          <TextField
            value={duracion}
            onChange={(event) => handleNumbers(event, index, 'duracion')}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t('antecedentes-pnp-actividad-duracion-m')}
                </InputAdornment>
              ),
              inputProps: { autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 3 },
            }}
            fullWidth
            disabled={hayCambios}
          />
        </div>
      </div>
      <IconButton
        aria-label="delete"
        onClick={() => eliminarEjercicio(index)}
        disabled={hayCambios}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
}

export default EjercicioComponente;
