import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  InputAdornment,
  RadioGroup,
  Button,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles, { InputDisabled } from './styles';
import { ICatalogosEstandar } from '../../../../../common/types';
import { getAnimales } from '../../../../../utils/getCatalogos';
import { RootState } from '../../../../../store';
import MascotasComponente from './Mascotas/index';
import ResumenCambios from '../../../../../components/ResumenCambios';
import BotonesGuardar from '../../../../../components/BotonesGuardar';
import {
  antecedentesNoPatologicosGuardarVivienda,
  antecedentesNoPatologicosUsuarioPacienteGuardarVivienda,
  antecedentesNoPatologicosUsuarioGuardarVivienda,
} from '../../../../../utils/sendInfo';
import {
  ITipoMascota, IVivienda, IViviendaProps, viviendaInitial,
} from './types';
import { setRequest } from '../../../../../actions/request/types';

function Vivienda(props: IViviendaProps) {
  const {
    setActiveSubStep,
    vivienda,
    setVivienda,
    cambiosUsuario,
    viviendaUsuario,
    setViviendaUsuario,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const [viviendaTemp, setViviendaTemp] = useState<IVivienda>(viviendaInitial);
  const [txtHac, setTxtHac] = useState<string>('');
  /** Catalogos */
  const [catAnimales, setCatAnimales] = useState<Array<ICatalogosEstandar>>([]);

  const hayCambios: boolean = idUsuario > 0 && cambiosUsuario.filter((c: string) => c.length > 0).length > 0;

  const tipoMascotaInitial: ITipoMascota = {
    idAnimal: { id: 0, label: '' },
    especifica: '',
    cantidad: '',
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'check1') {
      setViviendaTemp({
        ...viviendaTemp,
        serviciosBasicos: {
          check1: event.target.checked,
          check2: false,
          check3: false,
          check4: false,
        },
      });
    } else {
      setViviendaTemp({
        ...viviendaTemp,
        serviciosBasicos: {
          ...viviendaTemp.serviciosBasicos,
          [event.target.name]: event.target.checked,
        },
      });
    }
  };
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    setViviendaTemp({
      ...viviendaTemp,
      animales: val,
      tipo: val === 'F' ? [] : [tipoMascotaInitial],
    });
  };
  const handleNumbers = (e: React.ChangeEvent<{ value: unknown }>, sel: string) => {
    const val = (e.target.value as string).match(/\D/g) ? '0' : (e.target.value as string);
    if (sel === 'habitantes') {
      setViviendaTemp({
        ...viviendaTemp,
        numeroHabitantes: val ? parseInt(val, 10) : null,
      });
    } else if (sel === 'habitaciones') {
      setViviendaTemp({
        ...viviendaTemp,
        numeroHabitaciones: val ? parseInt(val, 10) : null,
      });
    }
  };
  const addAnimal = () => {
    setViviendaTemp({
      ...viviendaTemp,
      tipo: [...viviendaTemp.tipo, tipoMascotaInitial],
    });
  };
  const deleteAnimal = (index: number) => {
    if (viviendaTemp.tipo.length === 1) {
      setViviendaTemp({
        ...viviendaTemp,
        tipo: [tipoMascotaInitial],
      });
    } else {
      const newTipo = [...viviendaTemp.tipo];
      newTipo.splice(index, 1);
      setViviendaTemp({
        ...viviendaTemp,
        tipo: newTipo,
      });
    }
  };

  const getDatosVivienda = (datos: IVivienda) => ({
    serviciosBasicos: datos.serviciosBasicos || {
      check1: false,
      check2: false,
      check3: false,
      check4: false,
    },
    numeroHabitantes: datos.numeroHabitantes,
    numeroHabitaciones: datos.numeroHabitaciones,
    animales: datos.animales || '',
    tipo: datos.tipo || [],
    comentarios: datos.comentarios || '',
  });
  const getDatosExpediente = (datos: IVivienda) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    vivienda: getDatosVivienda(datos),
  });
  const getDatosUsuario = (datos: IVivienda) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    vivienda: getDatosVivienda(datos),
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(viviendaTemp);
    const sendFunctions = [antecedentesNoPatologicosGuardarVivienda(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarVivienda(datosToSend));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => setVivienda({ ...viviendaTemp, loaded: true }),
          () => setViviendaUsuario({ ...viviendaTemp, loaded: true }),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarVivienda(
          getDatosUsuario(viviendaTemp),
        ),
        successFunction: () => {
          setVivienda({ ...viviendaTemp, loaded: true });
          setActiveSubStep('higiene');
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarVivienda(
          getDatosExpediente(viviendaUsuario),
        ),
        successFunction: () => {
          setVivienda(viviendaUsuario);
          setViviendaTemp(viviendaUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarVivienda(
          getDatosExpediente(vivienda),
        ),
        successFunction: () => setViviendaUsuario(vivienda),
      }),
    );
  };

  useEffect(() => {
    if (vivienda.loaded && !viviendaTemp.loaded) {
      setViviendaTemp(vivienda);
    }
  }, [vivienda]);
  useEffect(() => {
    if (viviendaTemp.loaded) {
      if (
        viviendaTemp.numeroHabitaciones
        && viviendaTemp.numeroHabitaciones !== 0
        && viviendaTemp.numeroHabitantes
        && viviendaTemp.numeroHabitantes !== 0
      ) {
        const indiceHac = viviendaTemp.numeroHabitantes / viviendaTemp.numeroHabitaciones;
        setViviendaTemp({
          ...viviendaTemp,
          indiceHac: indiceHac > 0 ? indiceHac.toFixed(1) : '',
        });
        if (indiceHac > 2.5) {
          setTxtHac(`${t('antecedentes-pnp-vivienda-conih')}*`);
        } else {
          setTxtHac(`${t('antecedentes-pnp-vivienda-sinih')}*`);
        }
      } else {
        setViviendaTemp({
          ...viviendaTemp,
          indiceHac: '',
        });
        setTxtHac('');
      }
    }
  }, [viviendaTemp.numeroHabitaciones, viviendaTemp.numeroHabitantes]);
  useEffect(() => {
    getAnimales().then((result: ICatalogosEstandar[]) => {
      setCatAnimales(result);
    });
  }, []);

  return (
    <div className="relative py-4">
      <div className="text-left">
        <h2 className="text-blue-800 font-normal m-0">{t('antecedentes-pnp-vivienda')}</h2>
      </div>
      {hayCambios && <ResumenCambios cambios={cambiosUsuario} />}
      <div className="grid grid-cols-5 gap-4 pt-6 pb-4">
        <FormControl>
          <FormLabel component="legend" className={classes.boldText}>
            {t('antecedentes-pnp-vivienda-sv')}
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              disabled={hayCambios}
              value={viviendaTemp.serviciosBasicos.check1}
              control={(
                <Checkbox
                  checked={viviendaTemp.serviciosBasicos.check1}
                  color="primary"
                  name="check1"
                  onChange={handleChange}
                />
              )}
              label={t('antecedentes-pnp-vivienda-tt')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={viviendaTemp.serviciosBasicos.check2}
                  color="primary"
                  name="check2"
                  onChange={handleChange}
                />
              )}
              label={t('antecedentes-pnp-vivienda-sa')}
              disabled={viviendaTemp.serviciosBasicos.check1 || hayCambios}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={viviendaTemp.serviciosBasicos.check3}
                  color="primary"
                  name="check3"
                  onChange={handleChange}
                />
              )}
              label={t('antecedentes-pnp-vivienda-se')}
              disabled={viviendaTemp.serviciosBasicos.check1 || hayCambios}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={viviendaTemp.serviciosBasicos.check4}
                  color="primary"
                  name="check4"
                  onChange={handleChange}
                />
              )}
              label={t('antecedentes-pnp-vivienda-sd')}
              disabled={viviendaTemp.serviciosBasicos.check1 || hayCambios}
            />
          </FormGroup>
        </FormControl>
        <div>
          <h3 className="text-gray-600 font-medium mb-2">{t('antecedentes-pnp-vivienda-nhab')}</h3>
          <TextField
            disabled={hayCambios}
            id="numero-habitantes"
            variant="outlined"
            fullWidth
            value={viviendaTemp.numeroHabitantes}
            onChange={(event) => handleNumbers(event, 'habitantes')}
            inputProps={{ maxLength: 2, autoComplete: 'off' }}
          />
        </div>
        <div>
          <h3 className="text-gray-600 font-medium mb-2">{t('antecedentes-pnp-vivienda-ncua')}</h3>
          <TextField
            disabled={hayCambios}
            id="numero-habitaciones"
            variant="outlined"
            fullWidth
            value={viviendaTemp.numeroHabitaciones}
            onChange={(event) => handleNumbers(event, 'habitaciones')}
            inputProps={{ maxLength: 2, autoComplete: 'off' }}
          />
        </div>
        {!esPaciente && (
          <div className="col-span-2">
            <h3 className="text-gray-600 font-medium mb-2">
              {t('antecedentes-pnp-vivienda-ihac')}
            </h3>
            <InputDisabled
              disabled
              id="ihac-disabled"
              value={viviendaTemp.indiceHac}
              type="string"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {parseFloat(viviendaTemp.indiceHac) > 2.5 ? (
                      <span className="text-red-500">{txtHac}</span>
                    ) : (
                      txtHac
                    )}
                  </InputAdornment>
                ),
              }}
              helperText={txtHac.length > 0 ? t('antecedentes-pnp-vivienda-notaih') : ''}
              variant="standard"
              fullWidth
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-5 gap-4 py-4">
        <FormControl>
          <FormLabel component="legend" className={classes.boldText}>
            {t('antecedentes-pnp-vivienda-mascotas')}
          </FormLabel>
          <RadioGroup
            aria-label="mascotas"
            name="mascotas"
            value={viviendaTemp.animales}
            onChange={handleChangeRadio}
          >
            <FormControlLabel
              disabled={hayCambios}
              value="T"
              control={<Radio color="primary" />}
              label={t('si')}
            />
            <FormControlLabel
              disabled={hayCambios}
              value="F"
              control={<Radio color="primary" />}
              label={t('no')}
            />
          </RadioGroup>
        </FormControl>
      </div>
      {viviendaTemp.animales === 'T' ? (
        <>
          {viviendaTemp.tipo.map((val, index) => (
            <MascotasComponente
              {...val}
              hayCambios={hayCambios}
              catAnimales={catAnimales}
              eliminarMascota={() => deleteAnimal(index)}
              viviendaTemp={viviendaTemp}
              setViviendaTemp={setViviendaTemp}
              index={index}
              // eslint-disable-next-line react/no-array-index-key
              key={String(index)}
            />
          ))}
          <div className="flex justify-end mb-4">
            <Button
              disabled={
                hayCambios
                || viviendaTemp.tipo.some(
                  (mascota) => mascota.idAnimal.id === 0 && !mascota.especifica.length,
                )
              }
              color="primary"
              size="small"
              onClick={addAnimal}
              className={classes.noTextTranform}
              startIcon={<AddCircleOutlineIcon />}
            >
              {t('antecedentes-pnp-vivienda-amascota')}
            </Button>
          </div>
          <TextField
            disabled={hayCambios}
            id="comentarios"
            label={t('comentarios')}
            variant="outlined"
            fullWidth
            value={viviendaTemp.comentarios}
            onChange={(event) => setViviendaTemp({
              ...viviendaTemp,
              comentarios: event.target.value,
            })}
            inputProps={{ maxLength: 500, autoComplete: 'off' }}
            multiline
            rows="4"
          />
        </>
      ) : null}
      <BotonesGuardar
        hayCambios={hayCambios}
        aceptarCallback={guardarDatosDeUsuarioAExpediente}
        cancelarCallback={guardarDatosDeExpedienteAUsuario}
        guardarCallback={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
        continuar={esPaciente}
      />
    </div>
  );
}

export default Vivienda;
