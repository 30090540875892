/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Button,
  Collapse,
} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import {
  getCatalogoLaboratoriosResultado,
  getCatalogoLaboratoriosUnidades,
  getCatLaboratoriosPaciente,
  getLaboratorio,
  getPlanLaboratorioArchivos,
} from '../../../../../../utils/getCatalogos';
import { editarLaboratorio } from '../../../../../../utils/laboratory';
import { sendLaboratory, eliminarLaboratorio } from '../../../../../../utils/sendInfo';
import { constDia, constMes, constAnio } from '../../../../../../constants/fechas';
import {
  validacionDiezNumerosCuatroDecimales,
  validacionIngresoSoloUnPunto,
} from '../../../../../../constants/validaciones';
import useStyles from './styles';
import { IFechaField, ICatalogosEstandar, IArchivosListaEP } from '../../../../../../common/types';
import {
  ICatLaboratorio,
  IArrayLaboratorioTotal,
  ILaboratorioResultado,
  laboratorioResultadoInitial,
} from './types';
import DeleteDialog from '../../../../../../components/DeleteDialog';
import {
  currentSmallDatatimeFormat,
  validarFecha,
  validarFechaEsAnterior,
} from '../../../../../../common/functions';
import { setLoading } from '../../../../../../actions/loading/actions';
import Resultados from './Resultados/index';
import { setSnackComplete } from '../../../../../../actions/snackbar/types';
import { RootState } from '../../../../../../store';
import CargaDocumentosLaboratorio from './CargaDocumentos';
import { subirArchivo } from '../../../../../../utils/sendFiles';
import { deleteArchivo } from '../../../../../../utils/deleteFiles';
import { setRequest } from '../../../../../../actions/request/types';
import { setRequestFile } from '../../../../../../actions/requestFile/types';

function RegistrarLaboratorioComponente() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const [actlz, setActlz] = useState<boolean>(false);
  const [catLaboratorio, setCatLaboratorio] = useState<ICatLaboratorio[]>([]);
  const [catUnidades, setCatUnidades] = useState<ICatalogosEstandar[]>([]);
  const [arrConjunto, setArrConjunto] = useState<IArrayLaboratorioTotal[]>([]);
  const [totalResultados, setTotalResultados] = useState<number>(0);
  const [resultado, setResultado] = useState<ILaboratorioResultado>(laboratorioResultadoInitial);
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const [resultadoEspecifico, setResultadoEspecifico] = useState<boolean>(true);
  const [idSelected, setIdSelected] = useState<string>('0');
  const [objSeleccionArchivo, setObjSeleccionArchivo] = useState<{
    archivo: File;
    fecha: string;
  } | null>(null);
  const [listaArchivos, setListaArchivos] = useState<IArchivosListaEP[]>([]);
  const [paginaArchivos, setPaginaArchivos] = useState<number>(1);
  const [totalPaginasArchivos, setTotalPaginasArchivos] = useState<number>(1);
  const [objArchivoBorrar, setObjArchivoBorrar] = useState<{
    idArchivo: number;
    nombreArchivo: string;
    pathArchivo: string;
  } | null>(null);
  // cierre del modal
  const [openArchivoBorrar, setOpenArchivoBorrar] = useState<boolean>(false);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  const handleDeleteArchivoBorrar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setObjArchivoBorrar(null);
    setOpenArchivoBorrar(false);
  };
  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };
  const busquedaTotalLaboratorios = () => {
    getCatLaboratoriosPaciente(idMedico, idPaciente, idConsultorio).then(
      (result: IArrayLaboratorioTotal[]) => {
        setArrConjunto(result);
        if (result.length > 0) {
          let contado = 0;
          result.forEach((x) => {
            contado += x.total;
          });
          setTotalResultados(contado);
        }
      },
    );
  };
  const handleChangeFecha = (event: React.ChangeEvent<{ value: unknown }>, selector: string) => {
    const resVal = { ...resultado };
    if (selector === 'dia') {
      resVal.dia = event.target.value as string;
    } else if (selector === 'mes') {
      resVal.mes = event.target.value as string;
    } else if (selector === 'anio') {
      resVal.anio = event.target.value as string;
    }
    setResultado(resVal);
  };
  const handleDisabled = () => {
    if (
      (resultado.catSelect
        && resultado.catSelect.idCat !== 0
        && resultado.valor.length > 0
        && resultado.dia.length > 0
        && resultado.mes.length > 0
        && resultado.anio.length > 0)
      || (resultado.catSelect
        && resultado.catSelect.idCat === 0
        && resultado.txtLibre.length > 0
        && resultado.valor.length > 0
        && resultado.unidadSelect
        && resultado.unidadSelect.id !== 0
        && resultado.dia.length > 0
        && resultado.mes.length > 0
        && resultado.anio.length > 0)
    ) {
      return false;
    }
    return true;
  };
  const handleGuardarLaboratorio = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendLaboratory({
          idPaciente,
          idMedico,
          consulta: idConsulta,
          data: resultado,
          idConsultorio,
        }),
        successFunction: () => {
          setResultado(laboratorioResultadoInitial);
          busquedaTotalLaboratorios();
          setActlz((preV) => !preV);
        },
      }),
    );
  };
  const findEditarResultado = (idLab = idSelected) => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getLaboratorio(idPaciente, idLab, idMedico, idConsultorio),
        successFunction: (result: any) => {
          const editFind = catLaboratorio.find((x) => x.id === result.datos[0].ID_CS_LABORATORIO);
          setResultado({
            id: result.datos[0].ID_LABORATORIO_RESULTADOS,
            radio: result.datos[0].ID_CS_LABORATORIO ? 'catalogo' : 'txtLibre',
            catSelect: editFind
              ? {
                idCat: editFind.idCat,
                categoria: editFind.categoria,
                label: editFind.label,
                unidad: editFind.unidad,
              }
              : {
                idCat: 0,
                categoria: '',
                label: '',
                unidad: '',
              },
            txtLibre: result.datos[0].TXT_LIBRE ? result.datos[0].TXT_LIBRE : '',
            valor: result.datos[0].VALOR,
            unidadSelect:
              result.datos[0].ID_CS_LABORATORIO_UNIDADES
              && result.datos[0].ID_CS_LABORATORIO_UNIDADES > 0
                ? catUnidades.find((x) => x.id === result.datos[0].ID_CS_LABORATORIO_UNIDADES)
                : { id: 0, label: '' },
            dia: result.datos[0].DIA,
            mes: result.datos[0].MES,
            anio: result.datos[0].ANIO,
          });
          setActlz((preV) => !preV);
        },
      }),
    );
    // editarLaboratorio(idPaciente, idLab, idMedico, idConsultorio).then((result: any) => {
    //   if (result) {
    //     const editFind = catLaboratorio.find((x) => x.id === result.ID_CS_LABORATORIO);
    //     setResultado({
    //       id: result.ID_LABORATORIO_RESULTADOS,
    //       radio: result.ID_CS_LABORATORIO ? 'catalogo' : 'txtLibre',
    //       catSelect: editFind
    //         ? {
    //           idCat: editFind.idCat,
    //           categoria: editFind.categoria,
    //           label: editFind.label,
    //           unidad: editFind.unidad,
    //         }
    //         : {
    //           idCat: 0,
    //           categoria: '',
    //           label: '',
    //           unidad: '',
    //         },
    //       txtLibre: result.TXT_LIBRE ? result.TXT_LIBRE : '',
    //       valor: result.VALOR,
    //       unidadSelect:
    //         result.ID_CS_LABORATORIO_UNIDADES && result.ID_CS_LABORATORIO_UNIDADES > 0
    //           ? catUnidades.find((x) => x.id === result.ID_CS_LABORATORIO_UNIDADES)
    //           : { id: 0, label: '' },
    //       dia: result.DIA,
    //       mes: result.MES,
    //       anio: result.ANIO,
    //     });
    //     setActlz((preV) => !preV);
    //   }
    // });
  };
  const findDeleteResultado = () => {
    dispatch(
      setRequest({
        type: 'delete',
        requestFunction: eliminarLaboratorio({
          idPaciente,
          idMedico,
          idLaboratorio: idSelected,
          consulta: idConsulta,
          idConsultorio,
        }),
        successFunction: () => {
          setResultado(laboratorioResultadoInitial);
          busquedaTotalLaboratorios();
        },
      }),
    );
  };
  const firstStepEditar = (idLaboratorio: string) => {
    setIdSelected(idLaboratorio);
    if (
      (resultado.catSelect && resultado.catSelect.idCat && resultado.catSelect?.idCat > 0)
      || resultado.txtLibre.length > 0
      || resultado.valor.length > 0
      || (resultado.unidadSelect && resultado.unidadSelect?.id && resultado.unidadSelect?.id > 0)
      || resultado.dia.length > 0
      || resultado.mes.length > 0
      || resultado.anio.length > 0
    ) {
      setEditAlert(true);
    } else {
      findEditarResultado(idLaboratorio);
    }
  };
  const firstStepDelete = (idLaboratorio: string) => {
    setIdSelected(idLaboratorio);
    setDeleteAlert(true);
  };
  const obtenerTablaDocumentos = (pagina: number) => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getPlanLaboratorioArchivos(idPaciente, idMedico, idConsultorio, pagina),
        successFunction: (result: any) => {
          // result.code = 200
          setListaArchivos(result.datos.datos);
          setPaginaArchivos(result.datos.pagina);
          setTotalPaginasArchivos(result.datos.totalPaginas);
          setTotalResultados(result.datos.totalResultados);
        },
        errorFunction: () => {
          // result.code != 200
          setListaArchivos([]);
          setPaginaArchivos(0);
          setTotalPaginasArchivos(0);
          setTotalResultados(0);
        },
        showMsgs: false,
      }),
    );
  };
  const verArchivo = (
    _idLista: number,
    idArchivo: number,
    nombreArchivo: string,
    pathArchivo: string,
  ) => {
    dispatch(
      setRequestFile({
        type: 'get',
        idMedico: null,
        idPaciente,
        idArchivo,
        nombreArchivo,
        pathArchivo,
        tipoArchivo: 'laboratorios',
      }),
    );
  };

  const eliminarArchivo = (
    _idLista: number,
    idArchivo: number,
    nombreArchivo: string,
    pathArchivo: string,
  ) => {
    setObjArchivoBorrar({ idArchivo, nombreArchivo, pathArchivo });
  };

  const handleChangePaginaArchivos = (_event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setLoading(true));
    obtenerTablaDocumentos(value);
  };

  const deleteArchivoBorrar = () => {
    if (objArchivoBorrar) {
      dispatch(setLoading(true));
      if (listaArchivos.length) {
        const objBorrar = {
          idLista: 0,
          idArchivo: objArchivoBorrar.idArchivo,
          idMedico,
          idPaciente,
          idConsultorio,
          pathArchivo: objArchivoBorrar.pathArchivo,
          registro: 'laboratorios',
        };
        deleteArchivo(objBorrar)
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              // actualizamos la lista de archivos para evitar hacer un refresh y evitar
              // peticiones extra a la BD
              if (listaArchivos.length > 1) {
                obtenerTablaDocumentos(paginaArchivos);
              } else {
                obtenerTablaDocumentos(paginaArchivos - 1);
              }
            } else {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: result.msg,
                }),
              );
            }
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `Ocurrio un error al eliminar el archivo: ${err}`,
              }),
            );
            dispatch(setLoading(false));
          });
      } else {
        dispatch(setLoading(false));
      }
    } else {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: 'Ocurrio un error intentar nuevamente',
        }),
      );
    }
  };

  useEffect(() => {
    if (resultado.dia !== '' && resultado.mes !== '' && resultado.anio !== '') {
      if (
        !validarFecha(resultado.anio, resultado.mes, resultado.dia)
        || !validarFechaEsAnterior(resultado.anio, resultado.mes, resultado.dia)
      ) {
        setFechaField({
          error: true,
          helperText: t('fecha_invalida'),
        });
      } else {
        setFechaField({
          error: false,
          helperText: '',
        });
      }
    }
  }, [resultado]);
  useEffect(() => {
    setLoading(true);
    getCatalogoLaboratoriosResultado().then((result: ICatLaboratorio[]) => {
      setCatLaboratorio(result);
    });
    getCatalogoLaboratoriosUnidades().then((result: ICatalogosEstandar[]) => {
      setCatUnidades(result);
    });
    // busquedaTotalLaboratorios();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (idPaciente > 0 && idMedico > 0 && idConsulta > 0 && idConsultorio > 0) {
      dispatch(setLoading(true));
      busquedaTotalLaboratorios();
      obtenerTablaDocumentos(paginaArchivos);
    }
  }, [idPaciente, idMedico, idConsulta, idConsultorio]);

  useEffect(() => {
    if (objSeleccionArchivo && objSeleccionArchivo.archivo.size > 10485760) {
      setObjSeleccionArchivo(null);
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('el_archivo_selecionado_pesa_mas_de_10_megas'),
        }),
      );
      return;
    }
    if (objSeleccionArchivo) {
      dispatch(setLoading(true));
      // despues en un futuro debemos crear un XMLHttpRequest en vez de un fetch para obtener el progreso de la carga
      // ese progreso se dejará maqueteado para usarlo en un futuro, asi que los comentarios de dejan a proposito
      // obtenemos un objeto form data que nos servira para enviar nuestra peticion como si se hubiera generado en
      // un formulario
      const data = new FormData();
      // añadimos la informacion importante y OJO el archivo debe ir siempre al último o el back no parseara correctamente
      // los parametros que se le adjunta
      data.append('idLista', '0');
      data.append('idMedico', String(idMedico));
      data.append('idPaciente', String(idPaciente));
      data.append('idConsulta', String(idConsulta));
      data.append('nombre', String(objSeleccionArchivo.archivo?.name));
      data.append('tipo', objSeleccionArchivo.archivo?.type);
      data.append('peso', String(objSeleccionArchivo.archivo?.size));
      data.append('registro', 'laboratorios');
      data.append('file', objSeleccionArchivo?.archivo);
      subirArchivo(data)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            // si el resultado es ok procedemos a anadir a la lista el archivo mandado
            if (listaArchivos.length === 10 || listaArchivos.length === 0) {
              setTotalResultados((preTR) => preTR + 1);
              setPaginaArchivos((prePA) => prePA + 1);
              setTotalPaginasArchivos((preTPA) => preTPA + 1);
            } else {
              setTotalResultados((preTR) => preTR + 1);
            }
            setListaArchivos((preTra) => {
              if (preTra.length < 10) {
                return preTra.concat({
                  idArchivo: result.datos.idArchivo as number,
                  idMedico,
                  nombreArchivo: objSeleccionArchivo.archivo.name,
                  pathArchivo: result.datos.url as string,
                  pesoArchivo: objSeleccionArchivo.archivo?.size || 0,
                  extensionArchivo: objSeleccionArchivo.archivo.name.substring(
                    objSeleccionArchivo.archivo.name.lastIndexOf('.'),
                  ),
                  fechaCarga: currentSmallDatatimeFormat(),
                });
              }
              return [
                {
                  idArchivo: result.datos.idArchivo as number,
                  idMedico,
                  nombreArchivo: objSeleccionArchivo.archivo.name,
                  pathArchivo: result.datos.url as string,
                  pesoArchivo: objSeleccionArchivo.archivo?.size || 0,
                  extensionArchivo: objSeleccionArchivo.archivo.name.substring(
                    objSeleccionArchivo.archivo.name.lastIndexOf('.'),
                  ),
                  fechaCarga: currentSmallDatatimeFormat(),
                },
              ];
            });
          }
          // reseteamos el objeto y apagamos el loading
          setObjSeleccionArchivo(null);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `Ocurrio un error al procesar el archivo: ${err}`,
            }),
          );
        });
    }
  }, [objSeleccionArchivo]);

  useEffect(() => {
    if (objArchivoBorrar) {
      setOpenArchivoBorrar(true);
    }
  }, [objArchivoBorrar]);

  return (
    <>
      <div className="border-solid border border-gray-400 rounded p-4">
        <DeleteDialog
          open={deleteAlert}
          titulo={t('_atencion_')}
          descripcion={t('d-p-plan-lab-modal-borrar')}
          callBackAceptar={findDeleteResultado}
          callBackClose={handleDeleteAlertClose}
        />
        <DeleteDialog
          open={openArchivoBorrar}
          titulo={t('_atencion_')}
          descripcion={`${t('_estas_seguro_de_eliminar')}: ${objArchivoBorrar?.nombreArchivo}?`}
          callBackAceptar={deleteArchivoBorrar}
          callBackClose={handleDeleteArchivoBorrar}
        />
        <DeleteDialog
          open={editAlert}
          titulo={t('_atencion_')}
          descripcion={t('d-p-plan-lab-modal-editar')}
          callBackAceptar={findEditarResultado}
          callBackClose={handleEditAlertClose}
        />
        <h2 className="text-gray-500 font-normal m-0 pr-4">{t('d-p-plan-lab-resultado')}</h2>
        <div className="w-full">
          <div className="pb-2 flex items-center">
            <h3 className="m-0 mr-2 font-normal text-gray-900">{`${t('d-p-plan-lab-nom')}*`}</h3>
            <RadioGroup
              aria-label="realiza"
              name="realiza"
              value={resultado.radio}
              onChange={(e) => {
                const resVal = { ...resultado };
                resVal.id = -1;
                resVal.radio = (e.target as HTMLInputElement).value;
                resVal.txtLibre = '';
                resVal.valor = '';
                resVal.dia = '';
                resVal.mes = '';
                resVal.anio = '';
                resVal.catSelect = {
                  idCat: 0,
                  categoria: '',
                  label: '',
                  unidad: '',
                };
                resVal.unidadSelect = { id: 0, label: '' };
                setFechaField({ error: false, helperText: '' });
                setResultado(resVal);
              }}
              row
            >
              <FormControlLabel
                value="catalogo"
                control={<Radio color="primary" />}
                label={t('d-p-plan-lab-cat')}
              />
              <FormControlLabel
                value="txtLibre"
                control={<Radio color="primary" />}
                label={t('texto-libre')}
              />
            </RadioGroup>
          </div>
          <div className="pb-4">
            {resultado.radio === 'catalogo' ? (
              <Autocomplete
                id="cat"
                options={catLaboratorio}
                getOptionLabel={(option) => option.label}
                noOptionsText={t('sin_resultados')}
                value={resultado.catSelect}
                onChange={(e, nV) => {
                  if (nV) {
                    const resVal = { ...resultado };
                    resVal.catSelect = nV;
                    resVal.valor = '';
                    resVal.dia = '';
                    resVal.mes = '';
                    resVal.anio = '';
                    setResultado(resVal);
                  }
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            ) : (
              <TextField
                id="especifica"
                placeholder={t('especifica')}
                value={resultado.txtLibre}
                onChange={(event) => {
                  const resVal = { ...resultado };
                  resVal.txtLibre = event.target.value;
                  setResultado(resVal);
                }}
                variant="outlined"
                fullWidth
              />
            )}
          </div>
          <div className="pb-2 grid grid-cols-12 gap-4">
            <div className="col-span-2">
              <h4 className="m-0 mb-2 font-normal text-gray-500">{`${t('valor')}*`}</h4>
              <TextField
                id="valor"
                value={resultado.valor}
                onChange={(event) => {
                  const valueEvent = event.target.value as string;
                  if (
                    valueEvent.length === 0
                    || validacionIngresoSoloUnPunto(valueEvent)
                    || validacionDiezNumerosCuatroDecimales(valueEvent)
                  ) {
                    const resVal = { ...resultado };
                    resVal.valor = valueEvent;
                    setResultado(resVal);
                  }
                }}
                variant="outlined"
                fullWidth
              />
            </div>
            <div className="col-span-3">
              <h4 className="m-0 mb-2 font-normal text-gray-500">{`${t('unidad')}*`}</h4>
              {resultado.radio === 'catalogo' ? (
                <TextField
                  id="unidad"
                  value={resultado.catSelect && resultado.catSelect.unidad}
                  variant="outlined"
                  inputProps={{ className: classes.txtCenter }}
                  disabled
                  fullWidth
                />
              ) : (
                <Autocomplete
                  id="cat"
                  options={catUnidades}
                  getOptionLabel={(option) => option.label}
                  noOptionsText={t('sin_resultados')}
                  value={resultado.unidadSelect}
                  onChange={(e, nV) => {
                    if (nV) {
                      const resVal = { ...resultado };
                      resVal.unidadSelect = nV;
                      setResultado(resVal);
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              )}
            </div>
            <div className="w-full col-span-3">
              <h4 className="m-0 mb-2 font-normal text-gray-500">{`${t('fecha')}*`}</h4>
              <div className="grid grid-cols-3 col-span-3 gap-2">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-dia">{t('dia')}</InputLabel>
                  <Select
                    labelId="label-dia"
                    id="select-dia"
                    value={resultado.dia}
                    onChange={(e) => handleChangeFecha(e, 'dia')}
                    label={t('dia')}
                    error={fechaField.error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    {constDia.map((diaSingle) => (
                      <MenuItem value={diaSingle.toString()} key={diaSingle}>
                        {diaSingle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-mes">{t('mes')}</InputLabel>
                  <Select
                    labelId="label-mes"
                    id="select-mes"
                    value={resultado.mes}
                    onChange={(e) => handleChangeFecha(e, 'mes')}
                    label={t('mes')}
                    error={fechaField.error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    {constMes.map((mesSingle, indx) => (
                      <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                        {mesSingle.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    className={classes.absoluteBottom}
                    style={{ textAlign: 'center', margin: '0', width: '100%' }}
                  >
                    {fechaField.helperText}
                  </FormHelperText>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="label-año">{t('anio')}</InputLabel>
                  <Select
                    labelId="label-año"
                    id="select-año"
                    value={resultado.anio}
                    onChange={(e) => handleChangeFecha(e, 'anio')}
                    label={t('anio')}
                    error={fechaField.error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    {constAnio().map((anioSingle) => (
                      <MenuItem key={anioSingle} value={anioSingle.toString()}>
                        {anioSingle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            {resultado && resultado.id === -1 ? (
              <div className="col-span-4 flex place-self-end">
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    handleGuardarLaboratorio();
                  }}
                  startIcon={<SaveIcon />}
                  disabled={handleDisabled()}
                >
                  {t('d-p-plan-lab-add-button')}
                </Button>
              </div>
            ) : (
              <div className="col-span-4 flex place-self-end">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    handleGuardarLaboratorio();
                  }}
                  startIcon={<SyncIcon />}
                >
                  {t('actualizar')}
                </Button>
              </div>
            )}
          </div>
          <div className="flex justify-between">
            <h4 className="m-0 mt-2 font-normal text-gray-300">{`*${t('campos_obligatorios')}`}</h4>
            <h4 className="m-0 mt-2 font-normal text-gray-500">
              {resultado && resultado.id === -1 ? (
                <Trans i18nKey="d-p-plan-lab-agregar">
                  index0
                  <strong title="index1">index1</strong>
                </Trans>
              ) : (
                <Trans i18nKey="d-p-plan-lab-actualizar">
                  index0
                  <strong title="index1">index1</strong>
                </Trans>
              )}
            </h4>
          </div>
        </div>
      </div>
      {arrConjunto ? (
        <div className="mt-4 bg-white rounded p-4 shadow">
          <h2 className="text-blue-500 font-normal m-0">
            {`${t('d-p-plan-lab-lista-resultados')} (${totalResultados})`}
          </h2>
          <div className="mt-4">
            <Collapse in={resultadoEspecifico}>
              <Resultados
                arrConjunto={arrConjunto}
                firstStepEditar={firstStepEditar}
                firstStepDelete={firstStepDelete}
                totalResultados={totalResultados}
                actualizar={actlz}
              />
            </Collapse>
            <div className="text-center">
              <Button
                onClick={() => setResultadoEspecifico((prevVal: boolean) => !prevVal)}
                color="primary"
              >
                {resultadoEspecifico ? <span>{t('ver_menos')}</span> : <span>{t('ver_mas')}</span>}
                {resultadoEspecifico ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      <div className="p-4" />
      <CargaDocumentosLaboratorio
        idMedico={idMedico}
        archivos={listaArchivos}
        setObjSeleccionArchivo={setObjSeleccionArchivo}
        verArchivo={verArchivo}
        eliminarArchivo={eliminarArchivo}
        totalPaginasArchivos={totalPaginasArchivos}
        paginaArchivos={paginaArchivos}
        handleChangePaginaArchivos={handleChangePaginaArchivos}
      />
    </>
  );
}

export default RegistrarLaboratorioComponente;
