import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { RootState } from '../../../../store';
import DeleteDialog from '../../../../components/DeleteDialog';
import FormAntecedentesLaborales from './FormAntecedentesLaborales';
import { sendAntecedentesLaboralesSinAntecedentes } from '../../../../utils/sendInfo';
import { ILaboralesProps, IObjLaboral } from './types';
import { setRequest } from '../../../../actions/request/types';

function Laborales(props: ILaboralesProps) {
  const {
    objLaboral, setObjLaboral, antecedentesLaborales, setAntecedentesLaborales,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { nombre: nombreConsultorio, idConsultorio } = useSelector(
    (state: RootState) => state.Consultorios.consultorio,
  );
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const [cambioAlert, setCambioAlert] = useState<boolean>(false);
  const [objLaboralTemp, setObjLaboralTemp] = useState<IObjLaboral>({
    id: -1,
    sinAntecedentes: false,
    guardadoOk: false,
  });
  const [disabledCheck, setDisabledCheck] = useState<boolean>(false);
  /* funcion para cerrar la alerta de activar sin antecedentes de alergias */
  const handleCambioAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCambioAlert(false);
  };
  useEffect(() => {
    if (objLaboral.id > 0 && objLaboralTemp.id <= 0) {
      setObjLaboralTemp(objLaboral);
    }
  }, [objLaboral]);

  return (
    <>
      <DeleteDialog
        open={cambioAlert}
        titulo={t('_atencion_')}
        descripcion={t('_estas_seguro__si_existe_registros_anteriores_se_eliminaran')}
        callBackAceptar={() => setCambioAlert(false)}
        callBackClose={handleCambioAlertClose}
      />
      <div className="container mx-auto shadow-lg border border-solid border-gray-200 rounded p-4">
        <h1 className="text-blue-800 font-normal m-0">{t('antecedentes_laborales_ant')}</h1>
        <FormControlLabel
          control={(
            <Checkbox
              checked={objLaboralTemp.sinAntecedentes}
              onChange={(e) => {
                dispatch(
                  setRequest({
                    type: 'send',
                    requestFunction: sendAntecedentesLaboralesSinAntecedentes({
                      ...objLaboralTemp,
                      sinAntecedentes: e.target.checked,
                      idPaciente,
                      idMedico,
                      idConsultorio,
                    }),
                    successFunction: (result: any) => {
                      const dataLaboral = {
                        ...objLaboralTemp,
                        sinAntecedentes: result.data.sinAntecedentes,
                        id: parseInt(result.data.idAntecentesLaborales, 10),
                        guardadoOk: true,
                      };
                      setObjLaboralTemp(dataLaboral);
                      setObjLaboral(dataLaboral);
                    },
                  }),
                );
              }}
              name="sinAntecedentesLaborales"
              color="primary"
            />
          )}
          label={t('antecedentes_laborales_sin_ant')}
          disabled={disabledCheck}
        />
        {!objLaboralTemp.sinAntecedentes && (
          <FormAntecedentesLaborales
            nombreEmpresa={nombreConsultorio}
            setDisabledCheck={setDisabledCheck}
            idLaboral={objLaboral.id}
            antecedentesLaborales={antecedentesLaborales}
            setAntecedentesLaborales={setAntecedentesLaborales}
          />
        )}
      </div>
    </>
  );
}

export default Laborales;
