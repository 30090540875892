import { getToken } from './commonStore';
import { ArchivosURL } from './commonUrl';

function fetchDeleteFile(url: string, obj: any) {
  return fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function deleteArchivo(obj: any) {
  return fetchDeleteFile(ArchivosURL(), obj);
}

export function dummyEvitaError() {
  return true;
}
