import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paperPadding: {
    padding: '1rem',
  },
  fullWidth: {
    width: '100%',
  },
  marginUpDown: {
    margin: '0.9375rem 0',
  },
  marginDown: {
    marginBottom: '0.9375rem',
  },
  marginUp: {
    marginTop: '0.9375rem',
  },
  margin10pdown: {
    marginBottom: '10px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  helperText: {
    textAlign: 'right',
  },
});

export default useStyles;
