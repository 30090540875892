/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EvaluacionMedidas from './EvaluacionMedidas';
import PrevencionCardiovascular from './PrevencionCardiovascular';
// import PrevencionEndocrinometabolicas from './PrevencionEndocrinometabolicas';
import DeteccionTempranaCancer from './DeteccionTempranaCancer';
import { RootState } from '../../../../../store';
import { IMedicinaPreventivaProps } from './types';

function MedicinaPreventiva(props: IMedicinaPreventivaProps) {
  const { setActiveStep, exploracionFisica } = props;
  const { t } = useTranslation();
  const {
    edad: { edad, periodo },
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const [expanded, setExpanded] = React.useState<string | false>(
    esPaciente ? 'evaluacion-medidas' : false,
  );

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {esPaciente ? (
        <h1 className="text-blue-800 font-normal text-center m-0 mb-4">
          {t('medicina_preventiva')}
        </h1>
      ) : (
        <h2 className="font-normal text-blue-900">{t('medicina_preventiva')}</h2>
      )}
      {periodo === 'anios' && edad >= 20 && (
        <Accordion
          expanded={expanded === 'evaluacion-medidas'}
          onChange={handleChange('evaluacion-medidas')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
            <div className="w-full flex justify-between items-center">
              <h3 className="m-0 text-blue-500 font-normal">
                {t('evaluacion_de_medidas_antropometricas')}
              </h3>
              <span className="m-0 text-blue-500">
                {expanded === 'evaluacion-medidas' ? t('ver_menos') : t('ver_mas')}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <EvaluacionMedidas
              setActiveStep={setActiveStep}
              peso={exploracionFisica.peso ? parseInt(exploracionFisica.peso, 10) : null}
              talla={
                exploracionFisica.altura ? parseInt(exploracionFisica.altura, 10) * 0.01 : null
              }
              perCintura={
                exploracionFisica.perCintura ? parseInt(exploracionFisica.perCintura, 10) : null
              }
              imc={exploracionFisica.imc ? parseInt(exploracionFisica.imc, 10) : null}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {!esPaciente && periodo === 'anios' && edad >= 40 && edad <= 81 && (
        <Accordion
          expanded={expanded === 'prevencion-cardiovascular'}
          onChange={handleChange('prevencion-cardiovascular')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
            <div className="w-full flex justify-between items-center">
              <h3 className="m-0 text-blue-500 font-normal">
                {t('prevencion_de_enfermedades_cardiovasculares')}
              </h3>
              <span className="m-0 text-blue-500">
                {expanded === 'prevencion-cardiovascular' ? t('ver_menos') : t('ver_mas')}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <PrevencionCardiovascular />
          </AccordionDetails>
        </Accordion>
      )}
      {!esPaciente && periodo === 'anios' && edad >= 21 && edad <= 80 && (
        <Accordion
          expanded={expanded === 'deteccion-temprana-cancer'}
          onChange={handleChange('deteccion-temprana-cancer')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
            <div className="w-full flex justify-between items-center">
              <h3 className="m-0 text-blue-500 font-normal">{t('deteccion_temprana_de_cancer')}</h3>
              <span className="m-0 text-blue-500">
                {expanded === 'deteccion-temprana-cancer' ? t('ver_menos') : t('ver_mas')}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <DeteccionTempranaCancer />
          </AccordionDetails>
        </Accordion>
      )}
      {/* <Accordion
        expanded={expanded === 'prevencion-endocrinometabolicas'}
        onChange={handleChange('prevencion-endocrinometabolicas')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full flex justify-between items-center">
            <h3 className="m-0 text-blue-500 font-normal">
              {t('prevencion_de_enfermedades_endocrinometabolicas')}
            </h3>
            <span className="m-0 text-blue-500">
              {expanded === 'prevencion-endocrinometabolicas' ? t('ver_menos') : t('ver_mas')}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <PrevencionEndocrinometabolicas />
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}

export default MedicinaPreventiva;
