import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, withStyles } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { ITablaExploracionProps } from './types';
import useStyles from '../styles';
import arrAreas from '../arrVariables';

const WhiteCheckbox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const BlackRadio = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const BlackTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

function TablaExploracion(props: ITablaExploracionProps) {
  const { exploracionFisica, setExploracionFisica, sexo } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [totalCheck, setTotalCheck] = useState<boolean>(false);

  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valCheck = event.target.checked as boolean;
    setTotalCheck(valCheck);
    const newContenidoTabla = valCheck
      ? exploracionFisica.contenidoTabla.map((preR, indx) => {
        let cooms = t(arrAreas[indx].valor.a);
        if (indx === 11 || indx === 15) {
          if (sexo === 'H') {
            cooms = t(arrAreas[indx].valor.h);
          } else if (sexo === 'M') {
            cooms = t(arrAreas[indx].valor.m);
          }
        }
        if (preR.nombreSintoma === 'antecedentes-ef-otro') {
          return {
            comentarioSintoma: '',
            nombreSintoma: preR.nombreSintoma,
            normal: '',
          };
        }
        return {
          comentarioSintoma: cooms,
          nombreSintoma: preR.nombreSintoma,
          normal: 'Normal',
        };
      })
      : exploracionFisica.contenidoTabla.map((preR) => ({
        comentarioSintoma: '',
        nombreSintoma: preR.nombreSintoma,
        normal: '',
      }));
    setExploracionFisica({
      ...exploracionFisica,
      contenidoTabla: newContenidoTabla,
    });
  };

  const handleChangeRadioSeleccion = (event: React.ChangeEvent<HTMLInputElement>, indx: number) => {
    const newContenidoTabla = exploracionFisica.contenidoTabla.map((preR, indxi) => {
      if (indxi === indx) {
        let cooms = t(arrAreas[indx].valor.a);
        if (indx === 11 || indx === 15) {
          if (sexo === 'H') {
            cooms = t(arrAreas[indx].valor.h);
          } else if (sexo === 'M') {
            cooms = t(arrAreas[indx].valor.m);
          }
        }
        return {
          comentarioSintoma: (event.target.value as string) === 'Normal' ? cooms : '',
          nombreSintoma: preR.nombreSintoma,
          normal: event.target.value as string,
        };
      }
      return preR;
    });
    setExploracionFisica({
      ...exploracionFisica,
      contenidoTabla: newContenidoTabla,
    });
  };

  const handleChangeComentarios = (event: React.ChangeEvent<{ value: unknown }>, indx: number) => {
    const newContenidoTabla = exploracionFisica.contenidoTabla.map((preR, indi) => {
      if (indi === indx) {
        return {
          comentarioSintoma: event.target.value as string,
          nombreSintoma: preR.nombreSintoma,
          normal: preR.normal,
        };
      }
      return preR;
    });
    setExploracionFisica({
      ...exploracionFisica,
      contenidoTabla: newContenidoTabla,
    });
  };

  return (
    <div className="border border-solid border-gray-600">
      <div className="grid grid-cols-12 gap-4 bg-blue-600 text-white text-center items-center py-2">
        <div className="col-span-1 flex items-center">
          <WhiteCheckbox name="checkbox-all" checked={totalCheck} onChange={handleChangeCheck} />
          {t('antecedentes-ef-sel-normal')}
        </div>
        <div className="col-span-1">{t('antecedentes-ef-sel-anormal')}</div>
        <span className="col-span-2">{t('antecedentes-ef-area')}</span>
        <span className="col-span-8">{t('comentarios')}</span>
      </div>
      {exploracionFisica.contenidoTabla.map((single, index) => (
        <div
          className={`grid grid-cols-12 gap-4 ${
            index % 2 === 0 ? 'bg-blue-300' : ''
          } text-center items-center py-4`}
          key={single.nombreSintoma}
        >
          <div className="col-span-1">
            <BlackRadio
              value="Normal"
              name={`radio-button-normal-${index}`}
              checked={single.normal === 'Normal'}
              onChange={(e) => handleChangeRadioSeleccion(e, index)}
            />
          </div>
          <div className="col-span-1">
            <BlackRadio
              value="Anormal"
              name={`radio-button-anormal-${index}`}
              checked={single.normal === 'Anormal'}
              onChange={(e) => handleChangeRadioSeleccion(e, index)}
            />
          </div>
          <span className="col-span-2 text-left">{t(single.nombreSintoma)}</span>
          <div className="col-span-8 pr-4">
            <BlackTextField
              disabled={exploracionFisica.contenidoTabla[index].normal.length === 0}
              multiline
              rows="2"
              variant="outlined"
              fullWidth
              InputProps={{
                className: classes.backgroundWhite,
                inputProps: { maxLength: 500, autoComplete: 'off' },
              }}
              value={single.comentarioSintoma}
              onChange={(e) => handleChangeComentarios(e, index)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default TablaExploracion;
