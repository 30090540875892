import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Chip,
  Button,
} from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { IBuilderLDProps } from './types';
import { capitalize } from '../../../../common/functions';
import { IMCPACompleto } from '../../../TabsPaciente/MCPA/types';

function BuilderListaDesplegable({ componenteListaDesplegable, setMCPA, id }: IBuilderLDProps) {
  const { t } = useTranslation();

  const handleChangeListaDesplegableSimple = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setMCPA((preMCPA: IMCPACompleto) => ({
      ...preMCPA,
      componentes: preMCPA.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: {
                ...preComp.constructorComponente.constructor,
                listaDesplegable: {
                  ...preComp.constructorComponente.constructor.listaDesplegable!,
                  seleccionListaDesplegable: [val],
                },
              },
              valores: { ...preComp.constructorComponente.valores, listaDesplegable: [val] },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  const handleDeleteChip = (index: number) => {
    setMCPA((preMCPA: IMCPACompleto) => ({
      ...preMCPA,
      componentes: preMCPA.componentes.map((preComp) => {
        if (preComp.id === id) {
          const val = preComp.constructorComponente.constructor.listaDesplegable!.seleccionListaDesplegable.filter(
            (_sld, indx) => indx !== index,
          );
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: {
                ...preComp.constructorComponente.constructor,
                listaDesplegable: {
                  ...preComp.constructorComponente.constructor.listaDesplegable!,
                  seleccionListaDesplegable: val,
                },
              },
              valores: { ...preComp.constructorComponente.valores, listaDesplegable: val },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  const handleChangeListaDesplegableMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string[];
    setMCPA((preMCPA: IMCPACompleto) => ({
      ...preMCPA,
      componentes: preMCPA.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: {
                ...preComp.constructorComponente.constructor,
                listaDesplegable: {
                  ...preComp.constructorComponente.constructor.listaDesplegable!,
                  seleccionListaDesplegable: val,
                },
              },
              valores: { ...preComp.constructorComponente.valores, listaDesplegable: val },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  const handleChangeComentarios = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setMCPA((preMCPA: IMCPACompleto) => ({
      ...preMCPA,
      componentes: preMCPA.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: { ...preComp.constructorComponente.constructor, comentarios: val },
              valores: { ...preComp.constructorComponente.valores, textoLibre: val },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="flex items-end mb-2">
        <h3 className="m-0 pr-4 text-gray-600 font-medium">
          {capitalize(componenteListaDesplegable.datosComponente.titulo)}
        </h3>
        <div className="text-gray-500">
          {componenteListaDesplegable.datosComponente.descripcion
            ? `* ${componenteListaDesplegable.datosComponente.descripcion}`
            : ''}
        </div>
      </div>
      <div className="flex items-end mb-4">
        <div className="w-full">
          {!componenteListaDesplegable.constructor.listaDesplegable!.seleccionMultiple ? (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label">{t('selecciona')}</InputLabel>
              <Select
                labelId="label-lista"
                id="select-lista"
                label={t('selecciona')}
                value={
                  componenteListaDesplegable.constructor.listaDesplegable!
                    .seleccionListaDesplegable[0]
                }
                onChange={handleChangeListaDesplegableSimple}
              >
                {componenteListaDesplegable.constructor.listaDesplegable!.arrValores.map(
                  (valor, inx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem value={valor} key={String(inx)}>
                      {valor}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          ) : (
            <div className="flex flex-wrap items-end justify-end">
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{t('selecciona')}</InputLabel>
                <Select
                  label={t('selecciona')}
                  multiple
                  value={
                    componenteListaDesplegable.constructor.listaDesplegable!
                      .seleccionListaDesplegable
                  }
                  input={<Input />}
                  renderValue={(selected) => (
                    <div>
                      {(selected as string[]).map((value, indx) => (
                        <Chip
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          key={value}
                          label={value}
                          className="mr-2"
                          onDelete={() => handleDeleteChip(indx)}
                          variant="outlined"
                          color="primary"
                        />
                      ))}
                    </div>
                  )}
                  onChange={handleChangeListaDesplegableMultiple}
                >
                  {componenteListaDesplegable.constructor.listaDesplegable!.arrValores.map(
                    (valor, iny) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem key={String(iny)} value={valor}>
                        {valor}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
              <div className="mt-2">
                <Button
                  color="primary"
                  size="small"
                  style={{ color: '#F59E0B', textTransform: 'none' }}
                  startIcon={<FormatListBulletedIcon />}
                  disabled
                >
                  {t('podras_seleccionar_varias_opciones')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <TextField
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        label={t('comentarios')}
        inputProps={{ autoComplete: 'off', maxLength: 500 }}
        value={componenteListaDesplegable.constructor.comentarios}
        onChange={handleChangeComentarios}
      />
    </div>
  );
}

export default React.memo(BuilderListaDesplegable);
