import React from 'react';
import { Avatar } from '@material-ui/core';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { IHeaderCertificadoProps } from './types';

function HeaderCertificado(props: IHeaderCertificadoProps) {
  const { urlLogo } = props;
  return (
    <>
      <div
        style={{
          backgroundColor: '#1E3A8A',
          height: '30px',
        }}
      />
      <Avatar className="m-6" style={{ width: '90px', height: '90px' }} src={urlLogo}>
        {urlLogo.length === 0 && <AddToPhotosIcon fontSize="large" />}
      </Avatar>
    </>
  );
}

export default HeaderCertificado;
