import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTable, usePagination } from 'react-table';
import {
  TableBody, TableCell, TableHead, TableRow, Button,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MaUTable from '@material-ui/core/Table';
import { Link } from 'react-router-dom';
import { RootState } from '../../../store';
import { ITablaSuscripcionesProps } from './types';
import useStyles from './styles';

function TablaSuscripciones({
  columns,
  data,
  pageCount: controlledPageCount,
}: ITablaSuscripcionesProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { organizacion } = useSelector((state: RootState) => state.Organizaciones);
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      pageCount: controlledPageCount,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination,
  );
  const getCellValue = (value: string, rowIndex: number, cellIndex: number) => {
    if (cellIndex >= 0 && cellIndex <= 2) {
      let objAdminId = '-1';
      if (cellIndex > 0) {
        objAdminId = cellIndex === 1 ? data[rowIndex].admin1Id : data[rowIndex].admin2Id;
      }
      const content = cellIndex !== 0 && objAdminId === '-1' ? (
        <Button startIcon={<AddCircleOutlineIcon />} size="small">
          {t('agregar_administrador')}
        </Button>
      ) : (
        value
      );
      return (
        <Link
          to={{
            pathname: '/administracion',
            state: {
              idOrg: organizacion.idOrganizacion,
              objType: cellIndex === 0 ? 'organizacion' : 'administrador',
              objOrgId: data[rowIndex].id,
              objOrgLabel: data[rowIndex].nombre,
              objAdminId,
            },
          }}
        >
          {content}
        </Link>
      );
    }
    return <span>{value}</span>;
  };

  // Render the UI for your table
  return (
    <>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()} className={classes.header}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {data.length > 0 && (
          <TableBody>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <TableRow className={`${rowIndex % 2 === 0 ? 'bg-gray-200' : ''}`}>
                  {row.cells.map((cell, cellIndex) => {
                    if (cell.column) {
                      return (
                        <TableCell {...cell.getCellProps()} height="63px">
                          {getCellValue(cell.value, rowIndex + pageSize * pageIndex, cellIndex)}
                        </TableCell>
                      );
                    }
                    return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
                  })}
                </TableRow>
              );
            })}
            <Link
              to={{
                pathname: '/administracion',
                state: { idOrg: organizacion.idOrganizacion, objType: 'organizacion' },
              }}
            >
              <Button startIcon={<AddCircleOutlineIcon />} size="small">
                {t('agregar_otra_organizacion')}
              </Button>
            </Link>
          </TableBody>
        )}
      </MaUTable>
      <div className="flex pt-1 justify-between">
        <div className="flex pagination content-between items-center">
          <div className="pr-1">
            <Button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              variant="outlined"
              size="small"
            >
              {'<<'}
            </Button>
          </div>
          <div className="pr-1">
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              variant="outlined"
              size="small"
            >
              {'<'}
            </Button>
          </div>
          <div className="pr-1">
            <Button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              variant="outlined"
              size="small"
            >
              {'>'}
            </Button>
          </div>
          <div className="pr-1">
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              variant="outlined"
              size="small"
            >
              {'>>'}
            </Button>
          </div>
          <span className="text-gray-500">
            {`${t('resultado-paginas')} ${pageIndex + 1} / ${pageOptions.length}`}
          </span>
        </div>
      </div>
    </>
  );
}

export default TablaSuscripciones;
