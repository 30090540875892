import React from 'react';
import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogContent, DialogContentText, Slide,
} from '@material-ui/core';
/** importacion de validaciones */
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { TransitionProps } from '@material-ui/core/transitions';
import { IModalPronosticosProps, IListaElementoProps, IFecha } from './types';
import ViewComentarios from '../../../../../../../components/Comentarios/view';

require('dayjs/locale/es');

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#111827',
  },
});

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string | undefined) => void;
}

function ModalPronosticos(props: IModalPronosticosProps) {
  const { t } = useTranslation();
  const { open, callBackClose, arrayData } = props;

  const selectColor = (radioProcedimiento: string) => {
    let colorStyle = 'font-normal mt-4 mr-4 absolute top-0 right-0 text-gray-500';
    if (
      radioProcedimiento === 'd-p-plan-pron-reservado'
      || radioProcedimiento === 'd-p-plan-pron-malo'
    ) {
      colorStyle = 'font-normal mt-4 mr-4 absolute top-0 right-0 text-red-500';
    } else if (radioProcedimiento === 'd-p-plan-pron-regular') {
      colorStyle = 'font-normal mt-4 mr-4 absolute top-0 right-0 text-yellow-500';
    }
    return colorStyle;
  };

  const selectMarginColor = (radioProcedimiento: string) => {
    let colorStyle = 'border-0 border-solid border-gray-500 rounded border-l-8 p-4 w-full';
    if (
      radioProcedimiento === 'd-p-plan-pron-reservado'
      || radioProcedimiento === 'd-p-plan-pron-malo'
    ) {
      colorStyle = 'border-0 border-solid border-red-500 rounded border-l-8 p-4 w-full';
    } else if (radioProcedimiento === 'd-p-plan-pron-regular') {
      colorStyle = 'border-0 border-solid border-yellow-500 rounded border-l-8 p-4 w-full';
    }
    return colorStyle;
  };

  const formatDate = (fecha: IFecha) => {
    const strDate = `
      ${fecha.anio}-
      ${fecha.mes.length === 2 ? fecha.mes : `0${fecha.mes}`}
      ${fecha.dia.length === 2 ? fecha.dia : `0${fecha.dia}`}`;
    return strDate;
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="alert-dialog-titulo"
      aria-describedby="alert-dialo-descripcion"
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-end pl-4 bg-blue-800 text-white">
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <DialogContentText id="alert-dialog-descripcion">
          {arrayData.map((value: IListaElementoProps, inx: number) => (
            <div
              className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative"
              // eslint-disable-next-line react/no-array-index-key
              key={inx}
            >
              <h4 className={selectColor(value.radioProcedimiento)}>
                {`${t('pronostico')}: ${t(value.radioProcedimiento)}`}
              </h4>
              <div className={selectMarginColor(value.radioProcedimiento)}>
                <h2 className="m-0 overflow-ellipsis overflow-hidden">{value.diagnostico.label}</h2>
                <div className="flex flex-col">
                  <h4 className="font-normal text-gray-500 m-0 mt-2">
                    {`${t('d-p-plan-pron-fecha')}: ${dayjs(value.fechaCreacion).format(
                      'DD-MMM-YYYY',
                    )}`}
                  </h4>
                  {value.fecha.dia.length > 0
                  && value.fecha.mes.length > 0
                  && value.fecha.anio.length > 0 ? (
                    <h4 className="font-normal text-gray-900 m-0 mt-2">
                      {`${t('d-p-plan-pron-terminacion')}: ${dayjs(formatDate(value.fecha)).format(
                        'DD-MMM-YYYY',
                      )}.`}
                    </h4>
                    ) : null}
                  <h4 className="font-normal text-gray-900 m-0 mt-2">
                    {`${t('d-p-plan-pron-curso')}:`}
                  </h4>
                  {value.groupCheck.check1 ? (
                    <>
                      <div className="flex flex-row items-center mt-2">
                        <CheckIcon />
                        <h4 className="font-normal text-gray-900 m-0 ml-2">
                          {`${t('d-p-plan-pron-curara')}.`}
                        </h4>
                      </div>
                      {value.secuelaTratamiento.radio === 'N' ? (
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {`${t('d-p-plan-pron-secuela2')}: ${t('no')}`}
                        </h4>
                      ) : (
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {`${t('d-p-plan-pron-secuela2')}: ${
                            value.secuelaTratamiento.area.label
                          }. ${value.secuelaTratamiento.tipo}`}
                        </h4>
                      )}
                    </>
                  ) : null}
                  {value.groupCheck.check2 ? (
                    <>
                      <div className="flex flex-row items-center mt-2">
                        <CheckIcon />
                        <h4 className="font-normal text-gray-900 m-0 ml-2">
                          {`${t('d-p-plan-pron-espontaneamente')}.`}
                        </h4>
                      </div>
                      {value.secuelaEspontanea.radio === 'N' ? (
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {`${t('d-p-plan-pron-secuela2')}: ${t('no')}`}
                        </h4>
                      ) : (
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {`${t('d-p-plan-pron-secuela2')}: ${
                            value.secuelaEspontanea.area.label
                          }. ${value.secuelaEspontanea.tipo}`}
                        </h4>
                      )}
                    </>
                  ) : null}
                  {value.groupCheck.check6 ? (
                    <>
                      <div className="mt-2 w-full">
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {t('d-p-plan-pron-terapeuticas')}
                        </h4>
                        {value.terapeuticas.blocks.length > 0 ? (
                          <div>
                            <ViewComentarios comentarios={value.terapeuticas} />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                  {value.groupCheck.check7 ? (
                    <>
                      <div className="mt-2 w-full">
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {t('d-p-plan-pron-exacerbaciones')}
                        </h4>
                        {value.exacerbaciones.blocks.length > 0 ? (
                          <div>
                            <ViewComentarios comentarios={value.exacerbaciones} />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                  {value.groupCheck.check8 ? (
                    <>
                      <div className="mt-2 w-full">
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {t('d-p-plan-pron-remisiones')}
                        </h4>
                        {value.remisiones.blocks.length > 0 ? (
                          <div>
                            <ViewComentarios comentarios={value.remisiones} />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                  {value.groupCheck.check9 ? (
                    <>
                      <div className="mt-2 w-full">
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {t('d-p-plan-pron-recaidas')}
                        </h4>
                        {value.recaidas.blocks.length > 0 ? (
                          <div>
                            <ViewComentarios comentarios={value.recaidas} />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                  {value.groupCheck.check3 ? (
                    <>
                      <div className="mt-2 w-full">
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {t('d-p-plan-pron-causa')}
                        </h4>
                        {value.causa.blocks.length > 0 ? (
                          <div>
                            <ViewComentarios comentarios={value.causa} />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                  {value.groupCheck.check4 ? (
                    <>
                      <div className="mt-2 w-full">
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {t('d-p-plan-pron-cronica')}
                        </h4>
                        {value.cronica.blocks.length > 0 ? (
                          <div>
                            <ViewComentarios comentarios={value.cronica} />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                  {value.groupCheck.check5 ? (
                    <>
                      <div className="mt-2 w-full">
                        <h4 className="font-normal text-gray-900 m-0 mt-2">
                          {t('d-p-plan-pron-otros')}
                        </h4>
                        {value.otro.blocks.length > 0 ? (
                          <div>
                            <ViewComentarios comentarios={value.otro} />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="mt-2 w-full">
                  {value.comentarios.blocks.length > 0 ? (
                    <div>
                      <h4 className="font-normal text-gray-900 m-0 mt-3">
                        {t('d-p-plan-pron-comentarios')}
                      </h4>
                      <div>
                        <ViewComentarios comentarios={value.comentarios} />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default ModalPronosticos;
