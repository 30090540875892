import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  header: {
    color: 'inherit',
  },
  noTextTransform: {
    textTransform: 'none',
    color: '#3B82F6',
  },
}));

export default useStyles;
