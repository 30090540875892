import { IObjEvaluacionMedidas, IObjPresionArterial, IObjExamenColesterol } from './types';

export const objEvaluacionMedidasInitial: IObjEvaluacionMedidas = {
  talla: undefined,
  perCintura: undefined,
  peso: undefined,
  imc: undefined,
};

export const objPresionArterialInitial: IObjPresionArterial = {
  pSistolica: undefined,
  pDiastolica: undefined,
};

export const objExamenColesterolInitial: IObjExamenColesterol = {
  anio: '',
  dia: '',
  mes: '',
  valor: '',
};
