import React from 'react';
import { useSelector } from 'react-redux';
import ViewTxt from '../../../../../../components/Comentarios/view2';
import { RootState } from '../../../../../../store';
import { calculateEdad } from '../../../../../../common/functions';
import { IPrintProps } from './types';
import Footer from '../../Procedimientos/PrintHtml/Footer';
import Header from '../../Procedimientos/PrintHtml/Header';

const PrintStyle = React.forwardRef(
  ({ laboratoriosArray, comentarios, labelPdf }: IPrintProps, ref: React.Ref<HTMLInputElement>) => {
    const ordenarArray = [...laboratoriosArray];
    ordenarArray.sort((a, b) => {
      let valReturn = 0;
      if (a.catLaboratorios.idCat > b.catLaboratorios.idCat) {
        valReturn = 1;
      } else if (a.catLaboratorios.idCat < b.catLaboratorios.idCat) {
        valReturn = -1;
      }
      return valReturn;
    });
    const arrObj: any[] = [];
    let idActual = 0;
    ordenarArray.forEach((valor, inx) => {
      if (inx === 0 && valor.txtLibre.length === 0) {
        arrObj.push({
          titulo: valor.catLaboratorios.categoria,
          apartado: [valor.catLaboratorios.label],
        });
        idActual = valor.catLaboratorios.idCat;
      } else if (inx === 0 && valor.txtLibre.length !== 0) {
        arrObj.push({
          titulo: 'Otros',
          apartado: [valor.txtLibre],
        });
      } else if (valor.txtLibre.length !== 0) {
        const updateApartado = [...arrObj[arrObj.length - 1].apartado];
        updateApartado.push(valor.txtLibre);
        arrObj[arrObj.length - 1].apartado = updateApartado;
      } else if (valor.catLaboratorios.idCat !== 0 && idActual === valor.catLaboratorios.idCat) {
        const updateApartado = [...arrObj[arrObj.length - 1].apartado];
        updateApartado.push(valor.catLaboratorios.label);
        arrObj[arrObj.length - 1].apartado = updateApartado;
      } else if (valor.catLaboratorios.idCat !== 0 && idActual !== valor.catLaboratorios.idCat) {
        arrObj.push({
          titulo: valor.catLaboratorios.categoria,
          apartado: [valor.catLaboratorios.label],
        });
        idActual = valor.catLaboratorios.idCat;
      }
    });
    arrObj.sort((a) => {
      let valReturn = 0;
      if (a.titulo === 'Otros') {
        valReturn = 1;
      }
      return valReturn;
    });
    const {
      nombre, sexo, dia, mes, anio,
    } = useSelector(
      (state: RootState) => state.BasicosPaciente,
    );
    const {
      idMedico,
      nombre: nombreMedico,
      primerApellido,
      segundoApellido,
      sexo: sexoMedico,
      enOrganizacion,
    } = useSelector((state: RootState) => state.Me);
    const { consultorio } = useSelector((state: RootState) => state.Consultorios);
    const edad = dia && mes && anio ? calculateEdad(anio, mes, dia) : null;
    return (
      <div ref={ref}>
        <div className="m-6">
          <Header
            idMedico={idMedico}
            consultorio={consultorio}
            nombre={nombre}
            edad={edad}
            sexo={sexo}
            enOrganizacion={enOrganizacion}
            labelPdf={labelPdf}
          />
          <div className="grid grid-cols-3 gap-2 pt-4 pb-4">
            {arrObj
              && arrObj.map((objX, inx) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`print-${inx}`}>
                  <h4 className="mt-2 mb-2">{`${objX.titulo}`}</h4>
                  {objX
                    && objX.apartado
                    && objX.apartado.map((data: any, inxx: number) => (
                      <h4
                        className="mt-2 mb-2 font-normal"
                        // eslint-disable-next-line react/no-array-index-key
                        key={`apartado-${inxx}`}
                      >
                        {`- ${data}`}
                      </h4>
                    ))}
                </div>
              ))}
          </div>
          <div className="p-2 border-solid border border-gray-600">
            <ViewTxt comentarios={comentarios} key={Math.floor(Math.random() * (100 - 1)) + 1} />
          </div>
        </div>
        <Footer
          consultorio={consultorio}
          sexoMedico={sexoMedico}
          nombreMedico={nombreMedico}
          primerApellido={primerApellido}
          segundoApellido={segundoApellido}
        />
      </div>
    );
  },
);

export default PrintStyle;
