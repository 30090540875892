import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  ButtonGroup,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import useStyles from '../../../../../../common/styles';
import NivelCafeinaAlerta from '../../../../../../components/AlertasInformacion/NivelCafeina';
import { ICafeinaProps } from './types';
import { toxicomaniaInitial } from '../types';

function Cafeina(props: ICafeinaProps) {
  const {
    hayCambios, enGestacion, toxicomania, setToxicomania, getCafeina,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [openInfo, setOpenInfo] = useState(false);
  const numLabels = ['1', '2', '3', '4', '5+'];
  const estatus = ['niega', 'activo'];

  const handleChangeConsumoCafeina = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as 'niega' | 'activo';
    const newCafeina = val === 'niega' ? { ...toxicomaniaInitial.cafeina } : { ...toxicomania.cafeina };
    newCafeina.consumoCafeina = val;
    setToxicomania({
      ...toxicomania,
      cafeina: newCafeina,
    });
  };

  const handleChangeComentarios = (event: React.ChangeEvent<{ value: unknown }>) => {
    setToxicomania({
      ...toxicomania,
      cafeina: {
        ...toxicomania.cafeina,
        comentarios: event.target.value as string,
      },
    });
  };

  const handleChangeTazaDeCafe = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToxicomania({
      ...toxicomania,
      cafeina: {
        ...toxicomania.cafeina,
        tazaDeCafe: event.currentTarget.textContent as string,
      },
    });
  };

  const handleChangeLataBebida = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToxicomania({
      ...toxicomania,
      cafeina: {
        ...toxicomania.cafeina,
        lataDeBebidaEnergetica: event.currentTarget.textContent as string,
      },
    });
  };

  useEffect(() => {
    setToxicomania({
      ...toxicomania,
      cafeina: getCafeina(toxicomania.cafeina),
    });
  }, [toxicomania.cafeina.tazaDeCafe, toxicomania.cafeina.lataDeBebidaEnergetica, enGestacion]);

  return (
    <>
      <NivelCafeinaAlerta open={openInfo} setOpen={setOpenInfo} />
      <div className="border-solid border-0 border-b border-gray-600">
        <h2 className="mt-4 mb-0 font-normal text-blue-800">{t('cafeina')}</h2>
        <RadioGroup
          row
          name="consumo-cafeina"
          value={toxicomania.cafeina.consumoCafeina}
          onChange={handleChangeConsumoCafeina}
        >
          {estatus.map((valor) => (
            <FormControlLabel
              value={valor}
              control={<Radio color="primary" />}
              label={t(`${valor}`)}
              labelPlacement="end"
              disabled={hayCambios}
            />
          ))}
        </RadioGroup>
        {toxicomania.cafeina.consumoCafeina === 'activo' && (
          <>
            <div className="grid grid-cols-4 gap-4">
              <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('presentacion')}</h3>
              <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('cantidad_al_dia')}</h3>
              <h3 className="mt-4 mb-2 col-span-2 text-gray-600 font-medium">
                {t('total_aproximado_de_cafeina_al_dia')}
              </h3>
            </div>
            <div className="grid grid-cols-4 gap-y-2 gap-x-4  items-center">
              <h3 className="m-0 font-medium">{t('taza_de_cafe')}</h3>
              <ButtonGroup
                variant="outlined"
                color="primary"
                aria-label="cantidad-tazas-cafe"
                fullWidth
                disabled={hayCambios}
              >
                {numLabels.map((num) => (
                  <Button
                    variant={toxicomania.cafeina.tazaDeCafe === num ? 'contained' : 'outlined'}
                    onClick={handleChangeTazaDeCafe}
                  >
                    {num}
                  </Button>
                ))}
              </ButtonGroup>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{t('mg_de_cafeina/dia')}</InputAdornment>
                  ),
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                  },
                }}
                disabled
                value={toxicomania.cafeina.cafeinaAlDia}
              />
              <Button
                color="primary"
                size="small"
                className={classes.noTextTransform}
                onClick={() => setOpenInfo(true)}
                startIcon={<InfoIcon />}
                disabled={hayCambios}
              >
                {t('estimacion_de_nivel_de_cafeina')}
              </Button>
              <h3 className="m-0 font-medium">{t('lata_de_bebida_energetica')}</h3>
              <ButtonGroup
                variant="outlined"
                color="primary"
                aria-label="cantidad-latas-bebida-energetica"
                fullWidth
                disabled={hayCambios}
              >
                {numLabels.map((num) => (
                  <Button
                    variant={
                      toxicomania.cafeina.lataDeBebidaEnergetica === num ? 'contained' : 'outlined'
                    }
                    onClick={handleChangeLataBebida}
                  >
                    {num}
                  </Button>
                ))}
              </ButtonGroup>
              {toxicomania.cafeina.alarmaConsumoExcesivo && (
                <p className="text-red-500 col-span-2">
                  {enGestacion === 'si'
                    ? t('posible_consumo_excesivo_de_cafeina_durante_el_embarazo')
                    : t('posible_consumo_excesivo_de_cafeina')}
                </p>
              )}
            </div>
            <div className="my-4">
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                value={toxicomania.cafeina.comentarios}
                onChange={handleChangeComentarios}
                inputProps={{
                  maxLength: 300,
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  className: 'uppercase',
                }}
                label={t('comentarios')}
                disabled={hayCambios}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Cafeina;
