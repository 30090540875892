import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  noTextTranform: {
    textTransform: 'none',
  },
  header: {
    backgroundColor: 'rgb(59, 130, 246)',
    color: '#FFFFFF',
  },
  row: {
    backgroundColor: 'rgb(147, 197, 253)',
    color: '#111827',
  },
});

export default useStyles;
