import React from 'react';
import { useTable, usePagination } from 'react-table';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '../../../../../components/PaginationButtons';
import { IPropsTablaErroresEquipoMedico } from './types';
import useStyles from './styles';

function TablaErroresEquipoMedico(props: IPropsTablaErroresEquipoMedico) {
  const classes = useStyles();
  // Use the state and functions returned from useTable to build your UI
  const { columns, data, pageCount: controlledPageCount } = props;
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      pageCount: controlledPageCount,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination,
  );
  // Render the UI for your table
  return (
    <>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()} className={classes.header}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const rowIndex = parseInt(cell.row.id, 10) || 0;
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={rowIndex % 2 === 0 ? `${classes.row}` : `${classes.row2}`}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
      <Pagination
        pageIndex={pageIndex}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
      />
    </>
  );
}

export default TablaErroresEquipoMedico;
