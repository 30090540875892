import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
}));

export default useStyles;
