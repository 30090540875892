import React, { useState, useRef } from 'react';
import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ColorButton } from 'material-ui-color';
import { useTable } from 'react-table';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
  TextField,
  Button,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import MaUTable from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TransitionProps } from '@material-ui/core/transitions';
import { RootState } from '../../../store';
import { IConfigurarDialogoProps, INewEventProps } from './types';
import { setSnackComplete } from '../../../actions/snackbar/types';
import { sendAgendaConfigurar } from '../../../utils/sendInfo';
import { sendAgendaActualizar } from '../../../utils/editInfo';
import { agendaEliminarConfiguracion } from '../../../utils/deleteInfo';
import useStyles from './styles';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#111827',
  },
});

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string | undefined) => void;
}

function ConfigurarDialogo(props: IConfigurarDialogoProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.Me);
  const {
    open, titulo, callBackClose, arrayCita, setArrayCita,
  } = props;
  const columnas = [
    {
      Header: t('calendar-columna-cita'),
      accessor: 'cita',
    },
    {
      Header: t('calendar-columna-etiqueta'),
      accessor: 'etiqueta',
    },
    {
      Header: '',
      accessor: 'noBorrar',
    },
  ];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const overlayEl = useRef<HTMLDivElement>(null);
  const [inxSeleccionado, setInxSeleccionado] = useState<number>(-1);
  const [warBorrado, setWarBorrado] = useState<boolean>(false);
  const [warActualizar, setWarActualizar] = useState<boolean>(false);
  const [seActualiza, setSeActualiza] = useState<boolean>(false);
  const [nuevoEvento, setNuevoEvento] = useState<INewEventProps>({
    idMedico: me.idMedico,
    id: -1,
    cita: '',
    etiqueta: '#E5E7EB',
    noBorrar: true,
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleBack = () => {
    if (overlayEl.current) {
      overlayEl.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleUpdate = () => {
    const addUpd = { ...arrayCita[inxSeleccionado] };
    addUpd.idMedico = me.idMedico;
    setNuevoEvento(addUpd);
    setWarActualizar(false);
    setSeActualiza(true);
  };
  const handleDelete = () => {
    agendaEliminarConfiguracion({
      idMedico: me.idMedico,
      id: arrayCita[inxSeleccionado].id,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-delete-success'),
            }),
          );
          setNuevoEvento({
            idMedico: me.idMedico,
            id: -1,
            cita: '',
            etiqueta: '#E5E7EB',
            noBorrar: true,
          });
          setWarBorrado(false);
          setInxSeleccionado(-1);
          setArrayCita(result.datos);
          callBackClose();
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = t('campo_repetido');
          } else {
            mensaje = result.msg;
          }
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${error.toString()}`,
          }),
        );
      });
  };
  const handleActualizarAgenda = () => {
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-update'),
      }),
    );
    sendAgendaActualizar(nuevoEvento)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          setNuevoEvento({
            idMedico: me.idMedico,
            id: -1,
            cita: '',
            etiqueta: '#E5E7EB',
            noBorrar: true,
          });
          setArrayCita(result.datos);
          callBackClose();
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = t('campo_repetido');
          } else {
            mensaje = result.msg;
          }
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${error.toString()}`,
          }),
        );
      });
  };
  const handleGuardarAgenda = () => {
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    sendAgendaConfigurar(nuevoEvento)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          setNuevoEvento({
            idMedico: me.idMedico,
            id: -1,
            cita: '',
            etiqueta: '#E5E7EB',
            noBorrar: true,
          });
          setArrayCita(result.datos);
          callBackClose();
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = t('campo_repetido');
          } else {
            mensaje = result.msg;
          }
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${error.toString()}`,
          }),
        );
      });
  };
  const Table = (val: any) => {
    // Use the state and functions returned from useTable to build your UI
    const { columns, data } = val;
    const {
      getTableProps, headerGroups, rows, prepareRow,
    } = useTable({
      columns,
      data,
    });
    // Render the UI for your table
    return (
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()} className={classes.header}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row, inx) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const rowIndex = parseInt(cell.row.id, 10) || 0;
                  if (cell.column && cell.column.id === 'cita') {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  }
                  if (cell.column && cell.column.id === 'etiqueta') {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                      >
                        <div>
                          <ColorButton
                            color={cell.value}
                            tooltip={t('calendar-agenda-seleccionado')}
                          />
                        </div>
                      </TableCell>
                    );
                  }
                  if (cell.column && cell.column.id === 'etiqueta') {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                    >
                      <div className="min-w-max self-left">
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handleBack();
                            if (nuevoEvento.cita.length > 0) {
                              setWarActualizar(true);
                              setWarBorrado(false);
                              setInxSeleccionado(inx);
                            } else {
                              setSeActualiza(true);
                              setInxSeleccionado(inx);
                              const datAdd = { ...data[inx] };
                              datAdd.idMedico = me.idMedico;
                              setNuevoEvento(datAdd);
                            }
                          }}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                        {cell.value ? (
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              handleBack();
                              setWarActualizar(false);
                              setWarBorrado(true);
                              setInxSeleccionado(inx);
                            }}
                            color="primary"
                            disabled={inx === inxSeleccionado}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : null}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
    );
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="alert-dialog-titulo"
      aria-describedby="alert-dialo-descripcion"
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
        <h2 className="font-normal">{titulo}</h2>
        <IconButton
          aria-label="cerrar"
          color="inherit"
          onClick={() => {
            setNuevoEvento({
              idMedico: me.idMedico,
              id: -1,
              cita: '',
              etiqueta: '#E5E7EB',
              noBorrar: true,
            });
            setWarBorrado(false);
            setWarActualizar(false);
            setSeActualiza(false);
            setInxSeleccionado(-1);
            callBackClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <div className="py-2 px-0" ref={overlayEl}>
          <Collapse in={warActualizar}>
            <Alert
              action={(
                <>
                  <Button color="inherit" size="small" onClick={() => handleUpdate()}>
                    {t('aceptar')}
                  </Button>
                  <Button color="inherit" size="small" onClick={() => setWarActualizar(false)}>
                    {t('cancelar')}
                  </Button>
                </>
              )}
              severity="info"
            >
              {t('calendar-agenda-editar')}
            </Alert>
          </Collapse>
          <Collapse in={warBorrado}>
            <Alert
              action={(
                <>
                  <Button color="inherit" size="small" onClick={() => handleDelete()}>
                    {t('aceptar')}
                  </Button>
                  <Button color="inherit" size="small" onClick={() => setWarBorrado(false)}>
                    {t('cancelar')}
                  </Button>
                </>
              )}
              severity="error"
            >
              {t('calendar-agenda-eliminar')}
            </Alert>
          </Collapse>
        </div>
        <DialogContentText id="alert-dialog-descripcion">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="text-gray-900 font-normal mt-0 mb-2 col-span-2">
                {t('calendar-agenda-agregar')}
              </h4>
              <div className="mb-4">
                <TextField
                  variant="outlined"
                  type="nuevo"
                  value={nuevoEvento.cita}
                  onChange={(e) => {
                    const updDato = { ...nuevoEvento };
                    updDato.cita = e.target.value as string;
                    setNuevoEvento(updDato);
                  }}
                  fullWidth
                  inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 40 }}
                  disabled={!nuevoEvento.noBorrar}
                />
              </div>
              <h4 className="text-gray-900 font-normal mt-0 mb-2 col-span-2">
                {t('calendar-agenda-agregar-etiqueta')}
              </h4>
              <div className="mb-4 grid grid-cols-2">
                <div>
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    onClick={handleClick}
                    startIcon={(
                      <ColorButton
                        color={nuevoEvento.etiqueta}
                        tooltip={t('calendar-agenda-seleccionado')}
                      />
                    )}
                    endIcon={<ExpandMoreIcon />}
                  />
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        const upColor = { ...nuevoEvento };
                        upColor.etiqueta = '#E5E7EB';
                        setNuevoEvento(upColor);
                        handleClose();
                      }}
                    >
                      <ColorButton color="#E5E7EB" tooltip={t('calendar-agenda-color-1')} />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const upColor = { ...nuevoEvento };
                        upColor.etiqueta = '#FECACA';
                        setNuevoEvento(upColor);
                        handleClose();
                      }}
                    >
                      <ColorButton color="#FECACA" tooltip={t('calendar-agenda-color-2')} />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const upColor = { ...nuevoEvento };
                        upColor.etiqueta = '#FDE68A';
                        setNuevoEvento(upColor);
                        handleClose();
                      }}
                    >
                      <ColorButton color="#FDE68A" tooltip={t('calendar-agenda-color-3')} />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const upColor = { ...nuevoEvento };
                        upColor.etiqueta = '#A7F3D0';
                        setNuevoEvento(upColor);
                        handleClose();
                      }}
                    >
                      <ColorButton color="#A7F3D0" tooltip={t('calendar-agenda-color-4')} />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const upColor = { ...nuevoEvento };
                        upColor.etiqueta = '#BFDBFE';
                        setNuevoEvento(upColor);
                        handleClose();
                      }}
                    >
                      <ColorButton color="#BFDBFE" tooltip={t('calendar-agenda-color-5')} />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const upColor = { ...nuevoEvento };
                        upColor.etiqueta = '#C7D2FE';
                        setNuevoEvento(upColor);
                        handleClose();
                      }}
                    >
                      <ColorButton color="#C7D2FE" tooltip={t('calendar-agenda-color-6')} />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const upColor = { ...nuevoEvento };
                        upColor.etiqueta = '#DDD6FE';
                        setNuevoEvento(upColor);
                        handleClose();
                      }}
                    >
                      <ColorButton color="#DDD6FE" tooltip={t('calendar-agenda-color-7')} />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const upColor = { ...nuevoEvento };
                        upColor.etiqueta = '#FBCFE8';
                        setNuevoEvento(upColor);
                        handleClose();
                      }}
                    >
                      <ColorButton color="#FBCFE8" tooltip={t('calendar-agenda-color-8')} />
                    </MenuItem>
                  </Menu>
                </div>
                <div className="flex justify-end items-end">
                  {seActualiza ? (
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => {
                        handleActualizarAgenda();
                        setSeActualiza(false);
                      }}
                      className={classes.noTextTranform}
                      startIcon={<UpdateIcon />}
                      disabled={nuevoEvento.cita.length === 0}
                    >
                      {t('calendar-agenda-actualizar')}
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => {
                        handleGuardarAgenda();
                        setSeActualiza(false);
                      }}
                      className={classes.noTextTranform}
                      startIcon={<AddCircleOutlineIcon />}
                      disabled={nuevoEvento.cita.length === 0}
                    >
                      {t('calendar-agenda-agregar-boton')}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {arrayCita && arrayCita.length && <Table columns={columnas} data={arrayCita} />}
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default ConfigurarDialogo;
