import { Dispatch, SetStateAction } from 'react';
import { IFechaObj } from '../../../../../common/types';

interface IGeneralidades {
  menarquia: string;
  menarquiaNA: boolean;
  fur: IFechaObj;
  lactancia: string;
  menopausia: string;
  menopausiaNA: boolean;
  sintomasDelClimaterio: string;
  comentarios: string;
}

interface IMenstruacion {
  ritmoCada: string;
  ritmoDuracion: string;
  cantidad: string;
  dolor: string;
  comentarios: string;
}

interface IAnticonceptivo {
  arrayTabla: string[];
  metodoEnUso: string[];
  tiempoEnUso: string[];
  comentarios: string;
}

interface IEmbarazos {
  embarazos: string;
  gestas: string;
  para: string;
  cesareas: string;
  abortos: string;
  ectopicos: string;
  nacidosVivos: string;
  comentarios: string;
}

interface IResultadoCervicoUterino {
  check1: boolean | null;
  check2: boolean | null;
  check3: boolean | null;
  selector2: number | null;
  selector3: number | null;
  resultadoRadio: string | null;
  resultadoTexto: string;
}

export interface ICervicoUterino {
  arrayTabla: string[];
  estudio: string[];
  especifica: string[];
  resultado: IResultadoCervicoUterino[];
  dia: string[];
  mes: string[];
  anio: string[];
  comentarios: string[];
}

interface IMama {
  arrayTabla: string[];
  estudio: string[];
  especifica: string[];
  resultado: string[];
  dia: string[];
  mes: string[];
  anio: string[];
  comentarios: string[];
}

interface IDespistajeCancer {
  cervicoUterino: ICervicoUterino;
  mama: IMama;
}

interface IVasomotores {
  radiosValues: string[];
  comentariosValues: string[];
  items: string[];
}

interface ITractoGenital {
  radiosValues: string[];
  comentariosValues: string[];
  items: string[];
}

interface ISomaticos {
  radiosValues: string[];
  comentariosValues: string[];
  items: string[];
}

interface IPsicologicos {
  radiosValues: string[];
  comentariosValues: string[];
  items: string[];
}

interface ISintomasClimaterio {
  vasomotores: IVasomotores;
  tractoGenital: ITractoGenital;
  somaticos: ISomaticos;
  psicologicos: IPsicologicos;
}

interface IEstudio {
  radio: 'si' | 'no' | '';
  fecha: IFechaObj;
}

export interface IEnGestacionUsuario {
  loaded: boolean;
  enGestacion: '' | 'si' | 'no';
}

export interface IGinecoObstetrico {
  loaded: boolean;
  generalidades: IGeneralidades;
  menstruacionHabitual: IMenstruacion;
  anticonceptivo: IAnticonceptivo;
  embarazos: IEmbarazos;
  citologiaRadio: 'si' | 'no' | '';
  mastografia: IEstudio;
  ultrasonido: IEstudio;
  despistajeCancer: IDespistajeCancer;
  sintomasClimaterio: ISintomasClimaterio;
}

export const ginecoObstetricoInitial: IGinecoObstetrico = {
  loaded: false,
  generalidades: {
    menarquia: '',
    menarquiaNA: false,
    fur: {
      dia: '',
      mes: '',
      anio: '',
    },
    lactancia: '',
    menopausia: '',
    menopausiaNA: false,
    sintomasDelClimaterio: '',
    comentarios: '',
  },
  menstruacionHabitual: {
    ritmoCada: '',
    ritmoDuracion: '',
    cantidad: '',
    dolor: '',
    comentarios: '',
  },
  anticonceptivo: {
    arrayTabla: ['I'],
    metodoEnUso: [''],
    tiempoEnUso: [''],
    comentarios: '',
  },
  embarazos: {
    embarazos: '',
    gestas: '',
    para: '',
    cesareas: '',
    abortos: '',
    ectopicos: '',
    nacidosVivos: '',
    comentarios: '',
  },
  citologiaRadio: '',
  mastografia: {
    radio: '',
    fecha: { dia: '', mes: '', anio: '' },
  },
  ultrasonido: {
    radio: '',
    fecha: { dia: '', mes: '', anio: '' },
  },
  despistajeCancer: {
    cervicoUterino: {
      arrayTabla: ['I'],
      estudio: [''],
      especifica: [''],
      resultado: [
        {
          check1: null,
          check2: null,
          check3: null,
          selector2: null,
          selector3: null,
          resultadoRadio: null,
          resultadoTexto: '',
        },
      ],
      dia: [''],
      mes: [''],
      anio: [''],
      comentarios: [''],
    },
    mama: {
      arrayTabla: ['I'],
      estudio: [''],
      especifica: [''],
      resultado: [''],
      dia: [''],
      mes: [''],
      anio: [''],
      comentarios: [''],
    },
  },
  sintomasClimaterio: {
    vasomotores: {
      radiosValues: ['', '', ''],
      comentariosValues: ['', '', ''],
      items: ['bochorno', 'sudoracion_nocturna', 'palpitaciones'],
    },
    tractoGenital: {
      radiosValues: ['', ''],
      comentariosValues: ['', ''],
      items: ['resequedad_vaginal', 'dispareunia'],
    },
    somaticos: {
      radiosValues: ['', '', '', '', '', ''],
      comentariosValues: ['', '', '', '', '', ''],
      items: ['insomnio', 'piel_reseca', 'cefalea', 'mialgias', 'artralgias', 'mareos'],
    },
    psicologicos: {
      radiosValues: ['', '', '', '', '', ''],
      comentariosValues: ['', '', '', '', '', ''],
      items: [
        'depresion',
        'disminucion_de_la_libido',
        'fatiga',
        'alteraciones_en_la_memoria',
        'ansiedad',
        'cambios_en_el_estado_de_animo',
      ],
    },
  },
};

export interface IGinecoObstetricoProps {
  setActiveStep: Dispatch<
  SetStateAction<
  | 'perfil'
  | 'mcpa'
  | 'antecedentes'
  | 'aparatos-y-sistemas'
  | 'exploracion-fisica'
  | 'diagnostico-y-plan'
  | 'incapacidades'
  >
  >;
  enGestacion: '' | 'si' | 'no';
  setEnGestacion: Dispatch<SetStateAction<'' | 'si' | 'no'>>;
  ginecoObstetrico: IGinecoObstetrico;
  setGinecoObstetrico: Dispatch<SetStateAction<IGinecoObstetrico>>;
  cambiosUsuario: string[];
  enGestacionUsuario: IEnGestacionUsuario;
  setEnGestacionUsuario: Dispatch<SetStateAction<IEnGestacionUsuario>>;
  ginecoObstetricoUsuario: IGinecoObstetrico;
  setGinecoObstetricoUsuario: Dispatch<SetStateAction<IGinecoObstetrico>>;
}
