import { Dispatch, SetStateAction } from 'react';

export interface IAlimentos {
  alimento: string;
  especifica: string;
}

export interface IAlimentacion {
  loaded: boolean;
  dieta: string;
  alimentos: IAlimentos[];
  botonComidas: number | null;
  botonFruta: number | null;
  botonVegetales: number | null;
  comidas: number | null;
  carne: number | null;
  pollo: number | null;
  pescado: number | null;
  refrescos: number | null;
  postres: number | null;
  comentarios: string;
}

export const alimentacionInitial: IAlimentacion = {
  loaded: false,
  dieta: '',
  alimentos: [{ alimento: '', especifica: '' }],
  botonComidas: null,
  botonFruta: null,
  botonVegetales: null,
  comidas: null,
  carne: null,
  pollo: null,
  pescado: null,
  refrescos: null,
  postres: null,
  comentarios: '',
};

export interface IAlimentacionProps {
  setActiveSubStep: Dispatch<
  SetStateAction<
  | 'vivienda'
  | 'higiene'
  | 'alimentacion'
  | 'actividad-fisica'
  | 'inmunizaciones'
  | 'toxicomania'
  | 'gineco-obstetricos'
  | 'historia-sexual'
  >
  >;
  alimentacion: IAlimentacion;
  setAlimentacion: Dispatch<SetStateAction<IAlimentacion>>;
  cambiosUsuario: string[];
  alimentacionUsuario: IAlimentacion;
  setAlimentacionUsuario: Dispatch<SetStateAction<IAlimentacion>>;
}
