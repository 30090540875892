import React from 'react';
// import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { IViewComentariosProps } from './types';

function ViewComentarios({ comentarios }: IViewComentariosProps) {
  return (
    <Editor
      toolbarHidden
      initialContentState={comentarios}
      readOnly
      wrapperStyle={{ height: 'min-content' }}
    />
  );
}

export default ViewComentarios;
