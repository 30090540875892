import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import dayjs from 'dayjs';
import { RootState } from '../../../../store';
import { setSnackComplete } from '../../../../actions/snackbar/types';
import { IAdministradoresProps } from './types';
import { sendEnviaAdministradorBienvenida } from '../../../../utils/sendInfo';
import DeleteDialog from '../../../../components/DeleteDialog';

function Administradores({
  administrador,
  objAdministrador,
  setObjAdministrador,
  setDocId,
  deleteAdministrador,
}: IAdministradoresProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email, pais } = useSelector((state: RootState) => state.Me);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);

  const editAdministrador = () => {
    setObjAdministrador({ ...administrador, password: '*' });
    setDocId(administrador.docId);
    window.scrollTo({ top: 180, left: 0, behavior: 'smooth' });
  };
  const handleEditAdminstrador = () => {
    if (
      objAdministrador.nombre.length === 0
      && objAdministrador.apellidoP.length === 0
      && objAdministrador.docId.length === 0
      && objAdministrador.sexo.length === 0
      && objAdministrador.fechaNac.dia.length === 0
      && objAdministrador.fechaNac.mes.length === 0
      && objAdministrador.nacionalidad.id.length === 0
      && objAdministrador.fechaNac.anio.length === 0
      && objAdministrador.celular.numero.length === 0
      && objAdministrador.email.length === 0
      && objAdministrador.password?.length === 0
    ) {
      editAdministrador();
    } else {
      setEditAlert(true);
    }
  };

  const sendBienvenida = () => {
    sendEnviaAdministradorBienvenida(administrador.email, email, idOrganizacion)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('email_enviado_correcto'),
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('email_enviado_error'),
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('medico_perfil_error_400')} ${err.toString()}`,
          }),
        );
      });
  };
  return (
    <div>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('administrador_borrar')}
        callBackAceptar={() => deleteAdministrador(administrador.id, administrador.organizacion.id)}
        callBackClose={() => setDeleteAlert(false)}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_el_registro_se_borrara_el_que_estas_creando')}
        callBackAceptar={editAdministrador}
        callBackClose={() => setEditAlert(false)}
      />
      <div className="shadow-md border border-solid border-gray-100 rounded mt-2 flex justify-between relative">
        <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 pr-5 w-full">
          <h3 className="font-normal text-gray-500 m-0 mr-4">
            {`${t('nombre')}: ${administrador.nombre} ${administrador.apellidoP} ${
              administrador.apellidoS
            }`}
          </h3>
          <h4 className="font-normal text-gray-500 m-0 mr-4">
            {`${t(`doc_id_${pais}`)}: ${administrador.docId.toUpperCase()}`}
          </h4>
          <h4 className="font-normal text-gray-500 m-0 mr-4">
            {`${t('sexo')}: ${administrador.sexo === 'H' ? 'Hombre' : 'Mujer'}`}
          </h4>
          <h4 className="font-normal text-gray-500 m-0 mr-4">
            {`${t('fecha_nacimiento')}: ${
              administrador.fechaNac.dia
              && administrador.fechaNac.mes
              && administrador.fechaNac.anio
              && dayjs(
                `${administrador.fechaNac.anio}-${administrador.fechaNac.mes}-${administrador.fechaNac.dia}`,
              ).format('DD-MMM-YYYY')
            }`}
          </h4>
          <h4 className="font-normal text-gray-500 m-0 mr-4">
            {`${t('telefono_celular')}: ${
              administrador.celular.codigo + administrador.celular.numero || ''
            } - ${t('telefono_fijo')}: ${
              administrador.telefono.codigo + administrador.telefono.numero || ''
            }`}
          </h4>
          <div className="flex items-center m-0">
            <h4 className="font-normal m-0 text-gray-500 mr-4 pr-6">
              {`${t('email')}: ${administrador.email}`}
            </h4>
            <Button
              color="primary"
              size="small"
              onClick={sendBienvenida}
              // className={classes.noTextTranform}
            >
              {t('reenviar_correo_invitacion')}
            </Button>
          </div>
          <h4 className="font-normal text-gray-500 m-0 mr-4">
            {`${t('nacionalidad')}: ${administrador.nacionalidad.label}`}
          </h4>
        </div>
        <div className="min-w-max self-center">
          <IconButton aria-label="edit" color="primary" onClick={handleEditAdminstrador}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" color="primary" onClick={() => setDeleteAlert(true)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default Administradores;
