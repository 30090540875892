/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ResumenPago from '../ResumenPago';
import TipoSuscripcion from './TipoSubscripcion';
import { ICheckoutProps } from './types';
import { RootState } from '../../../store';
import {
  crearCustomerSubscriptionConekta,
  crearSubscripcionConekta,
  crearTokenConekta,
} from '../../../utils/sendInfo';
import { setSnackComplete } from '../../../actions/snackbar/types';
import loading from '../../../actions/loading/actions';
import AnalizandoPago from '../AnalizandoPago';
import TarjetaRechazadaModal from '../../../components/ModalDialogs/TarjetaRechazada';
import LlamarPagoModal from '../../../components/ModalDialogs/LlamarPago';

declare const window: Window & typeof globalThis & { ConektaCheckoutComponents: any };

function Checkout({ customer }: ICheckoutProps) {
  const { t } = useTranslation();
  const [triggerConekta, setTriggerConekta] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: email } = useSelector((state: RootState) => state.Auth);
  const [tipoSuscripcion] = useState('premium');
  const [tiempoSuscripcion, setTiempoSuscripcion] = useState('anual');
  const [tokID, setTokID] = useState('');
  const [tokenTarjeta, setTokenTarjeta] = useState('');
  const [checkID, setCheckID] = useState('');
  const [cust, setCust] = useState<string>('');
  const [custoM, setCustoM] = useState<string>('');
  const [modalError, setModalError] = useState<boolean>(false);
  const [modalLlame, setModalLlame] = useState<boolean>(false);

  const tokenCreadoCorrectamente = (objToken: { [key: string]: any }) => {
    dispatch(loading(true));
    // si el token fue creado correctamente
    // solo por seguridad nos percatamos que el id del token updateado sea el mismo que tenemos guardado
    if (objToken.id === tokID) {
      // aqui vamos a cargar el token en otra variable para que nos genere un use effect de que el token se cambio
      setTokenTarjeta(objToken.id);
    } else {
      // mostrar algún error -CREO JAMAS SUCEDERÁ-
      dispatch(loading(false));
    }
  };

  const errorTokenCreado = (objErr: { [key: string]: any }) => {
    // en caso que el token no se haya podidio crear mostrar una galleta al usuario
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'error',
        mensaje: `${t('message-error-warning')} ${
          objErr.message_to_purchaser
        } - Reintentar Nuevamente`,
      }),
    );
  };

  const cancelarModalLlamar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setModalLlame(false);
    history.push('/subscripcion');
    window.location.reload();
  };

  const aceptarModalError = () => {
    window.location.reload();
  };

  const cancelarModalError = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setModalError(false);
    history.push('/subscripcion');
    window.location.reload();
  };

  useEffect(() => {
    if (tokenTarjeta) {
      // tenemos que verificar si es un usuario con customer, o un usuario sin customer
      if (customer) {
        // en caso que sea un usuario con un customer creado
        // significan varias cosas, como cuenta sin pago, cuenta creada con tarjeta incorrecta etc.
        // solo debemos agregar la tarjeta a su metodo de pago y crear la subscripcion
        // por eso enviamos el token de la tarjeta, el plan seleccionado y el customer previamente creado
        // un customer (de conekta) por usuario (PA_USUARIO)
        crearSubscripcionConekta({
          tokenTarjeta: tokID,
          email,
          planSeleccionado: `plan-${tipoSuscripcion[0].toUpperCase()}${tiempoSuscripcion[0].toUpperCase()}`,
          customer,
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              // si todo es correcto entonces significa que podemos mostrar la pantalla de verificacion del pago, por lo que
              // seteamos en otra variable el customer (para que nos salga esa pantalla) y la variable para mostrar el proceso
              setCust(result.data.customer);
            } else if (result.code === 201) {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-error-general')} ${t(result.msg)}`,
                }),
              );
            } else {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-error-general')} ${result.detail}`,
                }),
              );
            }
            dispatch(loading(false));
          })
          .catch((err) => {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
          });
      } else {
        // en caso de ser un usuario nuevo se debe crear un customer
        // solamente con el token de la tarjeta, el email y el plan seleccionado
        crearCustomerSubscriptionConekta({
          tokenTarjeta: tokID,
          email,
          planSeleccionado: `plan-${tipoSuscripcion[0].toUpperCase()}${tiempoSuscripcion[0].toUpperCase()}`,
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              // mostramos la pantalla de procesar pago y seteamos el cust con el customer que acabamos de crear
              setCust(result.data.customer);
            } else if (result.code === 401) {
              // en caso que hayamos creado todo lo demas pero no se haya guardado en nuestra base de datos
              // que nos llamen
              setCustoM(result.data.customer);
              setModalLlame(true);
            } else {
              // si ocurrio algun error al no tener el cobro entonces solo se marca de manera correcta acá
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: result.msg,
                }),
              );
            }
            dispatch(loading(false));
          })
          .catch((err) => {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
          });
      }
    }
  }, [tokenTarjeta]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://pay.conekta.com/v1.0/js/conekta-checkout.min.js';
    script.async = true;
    document.body.appendChild(script);
    // procedemos a intentar crear un token vacio
    crearTokenConekta()
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          // si todo fue correcto entonces seteamos el token y el checkout_id (para inyectar en el infame iframe de conekta)
          setTokID(result.data.id);
          setCheckID(result.data.check);
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${result.msg} ${result.detail}`,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} Error de Conekta ${err.toString()}`,
          }),
        );
      });
    return () => {
      // al desmontar el compponente removemos el script
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // cada que se trigeree el trigger de conekta verificamos que tengamos el script
    // correctamente cargado y tengamos el checkout_id de conekta
    if (window.ConektaCheckoutComponents && checkID.length) {
      // intentamos montar el iframe de conekta en nuestro div
      // conekta utiliza zoid de una manera algo infame ojalá pronto resulvan el bug
      // de que aunque desmontes el componente completo, si no cargas la pagina no puedes
      // volver a crear o llamar el iframe
      try {
        window.ConektaCheckoutComponents.Card({
          targetIFrame: '#conektaIframeContainer',
          allowTokenization: true,
          checkoutRequestId: checkID,
          publicKey: process.env.REACT_APP_CONEKTA_PUBLIC_KEY,
          options: {
            styles: {
              inputType: 'basic',
              buttonType: 'basic',
              colors: {
                primary: '#3B82F6',
                secondary: '#f8961e',
              },
            },
            languaje: 'es',
            button: {
              text: 'Pagar ahora',
              backgroundColor: '#3B82F6',
            },
            iframe: {
              colorText: '#4B5563',
              backgroundColor: '#fff',
              // backgroundColor: '#F3F4F6',
            },
          },
          onCreateTokenSucceeded: tokenCreadoCorrectamente,
          onCreateTokenError: errorTokenCreado,
        });
      } catch (err) {
        window.location.reload();
      }
    } else {
      // en caso que no este montado volvemos a esperar 1 segundo para reintentar mostrar el
      // web checkout tokenizer de conekta
      setTimeout(() => {
        setTriggerConekta((pre) => !pre);
      }, 1000);
    }
  }, [triggerConekta]);

  return (
    <>
      <TarjetaRechazadaModal
        open={modalError}
        callBackAceptar={aceptarModalError}
        callBackClose={cancelarModalError}
      />
      <LlamarPagoModal
        open={modalLlame}
        callBackClose={cancelarModalLlamar}
        customer={cust || custoM}
        email={email}
      />
      {cust ? (
        <AnalizandoPago
          email={email}
          customer={cust}
          setCust={setCust}
          setModalError={setModalError}
          setModalLlame={setModalLlame}
        />
      ) : (
        <>
          <div className="pb-4 mt-4">
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => history.goBack()}
            >
              {t('regresar')}
            </Button>
          </div>
          <div className="p-4 shadow-lg bg-white rounded-lg border border-solid border-gray-200">
            <div className="w-full flex">
              <div className="w-2/3 pr-4">
                <h1 className="m-0 mb-2 font-medium text-gray-600">
                  {t('completa_tus_datos_y_finaliza_tu_compra')}
                </h1>
                <TipoSuscripcion
                  tipoSuscripcion={tipoSuscripcion}
                  tiempoSuscripcion={tiempoSuscripcion}
                  setTiempoSuscripcion={setTiempoSuscripcion}
                />
              </div>
              <div className="w-1/3 flex flex-col">
                <h1 className="m-0 mb-2 font-medium text-gray-600">{t('resumen_de_pago')}</h1>
                <ResumenPago
                  tipoSuscripcion={tipoSuscripcion}
                  tiempoSuscripcion={tiempoSuscripcion}
                />
              </div>
            </div>
            <div
              className="pt-2 shadow-lg bg-white rounded-lg border border-solid border-gray-200 mt-4 mb-2"
              // className="border border-solid border-gray-200 rounded-lg shadow-lg bg-white mt-4 pt-2"
              id="conektaIframeContainer"
              style={{ width: '100%', height: '595px' }}
            />
          </div>
        </>
      )}
    </>
  );
}

export default Checkout;
