import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.16rem',
    height: '1.1876em',
  },
});

export default useStyles;
