export const SET_REQUEST_FILE_COMPLETE = 'SET_REQUEST_FILE_COMPLETE';

export interface IRequestFile {
  type: null | 'get' | 'delete';
  idMedico: number | null;
  idPaciente: number | null;
  idArchivo: number;
  nombreArchivo: string | null;
  pathArchivo: string;
  tipoArchivo: string;
  successFunction?: any;
}

interface SetComplete {
  type: typeof SET_REQUEST_FILE_COMPLETE;
  payload: IRequestFile;
}

export type RequestFileActionTypes = SetComplete;
