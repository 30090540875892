import { ButtonGroup, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledButtonGroup = withStyles({
  groupedContainedPrimary: {
    '&:not(:last-child)': {
      border: 'none',
    },
  },
  root: {
    borderRadius: '50px',
  },
})(ButtonGroup);

export const StyledFirstButton = withStyles({
  root: {
    padding: '10px 0',
    fontSize: '1rem',
    borderTopLeftRadius: '0.5rem',
    borderBottomLeftRadius: '0.5rem',
    '&:hover': {
      backgroundColor: '#2563EB',
    },
  },
  label: {
    borderRight: '1px solid white',
    padding: '0 16px',
    height: '100%',
  },
})(Button);

export const StyledButton = withStyles({
  root: {
    padding: '10px 0',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#2563EB',
    },
  },
  label: {
    borderRight: '1px solid white',
    borderLeft: '1px solid white',
    padding: '0 16px',
    height: '100%',
  },
})(Button);

export const StyledLastButton = withStyles({
  root: {
    padding: '10px 0',
    fontSize: '1rem',
    borderTopRightRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
    '&:hover': {
      backgroundColor: '#2563EB',
    },
  },
  label: {
    borderLeft: '1px solid white',
    padding: '0 16px',
    height: '100%',
  },
})(Button);
