import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IObjTarjetaConekta, IVerMetodosDePagoProps } from './types';
import { getInfoMetodosPago } from '../../../utils/getCatalogos';
import { setSnackComplete } from '../../../actions/snackbar/types';
import loading from '../../../actions/loading/actions';
import TarjetaInfo from './TarjetaInfo';
import DeleteDialog from '../../../components/DeleteDialog';
import { sendTarjetaConektaEliminar } from '../../../utils/deleteInfo';

function VerMetodosPago({ customer }: IVerMetodosDePagoProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [arrayTarjeta, setArrayTarjetas] = useState<IObjTarjetaConekta[]>([]);
  const [modalData, setModalData] = useState({ open: false, idTarjeta: '', last4: '' });

  const handleClickEliminarTarjeta = (idMetodo: string, ultimos: string) => {
    setModalData({ open: true, idTarjeta: idMetodo, last4: ultimos });
  };

  const modalAceptar = () => {
    dispatch(loading(true));
    sendTarjetaConektaEliminar({ customer, id: modalData.idTarjeta })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setArrayTarjetas((preVAT) => preVAT.filter((tarje) => tarje.id !== result.data.id));
        } else if (result.code === 400) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-general')} ${result.data.t} ${result.detail}`,
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-general')} ${result.msg} ${result.detail}`,
            }),
          );
        }
        dispatch(loading(false));
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: err,
          }),
        );
        dispatch(loading(false));
      });
  };

  const modalCancelar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setModalData({ open: false, idTarjeta: '', last4: '' });
  };

  useEffect(() => {
    if (customer.length) {
      dispatch(loading(true));
      getInfoMetodosPago(customer)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setArrayTarjetas(result.data.data);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-general')} ${result.msg} ${result.detail}`,
              }),
            );
          }
          dispatch(loading(false));
        })
        .catch((err) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: err,
            }),
          );
          dispatch(loading(false));
        });
    }
  }, [customer]);

  return (
    <>
      <DeleteDialog
        open={modalData.open}
        titulo={t('eliminar_tarjeta')}
        descripcion={`${t('_esta_seguro_de_eliminar_la_tarjeta_')}. ${t('con_terminacion')} ${
          modalData.last4
        }`}
        callBackAceptar={modalAceptar}
        callBackClose={modalCancelar}
      />
      <div className="pb-4 mt-4">
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            history.goBack();
          }}
        >
          {t('regresar')}
        </Button>
      </div>
      <div className="py-4 shadow-lg bg-white rounded-lg border border-solid border-gray-200">
        <div className="container mx-auto w-10/12">
          {arrayTarjeta.map((tarjeta) => (
            <TarjetaInfo
              key={tarjeta.id}
              last4={tarjeta.last4}
              brand={tarjeta.brand}
              predeterminada={tarjeta.default}
              createdAt={tarjeta.created_at}
              expMonth={tarjeta.exp_month}
              expYear={tarjeta.exp_year}
              handleClickEliminar={handleClickEliminarTarjeta}
              id={tarjeta.id}
            />
          ))}
          {arrayTarjeta.length === 0 && (
            <h2 className="text-blue-800 font-medium">{t('sin_metodos_de_pago_registrados')}</h2>
          )}
        </div>
      </div>
    </>
  );
}

export default VerMetodosPago;
