import { Dispatch, SetStateAction } from 'react';
import { ICatalogosEstandar, IEdad } from '../../../common/types';

export interface ISintomaEF {
  nombreSintoma: string;
  comentarioSintoma: string;
  normal: string;
}

export interface IExploracionFisica {
  loaded: boolean;
  sitioMedicion: ICatalogosEstandar;
  temperatura: string;
  fCardiaca: string;
  fCardiacaRadio: string;
  diastolica: string;
  sistolica: string;
  pMedia: string;
  pPulso: string;
  pArterialRadio: string;
  fRespiratoria: string;
  satOxigeno: string;
  peso: string;
  altura: string;
  imc: string;
  perCintura: string;
  contenidoTabla: ISintomaEF[];
}

export const exploracionFisicaInitial: IExploracionFisica = {
  loaded: false,
  sitioMedicion: { id: 0, label: '' },
  temperatura: '',
  fCardiaca: '',
  fCardiacaRadio: '',
  diastolica: '',
  sistolica: '',
  pMedia: '',
  pPulso: '',
  pArterialRadio: '',
  fRespiratoria: '',
  satOxigeno: '',
  peso: '',
  altura: '',
  imc: '',
  perCintura: '',
  contenidoTabla: [],
};

export interface IExploracionFisicaProps {
  setActiveStep: Dispatch<
  SetStateAction<
  | 'perfil'
  | 'mcpa'
  | 'antecedentes'
  | 'aparatos-y-sistemas'
  | 'exploracion-fisica'
  | 'diagnostico-y-plan'
  | 'incapacidades'
  >
  >;
  exploracionFisica: IExploracionFisica;
  setExploracionFisica: Dispatch<SetStateAction<IExploracionFisica>>;
  edad: IEdad;
}

export interface IControlErrores {
  peso: boolean;
  altura: boolean;
  cintura: boolean;
  sistolica: boolean;
  diastolica: boolean;
  fcardiaca: boolean;
  frespiratoria: boolean;
  temperatura: boolean;
  sp: boolean;
}
