import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  RadioGroup, FormControlLabel, Radio, TextField, InputAdornment,
} from '@material-ui/core';
import { RootState } from '../../../../../../store';
import { iEdadMenorOIgual } from '../../../../../../common/functions';
import { validacionSoloNumeros } from '../../../../../../constants/validaciones';
import useStyles from '../../../../../../common/styles';
import { ITabacoProps } from './types';

function Tabaco(props: ITabacoProps) {
  const {
    hayCambios, toxicomania, setToxicomania, getTabaco,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { edad } = useSelector((state: RootState) => state.BasicosPaciente);
  // const edadPaciente = parseInt(calculateEdad(anio, mes, dia), 10);
  // const iEdadPaciente = edadCalculada(anio, mes, dia);
  const [errores, setErrores] = useState({
    edadInicio: { error: false, helperText: '' },
    edadTermino: { error: false, helperText: '' },
    general: { error: false, helperText: '' },
  });
  const estatus = ['niega', 'activo', 'ex-fumador'];

  const handleChangeConsumoTabaco = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as 'niega' | 'activo' | 'ex-fumador';
    const newTabaco = { ...toxicomania.tabaco };
    newTabaco.consumoTabaco = val;
    if (val === 'niega') {
      newTabaco.edadInicio = '';
      newTabaco.edadTermino = '';
      newTabaco.cigarrillosDia = '';
      newTabaco.comentarios = '';
    } else if (val === 'activo') {
      newTabaco.edadTermino = '';
    }
    setToxicomania({
      ...toxicomania,
      tabaco: newTabaco,
    });
  };

  const handleChangeEdadInicio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setToxicomania({
        ...toxicomania,
        tabaco: {
          ...toxicomania.tabaco,
          edadInicio: val,
        },
      });
      if (val !== '') {
        const age = parseInt(val, 10);
        if (!iEdadMenorOIgual({ edad: age, claveEdad: 5, periodo: 'anios' }, edad)) {
          setErrores((prev) => ({
            ...prev,
            edadInicio: {
              error: true,
              helperText:
                'la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente',
            },
          }));
        } else {
          setErrores((prev) => ({
            ...prev,
            edadInicio: {
              error: false,
              helperText: '',
            },
          }));
        }
      } else {
        setErrores((prev) => ({
          ...prev,
          edadInicio: {
            error: false,
            helperText: '',
          },
        }));
      }
    } else {
      setErrores((prev) => ({
        ...prev,
        edadInicio: {
          error: false,
          helperText: '',
        },
      }));
    }
  };

  const handleChangeEdadTermino = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setToxicomania({
        ...toxicomania,
        tabaco: {
          ...toxicomania.tabaco,
          edadTermino: val,
        },
      });
      if (val !== '') {
        const age = parseInt(val, 10);
        if (!iEdadMenorOIgual({ edad: age, claveEdad: 5, periodo: 'anios' }, edad)) {
          setErrores((prev) => ({
            ...prev,
            edadTermino: {
              error: true,
              helperText:
                'la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente',
            },
          }));
        } else if (
          iEdadMenorOIgual(
            { edad: age, claveEdad: 5, periodo: 'anios' },
            { edad: parseInt(toxicomania.tabaco.edadInicio, 10), claveEdad: 5, periodo: 'anios' },
          )
        ) {
          setErrores((prev) => ({
            ...prev,
            edadTermino: {
              error: true,
              helperText: 'la_edad_de_termino_es_menor_a_la_edad_actual_de_inicio',
            },
          }));
        } else {
          setErrores((prev) => ({
            ...prev,
            edadTermino: {
              error: false,
              helperText: '',
            },
          }));
        }
      } else {
        setErrores((prev) => ({
          ...prev,
          edadTermino: {
            error: false,
            helperText: '',
          },
        }));
      }
    } else {
      setErrores((prev) => ({
        ...prev,
        edadTermino: {
          error: false,
          helperText: '',
        },
      }));
    }
  };

  const handleChangeCigarrillosDia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setToxicomania({
        ...toxicomania,
        tabaco: {
          ...toxicomania.tabaco,
          cigarrillosDia: val,
        },
      });
    }
  };

  const handleChangeComentarios = (event: React.ChangeEvent<{ value: unknown }>) => {
    setToxicomania({
      ...toxicomania,
      tabaco: {
        ...toxicomania.tabaco,
        comentarios: event.target.value as string,
      },
    });
  };

  useEffect(() => {
    if (toxicomania.tabaco.consumoTabaco !== 'niega') {
      setToxicomania({
        ...toxicomania,
        tabaco: getTabaco(toxicomania.tabaco),
      });
    }
  }, [
    toxicomania.tabaco.cigarrillosDia,
    toxicomania.tabaco.edadInicio,
    toxicomania.tabaco.edadTermino,
    toxicomania.tabaco.consumoTabaco,
  ]);

  return (
    <div className="border-solid border-0 border-b border-gray-600">
      <h2 className="mt-4 mb-0 font-normal text-blue-800">{t('tabaco')}</h2>
      <RadioGroup
        row
        name="consumo-tabaco"
        value={toxicomania.tabaco.consumoTabaco}
        onChange={handleChangeConsumoTabaco}
      >
        {estatus.map((valor) => (
          <FormControlLabel
            value={valor}
            control={<Radio color="primary" />}
            label={t(`${valor}`)}
            labelPlacement="end"
            disabled={hayCambios}
          />
        ))}
      </RadioGroup>
      {(toxicomania.tabaco.consumoTabaco === 'activo'
        || toxicomania.tabaco.consumoTabaco === 'ex-fumador') && (
        <>
          <div
            className={`grid grid-cols-${
              toxicomania.tabaco.consumoTabaco === 'ex-fumador' ? '4' : '3'
            } gap-x-4 w-full`}
          >
            <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('edad_de_inicio')}</h3>
            {toxicomania.tabaco.consumoTabaco === 'ex-fumador' && (
              <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('edad_de_termino')}</h3>
            )}
            <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('cigarrillos_al_dia')}</h3>
            <h3 className="mt-4 mb-2 text-gray-600 font-medium">
              {t('indice_de_paquetes_al_año')}
            </h3>
          </div>
          <div
            className={`grid grid-cols-${
              toxicomania.tabaco.consumoTabaco === 'ex-fumador' ? '4' : '3'
            } gap-x-4 w-full items-center`}
          >
            <TextField
              variant="outlined"
              value={toxicomania.tabaco.edadInicio}
              onChange={handleChangeEdadInicio}
              InputProps={{
                endAdornment: <InputAdornment position="end">{t('anios')}</InputAdornment>,
                inputProps: {
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  maxLength: '2',
                },
              }}
              error={errores.edadInicio.error}
              helperText={errores.edadInicio.error ? t(errores.edadInicio.helperText) : ''}
              FormHelperTextProps={{ className: classes.absoluteBottom2 }}
              fullWidth
              disabled={hayCambios}
            />
            {toxicomania.tabaco.consumoTabaco === 'ex-fumador' && (
              <TextField
                value={toxicomania.tabaco.edadTermino}
                onChange={handleChangeEdadTermino}
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end">{t('anios')}</InputAdornment>,
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: '2',
                  },
                }}
                error={errores.edadTermino.error}
                helperText={errores.edadTermino.error ? t(errores.edadTermino.helperText) : ''}
                FormHelperTextProps={{ className: classes.absoluteBottom2 }}
                fullWidth
                disabled={hayCambios}
              />
            )}
            <TextField
              variant="outlined"
              value={toxicomania.tabaco.cigarrillosDia}
              onChange={handleChangeCigarrillosDia}
              InputProps={{
                inputProps: {
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  maxLength: '2',
                },
              }}
              fullWidth
              disabled={hayCambios}
            />
            <TextField value={toxicomania.tabaco.ipa} fullWidth disabled />
            {toxicomania.tabaco.ipa.length > 0
              && toxicomania.tabaco.alarmaTabaquismo.length > 0 && (
                <>
                  {toxicomania.tabaco.consumoTabaco === 'ex-fumador' && <div />}
                  <div />
                  <div />
                  <div className="text-red-500 self-center ml-4">
                    {t(`tabaquismo_${toxicomania.tabaco.alarmaTabaquismo}`)}
                  </div>
                </>
            )}
          </div>
        </>
      )}
      {(toxicomania.tabaco.consumoTabaco === 'activo'
        || toxicomania.tabaco.consumoTabaco === 'ex-fumador') && (
        <div className="my-4">
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={3}
            value={toxicomania.tabaco.comentarios}
            onChange={handleChangeComentarios}
            inputProps={{
              maxLength: 300,
              autoComplete: 'off',
              'aria-autocomplete': 'none',
              className: 'uppercase',
            }}
            label={t('comentarios')}
            disabled={hayCambios}
          />
        </div>
      )}
    </div>
  );
}

export default Tabaco;
