import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    justifyContent: 'left',
    textTransform: 'none',
  },
  boldText: {
    fontWeight: 'bold',
    color: 'black',
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  header: {
    backgroundColor: '#1E40AF',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  row: {
    backgroundColor: '#BFDBFE',
    textAlign: 'center',
    color: '#4B5563',
  },
  rowError: {
    backgroundColor: '#BFDBFE',
    textAlign: 'center',
    color: '#D97706',
  },
  // rowError: {
  //   backgroundColor: '#BFDBFE',
  //   textAlign: 'center',
  //   color: '#4B5563',
  //   border: '2px solid',
  //   borderLeft: 0,
  //   borderRight: 0,
  //   borderColor: '#D97706',
  // },
  // rowErrorInit: {
  //   backgroundColor: '#BFDBFE',
  //   textAlign: 'center',
  //   color: '#4B5563',
  //   border: '2px solid',
  //   borderRight: 0,
  //   borderColor: '#D97706',
  // },
  // rowErrorEnd: {
  //   backgroundColor: '#BFDBFE',
  //   textAlign: 'center',
  //   color: '#4B5563',
  //   border: '2px solid',
  //   borderLeft: 0,
  //   borderColor: '#D97706',
  // },
  row2: {
    backgroundColor: 'inherit',
    textAlign: 'center',
    color: '#4B5563',
  },
  row2Error: {
    backgroundColor: 'inherit',
    textAlign: 'center',
    color: '#D97706',
  },
  // row2Error: {
  //   backgroundColor: 'inherit',
  //   textAlign: 'center',
  //   color: '#D97706',
  //   border: '2px solid',
  //   borderLeft: 0,
  //   borderRight: 0,
  //   borderColor: '#D97706',
  // },
  // row2ErrorInit: {
  //   backgroundColor: 'inherit',
  //   textAlign: 'center',
  //   color: '#D97706',
  //   border: '2px solid',
  //   borderRight: 0,
  //   borderColor: '#D97706',
  // },
  // row2ErrorEnd: {
  //   backgroundColor: 'inherit',
  //   textAlign: 'center',
  //   color: '#D97706',
  //   border: '2px solid',
  //   borderLeft: 0,
  //   borderColor: '#D97706',
  // },
  rowTitle: {
    backgroundColor: '#BFDBFE',
    textAlign: 'left',
    color: '#4B5563',
  },
  rowTitle2: {
    backgroundColor: 'inherit',
    textAlign: 'left',
    color: '#4B5563',
  },
  noTextTranform: {
    textTransform: 'none',
    color: '#D97706',
  },
  noTextTransform: {
    textTransform: 'none',
    color: '#3B82F6',
  },
});

export default useStyles;
