import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { RootState } from '../../../store';
import useStyles from './styles';
import PersonalesNoPatologicos from './PersonalesNoPatologicos';
import PersonalesPatologicos from './PersonalesPatologicos';
import Familiares from './Familiares';
import Laborales from './Laborales';
import { IAntecedentesProps } from './types';
import { IDiagnosticoGeneral } from './PersonalesPatologicos/DiagnosticosGenerales/types';

function Antecedentes(props: IAntecedentesProps) {
  const {
    setActiveStep,
    enfReferidas,
    setEnfReferidas,
    catDiagnostico,
    setCatDiagnostico,
    enfNegadas,
    setEnfNegadas,
    grupoEnfNegadas,
    setGrupoEnfNegadas,
    diagnosticosGenerales,
    setDiagnosticosGenerales,
    tratamientos,
    setTratamientos,
    sinHospitalizaciones,
    setSinHospitalizaciones,
    hospitalizaciones,
    setHospitalizaciones,
    procDiagnosticos,
    setProcDiagnosticos,
    procTerapeuticos,
    setProcTerapeuticos,
    sinProcQuirurgicos,
    setSinProcQuirurgicos,
    procQuirurgicos,
    setProcQuirurgicos,
    sinAlergias,
    setSinAlergias,
    alergias,
    setAlergias,
    sinTransfusiones,
    setSinTransfusiones,
    transfusiones,
    setTransfusiones,
    enGestacion,
    setEnGestacion,
    vivienda,
    setVivienda,
    higiene,
    setHigiene,
    alimentacion,
    setAlimentacion,
    actividadFisica,
    setActividadFisica,
    inmunizaciones,
    setInmunizaciones,
    toxicomania,
    setToxicomania,
    ginecoObstetrico,
    setGinecoObstetrico,
    historiaSexual,
    setHistoriaSexual,
    familiares,
    setFamiliares,
    objLaboral,
    setObjLaboral,
    antecedentesLaborales,
    setAntecedentesLaborales,
    cambiosVivienda,
    cambiosHigiene,
    cambiosAlimentacion,
    cambiosActividadFisica,
    cambiosToxicomania,
    cambiosGinecoObstetrico,
    viviendaUsuario,
    setViviendaUsuario,
    higieneUsuario,
    setHigieneUsuario,
    alimentacionUsuario,
    setAlimentacionUsuario,
    actividadFisicaUsuario,
    setActividadFisicaUsuario,
    toxicomaniaUsuario,
    setToxicomaniaUsuario,
    enGestacionUsuario,
    setEnGestacionUsuario,
    ginecoObstetricoUsuario,
    setGinecoObstetricoUsuario,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { secciones, esPaciente } = useSelector((state: RootState) => state.Me);
  const { idUsuario, sexo } = useSelector((state: RootState) => state.BasicosPaciente);
  const [activeSubStep, setActiveSubStep] = useState<
  'personales-patologicos' | 'personales-no-patologicos' | 'familiares' | 'laborales'
  >('personales-patologicos');

  return (
    <>
      <div className="mt-4 mb-4 bg-blue-200 container mx-auto flex content-center items-center p-4">
        <h3 className="text-blue-500 font-medium m-0 pr-4">{`${t('antecedentes-label')}:`}</h3>
        {secciones.length > 0
          && secciones.find((valor) => valor.idSeccion >= 14 && valor.idSeccion <= 22) && (
            <div className="pr-4">
              <Button
                variant="outlined"
                color="primary"
                className={
                  activeSubStep === 'personales-patologicos'
                    ? classes.selected
                    : classes.buttonColor
                }
                onClick={() => setActiveSubStep('personales-patologicos')}
                startIcon={
                  !esPaciente
                  && idUsuario > 0
                  && diagnosticosGenerales.contenidoTabla?.some(
                    (diagnostico: IDiagnosticoGeneral) => diagnostico.posee === 'si' && !diagnostico.validado,
                  ) ? (
                    <Warning color="secondary" fontSize="small" />
                    ) : null
                }
              >
                {t('antecedentes-pp')}
              </Button>
            </div>
        )}
        {secciones.length > 0
          && secciones.find((valor) => valor.idSeccion >= 23 && valor.idSeccion <= 30) && (
            <div className="pr-4">
              <Button
                variant="outlined"
                color="primary"
                className={
                  activeSubStep === 'personales-no-patologicos'
                    ? classes.selected
                    : classes.buttonColor
                }
                onClick={() => setActiveSubStep('personales-no-patologicos')}
                startIcon={
                  !esPaciente
                  && idUsuario > 0
                  && (cambiosVivienda.filter((c: string) => c.length > 0).length > 0
                    || cambiosHigiene.filter((c: string) => c.length > 0).length > 0
                    || cambiosAlimentacion.filter((c: string) => c.length > 0).length > 0
                    || cambiosActividadFisica.filter((c: string) => c.length > 0).length > 0
                    || cambiosToxicomania.filter((c: string) => c.length > 0).length > 0
                    || cambiosGinecoObstetrico.filter((c: string) => c.length > 0).length > 0
                    || (sexo === 'M'
                      && enGestacionUsuario.loaded
                      && enGestacion !== enGestacionUsuario.enGestacion)) ? (
                        <Warning color="secondary" fontSize="small" />
                    ) : null
                }
              >
                {t('antecedentes-pnp')}
              </Button>
            </div>
        )}
        {secciones.length > 0
          && secciones.find((valor) => valor.idSeccion >= 31 && valor.idSeccion <= 32) && (
            <div className="pr-4">
              <Button
                variant="outlined"
                color="primary"
                className={activeSubStep === 'familiares' ? classes.selected : classes.buttonColor}
                onClick={() => setActiveSubStep('familiares')}
              >
                {t('antecedentes-familiares')}
              </Button>
            </div>
        )}
        {secciones.length > 0
          && secciones.find((valor) => valor.idSeccion >= 31 && valor.idSeccion <= 32) && (
            <div className="pr-4">
              <Button
                variant="outlined"
                color="primary"
                className={activeSubStep === 'laborales' ? classes.selected : classes.buttonColor}
                onClick={() => setActiveSubStep('laborales')}
              >
                {t('antecedentes_laborales')}
              </Button>
            </div>
        )}
      </div>
      {activeSubStep === 'personales-patologicos'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion >= 14 && valor.idSeccion <= 22) && (
          <PersonalesPatologicos
            setActiveSubStep={setActiveSubStep}
            enfReferidas={enfReferidas}
            setEnfReferidas={setEnfReferidas}
            catDiagnostico={catDiagnostico}
            setCatDiagnostico={setCatDiagnostico}
            enfNegadas={enfNegadas}
            setEnfNegadas={setEnfNegadas}
            grupoEnfNegadas={grupoEnfNegadas}
            setGrupoEnfNegadas={setGrupoEnfNegadas}
            diagnosticosGenerales={diagnosticosGenerales}
            setDiagnosticosGenerales={setDiagnosticosGenerales}
            tratamientos={tratamientos}
            setTratamientos={setTratamientos}
            sinHospitalizaciones={sinHospitalizaciones}
            setSinHospitalizaciones={setSinHospitalizaciones}
            hospitalizaciones={hospitalizaciones}
            setHospitalizaciones={setHospitalizaciones}
            procDiagnosticos={procDiagnosticos}
            setProcDiagnosticos={setProcDiagnosticos}
            procTerapeuticos={procTerapeuticos}
            setProcTerapeuticos={setProcTerapeuticos}
            sinProcQuirurgicos={sinProcQuirurgicos}
            setSinProcQuirurgicos={setSinProcQuirurgicos}
            procQuirurgicos={procQuirurgicos}
            setProcQuirurgicos={setProcQuirurgicos}
            sinAlergias={sinAlergias}
            setSinAlergias={setSinAlergias}
            alergias={alergias}
            setAlergias={setAlergias}
            sinTransfusiones={sinTransfusiones}
            setSinTransfusiones={setSinTransfusiones}
            transfusiones={transfusiones}
            setTransfusiones={setTransfusiones}
          />
      )}
      {activeSubStep === 'personales-no-patologicos'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion >= 23 && valor.idSeccion <= 30) && (
          <PersonalesNoPatologicos
            setActiveStep={setActiveStep}
            enGestacion={enGestacion}
            setEnGestacion={setEnGestacion}
            vivienda={vivienda}
            setVivienda={setVivienda}
            higiene={higiene}
            setHigiene={setHigiene}
            alimentacion={alimentacion}
            setAlimentacion={setAlimentacion}
            actividadFisica={actividadFisica}
            setActividadFisica={setActividadFisica}
            inmunizaciones={inmunizaciones}
            setInmunizaciones={setInmunizaciones}
            toxicomania={toxicomania}
            setToxicomania={setToxicomania}
            ginecoObstetrico={ginecoObstetrico}
            setGinecoObstetrico={setGinecoObstetrico}
            historiaSexual={historiaSexual}
            setHistoriaSexual={setHistoriaSexual}
            cambiosVivienda={cambiosVivienda}
            cambiosHigiene={cambiosHigiene}
            cambiosAlimentacion={cambiosAlimentacion}
            cambiosActividadFisica={cambiosActividadFisica}
            cambiosToxicomania={cambiosToxicomania}
            cambiosGinecoObstetrico={cambiosGinecoObstetrico}
            viviendaUsuario={viviendaUsuario}
            setViviendaUsuario={setViviendaUsuario}
            higieneUsuario={higieneUsuario}
            setHigieneUsuario={setHigieneUsuario}
            alimentacionUsuario={alimentacionUsuario}
            setAlimentacionUsuario={setAlimentacionUsuario}
            actividadFisicaUsuario={actividadFisicaUsuario}
            setActividadFisicaUsuario={setActividadFisicaUsuario}
            toxicomaniaUsuario={toxicomaniaUsuario}
            setToxicomaniaUsuario={setToxicomaniaUsuario}
            enGestacionUsuario={enGestacionUsuario}
            setEnGestacionUsuario={setEnGestacionUsuario}
            ginecoObstetricoUsuario={ginecoObstetricoUsuario}
            setGinecoObstetricoUsuario={setGinecoObstetricoUsuario}
          />
      )}
      {activeSubStep === 'familiares'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion >= 31 && valor.idSeccion <= 32) && (
          <Familiares familiares={familiares} setFamiliares={setFamiliares} />
      )}
      {activeSubStep === 'laborales'
        && secciones.length > 0
        && secciones.find((valor) => valor.idSeccion >= 31 && valor.idSeccion <= 32) && (
          <Laborales
            objLaboral={objLaboral}
            setObjLaboral={setObjLaboral}
            antecedentesLaborales={antecedentesLaborales}
            setAntecedentesLaborales={setAntecedentesLaborales}
          />
      )}
    </>
  );
}

export default Antecedentes;
