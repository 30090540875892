import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  buttonRoot: {
    minWidth: 'auto',
    backgroundColor: '#3B82F6',
    color: 'white',
    borderRadius: '5px 5px 0 0',
    borderLeft: '1px solid white',
    borderRight: '1px solid white',
    '&:hover': {
      backgroundColor: '#2563EB',
    },
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  buttonSelected: {
    minWidth: 'auto',
    backgroundColor: '#1E40AF',
    color: 'white',
    borderRadius: '5px 5px 0 0',
    borderLeft: '1px solid #DDDDDD',
    borderRight: '1px solid #DDDDDD',
    borderTop: '1px solid #DDDDDD',
    '&:hover': {
      backgroundColor: '#2563EB',
    },
    paddingLeft: '16px',
    paddingRight: '16px',
  },
});

export default useStyles;
