import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ViewTxt from '../../../../../../components/Comentarios/view2';
import { RootState } from '../../../../../../store';
import { calculateEdad } from '../../../../../../common/functions';
import { IPrintProps } from './types';
import Footer from './Footer';
import Header from './Header';

const PrintStyle = React.forwardRef(
  (
    {
      diagnosticoArray,
      procedimientosArray,
      datosClinicos,
      txtResumen,
      labelPdf,
      arrayDiagnostico,
    }: IPrintProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const { t } = useTranslation();
    const {
      dia, mes, anio, nombre, sexo,
    } = useSelector(
      (state: RootState) => state.BasicosPaciente,
    );
    const {
      idMedico,
      nombre: nombreMedico,
      primerApellido,
      segundoApellido,
      sexo: sexoMedico,
      enOrganizacion,
    } = useSelector((state: RootState) => state.Me);
    const { consultorio } = useSelector((state: RootState) => state.Consultorios);
    const edad = dia && mes && anio ? calculateEdad(anio, mes, dia) : null;
    return (
      <div ref={ref}>
        <div className="m-6">
          <Header
            idMedico={idMedico}
            consultorio={consultorio}
            nombre={nombre}
            edad={edad}
            sexo={sexo}
            enOrganizacion={enOrganizacion}
            labelPdf={labelPdf}
          />
          <div>
            <h4 className="m-0">{`${t('d-p-plan-pro-dia-pac')}: `}</h4>
            {diagnosticoArray.map((x, inx) => (
              // eslint-disable-next-line react/no-array-index-key
              <h4 className="m-0 font-normal" key={`print-${inx}`}>
                {`- ${arrayDiagnostico
                  .find((element) => element.id === parseInt(x, 10))
                  ?.label.toUpperCase()}`}
              </h4>
            ))}
          </div>
          <div>
            <h4 className="m-0">{`${t('d-p-plan-pro-pro-sol')}: `}</h4>
            {procedimientosArray.map((objX, inx) => {
              let showTxt: string = '';
              if (objX.selector === 'cie9') {
                showTxt = objX.cie9.label.toUpperCase();
              } else {
                showTxt = objX.txtLibre.toUpperCase();
              }
              // eslint-disable-next-line react/no-array-index-key
              return <h4 className="m-0 font-normal" key={`print-${inx}`}>{`- ${showTxt}`}</h4>;
            })}
          </div>
          <h2 className="text-blue-500 font-medium m-0 text-center py-4">
            {t('d-p-plan-pro-sol-datos').toUpperCase()}
          </h2>
          <div className="p-2 border-solid border border-gray-600">
            <p className="font-normal text-justify">{txtResumen}</p>
            <div>
              <ViewTxt
                comentarios={datosClinicos}
                key={Math.floor(Math.random() * (100 - 1)) + 1}
              />
            </div>
          </div>
        </div>
        <Footer
          consultorio={consultorio}
          sexoMedico={sexoMedico}
          nombreMedico={nombreMedico}
          primerApellido={primerApellido}
          segundoApellido={segundoApellido}
        />
      </div>
    );
  },
);

export default PrintStyle;
