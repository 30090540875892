import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IAnimalesProps } from './types';
import { ICatalogosEstandar } from '../../../../../../common/types';

function MascotasComponente({
  hayCambios,
  eliminarMascota,
  viviendaTemp,
  setViviendaTemp,
  catAnimales,
  index,
  idAnimal,
  cantidad,
  especifica,
}: IAnimalesProps) {
  const { t } = useTranslation();
  const handleNumbers = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = (e.target.value as string).match(/\D/g) ? '0' : (e.target.value as string);
    const valUpdate = [...viviendaTemp.tipo];
    valUpdate[index].cantidad = val;
    setViviendaTemp({
      ...viviendaTemp,
      tipo: valUpdate,
    });
  };
  const handleChangeAnimal = (event: any, newValue: ICatalogosEstandar | null) => {
    const valUpdate = [...viviendaTemp.tipo];
    if (newValue) {
      if (newValue.id !== 28) {
        valUpdate[index].especifica = '';
        valUpdate[index].idAnimal = newValue;
      } else {
        valUpdate[index].idAnimal = newValue;
      }
    } else {
      valUpdate[index].especifica = '';
      valUpdate[index].idAnimal = { id: 0, label: '' };
    }
    setViviendaTemp({
      ...viviendaTemp,
      tipo: valUpdate,
    });
  };
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 flex">
      <div className="grid gap-4 grid-cols-3 xl:grid-cols-4 w-full">
        <div>
          <h3 className="text-gray-600 font-medium mb-2">{t('antecedentes-pnp-vivienda-tipo')}</h3>
          <Autocomplete
            options={catAnimales}
            getOptionLabel={(option) => option.label}
            noOptionsText={t('sin_resultados')}
            value={idAnimal}
            onChange={(e, nV) => handleChangeAnimal(e, nV)}
            fullWidth
            disabled={hayCambios}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        </div>
        {idAnimal && idAnimal.id && idAnimal.id === 28 ? (
          <div>
            <h3 className="text-gray-600 font-medium mb-2">{t('especifica')}</h3>
            <TextField
              value={especifica}
              onChange={(event) => {
                if (event.target.value.length !== 0) {
                  const valUpdate = [...viviendaTemp.tipo];
                  valUpdate[index].especifica = event.target.value;
                  setViviendaTemp({
                    ...viviendaTemp,
                    tipo: valUpdate,
                  });
                }
              }}
              variant="outlined"
              fullWidth
              disabled={hayCambios}
            />
          </div>
        ) : null}
        <div>
          <h3 className="text-gray-600 font-medium mb-2">{t('cantidad')}</h3>
          <TextField
            value={cantidad}
            onChange={(event) => handleNumbers(event)}
            variant="outlined"
            fullWidth
            disabled={hayCambios}
          />
        </div>
      </div>
      <IconButton aria-label="delete" onClick={eliminarMascota} disabled={hayCambios}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
}

export default MascotasComponente;
