import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  AppBar, Toolbar, IconButton, Avatar, CssBaseline, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PersonIcon from '@material-ui/icons/Person';
import { Settings } from '@material-ui/icons';
import MenuDoctor from './MenuDoctor';
import MenuOrganizacion from './MenuOrganizacion';
import useStyles from './styles';
import AdmonSideBar from '../AdmonSideBar/index';
import { RootState } from '../../store';
import { sendLoguot } from '../../utils/sendInfo';
import loading from '../../actions/loading/actions';
import {
  getToken,
  getUser,
  removeUserSession,
  getOrganizacionSS,
  setOrganizacionSS,
} from '../../utils/commonStore';
import { resetMe } from '../../actions/me/actions';
import { ReactComponent as LogoIcon } from '../../assets/logo/Logotipo_Color.svg';
import { setSnackComplete } from '../../actions/snackbar/types';
import {
  getOrganizacion,
  getOrganizaciones,
  resetOrganizacion,
  resetOrganizaciones,
} from '../../actions/organizaciones/actions';
import { limpiarRedux } from '../../utils/reduxCommon';
import { setAuth } from '../../actions/auth/actions';

function AdmonNavbar() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  /** state del componente */
  const [doctorEl, setDoctorEl] = useState<null | HTMLButtonElement>(null);
  const [organizacionEl, setOrganizacionEl] = useState<null | HTMLElement>(null);
  // const [avatarURL] = useState(
  //   'https://media-exp3.licdn.com/dms/image/C5603AQG_u5SDFqU_Gw/profile-displayphoto-shrink_200_200/0/1612906027923?e=1631750400&v=beta&t=z2J3I4Qu-HMMvnHvSdPwSe3H9WfBujmOaL4WXHPDZnw',
  // );
  const [doctorName, setDoctorName] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const openDoctorMenu = Boolean(doctorEl);
  const openOrganizacionMenu = Boolean(organizacionEl);
  const {
    nombre, primerApellido, segundoApellido, idMedico,
  } = useSelector(
    (state: RootState) => state.Me,
  );
  const { organizacion, organizaciones } = useSelector((state: RootState) => state.Organizaciones);
  useEffect(() => {
    setDoctorName(
      `${nombre ? nombre[0] : ''}${primerApellido ? primerApellido[0] : ''}${
        segundoApellido ? segundoApellido[0] : ''
      }`,
    );
  }, [nombre, primerApellido, segundoApellido]);

  useEffect(() => {
    if (idMedico > 0) {
      dispatch(getOrganizaciones(idMedico));
    }
  }, [idMedico]);

  // OJO AQUI ES EL CAMBIO DE ORGANIZACION
  useEffect(() => {
    if (organizaciones.length && getOrganizacionSS()) {
      dispatch(getOrganizacion(idMedico, getOrganizacionSS()));
    } else if (organizaciones.length) {
      setOrganizacionSS(organizaciones[0].id);
      dispatch(getOrganizacion(idMedico, organizaciones[0].id));
    }
  }, [organizaciones]);
  /** Handlers del componente */

  /** Abrir el menú doctor */
  const handleMenuDoctor = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDoctorEl(e.currentTarget);
  };

  /** Abrir el menú organizacion */
  const handleMenuOrganizacion = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOrganizacionEl(e.currentTarget);
  };

  /** Cerrar el menú del doctor */
  const handleCloseDoctor = () => {
    setDoctorEl(null);
  };

  /** Cerrar el menu organizacion */
  const handleCloseOrganizacion = () => {
    setOrganizacionEl(null);
  };

  /** Abrir el Sidebar */
  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };

  /** Cerrar el Sidebar */
  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  const cerrarSesion = () => {
    dispatch(loading(true));
    const obj = { token: getToken(), usuario: getUser() };
    sendLoguot(obj)
      .then((result) => result.json())
      .then((data) => {
        if (data.code === 200) {
          removeUserSession();
          dispatch(resetMe());
          dispatch(resetOrganizaciones());
          dispatch(resetOrganizacion());
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
              keyBack: '',
            }),
          );
          limpiarRedux();
          history.push('/login');
        }
        dispatch(loading(false));
      })
      .catch((err) => {
        if (err.toString() === 'SyntaxError: Unexpected token E in JSON at position 0') {
          removeUserSession();
          dispatch(resetMe());
          dispatch(resetOrganizaciones());
          dispatch(resetOrganizacion());
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
              keyBack: '',
            }),
          );
          limpiarRedux();
          history.push('/login');
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        }
        dispatch(loading(false));
      });
  };

  /** Manejar el eventoLogout */
  const handleLogout = () => cerrarSesion();

  /** Manejar el evento cambiar organizacion */
  const handleChangeOrganizacion = (idOrganizacionCambio: number) => {
    if (idOrganizacionCambio !== organizacion.idOrganizacion) {
      setOrganizacionSS(idOrganizacionCambio);
      dispatch(getOrganizacion(idMedico, idOrganizacionCambio));
      limpiarRedux();
      handleCloseOrganizacion();
    } else {
      handleCloseOrganizacion();
    }
  };
  /** Regresa el navbar y el sidebar */
  return (
    <>
      <CssBaseline />
      <div className="text-white bg-white z-30">
        <AppBar
          position="fixed"
          color="inherit"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: sidebarOpen,
          })}
        >
          <Toolbar>
            <div className="text-gray-900">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="expandir menu"
                onClick={handleSidebarOpen}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div className="text-gray-900 w-100 flex-grow">
              <LogoIcon height="55" />
            </div>
            {organizaciones.length > 1 && (
              <>
                <Button
                  color="default"
                  startIcon={<Settings />}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handleMenuOrganizacion}
                  disableElevation
                >
                  {organizacion.nombreOrganizacion || organizacion.razonSocial}
                </Button>
                <MenuOrganizacion
                  anchorEl={organizacionEl}
                  handleClose={handleCloseOrganizacion}
                  open={openOrganizacionMenu}
                  handleCambiarOrganizacion={handleChangeOrganizacion}
                />
              </>
            )}
            {/* <Button color="default" startIcon={<HelpOutlineIcon />} disableElevation>
              Ayuda
            </Button> */}
            <Button
              color="default"
              startIcon={<PersonIcon />}
              endIcon={(
                <Avatar variant="rounded" style={{ backgroundColor: '#1D4ED8' }} alt={doctorName}>
                  {doctorName}
                </Avatar>
              )}
              onClick={handleMenuDoctor}
              disableElevation
            >
              {`${nombre} ${primerApellido} ${segundoApellido}`}
            </Button>
            <MenuDoctor
              anchorEl={doctorEl}
              handleClose={handleCloseDoctor}
              open={openDoctorMenu}
              handleLogout={handleLogout}
            />
          </Toolbar>
        </AppBar>
      </div>
      <AdmonSideBar sidebarOpen={sidebarOpen} handleSidebarClose={handleSidebarClose} />
    </>
  );
}

export default AdmonNavbar;
