import { getToken } from './commonStore';
import { ArchivosURLGET } from './commonUrl';

function getFile(url: string) {
  return fetch(url, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
}

export function obtenerURLArchivo(
  idMedico: number | null,
  idPaciente: number | null,
  idArchivo: number | null,
  nombreArchivo: string | null,
  pathArchivo: string,
  registro: string,
  // 'transfusiones'
) {
  return getFile(
    ArchivosURLGET(idMedico, idPaciente, registro, idArchivo, nombreArchivo, pathArchivo),
  );
}

export function dummyEvitaError() {
  return true;
}
