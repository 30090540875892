import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
  RadioGroup,
  ButtonGroup,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from './styles';
import { RootState } from '../../../../../store';
import AlimentosComponente from './Alimentos/index';
import ResumenCambios from '../../../../../components/ResumenCambios';
import BotonesGuardar from '../../../../../components/BotonesGuardar';
import {
  antecedentesNoPatologicosGuardarAlimentacion,
  antecedentesNoPatologicosUsuarioPacienteGuardarAlimentacion,
  antecedentesNoPatologicosUsuarioGuardarAlimentacion,
} from '../../../../../utils/sendInfo';
import { IAlimentacion, IAlimentacionProps, alimentacionInitial } from './types';
import { setRequest } from '../../../../../actions/request/types';

function Alimentacion(props: IAlimentacionProps) {
  const {
    setActiveSubStep,
    alimentacion,
    setAlimentacion,
    cambiosUsuario,
    alimentacionUsuario,
    setAlimentacionUsuario,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const [alimentacionTemp, setAlimentacionTemp] = useState<IAlimentacion>(alimentacionInitial);

  const hayCambios: boolean = idUsuario > 0 && cambiosUsuario.filter((c: string) => c.length > 0).length > 0;
  const frecuencia: {
    tipo: 'comidas-f' | 'carne' | 'pollo' | 'pescado' | 'refrescos' | 'postres';
    veces: number | null;
  }[] = [
    { tipo: 'comidas-f', veces: alimentacionTemp.comidas },
    { tipo: 'carne', veces: alimentacionTemp.carne },
    { tipo: 'pollo', veces: alimentacionTemp.pollo },
    { tipo: 'pescado', veces: alimentacionTemp.pescado },
    { tipo: 'refrescos', veces: alimentacionTemp.refrescos },
    { tipo: 'postres', veces: alimentacionTemp.postres },
  ];

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dieta = (event.target as HTMLInputElement).value;
    if (dieta === 'F') {
      setAlimentacionTemp({
        ...alimentacionTemp,
        dieta,
        alimentos: [
          {
            alimento: '',
            especifica: '',
          },
        ],
      });
    } else {
      setAlimentacionTemp({
        ...alimentacionTemp,
        dieta,
      });
    }
  };
  const handleChangeNumbers = (e: React.ChangeEvent<{ value: unknown }>, sel: string) => {
    const val = (e.target.value as string).match(/\D/g) ? '0' : (e.target.value as string);
    if (val.length <= 2) {
      const value = val ? parseInt(val, 10) : null;
      const newAlimentacion = { ...alimentacionTemp };
      switch (sel) {
        case 'comidas-f':
          newAlimentacion.comidas = value;
          break;
        case 'carne':
          newAlimentacion.carne = value;
          break;
        case 'pollo':
          newAlimentacion.pollo = value;
          break;
        case 'pescado':
          newAlimentacion.pescado = value;
          break;
        case 'refrescos':
          newAlimentacion.refrescos = value;
          break;
        case 'postres':
          newAlimentacion.postres = value;
          break;
        default:
          break;
      }
      setAlimentacionTemp(newAlimentacion);
    }
  };
  const addAlimentos = () => {
    setAlimentacionTemp({
      ...alimentacionTemp,
      alimentos: [
        ...alimentacionTemp.alimentos,
        {
          alimento: '',
          especifica: '',
        },
      ],
    });
  };
  const handleChangeAlimentos = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number,
    sel: string,
  ) => {
    const val = event.target.value as string;
    const valUpdate = [...alimentacionTemp.alimentos];
    if (sel === 'alimento') {
      valUpdate[index].alimento = val;
    } else if (sel === 'especifica') {
      valUpdate[index].especifica = val;
    }
    setAlimentacionTemp({
      ...alimentacionTemp,
      alimentos: valUpdate,
    });
  };
  const eliminarAlimentos = (index: number) => {
    if (alimentacionTemp.alimentos.length === 1) {
      setAlimentacionTemp({
        ...alimentacionTemp,
        alimentos: [
          {
            alimento: '',
            especifica: '',
          },
        ],
      });
    } else {
      const newAlimento = [...alimentacionTemp.alimentos];
      newAlimento.splice(index, 1);
      setAlimentacionTemp({
        ...alimentacionTemp,
        alimentos: newAlimento,
      });
    }
  };
  const handleChangeBotonComidas = (val: number) => {
    setAlimentacionTemp({
      ...alimentacionTemp,
      botonComidas: val,
    });
  };
  const handleChangeBotonFruta = (val: number) => {
    setAlimentacionTemp({
      ...alimentacionTemp,
      botonFruta: val,
    });
  };
  const handleChangeBotonVegetales = (val: number) => {
    setAlimentacionTemp({
      ...alimentacionTemp,
      botonVegetales: val,
    });
  };

  const getDatosAlimentacion = (datos: IAlimentacion) => ({
    dieta: datos.dieta,
    alimentos: datos.alimentos,
    botonComidas: datos.botonComidas,
    botonFruta: datos.botonFruta,
    botonVegetales: datos.botonVegetales,
    comidas: datos.comidas,
    carne: datos.carne,
    pollo: datos.pollo,
    pescado: datos.pescado,
    refrescos: datos.refrescos,
    postres: datos.postres,
    comentarios: datos.comentarios,
  });
  const getDatosExpediente = (datos: IAlimentacion) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    alimentacion: getDatosAlimentacion(datos),
  });
  const getDatosUsuario = (datos: IAlimentacion) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    alimentacion: getDatosAlimentacion(datos),
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(alimentacionTemp);
    const sendFunctions = [antecedentesNoPatologicosGuardarAlimentacion(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarAlimentacion(datosToSend));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => setAlimentacion({ ...alimentacionTemp, loaded: true }),
          () => setAlimentacionUsuario({ ...alimentacionTemp, loaded: true }),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarAlimentacion(
          getDatosUsuario(alimentacionTemp),
        ),
        successFunction: () => {
          setAlimentacion({ ...alimentacionTemp, loaded: true });
          setActiveSubStep('actividad-fisica');
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarAlimentacion(
          getDatosExpediente(alimentacionUsuario),
        ),
        successFunction: () => {
          setAlimentacion(alimentacionUsuario);
          setAlimentacionTemp(alimentacionUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarAlimentacion(
          getDatosExpediente(alimentacion),
        ),
        successFunction: () => setAlimentacionUsuario(alimentacion),
      }),
    );
  };

  useEffect(() => {
    if (alimentacion.loaded && !alimentacionTemp.loaded) {
      setAlimentacionTemp(alimentacion);
    }
  }, [alimentacion]);

  return (
    <div className="relative py-4">
      {hayCambios && <ResumenCambios cambios={cambiosUsuario} />}
      <div className="grid grid-cols-6 pb-4">
        <div className="justify-self-start place-self-center">
          <h2 className="text-blue-800 font-normal m-0">{t('antecedentes-pnp-alimentacion')}</h2>
        </div>
        <div className="border-solid border-0 border-l border-gray-600 pl-12">
          <FormControl>
            <FormLabel component="legend" className={classes.boldText}>
              {t('antecedentes-pnp-alimentacion-restriccion')}
            </FormLabel>
            <RadioGroup
              aria-label="dieta"
              name="dieta"
              value={alimentacionTemp.dieta}
              onChange={handleChangeRadio}
              row
            >
              <FormControlLabel
                value="T"
                control={<Radio color="primary" />}
                label={t('si')}
                disabled={hayCambios}
              />
              <FormControlLabel
                value="F"
                control={<Radio color="primary" />}
                label={t('no')}
                disabled={hayCambios}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="border-solid border-0 border-b border-gray-600 pb-6">
        {alimentacionTemp.dieta === 'T' ? (
          <>
            {alimentacionTemp.alimentos.map((val, index) => (
              <AlimentosComponente
                {...val}
                index={index}
                eliminar={eliminarAlimentos}
                handleChange={handleChangeAlimentos}
                hayCambios={hayCambios}
              />
            ))}
            <div className="flex justify-end mb-4">
              <Button
                color="primary"
                size="small"
                onClick={addAlimentos}
                className={classes.noTextTranform}
                startIcon={<AddCircleOutlineIcon />}
                disabled={hayCambios}
              >
                {t('antecedentes-pnp-alimentacion-aalimento')}
              </Button>
            </div>
          </>
        ) : null}
      </div>
      <div className="grid gap-6 lg:grid-cols-2 xl:grid-cols-3 w-full pt-6">
        <div className="py-4 place-self-center">
          <FormControl>
            <FormLabel component="legend" className={classes.titleButton}>
              {t('antecedentes-pnp-alimentacion-comidas-d')}
            </FormLabel>
            <ButtonGroup
              size="large"
              color="primary"
              aria-label="large outlined primary button group"
            >
              {[...Array(7)].map((x, i) => (
                <Button
                  className={
                    alimentacionTemp.botonComidas === i + 1 ? classes.selected : classes.button
                  }
                  onClick={() => handleChangeBotonComidas(i + 1)}
                  disabled={hayCambios}
                >
                  {t(`antecedentes-pnp-alimentacion-${i + 1}`)}
                </Button>
              ))}
            </ButtonGroup>
          </FormControl>
        </div>
        <div className="py-4 place-self-center">
          <FormControl>
            <FormLabel component="legend" className={classes.titleButton}>
              {t('antecedentes-pnp-alimentacion-fruta')}
            </FormLabel>
            <ButtonGroup
              size="large"
              color="primary"
              aria-label="large outlined primary button group"
            >
              {[...Array(5)].map((x, i) => (
                <Button
                  className={
                    alimentacionTemp.botonFruta === i + 1 ? classes.selected : classes.button
                  }
                  onClick={() => handleChangeBotonFruta(i + 1)}
                  disabled={hayCambios}
                >
                  {i + 1 === 1 || i + 1 === 5
                    ? t(`antecedentes-pnp-alimentacion-m${i + 1}`)
                    : t(`antecedentes-pnp-alimentacion-${i + 1}`)}
                </Button>
              ))}
            </ButtonGroup>
          </FormControl>
        </div>
        <div className="py-4 place-self-center">
          <FormControl>
            <FormLabel component="legend" className={classes.titleButton}>
              {t('antecedentes-pnp-alimentacion-vegetales')}
            </FormLabel>
            <ButtonGroup
              size="large"
              color="primary"
              aria-label="large outlined primary button group"
            >
              {[...Array(5)].map((x, i) => (
                <Button
                  className={
                    alimentacionTemp.botonVegetales === i + 1 ? classes.selected : classes.button
                  }
                  onClick={() => handleChangeBotonVegetales(i + 1)}
                  disabled={hayCambios}
                >
                  {i + 1 === 1 || i + 1 === 5
                    ? t(`antecedentes-pnp-alimentacion-m${i + 1}`)
                    : t(`antecedentes-pnp-alimentacion-${i + 1}`)}
                </Button>
              ))}
            </ButtonGroup>
          </FormControl>
        </div>
      </div>
      <div className="py-4">
        <div className="text-left pb-6">
          <h2 className="text-blue-800 font-normal m-0">
            {t('antecedentes-pnp-alimentacion-recurrencia')}
          </h2>
        </div>
        <div className="grid lg:grid-cols-3 xl:grid-cols-6 gap-4">
          {frecuencia.map((consumo) => (
            <div className="grid grid-cols-2">
              <div className="text-left pb-3 col-span-2">
                <h3 className="text-gray-600 font-medium m-0">
                  {t(`antecedentes-pnp-alimentacion-${consumo.tipo}`)}
                </h3>
              </div>
              <TextField
                value={consumo.veces}
                onChange={(event) => handleChangeNumbers(event, consumo.tipo)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t('antecedentes-pnp-alimentacion-veces')}
                    </InputAdornment>
                  ),
                }}
                disabled={hayCambios}
                fullWidth
              />
            </div>
          ))}
        </div>
      </div>
      <TextField
        label={t('comentarios')}
        variant="outlined"
        fullWidth
        value={alimentacionTemp.comentarios}
        onChange={(event) => setAlimentacionTemp({
          ...alimentacionTemp,
          comentarios: event.target.value,
        })}
        inputProps={{ maxLength: 500, autoComplete: 'off' }}
        multiline
        rows="4"
        disabled={hayCambios}
      />
      <BotonesGuardar
        hayCambios={hayCambios}
        aceptarCallback={guardarDatosDeUsuarioAExpediente}
        cancelarCallback={guardarDatosDeExpedienteAUsuario}
        guardarCallback={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
        continuar={esPaciente}
      />
    </div>
  );
}

export default Alimentacion;
