import { Dispatch, SetStateAction } from 'react';

export interface IInfoEmergencia {
  loaded: boolean;
  desconoceGSanguineo: boolean;
  tipoSanguineo: string;
  factorSanguineo: string;
  nombreContacto: string;
  apellidosContacto: string;
  parentescoContacto: string;
  especifiqueParentescoContacto: string;
  emailContacto: string;
  ladaTel: string;
  telefonoContacto: string;
  tipoTelefonoContacto: string;
}

export const infoEmergenciaInitial: IInfoEmergencia = {
  loaded: false,
  desconoceGSanguineo: false,
  tipoSanguineo: '',
  factorSanguineo: '',
  nombreContacto: '',
  apellidosContacto: '',
  parentescoContacto: '',
  especifiqueParentescoContacto: '',
  emailContacto: '',
  ladaTel: '',
  telefonoContacto: '',
  tipoTelefonoContacto: '',
};

export interface IInfoEmergenciaProps {
  setActiveSubStep: Dispatch<
  SetStateAction<
  | 'datos-personales'
  | 'datos-ubicacion'
  | 'discapacidades'
  | 'info-emergencia'
  | 'seguros-planes'
  >
  >;
  infoEmergencia: IInfoEmergencia;
  setInfoEmergencia: Dispatch<SetStateAction<IInfoEmergencia>>;
  cambiosUsuario: string[];
  infoEmergenciaUsuario: IInfoEmergencia;
  setInfoEmergenciaUsuario: Dispatch<SetStateAction<IInfoEmergencia>>;
}
