import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertDialogProps from './types';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function SeleccionarTipoPaciente(props: IAlertDialogProps) {
  const {
    open, esExterno, tipoPaciente, setTipoPaciente, callBackAceptar, callBackClose,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="seleccionar-tipo-paciente-titulo"
      aria-describedby="seleccionar-tipo-paciente-descripcion"
    >
      <div className="flex justify-between items-center bg-blue-500 text-white pl-4">
        <span className="text-lg">{t('tipo_paciente_titulo')}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <DialogContentText>
          <FormControl component="fieldset">
            <h3 className="font-normal text-gray-600 pr-32">{`${t('tipo_paciente_pregunta')}:`}</h3>
            <RadioGroup
              value={tipoPaciente}
              onChange={(e) => setTipoPaciente(e.target.value as 'directo' | 'candidato' | 'contratista' | 'otro')}
              aria-label="tipo-de-paciente"
              name="tipo-paciente"
            >
              {!esExterno && (
                <FormControlLabel
                  value="directo"
                  control={<Radio color="primary" />}
                  label={t('tipo_paciente_directo')}
                />
              )}
              <FormControlLabel
                value="candidato"
                control={<Radio color="primary" />}
                label={t(esExterno ? 'tipo_paciente_candidato' : 'tipo_paciente_externo')}
              />
              <FormControlLabel
                value="contratista"
                control={<Radio color="primary" />}
                label={t('tipo_paciente_contratista')}
              />
              <FormControlLabel
                value="otro"
                control={<Radio color="primary" />}
                label={t('tipo_paciente_otro')}
              />
            </RadioGroup>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex justify-center mb-2">
          <div className="mr-4">
            <Button onClick={callBackClose} color="primary" variant="outlined">
              {t('cancelar')}
            </Button>
          </div>
          <div>
            <Button
              onClick={() => callBackAceptar()}
              color="primary"
              variant="contained"
              disabled={!tipoPaciente}
            >
              {t('aceptar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default SeleccionarTipoPaciente;
