import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Avatar,
  TextField,
  Button,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { RootState } from '../../../store';
import { setLoading } from '../../../actions/loading/actions';
import { setSnackComplete } from '../../../actions/snackbar/types';
import useStyles from './styles';
import {
  getCatalogoAgee,
  getCatalogoAgem,
  getCatalogoAgel,
  getCatalogoAgea,
  getCatalogoCfdi,
} from '../../../utils/getCatalogos';
import obtenerDatosGeograficos from '../../../utils/geografia';
import { obtenerURLArchivo } from '../../../utils/getFiles';
import { crearArchivo } from '../../../utils/sendFiles';
import { HtmlTooltip } from '../../../utils/ComponentesJSX';
import {
  rfcLongitudMax,
  validacionSoloNumeros,
  validacionEmail,
  validacionRfc,
} from '../../../constants/validaciones';
import {
  ICatalogoAGEE,
  ICatalogoAGEM,
  ICatalogoAGEL,
  ICatalogoAGEA,
} from '../../TabsPaciente/PerfilPaciente/DatosDeUbicacion/types';
import { ICatalogosEstandar } from '../../../common/types';
import { IOrganizacionProps, ICatalogoCfdi } from './types';
import { IDatosOrganizacion } from '../../AdmonInformacion/types';
import { getOrganizacion } from '../../../actions/organizaciones/actions';

function Organizacion(props: IOrganizacionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    objOrganizacion, setObjOrganizacion, setDisabledSave, setDisabledNext,
  } = props;
  const { secciones, idMedico, pais } = useSelector((state: RootState) => state.Me);
  const { organizacion } = useSelector((state: RootState) => state.Organizaciones);
  const [catAGEE, setCatAGEE] = useState<ICatalogoAGEE[]>([]);
  const [catAGEM, setCatAGEM] = useState<ICatalogoAGEM[]>([]);
  const [catAGEL, setCatAGEL] = useState<ICatalogoAGEL[]>([]);
  const [catAGEA, setCatAGEA] = useState<ICatalogoAGEA[]>([]);
  const [catCfdi, setCatCfdi] = useState<ICatalogoCfdi[]>([]);
  const [filterCatCfdi, setFilterCatCfdi] = useState<ICatalogoCfdi[]>([]);
  const [errores, setErrores] = useState({
    email: { error: false, helperText: '' },
    rfc: { error: false, helperText: '' },
  });
  /* archivo */
  const [selObjArchivo, setSelObjArchivo] = useState<{ archivo: File; idLista: number } | null>(
    null,
  );
  const [urlImage, setUrlImage] = useState<string>('');
  const geo = obtenerDatosGeograficos(pais);
  const handleChangeAgee = (event: any, newValue: ICatalogoAGEE | null) => {
    setObjOrganizacion({ ...objOrganizacion, agee: newValue || null });
    setCatAGEM([]);
    setCatAGEL([]);
    setCatAGEA([]);
  };
  const handleChangeAgem = (event: any, newValue: ICatalogoAGEM | null) => {
    setObjOrganizacion({ ...objOrganizacion, agem: newValue || null });
    setCatAGEL([]);
    setCatAGEA([]);
  };
  const handleChangeAgel = (event: any, newValue: ICatalogoAGEL | null) => {
    setObjOrganizacion({ ...objOrganizacion, agel: newValue || null });
    setCatAGEA([]);
  };
  const handleChangeAgea = (event: any, newValue: ICatalogoAGEA | null) => {
    setObjOrganizacion({ ...objOrganizacion, agea: newValue || null });
  };
  const handleChangePersonalJuridica = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target as HTMLInputElement;
    if (val.value === 'pm') {
      setFilterCatCfdi(catCfdi.filter((valCatCfdi) => valCatCfdi.personaMoral));
    } else {
      setFilterCatCfdi(catCfdi);
    }
    setObjOrganizacion({
      ...objOrganizacion,
      personalJuridica: val.value,
    });
  };
  const handleChangeNombre = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, nombreOrganizacion: val });
  };
  const handleChangeRazonSocial = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, razonSocial: val });
  };
  const handleChangeRfc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, rfc: val.toUpperCase() });
  };
  const handleBlurRfc = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (validacionRfc(val.toUpperCase(), pais)) {
      setErrores((preV) => ({ ...preV, rfc: { helperText: '', error: false } }));
    } else {
      setErrores((preV) => ({
        ...preV,
        rfc: { helperText: t(`rfc_incorrecto_${pais}`), error: true },
      }));
    }
  };
  const handleChangeCfdi = (event: any, newValue: ICatalogosEstandar | null) => {
    setObjOrganizacion({
      ...objOrganizacion,
      cfdi: { id: newValue?.id || 0, label: newValue?.label || '' },
    });
  };
  const handleChangeCorreoElectronico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, email: val.toLowerCase() });
    setErrores((preV) => ({ ...preV, email: { helperText: '', error: false } }));
  };
  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setErrores((preV) => ({ ...preV, email: { helperText: '', error: false } }));
    } else {
      setErrores((preV) => ({
        ...preV,
        email: { helperText: t('email_incorrecto'), error: true },
      }));
    }
  };
  const handleChangeTelefono1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjOrganizacion({ ...objOrganizacion, telefono: val });
    }
  };
  const handleChangeExtension1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjOrganizacion({ ...objOrganizacion, extension: val });
    }
  };
  const handleChangeCalle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, calle: val });
  };
  const handleChangeNum1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, num1: val });
  };
  const handleChangeNum2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, num2: val });
  };
  const handleChangeCP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjOrganizacion({ ...objOrganizacion, cp: val });
    }
  };
  const seleccionarArchivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      setSelObjArchivo({
        archivo: event.target.files[0],
        idLista: objOrganizacion.idOrganizacion || 0,
      });
    } else {
      setSelObjArchivo(null);
    }
  };
  const verImagen = (idArchivo: number, pathArchivo: string) => {
    obtenerURLArchivo(idMedico, null, idArchivo, null, pathArchivo, 'imagenOrganizacion')
      .then((response) => response.json())
      .then((result) => {
        if (result.code !== 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `Ocurrio un error al obtener el archivo: ${result.error}`,
            }),
          );
        } else if (result.data.url) {
          setUrlImage(result.data.url);
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `Ocurrio un error al obtener el archivo: ${err}`,
          }),
        );
      });
  };
  const invalidOrg = (org: IDatosOrganizacion) => {
    if (
      errores.email.error
      || errores.rfc.error
      || org.personalJuridica === ''
      || (org.personalJuridica === 'pm' && org.nombreOrganizacion.length === 0)
      || org.razonSocial.length === 0
      || (geo.agee && geo.agee.required && !org.agee)
      || (geo.agem && geo.agem.required && !org.agem)
      || (geo.agel && geo.agel.required && !org.agel)
      || (geo.calle && geo.calle.required && org.calle.length === 0)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const catAgeeP = getCatalogoAgee(idMedico);
    const cfdiP = getCatalogoCfdi();
    Promise.all([catAgeeP, cfdiP])
      .then((responses) => {
        Promise.all([responses[0], responses[1]])
          .then((results) => {
            setCatAGEE(results[0] || []);
            setCatCfdi(results[1] || []);
            setFilterCatCfdi(results[1] || []);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
            dispatch(setLoading(false));
          });
      })
      .catch((err) => {
        if (err.toString() !== 'AbortError: The user aborted a request.') {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        }
        dispatch(setLoading(false));
      });
  }, []);
  useEffect(() => {
    setDisabledSave(invalidOrg(objOrganizacion));
    setDisabledNext(objOrganizacion.idOrganizacion === -1 || invalidOrg(objOrganizacion));
  }, [objOrganizacion]);
  useEffect(() => {
    if (objOrganizacion.agee?.cve_agee && catAGEM.length === 0) {
      dispatch(setLoading(true));
      setObjOrganizacion({
        ...objOrganizacion,
        agem: null,
        agel: null,
        agea: null,
      });
      getCatalogoAgem(objOrganizacion.agee?.cve_agee || '')
        .then((result: ICatalogoAGEM[]) => {
          setCatAGEM(result.map((mun) => ({ ...mun, nom_agem: mun.nom_agem })));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        });
    }
  }, [objOrganizacion.agee]);
  useEffect(() => {
    if (objOrganizacion.agem?.cve_agee && catAGEL.length === 0) {
      dispatch(setLoading(true));
      setObjOrganizacion({ ...objOrganizacion, agel: null, agea: null });
      getCatalogoAgel(objOrganizacion.agem?.cve_agee, objOrganizacion.agem?.cve_agem)
        .then((result: ICatalogoAGEL[]) => {
          setCatAGEL(result.map((loc) => ({ ...loc, nom_agel: loc.nom_agel })));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        });
    }
  }, [objOrganizacion.agem]);
  useEffect(() => {
    if (objOrganizacion.agel?.cve_agel && catAGEA.length === 0) {
      dispatch(setLoading(true));
      setObjOrganizacion({ ...objOrganizacion, agea: null });
      getCatalogoAgea(
        objOrganizacion.agel?.cve_agee,
        objOrganizacion.agel?.cve_agem,
        objOrganizacion.agel?.cve_agel,
      )
        .then((result: ICatalogoAGEA[]) => {
          setCatAGEA(result.map((col) => ({ ...col, nom_agea: col.nom_agea })));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        });
    }
  }, [objOrganizacion.agel]);
  useEffect(() => {
    setUrlImage('');
    if (organizacion.urlLogotipo && organizacion.urlLogotipo.length > 0) {
      verImagen(organizacion.idOrganizacion, organizacion.urlLogotipo);
    }
    if (organizacion.personalJuridica === 'pm') {
      setFilterCatCfdi(catCfdi.filter((valCatCfdi) => valCatCfdi.personaMoral));
    } else {
      setFilterCatCfdi(catCfdi);
    }
    const fetchCatAgem = organizacion.agee ? getCatalogoAgem(organizacion.agee.cve_agee) : [];
    const fetchCatAgel = organizacion.agem
      ? getCatalogoAgel(organizacion.agem.cve_agee, organizacion.agem.cve_agem)
      : [];
    const fetchCatAgea = organizacion.agel
      ? getCatalogoAgea(
        organizacion.agel.cve_agee,
        organizacion.agel.cve_agem,
        organizacion.agel.cve_agel,
      )
      : [];
    Promise.all([fetchCatAgem, fetchCatAgel, fetchCatAgea])
      .then((responses) => {
        Promise.all([responses[0], responses[1], responses[2]])
          .then((results) => {
            setCatAGEM(
              results[0].map((mun: ICatalogoAGEM) => ({
                ...mun,
                nom_agem: mun.nom_agem,
              })) || [],
            );
            setCatAGEL(
              results[1].map((loc: ICatalogoAGEL) => ({
                ...loc,
                nom_agel: loc.nom_agel,
              })) || [],
            );
            setCatAGEA(
              results[2].map((col: ICatalogoAGEA) => ({
                ...col,
                nom_agea: col.nom_agea,
              })) || [],
            );
            setObjOrganizacion(organizacion);
          })
          .catch((err) => {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
            setObjOrganizacion(organizacion);
          });
      })
      .catch((err) => {
        if (err.toString() !== 'AbortError: The user aborted a request.') {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
          setObjOrganizacion(organizacion);
        }
      });
  }, [organizacion]);
  useEffect(() => {
    if (selObjArchivo && selObjArchivo.archivo.size > 1048576) {
      setSelObjArchivo(null);
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('el_archivo_selecionado_pesa_mas_de_1_mega'),
        }),
      );
      return;
    }
    if (selObjArchivo) {
      dispatch(setLoading(true));
      // despues en un futuro debemos crear un XMLHttpRequest en vez de un fetch para obtener el progreso de la carga
      // ese progreso se dejará maqueteado para usarlo en un futuro, asi que los comentarios de dejan a proposito
      // obtenemos un objeto form data que nos servira para enviar nuestra peticion como si se hubiera generado en
      // un formulario
      const data = new FormData();
      // añadimos la informacion importante y OJO el archivo debe ir siempre al último o el back no parseara correctamente
      // los parametros que se le adjunta
      data.append('idLista', String(selObjArchivo.idLista));
      data.append('idMedico', String(idMedico));
      data.append('nombre', String(selObjArchivo.archivo?.name));
      data.append('tipo', selObjArchivo.archivo?.type);
      data.append('peso', String(selObjArchivo.archivo?.size));
      data.append('registro', 'imagenOrganizacion');
      data.append('file', selObjArchivo?.archivo);
      crearArchivo(data)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(getOrganizacion(idMedico, objOrganizacion.idOrganizacion));
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `Ocurrio un error al procesar el archivo: ${err}`,
            }),
          );
        });
    }
  }, [selObjArchivo]);

  return (
    <div className="p-4 bg-white">
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <div className="flex flex-col justify-center">
          <h1 className="text-blue-500 font-normal m-2 text-center">
            {t('administrador_mi_organizacion')}
          </h1>
        </div>
        <div className="flex justify-center items-center flex-col">
          <Avatar className={classes.ninetyPx} src={urlImage}>
            {urlImage.length === 0 && <AddToPhotosIcon fontSize="large" />}
          </Avatar>
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              onChange={seleccionarArchivo}
              disabled={objOrganizacion.idOrganizacion <= 0}
            />
            <HtmlTooltip
              title={
                objOrganizacion.idOrganizacion <= 0
                  ? `${t('administrador_alta_empresa_luego_logo')}`
                  : ''
              }
            >
              <span>
                <Button
                  component="span"
                  color="primary"
                  disabled={objOrganizacion.idOrganizacion <= 0}
                >
                  {objOrganizacion.urlLogotipo && objOrganizacion.urlLogotipo.length > 0
                    ? t('cambiar_logotipo')
                    : t('agregar_logotipo')}
                </Button>
              </span>
            </HtmlTooltip>
          </label>
        </div>
        <div className="pt-4">
          <FormControl component="fieldset">
            <FormLabel>{`${t('administrador_mi_organizacion_personalidad')}*`}</FormLabel>
            <RadioGroup
              value={objOrganizacion.personalJuridica}
              onChange={handleChangePersonalJuridica}
              aria-label="personalidad_juridica"
              name="personalidad_juridica"
              row
            >
              <FormControlLabel
                value="pm"
                control={<Radio color="primary" />}
                label={t('administrador_mi_organizacion_moral')}
              />
              <FormControlLabel
                value="pf"
                control={<Radio color="primary" />}
                label={t('administrador_mi_organizacion_fisica')}
              />
            </RadioGroup>
          </FormControl>
        </div>
        {objOrganizacion.personalJuridica === 'pm' && (
          <div className="w-full my-4">
            <h3 className="text-gray-500 font-normal m-0 mb-4">
              {`${t('administrador_mi_organizacion_nombre')}* (${t(
                'administrador_mi_organizacion_comercial',
              )})`}
            </h3>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 250 }}
              onChange={handleChangeNombre}
              value={objOrganizacion.nombreOrganizacion}
            />
          </div>
        )}
        <div className="w-full my-4">
          <h3 className="text-gray-500 font-normal m-0 mb-4">
            {`${t('administrador_mi_organizacion_razon_social')}*`}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 250 }}
            onChange={handleChangeRazonSocial}
            value={objOrganizacion.razonSocial}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <h3 className="text-gray-500 font-normal m-0 mb-4">
              {t(`administrador_mi_organizacion_rfc_${pais}`)}
            </h3>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{
                autocomplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: rfcLongitudMax(pais),
              }}
              onChange={handleChangeRfc}
              value={objOrganizacion.rfc}
              onBlur={handleBlurRfc}
              helperText={errores.rfc.helperText}
              error={errores.rfc.error}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
            />
          </div>
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 152) && (
            <div>
              <h3 className="font-normal m-0 text-gray-500 mb-4">
                {t('administrador_mi_organizacion_cfdi')}
              </h3>
              <Autocomplete
                options={filterCatCfdi}
                getOptionLabel={(option) => option.label}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={objOrganizacion.cfdi}
                onChange={(e, nV) => handleChangeCfdi(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
          )}
        </div>
        {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 152) && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <h3 className="text-gray-500 font-normal m-0 mb-4">
                {t('administrador_mi_organizacion_email')}
              </h3>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                InputProps={{
                  inputProps: { autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 },
                }}
                onChange={handleChangeCorreoElectronico}
                value={objOrganizacion.email}
                onBlur={handleBlurEmail}
                helperText={errores.email.helperText}
                error={errores.email.error}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
              />
            </div>
            <div className="grid grid-cols-6 gap-4 items-end">
              <div className="col-span-4">
                <h3 className="text-gray-500 font-normal m-0 mb-4">
                  {t('administrador_mi_organizacion_telefono')}
                </h3>
                <TextField
                  variant="outlined"
                  fullWidth
                  inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                  onChange={handleChangeTelefono1}
                  value={objOrganizacion.telefono}
                />
              </div>
              <div className="col-span-2">
                <h3 className="text-gray-500 font-normal m-0 mb-4">{t('extension')}</h3>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder={t('numero')}
                  inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                  onChange={handleChangeExtension1}
                  value={objOrganizacion.extension}
                />
              </div>
            </div>
          </div>
        )}
        {geo.agee && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <h3 className="font-normal m-0 text-gray-500 mb-4">
                {`${t(`${geo.agee.label}`)}${geo.agee.required ? '*' : ''}`}
              </h3>
              <Autocomplete
                options={catAGEE}
                getOptionLabel={(option) => option.nom_agee}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={objOrganizacion.agee}
                onChange={(e, nV) => handleChangeAgee(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
            {geo.agem && (
              <div>
                <h3 className="font-normal m-0 text-gray-500 mb-4">
                  {`${t(`${geo.agem.label}`)}${geo.agem.required ? '*' : ''}`}
                </h3>
                <Autocomplete
                  options={catAGEM}
                  getOptionLabel={(option) => option.nom_agem}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={objOrganizacion.agem}
                  onChange={(e, nV) => handleChangeAgem(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
            )}
          </div>
        )}
        {geo.agel && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <h3 className="font-normal m-0 text-gray-500 mb-4">
                {`${t(`${geo.agel.label}`)}${geo.agel.required ? '*' : ''}`}
              </h3>
              <Autocomplete
                options={catAGEL}
                getOptionLabel={(option) => option.nom_agel}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={objOrganizacion.agel}
                onChange={(e, nV) => handleChangeAgel(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
            {geo.agea && (
              <div>
                <h3 className="font-normal m-0 text-gray-500 mb-4">
                  {`${t(`${geo.agea.label}`)}${geo.agea.required ? '*' : ''}`}
                </h3>
                <Autocomplete
                  id="combo-box-agea-residente"
                  options={catAGEA}
                  getOptionLabel={(option) => option.nom_agea}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={objOrganizacion.agea}
                  onChange={(e, nV) => handleChangeAgea(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
            )}
          </div>
        )}
        <div className="grid grid-cols-12 gap-4 mt-4">
          <div className="col-span-9">
            <h3 className="text-gray-500 font-normal m-0 mb-4">
              {`${t(`${geo.calle?.label}`)}${geo.calle?.required ? '*' : ''}`}
            </h3>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 50 }}
              onChange={handleChangeCalle}
              value={objOrganizacion.calle}
            />
          </div>
          <div>
            <h3 className="text-gray-500 font-normal m-0 mb-4">
              {`${t(`${geo.num1?.label}`)}${geo.num1?.required ? '*' : ''}`}
            </h3>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
              onChange={handleChangeNum1}
              value={objOrganizacion.num1}
            />
          </div>
          {geo.num2 && (
            <div>
              <h3 className="text-gray-500 font-normal m-0 mb-4">
                {`${t(`${geo.num2.label}`)}${geo.num2.required ? '*' : ''}`}
              </h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                onChange={handleChangeNum2}
                value={objOrganizacion.num2}
              />
            </div>
          )}
          <div>
            <h3 className="text-gray-500 font-normal m-0 mb-4">
              {`${t(`${geo.cp?.label}_av`)}${geo.cp?.required ? '*' : ''}`}
            </h3>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 5 }}
              onChange={handleChangeCP}
              value={objOrganizacion.cp}
            />
          </div>
        </div>
        <span className="text-gray-500">
          *
          {t('campos_obligatorios')}
        </span>
      </div>
    </div>
  );
}

export default Organizacion;
