import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  noTextTranform: {
    textTransform: 'none',
  },
  root: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& > *': {
      margin: 0,
    },
  },
  iconButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: 0,
    padding: 0,
  },
  largeAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: 0,
    padding: 0,
    backgroundColor: '#1E40AF',
    fontSize: 'bold',
  },
  input: {
    display: 'none',
    margin: 0,
    padding: 0,
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
}));

export default useStyles;
