import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  paginationButtonContainer: {
    width: '46px',
    height: '46px',
    marginRight: '.25rem',
  },
  paginationButton: {
    minWidth: '46px',
    maxWidth: '46px',
    minHeight: '46px',
    maxHeight: '46px',
    justifyContent: 'normal',
  },
}));

export default useStyles;
