import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IConstructorRespuestaBreveProps } from './types';
import { capitalize } from '../../../../common/functions';
import { getCatalogoUnidades } from '../../../../utils/getCatalogos';
import { ICatalogosEstandar } from '../../../../common/types';

/** Componente de respuesta breve */
function RespuestaBreve({
  variable,
  setVariable,
  errores,
  setErrores,
  setDisableContinuar,
}: IConstructorRespuestaBreveProps) {
  const { t } = useTranslation();
  /** States del functional component */
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  /** Catalogos */
  const [catUnidades, setCatUnidades] = useState<ICatalogosEstandar[]>([]);
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Logica */
  /** Hooks */
  /** Efecto al cargar la pagina */
  useEffect(() => {
    getCatalogoUnidades().then((result: ICatalogosEstandar[]) => {
      setCatUnidades(result);
    });
  }, []);

  /** Handlers de la snackbar */
  /** Handlers del componente */
  const handleChangeDescripcion = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setVariable((preCons) => ({
      ...preCons,
      datosComponente: {
        ...preCons.datosComponente,
        descripcion: val,
      },
      constructor: {
        ...preCons.constructor,
        descripcion: val,
      },
    }));
  };

  const handleChangeUnidad = (event: any, newValue: ICatalogosEstandar | null) => {
    if (newValue && newValue.label === 'OTRA') {
      setVariable((preCons) => ({
        ...preCons,
        constructor: {
          ...preCons.constructor,
          respuestaBreve: {
            unidad: { id: newValue.id, label: newValue.label },
            otraUnidad: '',
          },
        },
      }));
      setErrores((preE) => ({ ...preE, errorAutocomplete: false }));
    } else if (newValue && newValue.label === 'SIN UNIDAD') {
      setVariable((preCons) => ({
        ...preCons,
        constructor: {
          ...preCons.constructor,
          respuestaBreve: {
            unidad: newValue,
            otraUnidad: '',
          },
        },
      }));
      setErrores((preE) => ({ ...preE, errorAutocomplete: false }));
    } else if (newValue?.label !== '') {
      setVariable((preCons) => ({
        ...preCons,
        constructor: {
          ...preCons.constructor,
          respuestaBreve: {
            unidad: { id: newValue?.id || 0, label: newValue?.label || '' },
            otraUnidad: '',
          },
        },
      }));
      setErrores((preE) => ({ ...preE, errorAutocomplete: false }));
    }
    setDisableContinuar(false);
  };

  const handleChangeOtraUnidad = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setVariable((preCons) => ({
      ...preCons,
      constructor: {
        ...preCons.constructor,
        respuestaBreve: {
          unidad: {
            id: preCons.constructor.respuestaBreve?.unidad.id || 0,
            label: preCons.constructor.respuestaBreve?.unidad.label || '',
          },
          otraUnidad: val,
        },
      },
    }));
    setErrores((preE) => ({ ...preE, errorEspecifica: false }));
    setDisableContinuar(false);
  };
  /** handles de disabled components */
  /** Handlers para enviar la informacion */
  /** handler del evento submit */
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 grid grid-cols-2 gap-x-4">
      <div className="col-span-4 grid grid-cols-4 gap-2 items-end">
        <h3 className="m-0 overflow-ellipsis overflow-hidden text-gray-600 font-medium">
          {capitalize(variable.datosComponente.titulo)}
        </h3>
        <h3 className="mt-0 mb-2 text-gray-600 font-medium">
          {t('incluye_una_descripcion_opcional')}
        </h3>
      </div>
      <div>
        <div className="grid grid-cols-2 gap-2 mb-3.5 items-end">
          <TextField variant="outlined" fullWidth disabled />
          <TextField
            fullWidth
            value={variable.datosComponente.descripcion}
            onChange={handleChangeDescripcion}
            inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            multiline
            rows={1}
          />
        </div>
        <div className="grid grid-cols-2 gap-2 items-start">
          <Autocomplete
            id="combo-box-unidades"
            options={catUnidades}
            getOptionLabel={(option) => option.label}
            fullWidth
            noOptionsText={t('sin_resultados')}
            value={variable.constructor.respuestaBreve?.unidad}
            onChange={(e, nV) => handleChangeUnidad(e, nV)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('unidad')}
                variant="outlined"
                error={errores.errorAutocomplete}
                helperText={errores.errorAutocomplete ? t('necesario_colocar_unidad') : ''}
              />
            )}
          />
          {variable.constructor.respuestaBreve?.unidad.label === 'OTRA' && (
            <TextField
              variant="outlined"
              label={t('especifica')}
              onChange={handleChangeOtraUnidad}
              value={variable.constructor.respuestaBreve?.otraUnidad}
              inputProps={{ maxLength: 25, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              multiline
              rows={1}
              error={errores.errorEspecifica}
              helperText={errores.errorEspecifica ? t('necesario_colocar_otra_unidad') : ''}
            />
          )}
        </div>
      </div>
      <TextField
        variant="outlined"
        rows={6}
        multiline
        fullWidth
        disabled
        label={t('comentarios')}
      />
    </div>
  );
}

export default RespuestaBreve;
