import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  noTextTranform: {
    textTransform: 'none',
  },
  header: {
    backgroundColor: 'rgb(59, 130, 246)',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  headerTitle: {
    backgroundColor: 'rgb(59, 130, 246)',
    color: '#FFFFFF',
    textAlign: 'left',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  buttonRoot: {
    minWidth: 'auto',
    backgroundColor: '#3B82F6',
    color: 'white',
    borderRadius: '5px 5px 0 0',
    borderLeft: '1px solid white',
    borderRight: '1px solid white',
    '&:hover': {
      backgroundColor: '#2563EB',
    },
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  buttonSelected: {
    minWidth: 'auto',
    backgroundColor: '#1E40AF',
    color: 'white',
    borderRadius: '5px 5px 0 0',
    borderLeft: '1px solid #DDDDDD',
    borderRight: '1px solid #DDDDDD',
    borderTop: '1px solid #DDDDDD',
    '&:hover': {
      backgroundColor: '#2563EB',
    },
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  operacionSelected: {
    backgroundColor: '#1E467E',
    color: '#FFFFFF',
    textTransform: 'none',
  },
  operacionButtonColor: {
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1E467E',
      color: '#FFFFFF',
    },
  },
  buttonGraphRoot: {
    minWidth: 'auto',
    color: 'grey',
    border: '1px solid',
    borderColor: 'grey',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  buttonGraphSelected: {
    minWidth: 'auto',
    color: '#1E40AF',
    border: '1px solid',
    borderColor: '#1E40AF',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  colorBlack: {
    color: '#000000',
  },
  colorRed: {
    color: '#B20837',
  },
  colorOrange: {
    color: '#EF4444',
  },
  colorYellow: {
    color: '#F79646',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  paginationButtonContainer: {
    width: '46px',
    height: '46px',
    marginRight: '.25rem',
  },
  paginationButton: {
    minWidth: '46px',
    maxWidth: '46px',
    minHeight: '46px',
    maxHeight: '46px',
    justifyContent: 'normal',
  },
}));

export default useStyles;
