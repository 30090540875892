import { SET_LOADING, LoadingActionTypes } from './types';

export function setLoading(loading: boolean): LoadingActionTypes {
  return {
    type: SET_LOADING,
    payload: loading,
  };
}

export default setLoading;
