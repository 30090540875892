import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { RawDraftContentState } from 'draft-js';
import { Button, Collapse } from '@material-ui/core';
import { RootState } from '../../../store';
import { esMismoDiaFechaISO, getFechaActualISO } from '../../../common/functions';
import Plan from './Plan/index';
import Diagnostico from './Diagnostico/Formulario';
import ListaDiagnosticos from './Diagnostico/ListaDiagnosticos';
import { IDiagnosticoProps } from './types';
import {
  IEnfermedad,
  IEnfermedadesForm,
  enfermedadesFormInitial,
} from '../Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';

function DiagnosticoGeneral(props: IDiagnosticoProps) {
  const {
    setActiveStep,
    laboratorioSolicitud,
    setLaboratorioSolicitud,
    enfReferidas,
    setEnfReferidas,
    enfNegadas,
    setEnfNegadas,
    grupoEnfNegadas,
    setGrupoEnfNegadas,
    solProcDiagnosticos,
    setSolProcDiagnosticos,
    solProcTerapeuticos,
    setSolProcTerapeuticos,
    solProcQuirurgicos,
    setSolProcQuirurgicos,
    procDiagnosticos,
    setProcDiagnosticos,
    procTerapeuticos,
    setProcTerapeuticos,
    sinProcQuirurgicos,
    setSinProcQuirurgicos,
    procQuirurgicos,
    setProcQuirurgicos,
    catDiagnostico,
    tratamientosActualesConsulta,
    setTratamientos,
    indiceReceta,
    setIndiceReceta,
    recetas,
    setRecetas,
    exploracionFisica,
    enGestacion,
    setEnGestacion,
    inmunizaciones,
    setInmunizaciones,
    pronosticos,
    setPronosticos,
  } = props;
  const { t } = useTranslation();
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { secciones, esPaciente } = useSelector((state: RootState) => state.Me);
  // const [selector, setSelector] = useState<number>(1);
  const [referidasActivasExpand, setReferidasActivasExpand] = useState(true);
  const [referidasResueltasExpand, setReferidasResueltasExpand] = useState(false);
  const [enfActivas, setEnfActivas] = useState<IEnfermedad[]>([]);
  const [enfResueltas, setEnfResueltas] = useState<IEnfermedad[]>([]);
  const [idDiagnostico, setIdDiagnostico] = useState<number>(-1);
  const [diagnosticoSeleccionado, setDiagnosticoSeleccionado] = useState<IEnfermedadesForm>(enfermedadesFormInitial);

  useEffect(() => {
    const enfermedadesActivas = [] as IEnfermedad[];
    const enfermedadesResueltas = [] as IEnfermedad[];
    enfReferidas.forEach((e) => {
      if (e.idConsulta === idConsulta) {
        if (e.estatusDelDiagnostico === 'activo') {
          enfermedadesActivas.push(e);
        } else if (e.estatusDelDiagnostico === 'resuelto') {
          enfermedadesResueltas.push(e);
        }
      }
    });
    setEnfActivas(enfermedadesActivas);
    setEnfResueltas(enfermedadesResueltas);
  }, [enfReferidas]);

  useEffect(() => {
    if (idDiagnostico !== -1) {
      const diagnosticoSel = enfReferidas.find((e) => e.idBD === idDiagnostico);
      if (diagnosticoSel) {
        const comentario = diagnosticoSel.comentarios.length > 0
          && esMismoDiaFechaISO(diagnosticoSel.comentarios[0].fecha, getFechaActualISO())
          ? diagnosticoSel.comentarios[0].comentario
          : ({ blocks: [], entityMap: {} } as RawDraftContentState);
        setDiagnosticoSeleccionado({
          ...diagnosticoSel,
          comentario,
        });
      }
    }
  }, [idDiagnostico]);

  return (
    <div className="bg-white w-full">
      {!esPaciente && (
        <div className="container mx-auto border border-solid border-gray-200 shadow">
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 47) && (
            <Diagnostico
              idDiagnostico={idDiagnostico}
              setIdDiagnostico={setIdDiagnostico}
              diagnostico={diagnosticoSeleccionado}
              setDiagnostico={setDiagnosticoSeleccionado}
              enfReferidas={enfReferidas}
              setEnfReferidas={setEnfReferidas}
              enfNegadas={enfNegadas}
              setEnfNegadas={setEnfNegadas}
              grupoEnfNegadas={grupoEnfNegadas}
              setGrupoEnfNegadas={setGrupoEnfNegadas}
            />
          )}
          <div className="mt-4 bg-white rounded p-4 shadow">
            {enfActivas.length > 0 ? (
              <h3 className="text-blue-500 font-medium m-0">{t('lista_de_enfermedades')}</h3>
            ) : (
              <h3 className="text-blue-500 font-medium m-0">
                {t('no_hay_registros_existentes_para_esta_consulta')}
              </h3>
            )}
            <div className="mt-4">
              {enfActivas.length > 0 && (
                <>
                  <h3 className="text-blue-500 font-light m-0 mb-4">
                    {`${t('diagnosticos_activos')}: ${enfActivas.length}`}
                  </h3>
                  <Collapse in={referidasActivasExpand}>
                    {enfActivas.map((diagnostico) => (
                      <ListaDiagnosticos
                        diagnostico={diagnostico}
                        idDiagnostico={idDiagnostico}
                        setIdDiagnostico={setIdDiagnostico}
                        enfReferidas={enfReferidas}
                        setEnfReferidas={setEnfReferidas}
                      />
                    ))}
                  </Collapse>
                  <div className="text-center">
                    <Button
                      onClick={() => setReferidasActivasExpand((prevVal: boolean) => !prevVal)}
                      color="primary"
                    >
                      {referidasActivasExpand ? (
                        <span>{t('ver_menos')}</span>
                      ) : (
                        <span>{t('ver_mas')}</span>
                      )}
                      {referidasActivasExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Button>
                  </div>
                </>
              )}
              {enfResueltas.length > 0 && (
                <>
                  <h3 className="text-blue-500 font-light m-0 mb-4">
                    {`${t('diagnosticos_resueltos')}: ${enfResueltas.length}`}
                  </h3>
                  <Collapse in={referidasResueltasExpand}>
                    {enfResueltas.map((diagnostico) => (
                      <ListaDiagnosticos
                        diagnostico={diagnostico}
                        idDiagnostico={idDiagnostico}
                        setIdDiagnostico={setIdDiagnostico}
                        enfReferidas={enfReferidas}
                        setEnfReferidas={setEnfReferidas}
                      />
                    ))}
                  </Collapse>
                  <div className="text-center">
                    <Button
                      onClick={() => setReferidasResueltasExpand((prevVal: boolean) => !prevVal)}
                      color="primary"
                    >
                      {referidasResueltasExpand ? (
                        <span>{t('ver_menos')}</span>
                      ) : (
                        <span>{t('ver_mas')}</span>
                      )}
                      {referidasResueltasExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="pt-2 pb-4">
        <div className="container mx-auto shadow-lg">
          <div className="container mx-auto shadow flex content-center items-center p-4">
            <Plan
              setActiveStep={setActiveStep}
              laboratorioSolicitud={laboratorioSolicitud}
              setLaboratorioSolicitud={setLaboratorioSolicitud}
              listaEnfermedades={enfReferidas}
              solProcDiagnosticos={solProcDiagnosticos}
              setSolProcDiagnosticos={setSolProcDiagnosticos}
              solProcTerapeuticos={solProcTerapeuticos}
              setSolProcTerapeuticos={setSolProcTerapeuticos}
              solProcQuirurgicos={solProcQuirurgicos}
              setSolProcQuirurgicos={setSolProcQuirurgicos}
              procDiagnosticos={procDiagnosticos}
              setProcDiagnosticos={setProcDiagnosticos}
              procTerapeuticos={procTerapeuticos}
              setProcTerapeuticos={setProcTerapeuticos}
              sinProcQuirurgicos={sinProcQuirurgicos}
              setSinProcQuirurgicos={setSinProcQuirurgicos}
              procQuirurgicos={procQuirurgicos}
              setProcQuirurgicos={setProcQuirurgicos}
              catDiagnostico={catDiagnostico}
              tratamientosActualesConsulta={tratamientosActualesConsulta}
              setTratamientos={setTratamientos}
              indiceReceta={indiceReceta}
              setIndiceReceta={setIndiceReceta}
              recetas={recetas}
              setRecetas={setRecetas}
              enfermedadesActivas={enfActivas}
              enfermedadesResueltas={enfResueltas}
              exploracionFisica={exploracionFisica}
              enGestacion={enGestacion}
              setEnGestacion={setEnGestacion}
              inmunizaciones={inmunizaciones}
              setInmunizaciones={setInmunizaciones}
              pronosticos={pronosticos}
              setPronosticos={setPronosticos}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiagnosticoGeneral;
