import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertDialogProps from './types';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function AvisoTokenOrganizacion(props: IAlertDialogProps) {
  const { open, callBackClose } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="validar-usuario-correo-titulo"
      aria-describedby="validar-usuario-correo-descripcion"
    >
      <div className="flex justify-between items-center bg-blue-500 text-white pl-4">
        <span className="text-lg">{t('aviso_token_organizacion_titulo')}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <DialogContentText>
          <p className="text-justify text-gray-500 mt-4">{t('aviso_token_organizacion_texto')}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex justify-center mb-2">
          <div>
            <Button onClick={callBackClose} color="primary" variant="contained">
              {t('aceptar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default AvisoTokenOrganizacion;
