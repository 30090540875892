import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TextField, withStyles } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { ITablaDiagnosticosProps } from './types';
import useStyles from './styles';
import { IDiagnosticoGeneral } from '../types';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const BlackRadio = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

function TablaDiagnosticos(props: ITablaDiagnosticosProps) {
  const { diagnosticos, setDiagnosticos, sexo } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChangeRadioSeleccion = (event: React.ChangeEvent<HTMLInputElement>, indx: number) => {
    const posee = event.target.value as 'si' | 'no';
    const newContenidoTabla = diagnosticos.contenidoTabla.map(
      (d: IDiagnosticoGeneral, indi: number) => (indi === indx
        ? {
          nombre: d.nombre,
          posee,
          medicamento: posee === 'no' ? '' : d.medicamento,
          noMedicamento: posee === 'no' ? false : d.noMedicamento,
          sexo: d.sexo,
          validado: false,
        }
        : d),
    );
    setDiagnosticos({
      ...diagnosticos,
      contenidoTabla: newContenidoTabla,
    });
  };

  const handleClickRadioSeleccion = (value: 'si' | 'no' | '', indx: number) => {
    let val = value;
    if (diagnosticos.contenidoTabla[indx].posee === val) {
      val = '';
    }
    const newContenidoTabla = diagnosticos.contenidoTabla.map(
      (d: IDiagnosticoGeneral, indi: number) => (indi === indx
        ? {
          nombre: d.nombre,
          posee: val,
          medicamento: d.medicamento,
          noMedicamento: d.noMedicamento,
          sexo: d.sexo,
          validado: false,
        }
        : d),
    );
    setDiagnosticos({
      ...diagnosticos,
      contenidoTabla: newContenidoTabla,
    });
  };

  const handleChangeMedicamento = (event: React.ChangeEvent<{ value: unknown }>, indx: number) => {
    const newContenidoTabla = diagnosticos.contenidoTabla.map(
      (d: IDiagnosticoGeneral, indi: number) => {
        if (indi === indx) {
          const posee: 'si' | 'no' | '' = 'si';
          return {
            nombre: d.nombre,
            posee,
            medicamento: event.target.value as string,
            noMedicamento: d.noMedicamento,
            sexo: d.sexo,
            validado: false,
          };
        }
        return d;
      },
    );
    setDiagnosticos({
      ...diagnosticos,
      contenidoTabla: newContenidoTabla,
    });
  };

  const handleChangeNoMedicamento = (event: React.ChangeEvent<HTMLInputElement>, indx: number) => {
    const val = event.target.checked as boolean;
    const newContenidoTabla = diagnosticos.contenidoTabla.map(
      (d: IDiagnosticoGeneral, indi: number) => (indi === indx
        ? {
          nombre: d.nombre,
          posee: d.posee,
          medicamento: val ? '' : d.medicamento,
          noMedicamento: val,
          sexo: d.sexo,
          validado: false,
        }
        : d),
    );
    setDiagnosticos({
      ...diagnosticos,
      contenidoTabla: newContenidoTabla,
    });
  };

  const obtenerTabla = () => {
    let i = 0;
    return diagnosticos.contenidoTabla.map((diagnostico: IDiagnosticoGeneral, index: number) => {
      if (['A', sexo].includes(diagnostico.sexo)) {
        i += 1;
        return (
          <div
            className={`grid grid-cols-12 gap-4 ${
              i % 2 === 0 ? 'bg-blue-300' : ''
            } text-center items-center py-4`}
            key={diagnostico.nombre}
          >
            <span className="col-span-5 pl-4 text-left">
              {diagnostico.nombre === 'antecedentes-pp-diagnosticos-g-otro' ? (
                <Trans i18nKey="antecedentes-pp-diagnosticos-g-otro_">
                  <span>index0</span>
                  <li>index1</li>
                  <li>index2</li>
                  <li>index3</li>
                </Trans>
              ) : (
                t(diagnostico.nombre)
              )}
            </span>
            <div className="col-span-1">
              <BlackRadio
                value="si"
                name={`radio-button-si-${index}`}
                checked={diagnostico.posee === 'si'}
                onChange={(e) => handleChangeRadioSeleccion(e, index)}
                onClick={() => handleClickRadioSeleccion('si', index)}
              />
            </div>
            <div className="col-span-1">
              <BlackRadio
                value="no"
                name={`radio-button-no-${index}`}
                checked={diagnostico.posee === 'no'}
                onChange={(e) => handleChangeRadioSeleccion(e, index)}
                onClick={() => handleClickRadioSeleccion('no', index)}
              />
            </div>
            <div className="col-span-4 pr-4">
              <TextField
                disabled={diagnostico.posee === 'no' || diagnostico.noMedicamento}
                multiline
                variant="outlined"
                fullWidth
                InputProps={{
                  className: classes.backgroundWhite,
                  inputProps: { maxLength: 500, autoComplete: 'off', backgroundColor: 'white' },
                }}
                value={diagnostico.medicamento}
                onChange={(e) => handleChangeMedicamento(e, index)}
              />
            </div>
            <div className="col-span-1 pr-4">
              <BlackCheckbox
                disabled={diagnostico.posee === 'no'}
                name="no-medicamento"
                checked={diagnostico.noMedicamento}
                onChange={(e) => handleChangeNoMedicamento(e, index)}
              />
            </div>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className="border border-solid border-gray-600">
      <div className="grid grid-cols-12 gap-4 bg-blue-600 text-white text-center items-center py-2">
        <span className="col-span-5">{t('diagnostico')}</span>
        <span className="col-span-1">{t('si')}</span>
        <span className="col-span-1">{t('no')}</span>
        <span className="col-span-4">{t('antecedentes-pp-diagnosticos-g-especifica')}</span>
        <span className="col-span-1">{t('antecedentes-pp-diagnosticos-g-no-medicamento')}</span>
      </div>
      {obtenerTabla()}
    </div>
  );
}

export default TablaDiagnosticos;
