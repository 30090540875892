import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { ITablaFMCCompartidosProps, ITablaFMCCompartidosRowProps } from './types';

function TablaFMCCompartidosRows({
  nombreFormato,
  rangoEdad,
  sexo,
  tipoConsulta,
  index,
  idFormato,
  handleVerFormato,
}: ITablaFMCCompartidosRowProps) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`grid grid-cols-12${
          index % 2 === 0 ? ' bg-blue-200' : ''
        } text-center items-center`}
      >
        <div className="col-span-3 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center">
          {nombreFormato}
        </div>
        <div className="col-span-4 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center text-left">
          {rangoEdad}
        </div>
        <div className="col-span-1 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center">
          {sexo}
        </div>
        <div className="col-span-2 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center overflow-ellipsis">
          <p className="m-0 overflow-ellipsis overflow-hidden">{tipoConsulta}</p>
        </div>
        <div className="col-span-2 p-2 border-l border-solid border-0 border-gray-400 h-full flex justify-center items-center">
          <Button color="primary" onClick={() => handleVerFormato(idFormato)}>
            {t('ver_formato')}
          </Button>
        </div>
      </div>
    </>
  );
}

function TablaFMCCompartidos({
  motivosConsultaCompartidos,
  handleVerFormato,
}: ITablaFMCCompartidosProps) {
  const { t } = useTranslation();
  return (
    <div className="border border-solid border-gray-400 rounded-md">
      <div className="grid grid-cols-12 bg-blue-600 text-white text-center py-2 items-center">
        <span className="col-span-3">{t('nombre_del_formato')}</span>
        <span className="col-span-4">{t('rango_de_edad')}</span>
        <span className="col-span-1">{t('sexo')}</span>
        <span className="col-span-2">{t('tipo_de_consulta')}</span>
      </div>
      {motivosConsultaCompartidos.map((objResultado, index) => (
        <TablaFMCCompartidosRows
          nombreFormato={objResultado.nombreFormato}
          rangoEdad={objResultado.rangoEdad}
          sexo={objResultado.sexo}
          tipoConsulta={objResultado.tipoConsulta}
          index={index}
          idFormato={objResultado.idFormato}
          handleVerFormato={handleVerFormato}
          key={`${objResultado.nombreFormato}-${objResultado.rangoEdad}-${objResultado.sexo}-${objResultado.tipoConsulta}-${objResultado.idFormato}`}
        />
      ))}
    </div>
  );
}

export default TablaFMCCompartidos;
