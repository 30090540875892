export const arrGeneral = [
  'escalofrios',
  'fatiga',
  'fiebre',
  'malestar',
  'sudoracion_nocturna',
  'aumento_de_peso',
  'perdida_de_peso',
  'otros',
];

export const arrCabezaOidosBocaGarganta = [
  'secrecion_en_oido',
  'dolor_de_oido',
  'secrecion_en_ojo',
  'dolor_en_ojo',
  'alteraciones_de_la_audiciones',
  'epistaxis',
  'secrecion_nasal',
  'sinusitis',
  'dolor_de_garganta',
  'disfagia',
  'odinofagia',
  'ronquera',
  'problemas_dentales',
  'alteracion_de_la_vision',
  'otros',
];

export const arrCardiovascular = [
  'dolor_toracico',
  'claudicacion',
  'edema',
  'palpitaciones',
  'otros',
];

export const arrRespiratorio = [
  'tos',
  'hemoptisis',
  'exposicion_a_tb',
  'dificultad_para_respirar',
  'sibilancias',
  'otros',
];

export const arrGastrointestinal = [
  'dolor_abdominal',
  'sangre_en_las_heces',
  'cambio_en_el_patron_evacuatorio',
  'estrenymiento',
  'diarrea',
  'pirosis',
  'disminucion_de_apetito',
  'nauseas',
  'vomitos',
  'otros',
];

export const arrGenitourinarioRenal = [
  'disuria',
  'hematuria',
  'poliuria',
  'polaquiuria',
  'nicturia',
  'incontinencia_urinaria',
  'secreciones',
  'otros',
];

export const arrHematicoLinfatico = [
  'sangrado_espontaneo',
  'hematomas_espontaneos',
  'linfadenopatia',
  'otros',
];

export const arrEndocrino = [
  'intolerancia_al_frio',
  'intolerancia_al_calor',
  'polidipsia',
  'polifagia',
  'otros',
];

export const arrNeurologico = [
  'mareo',
  'alteraciones_de_la_sensibilidad',
  'alteraciones_motoras',
  'alteraciones_de_la_marcha',
  'cefalea',
  'alteraciones_de_la_memoria',
  'convulsiones',
  'temblores',
  'otros',
];

export const arrMusculoEsqueletico = [
  'dolor_de_espalda',
  'dolor_articular',
  'edema_articular',
  'dolor_de_cuello',
  'otros',
];

export const arrPielYAnexos = [
  'secrecion_en_pezon',
  'masa_en_mama',
  'perdida_de_cabello',
  'unyas_quebradizas',
  'hirsutismo',
  'urticaria',
  'prurito',
  'cambio_en_lunares',
  'erupcion',
  'lesiones_en_piel',
  'otros',
];

export const arrReproductivoMujer = [
  'disminucion_de_la_libido',
  'ulceras',
  'dispareunia',
  'bochorno',
  'resequedad_vaginal',
  'otros',
];

export const arrReproductivoHombre = [
  'disminucion_de_la_libido',
  'ulceras',
  'disfuncion_sexual',
  'priapismo',
  'dolor_testicular',
  'otros',
];

export const arrEsferaPsiquica = [
  'tristeza',
  'euforia',
  'alteraciones_de_suenyo',
  'irritabilidad',
  'apatia',
  'otros',
];
