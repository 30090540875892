import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    justifyContent: 'left',
    textTransform: 'none',
  },
  boldText: {
    fontWeight: 'bold',
    color: 'black',
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  noTextTranform: {
    textTransform: 'none',
  },
});

export const InputDisabled = withStyles({
  root: {
    marginRight: 8,
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 1)',
    },
  },
})(TextField);

export default useStyles;
