import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLoading } from '../../actions/loading/actions';
import { IRequest } from '../../actions/request/actions';
import { setRequest } from '../../actions/request/types';
import { SetSnackClose, setSnackComplete } from '../../actions/snackbar/types';

/* TODO: añadir más validaciones
 * Al utilizar este componente se debe tener cuidado si se realizan varias peticiones simultáneamente, ya que, por ser un mismo componente para cualquier petición, estas podrían cruzase
 * type: define el tipo de peticion, si es para obtener (get), enviar (get) o eliminar (delete) datos. Esto determina el tipo de mensajes que se muestran de respuesta o de error
 * multiple (false): define si la petición es múltiple o es una única petición. Por defecto es falso
 * requestFunction: Para peticiones únicas, llama a la función de la petición
 * requestFunctions: Para peticiones múltiples, define la funciones de las peticiones
 * successFunction: Para peticiones únicas, en caso de estar definida, llama a la función definida en caso de obtener un resultado satisfactorio (code === 200)
 * successFunctions: Para peticiones múltiples, en caso de estar definidas, llama a la funciones definidas en caso de obtener resultados satisfactorios (code === 200)
 * catchFunction: En caso de estar definida, llama a la función definida en caso de obtener un error en la(s) petición(es)
 * successMessage: En caso de estar definido, define el mensaje que se muestra si el resultado de la petición es satisfactorio (code === 200)
 * errorMessage: Para peticiones únicas, en caso de estar definido, muestra un mensaje de error si el resultado de la petición no es satisfactorio (code !== 200).
 * errorFunction: Para peticiones únicas, en caso de estar definida, llama a la función definida en caso de obtener un resultado insatisfactorio (code !== 200)
 * errorFunctions: Para peticiones múltiples, en caso de estar definidas, llama a la funciones definidas en caso de obtener resultados insatisfactorios (code !== 200)
 * alwaysFunction: Para peticiones únicas, en caso de estar definido, llama a una función que se ejecuta siempre, ya sea que el resultado sea satisfactorio o no.
 * toJson: determina si el resultado devuelto se parsea a formato JSON o no. Puede recibir 'true', para indicar que todos se parsean, o una lista de números para indicar los índices de los resultados
 *         que se van a parsear (se cuentan desde cero). Si este parámetro es falso, quedan obsoletos los parámetros "errorMessage", "errorFunction", "errorFunctions" y "alwaysFunction"
 * loading (true): En caso de estar definida, determina si se muestra el componente que opaca la vista para indicar que se está ejecutando una consulta
 * showMsgs (true): En caso de estar definida, determina si se muestra o no el mensaje de ejecución de la consulta
 * showMsgLoading (true): En caso de estar definida, determina si se muestran todos los mensajes
 * showMsgSuccess (true): En caso de estar definida, determina si se muestra o no el mensaje de respuesta satisfactoria
 * showMsgError (true): En caso de estar definida, determina si se muestra o no el mensaje de respuesta insatisfactoria
 */
function RequestComponent(props: IRequest) {
  const {
    type,
    multiple,
    requestFunction,
    requestFunctions,
    successFunction,
    successFunctions,
    errorFunction,
    errorFunctions,
    catchFunction,
    alwaysFunction,
    successMessage,
    errorMessage,
    toJson,
    loading,
    showMsgs,
    showMsgLoading,
    showMsgSuccess,
    showMsgError,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const msgLoading: string[] = ['', t('message-info'), t('message-delete')];
  const msgSuccess: string[] = [
    t('message_get_success'),
    t('message-success'),
    t('message-delete-success'),
  ];
  const msgError: string[] = [
    t('message-get-error'),
    t('message-error'),
    t('message-error-delete'),
  ];
  const msgWarning: string[] = [
    t('message-error-general'),
    t('message-error-warning'),
    t('message-error-delete'),
  ];

  // tp: 0=get / 1=save / 2=delete
  const sendGetDelData = (tp: number) => {
    if (requestFunction) {
      if (loading) {
        dispatch(setLoading(true));
      }
      if (tp > 0 && showMsgs && showMsgLoading) {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'info',
            mensaje: msgLoading[tp],
          }),
        );
      }
      requestFunction
        .then((response: Response) => (toJson ? response.json() : response))
        .then((result: any) => {
          if (!toJson || result.code === 200) {
            if (successFunction) {
              successFunction(result);
            }
            if (showMsgs && showMsgSuccess) {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'success',
                  mensaje: successMessage || msgSuccess[tp],
                }),
              );
            } else {
              dispatch(SetSnackClose());
            }
          } else if (toJson) {
            if (errorFunction) {
              errorFunction(result);
            }
            if (showMsgs && showMsgError) {
              const msg = errorMessage || (result.msg ? t(result.msg) : '');
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${msgError[tp]}${msg ? `: ${msg}` : ''}`,
                }),
              );
            } else if (!errorFunction) {
              dispatch(SetSnackClose());
            }
            if (alwaysFunction) {
              alwaysFunction();
            }
          }
          if (loading) {
            dispatch(setLoading(false));
          }
          dispatch(setRequest({ type: null }));
        })
        .catch((error: any) => {
          if (catchFunction) {
            catchFunction();
          }
          if (showMsgs && showMsgError) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${msgWarning[tp]}: ${error.toString()}`,
              }),
            );
          } else if (!catchFunction) {
            dispatch(SetSnackClose());
          }
          if (alwaysFunction) {
            alwaysFunction();
          }
          if (loading) {
            dispatch(setLoading(false));
          }
          dispatch(setRequest({ type: null }));
        });
    }
  };

  // tp: 0=get / 1=save
  const sendGetMultiData = (tp: number) => {
    if (requestFunctions?.length) {
      if (successFunctions?.length && requestFunctions.length > successFunctions.length) {
        dispatch(setRequest({ type: null }));
        throw new Error(
          'Error en la ejecución de los request: El número de peticiones no puede exceder al número de funciones satisfactorias',
        );
      } else if (errorFunctions?.length && requestFunctions.length > errorFunctions.length) {
        dispatch(setRequest({ type: null }));
        throw new Error(
          'Error en la ejecución de los request: El número de peticiones no puede exceder al número de funciones de error',
        );
      } else {
        if (loading) {
          dispatch(setLoading(true));
        }
        if (tp > 0 && showMsgs && showMsgLoading) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: msgLoading[tp],
            }),
          );
        }
        Promise.all(requestFunctions)
          .then((responses: Response[]) => {
            Promise.all(
              responses.map((r: Response, i: number) => (toJson === true || (Array.isArray(toJson) && toJson.includes(i)) ? r.json() : r)),
            )
              .then((results: any[]) => {
                results.forEach((result: any, index: number) => {
                  const tJson = toJson === true || (Array.isArray(toJson) && toJson.includes(index));
                  if (!tJson || result.code === 200) {
                    if (successFunctions?.length) {
                      successFunctions[index](result);
                    }
                    if (showMsgs && showMsgSuccess) {
                      dispatch(
                        setSnackComplete({
                          open: true,
                          severity: 'success',
                          mensaje: successMessage || msgSuccess[tp],
                        }),
                      );
                    } else {
                      dispatch(SetSnackClose());
                    }
                  } else if (tJson) {
                    if (errorFunctions?.length) {
                      errorFunctions[index](result);
                    }
                    if (showMsgs && showMsgError) {
                      dispatch(
                        setSnackComplete({
                          open: true,
                          severity: 'error',
                          mensaje: `${msgError[tp]}${result.msg ? `: ${t(result.msg)}` : ''}`,
                        }),
                      );
                    } else if (!errorFunctions || errorFunctions?.length < index) {
                      dispatch(SetSnackClose());
                    }
                  }
                });
                dispatch(setRequest({ type: null }));
                if (loading) {
                  dispatch(setLoading(false));
                }
              })
              .catch((error) => {
                if (catchFunction) {
                  catchFunction();
                }
                if (showMsgs && showMsgError) {
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'error',
                      mensaje: `${msgWarning[tp]} ${error.toString()}`,
                    }),
                  );
                } else if (!catchFunction) {
                  dispatch(SetSnackClose());
                }
                if (loading) {
                  dispatch(setLoading(false));
                }
                dispatch(setRequest({ type: null }));
              });
          })
          .catch((error) => {
            if (catchFunction) {
              catchFunction();
            }
            if (showMsgs && showMsgError) {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${msgWarning[tp]} ${error.toString()}`,
                }),
              );
            } else if (!catchFunction) {
              dispatch(SetSnackClose());
            }
            if (loading) {
              dispatch(setLoading(false));
            }
            dispatch(setRequest({ type: null }));
          });
      }
    }
  };

  useEffect(() => {
    if (type !== null) {
      if (multiple) {
        if (requestFunctions?.length) {
          if (
            Array.isArray(toJson)
            && toJson.some((index: number) => index >= requestFunctions.length)
          ) {
            dispatch(setRequest({ type: null }));
            throw new Error('El parámetro "toJson" contiene un índice inválido.');
          } else {
            sendGetMultiData(['get', 'send'].indexOf(type));
          }
        } else {
          dispatch(setRequest({ type: null }));
          throw new Error(
            'Error en la ejecución de los requests: No hay funciones para realizar las peticiones. Si la petición es única debe quitar el parámetro "multiple" de la llamada.',
          );
        }
      } else if (requestFunction) {
        if (Array.isArray(toJson)) {
          dispatch(setRequest({ type: null }));
          throw new Error('El parámetro "toJson" no puede ser un arreglo si la petición es única.');
        } else {
          sendGetDelData(['get', 'send', 'delete'].indexOf(type));
        }
      } else {
        dispatch(setRequest({ type: null }));
        throw new Error(
          'Error en la ejecución del request: No hay función para realizar la petición. Si la petición es múltiple debe añadir el parámetro "multiple" a la llamada.',
        );
      }
    }
  }, [type]);

  return <></>;
}

export default RequestComponent;
