import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl, Select, MenuItem, IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IDiscapacidadProps } from './types';
import { ICatalogosEstandar } from '../../../../../common/types';

function Discapacidad({
  index,
  eliminarDiscapacidad,
  cambiarValorSelect,
  tipoId,
  gradoId,
  origenId,
  catGradoDiscapacidad,
  catTipoDiscapacidad,
  catOrigenDiscapacidad,
  sinDiscapacidad,
}: IDiscapacidadProps) {
  const { t } = useTranslation();
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 flex">
      <div className="grid gap-4 grid-cols-3 xl:grid-cols-4 w-full">
        <div>
          <h3 className="text-gray-600 font-medium m-0 mb-2">{t('tipo_discapacidad')}</h3>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={tipoId}
              onChange={(e) => cambiarValorSelect(e, index, 'tipoId')}
              displayEmpty
              disabled={sinDiscapacidad}
            >
              <MenuItem value="" disabled>
                <span className="text-gray-400">{t('selecciona')}</span>
              </MenuItem>
              {catTipoDiscapacidad.length > 0
                && catTipoDiscapacidad.map((singleTipoDiscapacidad: ICatalogosEstandar) => (
                  <MenuItem
                    value={singleTipoDiscapacidad.id.toString()}
                    key={singleTipoDiscapacidad.id}
                  >
                    {singleTipoDiscapacidad.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <h3 className="text-gray-600 font-medium m-0 mb-2">{t('grado_discapacidad')}</h3>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={gradoId}
              onChange={(e) => cambiarValorSelect(e, index, 'gradoId')}
              disabled={sinDiscapacidad || tipoId.length === 0 || tipoId === '9'}
              displayEmpty
            >
              <MenuItem value="" disabled>
                <span className="text-gray-400">{t('selecciona')}</span>
              </MenuItem>
              {catGradoDiscapacidad.length > 0
                && catGradoDiscapacidad.map((singleGradoDiscapacidad: ICatalogosEstandar) => (
                  <MenuItem
                    value={singleGradoDiscapacidad.id.toString()}
                    key={singleGradoDiscapacidad.id}
                  >
                    {singleGradoDiscapacidad.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <h3 className="text-gray-600 font-medium m-0 mb-2">{t('origen_discapacidad')}</h3>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={origenId}
              onChange={(e) => cambiarValorSelect(e, index, 'origenId')}
              disabled={sinDiscapacidad || gradoId.length === 0 || gradoId === '9'}
              displayEmpty
            >
              <MenuItem value="" disabled>
                <span className="text-gray-400">{t('selecciona')}</span>
              </MenuItem>
              {catOrigenDiscapacidad.length > 0
                && catOrigenDiscapacidad.map((singleOrigenDiscapacidad: ICatalogosEstandar) => (
                  <MenuItem
                    value={singleOrigenDiscapacidad.id.toString()}
                    key={singleOrigenDiscapacidad.id}
                  >
                    {singleOrigenDiscapacidad.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <IconButton aria-label="delete" onClick={eliminarDiscapacidad} disabled={sinDiscapacidad}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
}

export default Discapacidad;
