import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from './styles';
import AlertDialog from '../../../../components/AlertDialogs/AlertDialog';
import ResumenCambios from '../../../../components/ResumenCambios';
import BotonesGuardar from '../../../../components/BotonesGuardar';
import {
  IDiscapacidadesProps,
  IDiscapacidades,
  discapacidadesInitial,
  discapacidadInitial,
  IDiscapacidadSimple,
  discapacidadSimpleInitial,
  IDiscapacidad,
} from './types';
import { RootState } from '../../../../store';
import Discapacidad from './Discapacidad';
import { ICatalogosEstandar } from '../../../../common/types';
import {
  getCatalogoDiscapacidadGrado,
  getCatalogoDiscapacidadOrigen,
  getCatalogoDiscapacidadTipo,
} from '../../../../utils/getCatalogos';
import {
  updateDiscapacidades,
  updateDiscapacidadesPaciente,
  updateDiscapacidadesUsuario,
} from '../../../../utils/sendInfo';
import { setRequest } from '../../../../actions/request/types';

function Discapacidades(props: IDiscapacidadesProps) {
  const {
    setActiveSubStep,
    discapacidades,
    setDiscapacidades,
    cambiosUsuario,
    discapacidadesUsuario,
    setDiscapacidadesUsuario,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const [discapacidadesTemp, setDiscapacidadesTemp] = useState<IDiscapacidades>(discapacidadesInitial);
  /** states del alert */
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  /** catalogos */
  const [catTipoDiscapacidad, setCatTipoDiscapacidad] = useState<Array<ICatalogosEstandar>>([]);
  const [catGradoDiscapacidad, setCatGradoDiscapacidad] = useState<Array<ICatalogosEstandar>>([]);
  const [catOrigenDiscapacidad, setCatOrigenDiscapacidad] = useState<Array<ICatalogosEstandar>>([]);

  const hayCambios: boolean = idUsuario > 0 && cambiosUsuario.filter((c: string) => c.length > 0).length > 0;

  /** Logica */
  const verificarErrorValidacionGeneral = false;
  const agregarDiscapacidad = () => {
    if (discapacidadesTemp.arrayDiscapacidades.length <= 6) {
      setDiscapacidadesTemp({
        ...discapacidadesTemp,
        arrayDiscapacidades: [...discapacidadesTemp.arrayDiscapacidades, discapacidadInitial],
      });
    }
  };

  const eliminarDiscapacidad = (indx: number) => {
    if (discapacidadesTemp.arrayDiscapacidades.length === 1) {
      setDiscapacidadesTemp({
        ...discapacidadesTemp,
        arrayDiscapacidades: [discapacidadInitial],
      });
    } else {
      setDiscapacidadesTemp({
        ...discapacidadesTemp,
        arrayDiscapacidades: [
          ...discapacidadesTemp.arrayDiscapacidades.slice(0, indx),
          ...discapacidadesTemp.arrayDiscapacidades.slice(indx + 1),
        ],
      });
    }
  };

  const cambiarState = (
    event: React.ChangeEvent<{ value: unknown }>,
    indx: number,
    tipo: string,
  ) => {
    const val = event.target.value as string;
    let rowArr;
    switch (tipo) {
      case 'tipoId':
        rowArr = discapacidadesTemp.arrayDiscapacidades[indx];
        rowArr.tipoId = val;
        if (val === '9') {
          rowArr.origenId = '';
          rowArr.gradoId = '';
        }
        break;
      case 'gradoId':
        rowArr = discapacidadesTemp.arrayDiscapacidades[indx];
        if (val === '9') {
          rowArr.origenId = '';
        }
        rowArr.gradoId = val;
        break;
      case 'origenId':
        rowArr = discapacidadesTemp.arrayDiscapacidades[indx];
        rowArr.origenId = val;
        break;
      default:
        rowArr = discapacidadesTemp.arrayDiscapacidades[indx];
        break;
    }
    setDiscapacidadesTemp({
      ...discapacidadesTemp,
      arrayDiscapacidades: [
        ...discapacidadesTemp.arrayDiscapacidades.slice(0, indx),
        rowArr,
        ...discapacidadesTemp.arrayDiscapacidades.slice(indx + 1),
      ],
    });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCatalogoDiscapacidadTipo().then((result: ICatalogosEstandar[]) => {
      setCatTipoDiscapacidad(result);
    });
    getCatalogoDiscapacidadGrado().then((result: ICatalogosEstandar[]) => {
      setCatGradoDiscapacidad(result);
    });
    getCatalogoDiscapacidadOrigen().then((result: ICatalogosEstandar[]) => {
      setCatOrigenDiscapacidad(result);
    });
  }, []);
  useEffect(() => {
    if (discapacidades.loaded && !discapacidadesTemp.loaded) {
      setDiscapacidadesTemp(discapacidades);
    }
  }, [discapacidades]);

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const handleChangeSinDiscapacidad = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setDiscapacidadesTemp({
      ...discapacidadesTemp,
      sinDiscapacidad: checked,
      arrayDiscapacidades: [discapacidadInitial],
      certificado: checked ? '' : discapacidadesTemp.certificado,
    });
  };

  const handleChangeCertificadoRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscapacidadesTemp({
      ...discapacidadesTemp,
      certificado: event.target.value as 'si' | 'no',
    });
  };

  /** handlers del componente */
  const handleChangeComentario = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDiscapacidadesTemp({
      ...discapacidadesTemp,
      comentarios: event.target.value as string,
    });
  };

  const getData = (datos: IDiscapacidades, idUser?: number) => {
    const arrD: IDiscapacidadSimple[] = datos.arrayDiscapacidades
      .filter((e) => e.tipoId !== '')
      .map((d: IDiscapacidad) => ({
        tipoId: d.tipoId,
        gradoId: d.gradoId,
        origenId: d.origenId,
      })) || [discapacidadSimpleInitial];
    return {
      idMedico,
      idConsultorio,
      idPaciente,
      idUsuario: idUser,
      sinDiscapacidad: datos.sinDiscapacidad,
      certificado: datos.certificado,
      arrayDiscapacidades: arrD,
      comentarios: datos.comentarios,
    };
  };

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosDiscapacidades = getData(discapacidadesTemp, idUsuario);
    const sendFunctions = [updateDiscapacidades(datosDiscapacidades)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateDiscapacidadesPaciente(datosDiscapacidades));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => setDiscapacidades({ ...discapacidadesTemp, loaded: true }),
          () => setDiscapacidadesUsuario({ ...discapacidadesTemp, loaded: true }),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDiscapacidadesUsuario(getData(discapacidadesTemp, idMiUsuario)),
        successFunction: () => {
          setDiscapacidades({ ...discapacidadesTemp, loaded: true });
          setActiveSubStep('info-emergencia');
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDiscapacidades(getData(discapacidadesUsuario)),
        successFunction: () => {
          setDiscapacidades(discapacidadesUsuario);
          setDiscapacidadesTemp(discapacidadesUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDiscapacidadesPaciente(getData(discapacidades, idUsuario)),
        successFunction: () => setDiscapacidadesUsuario(discapacidades),
      }),
    );
  };

  return (
    <div id="discapacidadesTemp-paciente" aria-labelledby="discapacidadesTemp-paciente">
      <AlertDialog
        open={alertOpen}
        titulo="¡Atención!"
        descripcion=""
        nombreCancelar="Regresar"
        nombreAceptar="Guardar"
        callBackAceptar={guardarDatosExpediente}
        callBackClose={handleAlertClose}
      />
      <div className="p-4 bg-white container mx-auto shadow rounded-b-md border-solid border border-gray-100">
        {hayCambios && <ResumenCambios cambios={cambiosUsuario} />}
        <form>
          <FormControlLabel
            className="pt-2"
            value={discapacidadesTemp.sinDiscapacidad}
            control={(
              <Checkbox
                disabled={hayCambios}
                checked={discapacidadesTemp.sinDiscapacidad}
                onChange={handleChangeSinDiscapacidad}
                name="sin-discapacidad"
                color="primary"
              />
            )}
            label={t('sin_discapacidades')}
          />
          {discapacidadesTemp.arrayDiscapacidades
            && discapacidadesTemp.arrayDiscapacidades.map((singleProp, indx) => (
              <Discapacidad
                {...singleProp}
                eliminarDiscapacidad={() => eliminarDiscapacidad(indx)}
                cambiarValorSelect={cambiarState}
                catTipoDiscapacidad={catTipoDiscapacidad}
                catGradoDiscapacidad={catGradoDiscapacidad}
                catOrigenDiscapacidad={catOrigenDiscapacidad}
                index={indx}
                sinDiscapacidad={discapacidadesTemp.sinDiscapacidad}
              />
            ))}
          <div className="flex justify-end mb-2">
            <Button
              color="primary"
              size="small"
              onClick={agregarDiscapacidad}
              className={classes.noTextTranform}
              startIcon={<AddCircleOutlineIcon />}
              disabled={discapacidadesTemp.sinDiscapacidad || hayCambios}
            >
              {t('agregar_discapacidad')}
            </Button>
          </div>
          <h3 className="text-gray-600 font-medium m-0">{t('tiene_certificado')}</h3>
          <RadioGroup
            row
            name="sintomas del climaterio"
            value={discapacidadesTemp.certificado}
            onChange={handleChangeCertificadoRadio}
          >
            <FormControlLabel
              value="si"
              control={<Radio color="primary" />}
              label={t('si')}
              labelPlacement="end"
              disabled={discapacidadesTemp.sinDiscapacidad}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label={t('no')}
              labelPlacement="end"
              disabled={discapacidadesTemp.sinDiscapacidad}
            />
          </RadioGroup>
          <h3 className="text-gray-600 font-medium m-0 my-2">{t('comentario')}</h3>
          <TextField
            disabled={hayCambios}
            id="texfield-discapacidadesTemp-comentario"
            variant="outlined"
            multiline
            rows={2}
            fullWidth
            value={discapacidadesTemp.comentarios}
            onChange={handleChangeComentario}
            helperText={`${
              discapacidadesTemp.comentarios ? discapacidadesTemp.comentarios.length : 0
            }/300`}
            inputProps={{ maxLength: 300, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            FormHelperTextProps={{ className: classes.helperText }}
          />
          <div className="flex justify-end mt-6">
            <BotonesGuardar
              hayCambios={hayCambios}
              aceptarCallback={guardarDatosDeUsuarioAExpediente}
              cancelarCallback={guardarDatosDeExpedienteAUsuario}
              guardarCallback={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
              guardarDisable={verificarErrorValidacionGeneral}
              continuar={esPaciente}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Discapacidades;
