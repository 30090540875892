import React from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IListaEnfermedadesNegadasProps } from './types';

function ListaEnfermedadesNegadas(props: IListaEnfermedadesNegadasProps) {
  const { enfermedad, handleBorrarEnfermedadNegada } = props;

  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between items-center">
      <h3 className="m-0 overflow-ellipsis overflow-hidden font-normal text-gray-900 pl-4">
        {enfermedad.diagnosticoCie10?.label}
      </h3>
      <div className="min-w-max self-center">
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => handleBorrarEnfermedadNegada(enfermedad.idBD)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ListaEnfermedadesNegadas;
