import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles({
  noTextTranform: {
    textTransform: 'none',
  },
  paperPadding: {
    padding: '1rem',
  },
  fullWidth: {
    width: '100%',
  },
  widthLess: {
    width: 'calc(100% - 1rem)',
  },
  marginUpDown: {
    margin: '0.9375rem 0',
  },
  marginDown: {
    marginBottom: '0.9375rem',
  },
  marginUp: {
    marginTop: '0.9375rem',
  },
  textUpper: {
    textTransform: 'uppercase',
  },
  colorBlack: {
    color: 'black',
  },
  lineaIzq: {
    borderLeft: '2px solid gray',
    height: '100%',
    width: '2px',
  },
  fila: {
    display: 'flex',
    marginBottom: '1.875rem',
    alignItems: 'center',
  },
  inputMarginR: {
    marginRight: '1rem',
  },
  labelAnios: {
    maxWidth: '4.5rem',
    color: 'black',
    minWidth: '4rem',
  },
  labelDia: {
    minWidth: '5rem',
  },
  labelMes: {
    minWidth: '8.75rem',
  },
  labelAnio: {
    minWidth: '6.25rem',
  },
  mb05: {
    marginBottom: '0.5rem',
  },
  igual: {
    margin: '0 0.5rem',
  },
  labelNacionalidad: {
    minWidth: '9.375rem',
  },
  labelGrupoEtnico: {
    minWidth: '11.25rem',
  },
  labelEscolaridad: {
    minWidth: '13.125rem',
  },
  labelReligion: {
    minWidth: '12.5rem',
  },
  labelOtraReligion: {
    maxWidth: '10rem',
  },
  labelEstadoCivil: {
    minWidth: '12.5rem',
  },
  labelTipoOcupacion: {
    minWidth: '12.5rem',
  },
  labelTiempoOcupacion: {
    minWidth: '18.75rem',
  },
  labelCelular: {
    maxWidth: '8.125rem',
  },
  labelCorreoElectronico: {
    minWidth: '25.125rem',
  },
  labelFijo: {
    maxWidth: '8.125rem',
  },
  lastRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bold: {
    fontWeight: 'bold',
    color: 'black',
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  noMarginDer: {
    marginRight: '0',
  },
});

export const InputDisabled = withStyles({
  root: {
    marginRight: 8,
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 1)',
    },
  },
})(TextField);

export default useStyles;
