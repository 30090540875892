import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { ITratamientoProps } from './types';

function Tratamiento({
  medicamento,
  indice,
  handleEditar,
  handleVerDetalle,
  handleBorrar,
}: ITratamientoProps) {
  const { t } = useTranslation();
  const {
    tipo,
    nombreDistintivo,
    nombreGenerico,
    medTextoLibre,
    via,
    cantidad,
    unidad,
    frecuencia,
    otraFrecuencia,
    duracion,
    periodo,
  } = medicamento;

  const titulo = () => {
    let jsx;
    switch (tipo) {
      case 'nombre_generico':
        jsx = <h3 className="font-normal m-0 text-gray-900">{nombreGenerico.label}</h3>;
        break;
      case 'denominacion_distintiva':
        jsx = (
          <>
            <h3 className="font-normal m-0 text-gray-900">{nombreDistintivo.label}</h3>
            <h4 className="font-normal m-0 text-gray-500">{nombreGenerico.label}</h4>
          </>
        );
        break;
      case 'texto_libre':
        jsx = <h3 className="font-normal m-0 text-gray-900">{medTextoLibre}</h3>;
        break;
      default:
        jsx = <h3 className="font-normal m-0 text-gray-900">Sin información</h3>;
        break;
    }
    return jsx;
  };

  const descripcion = () => (
    <p className="text-gray-500">
      <span className="text-gray-900">{via.impresion}</span>
      {` ${cantidad} ${unidad.label} ${frecuencia.label || otraFrecuencia} ${t(
        'farma_por',
      )} ${duracion} ${periodo.label}`}
    </p>
  );

  return (
    <div className="shadow-lg bg-white rounded border border-solid border-gray-200 my-4 w-full">
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 relative">
        <div className="pr-9">{titulo()}</div>
        {descripcion()}
        <div className="absolute bottom-0 right-0">
          <Button color="primary" onClick={() => handleVerDetalle(indice)}>
            {t('ver_detalle')}
          </Button>
          <IconButton color="primary" onClick={() => handleEditar(indice)}>
            <CreateIcon fontSize="small" />
          </IconButton>
          <IconButton color="primary" onClick={() => handleBorrar(indice)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default Tratamiento;
