import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import FormularioTratamientos from './FormularioTratamientos';
import ListaTratamiento from './ListaTratamiento';
import { ITratamientosProps } from './types';
import { IMedicamento } from '../../../../DiagnosticoPlan/Plan/TerapiaFarmacologica/types';

function Tratamientos({
  enfReferidas,
  catDiagnostico,
  setCatMedicamentos,
  mActuales,
  mAnteriores,
}: ITratamientosProps) {
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const [modoEditar, setModoEditar] = useState<boolean>(false);
  const formularioRef = useRef<HTMLDivElement>(null);
  const [toggleEditar, setToggleEditar] = useState<boolean>(false);
  const [medicamentoAEditar, setMedicamentoAEditar] = useState<IMedicamento>({
    idConsulta,
    idReceta: 0,
    idRecetaConsulta: 0,
    enfermedad: { id: 0, label: '' },
    cantidad: 0,
    duracion: 0,
    fechaFinTratamiento: '',
    fechaReceta: '',
    frecuencia: {
      id: 0,
      label: '',
    },
    otraFrecuencia: '',
    instruccionesAdicionales: '',
    medTextoLibre: '',
    nombreDistintivo: {
      id: 0,
      generico: 0,
      label: '',
    },
    nombreGenerico: {
      id: 0,
      label: '',
    },
    periodo: {
      id: 0,
      label: '',
    },
    presentacion: '',
    tipo: 'nombre_generico',
    unidad: {
      id: 0,
      label: '',
    },
    via: {
      id: 0,
      label: '',
      impresion: '',
    },
    id: -1,
    idPaciente: -1,
    actual: true,
    alertas: { alergias: [] },
  });
  return (
    <div>
      <FormularioTratamientos
        enfReferidas={enfReferidas}
        catDiagnostico={catDiagnostico}
        setCatMedicamentos={setCatMedicamentos}
        modoEditar={modoEditar}
        setModoEditar={setModoEditar}
        mActuales={mActuales}
        medicamentoAEditar={medicamentoAEditar}
        toggleEditar={toggleEditar}
        formularioRef={formularioRef}
      />
      <ListaTratamiento
        mActuales={mActuales}
        mAnteriores={mAnteriores}
        setToggleEditar={setToggleEditar}
        setMedicamentoAEditar={setMedicamentoAEditar}
        setCatMedicamentos={setCatMedicamentos}
        setModoEditar={setModoEditar}
      />
    </div>
  );
}

export default Tratamientos;
