import React from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { IFamiliarEnfermedadNegadaProps } from './types';

function FamiliarEnfermedadNegada({
  index,
  enfermedadNegada,
  handleBorrarEnfermedad,
  handleEditarEnfermedad,
}: IFamiliarEnfermedadNegadaProps) {
  const { t } = useTranslation();

  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 pl-4 pr-5 w-full flex justify-between items-center">
        <h3 className="font-normal m-0 text-gray-500">
          {`${t('niega')}: ${enfermedadNegada.diagnosticoCie10.label}`}
        </h3>
        <div className="min-w-max">
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEditarEnfermedad('negar', index)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => handleBorrarEnfermedad('negar', index)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default FamiliarEnfermedadNegada;
