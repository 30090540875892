import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertDialogProps from './types';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function NuevoUsuarioNoDocId(props: IAlertDialogProps) {
  const {
    open, callBackAceptar, callBackClose, pais,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="doc-id-correcto-titulo"
      aria-describedby="doc-id-correcto-descripcion"
    >
      <div className="flex justify-between items-center bg-blue-500 text-white pl-4">
        <span className="text-lg">{t(`registrar_usuario_no_doc_id_${pais}`)}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <WarningIcon className="text-red-500" color="inherit" fontSize="large" />
        <DialogContentText>
          <p className="text-justify text-red-500 m-0 my-2">{t(`registro_no_doc_id_l1_${pais}`)}</p>
          <p className="text-justify text-red-500 m-0 my-2">
            <Trans i18nKey="registro_no_doc_id_l2">
              index0
              <strong title="index1">index1</strong>
              index2
            </Trans>
          </p>
          <p className="text-justify text-gray-500 m-0 mt-2">
            <Trans i18nKey={`registro_no_doc_id_l3_${pais}`}>
              index0
              <strong title="index1">index1</strong>
              index2
            </Trans>
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex justify-center">
          <div className="mr-4">
            <Button onClick={callBackClose} color="primary" variant="outlined">
              {t('cancelar')}
            </Button>
          </div>
          <div>
            <Button
              onClick={(e) => {
                callBackAceptar();
                callBackClose(e);
              }}
              color="primary"
              variant="contained"
            >
              {t('aceptar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default NuevoUsuarioNoDocId;
