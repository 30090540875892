import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { ReactComponent as AlergiasIcon } from '../../../../assets/icons/Alergias.svg';
import { ReactComponent as EnfermedadesIcon } from '../../../../assets/icons/Enfermedades.svg';
import { ReactComponent as HospitalizacionIcon } from '../../../../assets/icons/Hospitalizacion.svg';
import { ReactComponent as ProcedimientosQuirurgicosIcon } from '../../../../assets/icons/ProcedimientosQuirurgicos.svg';
import { ReactComponent as ProcedimientosTerapeuticosIcon } from '../../../../assets/icons/ProcedimientosTerapeuticos.svg';
import { ReactComponent as ProcedimientosDiagnosticosIcon } from '../../../../assets/icons/ProcedimientosDiagnosticos.svg';
import { ReactComponent as TransfusionesIcon } from '../../../../assets/icons/Transfusiones.svg';
import useStyles from './styles';
import { RootState } from '../../../../store';
import Transfusiones from './Transfusiones';
import Hospitalizacion from './Hospitalizacion/index';
import Procedimiento from './Procedimiento/index';
import EnfermedadesTratamientos from './EnfermedadesTratamientos';
import Alergias from './Alergias';
import DiagnosticosGenerales from './DiagnosticosGenerales';
import { IPersonalesPatologicosProps } from './types';
import { IDiagnosticoGeneral } from './DiagnosticosGenerales/types';

function PersonalesPatologicos(props: IPersonalesPatologicosProps) {
  const {
    setActiveSubStep,
    enfReferidas,
    setEnfReferidas,
    catDiagnostico,
    setCatDiagnostico,
    enfNegadas,
    setEnfNegadas,
    grupoEnfNegadas,
    setGrupoEnfNegadas,
    diagnosticosGenerales,
    setDiagnosticosGenerales,
    tratamientos,
    setTratamientos,
    sinHospitalizaciones,
    setSinHospitalizaciones,
    hospitalizaciones,
    setHospitalizaciones,
    procDiagnosticos,
    setProcDiagnosticos,
    procTerapeuticos,
    setProcTerapeuticos,
    sinProcQuirurgicos,
    setSinProcQuirurgicos,
    procQuirurgicos,
    setProcQuirurgicos,
    sinAlergias,
    setSinAlergias,
    alergias,
    setAlergias,
    sinTransfusiones,
    setSinTransfusiones,
    transfusiones,
    setTransfusiones,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { secciones, esPaciente } = useSelector((state: RootState) => state.Me);
  const { idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const [activeSubSubStep, setActiveSubSubStep] = useState<
  | 'diagnosticos-generales'
  | 'enfermedades-tratamientos'
  | 'hospitalizaciones'
  | 'procedimientos-quirurgicos'
  | 'procedimientos-terapeuticos'
  | 'procedimientos-diagnosticos'
  | 'alergias'
  | 'transfusiones'
  >(esPaciente ? 'diagnosticos-generales' : 'enfermedades-tratamientos');

  return (
    <div>
      <div className="p-4 bg-white container mx-auto shadow rounded-b">
        <div className="relative pb-4">
          <div className="text-left">
            <h1 className="text-blue-800 font-normal m-2">{t('antecedentes-pnp-selecciona')}</h1>
          </div>
        </div>
        <div className="grid grid-cols-3 xl:grid-cols-4 gap-3 pb-6">
          {esPaciente
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 14) && (
              <Button
                className={
                  activeSubSubStep === 'diagnosticos-generales' ? classes.selected : classes.button
                }
                variant="contained"
                color="primary"
                startIcon={<EnfermedadesIcon />}
                fullWidth
                onClick={() => setActiveSubSubStep('diagnosticos-generales')}
                size="large"
              >
                {t('antecedentes-pp-diagnosticos-g')}
              </Button>
          )}
          {!esPaciente
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion >= 14 && valor.idSeccion <= 15) && (
              <Button
                className={
                  activeSubSubStep === 'enfermedades-tratamientos'
                    ? classes.selected
                    : classes.button
                }
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setActiveSubSubStep('enfermedades-tratamientos')}
                size="large"
                startIcon={
                  idUsuario > 0
                  && diagnosticosGenerales.contenidoTabla?.some(
                    (diagnostico: IDiagnosticoGeneral) => diagnostico.posee === 'si' && !diagnostico.validado,
                  ) ? (
                    <Warning color="secondary" fontSize="small" />
                    ) : (
                      <EnfermedadesIcon />
                    )
                }
              >
                {t('antecedentes-pp-enfermedades')}
              </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 17) && (
            <Button
              className={
                activeSubSubStep === 'hospitalizaciones' ? classes.selected : classes.button
              }
              variant="contained"
              color="primary"
              size="large"
              startIcon={<HospitalizacionIcon />}
              fullWidth
              onClick={() => setActiveSubSubStep('hospitalizaciones')}
            >
              {t('antecedentes-pp-hospitalizaciones')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 18) && (
            <Button
              className={
                activeSubSubStep === 'procedimientos-quirurgicos'
                  ? classes.selected
                  : classes.button
              }
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ProcedimientosQuirurgicosIcon />}
              fullWidth
              onClick={() => setActiveSubSubStep('procedimientos-quirurgicos')}
            >
              {t('antecedentes-pp-pquirurgico')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 19) && (
            <Button
              className={
                activeSubSubStep === 'procedimientos-terapeuticos'
                  ? classes.selected
                  : classes.button
              }
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ProcedimientosTerapeuticosIcon />}
              fullWidth
              onClick={() => setActiveSubSubStep('procedimientos-terapeuticos')}
            >
              {t('antecedentes-pp-pterapeutico')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 20) && (
            <Button
              className={
                activeSubSubStep === 'procedimientos-diagnosticos'
                  ? classes.selected
                  : classes.button
              }
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ProcedimientosDiagnosticosIcon />}
              fullWidth
              onClick={() => setActiveSubSubStep('procedimientos-diagnosticos')}
            >
              {t('antecedentes-pp-pdiagnosticos')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 21) && (
            <Button
              className={activeSubSubStep === 'alergias' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AlergiasIcon />}
              fullWidth
              onClick={() => setActiveSubSubStep('alergias')}
            >
              {t('antecedentes-pp-alergias')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 22) && (
            <Button
              className={activeSubSubStep === 'transfusiones' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<TransfusionesIcon />}
              fullWidth
              onClick={() => setActiveSubSubStep('transfusiones')}
            >
              {t('antecedentes-pp-transfusiones')}
            </Button>
          )}
        </div>
        <div>
          {activeSubSubStep === 'diagnosticos-generales'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 14) && (
              <DiagnosticosGenerales
                setActiveSubStep={setActiveSubStep}
                diagnosticosGenerales={diagnosticosGenerales}
                setDiagnosticosGenerales={setDiagnosticosGenerales}
              />
          )}
          {activeSubSubStep === 'enfermedades-tratamientos'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion >= 14 && valor.idSeccion <= 15) && (
              <EnfermedadesTratamientos
                enfReferidas={enfReferidas}
                setEnfReferidas={setEnfReferidas}
                catDiagnostico={catDiagnostico}
                setCatDiagnostico={setCatDiagnostico}
                enfNegadas={enfNegadas}
                setEnfNegadas={setEnfNegadas}
                grupoEnfNegadas={grupoEnfNegadas}
                setGrupoEnfNegadas={setGrupoEnfNegadas}
                diagnosticosGenerales={diagnosticosGenerales}
                setDiagnosticosGenerales={setDiagnosticosGenerales}
                tratamientos={tratamientos}
                setTratamientos={setTratamientos}
              />
          )}
          {activeSubSubStep === 'hospitalizaciones'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 17) && (
              <Hospitalizacion
                sinHospitalizaciones={sinHospitalizaciones}
                setSinHospitalizaciones={setSinHospitalizaciones}
                hospitalizaciones={hospitalizaciones}
                setHospitalizaciones={setHospitalizaciones}
                catDiagnostico={catDiagnostico}
              />
          )}
          {activeSubSubStep === 'procedimientos-quirurgicos'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 18) && (
              <Procedimiento
                modulo="antecedentes"
                tipo="quirurgico"
                sinProcedimientos={sinProcQuirurgicos}
                setSinProcedimientos={setSinProcQuirurgicos}
                procedimientos={procQuirurgicos}
                setProcedimientos={setProcQuirurgicos}
                catDiagnostico={catDiagnostico}
              />
          )}
          {activeSubSubStep === 'procedimientos-terapeuticos'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 19) && (
              <Procedimiento
                modulo="antecedentes"
                tipo="terapeutico"
                sinProcedimientos={false}
                setSinProcedimientos={setSinProcQuirurgicos}
                procedimientos={procTerapeuticos}
                setProcedimientos={setProcTerapeuticos}
                catDiagnostico={catDiagnostico}
              />
          )}
          {activeSubSubStep === 'procedimientos-diagnosticos'
            && secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 20) && (
              <Procedimiento
                modulo="antecedentes"
                tipo="diagnostico"
                sinProcedimientos={false}
                setSinProcedimientos={setSinProcQuirurgicos}
                procedimientos={procDiagnosticos}
                setProcedimientos={setProcDiagnosticos}
                catDiagnostico={catDiagnostico}
              />
          )}
          {activeSubSubStep === 'alergias' && (
            <Alergias
              sinAntecedentes={sinAlergias}
              setSinAntecedentes={setSinAlergias}
              alergias={alergias}
              setAlergias={setAlergias}
            />
          )}
          {activeSubSubStep === 'transfusiones' && (
            <Transfusiones
              sinAntecedentes={sinTransfusiones}
              setSinAntecedentes={setSinTransfusiones}
              transfusiones={transfusiones}
              setTransfusiones={setTransfusiones}
              catDiagnostico={catDiagnostico}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PersonalesPatologicos;
