import {
  IRequestFile,
  RequestFileActionTypes,
  SET_REQUEST_FILE_COMPLETE,
} from '../actions/requestFile/actions';

const initialState: IRequestFile = {
  type: null,
  idMedico: null,
  idPaciente: null,
  idArchivo: -1,
  nombreArchivo: null,
  pathArchivo: '',
  tipoArchivo: '',
  successFunction: null,
};

const RequestFileReducer = (state = initialState, action: RequestFileActionTypes) => {
  switch (action.type) {
    case SET_REQUEST_FILE_COMPLETE:
      return action.payload;
    default:
      return state;
  }
};

export default RequestFileReducer;
