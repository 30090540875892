import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { IVariableProps } from './types';

function Variable({
  idBD, texto, tipo, verVariable, agregarVariable,
}: IVariableProps) {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between items-center">
      <h4 className="m-0 font-normal text-gray-600">
        {texto}
        {tipo && ' (Medipraxi®)'}
      </h4>
      <div>
        <IconButton color="primary" onClick={() => agregarVariable(idBD)} size="small">
          <AddIcon />
        </IconButton>
        <Button color="primary" onClick={() => verVariable(idBD)}>
          {t('ver_modulo')}
        </Button>
      </div>
    </div>
  );
}

export default Variable;
