import { verificarLaboratorios, getEditLaboratorio } from './commonUrl';
import { getToken } from './commonStore';

async function busquedaLaboratorio(url: string, token: string, obj: any) {
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify(obj),
  });
  if (response.status === 200) {
    const data = await response.json();
    if (data.code === 200) {
      return data.datos[0];
    }
    return {};
  }
  return {};
}

export async function editarLaboratorio(
  idPaciente: number,
  idLaboratorio: string,
  idMedico: number,
  idConsultorio: number,
) {
  const token = getToken();
  const response = await fetch(
    getEditLaboratorio(idPaciente, idLaboratorio, idMedico, idConsultorio),
    {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  if (response.status === 200) {
    const data = await response.json();
    if (data.code === 200) {
      return data.datos[0];
    }
    return {};
  }
  return {};
}

export function verificarLaboratoriosPaciente(objToVerify: any) {
  return busquedaLaboratorio(verificarLaboratorios, getToken(), objToVerify);
}
