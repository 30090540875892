/* La configuración permite indicar:
1. La existencia del campo (si el campo está en el objeto es porque existe en la geografía; si no está es porque no existe)
2. El nombre del campo ("label")
4. La obligatoriedad del campo ("required")

Debe mantenerse siempre el orden de los campos de los niveles geográficos, ya que estos son dependientes.
Es decir, cada nivel depende del nivel superior, a excepción del primero.
Siempre que un país tenga menos niveles, estos deben omitirse desde abajo hacia arriba.
1. agee (Estado)
2. agem (Municipio)
3. agel (Localidad)
4. agea (Asentamiento)
*/
function obtenerDatosGeograficos(pais: string) {
  switch (pais) {
    case 'mx':
      return {
        agee: { label: 'entidad_federativa', required: true },
        agem: { label: 'municipio_delegación', required: true },
        agel: { label: 'localidad', required: true },
        agea: { label: 'colonia', required: false },
        calle: { label: 'calle', required: false },
        num1: { label: 'exterior', required: false },
        num2: { label: 'interior', required: false },
        cp: { label: 'codigo_postal', required: false },
        referencias: { label: 'referencias', required: false },
      };
    case 'uy':
      return {
        agee: { label: 'departamento', required: true },
        agem: { label: 'localidad', required: true },
        calle: { label: 'avenida_calle', required: false },
        num1: { label: 'numero', required: false },
        cp: { label: 'codigo_postal', required: false },
        referencias: { label: 'referencias', required: false },
      };
    default:
      return {};
  }
}

export default obtenerDatosGeograficos;
