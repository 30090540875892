import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DayJS from 'dayjs';
import { IHeaderProcedimientosProps } from './types';
import { setSnackComplete } from '../../../../../../../actions/snackbar/types';
import { getMiOrganizacionOrganizacion } from '../../../../../../../utils/getCatalogos';
import { RootState } from '../../../../../../../store';
import { setRequestFile } from '../../../../../../../actions/requestFile/types';

function Header({
  idMedico,
  consultorio,
  nombre,
  edad,
  sexo,
  labelPdf,
  enOrganizacion,
}: IHeaderProcedimientosProps) {
  const { t } = useTranslation();
  const { organizaciones } = useSelector((state: RootState) => state.Organizaciones);
  const [urlImage, setUrlImage] = useState<string>('');
  const dispatch = useDispatch();
  const verImagen = (idArchivo: number, pathArchivo: string, registro: string) => {
    dispatch(
      setRequestFile({
        type: 'get',
        idMedico,
        idPaciente: null,
        idArchivo,
        nombreArchivo: null,
        pathArchivo,
        tipoArchivo: registro,
        successFunction: (url: string) => setUrlImage(url),
      }),
    );
  };
  useEffect(() => {
    if (enOrganizacion) {
      if (idMedico) {
        getMiOrganizacionOrganizacion(idMedico, organizaciones[0].id)
          .then((response) => response.json())
          .then((result) => {
            if (
              result.code === 200
              && result.data?.urlLogotipo
              && result.data?.urlLogotipo.length > 0
            ) {
              verImagen(result.data.id, result.data.urlLogotipo, 'imagenOrganizacion');
            }
          })
          .catch((err) => {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
          });
      }
    } else if (consultorio.logotipo && consultorio.logotipo.length > 0) {
      verImagen(consultorio.idConsultorio, consultorio.logotipo, 'imagenConsultorio');
    }
  }, []);
  return (
    <div>
      <div className="flex items-center border-solid border-0 border-b border-gray-600 mt-4 pb-2">
        {urlImage.length > 0 && (
          <img className="object-contain" width="50px" height="50px" src={urlImage} alt="logo" />
        )}
        <div className="px-5">
          <h2 className="text-blue-500 font-medium m-0 text-center">
            {consultorio.clinicaHospital.id && consultorio.clinicaHospital.id.length
              ? consultorio.clinicaHospital.label.slice(14)
              : consultorio.nombre}
          </h2>
        </div>
      </div>
      <div className="py-4">
        <h2 className="text-blue-500 font-medium m-0 text-center">{labelPdf.toUpperCase()}</h2>
      </div>
      <div className="flex w-full justify-between">
        <h4 className="m-0">
          Nombre paciente: &nbsp;
          <span className="font-normal">{nombre}</span>
        </h4>
        <h4 className="m-0 text-right">
          {`${t('fecha')}: `}
          <span className="font-normal">{DayJS().format('DD/MM/YYYY')}</span>
        </h4>
      </div>
      <div className="flex">
        <h4 className="m-0">
          {t('edad')}
          : &nbsp;
          <span className="font-normal">{edad}</span>
        </h4>
      </div>
      <div className="flex">
        <h4 className="m-0">
          {t('sexo')}
          : &nbsp;
          <span className="font-normal">{sexo && sexo === 'M' ? 'MUJER' : 'HOMBRE'}</span>
        </h4>
      </div>
    </div>
  );
}

export default Header;
