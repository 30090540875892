import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { IFamiliarEnfermedadProps } from './types';

function FamiliarEnfermedad({
  enfermedad,
  index,
  handleEditarEnfermedad,
  handleBorrarEnfermedad,
}: IFamiliarEnfermedadProps) {
  const {
    diagnostico, diagnosticoCie10, diagnosticoMedico, edadAlDiagnostico, esCausaDeMuerte,
  } = enfermedad;
  const { t } = useTranslation();

  const borderDiagnostico = () => {
    let border = 'rounded p-4 w-full';
    if (esCausaDeMuerte) {
      border = 'border-0 border-solid border-red-500 rounded border-l-8 p-4 pr-5 w-full';
    } else {
      border = 'border-0 border-solid border-blue-500 rounded border-l-8 p-4 pr-5 w-full';
    }
    return border;
  };

  const tituloDiagnostico = () => {
    switch (diagnostico) {
      case 'cie10':
        return diagnosticoCie10.label;
      case 'medico':
        return diagnosticoMedico;
      default:
        return '';
    }
  };

  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 pr-4 flex justify-between relative">
      {esCausaDeMuerte && (
        <h4 className="font-normal mb-0 mr-0 mt-0 mr-4 text-red-500 absolute top-0 right-0">
          {t('causa_de_muerte')}
        </h4>
      )}
      <div className={borderDiagnostico()}>
        <h3 className="m-0 overflow-ellipsis overflow-hidden text-left text-gray-500 font-normal">
          {tituloDiagnostico()}
        </h3>
        {edadAlDiagnostico.edad > 0 && (
          <p className="m-0 text-gray-500">
            {`${t('edad_al_diagnostico')}: ${edadAlDiagnostico.edad} `}
            <span>{t(edadAlDiagnostico.periodo)}</span>
          </p>
        )}
      </div>
      <div className="min-w-max self-center">
        <IconButton
          aria-label="edit"
          color="primary"
          onClick={() => {
            handleEditarEnfermedad('referir', index);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => handleBorrarEnfermedad('referir', index)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default FamiliarEnfermedad;
