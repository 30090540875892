export const numeroEnfermedadGrupo = [
  '',
  'infecciosasYParasitarias',
  'neoplasias',
  'hematologicasOQueAfectanElMecanismoDeLaInmunidad',
  'endocrinasNutricionalesYMetabolicas',
  'trastornosMentalesYDelComportamiento',
  'sistemaNervioso',
  'delOjoYSusAnexos',
  'delOidoYApofisisMastoide',
  'sistemaCirculatorio',
  'sistemaRespiratorio',
  'sistemaDigestivo',
  'pielYDelTejidoSubcutaneo',
  'sistemaOsteomuscularYDelTejidoConjuntivo',
  'sistemaGenitourinario',
  'embarazoPartoPuerperio',
  'delPeriodoPerinatal',
  'malformaconesCongenitasYAnomaliasCongenitas',
  'traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternas',
];

export const enf = () => null;
