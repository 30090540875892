import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sendAuthorization } from '../../utils/sendInfo';
import { setRequest } from '../../actions/request/types';

function Authorize() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [autorizado, setAutorizado] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: sendAuthorization({ token }),
          successFunction: () => setAutorizado(true),
          errorFunction: (result: any) => {
            switch (result.code) {
              case 401:
                setError('token_invalido');
                break;
              case 402:
                setError('token_usado');
                break;
              case 500:
                setError('sin_autorizacion');
                break;
              default:
                setError('error');
                break;
            }
          },
          showMsgs: false,
        }),
      );
    } else {
      setError('token_invalido');
    }
  }, []);

  return (
    <div className="relative md:mx-auto w-full md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12">
      <div className="shadow-lg bg-white rounded-xl py-6 px-10 border border-solid border-gray-200">
        <div className="text-center">
          <h2>
            {autorizado && t('autorizacion_exitosa')}
            {error && t(error)}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Authorize;
