import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  noTextTransform: {
    textTransform: 'none',
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: '-1.2rem',
  },
  absoluteBottom2: {
    position: 'absolute',
    bottom: '-2.4rem',
  },
  textUppercase: {
    textTransform: 'uppercase',
  },
  buttonLeft: {
    justifyContent: 'left',
  },
  buttonLeftselected: {
    justifyContent: 'left',
    backgroundColor: '#1E467E',
    color: '#FFFFFF',
  },
});

export default useStyles;
