import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../actions/loading/actions';
import { RootState } from '../../store';
import {
  getSAOrganizacionLicencias,
  getSAOrganizacionOrganizaciones,
} from '../../utils/getCatalogos';
import TablaSuscripciones from './TablaSuscripciones';
import { setSnackComplete } from '../../actions/snackbar/types';
import { IDatosOrganizacion } from './types';
import { IDatosOtraOrganizacion } from '../SuperAdmonInformacion/types';

const TableroSuscripciones = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    idMedico,
    email: emailSend,
    nombre,
    primerApellido,
    orgsAdministradas,
  } = useSelector((state: RootState) => state.Me);
  const { organizacion } = useSelector((state: RootState) => state.Organizaciones);
  const [licencias, setLicencias] = useState({ totales: 0, disponibles: 0 });
  const [organizaciones, setOrganizaciones] = useState<IDatosOrganizacion[]>(
    Array(5).fill({
      id: -1,
      nombre: '',
      admin1Id: ' ',
      admin1Nombre: '',
      admin2Id: ' ',
      admin2Nombre: '',
      licenciasAsignadas: '',
      licenciasDisponibles: '',
    }),
  );

  const columnas = [
    {
      Header: t('administrador_organizacion'),
      accessor: 'nombre',
    },
    {
      Header: `${t('administrador_administrador')} 1`,
      accessor: 'admin1Nombre',
    },
    {
      Header: `${t('administrador_administrador')} 2`,
      accessor: 'admin2Nombre',
    },
    {
      Header: t('administrador_suscripciones_asignadas'),
      accessor: 'licenciasAsignadas',
    },
    {
      Header: t('administrador_suscripciones_disponibles'),
      accessor: 'licenciasDisponibles',
    },
  ];

  useEffect(() => {
    dispatch(setLoading(true));
    if (organizacion.idOrganizacion !== -1) {
      if (orgsAdministradas === 0) {
        history.push('/administracion');
      }
      const fetchLicencias = getSAOrganizacionLicencias(
        idMedico,
        emailSend,
        organizacion.idOrganizacion,
      );
      const fetchOrganizaciones = getSAOrganizacionOrganizaciones(
        idMedico,
        organizacion.idOrganizacion,
      );
      Promise.all([fetchLicencias, fetchOrganizaciones])
        .then((responses) => {
          Promise.all([responses[0].json(), responses[1].json()])
            .then((results) => {
              setLicencias(results[0].data);
              const newArray = results[1].data.map((org: IDatosOtraOrganizacion) => ({
                id: org.id,
                nombre: org.nombreOrganizacion,
                admin1Id: org.administradores.length > 0 ? org.administradores[0].id : '-1',
                admin1Nombre: org.administradores.length > 0 ? org.administradores[0].label : '',
                admin2Id: org.administradores.length > 1 ? org.administradores[1].id : '-1',
                admin2Nombre: org.administradores.length > 1 ? org.administradores[1].label : '',
                licenciasAsignadas: org.licencias,
                licenciasDisponibles: org.licencias - org.licenciasUsadas,
              }));
              setOrganizaciones(newArray);
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-error-warning')} ${err.toString()}`,
                }),
              );
              dispatch(setLoading(false));
            });
        })
        .catch((err) => {
          if (err.toString() !== 'AbortError: The user aborted a request.') {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
          }
          dispatch(setLoading(false));
        });
    }
  }, [organizacion]);

  return (
    <div className="p-4 bg-white">
      <h1 className="text-blue-800 font-normal m-0 text-center">
        {`${t('bienvenido')}, ${nombre || ''} ${primerApellido || ''}`}
      </h1>
      <h3 className="text-green-500 font-bold m-2 pt-4 text-right">
        {`${t('administrador_total_suscripciones_disponibles')}: ${licencias.disponibles}`}
      </h3>
      <h1 className="text-blue-500 font-normal m-2 text-center">
        {t('administrador_tablero_suscripciones')}
      </h1>
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <TablaSuscripciones
          columns={columnas}
          data={organizaciones}
          pageCount={organizaciones.length / 5}
        />
      </div>
    </div>
  );
};

export default TableroSuscripciones;
