import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Stepper, Step, StepLabel, Button, StepConnector,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import loading from '../../../../actions/loading/actions';
import { StepIconProps } from './types';

const LuisConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#10B981',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#10B981',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useLuisStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#10B981',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#10B981',
    zIndex: 1,
    fontSize: 18,
  },
});

function LuisStepIcon(props: StepIconProps) {
  const classes = useLuisStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed || active ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

function StepBienvenido() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [steps] = useState<string[]>(['step_pago_final_1', 'step_pago_final_2']);
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComenzar = () => {
    dispatch(loading(true));
    history.replace('/');
    window.location.reload();
  };

  return (
    <div>
      {activeStep === 0 ? (
        <h2 className="font-normal text-green-500 text-center">{t('_listo_')}</h2>
      ) : (
        <div className="h-16" />
      )}
      <Stepper activeStep={activeStep} alternativeLabel connector={<LuisConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={LuisStepIcon} />
          </Step>
        ))}
      </Stepper>
      <div className="text-center">
        <Trans i18nKey={steps[activeStep]}>
          <span className="text-gray-600 font-medium block">index0</span>
          <span className="text-gray-600 font-normal">index1</span>
        </Trans>
      </div>
      <div className="flex justify-around">
        {activeStep === 0 ? (
          <div />
        ) : (
          <Button onClick={handleBack} size="large">
            {t('atras')}
          </Button>
        )}
        <Button
          color="primary"
          onClick={activeStep === steps.length - 1 ? handleComenzar : handleNext}
          size="large"
        >
          {activeStep === steps.length - 1 ? t('comenzar') : t('siguiente')}
        </Button>
      </div>
    </div>
  );
}

export default StepBienvenido;
