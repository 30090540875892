import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  withStyles,
  RadioGroup,
  FormControlLabel,
  Button,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
/** importancion de las fechas para la fecha */
import { constDia, constMes, constAnio } from '../../../../../../../../constants/fechas';
import { validarFecha, validarFechaEsAnterior } from '../../../../../../../../common/functions';
import useStyles from '../../../../../../../../common/styles';
import { ITablaCervicoUterinoProps } from './types';
import DeleteDialog from '../../../../../../../../components/DeleteDialog';

const BlackRadio = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const BlackTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

function TablaCervicoUterino(props: ITablaCervicoUterinoProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    ginecoObstetrico,
    setGinecoObstetrico,
    index,
    lastIndex,
    estudioItems,
    handleDeleteRow,
    fechaField,
    setFechaField,
  } = props;

  const resultadoOtroItems = ['normal', 'anormal'];
  const resultadoVPHItems = ['negativo', 'positivo'];
  const resultadoPapITems = [
    'Negativo para lesión intraepitelial o malignidad',
    'Células escamosas atípicas',
    'Células glandulares atípicas',
  ];
  const csCelulasEscamosas = [
    {
      id: 1,
      label: 'Células escamosas atípicas de significado incierto (ASC-US)',
    },
    {
      id: 2,
      label: 'Células escamosas atípicas sugestivas de alto grado (ASC-H)',
    },
    {
      id: 3,
      label: 'Lesiones intraepiteliales escamosas de bajo grado (L-SIL)',
    },
    {
      id: 4,
      label: 'Lesión intraepitelial escamosa de alto grado (H-SIL)',
    },
    {
      id: 5,
      label: 'Carcinoma de células escamosas (SCC)',
    },
  ];
  const csCelulasGlandulares = [
    {
      id: 1,
      label: 'Células glandulares atípicas no especificados en otra (AGC-NOS)',
    },
    {
      id: 2,
      label: 'Células glandulares atípicas que sugieren neoplasia (AGC-Neo)',
    },
    {
      id: 3,
      label: 'Adenocarcinoma in situ endocervical (AIS)',
    },
    {
      id: 4,
      label: 'Adenocarcinoma (AC)',
    },
  ];

  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  let resultadoItems = [''];
  switch (ginecoObstetrico.despistajeCancer.cervicoUterino.estudio[index]) {
    case 'Citología cervical':
      resultadoItems = resultadoPapITems;
      break;
    case 'Prueba de VPH-AR':
      resultadoItems = resultadoVPHItems;
      break;
    case 'otro':
    default:
      resultadoItems = resultadoOtroItems;
      break;
  }

  const handleChangeDia = (event: React.ChangeEvent<{ value: unknown }>) => {
    const arr = [...ginecoObstetrico.despistajeCancer.cervicoUterino.dia];
    arr[index] = event.target.value as string;
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        cervicoUterino: {
          ...ginecoObstetrico.despistajeCancer.cervicoUterino,
          dia: arr,
        },
      },
    });
  };

  const handleChangeMes = (event: React.ChangeEvent<{ value: unknown }>) => {
    const arr = [...ginecoObstetrico.despistajeCancer.cervicoUterino.mes];
    arr[index] = event.target.value as string;
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        cervicoUterino: {
          ...ginecoObstetrico.despistajeCancer.cervicoUterino,
          mes: arr,
        },
      },
    });
  };

  const handleChangeAnio = (event: React.ChangeEvent<{ value: unknown }>) => {
    const arr = [...ginecoObstetrico.despistajeCancer.cervicoUterino.anio];
    arr[index] = event.target.value as string;
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        cervicoUterino: {
          ...ginecoObstetrico.despistajeCancer.cervicoUterino,
          anio: arr,
        },
      },
    });
  };

  useEffect(() => {
    if (
      ginecoObstetrico.despistajeCancer.cervicoUterino.dia[index] !== ''
      && ginecoObstetrico.despistajeCancer.cervicoUterino.mes[index] !== ''
      && ginecoObstetrico.despistajeCancer.cervicoUterino.anio[index] !== ''
    ) {
      if (
        !validarFecha(
          ginecoObstetrico.despistajeCancer.cervicoUterino.anio[index],
          ginecoObstetrico.despistajeCancer.cervicoUterino.mes[index],
          ginecoObstetrico.despistajeCancer.cervicoUterino.dia[index],
        )
      ) {
        setFechaField((prevArr) => {
          const arr = [...prevArr];
          arr[index].error = true;
          arr[index].helperText = t('fecha_invalida');
          return arr;
        });
      } else if (
        !validarFechaEsAnterior(
          ginecoObstetrico.despistajeCancer.cervicoUterino.anio[index],
          ginecoObstetrico.despistajeCancer.cervicoUterino.mes[index],
          ginecoObstetrico.despistajeCancer.cervicoUterino.dia[index],
        )
      ) {
        setFechaField((prevArr) => {
          const arr = [...prevArr];
          arr[index].error = true;
          arr[index].helperText = t('fecha_invalida');
          return arr;
        });
      } else {
        setFechaField((prevArr) => {
          const arr = [...prevArr];
          arr[index].error = false;
          arr[index].helperText = '';
          return arr;
        });
      }
    }
  }, [
    ginecoObstetrico.despistajeCancer.cervicoUterino.dia[index],
    ginecoObstetrico.despistajeCancer.cervicoUterino.mes[index],
    ginecoObstetrico.despistajeCancer.cervicoUterino.anio[index],
  ]);

  const handleChangeRadioEstudio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    const newCervicoUterino = ginecoObstetrico.despistajeCancer.cervicoUterino;
    const arrEs = ginecoObstetrico.despistajeCancer.cervicoUterino.estudio.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    newCervicoUterino.estudio = arrEs;
    const arrRes = ginecoObstetrico.despistajeCancer.cervicoUterino.resultado.map((item, j) => {
      if (j === index) {
        return {
          check1: null,
          check2: null,
          check3: null,
          selector2: null,
          selector3: null,
          resultadoRadio: null,
          resultadoTexto: '',
        };
      }
      return item;
    });
    newCervicoUterino.resultado = arrRes;
    if (val !== 'otro') {
      const arrEsp = ginecoObstetrico.despistajeCancer.cervicoUterino.especifica.map((item, j) => {
        if (j === index) {
          return '';
        }
        return item;
      });
      newCervicoUterino.especifica = arrEsp;
    }
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        cervicoUterino: newCervicoUterino,
      },
    });
  };

  const handleChangeEspecifica = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const arrEsp = ginecoObstetrico.despistajeCancer.cervicoUterino.especifica.map((item, j) => {
      if (j === index) {
        return val.toUpperCase();
      }
      return item;
    });
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        cervicoUterino: {
          ...ginecoObstetrico.despistajeCancer.cervicoUterino,
          especifica: arrEsp,
        },
      },
    });
  };

  const handleChangeRadioResultado = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    const arr = ginecoObstetrico.despistajeCancer.cervicoUterino.resultado.map((item, j) => {
      if (j === index) {
        return {
          ...item,
          resultadoRadio: val,
          resultadoTexto: val,
        };
      }
      return item;
    });
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        cervicoUterino: {
          ...ginecoObstetrico.despistajeCancer.cervicoUterino,
          resultado: arr,
        },
      },
    });
  };

  const handleChangeCheckResultado = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.checked;
    const positionArray = resultadoItems.findIndex((resArray) => resArray === event.target.name);
    const arr = ginecoObstetrico.despistajeCancer.cervicoUterino.resultado.map((item, j) => {
      if (j === index) {
        if (positionArray === 0) {
          return {
            ...item,
            check1: val === false ? null : val,
            resultadoTexto: val ? resultadoPapITems[0] : '',
          };
        }
        if (positionArray === 1) {
          return {
            ...item,
            check2: val === false ? null : val,
            selector2: val === false ? null : item.selector2,
            resultadoTexto:
              val === false && item.selector3
                ? csCelulasGlandulares.find((c) => c.id === item.selector3)?.label || ''
                : '',
          };
        }
        if (positionArray === 2) {
          return {
            ...item,
            check3: val === false ? null : val,
            selector3: val === false ? null : item.selector3,
            resultadoTexto:
              val === false && item.selector2
                ? csCelulasEscamosas.find((c) => c.id === item.selector2)?.label || ''
                : '',
          };
        }
      }
      return item;
    });
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        cervicoUterino: {
          ...ginecoObstetrico.despistajeCancer.cervicoUterino,
          resultado: arr,
        },
      },
    });
  };

  const handleChangeComentario = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const arr = ginecoObstetrico.despistajeCancer.cervicoUterino.comentarios.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        cervicoUterino: {
          ...ginecoObstetrico.despistajeCancer.cervicoUterino,
          comentarios: arr,
        },
      },
    });
  };

  const handleChangeSelector = (event: React.ChangeEvent<{ value: unknown }>, selector: number) => {
    const val = event.target.value as number;
    if (selector === 1) {
      const arr = ginecoObstetrico.despistajeCancer.cervicoUterino.resultado.map((item, j) => {
        if (j === index) {
          const sel2 = ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].selector3;
          return {
            ...item,
            selector2: val,
            resultadoTexto: val
              ? `${csCelulasEscamosas.find((c) => c.id === val)?.label}${
                sel2 ? `, ${csCelulasGlandulares.find((c) => c.id === sel2)?.label}` : ''
              }`
              : '',
          };
        }
        return item;
      });
      setGinecoObstetrico({
        ...ginecoObstetrico,
        despistajeCancer: {
          ...ginecoObstetrico.despistajeCancer,
          cervicoUterino: {
            ...ginecoObstetrico.despistajeCancer.cervicoUterino,
            resultado: arr,
          },
        },
      });
    } else if (selector === 2) {
      const sel1 = ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].selector2;
      const arr = ginecoObstetrico.despistajeCancer.cervicoUterino.resultado.map((item, j) => {
        if (j === index) {
          return {
            ...item,
            selector3: val,
            resultadoTexto: val
              ? `${sel1 ? `${csCelulasEscamosas.find((c) => c.id === sel1)?.label}, ` : ''}${
                csCelulasGlandulares.find((c) => c.id === val)?.label
              }`
              : '',
          };
        }
        return item;
      });
      setGinecoObstetrico({
        ...ginecoObstetrico,
        despistajeCancer: {
          ...ginecoObstetrico.despistajeCancer,
          cervicoUterino: {
            ...ginecoObstetrico.despistajeCancer.cervicoUterino,
            resultado: arr,
          },
        },
      });
    }
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <DeleteDialog
        open={alertOpen}
        titulo={t('_atencion_')}
        descripcion={`${t('_esta_seguro_que_desea_eliminar_el_estudio')}${
          ginecoObstetrico.despistajeCancer.cervicoUterino.estudio[index]
            ? ` ${t(ginecoObstetrico.despistajeCancer.cervicoUterino.estudio[index])}`
            : ''
        }${t('_?')}`}
        callBackAceptar={() => handleDeleteRow(index)}
        callBackClose={handleAlertClose}
      />
      <div
        className={`grid grid-cols-12 bg-gray-100 border border-solid border-gray-300 border-t-0${
          lastIndex === index ? ' rounded-b' : ''
        }`}
      >
        <div className="p-4 col-span-3 bg-white">
          <RadioGroup
            aria-label="estudio-cancer-mama"
            value={ginecoObstetrico.despistajeCancer.cervicoUterino.estudio[index]}
            onChange={handleChangeRadioEstudio}
          >
            {estudioItems.map((est) => (
              <FormControlLabel
                key={est}
                value={est}
                control={<Radio color="primary" name={`radio-button-${est}-estudio`} />}
                label={t(est)}
              />
            ))}
          </RadioGroup>
          {ginecoObstetrico.despistajeCancer.cervicoUterino.estudio[index] === 'otro' && (
            <TextField
              variant="outlined"
              fullWidth
              label={t('especifica')}
              InputProps={{
                inputProps: {
                  maxLength: 50,
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                },
              }}
              value={ginecoObstetrico.despistajeCancer.cervicoUterino.especifica[index]}
              onChange={handleChangeEspecifica}
            />
          )}
        </div>
        <div className="bg-blue-300 p-4 col-span-3">
          {ginecoObstetrico.despistajeCancer.cervicoUterino.estudio[index]
          === 'Citología cervical' ? (
            <>
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={
                      ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].check1
                      || false
                    }
                    onChange={handleChangeCheckResultado}
                    name={resultadoItems[0]}
                    disabled={
                      !!ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].check2
                      || !!ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].check3
                    }
                  />
                )}
                label={resultadoItems[0]}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={
                      ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].check2
                      || false
                    }
                    onChange={handleChangeCheckResultado}
                    name={resultadoItems[1]}
                    disabled={
                      !!ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].check1
                    }
                  />
                )}
                label={resultadoItems[1]}
              />
              {ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].check2 && (
                <FormControl variant="outlined" fullWidth>
                  <Select
                    displayEmpty
                    labelId="tipo"
                    id="tipo"
                    value={
                      ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].selector2
                    }
                    onChange={(e) => handleChangeSelector(e, 1)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    <MenuItem value={0} disabled>
                      <span className="text-gray-300">{t('selecciona')}</span>
                    </MenuItem>
                    {csCelulasEscamosas.map((tipo, inx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem value={tipo.id} key={inx}>
                        {tipo.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    onChange={handleChangeCheckResultado}
                    checked={
                      ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].check3
                      || false
                    }
                    name={resultadoItems[2]}
                    disabled={
                      !!ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].check1
                    }
                  />
                )}
                label={resultadoItems[2]}
              />
              {ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].check3 && (
                <FormControl variant="outlined" fullWidth>
                  <Select
                    displayEmpty
                    labelId="tipo"
                    id="tipo"
                    value={
                      ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].selector3
                    }
                    onChange={(e) => handleChangeSelector(e, 2)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                  >
                    <MenuItem value={0} disabled>
                      <span className="text-gray-300">{t('selecciona')}</span>
                    </MenuItem>
                    {csCelulasGlandulares.map((tipo, inx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem value={tipo.id} key={inx}>
                        {tipo.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
            ) : (
              <RadioGroup
                aria-label="resultado-cancer-mama"
                value={
                ginecoObstetrico.despistajeCancer.cervicoUterino.resultado[index].resultadoRadio
              }
                onChange={handleChangeRadioResultado}
              >
                {resultadoItems.map((res) => (
                  <FormControlLabel
                    key={res}
                    value={res}
                    control={<BlackRadio name={`radio-button-${res}-resultado`} />}
                    label={t(res)}
                  />
                ))}
              </RadioGroup>
            )}
          <div className="mt-4">
            <Button
              color="primary"
              className={classes.noTextTransform}
              startIcon={<CloudUploadIcon />}
            >
              {t('cargar_resultado')}
            </Button>
          </div>
        </div>
        <div className="bg-white w-full grid grid-cols-3 gap-2 p-4 col-span-5">
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-dia">{t('dia')}</InputLabel>
            <Select
              labelId="label-dia"
              id="select-dia"
              value={ginecoObstetrico.despistajeCancer.cervicoUterino.dia[index]}
              onChange={handleChangeDia}
              label={`${t('dia')}*`}
              error={false || fechaField[index].error}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
              style={{ backgroundColor: 'white' }}
            >
              {constDia.map((diaSingle) => (
                <MenuItem value={diaSingle.toString()} key={diaSingle}>
                  {diaSingle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-mes">{t('mes')}</InputLabel>
            <Select
              labelId="label-mes"
              id="select-mes"
              value={ginecoObstetrico.despistajeCancer.cervicoUterino.mes[index]}
              onChange={handleChangeMes}
              label={`${t('mes')}*`}
              error={false || fechaField[index].error}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
              style={{ backgroundColor: 'white' }}
            >
              {constMes.map((mesSingle, indx) => (
                <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                  {mesSingle.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.absoluteBottom}>
              {fechaField[index].helperText}
            </FormHelperText>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-año">{t('anio')}</InputLabel>
            <Select
              labelId="label-año"
              id="select-año"
              value={ginecoObstetrico.despistajeCancer.cervicoUterino.anio[index]}
              onChange={handleChangeAnio}
              label={`${t('anio')}*`}
              error={false || fechaField[index].error}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
              style={{ backgroundColor: 'white' }}
            >
              {constAnio().map((anioSingle) => (
                <MenuItem key={anioSingle} value={anioSingle.toString()}>
                  {anioSingle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="col-span-3 mt-4">
            <BlackTextField
              variant="outlined"
              rows={8}
              multiline
              fullWidth
              InputProps={{
                className: 'bg-white',
                inputProps: {
                  maxLength: 300,
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                },
              }}
              value={ginecoObstetrico.despistajeCancer.cervicoUterino.comentarios[index]}
              onChange={handleChangeComentario}
              label={t('comentarios')}
            />
          </div>
        </div>
        <div className="p-4 flex justify-center items-center">
          <IconButton color="primary" onClick={() => setAlertOpen(true)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
}

export default TablaCervicoUterino;
