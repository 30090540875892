import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField, Checkbox, FormControl, FormControlLabel, FormGroup,
} from '@material-ui/core';
import { IBuilderCasillaVerificacionProps } from './types';
import { capitalize } from '../../../../common/functions';
import { IMCPACompleto } from '../../../TabsPaciente/MCPA/types';

function BuilderCasillaVerificacion({
  componenteCasillasOpciones,
  setMCPA,
  id,
}: IBuilderCasillaVerificacionProps) {
  const { t } = useTranslation();

  const handleChangeCheckOp = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nombre = event.target.name;
    const checado = event.target.checked;
    setMCPA((preMCPA: IMCPACompleto) => ({
      ...preMCPA,
      componentes: preMCPA.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: {
                ...preComp.constructorComponente.constructor,
                casillasOpciones: {
                  arrOpciones:
                    preComp.constructorComponente.constructor.casillasOpciones!.arrOpciones.map(
                      (opc) => {
                        if (opc.nombreOpcion === nombre) {
                          return {
                            ...opc,
                            checked: checado,
                            arraySubOpciones: checado
                              ? opc.arraySubOpciones
                              : opc.arraySubOpciones.map((subp) => ({ ...subp, checked: false })),
                          };
                        }
                        return opc;
                      },
                    ),
                },
              },
              valores: {
                ...preComp.constructorComponente.valores,
                casillasOpciones: {
                  arrOpciones: checado
                    ? [
                      ...preComp.constructorComponente.valores.casillasOpciones!.arrOpciones,
                      { nombreOpcion: nombre, arraySubOpciones: [] },
                    ]
                    : preComp.constructorComponente.valores.casillasOpciones!.arrOpciones.filter(
                      (opc) => opc.nombreOpcion !== nombre,
                    ),
                },
              },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  const handlechangeCheckSo = (event: React.ChangeEvent<HTMLInputElement>, indx: number) => {
    const nombre = event.target.name;
    const checado = event.target.checked;
    let papa = '';
    setMCPA((preMCPA: IMCPACompleto) => ({
      ...preMCPA,
      componentes: preMCPA.componentes.map((preComp) => {
        if (preComp.id === id) {
          papa = preComp.constructorComponente.constructor.casillasOpciones!.arrOpciones[indx]
            .nombreOpcion;
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: {
                ...preComp.constructorComponente.constructor,
                casillasOpciones: {
                  arrOpciones:
                    preComp.constructorComponente.constructor.casillasOpciones!.arrOpciones.map(
                      (opc, indi) => {
                        if (indi === indx) {
                          return {
                            ...opc,
                            arraySubOpciones: opc.arraySubOpciones.map((subo) => {
                              if (subo.nombreOpcion === nombre) {
                                return {
                                  ...subo,
                                  checked: checado,
                                };
                              }
                              return subo;
                            }),
                          };
                        }
                        return opc;
                      },
                    ),
                },
              },
              valores: {
                ...preComp.constructorComponente.valores,
                casillasOpciones: {
                  arrOpciones:
                    preComp.constructorComponente.valores.casillasOpciones!.arrOpciones.map(
                      (aop) => {
                        if (aop.nombreOpcion === papa) {
                          return {
                            ...aop,
                            arraySubOpciones: checado
                              ? [...aop.arraySubOpciones, nombre]
                              : aop.arraySubOpciones.filter((opc) => opc !== nombre),
                          };
                        }
                        return aop;
                      },
                    ),
                },
              },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  const handleChangeComentarios = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setMCPA((preMCPA: IMCPACompleto) => ({
      ...preMCPA,
      componentes: preMCPA.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: { ...preComp.constructorComponente.constructor, comentarios: val },
              valores: { ...preComp.constructorComponente.valores, textoLibre: val },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="flex items-end">
        <h3 className="mr-4 mb-0 mt-0 text-gray-600 font-medium">
          {capitalize(componenteCasillasOpciones.datosComponente.titulo)}
        </h3>
        <div className="text-gray-500">
          {componenteCasillasOpciones.datosComponente.descripcion
            ? `* ${componenteCasillasOpciones.datosComponente.descripcion}`
            : ''}
        </div>
      </div>
      <div className="mb-4">
        <FormControl component="fieldset">
          <FormGroup>
            {componenteCasillasOpciones.constructor.casillasOpciones!.arrOpciones.map(
              (singleArr, indx) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={String(indx)}>
                  <FormControlLabel
                    value={singleArr.nombreOpcion}
                    control={(
                      <Checkbox
                        name={singleArr.nombreOpcion}
                        color="primary"
                        checked={singleArr.checked}
                        onChange={handleChangeCheckOp}
                      />
                    )}
                    label={singleArr.nombreOpcion}
                  />
                  <div className="ml-4">
                    <FormGroup row>
                      {singleArr.arraySubOpciones.map((singleCasilla, indy) => (
                        <div key={`secondOption-${String(indx)}-${String(indy)}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                disabled={!singleArr.checked}
                                name={singleCasilla.nombreOpcion}
                                color="primary"
                                checked={singleCasilla.checked}
                                onChange={(e) => handlechangeCheckSo(e, indx)}
                              />
                            )}
                            label={singleCasilla.nombreOpcion}
                          />
                        </div>
                      ))}
                    </FormGroup>
                  </div>
                </div>
              ),
            )}
          </FormGroup>
        </FormControl>
      </div>
      <TextField
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        label={t('comentarios')}
        inputProps={{ autoComplete: 'off', maxLength: 500 }}
        onChange={handleChangeComentarios}
        value={componenteCasillasOpciones.constructor.comentarios}
      />
    </div>
  );
}

export default React.memo(BuilderCasillaVerificacion);
