import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControl, Select, MenuItem, TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AlertDialog from '../../../../components/AlertDialogs/AlertDialog';
import ResumenCambios from '../../../../components/ResumenCambios';
import BotonesGuardar from '../../../../components/BotonesGuardar';
import useStyles from './styles';
import {
  IDatosPersonalesProps,
  IDatosUbicacion,
  ICatalogoAGEE,
  ICatalogoAGEM,
  ICatalogoAGEL,
  ICatalogoAGEA,
  datosUbicacionInitial,
} from './types';
import {
  getCatalogoAgee,
  getCatalogoAgem,
  getCatalogoAgel,
  getCatalogoAgea,
} from '../../../../utils/getCatalogos';
import obtenerDatosGeograficos from '../../../../utils/geografia';
import { validacionCp } from '../../../../constants/validaciones';
import { ITextFieldEstandar } from '../../../../common/types';
import {
  updateDatosUbicacion,
  updateDatosUbicacionPaciente,
  updateDatosUbicacionUsuario,
} from '../../../../utils/sendInfo';
import { RootState } from '../../../../store';
import { setRequest } from '../../../../actions/request/types';

/** Formulario de los datos de ubicacion del paciente */
function DatosDeUbicacion(props: IDatosPersonalesProps) {
  const {
    setActiveSubStep,
    datosUbicacion,
    setDatosUbicacion,
    cambiosUsuario,
    datosUbicacionUsuario,
    setDatosUbicacionUsuario,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    pais,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  /** states del componente funcional */
  const [ageeOriginario, setAgeeOriginario] = useState<ICatalogoAGEE | null>(null);
  const [agemOriginario, setAgemOriginario] = useState<ICatalogoAGEM | null>(null);
  const [agelOriginario, setAgelOriginario] = useState<ICatalogoAGEL | null>(null);
  const [ageeResidente, setAgeeResidente] = useState<ICatalogoAGEE | null>(null);
  const [agemResidente, setAgemResidente] = useState<ICatalogoAGEM | null>(null);
  const [agelResidente, setAgelResidente] = useState<ICatalogoAGEL | null>(null);
  const [ageaResidente, setAgeaResidente] = useState<ICatalogoAGEA | null>(null);
  /** states de comprobacion de fields */
  const [cpField, setCpField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  /** states de componentes que se deshabilitan */
  const [refAgee, setRefAgee] = useState<boolean>(true);
  const [refAgem, setRefAgem] = useState<boolean>(true);
  const [refAgel, setRefAgel] = useState<boolean>(true);
  const [refAgeeResidente, setRefAgeeResidente] = useState<boolean>(true);
  const [refAgemResidente, setRefAgemResidente] = useState<boolean>(true);
  const [refAgelResidente, setRefAgelResidente] = useState<boolean>(true);
  const [refAgeaResidente, setRefAgeaResidente] = useState<boolean>(true);
  /** states para mostrar o no diferentes campos */
  /** states del alert */
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMensaje, setAlertMensaje] = useState<string>('');
  /** catalogos */
  const [catAgeeOriginario, setCatAgeeOriginario] = useState<Array<ICatalogoAGEE>>([]);
  const [catAgemOriginario, setCatAgemOriginario] = useState<Array<ICatalogoAGEM>>([]);
  const [catAgelOriginario, setCatAgelOriginario] = useState<Array<ICatalogoAGEL>>([]);
  const [catAgeeResidente, setCatAgeeResidente] = useState<Array<ICatalogoAGEE>>([]);
  const [catAgemResidente, setCatAgemResidente] = useState<Array<ICatalogoAGEM>>([]);
  const [catAgelResidente, setCatAgelResidente] = useState<Array<ICatalogoAGEL>>([]);
  const [catAgeaResidente, setCatAgeaResidente] = useState<Array<ICatalogoAGEA>>([]);
  const [datosUbicacionTemp, setDatosUbicacionTemp] = useState<IDatosUbicacion>(datosUbicacionInitial);

  const hayCambios: boolean = idUsuario > 0 && cambiosUsuario.filter((c: string) => c.length > 0).length > 0;
  const geo = obtenerDatosGeograficos(pais);

  /** handlers de desabilitacion o no de otros campos */
  const handleAgeeShow = () => {
    setRefAgee(false);
  };
  const handleAgeeHide = () => {
    setRefAgee(true);
  };
  const handleAgemShow = () => {
    setRefAgem(false);
  };
  const handleAgemHide = () => {
    setRefAgem(true);
  };
  const handleAgelShow = () => {
    setRefAgel(false);
  };
  const handleAgelHide = () => {
    setRefAgel(true);
  };

  const handleAgeeResidenteHide = () => {
    setRefAgeeResidente(true);
  };

  const handleAgeeResidenteShow = () => {
    setRefAgeeResidente(false);
  };

  const handleAgemResidenteHide = () => {
    setRefAgemResidente(true);
  };

  const handleAgemResidenteShow = () => {
    setRefAgemResidente(false);
  };

  const handleAgelResidenteHide = () => {
    setRefAgelResidente(true);
  };

  const handleAgelResidenteShow = () => {
    setRefAgelResidente(false);
  };

  const handleAgeaResidenteHide = () => {
    setRefAgeaResidente(true);
  };

  const handleAgeaResidenteShow = () => {
    setRefAgeaResidente(false);
  };

  /** logica */

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  /** Handlers del componente */
  const handleChangeTerritorioOriginario = (event: React.ChangeEvent<{ value: unknown }>) => {
    const terrOrig = event.target.value as string;
    if (terrOrig === t(`gentilicio_${pais}`)) {
      handleAgeeShow();
    } else {
      handleAgeeHide();
      setAgeeOriginario(null);
      handleAgemHide();
    }
    setAgemOriginario(null);
    handleAgelHide();
    setAgelOriginario(null);
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      territorioOriginario: terrOrig,
    });
  };

  const handleChangeAgeeOriginario = (event: any, newValue: ICatalogoAGEE | null) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      ageeOriginario: newValue !== null ? newValue.cve_agee : '',
    });
    if (newValue) {
      setAgeeOriginario(newValue);
      handleAgemShow();
      setAgemOriginario(null);
      getCatalogoAgem(newValue.cve_agee).then((result: ICatalogoAGEM[]) => {
        setCatAgemOriginario(result.map((mun) => ({ ...mun, nom_agem: mun.nom_agem })));
      });
    } else {
      setAgeeOriginario(null);
      handleAgemHide();
    }
    setAgemOriginario(null);
    handleAgelHide();
    setAgelOriginario(null);
  };

  const handleChangeAgemOriginario = (event: any, newValue: ICatalogoAGEM | null) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      agemOriginario: newValue !== null ? newValue.cve_agem : '',
    });
    if (newValue) {
      setAgemOriginario(newValue);
      handleAgelShow();
      setAgelOriginario(null);
      getCatalogoAgel(newValue.cve_agee, newValue.cve_agem).then((result: ICatalogoAGEL[]) => {
        setCatAgelOriginario(result.map((loc) => ({ ...loc, nom_agel: loc.nom_agel })));
      });
    } else {
      setAgemOriginario(null);
      handleAgelHide();
    }
    setAgelOriginario(null);
  };

  const handleChangeAgelOriginario = (event: any, newValue: ICatalogoAGEL | null) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      agelOriginario: newValue !== null ? newValue.cve_agel : '',
    });
    if (newValue) {
      setAgelOriginario(newValue);
    } else {
      setAgelOriginario(null);
    }
  };

  const handleChangeTerritorioResidente = (event: React.ChangeEvent<{ value: unknown }>) => {
    const terrRes = event.target.value as string;
    if (terrRes === t(`gentilicio_${pais}`)) {
      handleAgeeResidenteShow();
      getCatalogoAgee(idMedico).then((result: ICatalogoAGEE[]) => {
        setCatAgeeResidente(result.map((edo) => ({ ...edo, nom_agee: edo.nom_agee })));
      });
    } else {
      handleAgeeResidenteHide();
    }
    setAgeeResidente(null);
    handleAgemResidenteHide();
    setAgemResidente(null);
    handleAgelResidenteHide();
    setAgelResidente(null);
    handleAgeaResidenteHide();
    setAgeaResidente(null);
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      territorioResidente: terrRes,
    });
  };

  const handleChangeAgeeResidente = (event: any, newValue: ICatalogoAGEE | null) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      ageeResidente: newValue !== null ? newValue.cve_agee : '',
    });
    if (newValue) {
      setAgeeResidente(newValue);
      handleAgemResidenteShow();
      setAgemResidente(null);
      getCatalogoAgem(newValue.cve_agee).then((result: ICatalogoAGEM[]) => {
        setCatAgemResidente(result.map((mun) => ({ ...mun, nom_agem: mun.nom_agem })));
      });
    } else {
      setAgeeResidente(null);
      handleAgemResidenteHide();
    }
    setAgemResidente(null);
    handleAgelResidenteHide();
    setAgelResidente(null);
    handleAgeaResidenteHide();
    setAgeaResidente(null);
  };

  const handleChangeAgemResidente = (event: any, newValue: ICatalogoAGEM | null) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      agemResidente: newValue !== null ? newValue.cve_agem : '',
    });
    if (newValue) {
      setAgemResidente(newValue);
      handleAgelResidenteShow();
      setAgelResidente(null);
      getCatalogoAgel(newValue.cve_agee, newValue.cve_agem).then((result: ICatalogoAGEL[]) => {
        setCatAgelResidente(result.map((loc) => ({ ...loc, nom_agel: loc.nom_agel })));
      });
    } else {
      setAgemResidente(null);
      handleAgelResidenteHide();
    }
    setAgelResidente(null);
    handleAgeaResidenteHide();
    setAgeaResidente(null);
  };

  const handleChangeAgelResidente = (event: any, newValue: ICatalogoAGEL | null) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      agelResidente: newValue !== null ? newValue.cve_agel : '',
    });
    if (newValue) {
      setAgelResidente(newValue);
      handleAgeaResidenteShow();
      setAgeaResidente(null);
      getCatalogoAgea(newValue.cve_agee, newValue.cve_agem, newValue.cve_agel).then(
        (result: ICatalogoAGEA[]) => {
          setCatAgeaResidente(result.map((col) => ({ ...col, nom_agea: col.nom_agea })));
        },
      );
    } else {
      setAgelOriginario(null);
      handleAgeaResidenteHide();
    }
    setAgeaResidente(null);
  };

  const handleChangeAgeaResidente = (event: any, newValue: ICatalogoAGEA | null) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      ageaResidente: newValue !== null ? newValue.cve_agea : '',
    });
    if (newValue) {
      setAgeaResidente(newValue);
    } else {
      setAgeaResidente(null);
    }
  };

  const handleChangeCalle = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      calle: event.target.value as string,
    });
  };

  const handleChangeNum1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      num1: event.target.value as string,
    });
  };

  const handleChangeNum2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      num2: event.target.value as string,
    });
  };

  const handleChangeCP = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      cp: event.target.value as string,
    });
    setCpField({ helperText: '', error: false });
  };

  const handleBlurCP = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val.length === 0 || validacionCp(val, pais)) {
      setCpField({ helperText: '', error: false });
    } else {
      setCpField({ helperText: t('cp_incorrecto'), error: true });
    }
  };

  const handleChangeReferencias = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      referencias: event.target.value as string,
    });
  };

  const handleChangeComentario = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDatosUbicacionTemp({
      ...datosUbicacionTemp,
      comentario: event.target.value as string,
    });
  };

  /** handles de disabled components */
  /** Verificar validaciones */
  const verificarErrorValidacionObligatorios = () => {
    if (
      ((datosUbicacionTemp.territorioOriginario === t(`gentilicio_${pais}`)
        && datosUbicacionTemp.ageeOriginario)
        || datosUbicacionTemp.territorioOriginario === 'extranjero'
        || datosUbicacionTemp.territorioOriginario === 'desconocido')
      && ((datosUbicacionTemp.territorioResidente === t(`gentilicio_${pais}`)
        && datosUbicacionTemp.ageeResidente)
        || datosUbicacionTemp.territorioResidente === 'extranjero'
        || datosUbicacionTemp.territorioResidente === 'desconocido')
      && !cpField.error
    ) {
      return false;
    }
    return true;
  };

  /** hooks como useEffect */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCatalogoAgee(idMedico).then((result: ICatalogoAGEE[]) => {
      setCatAgeeOriginario(result.map((edo) => ({ ...edo, nom_agee: edo.nom_agee })));
    });
  }, []);

  useEffect(() => {
    if (datosUbicacion.loaded && !datosUbicacionTemp.loaded) {
      setDatosUbicacionTemp(datosUbicacion);
    }
  }, [datosUbicacion]);

  useEffect(() => {
    if (
      datosUbicacionTemp.territorioOriginario === t(`gentilicio_${pais}`)
      || datosUbicacionTemp.territorioResidente === t(`gentilicio_${pais}`)
    ) {
      getCatalogoAgee(idMedico).then((agees: ICatalogoAGEE[]) => {
        if (datosUbicacionTemp.territorioOriginario === t(`gentilicio_${pais}`)) {
          handleAgeeShow();
          setCatAgeeOriginario(agees.map((edo) => ({ ...edo, nom_agee: edo.nom_agee })));
          const edoOrigin = agees.find(
            (ele) => ele.cve_agee.toString() === datosUbicacionTemp.ageeOriginario,
          );
          setAgeeOriginario(edoOrigin || null);
          if (datosUbicacionTemp.ageeOriginario.length) {
            handleAgemShow();
            getCatalogoAgem(datosUbicacionTemp.ageeOriginario).then(
              (munsOriginario: ICatalogoAGEM[]) => {
                setCatAgemOriginario(
                  munsOriginario.map((mun: ICatalogoAGEM) => ({
                    ...mun,
                    nom_agem: mun.nom_agem,
                  })),
                );
                const munOrigin = munsOriginario.find(
                  (ele) => ele.cve_agee.toString() === datosUbicacionTemp.ageeOriginario
                    && ele.cve_agem.toString() === datosUbicacionTemp.agemOriginario,
                );
                setAgemOriginario(munOrigin || null);
                if (datosUbicacionTemp.agemOriginario.length) {
                  handleAgelShow();
                  getCatalogoAgel(
                    datosUbicacionTemp.ageeOriginario,
                    datosUbicacionTemp.agemOriginario,
                  ).then((locsOriginario: ICatalogoAGEL[]) => {
                    setCatAgelOriginario(
                      locsOriginario.map((loc: ICatalogoAGEL) => ({
                        ...loc,
                        nom_agel: loc.nom_agel,
                      })),
                    );
                    const locOrigin = locsOriginario.find(
                      (ele) => ele.cve_agee.toString() === datosUbicacionTemp.ageeOriginario
                        && ele.cve_agem.toString() === datosUbicacionTemp.agemOriginario
                        && ele.cve_agel.toString() === datosUbicacionTemp.agelOriginario,
                    );
                    setAgelOriginario(locOrigin || null);
                  });
                }
              },
            );
          }
        }
        if (datosUbicacionTemp.territorioResidente === t(`gentilicio_${pais}`)) {
          handleAgeeResidenteShow();
          setCatAgeeResidente(
            agees.map((edo: ICatalogoAGEE) => ({ ...edo, nom_agee: edo.nom_agee })),
          );
          const edoResi = agees.find(
            (ele) => ele.cve_agee.toString() === datosUbicacionTemp.ageeResidente,
          );
          setAgeeResidente(edoResi || null);
          if (datosUbicacionTemp.ageeResidente.length) {
            handleAgemResidenteShow();
            getCatalogoAgem(datosUbicacionTemp.ageeResidente).then(
              (munsResidente: ICatalogoAGEM[]) => {
                setCatAgemResidente(
                  munsResidente.map((mun) => ({ ...mun, nom_agem: mun.nom_agem })),
                );
                const munResi = munsResidente.find(
                  (ele) => ele.cve_agee.toString() === datosUbicacionTemp.ageeResidente
                    && ele.cve_agem.toString() === datosUbicacionTemp.agemResidente,
                );
                setAgemResidente(munResi || null);
                if (datosUbicacionTemp.agemResidente.length) {
                  handleAgelResidenteShow();
                  getCatalogoAgel(
                    datosUbicacionTemp.ageeResidente,
                    datosUbicacionTemp.agemResidente,
                  ).then((locsResidente: ICatalogoAGEL[]) => {
                    setCatAgelResidente(
                      locsResidente.map((loc) => ({ ...loc, nom_agel: loc.nom_agel })),
                    );
                    const locResi = locsResidente.find(
                      (ele) => ele.cve_agee.toString() === datosUbicacionTemp.ageeResidente
                        && ele.cve_agem.toString() === datosUbicacionTemp.agemResidente
                        && ele.cve_agel.toString() === datosUbicacionTemp.agelResidente,
                    );
                    setAgelResidente(locResi || null);
                    if (datosUbicacionTemp.agelResidente.length) {
                      handleAgeaResidenteShow();
                      getCatalogoAgea(
                        datosUbicacionTemp.ageeResidente,
                        datosUbicacionTemp.agemResidente,
                        datosUbicacionTemp.agelResidente,
                      ).then((colsResidente: ICatalogoAGEA[]) => {
                        setCatAgeaResidente(
                          colsResidente.map((col) => ({ ...col, nom_agea: col.nom_agea })),
                        );
                        const colResi = colsResidente.find(
                          (ele) => ele.cve_agee.toString() === datosUbicacionTemp.ageeResidente
                            && ele.cve_agem.toString() === datosUbicacionTemp.agemResidente
                            && ele.cve_agel.toString() === datosUbicacionTemp.agelResidente
                            && ele.cve_agea.toString() === datosUbicacionTemp.ageaResidente,
                        );
                        setAgeaResidente(colResi || null);
                      });
                    }
                  });
                }
              },
            );
          }
        }
      });
    }
  }, [datosUbicacionTemp.territorioOriginario, datosUbicacionTemp.territorioResidente]);

  const getData = (datos: IDatosUbicacion, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsultorio,
    territorioOriginario: datos.territorioOriginario,
    ageeOriginario: datos.ageeOriginario ? datos.ageeOriginario : '',
    agemOriginario: datos.agemOriginario ? datos.agemOriginario : '',
    agelOriginario: datos.agelOriginario ? datos.agelOriginario : '',
    territorioResidente: datos.territorioResidente,
    ageeResidente: datos.ageeResidente ? datos.ageeResidente : '',
    agemResidente: datos.agemResidente ? datos.agemResidente : '',
    agelResidente: datos.agelResidente ? datos.agelResidente : '',
    ageaResidente: datos.ageaResidente ? datos.ageaResidente : '',
    calle: datos.calle,
    num1: datos.num1,
    num2: datos.num2,
    cp: datos.cp,
    referencias: datos.referencias,
    comentario: datos.comentario,
  });
  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const dataUbicacion = getData(datosUbicacionTemp, idUsuario);
    const sendFunctions = [updateDatosUbicacion(dataUbicacion)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateDatosUbicacionPaciente(dataUbicacion));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => setDatosUbicacion({ ...datosUbicacionTemp, loaded: true }),
          () => setDatosUbicacionUsuario({ ...datosUbicacionTemp, loaded: true }),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosUbicacionUsuario(getData(datosUbicacionTemp, idMiUsuario)),
        successFunction: () => {
          setDatosUbicacion({ ...datosUbicacionTemp, loaded: true });
          setActiveSubStep('discapacidades');
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosUbicacion(getData(datosUbicacionUsuario)),
        successFunction: () => {
          setDatosUbicacion(datosUbicacionUsuario);
          setDatosUbicacionTemp(datosUbicacionUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosUbicacionPaciente(getData(datosUbicacion, idUsuario)),
        successFunction: () => setDatosUbicacionUsuario(datosUbicacion),
      }),
    );
  };
  /** Handlers para enviar la informacion */
  const handleSubmitForm = () => {
    if (esPaciente) {
      guardarDatosUsuario();
    } else {
      verificarErrorValidacionObligatorios();
      if (verificarErrorValidacionObligatorios()) {
        setAlertOpen(true);
        setAlertMensaje(t('texto_llenado_norma_oficial'));
        return;
      }
      guardarDatosExpediente();
    }
  };

  return (
    <div id="datos-de-ubicacion" aria-labelledby="datos-de-ubicacion">
      <AlertDialog
        open={alertOpen}
        titulo={t('_atencion_')}
        descripcion={alertMensaje}
        nombreCancelar={t('regresar')}
        nombreAceptar={t('guardar')}
        callBackAceptar={guardarDatosExpediente}
        callBackClose={handleAlertClose}
      />
      <div className="p-4 bg-white container mx-auto shadow rounded-b-md border-solid border border-gray-100">
        {hayCambios && <ResumenCambios cambios={cambiosUsuario} />}
        <form>
          <h2 className="text-gray-600 font-medium my-4">{`${t('originario_de')}*`}</h2>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <h3 className="text-gray-600 font-medium m-0 mb-2">{`${t('territorio')}*`}</h3>
              <FormControl variant="outlined" fullWidth>
                <Select
                  disabled={hayCambios}
                  value={datosUbicacionTemp.territorioOriginario}
                  onChange={handleChangeTerritorioOriginario}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span className="text-gray-400">{t('selecciona')}</span>
                  </MenuItem>
                  <MenuItem value={t(`gentilicio_${pais}`)}>{t(`gentilicio_${pais}`)}</MenuItem>
                  <MenuItem value="extranjero">{t('extranjero')}</MenuItem>
                  <MenuItem value="desconocido">{t('desconocido')}</MenuItem>
                </Select>
              </FormControl>
            </div>
            {geo.agee && (
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">
                  {`${t(`${geo.agee.label}`)}${geo.agee.required ? '*' : ''}`}
                </h3>
                <Autocomplete
                  disabled={refAgee || hayCambios}
                  options={catAgeeOriginario}
                  getOptionLabel={(option) => option.nom_agee}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={ageeOriginario}
                  onChange={(e, nV) => handleChangeAgeeOriginario(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
            )}
            {geo.agem && (
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t(`${geo.agem.label}`)}</h3>
                <Autocomplete
                  disabled={refAgem || hayCambios}
                  options={catAgemOriginario}
                  getOptionLabel={(option) => option.nom_agem}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={agemOriginario}
                  onChange={(e, nV) => handleChangeAgemOriginario(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
            )}
            {geo.agel && (
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t(`${geo.agel.label}`)}</h3>
                <Autocomplete
                  disabled={refAgel || hayCambios}
                  options={catAgelOriginario}
                  getOptionLabel={(option) => option.nom_agel}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={agelOriginario}
                  onChange={(e, nV) => handleChangeAgelOriginario(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
            )}
          </div>
          <h2 className="text-gray-600 font-medium mb-4 mt-8">{`${t('residente_en')}*`}</h2>
          <div className="grid grid-cols-4 gap-4 mb-6">
            <div>
              <h3 className="text-gray-600 font-medium m-0 mb-2">{`${t('territorio')}*`}</h3>
              <FormControl variant="outlined" fullWidth>
                <Select
                  disabled={hayCambios}
                  value={datosUbicacionTemp.territorioResidente}
                  onChange={handleChangeTerritorioResidente}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span className="text-gray-400">{t('selecciona')}</span>
                  </MenuItem>
                  <MenuItem value={t(`gentilicio_${pais}`)}>{t(`gentilicio_${pais}`)}</MenuItem>
                  <MenuItem value="extranjero">{t('extranjero')}</MenuItem>
                  <MenuItem value="desconocido">{t('desconocido')}</MenuItem>
                </Select>
              </FormControl>
            </div>
            {geo.agee && (
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">
                  {`${t(`${geo.agee.label}`)}${geo.agee.required ? '*' : ''}`}
                </h3>
                <Autocomplete
                  disabled={refAgeeResidente || hayCambios}
                  options={catAgeeResidente}
                  getOptionLabel={(option) => option.nom_agee}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={ageeResidente}
                  onChange={(e, nV) => handleChangeAgeeResidente(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
            )}
            {geo.agem && (
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t(`${geo.agem.label}`)}</h3>
                <Autocomplete
                  disabled={refAgemResidente || hayCambios}
                  options={catAgemResidente}
                  getOptionLabel={(option) => option.nom_agem}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={agemResidente}
                  onChange={(e, nV) => handleChangeAgemResidente(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
            )}
            {geo.agel && (
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t(`${geo.agel.label}`)}</h3>
                <Autocomplete
                  disabled={refAgelResidente || hayCambios}
                  options={catAgelResidente}
                  getOptionLabel={(option) => option.nom_agel}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={agelResidente}
                  onChange={(e, nV) => handleChangeAgelResidente(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-12 gap-4 mb-6">
            {geo.agea && (
              <div className="col-span-3">
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t(`${geo.agea.label}`)}</h3>
                <Autocomplete
                  disabled={refAgeaResidente || hayCambios}
                  options={catAgeaResidente}
                  getOptionLabel={(option) => option.nom_agea}
                  fullWidth
                  noOptionsText={t('sin_resultados')}
                  value={ageaResidente}
                  onChange={(e, nV) => handleChangeAgeaResidente(e, nV)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                  )}
                />
              </div>
            )}
            <div className="col-span-4">
              <h3 className="text-gray-600 font-medium m-0 mb-2">{t(`${geo.calle?.label}`)}</h3>
              <TextField
                disabled={hayCambios}
                variant="outlined"
                fullWidth
                value={datosUbicacionTemp.calle}
                onChange={handleChangeCalle}
                inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
              />
            </div>
            <div>
              <h3 className="text-gray-600 font-medium m-0 mb-2">{t(`${geo.num1?.label}_av`)}</h3>
              <TextField
                disabled={hayCambios}
                variant="outlined"
                fullWidth
                value={datosUbicacionTemp.num1}
                onChange={handleChangeNum1}
                inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
              />
            </div>
            {geo.num2 && (
              <div>
                <h3 className="text-gray-600 font-medium m-0 mb-2">{t(`${geo.num2.label}_av`)}</h3>
                <TextField
                  disabled={hayCambios}
                  variant="outlined"
                  fullWidth
                  value={datosUbicacionTemp.num2}
                  onChange={handleChangeNum2}
                  inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                />
              </div>
            )}
            <div className="col-span-2">
              <h3 className="text-gray-600 font-medium m-0 mb-2">{t(`${geo.cp?.label}_av`)}</h3>
              <TextField
                disabled={hayCambios}
                variant="outlined"
                fullWidth
                value={datosUbicacionTemp.cp}
                onChange={handleChangeCP}
                error={cpField.error}
                helperText={cpField.helperText}
                onBlur={handleBlurCP}
                inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 6 }}
              />
            </div>
          </div>
          <div className="mb-6">
            <h3 className="text-gray-600 font-medium m-0 mb-2">{t(`${geo.referencias?.label}`)}</h3>
            <TextField
              disabled={hayCambios}
              variant="outlined"
              fullWidth
              value={datosUbicacionTemp.referencias}
              onChange={handleChangeReferencias}
              helperText={`${
                datosUbicacionTemp.referencias ? datosUbicacionTemp.referencias.length : 0
              }/100`}
              inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              FormHelperTextProps={{ className: classes.helperText }}
            />
          </div>
          <div className="mb-4">
            <h3 className="text-gray-600 font-medium m-0 mb-2">{t('comentario')}</h3>
            <TextField
              disabled={hayCambios}
              variant="outlined"
              multiline
              rows={2}
              fullWidth
              value={datosUbicacionTemp.comentario}
              onChange={handleChangeComentario}
              helperText={`${
                datosUbicacionTemp.comentario ? datosUbicacionTemp.comentario.length : 0
              }/300`}
              inputProps={{ maxLength: 300, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              FormHelperTextProps={{ className: classes.helperText }}
            />
          </div>
          <div className="flex flex-wrap justify-end my-4">
            <span className="text-gray-500 w-full text-right mb-2">
              *
              {t('campos_obligatorios')}
            </span>
            <BotonesGuardar
              hayCambios={hayCambios}
              aceptarCallback={guardarDatosDeUsuarioAExpediente}
              cancelarCallback={guardarDatosDeExpedienteAUsuario}
              guardarCallback={handleSubmitForm}
              guardarDisable={cpField.error}
              continuar={esPaciente}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default DatosDeUbicacion;
