/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from '../../store';
import { ILoggedOutRouteProps } from './types';

function LoggedOutRoute({ children, ...otrasProps }: ILoggedOutRouteProps) {
  const { acreditado } = useSelector((state: RootState) => state.Auth);
  const { rol } = useSelector((state: RootState) => state.Me);
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <img
        src="/img/Logotipo_Color.png"
        alt="logotipo"
        className="absolute top-0 left-0 ml-8 mt-8 z-10"
      />
      <div
        style={{
          backgroundImage: 'url(/img/background_login.svg)',
          height: '100%',
          backgroundSize: 'cover',
          width: '100%',
        }}
        className={`flex ${otrasProps.path === '/signup-patient' ? 'items-start' : 'items-center'}`}
      >
        <Route
          {...otrasProps}
          render={({ location }) => (acreditado === true && rol.id !== -1 ? (
            <Redirect
              to={{
                pathname: `${rol.tipo === 'admin' ? '/administracion' : '/home'}`,
                state: { from: location },
              }}
            />
          ) : (
            children
          ))}
        />
      </div>
    </div>
  );
}

export default LoggedOutRoute;
