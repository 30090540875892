/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ButtonGroup, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../../../../../../../common/styles';
import DeleteDialog from '../../../../../../../components/DeleteDialog';
import { ITabsRecetas } from './types';
import { RootState } from '../../../../../../../store';
import { deleteTerapiaFarmacologicaReceta } from '../../../../../../../utils/deleteInfo';

function TabRecetas({
  indiceReceta, setIndiceReceta, recetas, setRecetas,
}: ITabsRecetas) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  // const [selArray, setSelArray] = useState<number>(0);
  const [delSel, setDelSel] = useState<number>(0);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);

  const verificarDisableAgregarReceta = () => {
    // return false;
    const len = recetas.length;
    return !(recetas[len - 1].idBD > 0);
  };

  const handleAgregarReceta = () => {
    const len = recetas.length;
    if (recetas[len - 1].idBD > 0 && len < 4) {
      // if (len < 4) {
      const valArray = [...recetas];
      valArray.push({
        idBD: -1,
        actualizado: true,
      });
      setRecetas(valArray);
      setIndiceReceta(valArray.length - 1);
    }
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  const eliminarReceta = () => {
    const idReceta = recetas[delSel].idBD;
    let eliminar = true;
    if (idReceta > 0) {
      deleteTerapiaFarmacologicaReceta({
        idReceta,
        idPaciente,
        idMedico,
        idConsultorio,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code !== 200) {
            eliminar = false;
          }
        })
        .catch(() => {});
    }
    if (eliminar) {
      if (delSel === indiceReceta || indiceReceta > delSel) {
        setIndiceReceta((prevVal) => prevVal - 1);
      }
      setRecetas((prevArr) => prevArr.filter((_almr, ind) => delSel !== ind));
    }
  };

  return (
    <div className="border-solid border-0 border-b border-gray-600 flex items-center">
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('d-p-plan-lab-del')}
        callBackAceptar={() => eliminarReceta()}
        callBackClose={handleAlertClose}
      />
      <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
        {recetas.map((_val, inx) => (
          <Button
            // eslint-disable-next-line react/no-array-index-key
            key={String(inx)}
            className={indiceReceta === inx ? classes.buttonLeftselected : classes.buttonLeft}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setIndiceReceta(inx)}
            endIcon={
              inx !== 0 ? (
                <CloseIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setDelSel(inx);
                    setDeleteAlert(true);
                  }}
                />
              ) : null
            }
          >
            {`${t('receta')} ${inx + 1}`}
          </Button>
        ))}
      </ButtonGroup>
      {recetas.length < 4 && (
        <div className="pl-2">
          <Button
            color="primary"
            size="small"
            onClick={handleAgregarReceta}
            disabled={verificarDisableAgregarReceta()}
            startIcon={<AddCircleOutlineIcon />}
          >
            {t('agregar_receta')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default TabRecetas;
