import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField, Checkbox, FormControl, FormControlLabel, FormGroup,
} from '@material-ui/core';
import { IViewCasillasVerificacion } from './types';
import { capitalize } from '../../../../common/functions';

function CasillasVerificacion({ componenteCasillasOpciones }: IViewCasillasVerificacion) {
  const { t } = useTranslation();
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="flex items-end">
        <h3 className="mr-4 mb-0 mt-0 text-gray-600 font-medium">
          {capitalize(componenteCasillasOpciones.datosComponente.titulo)}
        </h3>
        <div className="text-gray-500">
          {componenteCasillasOpciones.datosComponente.descripcion
            ? `* ${componenteCasillasOpciones.datosComponente.descripcion}`
            : ''}
        </div>
      </div>
      <div className="mb-4">
        <FormControl component="fieldset">
          <FormGroup>
            {componenteCasillasOpciones.constructor.casillasOpciones!.arrOpciones.map(
              (singleArr, indx) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={String(indx)}>
                  <FormControlLabel
                    value={singleArr.nombreOpcion}
                    control={(
                      <Checkbox
                        disabled
                        name={singleArr.nombreOpcion}
                        color="primary"
                        checked={singleArr.checked}
                      />
                    )}
                    label={singleArr.nombreOpcion}
                  />
                  <div className="ml-4">
                    <FormGroup row>
                      {singleArr.arraySubOpciones.map((singleCasilla, indy) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`secondOption-${indx}-${indy}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                name={singleCasilla.nombreOpcion}
                                color="primary"
                                disabled
                                checked={singleCasilla.checked}
                              />
                            )}
                            label={singleCasilla.nombreOpcion}
                          />
                        </div>
                      ))}
                    </FormGroup>
                  </div>
                </div>
              ),
            )}
          </FormGroup>
        </FormControl>
      </div>
      <TextField
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        disabled
        label={t('comentarios')}
        inputProps={{ autoComplete: 'off', maxLength: 500 }}
        value={componenteCasillasOpciones.constructor.comentarios}
      />
    </div>
  );
}

export default CasillasVerificacion;
